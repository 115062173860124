import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export interface GetTilageChecklist {
  inventory_item_type: number
  inventory_id: number
  disc: number
  share: number
  tine: number
  bearing: number
  stabilizer_wheel: number
  landslide: number
  cross_shaft: number
  frame: number
  bolts_and_nuts: number
  greasing: number
  hydraulics: number
  hitchPins: number
  gears: number
  result: boolean
  comments: string
  last_update_by: number
  item_verification_status: number
}

export const getTilageChecklist = async (
  inventoryId: number,
  inventoryTypeId: number
): Promise<ApiResponse<GetTilageChecklist>> => {
  return axios.get(
    `/assessment/seeder?inventory_id=${inventoryId}&inventory_item_type=${inventoryTypeId}`
  )
}

export const useGetTilageChecklist = (
  inventoryId: number,
  inventoryTypeId: number
): UseQueryResult<ApiResponse<GetTilageChecklist>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['seederChecklist'],
    queryFn: async () => getTilageChecklist(inventoryId, inventoryTypeId)
  })
}
