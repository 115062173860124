/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type React from 'react'

interface HomelayoutProp {
  className?: string
  children: React.ReactNode
  variant?: 'body' | 'container' | 'bodyMax'
}

export const LandingLayout: React.FC<HomelayoutProp> = ({
  children,
  variant = 'container',
  className
}) => {
  const classes = {
    body: 'w-full relative',
    bodyMax: 'override-body-wrapper',
    container: 'max-w-[1500px] mx-auto my-0 '
  }
  const customClass = `${classes[variant]} ${className || ''}`
  return <div className={customClass}>{children}</div>
}

export const variant = {
  body: 'body',
  bodyMax: 'bodyMax',
  container: 'container'
}
