import { BasicButton } from 'components/Elements/Button'
import type React from 'react'

interface EligibleCroppingPlanProps {
  handleNext: () => void
  handlePrev: () => void
}

export const Disclaimer: React.FC<EligibleCroppingPlanProps> = ({ handleNext, handlePrev }) => {
  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    handleNext()
  }

  return (
    <div className='w-full flex flex-col gap-8'>
      <div className='w-full flex flex-col items-center '>
        <p style={{ color: 'red' }} className=' text-md font-bold'>
          DISCLAIMER!!!
        </p>

        <p className='text-[color:var(--color-secondary-light)] text-md text-center mt-5 '>
          ENSURE THAT YOU HAVE A STABLE INTERNET CONNECTION AND FINISH ALL THE PROCEEDING STEPS.
        </p>
      </div>

      <div className='w-full flex justify-between'>
        <BasicButton label={'Back'} variant={'secondary'} size={'xs'} onClick={handlePrev} />
        <BasicButton label={'Continue'} variant={'primary'} size={'xs'} onClick={handleSubmit} />
      </div>
    </div>
  )
}
