import { SearchBar } from 'components/Form/SearchBar'
import { useSearchLease } from 'features/officer/lease-management/hooks/useSearchLease'
import type React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useOfficerStore } from 'stores/useOfficerStore'
import { LeaseResults } from '../components/LeaseResults'
import { useSearchParams } from 'react-router-dom'
import { Card, Loader, Text } from 'components/ui'

export const SearchLease: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('q')

  const { officer } = useOfficerStore()
  const { searchLease, data, error, isLoading } = useSearchLease(officer?.ext_area_id)
  const [searchQuery, setSearchQuery] = useState(query ?? '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value)
  }

  const handleSearchParams = (): void => {
    setSearchParams({ q: searchQuery })
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }

    // on page refresh search based on query param
    if (searchQuery !== '' && query != null && !isLoading) {
      void searchLease(searchQuery)
    }
  }, [error])

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'} className={'ml-2'}>
        Lease Verification
      </Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <div className={'flex flex-col gap-2'}>
          <Text size={'medium'} className={'ml-2'}>
            Search for a lease by entering the Lessor Farmer ID.
          </Text>
          <SearchBar
            className={'w-full max-w-[700px] flex gap-8'}
            searchQuery={searchQuery}
            onChange={handleChange}
            disabled={searchQuery.length !== 7}
            onSearch={() => {
              handleSearchParams()
              void searchLease(searchQuery)
            }}
            isSearching={isLoading}
          />
        </div>

        {isLoading ? (
          <div className={'mt-8 grid place-items-center h-full flex-1'}>
            <Loader />
          </div>
        ) : null}

        {error !== null ? (
          <Text size={'medium'} variant={'error'} className={'ml-4'}>
            {error}
          </Text>
        ) : null}

        {error === null && !isLoading && data?.length === 0 ? (
          <Text className={'text-center'} variant={'primary'} size={'medium'}>
            No Search Results
          </Text>
        ) : null}

        {data !== null && data.length > 0 ? (
          <LeaseResults
            leases={data}
            className={'flex flex-col gap-2 md:w-[100%]'}
            invalidateSearch={() => {
              void searchLease(searchQuery)
            }}
          />
        ) : null}
      </Card>
    </div>
  )
}
