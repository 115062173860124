import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()
export const usePhoneValidationArray = (phones: string[]): boolean => {
  try {
    for (const phone of phones) {
      if (!phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))) {
        return false
      }
    }
    return true
  } catch (error) {
    return false
  }
}
