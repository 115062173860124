import { Form, SearchField } from 'components/Form'
import { CustomDatePicker } from 'components/Form/DatePicker'
import dayjs from 'dayjs'
import { useIndividualRegistration } from 'features/authentication/hooks/useIndividualRegistration'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'

import { type SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { coerce, object, string, type TypeOf } from 'zod'
import { villageList } from '../../utils/villages'
import {
  Button,
  FormField,
  FormItem,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'

const schema = object({
  omang: string()
    .nonempty('Omang is required')
    .min(9, 'Invalid omang number')
    .max(9, 'Invalid omang number')
    .regex(/[0-9]/, 'Omang should only contain numbers'),
  expiryDate: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  surname: string().nonempty('Surname is required'),
  forenames: string().nonempty('Forenames are required'),
  placeOfBirth: string().nonempty('Place of birth is required'),
  dateOfBirth: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  gender: string().nonempty('Gender is required')
})

export type PersonalDetailsInput = TypeOf<typeof schema>

interface PersonalDetailsProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

export const PersonalDetailsForm: React.FC<PersonalDetailsProps> = ({
  className,
  onNext,
  onBack
}: PersonalDetailsProps) => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const { isLoading, error, verifyOmangDetails } = useIndividualRegistration(onNext)
  const { omangDetails, setOmangDetails } = useIndividualRegistrationStore()
  const [signature, setSignature] = useState<string>('')
  const onSubmit: SubmitHandler<PersonalDetailsInput> = (data: PersonalDetailsInput) => {
    void verifyOmangDetails(data)
  }
  const onSubmitBypass: SubmitHandler<PersonalDetailsInput> = (data: PersonalDetailsInput) => {
    setOmangDetails({
      omang: data.omang,
      dateOfBirth: data.dateOfBirth,
      expiryDate: data.expiryDate,
      forenames: data.forenames,
      gender: data.gender,
      placeOfBirth: data.placeOfBirth,
      surname: data.surname
    })
    onNext()
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(error)
    }
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [error])

  const handleSetSignature = (newSignature: string): void => {
    setSignature(newSignature)
  }

  return (
    <Form<PersonalDetailsInput, typeof schema>
      className={className}
      onSubmit={flags.omangValidation === true ? onSubmit : onSubmitBypass}
      schema={schema}
    >
      {({ control, register, formState: { errors }, handleSubmit }) => (
        <>
          <div className={'w-full flex flex-col gap-8'}>
            <div className={'flex flex-col gap-4 sm:gap-6'}>
              <Input
                placeholder={'Omang'}
                withLabel
                label={'Omang'}
                classLabel={'bg-background-dark sm:bg-card'}
                inputMode={'numeric'}
                error={!(errors.omang == null)}
                helperText={errors?.omang?.message ?? ''}
                register={register('omang')}
                defaultValue={omangDetails !== null ? omangDetails.omang : ''}
              />
              <CustomDatePicker
                error={!(errors.dateOfBirth == null)}
                helperText={errors?.dateOfBirth?.message ?? ''}
                defaultValue={omangDetails !== null ? dayjs(omangDetails.dateOfBirth) : undefined}
                label={'Date of Birth'}
                name={'dateOfBirth'}
                control={control}
              />
              <CustomDatePicker
                error={!(errors.expiryDate == null)}
                helperText={errors?.expiryDate?.message ?? ''}
                defaultValue={omangDetails !== null ? dayjs(omangDetails?.expiryDate) : undefined}
                label={'Expiry Date'}
                name={'expiryDate'}
                control={control}
              />
              <Input
                placeholder={'Forenames'}
                withLabel
                label={'Forenames'}
                classLabel={'bg-background-dark sm:bg-card'}
                inputMode={'text'}
                error={!(errors.forenames == null)}
                defaultValue={omangDetails !== null ? omangDetails.forenames : ''}
                helperText={errors?.forenames?.message ?? ''}
                register={register('forenames')}
              />
              <Input
                placeholder={'Surname'}
                withLabel
                label={'Surname'}
                classLabel={'bg-background-dark sm:bg-card'}
                inputMode={'text'}
                error={!(errors.surname == null)}
                defaultValue={omangDetails !== null ? omangDetails.surname : ''}
                helperText={errors?.surname?.message ?? ''}
                register={register('surname')}
              />
              <SearchField
                options={villageList}
                defaultValue={omangDetails !== null ? omangDetails.placeOfBirth : ''}
                label={'Place of Birth'}
                name={'placeOfBirth'}
                control={control}
                error={!(errors.placeOfBirth == null)}
                helperText={errors?.placeOfBirth?.message ?? ''}
              />
              <div className={'flex flex-col relative mb-2'}>
                <FormField
                  control={control}
                  name='gender'
                  render={({ field }) => (
                    <FormItem className={'w-full'}>
                      <Select onValueChange={field.onChange} defaultValue={(field.value = 'M')}>
                        <SelectTrigger>
                          <SelectValue placeholder='Select Gender' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value='M'>
                            <Text>Male</Text>
                          </SelectItem>
                          <SelectItem value='F'>
                            <Text>Female</Text>
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
                {errors.gender != null ? (
                  <Text
                    variant={'error'}
                    size={'small'}
                    className={'capitalize bottom-[-0.99rem] px-1 max-w-content ml-2 absolute'}
                  >
                    {errors?.gender?.message ?? ''}
                  </Text>
                ) : null}
              </div>
            </div>
            {/* /!* TODO: Handle call to backend with other information *!/ */}
            {/* <div className={'flex flex-row'}> */}
            {/*  <SigningPad setSignature={handleSetSignature} /> */}
            {/* </div> */}
            <div className={'flex flex-row justify-between'}>
              <Button
                className={'w-[46%]'}
                disabled={isLoading}
                variant={'secondary'}
                onClick={onBack}
              >
                Back
              </Button>
              <Button
                className={'w-[46%]'}
                variant={'primary'}
                disabled={isLoading}
                loading={isLoading}
                onClick={handleSubmit(flags?.omangValidation === true ? onSubmit : onSubmitBypass)}
                type={'submit'}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  )
}
