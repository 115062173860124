import type React from 'react'
import clsx from 'clsx'

interface TabsPanelProps {
  children: React.ReactNode
  className?: string
  index: number
  value: number
}

export const TabPanel: React.FC<TabsPanelProps> = ({
  index,
  children,
  value,
  className = 'min-h-full'
}) => {
  const style = clsx(
    'h-full',
    value !== index && 'hidden',
    'flex-grow',
    'flex',
    'flex-col',
    'justify-center'
  )
  return (
    <div
      role='tabpanel'
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={style}
    >
      {value === index && (
        <div
          className={`flex flex-col flex-grow justify-center items-center gap-6 w-full h-full ${className}`}
        >
          {children}
        </div>
      )}
    </div>
  )
}
