import { axios } from 'lib/axios'
import {
  type ApiResponseWithObject,
  type ShareHoldersDetails,
  type ShareHoldersDetailsResponse
} from 'types'

export interface ShareHoldersDetailsDTO {
  uin: string | undefined | null
  shareholders: ShareHoldersDetails
}

export const validateCompanyShareHoldersDetails = async (
  payload: ShareHoldersDetailsDTO
): Promise<ApiResponseWithObject<ShareHoldersDetailsResponse>> => {
  return axios.post('/validate/company/shareholder', payload)
}
