import type React from 'react'

export const NotFound: React.FC = () => {
  return (
    <main className={'flex flex-col gap-4 h-screen justify-center items-center'} role='alert'>
      <h1 className={'text-[3rem]'}>404</h1>
      <p className={'text-xl font-medium'}>Page not found</p>
      <p className={'text-md md:text-lg text-center'}>
        The page you are looking for doesn't exist.
      </p>
    </main>
  )
}
