import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateThreshingDTO {
  cp_id: number | null | undefined
  method: string
  user_id: number | null | undefined
  farmer_id: number | null | undefined
}

export const updateThreshing = async (params: UpdateThreshingDTO): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/threshing`, params)
}
