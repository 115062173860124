import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Farmer } from 'types'

export const getFarmer = async (userId: number | null): Promise<ApiResponse<Farmer>> => {
  return axios.get(`/farmer?USER_ID=${userId ?? ''}`)
}

export const useGetFarmer = (
  userId: number | null,
  role: number | null
): UseQueryResult<ApiResponse<Farmer>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farmer', userId],
    queryFn: async () => getFarmer(userId),
    enabled: userId !== null && role !== null && role === 1000
  })
}
