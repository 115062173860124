import { Dialog, DialogContent, Text } from 'components/ui'
import { SSRVerificationStatus } from 'features/farmer/ssr/components/SSRVerficationStatus'
import { ViewSoilSampleAnalysis } from 'features/farmer/ssr/components/ViewSoilSampleAnalysis'
import type React from 'react'
import { useState } from 'react'
import { type SoilSampleAnalysis, type SoilSampleRequest } from 'types'
import { RejectSsr } from 'features/farmer/ssr/components/RejectSsr'
import { UpdateSSR } from './new-ssr/UpdateSSR'
import dayjs from 'dayjs'

interface SSRCardProps {
  ssr: SoilSampleRequest
  ssa?: SoilSampleAnalysis | undefined
}

export const SSRCard: React.FC<SSRCardProps> = ({ ssa, ssr }) => {
  const [open, setOpen] = useState(false)

  const handleOpenDialog = (): void => {
    setOpen(true)
  }
  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  return (
    <>
      <div
        className={
          'bg-card-secondary flex flex-col cursor-pointer gap-4 p-2 sm:p-4 md:px-8 rounded-sm transition-shadow duration-200 ease-in-out transform shadow-sm shadow-secondary dark:shadow-none hover:shadow-md hover:shadow-input hover:border-primary dark:hover:border-primary'
        }
        onClick={handleOpenDialog}
      >
        <div className={'flex justify-between items-center mx-4'}>
          <Text size={'medium'}>{ssr?.farm_name}</Text>
          <SSRVerificationStatus ssr={ssr} className={'flex items-center gap-2'} />
        </div>

        <div className='hidden md:flex justify-between py-2 md:py-4 w-full border-t border-border dark:border-divider'>
          <div className={'flex flex-col'}>
            <Text size={'small'}>Merchant Name</Text>
            <Text>
              <strong>{ssr.merchant_name}</strong>
            </Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>Ploughing Season</Text>
            <Text>
              <strong>{ssr.plough_season}</strong>
            </Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>{ssa !== undefined ? 'Expiry Date' : 'Create Date'}</Text>
            <Text>
              <strong>
                {ssa !== undefined
                  ? dayjs(ssa?.expiry_date).format('DD-MM-YYYY')
                  : dayjs(ssr.create_date).format('DD-MM-YYYY')}
              </strong>
            </Text>
          </div>
        </div>

        <div className='bg-[#F3F6FD] md:hidden w-full flex flex-col gap-4  p-2 px-4  rounded-md'>
          <div className={'flex flex-col'}>
            <Text size={'small'}>Merchant Name</Text>
            <Text size={'medium'}>
              <strong>{ssr.merchant_name}</strong>
            </Text>
          </div>
          <div className={'flex justify-between'}>
            <div className={'flex flex-col'}>
              <Text size={'small'}>Ploughing Season</Text>
              <Text size={'medium'}>
                <strong> {ssr.plough_season}</strong>
              </Text>
            </div>
            <div className={'flex flex-col'}>
              <Text size={'small'}>{ssa !== undefined ? 'Expiry Date' : 'Create Date'}</Text>
              <Text size={'medium'}>
                <strong>
                  {ssa !== undefined
                    ? dayjs(ssa?.expiry_date).format('DD-MM-YYYY')
                    : dayjs(ssr.create_date).format('DD-MM-YYYY')}
                </strong>
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        key={'open-ssr'}
        open={open}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          {ssa != null ? (
            <ViewSoilSampleAnalysis ssa={ssa} />
          ) : ssr.status_name === 'REJECTED' ? (
            <RejectSsr ssr={ssr} onClose={handleCloseDialog} />
          ) : (
            <UpdateSSR cancel={handleCloseDialog} ssr={ssr} />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
