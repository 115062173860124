import type { Farm, Lease } from 'types'
import type React from 'react'
import { CroppingPlanHeader } from './steps/header/CroppingPlanHeader'
import { Step, Stepper } from 'components/ui/stepper'
import { SeedSelection } from './steps/seeding/SeedSelection'
import { useMedia } from 'react-use'
import { LandPreparation } from './steps/land/LandPreparation'
import { Harvesting } from './steps/harvesting/Harvesting'
import { Packaging } from './steps/packaging/Packaging'
import { ShellingAndThreshing } from './steps/cleaning/ShellingAndThreshing'
import { Weeding } from './steps/weeding/Weeding'
import { Pest } from './steps/pest/Pest'
import { CroppingPlanReview } from './steps/review/Review'
import { LastStep } from './steps/LastStep'

interface Base {
  cancel: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CreateCroppingPlanProps = WithFarm | WithLease

const steps = [
  { label: 'Crop & Hectarage' },
  { label: 'Seeding' },
  { label: 'Weeding', optional: true },
  { label: 'Pest Control', optional: true },
  { label: 'Land Preparation' },
  { label: 'Harvesting', optional: true },
  { label: 'Shelling & Threshing', optional: true },
  { label: 'Packaging', optional: true },
  { label: 'Review' }
]

export const CreateCroppingPlan: React.FC<CreateCroppingPlanProps> = ({ farm, lease, cancel }) => {
  const isDesktop = useMedia('(min-width: 900px)')

  if (farm != null) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={steps}
          onClickStep={(step, setStep) => {
            setStep(step)
          }}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {steps.map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} farm={farm} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Weeding />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Pest />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Harvesting />
                </Step>
              )
            } else if (index === 6) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <ShellingAndThreshing />
                </Step>
              )
            } else if (index === 7) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Packaging />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Stepper
        variant={isDesktop ? 'circle-alt' : 'line'}
        size={'sm'}
        initialStep={0}
        mobileBreakpoint={'900px'}
        steps={steps}
        onClickStep={(step, setStep) => {
          setStep(step)
        }}
        styles={{
          'step-label': 'text-xs md:text-sm'
        }}
        scrollTracking
      >
        {steps.map((stepProps, index) => {
          if (index === 0) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanHeader cancel={cancel} lease={lease} />
              </Step>
            )
          } else if (index === 1) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <SeedSelection />
              </Step>
            )
          } else if (index === 2) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <Weeding />
              </Step>
            )
          } else if (index === 3) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <Pest />
              </Step>
            )
          } else if (index === 4) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <LandPreparation />
              </Step>
            )
          } else if (index === 5) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <Harvesting />
              </Step>
            )
          } else if (index === 6) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <ShellingAndThreshing />
              </Step>
            )
          } else if (index === 7) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <Packaging />
              </Step>
            )
          }
          return (
            <Step key={stepProps.label} {...stepProps}>
              <CroppingPlanReview />
            </Step>
          )
        })}
        <LastStep next={cancel} />
      </Stepper>
    </div>
  )
}
