import { useState } from 'react'
import { AxiosError, type AxiosResponse } from 'axios'
import type { ApiResponse, ApiResponseWithObject } from 'types'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'
import { getFarmerContact } from '../../api/getFarmerContact'
import { useSearchParams } from 'react-router-dom'
import { useOtpVerification } from 'hooks/useOtpVerification'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMerchantStore } from 'stores/useMerchantStore'
import type { SelectedServices } from '../types'
import { useToast } from 'components/ui/use-toast'
import { type SuccessfulVoucherRedemption } from 'features/merchant/types'
import { redeemServicesVoucher, type ServicesVoucherDTO } from '../api/redeemServicesVoucher'

interface UseRedeemServicesVoucher {
  isLoading: boolean
  isOtpSent: boolean
  isVoucherRedeemed: boolean
  contact: string | null
  voucher: SuccessfulVoucherRedemption | null
  getContact: () => Promise<void>
  redeemVoucher: (selectedServices: SelectedServices[]) => void
}

export const useRedeemServicesVoucher = (): UseRedeemServicesVoucher => {
  const { toast } = useToast()
  const [searchParams] = useSearchParams()
  const omang = searchParams.get('omang')
  const queryClient = useQueryClient()
  const { merchant } = useMerchantStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isVoucherRedeemed, setIsVoucherRedeemed] = useState<boolean>(false)
  const [voucher, setVoucher] = useState<SuccessfulVoucherRedemption | null>(null)
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false)
  const [contact, setContact] = useState<string | null>(null)
  const { isLoading: isOtpLoading, sendOtpMessage } = useOtpVerification('', contact ?? '')

  const redeemVoucherMutation = useMutation({
    mutationFn: async (payload: ServicesVoucherDTO) => {
      return redeemServicesVoucher(payload)
    }
  })

  const otpIsSent = (): void => {
    setIsOtpSent(true)
  }

  const getContact = async (): Promise<void> => {
    setIsLoading(true)
    setContact(null)
    try {
      const response = await getFarmerContact(omang)
      if (response.status === 200) {
        setContact(response.data.contact)
        sendOtpMessage(otpIsSent)
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponseWithObject<[]>>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        })
      } else {
        const errorMsg = err as Error
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(errorMsg.message)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const redeemVoucher = (selectedServices: SelectedServices[]): void => {
    setIsVoucherRedeemed(false)

    redeemVoucherMutation.mutate(
      {
        merchant_id: merchant?.merchant_id,
        omang,
        services: selectedServices
      },

      {
        onSuccess: (response) => {
          if (response.status === 201) {
            setIsVoucherRedeemed(true)
            setVoucher(response.data)
            toast({
              variant: 'success',
              description: 'Voucher successfully redeemed!'
            })
            void queryClient.invalidateQueries({ queryKey: ['voucher-eligibility', omang] })
          }
        },
        onError: (err: unknown) => {
          if (err instanceof AxiosError) {
            const errorMsg = err.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errorMsg.data.message
            })
          }
        }
      }
    )
  }
  return {
    isOtpSent,
    isVoucherRedeemed,
    isLoading: isLoading || isOtpLoading || redeemVoucherMutation.isLoading,
    contact,
    voucher,
    getContact,
    redeemVoucher
  }
}
