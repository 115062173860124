import { type RejectReasonInput } from 'features/officer/cropping-plan/components/reject/RejectCroppingPlan'
import { ReturnCroppingPlan } from 'features/officer/cropping-plan/components/return/ReturnCroppingPlan'
import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'

interface ReturnCroppingPlanModalProps {
  header: CroppingPlan
  onClose: () => void
  returnCroppingPlan: (data: RejectReasonInput) => void
  returnFarm: (data: RejectReasonInput) => void
  isLoading: boolean
  className: string
}

export const ReturnCroppingPlanModal: React.FC<ReturnCroppingPlanModalProps> = ({
  header,
  onClose,
  returnFarm,
  returnCroppingPlan,
  isLoading,
  className
}) => {
  if (header.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment) {
    return (
      <div className={className}>
        <ReturnCroppingPlan
          label={'Farm Assessment'}
          onReturn={returnFarm}
          onCancel={onClose}
          isLoading={isLoading}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <ReturnCroppingPlan
        label={'Cropping Plan'}
        onReturn={returnCroppingPlan}
        onCancel={onClose}
        isLoading={isLoading}
      />
    </div>
  )
}
