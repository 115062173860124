import { Grow, Tab } from '@mui/material'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { NotFound } from 'components/Errors/NotFound'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '../../../../components/ui'
import { CustomTab } from 'components/Layout/Tab'
import { TabPanel } from 'components/Layout/TabsPanel'
import { FarmInformation } from 'features/farmer/land-management/components/farm-details/FarmInformation'
import { FarmerInformation } from 'features/officer/farmers/components/FarmViewTabs/FarmerInformation'
import { LeaseHistory } from 'features/officer/farmers/components/FarmViewTabs/LeaseHistory'
import { useFarmers } from 'features/officer/farmers/hooks/useFarmers'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useOfficerStore } from 'stores/useOfficerStore'
import { Box } from '../../../../components/App/Layout'
import { Loader } from '../../../../components/ui/loader'
import { CroppingPlans } from '../components/cropping-plan/CroppingPlans'

export const FarmersFarmView: React.FC = () => {
  const { officer } = useOfficerStore()
  const [value, setValue] = React.useState(0)
  const { farmId } = useParams<{ farmId: string }>()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { isLoading, farm, farmer, error } = useFarmers(officer?.ext_area_id, farmerId, farmId)

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full flex-1'}>
        <Loader size={36} />
      </div>
    )
  }

  if (error) {
    return <NotFound />
  }

  return (
    <Box>
      <Breadcrumb className={'border-b border-border p-4'}>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={'/officer/farmers'}>Farmers</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={`/officer/farmers/${farmerId ?? farm?.farmer_id ?? ''}`}>{farmerId}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{farm?.farm_name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      {farm !== undefined && farmer !== undefined ? (
        <Grow in>
          <div className={'flex flex-col gap-4'}>
            <CustomTab value={value} onChange={handleChange} className={'w-full'}>
              <Tab
                sx={{
                  fontFamily: 'Red Hat Display',
                  textTransform: 'Capitalize',
                  color: 'var(--foreground)'
                }}
                label='Information'
              />
              <Tab
                label='Lease History'
                sx={{
                  fontFamily: 'Red Hat Display',
                  textTransform: 'Capitalize',
                  color: 'var(--foreground)'
                }}
              />
              <Tab
                label='Cropping Plans'
                sx={{
                  fontFamily: 'Red Hat Display',
                  textTransform: 'Capitalize',
                  color: 'var(--foreground)'
                }}
              />
            </CustomTab>
            <div className={'w-full flex flex-col justify-center'}>
              <TabPanel index={0} value={value}>
                <FarmInformation farm={farm} />
                <FarmerInformation farmer={farmer} />
              </TabPanel>
              <TabPanel index={1} value={value}>
                <LeaseHistory />
              </TabPanel>
              <TabPanel index={2} value={value}>
                <CroppingPlans />
              </TabPanel>
            </div>
          </div>
        </Grow>
      ) : (
        <ErrorPage />
      )}
    </Box>
  )
}
