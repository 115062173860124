/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { ReviewApproved } from 'features/farmer/cropping-plan/components/approved-review/ReviewApproved'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type Farm, type Lease } from 'types'
import { CroppingPlans } from '../components/CroppingPlans'
import { Button, Text } from 'components/ui'
import { TabView } from '../../../../components/App/Layout'
import { CreateCroppingPlan } from '../components/CreateCroppingPlan'

interface Base {
  className?: string
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CroppingPlanProps = WithFarm | WithLease

export const CroppingPlan: React.FC<CroppingPlanProps> = ({ className, farm, lease }) => {
  const flags = useFlags()
  const ldClient = useLDClient()

  const {
    setHeader,
    setPest,
    setSeeding,
    setPackaging,
    setWeeding,
    setHarvesting,
    setShellingAndThreshing
  } = useCroppingPlanStore()
  const [isNewPlan, setIsNewPlan] = useState(false)
  const [isApproved, setIsApproved] = useState(false)

  const newPlan = (): void => {
    setHeader(null)
    setSeeding(null)
    setPest(null)
    setWeeding(null)
    setHarvesting(null)
    setShellingAndThreshing(null)
    setPackaging(null)
    setIsNewPlan(true)
  }

  const openPlan = (): void => {
    setIsNewPlan(true)
    setSeeding(null)
    setPest(null)
    setWeeding(null)
    setHarvesting(null)
    setShellingAndThreshing(null)
    setPackaging(null)
    setIsNewPlan(true)
  }

  const cancelCroppingPlan = (): void => {
    setHeader(null)
    setSeeding(null)
    setPest(null)
    setWeeding(null)
    setHarvesting(null)
    setShellingAndThreshing(null)
    setPackaging(null)
    setIsNewPlan(true)
    setIsNewPlan(false)
  }

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (isApproved) {
    return (
      <ReviewApproved
        onClose={() => {
          setIsApproved(false)
        }}
      />
    )
  }

  if (isNewPlan && farm !== undefined) {
    return (
      <TabView>
        <div className={'flex items-center justify-between p-4'}>
          <Text size={'medium'}>Cropping Plan</Text>
          <Button type={'button'} size={'sm'} onClick={cancelCroppingPlan} variant={'link'}>
            Go Back
          </Button>
        </div>

        <CreateCroppingPlan farm={farm} cancel={cancelCroppingPlan} />
      </TabView>
    )
  }

  if (isNewPlan && lease !== undefined) {
    return (
      <TabView>
        <div className={'flex items-center justify-between p-4'}>
          <Text size={'medium'}>Cropping Plan</Text>
          <Button type={'button'} size={'sm'} onClick={cancelCroppingPlan} variant={'link'}>
            Go Back
          </Button>
        </div>

        <CreateCroppingPlan lease={lease} cancel={cancelCroppingPlan} />
      </TabView>
    )
  }

  if (farm !== undefined) {
    if (flags.croppingPlan) {
      return (
        <TabView>
          <CroppingPlans
            openApprovedCroppingPlan={() => {
              setIsApproved(true)
            }}
            farm={farm}
            openCroppingPlan={openPlan}
            newCroppingPlan={newPlan}
          />
        </TabView>
      )
    }
    return (
      <TabView className={'items-center'}>
        <Text size={'medium'}>Unavailable</Text>
      </TabView>
    )
  }

  if (flags.croppingPlan) {
    return (
      <TabView>
        <CroppingPlans
          openApprovedCroppingPlan={() => {
            setIsApproved(true)
          }}
          lease={lease}
          openCroppingPlan={openPlan}
          newCroppingPlan={newPlan}
        />
      </TabView>
    )
  }
  return (
    <TabView className={'items-center'}>
      <Text size={'medium'}>Unavailable</Text>
    </TabView>
  )
}
