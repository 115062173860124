import { type ColumnDef } from '@tanstack/react-table'
import { type Farmer, type Lease } from 'types'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import { isLease } from '../../utils/funcitons'

export const farmersColumns: Array<ColumnDef<Lease | Farmer>> = [
  {
    id: 'farmer_id',
    accessorFn: (row) => (isLease(row) ? row.dest_farmer_id : row.farmer_id),
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => (
      <div className='lowercase'>
        {isLease(row) ? row.getValue('dest_farmer_id') : row.getValue('farmer_id')}
      </div>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader column={isLease(column) ? column : column} title='Farmer ID' />
    )
  },
  {
    id: 'firstname',
    accessorFn: (row) => (isLease(row) ? row.dest_farmer_firstname : row.firstname),
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => (
      <div className='uppercase'>
        {isLease(row) ? row.getValue('destination_citizen_firstname') : row.getValue('firstname')}
      </div>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader column={isLease(column) ? column : column} title='Forename(s)' />
    )
  },
  {
    id: 'surname',
    accessorFn: (row) => (isLease(row) ? row.dest_farmer_surname : row.surname),
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => (
      <div className='uppercase'>
        {isLease(row) ? row.getValue('destination_citizen_surname') : row.getValue('surname')}
      </div>
    ),
    header: ({ column }) => (
      <DataTableColumnHeader column={isLease(column) ? column : column} title='Surname' />
    )
  },
  {
    id: 'contact',
    accessorFn: (row) => (isLease(row) ? row.destination_farmer_contact : row.contact),
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => (
      <div className='uppercase'>
        {isLease(row) ? row.getValue('destination_farmer_contact') : row.getValue('contact')}
      </div>
    ),
    header: 'Contact Number'
  },
  {
    id: 'class_id',
    accessorFn: (row) => (isLease(row) ? row.dest_farmer_class : row.class_id),
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => (
      <div className='uppercase'>
        {isLease(row) ? row.getValue('dest_farmer_class') : row.getValue('class_id')}
      </div>
    ),
    header: 'Status'
  }
]
