import { Lab } from 'components/Auth/Lab'
import { ChangeLabPassword } from 'features/lab/profile/change-password/routes/ChangeMerchantPassword'
import { SoilSampleAnalysis } from 'features/lab/ssr/routes/SoilSampleAnalysis'
import { SoilSampleRequest } from 'features/lab/ssr/routes/SoilSampleRequest'
import { SoilSampleRequests } from 'features/lab/ssr/routes/SoilSampleRequests'
import { Navigate } from 'react-router-dom'
import { ServiceProviderProfile } from '../features/profile/routes/ServiceProvider'

export const labRoutes = {
  path: '/soil-lab',
  element: <Lab />,
  children: [
    { index: true, element: <SoilSampleRequests /> },
    { path: '/soil-lab/change-password', element: <ChangeLabPassword /> },
    { path: '/soil-lab/request/:farmerId/:farmId/:ssrId', element: <SoilSampleRequest /> },
    { path: '/soil-lab/analyses', element: <SoilSampleRequests /> },
    { path: '/soil-lab/analyses/:farmerId/:farmId/:ssrId', element: <SoilSampleAnalysis /> },
    { path: '/soil-lab/profile', element: <ServiceProviderProfile /> },
    { path: '*', element: <Navigate to={'.'} /> }
  ]
}
