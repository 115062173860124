import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type ApiResponse, type Lease } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from 'components/ui/use-toast'
import { renewLease, type RenewLeaseDTO } from '../lease-management/api/renewLease'
import { acceptRenewal, type AcceptRenewalDTO } from '../api/acceptRenewal'

interface UseRenewLeaseProps {
  initiateRenewal: (lease: Lease, params: RenewLeaseDTO) => void
  acceptLeaseRenewal: (lease: Lease, params: AcceptRenewalDTO) => void
  isLoading: boolean
}

export const useRenewLease = (next: () => void): UseRenewLeaseProps => {
  const { farmer } = useFarmerStore()
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const initiateLeaseRenewalMutation = useMutation({
    mutationFn: async (leaseDTO: RenewLeaseDTO) => {
      return renewLease(leaseDTO)
    }
  })

  const acceptLeaseRenewalMutation = useMutation({
    mutationFn: async (payload: AcceptRenewalDTO) => {
      return acceptRenewal(payload)
    }
  })

  const initiateRenewal = (lease: Lease, params: RenewLeaseDTO): void => {
    initiateLeaseRenewalMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: lease?.dest_farmer_id,
        // STATUS_ID: 9,
        LAST_UPDATED_BY: farmer?.user_id,
        LEASED_AREA: params.LEASED_AREA,
        TERMINATION_DATE: params.TERMINATION_DATE
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease renewal request has been sent.'
            })
            void queryClient.invalidateQueries({
              queryKey: [`lease-${lease.lease_id ?? ''}`]
            })
          } else {
            const error = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  const acceptLeaseRenewal = (lease: Lease, params: AcceptRenewalDTO): void => {
    acceptLeaseRenewalMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: farmer?.farmer_id,
        // STATUS_ID: 10,
        LAST_UPDATED_BY: farmer?.user_id,
        ACCEPTANCE: params.ACCEPTANCE
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease successfully renewed.'
            })
            void queryClient.invalidateQueries({ queryKey: [`leases-${lease.farm_id}`] })
          } else {
            const error = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  return {
    isLoading: initiateLeaseRenewalMutation.isLoading || acceptLeaseRenewalMutation.isLoading,
    acceptLeaseRenewal,
    initiateRenewal
  }
}
