import type React from 'react'
import { Outlet } from 'react-router-dom'
import { NavLandingPage, Footer } from 'features/landing/components'
import { Layout } from 'components/ui'
export const UserManuals: React.FC = () => {
  return (
    <Layout variant={'bodyMax'}>
      <NavLandingPage />
      <Outlet />
      <Footer />
    </Layout>
  )
}
