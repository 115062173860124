import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Farm } from 'types'

export const getFarms = async (
  farmerId: string | number | null | undefined,
  extAreaId: number | null | undefined
): Promise<ApiResponse<Farm>> => {
  return axios.get(`/farm?EXT_AREA_ID=${extAreaId ?? ''}&FARMER_ID=${farmerId ?? ''}`)
}

export const useGetFarms = (
  farmerId: string | number | null | undefined,
  extAreaId: number | null | undefined
): UseQueryResult<ApiResponse<Farm>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farmer-farms', farmerId, extAreaId],
    queryFn: async () => getFarms(farmerId, extAreaId),
    enabled: farmerId != null && extAreaId != null
  })
}
