import { CustomStepper } from 'components/Elements/Stepper'
import { CompanyShareHolders } from 'features/authentication/components/company/CompanyShareHolders'
import { LoginDetails } from 'features/authentication/components/company/LoginDetails'
import { SuccessfulCompanyRegistration } from 'features/authentication/components/company/SuccessfulCompanyRegistration'
import { ValidateCompanyDirector } from 'features/authentication/components/company/ValidateCompanyDirector'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompanyUIN } from '../components/company/CompanyUIN'
import { AuthLayout } from '../components/layout/AuthLayout'
import { Text } from '../../../components/ui'
import { CircleOff } from 'lucide-react'

const steps = [
  'Company Details',
  'Director Details',
  'Company Shareholders Details',
  'Login Details'
]

export const CompanyRegistrationPage: React.FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const [activeStep, setActiveStep] = useState(0)
  const [successfulRegistration, setSuccessfulRegistration] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }

  const goToRegistration = (): void => {
    navigate('/registration')
  }

  if (flags.companyRegistration === false) {
    return (
      <div className={'w-full h-screen flex flex-col justify-center items-center gap-4'}>
        <Text size={'xLarge'} className={'opacity-40'}>
          <CircleOff />
        </Text>
        <Text size={'medium'} variant={'bodyTextLight'}>
          This feature is currently not available!
        </Text>
      </div>
    )
  }

  if (successfulRegistration) {
    return <SuccessfulCompanyRegistration />
  }

  if (activeStep === 1) {
    return (
      <AuthLayout
        className={'w-full max-w-[430px] flex gap-8 flex-col justify-center items-center p-4 py-8'}
      >
        <CustomStepper steps={steps} activeStep={activeStep} />
        <ValidateCompanyDirector
          className={'flex flex-col gap-4 md:gap-4 lg:gap-6 w-full'}
          onNext={handleNext}
          onBack={handleBack}
        />
      </AuthLayout>
    )
  }

  if (activeStep === 2) {
    return (
      <AuthLayout
        className={'w-full max-w-[430px] flex gap-8 flex-col justify-center items-center p-4 py-8'}
      >
        <CustomStepper steps={steps} activeStep={activeStep} />
        <CompanyShareHolders onNext={handleNext} onBack={handleBack} />
      </AuthLayout>
    )
  }

  if (activeStep === steps.length - 1) {
    return (
      <AuthLayout
        className={'w-full max-w-[430px] flex gap-8 flex-col justify-center items-center p-4 py-8'}
      >
        <CustomStepper steps={steps} activeStep={activeStep} />
        <LoginDetails
          className={'flex flex-col gap-4 md:gap-4 lg:gap-6 w-full'}
          onNext={() => {
            setSuccessfulRegistration(!successfulRegistration)
          }}
          onBack={handleBack}
        />
      </AuthLayout>
    )
  }

  return (
    <AuthLayout
      className={'w-full max-w-[430px] flex gap-8 flex-col justify-center items-center p-4 py-8'}
    >
      <CustomStepper steps={steps} activeStep={activeStep} />
      <CompanyUIN
        className={'flex flex-col gap-2 md:gap-4 lg:gap-6 w-full'}
        onNext={handleNext}
        onBack={goToRegistration}
      />
    </AuthLayout>
  )
}
