import { RequiresAuth } from 'features/authentication/components/RequiresAuth'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ROLES } from 'types'
import { ErrorPage } from '../Errors/ErrorPage'

const OfficerDashboard = React.lazy(async () => import('../Dashboard/Officer'))

export const Officer: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <RequiresAuth allowedRoles={[ROLES.OFFICER]}>
        <OfficerDashboard />
      </RequiresAuth>
    </ErrorBoundary>
  )
}
