import type React from 'react'
import { type GetInventoryRes, type PoweredImplement } from '../types'
import { Button, Text } from 'components/ui'
import { LabInventoryStatus } from '../../../mechanisationOfficer/inventory/components/LabInventoryStatus'
import dayjs from 'dayjs'

interface InventoryCardProps {
  inventoryData: GetInventoryRes
  inventoryItem: PoweredImplement
  onClose?: () => void
  isMerchant?: boolean
}

export const ViewPoweredImplement: React.FC<InventoryCardProps> = ({
  inventoryData,
  onClose,
  inventoryItem,
  isMerchant
}) => {
  return (
    <div className={'w-full flex-col gap-4 md:gap-8'}>
      <div className={'mb-4 flex justify-between items-center'}>
        <Text size={'medium'}>Powered Implement</Text>
        <Text className={'flex gap-2 items-center'}>
          <LabInventoryStatus inventory={inventoryData} className={'flex items-center gap-4'} />
        </Text>
      </div>

      <div className={'w-full flex flex-wrap gap-4'}>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Serial Number</Text>
          <Text>{inventoryData?.reg_number}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Year Of Manufacture</Text>
          <Text>{dayjs(inventoryData?.yom).format('YYYY')}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Make</Text>
          <Text>{inventoryItem?.make}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Model</Text>
          <Text>{inventoryItem?.model}</Text>
        </div>

        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement Class</Text>
          <Text>{inventoryItem?.implement_class ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement Type</Text>
          <Text>{inventoryItem?.implement_type ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement Sub Type</Text>
          <Text>{inventoryItem?.implement_sub_type ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Capacity</Text>
          <Text>{inventoryItem?.volume_m3 ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Power</Text>
          <Text>{inventoryItem?.implement_power_hp ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Status</Text>
          <Text>{inventoryData.item_status}</Text>
        </div>
      </div>
      {isMerchant === false && (
        <div className={'flex w-full justify-end'}>
          <Button className={'w-full sm:w-[18rem] '} onClick={onClose}>
            Close
          </Button>
        </div>
      )}
    </div>
  )
}
