import moment from 'moment'
import type React from 'react'
import { type Farmer } from 'types'
import { Input, Text } from '../../../../../components/ui'
import { TabView } from '../../../../../components/App/Layout'

interface FarmerInformationProps {
  farmer: Farmer
}

export const FarmerInformation: React.FC<FarmerInformationProps> = ({ farmer }) => {
  return (
    <TabView>
      <Text size={'medium'}>Farmer Information</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={'Forenames'}
          value={farmer.firstname}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={'Surname'} value={farmer.surname} classLabel={'bg-card'} disabled />
        <Input withLabel label={'Omang'} value={farmer.omang} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={'Place of Birth'}
          value={farmer.individual_pob}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Date of Birth'}
          value={moment(farmer.individual_dob).format('DD-MM-YYYY')}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Gender'}
          value={farmer.individual_gender}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Physical Address'}
          value={farmer.physical_addr ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Postal Address'}
          value={farmer.postal_addr ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Email Address'}
          value={farmer.email}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Phone Number'}
          value={farmer.contact}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}
