import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CancelSSRDTO {
  userId: number | null | undefined
  merchantId: number | null | undefined
  farmId: number | null | undefined
  farmerId: number | null | undefined
  ssrId: number | null | undefined
}

export const cancelSSR = async (params: CancelSSRDTO): Promise<ApiResponse<[]>> => {
  return axios.delete(
    `/ssr?user_id=${params.userId ?? ''}&merchant_id=${params.merchantId ?? ''}&farm_id=${
      params.farmId ?? ''
    }&farmer_id=${params.farmerId ?? ''}&ssr_id=${params.ssrId ?? ''}`
  )
}
