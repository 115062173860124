import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type Seeding } from 'types'
import { type AxiosError } from 'axios'

export const getSeeding = async (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): Promise<ApiResponseWithObject<Seeding>> => {
  return axios.get(`/production/seeding?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}

export const useGetSeeding = (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): UseQueryResult<ApiResponseWithObject<Seeding>, AxiosError> => {
  return useQuery({
    queryKey: ['seeding', farmerId, cp_id],
    queryFn: async () => getSeeding(farmerId, cp_id),
    enabled: farmerId != null && cp_id != null
  })
}
