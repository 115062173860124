import UAM from 'assets/usermanuals/user-access-mobile.png'
import UAMac from 'assets/usermanuals/user-access.png'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Card,
  Layout,
  Text
} from 'components/ui'
import type React from 'react'
import { signUpManual, loginManual } from './manualsContent'
export const UserAccess: React.FC = () => {
  return (
    <Layout variant={'bodyMax'} className={'p-2 sm:py-4'}>
      <Layout className={'w-full flex flex-col gap-4 overflow-x-hidden'}>
        {/* order-1 md:order-2 px-2 z-2 */}
        <div className={'flex flex-wrap justify-center'}>
          <div
            className={
              'max-w-[600px] flex items-center flex-[1_0_300px] w-auto sm:flex-[1_0_600px] px-2 justify-center relative min-h-[200px] sm:min-h-[300px] max-h-[500px]'
            }
          >
            <img src={UAM} alt='user access for mobile' className={' h-[80%] absolute mr-[70%]'} />
            <img src={UAMac} alt='user access for mac' className={'max-h-[90%]'} />
          </div>
          <div
            className={
              'max-w-[500px] flex flex-[1_0_300px] flex-col items-center md:items-start justify-center'
            }
          >
            <Text size={'xLarge'} variant={'primaryDark'}>
              User Access Basics
            </Text>
            <Text variant={'muted'}>
              Learn how to get into the system and keep your account safe.
            </Text>
          </div>
        </div>

        <Breadcrumb className={'border-b border-border p-4'}>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href='/'>Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href='/user-manuals'>User Manuals</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>User Access Basis</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <div
          className={
            'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 md:gap-8 px-4 md:px-8'
          }
        >
          <div className={'flex flex-col gap-2 flex-[1_0_400px] sm:max-w-[800px]'}>
            <Text size={'medium'} className={'ml-6'}>
              Create an Account
            </Text>
            <Card>
              <ul>
                {signUpManual.map((desc, index) => (
                  <li>
                    <Text key={index} className={'flex gap-1 leading-loose'}>
                      {index + 1}.
                      <div dangerouslySetInnerHTML={{ __html: desc }} />
                    </Text>
                  </li>
                ))}
              </ul>
            </Card>
          </div>
          <div className={'flex flex-col gap-2 flex-[1_0_400px] sm:max-w-[800px]'}>
            <Text size={'medium'} className={'ml-6'}>
              Log In
            </Text>
            <Card>
              <ul>
                {loginManual.map((desc, index) => (
                  <li>
                    <Text key={index} className={'flex gap-1 leading-loose'}>
                      {index + 1}.
                      <div dangerouslySetInnerHTML={{ __html: desc }} />
                    </Text>
                  </li>
                ))}
              </ul>
            </Card>
          </div>
        </div>
      </Layout>
    </Layout>
  )
}
