import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateSoilSampleAnalysisDTO {
  ec_comp: string
  fe_comp: string
  mn_comp: string
  cu_comp: string
  ph_comp: string
  ca_comp: string
  zn_comp: string
  s_comp: string
  mg_comp: string
  na_comp: string
  k_comp: string
  p_comp: string
  c_comp: string
  cec_comp: string
  bulk_density: string
  user_id: number | null | undefined
  ssr_id: number | null | undefined
}

export const updateSoilSampleAnalysis = async (
  params: UpdateSoilSampleAnalysisDTO
): Promise<ApiResponse<[]>> => {
  return axios.put('/ssr/analysis', params)
}
