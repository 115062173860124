import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface PostTilageChecklist {
  inventory_item_type: number
  inventory_id: number
  disc: number
  share: number
  tine: number
  bearing: number
  stabilizer_wheel: number
  landslide: number
  cross_shaft: number
  frame: number
  bolts_and_nuts: number
  greasing: number
  hydraulics: number
  hitchPins: number
  gears: number
  result: boolean
  comments: string
  last_update_by: number
  item_verification_status: number
}

export const postTilageChecklist = async (
  params: PostTilageChecklist
): Promise<ApiResponse<[]>> => {
  return axios.post('/assessment/tilage', params)
}
