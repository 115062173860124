import { InputField, PhoneInputField } from 'components/Form'
import {
  CategoryLabel,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper'
import type React from 'react'
import { BasicButton } from '../../../../../components/Elements/Button'
import { useAtom } from 'jotai/index'
import { companyLoanStore } from '../stores'
import { FormProvider, useFieldArray, useFormContext, type UseFormReturn } from 'react-hook-form'
import { array, object, type TypeOf } from 'zod'
import { auditData } from './Accountants'
import { Text } from '../../../../../components/Elements/Text'

interface StepProps {
  methods: UseFormReturn<AuditorsInput>
  telephones: string[]
  faxes: string[]
  cells: string[]
  setTelephones: React.Dispatch<React.SetStateAction<string[]>>
  setFaxes: React.Dispatch<React.SetStateAction<string[]>>
  setCells: React.Dispatch<React.SetStateAction<string[]>>
}

export const schemaAud = object({
  auditors: array(auditData).nonempty('At least one auditor is required')
})

export type AuditorsInput = TypeOf<typeof schemaAud>
export const Auditors: React.FC<StepProps> = ({
  methods,

  setTelephones,
  setFaxes,
  setCells
}) => {
  const [loanData] = useAtom(companyLoanStore)
  const { control } = useFormContext<AuditorsInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'auditors'
  })

  const addAuditors = (): void => {
    append({
      bica_registration: '',
      physical_address: '',
      postal_address: '',
      cell: '',
      telephone: '',
      fax: ''
    })
  }

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <div className='w-full flex flex-col gap-8'>
          <div className={'flex items-center justify-between'}>
            <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
              Auditors
            </Text>

            <BasicButton label={'Add new'} variant={'primary'} size={'xs'} onClick={addAuditors} />
          </div>

          {fields.map((field, index) => (
            <div key={index} id={`auditor_${index + 1}`} className='w-full mb-8'>
              <CategoryLabel label={`Auditor ${index + 1}`} />
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px]  capitalize'
                  label='BICA Registration NO'
                  type='number'
                  defaultValue={loanData?.loan_auditors?.[index]?.bica_registration}
                  register={methods.register(`auditors.${index}.bica_registration`)}
                  error={!(methods.formState?.errors?.auditors?.[index]?.bica_registration == null)}
                  helperText={
                    methods.formState.errors?.auditors?.[index]?.bica_registration?.message ?? ''
                  }
                />
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label='physical address'
                  defaultValue={loanData?.loan_auditors?.[index]?.physical_address}
                  register={methods.register(`auditors.${index}.physical_address`)}
                  error={!(methods.formState?.errors?.auditors?.[index]?.physical_address == null)}
                  helperText={
                    methods.formState.errors?.auditors?.[index]?.physical_address?.message ?? ''
                  }
                />
              </RowContainer>

              <RowContainer>
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label='postal address'
                  defaultValue={loanData?.loan_auditors?.[index]?.postal_address}
                  register={methods.register(`auditors.${index}.postal_address`)}
                  error={!(methods.formState?.errors?.auditors?.[index]?.postal_address == null)}
                  helperText={
                    methods.formState.errors?.auditors?.[index]?.postal_address?.message ?? ''
                  }
                />
                <PhoneInputField
                  label='Cellphone'
                  onChange={(num) => {
                    methods.setValue(`auditors.${index}.cell`, num)
                    setCells((prevCells) => {
                      const newCells = [...prevCells]
                      newCells[index] = num
                      return newCells
                    })
                  }}
                  value={field.cell}
                  className='flex-[1_0_230px] capitalize'
                />
              </RowContainer>
              <RowContainer>
                <PhoneInputField
                  label='Telephone'
                  onChange={(num) => {
                    methods.setValue(`auditors.${index}.telephone`, num)
                    setTelephones((prevPhones) => {
                      const newPhones = [...prevPhones]
                      newPhones[index] = num
                      return newPhones
                    })
                  }}
                  value={field.telephone}
                  className='flex-[1_0_230px] capitalize'
                />
                <PhoneInputField
                  label='FAX'
                  onChange={(num) => {
                    methods.setValue(`auditors.${index}.fax`, num)
                    setFaxes((prevFaxes) => {
                      const newFaxes = [...prevFaxes]
                      newFaxes[index] = num
                      return newFaxes
                    })
                  }}
                  value={field.fax}
                  className='flex-[1_0_230px] capitalize'
                />
              </RowContainer>
              <div className='w-full flex justify-end '>
                <BasicButton
                  label='Remove'
                  color='error'
                  variant='secondary'
                  size='xs'
                  onClick={() => {
                    remove(index)
                    setTelephones((prevPhones) => {
                      const newPhones = [...prevPhones]
                      newPhones.splice(index, 1)
                      return newPhones
                    })
                    setFaxes((prevFaxes) => {
                      const newFaxes = [...prevFaxes]
                      newFaxes.splice(index, 1)
                      return newFaxes
                    })
                    setCells((prevCells) => {
                      const newCells = [...prevCells]
                      newCells.splice(index, 1)
                      return newCells
                    })
                  }}
                  disabled={index === 0}
                />
              </div>
            </div>
          ))}
        </div>
      </MainContainer>
    </FormProvider>
  )
}
