import { type ColumnDef } from '@tanstack/react-table'
import { type SoilSampleRequest } from 'types'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import moment from 'moment/moment'

export const ssrColumns: Array<ColumnDef<SoilSampleRequest>> = [
  {
    id: 'farmer_id',
    accessorFn: (row) => row.farmer_id,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='lowercase'>{row.getValue('farmer_id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Farmer ID' />
  },
  {
    id: 'ssr_id',
    accessorFn: (row) => row.ssr_id,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='capitalize'>{row.getValue('ssr_id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='SSR ID' />
  },
  {
    id: 'farm_name',
    accessorFn: (row) => row.farm_name,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => <div className='capitalize'>{row.getValue('farm_name')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='farm name' />
  },
  {
    id: 'farm_id',
    accessorFn: (row) => row.farm_id,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='capitalize'>{row.getValue('farm_id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='farm ID' />
  },
  {
    id: 'location',
    accessorFn: (row) => row.location,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => <div className='capitalize'>{row.getValue('location')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='location' />
  },
  {
    id: 'plough_season',
    accessorFn: (row) => row.plough_season,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='capitalize'>{row.getValue('plough_season')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Plough Season' />
  },
  {
    id: 'create_date',
    accessorFn: (row) => row.create_date,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => (
      <div className='capitalize'>{moment(row.getValue('create_date')).format('YYYY-MM-DD')}</div>
    ),
    header: ({ column }) => <DataTableColumnHeader column={column} title='Date of Creation' />
  }
]
