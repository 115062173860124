import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface RejectSoilSampleDTO {
  user_id: number | null | undefined
  farm_id: number
  farmer_id: number
  merchant_id: number
  ssr_id: number
  comment: string
}

export const rejectSsr = async (params: RejectSoilSampleDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/ssr/reject', params)
}
