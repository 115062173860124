import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export interface GetPropelledSprayers {
  inventory_id: number
  inventory_type: number
  nozel_output: number
  boom: number
  hoses: number
  pump_o_rings: number
  tank: number
  gasket: number
  regultors_guages_controls: number
  belts: number
  propeller_and_agitation: number
  tires: number
  filters: number
  dispenser: number
  measuring_device: number
  gears: number
  lights: number
  starting: number
  gauges: number
  cooling_leakages: number
  fuel_leakages: number
  lubrication_leakages: number
  hydraulic_oil_leakages: number
  transmission_leakages: number
  smoke_color: number
  oil_check: number
  greasing: number
  pto: number
  gear_shifting: number
  lifting_and_releasing: number
  hitch_pins: number
  bearings: number
  mirrors: number
  engine_stalling: boolean
  positioning_and_draft_control: boolean
  breaking_system: boolean
  steering_system: boolean
  diff_loc: boolean
  result: boolean
  comments: string
  create_date: string
  last_update_by: number
  last_update_date: string
}

export const getPropelledSprayerChecklist = async (
  tractorId: number
): Promise<ApiResponse<GetPropelledSprayers>> => {
  return axios.get(`/assessment/selfpropelledsprayers?inventory_id=${tractorId}`)
}

export const useGetPropelledSprayerChecklist = (
  tractorId: number
): UseQueryResult<ApiResponse<GetPropelledSprayers>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['tractorChecklist'],
    queryFn: async () => getPropelledSprayerChecklist(tractorId)
  })
}
