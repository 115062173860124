import { AxiosError, type AxiosResponse } from 'axios'
import { getFarms } from 'features/officer/land-management/api/getFarms'
import { useState } from 'react'
import { type ApiResponse, type Farm } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'

interface UseSearchFarm {
  isLoading: boolean
  error: string | null
  searchFarm: (query: string) => Promise<void>
  data: Farm[] | null
}

export const useSearchFarm = (officerId: number | null | undefined): UseSearchFarm => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<Farm[] | null>(null)

  const searchFarm = async (query: string): Promise<void> => {
    setData(null)
    setError(null)
    setIsLoading(true)
    try {
      const response = await getFarms(query, officerId)
      if (response.status === 200) {
        const farms = response.data.filter((farm) => {
          return farm.status === 'pending'
        })
        if (farms.length === 0) {
          setData([])
        } else {
          setData(farms)
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        setError(
          mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        )
      } else {
        const errorMsg = err as Error
        setError(mapCmsErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { error, isLoading, searchFarm, data }
}
