import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Grant } from 'types'
import { type AxiosError } from 'axios'

// get all farmer grants
export const getFarmerGrants = async (
  farmerId: number | null | undefined
): Promise<ApiResponse<Grant>> => {
  return axios.get(`/grant?farmer_id=${farmerId ?? ''}`)
}

export const useGetFarmerGrants = (
  farmerId: number | null | undefined
): UseQueryResult<ApiResponse<Grant>, AxiosError> => {
  return useQuery({
    queryKey: ['grants', farmerId],
    queryFn: async () => getFarmerGrants(farmerId),
    enabled: farmerId != null
  })
}
