import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type Weeding } from 'types'

export const getWeeding = async (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): Promise<ApiResponseWithObject<Weeding>> => {
  return axios.get(`/production/weeding?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}

export const useGetWeeding = (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): UseQueryResult<ApiResponseWithObject<Weeding>, AxiosError> => {
  return useQuery({
    queryKey: ['weeding', farmerId, cp_id],
    queryFn: async () => getWeeding(farmerId, cp_id),
    enabled: farmerId != null && cp_id != null
  })
}
