export const villageList = [
  'ARTISIA',
  'B. VISSER',
  'BDF MOGODITSHANE',
  'BEETSHA',
  'BERE',
  'BETESANKWE',
  'BETHEL',
  'BOATLANAME',
  'BOBONONG',
  'BODIBENG',
  'BOGOGOBO',
  'BOKAA',
  'BOKSPITS',
  'BONWAPITSE',
  'BOROBADILEPE',
  'BOROLONG',
  'BOROTSI',
  'BOTALAOTE',
  'BOTLHAPATLOU',
  'BOTLHATLOGO',
  'BRAY',
  'BUTALE',
  'CENTRAL KGALAGADI GAME RESERVE',
  'CHADIBE',
  'CHANGATE',
  'CHANOGA',
  'CHARLES HILL',
  'CHARLES SHELDON',
  'CHOBOKWANE',
  'CHUKUMUCHU',
  'DAGWI',
  'DAMOCHOJENA',
  'DECT ETIN',
  'DEKAR',
  'DIABO',
  'DIBETE STATION',
  'DIGAWANA',
  'DIKGATLHONG',
  'DIKGONNYE',
  'DIKHUKHUNG',
  'DIKWIDIDI',
  'DILORO',
  'DIMAJWE',
  'DINATSHANA',
  'DIPHUDUHUDU',
  'DIPOTSANA',
  'DITLADI',
  'DITLHARAPA',
  'DITSHEGWANE',
  'DITSHUKUDU',
  'DOORN LAAGTE',
  'DOVEDALE',
  'DUKWI',
  'DUTLWE',
  'D-KAR',
  'EAST HANAHAI',
  'ERETSHA',
  'ETSHA 1',
  'ETSHA 13',
  'ETSHA 6',
  'FRANCISTOWN',
  'GABANE',
  'GABORONE',
  'GACHIBANA',
  'GAKGATLA',
  'GAKUTLO',
  'GAMABUO',
  'GAMAJALELA',
  'GAMBULE',
  'GAMODUBU',
  'GANI',
  'GASITA',
  'GATHWANE',
  'GHANZI',
  'GOJWANE',
  'GONUTSUGA',
  'GOO-SEKGWENG',
  'GOOD HOPE',
  'GOOTAU',
  'GOSHWE',
  'GROOTE LAAGTE',
  'GUDINGWA',
  'GULUBANE',
  'GUMARE',
  'GUNGWE',
  'GWETA',
  'HABU',
  'HANS METZLER',
  'HASKINS FARM',
  'HATSALATLADI',
  'HEBRON',
  'HILDAVALE',
  'HUKUNTSI',
  'HUNHUKWE',
  'IKOGA',
  'IKONGWE',
  'INALEGOLO',
  'ITHOLOKE',
  'J. RANSALL',
  'JACKALAS NO. 1',
  'JACKALAS NO. 2',
  'JAMATAKA',
  'JANKI JANKI',
  'JERUNG',
  'JOHANNES KACHEJOHN',
  'JOHN FARM',
  'JWANENG',
  'KACGAE',
  'KACHIKAU',
  'KAJAJA',
  'KALAKAMATI',
  'KALAMARE',
  'KANAKU',
  'KANG',
  'KANGWE',
  'KANYE',
  'KARAKUBISE',
  'KARENG',
  'KASANE',
  'KATABA',
  'KAUDWANE',
  'KAUXWHI',
  'KAVIMBA',
  'KAZUNGULA',
  'KEDIA',
  'KENG',
  'KGAGODI',
  'KGAKGE/MAKAKUNG',
  'KGARI',
  'KGOMODIATSHABA',
  'KGOMOKASITWA',
  'KGOPE',
  'KGORO',
  'KHAKHEA',
  'KHISA',
  'KHONKHWA',
  'KHUDUMELAPYE',
  'KHUIS',
  'KHURUTSHE',
  'KHWAWA',
  'KHWEE',
  'KOBOJANGO',
  'KODIBELENG',
  'KOKONG',
  'KOKOTSHA',
  'KOLONKWANE',
  'KOMANA',
  'KOPONG',
  'KOTOLANAME',
  'KUBUNG',
  'KUDUMATSE',
  'KULE',
  'KUMAGA',
  'KUMAKWANE',
  'KUTAMOGREE',
  'KUTUKU',
  'KWENENG',
  'LECHENG',
  'LEFOKO',
  'LEHUTUTU',
  'LEJWANA',
  'LEKGOLOBOTLO',
  'LENTSWELEMORITI',
  'LENTSWELETAU',
  'LEOLOGANE',
  'LEPASHE',
  'LEPHEPHE',
  'LEPOKOLE',
  'LEPORUNG',
  'LERALA',
  'LESENEPOLE/MATOLWANE',
  'LESHIBITSE',
  'LESOMA',
  'LETLHAKANE',
  'LETLHAKENG',
  'LETSHOLATHEBE',
  'LOBATSE ESTATES',
  'LOBATSE',
  'LOGAGANE',
  'LOKGWABE',
  'LOROLWANA',
  'LORWANA',
  'LOSILAKGOKONG',
  'LOTLHAKANE WEST',
  'LOTLHAKANE',
  'MAAPE',
  'MABABE',
  'MABALANE',
  'MABELEAPUDI',
  'MABESEKWA',
  'MABOANE',
  'MABOLWE',
  'MABUDZANE',
  'MABULE',
  'MABUTSANE',
  'MACHANENG',
  'MADINGWANA',
  'MAFONGO/HOBONA',
  'MAGONO MAGONO',
  'MAGORIAPITSE',
  'MAGOTLHWANE',
  'MAHALAPYE',
  'MAHETLWE',
  'MAHOTSHWANE',
  'MAITENGWE',
  'MAJWANAADIPITSE',
  'MAJWANENG',
  'MAKALAMABEDI',
  'MAKALENG',
  'MAKE',
  'MAKOBO',
  'MAKOKWE',
  'MAKOPONG',
  'MAKUNDA',
  'MAKUTA',
  'MAKWATE',
  'MALAKA',
  'MALATSWAI',
  'MALESHE',
  'MALOKAGANYANE',
  'MALOLWANE',
  'MALOTWANA SIDING',
  'MALWELWE',
  'MAMBO',
  'MANALEDI',
  'MANDUNYANE',
  'MANTSHWABISI',
  'MANYANA',
  'MAOKANE',
  'MAPOKA',
  'MAPOSA',
  'MARALALENG',
  'MARAPONG',
  'MARATSHWANE',
  'MAROBELA',
  'MAROJANE',
  'MASIADIEME',
  'MASINGWANENG',
  'MASUKWANE',
  'MASUNGA',
  'MATEBELENG',
  'MATENGE',
  'MATHANGWANE',
  'MATHATHANE',
  'MATLAPANA',
  'MATLHAKO',
  'MATLHAKOLA',
  'MATOBO',
  'MATOPI',
  'MATSAUDI/SAKAPNAE',
  'MATSHELAGABEDI',
  'MATSILOJE',
  'MATSITAMA',
  'MAUBELO',
  'MAUN',
  'MAUNATLALA',
  'MBALAMBI',
  'MEDIE',
  'METLOBO',
  'METLOJANE',
  'METSIBOTLHOKO',
  'METSIMOTLHABE',
  'MHALAPITSA',
  'MIDDLEPITS',
  'MMADIKOLA',
  'MMADINARE',
  'MMAKGORI',
  'MMANKGODI',
  'MMANOKO',
  'MMANXOTAE',
  'MMAPHASHALALA',
  'MMASHORO',
  'MMATHETHE',
  'MMATHUBUDUKWANE',
  'MMATSETA',
  'MMATSHUMO',
  'MMEYA',
  'MMOPANE',
  'MMUTLANA',
  'MOCHUDI',
  'MODIPANE',
  'MOENG',
  'MOGAPI',
  'MOGAPINYANA',
  'MOGOBANE',
  'MOGODITSHANE',
  'MOGOJOGOJO',
  'MOGOME',
  'MOGOMOTHO',
  'MOGONONO',
  'MOGONYE',
  'MOGOROSI',
  'MOGWALALE',
  'MOHEMBO EAST',
  'MOHEMBO WEST',
  'MOIYABANA',
  'MOKATAKO',
  'MOKGACHA',
  'MOKGENENE',
  'MOKGOMANE',
  'MOKGWARE',
  'MOKHOMBA',
  'MOKHUNGWANA',
  'MOKOBENG',
  'MOKOBOXANE',
  'MOKOKWANA',
  'MOKOLODI',
  'MOKOSWANE',
  'MOKUBILO',
  'MOLALATAU',
  'MOLAPOWABOJANG',
  'MOLEPOLOLE',
  'MOLETE',
  'MOLETEMANE',
  'MOLOPO FARMS',
  'MONONG',
  'MONWANE',
  'MOOKANE',
  'MOPIPI',
  'MORALANE',
  'MOREMI',
  'MOREOMABELE',
  'MOREOMAOTO',
  'MOROKA',
  'MORWA',
  'MORWAMOSU',
  'MOSETSE',
  'MOSHANENG',
  'MOSHAWENG',
  'MOSHOBO',
  'MOSHOPHA',
  'MOSHUPA',
  'MOSOJANE',
  'MOSOLOTSHANE',
  'MOSU',
  'MOSWEU',
  'MOTLHABANENG',
  'MOTOKWE',
  'MOTOPI',
  'MOTSENTSHE',
  'MOTSHEGALETAU',
  'MOWANA',
  'MUCHINJE/MABELE',
  'MULAMBAKWENA',
  'MUSI',
  'NATA',
  'NATALE',
  'NCAANG',
  'NCOJANE',
  'NEW XADE',
  'NEW XANAGAS',
  'NGAMILAND DELTA',
  'NGARANGE',
  'NGWAPA',
  'NGWARE',
  'NGWATLE',
  'NGWATSAU',
  'NKANGE',
  'NLAKHWANE',
  'NOKANENG',
  'NSHAKASHOKWE',
  'NSHAKAZHOGWE',
  'NSWAZWI',
  'NTLHANTLHE',
  'NUMBER SEVEN',
  'NXAMASERE',
  'NXAUNXAU',
  'OLIPHANTS DRIFT',
  'OMAWENENO',
  'OODI',
  'ORAPA',
  'OTSE',
  'PAJE',
  'PALAPYE',
  'PALLA ROAD/DINOKWE',
  'PANDAMATENGA',
  'PAPATLO',
  'PARAKARUNGU',
  'PATAYAMATEBELE',
  'PHEPHENG/DRAAIHOEK',
  'PHIHITSHWANE',
  'PHITSHANE MOLOPO',
  'PHUDUHUDU',
  'PILANE STATION',
  'PILIKWE',
  'PITSANA-POTOKWE',
  'PITSANE SIDING',
  'PITSENG',
  'PITSHANE MOLOPO',
  'POLE',
  'POLOKA',
  'QABO',
  'QANGWA',
  'RADISELE',
  'RAKHUNA',
  'RALEKGETHO',
  'RAMAPHATLE',
  'RAMATLABAMA',
  'RAMOKGONAMI',
  'RAMOKGWEBANA',
  'RAMONAKA',
  'RAMOTLABAKI',
  'RAMOTSWA STATION/TAUNG',
  'RAMOTSWA',
  'RANAKA',
  'RAPPLES PAN',
  'RASESA',
  'RATHOLO',
  'ROBELELA',
  'SALAJWE',
  'SAMOCHEMA',
  'SANKUYO',
  'SATAU',
  'SEBINA',
  'SECHELE',
  'SEDIBENG',
  'SEFHARE',
  'SEFHOPHE',
  'SEFOPHE',
  'SEGWAGWA',
  'SEHERELELA',
  'SEHITHWA',
  'SEHUNOU',
  'SEKAKANGWE',
  'SEKHUTLANE',
  'SEKOMA',
  'SEKONDOMBORO',
  'SELEBI PHIKWE',
  'SELEKA',
  'SELOKOLELA',
  'SEMANE',
  'SEMBOYO',
  'SEMITWE',
  'SEMOLALE',
  'SENETE',
  'SENYAWE',
  'SEOLWANE',
  'SEPAKO',
  'SEPOPA',
  'SERINANE',
  'SERONGA',
  'SEROWE',
  'SERULE',
  'SESE',
  'SESUNG',
  'SHADISHADI',
  'SHAKAWE',
  'SHAKWE',
  'SHASHE BRIDGE',
  'SHASHE-MOOKE',
  'SHASHE/SEMOTSWANE',
  'SHEEP FARM',
  'SHOROBE',
  'SHOSHONG',
  'SIKWANE',
  'SIVIYA',
  'SOJWE',
  'SORILATHOLO',
  'SOWA TOWN',
  'STRUIZENDAM',
  'TAKATOKWANE',
  'TAMASANE',
  'TATI SIDING',
  'TAUPYE',
  'TEWANE',
  'THABALA',
  'THAMAGA',
  'THEBEPHATSHWA',
  'THEMASHANGA',
  'TLHANKANE',
  'TLHARESELEELE',
  'TLOANENG',
  'TLOKWENG',
  'TOBANE',
  'TOBERE',
  'TONOTA',
  'TOPISI',
  'TOROMOJA',
  'TOTENG',
  'TSABONG',
  'TSAMAYA',
  'TSAO',
  'TSETSEBJWE',
  'TSETSENG',
  'TSHANE',
  'TSHESEBE',
  'TSHIDILAMOLOMO',
  'TSHIMOYAPULA',
  'TSHOKATSHAA',
  'TSHOKWE',
  'TSHWAANE',
  'TSIENYANE/RAKOPS',
  'TSONYANE',
  'TSOOTSHA',
  'TSWAANE',
  'TSWAANENG',
  'TSWAGARE/LOTHOJE/LOKALANA',
  'TSWANYANENG',
  'TUBU',
  'TULI',

  'TUMASERA',
  'TUTUME',
  'UKWI',
  'VAALHOEK',
  'VUKWI',
  'WERDA',
  'WEST HANAHAI',
  'XAKAO',
  'XAXA',
  'XERE',
  'XHAUGA',
  'XHUMO',
  'ZAMBIA',
  'ZIMBABWE',
  'ZOROGA',
  'ZUTSWA',
  'ZWENSHAMBE'
]
