import { type Column } from '@tanstack/react-table'

import { Button } from '../ui'
import type React from 'react'
import { ArrowUpDown } from 'lucide-react'

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  title: string
}

// if you want a table header to have filter button
export function DataTableColumnHeader<TData, TValue>({
  column,
  title
}: DataTableColumnHeaderProps<TData, TValue>): React.ReactNode {
  return (
    <Button
      variant='ghost'
      className={'font-bold bg-card dark:hover:bg-second-background text-muted-foreground'}
      onClick={() => {
        column.toggleSorting(column.getIsSorted() === 'asc')
      }}
    >
      {title}
      <ArrowUpDown className='ml-2 h-4 w-4' />
    </Button>
  )
}
