/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import {
  browserPopupRedirectResolver,
  browserSessionPersistence,
  initializeAuth
} from 'firebase/auth'

export const API_URL = import.meta.env.VITE_API_URL as string

const SITE_KEY = import.meta.env.VITE_SITE_KEY as string

const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY as string

const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN as string

const FIREBASE_DATABASE_URL = import.meta.env.VITE_FIREBASE_DATABASE_URL as string

const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID as string

const FIREBASE_STORAGE_BUCKET = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET as string

const FIREBASE_SENDER_ID = import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID as string

const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID as string

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,

  authDomain: FIREBASE_AUTH_DOMAIN,

  databaseURL: FIREBASE_DATABASE_URL,

  projectId: FIREBASE_PROJECT_ID,

  storageBucket: FIREBASE_STORAGE_BUCKET,

  messagingSenderId: FIREBASE_SENDER_ID,

  appId: FIREBASE_APP_ID
}

export const app = initializeApp(firebaseConfig)

export const auth = initializeAuth(app, {
  persistence: browserSessionPersistence,
  popupRedirectResolver: browserPopupRedirectResolver
})

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(SITE_KEY),
  isTokenAutoRefreshEnabled: true
})
