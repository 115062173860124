import { BasicButton } from 'components/Elements/Button'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { Text } from 'components/Elements/Text/Text'
import { NotFound } from '../../../components/Errors/NotFound'
import { useAtom } from 'jotai'
import { farmerType } from '../components/stepper/stores'
import { LoanApplicationFormCompany } from '../components/stepper/company/LoanApplicationFormCompany'
import { LoanApplicationForm } from '../components/stepper/individual/LoanApplicationForm'

export const LoanApplication: React.FC = () => {
  const { farmer } = useFarmerStore()
  const flags = useFlags()
  const ldClient = useLDClient()
  const [famerType] = useAtom(farmerType)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.loan === false || farmer?.class_id !== 'TAXPAYER') {
    return <NotFound />
  }

  const saveAndExist = (): void => {
    // save and exit logic
    console.log('Save button clicked!')
  }

  return (
    <div className='w-full flex flex-col justify-center p-0 px-2 md:p-8'>
      <div
        className={
          'w-full max-w-[1500px] flex flex-col mx-auto gap-4 md:gap-8 md:rounded-md lg:rounded-xl p-4 py-6 sm:p-6 md:px-12'
        }
      >
        <div className={'flex justify-between'}>
          <Text size={'xLarge'}>
            Loan Application -{' '}
            {famerType?.farmerType === undefined ? (
              <Text size={'large'} variant={'bodyTextLight'}>
                New Application{' '}
              </Text>
            ) : (
              <Text size={'large'} variant={'bodyTextLight'} className={'ml-4 mb-4'}>
                {famerType?.farmerType} Farmer
              </Text>
            )}
          </Text>
          <BasicButton
            label={'save'}
            onClick={saveAndExist}
            size={'xs'}
            customSx={{
              padding: '0.3rem, 0.8rem',
              color: 'white',
              backgroundColor: 'var(--color-primary)'
            }}
            textColor={'var(--color-white)'}
          />
        </div>
      </div>
      <div className='w-full max-w-[1500px] flex flex-col mx-auto'>
        {farmer?.farmer_type === 1 ? (
          <LoanApplicationForm />
        ) : (
          farmer?.farmer_type === 3 && <LoanApplicationFormCompany />
        )}
      </div>
    </div>
  )
}
