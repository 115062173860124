import { axios } from 'lib/axios'
import { type ApiResponseWithObject } from 'types'
import { type ApplicationHeader } from '../types/individual'
import { type CompanyLoanType } from '../types/company'

export const createCompanyLoan = async (
  params: CompanyLoanType
): Promise<ApiResponseWithObject<ApplicationHeader>> => {
  return axios.post('/loan/company', params)
}
