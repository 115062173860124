/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type React from 'react'

import { BasicButton } from 'components/Elements/Button'
import { CustomDatePicker, InputField } from 'components/Form'
import {
  CategoryLabel,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper'
import { Text } from '../../../../../components/Elements/Text'
import { array, coerce, number, object, type TypeOf } from 'zod'
import { FormProvider, useFieldArray, useFormContext, type UseFormReturn } from 'react-hook-form'
import { useAtom } from 'jotai'
import { companyLoanStore } from '../stores'
import dayjs from 'dayjs'

interface StepProps {
  methods: UseFormReturn<CompanyOtherLoansInput>
}

const otherLoans = object({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  ref_no: number({ invalid_type_error: 'This should be a number' }).nonnegative(),
  date_aqcuired: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  int_rate: number({ invalid_type_error: 'This should be a number' }).nonnegative(),
  installment: number({ invalid_type_error: 'This should be a number' }).nonnegative(),
  amount_approved: number({ invalid_type_error: 'This should be a number' }).nonnegative(),
  outstanding_balance: number({ invalid_type_error: 'This should be a number' }).nonnegative(),
  arrears: number({ invalid_type_error: 'This should be a number' }).nonnegative(),
  loan_duration: number({ invalid_type_error: 'This should be a number' }).nonnegative()
})

export const schemaCOL = object({
  loans_with_other_institution: array(otherLoans).nonempty('Field is required')
})

export type CompanyOtherLoansInput = TypeOf<typeof schemaCOL>
export const CompanyOtherLoans: React.FC<StepProps> = ({ methods }) => {
  const { control } = useFormContext<CompanyOtherLoansInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'loans_with_other_institution'
  })
  const [loanData, setLoanData] = useAtom(companyLoanStore)

  const addOtherLoans = (): void => {
    append({
      ref_no: 0,
      loan_duration: 0,
      amount_approved: 0,
      arrears: 0,
      date_aqcuired: new Date(),
      installment: 0,
      int_rate: 0,
      outstanding_balance: 0
    })
  }

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <div className={'w-full flex flex-col gap-8'}>
          <div className={'flex items-center justify-between'}>
            <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
              History Of Other Loans
            </Text>

            <BasicButton
              label={'Add new'}
              variant={'primary'}
              size={'xs'}
              onClick={addOtherLoans}
            />
          </div>

          {fields.map((field, index) => (
            <div key={index} id={`loan_form_${index + 1}`} className={'w-full mb-8'}>
              <CategoryLabel label={`Loan ${index + 1}`} />
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px]  capitalize'
                  label={'Reference & Account Numbers '}
                  type={'number'}
                  defaultValue={loanData?.loans_with_other_institutions_ndb?.[index]?.ref_no}
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]?.ref_no == null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]?.ref_no
                      ?.message ?? ''
                  }
                  register={methods.register(`loans_with_other_institution.${index}.ref_no`, {
                    valueAsNumber: true
                  })}
                />

                <CustomDatePicker
                  className='flex-[1_0_230px] capitalize'
                  label={'Loan Start Date'}
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]
                        ?.date_aqcuired == null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]?.date_aqcuired
                      ?.message ?? ''
                  }
                  defaultValue={
                    loanData?.loans_with_other_institutions_ndb?.[index]?.date_aqcuired ===
                    undefined
                      ? undefined
                      : dayjs(loanData?.loans_with_other_institutions_ndb?.[index]?.date_aqcuired)
                  }
                  disableFuture
                  name={'date_aqcuired'}
                  control={control}
                />
              </RowContainer>
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'Loan Duration (years)'}
                  type={'number'}
                  defaultValue={loanData?.loans_with_other_institutions_ndb?.[index]?.loan_duration}
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]
                        ?.loan_duration == null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]?.loan_duration
                      ?.message ?? ''
                  }
                  register={methods.register(
                    `loans_with_other_institution.${index}.loan_duration`,
                    {
                      valueAsNumber: true
                    }
                  )}
                />

                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'interest rate(%)'}
                  type={'number'}
                  defaultValue={loanData?.loans_with_other_institutions_ndb?.[index]?.int_rate}
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]?.int_rate ==
                      null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]?.int_rate
                      ?.message ?? ''
                  }
                  register={methods.register(`loans_with_other_institution.${index}.int_rate`, {
                    valueAsNumber: true
                  })}
                />
              </RowContainer>
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'installment(BWP)'}
                  type={'number'}
                  defaultValue={loanData?.loans_with_other_institutions_ndb?.[index]?.installment}
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]?.installment ==
                      null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]?.installment
                      ?.message ?? ''
                  }
                  register={methods.register(`loans_with_other_institution.${index}.installment`, {
                    valueAsNumber: true
                  })}
                />
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'amount approved(BWP)'}
                  type={'number'}
                  defaultValue={
                    loanData?.loans_with_other_institutions_ndb?.[index]?.amount_approved
                  }
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]
                        ?.amount_approved == null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]?.amount_approved
                      ?.message ?? ''
                  }
                  register={methods.register(
                    `loans_with_other_institution.${index}.amount_approved`,
                    {
                      valueAsNumber: true
                    }
                  )}
                />
              </RowContainer>
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'outstanding balance(BWP)'}
                  type={'number'}
                  defaultValue={
                    loanData?.loans_with_other_institutions_ndb?.[index]?.amount_approved
                  }
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]
                        ?.outstanding_balance == null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]
                      ?.outstanding_balance?.message ?? ''
                  }
                  register={methods.register(
                    `loans_with_other_institution.${index}.outstanding_balance`,
                    {
                      valueAsNumber: true
                    }
                  )}
                />
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'arrears(BWP)'}
                  type={'number'}
                  defaultValue={loanData?.loans_with_other_institutions_ndb?.[index]?.arrears}
                  error={
                    !(
                      methods.formState.errors.loans_with_other_institution?.[index]?.arrears ==
                      null
                    )
                  }
                  helperText={
                    methods.formState.errors.loans_with_other_institution?.[index]?.arrears
                      ?.message ?? ''
                  }
                  register={methods.register(`loans_with_other_institution.${index}.arrears`, {
                    valueAsNumber: true
                  })}
                />
              </RowContainer>

              <div className='w-full flex justify-end '>
                <BasicButton
                  label={'Remove'}
                  color='error'
                  variant={'secondary'}
                  size={'xs'}
                  onClick={() => {
                    remove(index)
                  }}
                  disabled={index === 0}
                />
              </div>
            </div>
          ))}
        </div>
      </MainContainer>
    </FormProvider>
  )
}
