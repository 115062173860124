import type React from 'react'
import { useParams } from 'react-router-dom'
import { useGetLeased } from '../../api/getLeased'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { Loader } from 'components/ui/loader'
import { NotFound } from 'components/Errors/NotFound'
import { TabView } from 'components/App/Layout'
import dayjs from 'dayjs'

export const LeaseHistory: React.FC = () => {
  const { farmerId } = useParams()
  const { data: leases, isLoading, error } = useGetLeased(farmerId)

  if (isLoading) {
    return (
      <div className={'w-full grid place-items-center h-full flex-1'}>
        <Loader size={36} />
      </div>
    )
  }

  if (error != null || leases == null) {
    return <NotFound />
  }

  return (
    <TabView>
      <div className={'bg-background w-full flex flex-col gap-2 sm:gap-4 p-4'}>
        <Table>
          <TableCaption>Farm Lease History.</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className='font-bold text-color-text'>Lease ID</TableHead>
              <TableHead className='font-bold text-color-text'>Lessee ID</TableHead>
              <TableHead className='font-bold text-color-text'>Lessee Name</TableHead>
              <TableHead className='font-bold text-color-text'>Farm Name</TableHead>
              <TableHead className='font-bold text-color-text'>Hectares Leased</TableHead>
              <TableHead className='font-bold text-color-text'>Start Date</TableHead>
              <TableHead className='font-bold text-color-text'>Expiry Date</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {leases.data.map((lease) => (
              <TableRow key={lease.lease_id} role={'listitem'} className={'hover:bg-muted-hover'}>
                <TableCell className='font-medium'>{lease.lease_id}</TableCell>
                <TableCell className='font-medium'>{lease.dest_farmer_id}</TableCell>
                <TableCell className='font-medium'>{lease.dest_farmer_firstname}</TableCell>
                <TableCell className='font-medium'>{lease.farm_name}</TableCell>
                <TableCell className='font-medium'>{lease.leased_size}</TableCell>
                <TableCell className='font-medium'>
                  {dayjs(lease.commencement_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell className='font-medium'>
                  {lease.new_termination_date != null
                    ? dayjs(lease.new_termination_date).format('DD-MM-YYYY')
                    : dayjs(lease.expiry_date).format('DD-MM-YYYY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TabView>
  )
}
