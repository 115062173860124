import { type Lessee } from 'types'
import { create } from 'zustand'

interface UseLeaseStore {
  farmerID: string | null
  setFarmerID: (farmerId: string | null) => void
  lesseeDetails: Lessee | null
  setLessorDetails: (farmerDetails: Lessee | null) => void
}

export const useLeaseStore = create<UseLeaseStore>()((set) => ({
  farmerID: null,
  lesseeDetails: null,
  setFarmerID: (farmerID: string | null) => {
    set((state) => ({ ...state, farmerID }))
  },
  setLessorDetails: (farmerDetails: Lessee | null) => {
    set((state) => ({ ...state, lesseeDetails: farmerDetails }))
  }
}))
