import { type ApiResponse, type TitleDeedFarm } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getLISDetails = async (
  deedNumber: string,
  id: string
): Promise<ApiResponse<TitleDeedFarm>> => {
  return axios.get(`farm/lis-land?DEED_NUMBER=${deedNumber}&ID=${id}&FARMER_TYPE=1`)
}

export const useGetLISDetails = (
  deedNumber: string,
  id: string
): UseQueryResult<ApiResponse<TitleDeedFarm>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farm leases', deedNumber],
    queryFn: async () => getLISDetails(deedNumber, id)
  })
}
