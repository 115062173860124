import { type ApiResponse, type Lease } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getLease = async (
  leaseId: string | number | null | undefined
): Promise<ApiResponse<Lease>> => {
  return axios.get(`/lease?LEASE_ID=${leaseId ?? ''}`)
}

export const useGetLease = (
  leaseId: string | number | null | undefined
): UseQueryResult<ApiResponse<Lease>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: [`lease-${leaseId ?? ''}`, leaseId],
    queryFn: async () => getLease(leaseId),
    enabled: leaseId != null
  })
}
