import type React from 'react'
import {
  ProductionScheduleCompany,
  type ProductionScheduleCompanyInput,
  schemaPSC
} from './ProductionScheduleCompany'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { type SubmitHandler } from 'react-hook-form'
import { useAtom } from 'jotai/index'
import { companyLoanStore } from '../stores'
import LoanButtonGroup from '../../generic/form/LoanButtonGroup'
import { type LoanStepperProps } from '../../../types'

export const ProductionScheduleCompanyBody: React.FC<LoanStepperProps> = ({
  className,
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const onSubmitPSC: SubmitHandler<ProductionScheduleCompanyInput> = (
    data: ProductionScheduleCompanyInput
  ) => {
    setLoanData((prev) => ({
      ...prev!,
      production_history: data.productionSchedule
    }))
    onNext()
  }
  return (
    <FormWithStepper<ProductionScheduleCompanyInput, typeof schemaPSC>
      className={'flex flex-col p-4 flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmitPSC}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schemaPSC}
    >
      {(methods) => (
        <>
          <ProductionScheduleCompany methods={methods} />
          <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
            <LoanButtonGroup onBack={onBack} onSubmit={methods.handleSubmit(onSubmitPSC)} />
          </div>
        </>
      )}
    </FormWithStepper>
  )
}
