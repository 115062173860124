import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import {
  type AcceptFarmDTO,
  acceptOrRejectFarm
} from 'features/officer/land-management/api/acceptFarm'
import { useState } from 'react'
import { type ApiResponse, type Farm } from 'types'

interface Response {
  status: boolean
  reference: string
}

interface UseAcceptOrRejectFarm {
  isLoading: boolean
  error: string | null
  acceptFarm: () => void
  rejectFarm: () => void
  response: Response | null
}

export const useAcceptOrRejectFarm = (
  farm: Farm,
  officerId: number | null | undefined,
  rejectReason = ''
): UseAcceptOrRejectFarm => {
  const [error, setError] = useState<string | null>(null)
  const [response, setResponse] = useState<Response | null>(null)

  const acceptFarmMutation = useMutation({
    mutationFn: async (acceptDTO: AcceptFarmDTO) => {
      return acceptOrRejectFarm(farm.farm_id, acceptDTO)
    }
  })

  const rejectFarmMutation = useMutation({
    mutationFn: async (acceptDTO: AcceptFarmDTO) => {
      return acceptOrRejectFarm(farm.farm_id, acceptDTO)
    }
  })

  const acceptFarm = (): void => {
    setError(null)
    setResponse(null)

    acceptFarmMutation.mutate(
      {
        LAST_UPDATED_BY: officerId,
        FARMER_ID: farm.farmer_id,
        STATUS: 1,
        MESSAGE: 'accepted'
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setResponse({
              status: true,
              reference: result?.data[0]?.file_reference
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errMsg = err?.response as AxiosResponse<ApiResponse<[]>>
            setError(errMsg.data.message)
          } else {
            const errMsg = err as Error
            setError(errMsg.message)
          }
        }
      }
    )
  }

  const rejectFarm = (): void => {
    setError(null)
    setResponse(null)

    rejectFarmMutation.mutate(
      {
        LAST_UPDATED_BY: officerId,
        FARMER_ID: farm.farmer_id,
        STATUS: 2,
        MESSAGE: rejectReason
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setResponse({
              status: true,
              reference: result?.data[0]?.file_reference
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errMsg = err?.response as AxiosResponse<ApiResponse<[]>>
            setError(errMsg.data.message)
          } else {
            const errMsg = err as Error
            setError(errMsg.message)
          }
        }
      }
    )
  }

  return {
    isLoading: acceptFarmMutation.isLoading || rejectFarmMutation.isLoading,
    error,
    rejectFarm,
    acceptFarm,
    response
  }
}
