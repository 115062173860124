import type React from 'react'
import { Box, TabView } from 'components/App/Layout'
import { AvatarFallback, Avatar, Text, AvatarImage, Button } from 'components/ui'
import { ArrowLeft } from 'lucide-react'
import farmerIcon from 'assets/logos/farmer.png'
import { useNavigate } from 'react-router-dom'
import { useOfficerStore } from '../../../stores/useOfficerStore'
import { Info } from '../components/Info'

export const OfficerProfile: React.FC = () => {
  const navigate = useNavigate()
  const { officer } = useOfficerStore()

  const handleBack = (): void => {
    navigate(-1)
  }
  return (
    <Box>
      <div className={'flex items-center gap-2 mt-2 sm:mt-4'}>
        <Button
          onClick={handleBack}
          variant={'ghost'}
          className={
            'bg-transparent gap-2 py-1 text-[clamp(1.3rem,4vw,1.5rem)] leading-[70%] font-semibold capitalize'
          }
        >
          <ArrowLeft />
        </Button>
        <Text size={'large'}>My profile</Text>
      </div>

      <TabView>
        <div className={'flex gap-4 items-center'}>
          <Avatar className={'h-[clamp(3rem,4vw,4rem)] w-[clamp(3rem,4vw,4rem)]'}>
            <AvatarImage src={farmerIcon} />
            <AvatarFallback>
              <Text size={'medium'} className='fa-solid fa-user text-success'></Text>
            </AvatarFallback>
          </Avatar>
          <div className={'flex flex-col'}>
            <Text size={'small'} className={'capitalize'}>
              Officer ID
            </Text>
            <Text size={'medium'} variant={'primary'}>
              {officer?.user_id}
            </Text>
          </div>
        </div>

        <div className={'flex flex-col border-y border-input py-4'}>
          <Text size={'medium'} variant={'muted'}>
            Personal Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'Forename/S'} name={officer?.firstname ?? ''} />
            <Info label={'MiddleName'} name={officer?.middlename ?? ''} />
            <Info label={'Surname'} name={officer?.surname ?? ''} />
          </div>
        </div>

        <div className={'flex flex-col'}>
          <Text size={'medium'} variant={'muted'}>
            Location Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'district'} name={officer?.district_name.toLowerCase() ?? ''} />
            <Info label={'sub district'} name={officer?.sub_district_name ?? ''} />
            <Info label={'extension area'} name={officer?.extension_area_name ?? ''} />
          </div>
        </div>

        <div className={'flex flex-col'}>
          <Text size={'medium'} variant={'muted'}>
            Contact Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'email address'} name={officer?.email.toLowerCase() ?? ''} />
            <Info label={'phone'} name={officer?.contact ?? ''} />
          </div>
        </div>
      </TabView>
    </Box>
  )
}
