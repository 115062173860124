import { useGetSSA } from 'features/farmer/ssr/api/getSSA'
import { useGetSSR } from 'features/farmer/ssr/api/getSSR'
import { SSRCard } from 'features/farmer/ssr/components/SSRCard'
import type React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFarmerStore } from 'stores/useFarmerStore'
import type { Farm, Lease } from 'types'
import { Button, Dialog, DialogContent, Loader, Text } from 'components/ui'
import { TabView } from 'components/App/Layout'
import { CircleOff, FileInput } from 'lucide-react'
import { NewSSR } from '../components/new-ssr/NewSSR'

interface WithFarm {
  farm: Farm
  lease?: never
}

interface WithLease {
  farm: Farm
  lease: Lease
}

type FarmerSoilResultsProps = WithFarm | WithLease

export const FarmerSoilResults: React.FC<FarmerSoilResultsProps> = ({ farm, lease }) => {
  const [open, setOpen] = useState(false)
  const { farmId } = useParams<{ farmId: string }>()
  const { leaseId } = useParams<{ leaseId: string }>()
  const { farmer } = useFarmerStore()

  const handleOpenDialog = (): void => {
    setOpen(true)
  }
  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  const {
    isLoading: isLoadingSSR,
    isInitialLoading,
    data: ssr,
    error: ssrError
  } = useGetSSR(
    lease?.farm_id !== undefined ? lease.farm_id.toString() : farmId,
    farmer?.farmer_id,
    leaseId
  )
  const {
    isInitialLoading: isLoadingSSA,
    data: ssa,
    error: ssaError
  } = useGetSSA(farmId, farmer?.farmer_id, ssr?.data[0].ssr_id)

  if (isLoadingSSR || isLoadingSSA || isInitialLoading) {
    return (
      <TabView>
        <div className={'grid place-items-center'}>
          <Loader />
        </div>
      </TabView>
    )
  }

  if (ssrError?.response?.status === 404 || ssr?.data === undefined) {
    return (
      <TabView>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>Farm Information</Text>
          <Button
            variant={'warning'}
            size={'xs'}
            onClick={handleOpenDialog}
            disabled={farm.leased_size === farm.size}
          >
            Request Results
            <FileInput size={'12'} />
          </Button>
        </div>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <CircleOff />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            No Soil Sample Results Available
          </Text>
        </div>

        <Dialog
          key={'open-ssr'}
          open={open}
          defaultOpen={false}
          modal
          onOpenChange={handleCloseDialog}
        >
          <DialogContent key={'new-ssr'} className={'max-w-[80%] md:max-w-[40%] lg:max-w-[25%]'}>
            <NewSSR lease={lease} farm={farm} cancel={handleCloseDialog} />
          </DialogContent>
        </Dialog>
      </TabView>
    )
  }

  return (
    <TabView>
      <Text size={'medium'}>Soil Sample Results</Text>
      <SSRCard
        key={ssr?.data[ssr?.data.length - 1]?.ssr_id}
        ssr={ssr?.data[ssr?.data.length - 1]}
        ssa={ssa?.data[0]}
      />
    </TabView>
  )
}
