import {
  Button,
  Card,
  DropdownMenu,
  DropdownMenuTrigger,
  Loader,
  Text
} from '../../../../../components/ui'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type InventoryTransfer } from '../../types'
import { MoreVertical } from 'lucide-react'
import { AcceptRejectTransfer } from './AcceptRejectTransfer'
import { useGetFarmer } from '../../../land-management/api/getFarmer'

interface TransferRequestCardProps {
  transferRequest: InventoryTransfer
}

export const TransferRequestCard: React.FC<TransferRequestCardProps> = ({ transferRequest }) => {
  const [farmer, setFarmer] = useState('')

  const { data, isLoading } = useGetFarmer(transferRequest.origin_farmer_id)

  useEffect(() => {
    if (data !== null) {
      const firstname = data?.data?.firstname ?? ''
      const surname = data?.data?.surname ?? ''
      const farmerName = `${firstname} ${surname}`
      setFarmer(farmerName)
    }
  }, [data])

  if (isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <Card
      className={`md:max-w-[500px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 transition-shadow duration-200 ease-in-out transform`}
    >
      <div className={'absolute top-2 right-2'}>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size={'icon'} variant='icon' className='h-8 w-8 p-0'>
              <span className='sr-only'>Open menu</span>
              <MoreVertical className='h-5 w-5' />
            </Button>
          </DropdownMenuTrigger>
          <AcceptRejectTransfer transferRequest={transferRequest} />
        </DropdownMenu>
      </div>

      <Text className={'text-center'} size={'medium'} variant={'success'}>
        Inventory Transfer Request
      </Text>
      <div className={'w-full flex items-center justify-between gap-4'}>
        <div className={'flex items-center gap-4'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            From
          </Text>
          <Text className={'capitalize'} size={'medium'}>
            {farmer ?? ''}
          </Text>
        </div>
      </div>

      <div className={'flex items-center gap-4'}>
        <div className={'flex items-center gap-4'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            Registration/Serial Number
          </Text>
          <Text className={'capitalize'} size={'medium'}>
            {transferRequest.reg_number}
          </Text>
        </div>
      </div>
    </Card>
  )
}
