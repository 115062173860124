import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type NumberExists } from '../types'

export interface VerifyNumberDTO {
  CONTACT: string
}

export const verifyNumber = async (params: VerifyNumberDTO): Promise<ApiResponse<NumberExists>> => {
  return axios.get(`/user/checkcontact?USER_ROLE=1000&CONTACT=${params.CONTACT.replace('+', '')}`)
}
