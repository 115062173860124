import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreateThreshingDTO {
  cp_id: number | null | undefined
  method: string
  user_id: number | null | undefined
  farmer_id: number | null | undefined
}

export const createThreshing = async (params: CreateThreshingDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/production/threshing', params)
}
