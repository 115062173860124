import { useLeaseStore } from 'features/farmer/land-management/stores/leaseStore'
import type React from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { object, string } from 'zod'
import { useLeaseFarm } from '../../hooks/useLeaseFarm'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useToast } from '../../../../../components/ui/use-toast'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from 'components/ui'

const schema = object({
  farmerId: string().min(7, 'Farmer ID is required').regex(/[0-9]/, 'farmer id should be a number')
})

interface LesseeProps {
  className?: string
  next: () => void
  cancel: () => void
}

export const Lessee: React.FC<LesseeProps> = ({ className, next, cancel }) => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const { searchLessee, isLoading } = useLeaseFarm()
  const { setFarmerID } = useLeaseStore()

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      farmerId: ''
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    if (farmer?.farmer_id.toString() === data.farmerId) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Cannot lease to yourself.'
      })
    } else {
      setFarmerID(data.farmerId)
      void searchLessee(data.farmerId, next)
    }
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={'farmerId'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormDescription className={'text-md text-center'}>
                Enter the Farmer ID of the farmer you want to lease your land to.
              </FormDescription>
              <FormLabel>Farmer ID</FormLabel>
              <FormControl>
                <Input
                  placeholder='Farmer ID'
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button disabled={isLoading} variant={'secondary'} className={'w-1/2'} onClick={cancel}>
            Cancel
          </Button>
          <Button disabled={isLoading} loading={isLoading} className={'w-1/2'} type={'submit'}>
            Next
          </Button>
        </div>
      </form>
    </Form>
  )
}
