import { useCroppingPlanStore } from '../../../stores/useCroppingPlanStore'
import { useGetCroppingPlan, useGetIndividualHeader } from '../../../api/header/getHeader'
import { Loader, Text } from 'components/ui'
import { Separator } from '../../../../../../components/ui/separator'
import type React from 'react'
import { Error } from '../../../../../../components/Errors/Error'
import {
  getChemicalPestMethod,
  getManualPestStageValue,
  getManualWeedApplicationStageValue,
  getManualWeedMethodValue
} from '../../../utils/functions'
import { StepperFooter } from '../Footer'

export const CroppingPlanReview: React.FC = () => {
  const { header } = useCroppingPlanStore()
  const {
    data: apiHeader,
    isInitialLoading: apiIsLoading,
    error: headerError
  } = useGetIndividualHeader(header?.cropping_plan_id, header?.farmer_id)

  const {
    data: apiCroppingPlan,
    isInitialLoading: croppingPlanIsLoading,
    error: croppingPlanError
  } = useGetCroppingPlan(header?.cropping_plan_id, header?.farmer_id)

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading || croppingPlanIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (headerError != null || croppingPlanError != null) {
    if (headerError?.response?.status !== 404 || croppingPlanError?.response?.status !== 404) {
      return <Error />
    }
  }

  const notComplete =
    apiCroppingPlan?.data.seed_type == null ||
    apiCroppingPlan?.data?.tillage_method == null ||
    apiCroppingPlan?.data?.crop_id == null

  return (
    <div className={'w-full flex flex-col gap-6 sm:gap-8 py-8 px-6 sm:p-8 md:p-12 rounded-md'}>
      <Text variant={'primaryLight'} size={'xLarge'}>
        Cropping Plan Review
      </Text>

      {/* Cropping Plan Header */}
      {apiCroppingPlan?.data?.hectarage != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Crop & Hectarage
          </Text>

          <div className={'flex items-center gap-4'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Crop Name</Text>
              <Text size={'medium'}>{header?.crop_name}</Text>
            </div>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Hectarage</Text>
              <Text size={'medium'}>{apiHeader?.data[0].hectarage}</Text>
            </div>
          </div>
          <Separator />
        </section>
      ) : null}

      {/*  Seeding */}
      {apiCroppingPlan?.data?.seed_type != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Seeding
          </Text>

          <div className={'flex items-center gap-4'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Seed Variety</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.seed_variety}</Text>
            </div>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Seed Type</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.seed_type}</Text>
            </div>
          </div>

          <Text className={'text-gray-400'} size={'medium'} variant={'colorText'}>
            * the total cost of seeds was calculated using the maximum sowing rate.
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Anticipated Production</Text>
              <Text size={'medium'}>
                {apiCroppingPlan?.data?.target_yield}{' '}
                {apiCroppingPlan?.data?.target_yield == null
                  ? 'tonne(s)'
                  : apiCroppingPlan?.data?.target_yield > 1
                  ? 'tonnes'
                  : 'tonne'}
              </Text>
            </div>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Anticipated Returns</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {apiCroppingPlan?.data?.anticipated_returns}
              </Text>
            </div>
          </div>

          <Text className={'text-gray-400'} size={'medium'} variant={'colorText'}>
            * the total anticipated returns was calculated using current BAMB prices.
          </Text>

          <div className={'flex items-center gap-4'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Total cost for Seeding</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {apiCroppingPlan?.data?.seed_cost}
              </Text>
            </div>
          </div>

          <Separator />
        </section>
      ) : null}

      {/*  Weeding */}
      {apiCroppingPlan?.data?.weed_control_type != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Weeding
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Weeding Method</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.weed_control_type}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Application Method</Text>
              <Text size={'medium'}>
                {getManualWeedMethodValue(apiCroppingPlan?.data?.weed_control_manual_method)}
              </Text>
            </div>
          </div>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Application Stage</Text>
              <Text size={'medium'}>
                {getManualWeedApplicationStageValue(
                  apiCroppingPlan?.data?.weed_control_chemical_schedule ??
                    apiCroppingPlan?.data?.weed_control_manual_schedule ??
                    undefined
                )}
              </Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Total cost for Weeding</Text>
              <Text variant={'success'} size={'medium'}>
                BWP{' '}
                {apiCroppingPlan?.data?.weed_control_type === 'Chemical'
                  ? (apiCroppingPlan?.data?.weed_control_chemical_cost ?? 0) +
                    (apiCroppingPlan?.data?.weed_control_application_cost ?? 0)
                  : apiCroppingPlan?.data?.weed_control_application_cost}
              </Text>
            </div>
          </div>
          <Separator />
        </section>
      ) : null}

      {/*  Pest Control */}
      {apiCroppingPlan?.data?.pest_control_type != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Pest Control
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Pest Control Method</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.pest_control_type}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Application Method</Text>
              <Text size={'medium'}>
                {apiCroppingPlan?.data?.pest_control_manual_method !== null
                  ? apiCroppingPlan?.data?.pest_control_manual_method
                  : apiCroppingPlan?.data?.pest_control_chemical_method != null
                  ? getChemicalPestMethod(apiCroppingPlan?.data.pest_control_chemical_method)
                  : null}
              </Text>
            </div>
          </div>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Application Stage</Text>
              <Text size={'medium'}>
                {apiCroppingPlan?.data?.pest_control_chemical_schedule != null
                  ? apiCroppingPlan?.data?.pest_control_chemical_schedule
                  : getManualPestStageValue(apiCroppingPlan?.data?.pest_control_manual_schedule)}
              </Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Total cost for Weeding</Text>
              <Text variant={'success'} size={'medium'}>
                BWP{' '}
                {apiCroppingPlan?.data?.weed_control_type === 'Chemical'
                  ? (apiCroppingPlan?.data?.weed_control_chemical_cost ?? 0) +
                    (apiCroppingPlan?.data?.weed_control_application_cost ?? 0)
                  : apiCroppingPlan?.data?.weed_control_application_cost}
              </Text>
            </div>
          </div>
          <Separator />
        </section>
      ) : null}

      {/*  Land Preparation */}
      {apiCroppingPlan?.data?.tillage_method != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Land Preparation
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Land Preparation Method</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.tillage_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Harrowing?</Text>
              <Text size={'medium'}>
                {apiCroppingPlan?.data?.isHarrowing === true ? 'Yes' : 'No'}
              </Text>
            </div>
          </div>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'medium'}>Total cost for Land Preparation</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {apiCroppingPlan?.data?.land_preparation_cost}
              </Text>
            </div>
          </div>
          <Separator />
        </section>
      ) : null}

      {/*  Harvesting */}
      {apiCroppingPlan?.data?.harvesting_method != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Harvesting
          </Text>

          <div className={'flex items-center  gap-4'}>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Harvesting Method</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.harvesting_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Total cost for harvesting</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {apiCroppingPlan?.data?.harvesting_cost}
              </Text>
            </div>
          </div>
          <Separator />
        </section>
      ) : null}

      {/*  Shelling & Threshing */}
      {apiCroppingPlan?.data?.shelling_threshing_method != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Shelling & Threshing
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Shelling & Threshing Method</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.shelling_threshing_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col  gap-4'}>
              <Text size={'medium'}>Total cost for shelling & threshing</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {apiCroppingPlan?.data?.shelling_threshing_cost}
              </Text>
            </div>
          </div>
          <Separator />
        </section>
      ) : null}

      {/*  Packaging */}
      {apiCroppingPlan?.data?.packaging_method != null ? (
        <section className={'flex flex-col gap-4'}>
          <Text variant={'primaryLight'} size={'large'}>
            Packaging
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Packaging Method</Text>
              <Text size={'medium'}>{apiCroppingPlan?.data?.packaging_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col gap-4'}>
              <Text size={'medium'}>Total cost for harvesting</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {apiCroppingPlan?.data?.packaging_cost}
              </Text>
            </div>
          </div>
          <Separator />
        </section>
      ) : null}

      <StepperFooter notComplete={notComplete} />
    </div>
  )
}
