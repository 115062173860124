import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export interface GetTractorChecklist {
  id: number
  inventory_id: number
  lights: number
  starting: number
  gauges: number
  cooling_leakages: number
  fuel_leakages: number
  lubrication_leakages: number
  hydraulic_oil_leakages: number
  transmission_leakages: number
  smoke_color: number
  oil_check: number
  greasing: number
  pto: number
  gear_shifting: number
  lifting_and_releasing: number
  hitch_pins: number
  bearings: number
  tyres: number
  mirrors: number
  functional_gps: boolean
  engine_stalling: boolean
  positioning_and_draft_control: boolean
  breaking_system: boolean
  steering_system: boolean
  diff_loc: boolean
  result: boolean
  comments: string
  create_date: string
  last_update_by: number
  last_update_date: string
}

export const getTractorChecklist = async (
  tractorId: number
): Promise<ApiResponse<GetTractorChecklist>> => {
  return axios.get(`/assessment/tractor?inventory_id=${tractorId}`)
}

export const useGetTractorChecklist = (
  tractorId: number
): UseQueryResult<ApiResponse<GetTractorChecklist>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['tractorChecklist'],
    queryFn: async () => getTractorChecklist(tractorId)
  })
}
