import { BasicButton } from 'components/Elements/Button'
import { LoanCard, LoanModal } from 'features/loan-management/components'
import { ModalStepper } from 'features/loan-management/components/generic/modalSteps/ModalStepper'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Spinner } from '../../../components/Elements/Spinner'
import { useFarmerStore } from '../../../stores/useFarmerStore'
import { useGetLoanHeader } from '../api/getLoanHeader'
import { Text } from '../../../components/Elements/Text'
import { NotFound } from '../../../components/Errors/NotFound'

export const LoanView: React.FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const [newLoan, setNewLoan] = useState(false)
  const handleCloseNewLoan = (): void => {
    setNewLoan(false)
  }
  const { farmer } = useFarmerStore()
  const { data, isLoading } = useGetLoanHeader(farmer?.farmer_id as number)

  const handleOpenNewLoan = (): void => {
    setNewLoan(true)
  }

  if (flags.loan === false || farmer?.class_id !== 'TAXPAYER') {
    return <NotFound />
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full flex-1'}>
        <Spinner size={'sm'} />
      </div>
    )
  }

  if (data?.data === undefined) {
    return (
      <div className={'w-full flex justify-center p-2 md:p-8'}>
        <div
          className={
            'w-full max-w-[1500px] flex flex-col bg-white gap-4 md:gap-8 md:rounded-md lg:rounded-xl p-4 py-6 sm:p-6 md:px-12'
          }
        >
          <div className={'w-full flex flex-col items-center gap-6'}>
            <Text variant={'bodyTextLight'} size={'medium'}>
              No Loans Available
            </Text>
            <BasicButton
              label={'New Loan'}
              onClick={handleOpenNewLoan}
              size={'xs'}
              customSx={{
                padding: '0.3rem, 0.8rem',
                color: 'white',
                backgroundColor: 'var(--color-accent)'
              }}
              textColor={'var(--color-white)'}
              color={'warning'}
            />
          </div>
          <LoanModal open={newLoan} onClose={handleCloseNewLoan}>
            <ModalStepper onClose={handleCloseNewLoan} />
          </LoanModal>
        </div>
      </div>
    )
  }
  return (
    <div className={'w-full flex flex-col gap-4 p-4'}>
      <div className={'w-full flex justify-between'}>
        <h2 className={'text-[color:var(--color-secondary-light)] text-lg md:text-2xl truncate '}>
          Loan Management
        </h2>
      </div>

      <>
        <div
          className={
            'flex flex-col sm:flex-row items-end sm:items-start justify-between gap-2 sm:gap-8'
          }
        >
          <LoanCard loanHeader={data?.data} isLoading={isLoading} />
        </div>
      </>
    </div>
  )
}
