import { InputField } from 'components/Form'
import {
  CustomAccordion,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper/index'
import type React from 'react'
import { object, string, type TypeOf } from 'zod'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { BasicButton } from 'components/Elements/Button'
import { useAtom, useAtomValue } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import { farmerAtom } from 'stores/userStore'
import { Text } from '../../../../../components/Elements/Text/Text'
import { type LoanStepperProps } from '../../../types'

const schema = object({
  background_info: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  process_info: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  key_assum: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  market_info: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  competitors: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  distribution: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  })
})

type SummarizedBusinessInformationDetailsInput = TypeOf<typeof schema>

export const SummarizedBusinessInformation: React.FC<LoanStepperProps> = ({
  onBack,
  onNext,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)

  const farmer = useAtomValue(farmerAtom)

  const onSubmit: SubmitHandler<SummarizedBusinessInformationDetailsInput> = (
    data: SummarizedBusinessInformationDetailsInput
  ) => {
    setLoanData((prev) => ({
      ...prev!,
      summarized_business_information: {
        farmer_id: farmer?.farmer_id as number,
        background_info: data.background_info,
        process_info: data.process_info,
        key_assum: data.key_assum,
        market_info: data.market_info,
        competitors: data.competitors,
        distribution: data.distribution,
        last_update_by: farmer?.user_id as number
      }
    }))
    onNext()
  }

  return (
    <FormWithStepper<SummarizedBusinessInformationDetailsInput, typeof schema>
      className={'flex flex-col flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ register, formState: { errors }, handleSubmit }) => (
        <MainContainer>
          <div className={'w-full flex flex-col gap-4'}>
            <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
              Summarized Business Information
            </Text>
            <div className={'w-full '}>
              <CustomAccordion
                sections={[
                  {
                    label: 'Background of Business:',
                    fields: ['background_info'],
                    fieldErrors: errors,
                    children: [
                      <>
                        <RowContainer>
                          <InputField
                            className='flex-[1_0_230px] capitalize'
                            label={'Brief history of company'}
                            defaultValue={
                              loanData?.summarized_business_information?.background_info
                            }
                            error={!(errors.background_info == null)}
                            helperText={errors?.background_info?.message ?? ''}
                            register={register('background_info')}
                          />
                        </RowContainer>
                      </>
                    ]
                  },
                  {
                    label:
                      'Production Process: Overview of operational process/cycle, production capacity',
                    fields: ['process_info'],
                    fieldErrors: errors,
                    children: [
                      <>
                        <RowContainer>
                          <InputField
                            className='flex-[1_0_230px] capitalize'
                            label={'Overview of operational process/cycle, Production capacity'}
                            defaultValue={loanData?.summarized_business_information?.process_info}
                            error={!(errors.process_info == null)}
                            helperText={errors?.process_info?.message ?? ''}
                            register={register('process_info')}
                          />
                        </RowContainer>
                      </>
                    ]
                  },
                  {
                    label: 'List of Key Project Assumptions',
                    fields: ['key_assum'],
                    fieldErrors: errors,
                    children: [
                      <>
                        <RowContainer>
                          <InputField
                            className='flex-[1_0_230px] capitalize'
                            label={'asssumptions on sales/ turnover estimates'}
                            defaultValue={loanData?.summarized_business_information?.key_assum}
                            error={!(errors.key_assum == null)}
                            helperText={errors?.key_assum?.message ?? ''}
                            register={register('key_assum')}
                          />
                        </RowContainer>
                      </>
                    ]
                  },
                  {
                    label: 'Market Availability of Project Products/Services & existing customers',
                    fields: ['market_info'],
                    fieldErrors: errors,
                    children: [
                      <>
                        <RowContainer>
                          <InputField
                            className='flex-[1_0_230px] capitalize'
                            label={'Market Information'}
                            defaultValue={loanData?.summarized_business_information?.market_info}
                            error={!(errors.market_info == null)}
                            helperText={errors?.market_info?.message ?? ''}
                            register={register('market_info')}
                          />
                        </RowContainer>
                      </>
                    ]
                  },
                  {
                    label: 'Competitors and Competitive Advantage',
                    fields: ['competitors'],
                    fieldErrors: errors,
                    children: [
                      <>
                        <RowContainer>
                          <InputField
                            className='flex-[1_0_230px] capitalize'
                            label={'Competitors'}
                            defaultValue={loanData?.summarized_business_information?.competitors}
                            error={!(errors.competitors == null)}
                            helperText={errors?.competitors?.message ?? ''}
                            register={register('competitors')}
                          />
                        </RowContainer>
                      </>
                    ]
                  },
                  {
                    label: 'Distribution Channels',
                    fields: ['distribution'],
                    fieldErrors: errors,
                    children: [
                      <>
                        <RowContainer>
                          <InputField
                            className='flex-[1_0_230px] capitalize'
                            label={'Distribution'}
                            defaultValue={loanData?.summarized_business_information?.distribution}
                            error={!(errors.distribution == null)}
                            helperText={errors?.distribution?.message ?? ''}
                            register={register('distribution')}
                          />
                        </RowContainer>
                      </>
                    ]
                  }
                ]}
              />
              <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
                <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
                <BasicButton
                  label={'Next'}
                  variant={'primary'}
                  onClick={handleSubmit(onSubmit)} // handleSubmit(onSubmit)
                  size={'xs'}
                />
              </div>
            </div>
          </div>
        </MainContainer>
      )}
    </FormWithStepper>
  )
}
