export const Insecticides = [
  {
    id: 1,
    application_rate: 'Full cover application when necessary ',
    dosage: '15/30 kg/ha',
    withdrawal_period_in_days: 7,
    name: 'W130193 - Aphicide ',
    key_ingredients: 'Mercaptothion 250g/kg',
    crop_id: 10014,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 2,
    application_rate: 'Full cover application when necessary ',
    dosage: '10 - 15kg/ha',
    withdrawal_period_in_days: 35,
    name: 'W130132 - Dimethoate ',
    key_ingredients: 'Chlorpyrifos 450 g/l, Cypermethrin 50g/l',
    crop_id: 10004,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 3,
    application_rate: '2 - 4L spray mixture - ground application',
    dosage: '12,5-15kg/ha',
    withdrawal_period_in_days: 5,
    name: 'W130050 - Super Guard 50 EC',
    key_ingredients: 'Diazinon 275g/l',
    crop_id: 10011,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 4,
    application_rate: '2 - 4L spray mixture - ground application',
    dosage: '15 - 25kg/ha',
    withdrawal_period_in_days: 14,
    name: 'W1301659 - Rainifos 480 EC',
    key_ingredients: 'Chlorpyrifos 450 g/l, Cypermethrin 50g/l',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 5,
    application_rate: 'Full cover application when necessary ',
    dosage: '20 - 25kg/ha ',
    withdrawal_period_in_days: 35,
    name: 'W130854 - Chlopyrifos 480 EC',
    key_ingredients: 'Diazinon 275g/l',
    crop_id: 10007,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 6,
    application_rate: 'Full cover spray.',
    dosage: '100ml/100l water',
    withdrawal_period_in_days: 28,
    name: 'W130130 Chlopyrifos 480 EC',
    key_ingredients: 'Chlorantranipole 100g/l, Lambda Cyhalothrin 50g/l',
    crop_id: 10007,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 7,
    application_rate: '2 - 4L spray mixture - ground application',
    dosage: '100ml/100l water',
    withdrawal_period_in_days: 14,
    name: 'W130130 Chlopyrifos 480 EC',
    key_ingredients: 'Chlorantranipole 100g/l, Lambda Cyhalothrin 50g/l',
    crop_id: 10007,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 8,
    application_rate: 'Full cover spray.',
    dosage: '100ml/100l water',
    withdrawal_period_in_days: 2,
    name: 'W130048 - Avi - Klopirifos EC',
    key_ingredients: 'Pirimicarb 500g/kg',
    crop_id: 10008,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 9,
    application_rate: 'Full cover spray.',
    dosage: '100ml/100l water',
    withdrawal_period_in_days: 7,
    name: 'W130130 Chlopyrifos 480 EC',
    key_ingredients: 'Pirimicarb 500g/kg',
    crop_id: 10008,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 10,
    application_rate: 'Full cover spray.',
    dosage: '100ml/100l water',
    withdrawal_period_in_days: 5,
    name: 'W130048 - Avi - Klopirifos EC',
    key_ingredients: 'Pirimiphos-methyl 16g/kg, Permethrin 4g/kg',
    crop_id: 10007,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 11,
    application_rate: 'Full cover spray.',
    dosage: '75ml/100l water ',
    withdrawal_period_in_days: 3,
    name: 'W1301091 - Cypermethrin 200 EC',
    key_ingredients: 'Pirimiphos-methyl 16g/kg, Permethrin 4g/kgl',
    crop_id: 10008,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 30,
    application_rate: '200 -400l/ha spray mixture',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 28,
    name: 'W1301091 - Cypermethrin 200 EC',
    key_ingredients: 'Pirimicarb 500g/kg',
    crop_id: 10004,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 31,
    application_rate: '2 - 4L spray mixture - ground application',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 35,
    name: 'W130891 - Cypermethrin 200 EC',
    key_ingredients: 'Pirimiphos-methyl 16g/kg, Permethrin 4g/kg',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 32,
    application_rate: '200 -400l/ha spray mixture',
    dosage: '75ml/100l water',
    withdrawal_period_in_days: 30,
    name: 'W1301091 - Cypermethrin 200 EC',
    key_ingredients: 'Pirimiphos-methyl 16g/kg, Permethrin 4g/kg',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 33,
    application_rate: '2 - 4L spray mixture - ground application',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 7,
    name: 'W130891 - Cypermethrin 200 EC',
    key_ingredients: 'Pirimiphos-methyl 16g/kg, Permethrin 4g/kg',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 34,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 5,
    name: 'W130891 - Cypermethrin 200 EC',
    key_ingredients: 'Pirimiphos-methyl 16g/kg, Permethrin 4g/kg',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 35,
    application_rate: '2 -4l spray mixture/100m row - ground',
    dosage: '75ml/100l water ',
    withdrawal_period_in_days: 2,
    name: 'W130826 - Knox Worm',
    key_ingredients: 'Cypermethrin 200g/l',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 36,
    application_rate: '2 - 4L spray mixture - ground application',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 14,
    name: 'W130826 - Knox Worm ',
    key_ingredients: 'Cypermethrin 200g/l',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 37,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 30,
    name: 'W130737 - Cyperin 200 EC',
    key_ingredients: 'Cypermethrin 200g/l',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 38,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 28,
    name: 'W130826 - Knox Worm ',
    key_ingredients: 'Cypermethrin 200g/l',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 39,
    application_rate: '2 -4l spray mixture/100m row - ground',
    dosage: '75ml/100l water ',
    withdrawal_period_in_days: 2,
    name: 'FarmAg Azoxystrobin 250 SC',
    key_ingredients: 'Chlopyrifos 480g/l',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 40,
    application_rate: '30l water/ha - aerial application',
    dosage: '75ml/100l water ',
    withdrawal_period_in_days: 7,
    name: 'W130737 - Cyperin 200 EC',
    key_ingredients: 'Chlopyrifos 480g/l',
    crop_id: 10005,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 41,
    application_rate: '1000L/ha',
    dosage: '0,33ml/100m row',
    withdrawal_period_in_days: 30,
    name: 'Foxamyl 10 GR',
    key_ingredients: 'Chlopyrifos 480g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 42,
    application_rate: '30l water/ha - aerial application',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 14,
    name: 'W130737 - Cyperin 200 EC',
    key_ingredients: 'Chlopyrifos 480g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 43,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 14,
    name: 'Garden Phosphate 8,3',
    key_ingredients: 'Pirimiphos methyl 400g/l, Permethrin 75g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 44,
    application_rate: '30l water/ha - aerial application',
    dosage: '0,33ml/100m row',
    withdrawal_period_in_days: 7,
    name: 'W130737 - Cyperin 200 EC',
    key_ingredients: 'Pirimiphos methyl 400g/l, Permethrin 75g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 45,
    application_rate: '2 -4l spray mixture/100m row - ground',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 7,
    name: 'W130269 - Dimethoate ',
    key_ingredients: 'Pirimiphos methyl 400g/l, Permethrin 75g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 46,
    application_rate: '30l water/ha - aerial application',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 5,
    name: '2:3:2 (14)',
    key_ingredients: 'Dimethoate 400g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 47,
    application_rate: '600L/ha',
    dosage: '0,33ml/100m row',
    withdrawal_period_in_days: 2,
    name: 'W130287 - Tsunami Sipermetrien',
    key_ingredients: 'Dimethoate 400g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 48,
    application_rate: '2 -4l spray mixture/100m row - ground',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 14,
    name: 'W130287 - Tsunami Sipermetrien',
    key_ingredients: 'Dimethoate 400g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Contact'
  },
  {
    id: 49,
    application_rate: '30l water/ha - aerial application',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 14,
    name: 'W130611 - Ampligo',
    key_ingredients: 'Dimethoate 400g/l',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 50,
    application_rate: '30l water/ha - aerial application',
    dosage: '350g/ 100l water',
    withdrawal_period_in_days: 7,
    name: 'W130035 - Avi Kayazinon EK',
    key_ingredients: 'Mercaptothion 250g/kg',
    crop_id: 10009,
    type: 'Insecticide',
    application_stage: 'Preventive'
  },
  {
    id: 51,
    name: 'W130611 - Ampligo',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Preventive',
    application_rate: '200 -400l/ha spray mixture',
    dosage: '350g/ 100l water',
    withdrawal_period_in_days: 35
  },
  {
    id: 52,
    name: 'W130035 - Avi Kayazinon EK',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Contact',
    application_rate: '2 -4l spray mixture/100m row - ground',
    dosage: '350g/ 100l water',
    withdrawal_period_in_days: 2
  },
  {
    id: 53,
    name: 'W130611 - Ampligo ',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Preventive',
    application_rate: '0,33ml/100m row',
    dosage: '350g/ 100l water',
    withdrawal_period_in_days: 7
  },
  {
    id: 54,
    name: 'W130028 - Cyperfos',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Preventive',
    application_rate: '200 -400l/ha spray mixture',
    dosage: '350g/ 100l water',
    withdrawal_period_in_days: 30
  },
  {
    id: 55,
    name: 'W130611 - Ampligo ',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Preventive',
    application_rate: '0,33ml/100m row',
    dosage: '250g/ 100l water',
    withdrawal_period_in_days: 14
  },
  {
    id: 56,
    name: 'W130028 - Cyperfos',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Preventive',
    application_rate: '200 -400l/ha spray mixture',
    dosage: '250g/ 100l water',
    withdrawal_period_in_days: 5
  },
  {
    id: 57,
    name: 'W130020 - Merkaptotoks WP',
    key_ingredients: 'Mercaptothion 250g/kgl',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Contact',
    application_rate: '0,33ml/100m row',
    dosage: '250g/ 100l water',
    withdrawal_period_in_days: 14
  },
  {
    id: 58,
    name: 'W130020 - Merkaptotoks WP',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Contact',
    application_rate: '2 -4l spray mixture/100m row - ground',
    dosage: '250g/ 100l water',
    withdrawal_period_in_days: 30
  },
  {
    id: 59,
    name: 'W130020 - Merkaptotoks WP',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Contact',
    application_rate: '0,33ml/100m row',
    dosage: '250g/ 100l water',
    withdrawal_period_in_days: 35
  },
  {
    id: 60,
    name: 'W130020 - Merkaptotoks WP',
    key_ingredients: 'Mercaptothion 250g/kg',
    type: 'Insecticide',
    crop_id: 10011,
    application_stage: 'Contact',
    application_rate: '2 -4l spray mixture/100m row - ground',
    dosage: '250g/ 100l water',
    withdrawal_period_in_days: 35
  }
]
