export const getTimerTime = (date: Date, counter: number): Date => {
  if (counter === 0) {
    return new Date(Date.now())
  } else if (counter >= 1 && counter <= 2) {
    // time for 30 seconds
    const time = date
    time.setSeconds(time.getSeconds() + 30)
    return new Date(time)
  } else if (counter >= 3 && counter <= 4) {
    // time for 1 minute
    const time = date
    time.setSeconds(time.getSeconds() + 60)
    return new Date(time)
  } else if (counter >= 5 && counter <= 6) {
    // time for 30 minutes
    const time = date
    time.setSeconds(time.getSeconds() + 60 * 30)
    return new Date(time)
  } else if (counter >= 7) {
    // time for 4 hours
    const time = date
    time.setSeconds(time.getSeconds() + 60 * 60 * 4)
    return new Date(time)
  }
  return new Date(Date.now())
}
