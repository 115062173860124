import { type FC } from 'react'
import { CroppingPlanHeaderStatus, type Farm, type Lease } from 'types'
import { useGetCroppingPlans } from '../../../api/getCroppingPlans'
import { useFarmerStore } from 'stores/useFarmerStore'
import { Loader } from 'components/ui/loader'
import { Error } from 'components/Errors/Error'
import { Text } from 'components/ui'
import { GrantForm } from './GrantForm'

interface Base {
  cancel: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type NewGrantProps = WithLease | WithFarm

export const NewGrant: FC<NewGrantProps> = ({ cancel, lease, farm }) => {
  const { farmer } = useFarmerStore()
  const {
    data: croppingPlans,
    isLoading,
    error
  } = useGetCroppingPlans(farmer?.farmer_id, lease === undefined ? farm?.farm_id : lease?.farm_id)

  if (isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  const isError = error != null && error.response?.status !== 404

  if (isError) {
    return <Error />
  }

  // no cropping plans
  if (croppingPlans == null) {
    return (
      <div className={'flex flex-col items-center gap-4'}>
        <Text className={'text-center'} size={'large'} variant={'error'}>
          No cropping plans found
        </Text>
        <Text className={'text-center'} size={'medium'} variant={'bodyText'}>
          Please ensure that you have created a cropping plan before applying for a grant.
        </Text>
      </div>
    )
  }

  const verifiedCroppingPlans = croppingPlans.data.filter((item): boolean => {
    return item.is_verified === CroppingPlanHeaderStatus.Approved
  })

  // no verified cropping plans
  if (verifiedCroppingPlans.length === 0) {
    return (
      <div className={'flex flex-col items-center gap-4'}>
        <Text className={'text-center'} size={'large'} variant={'error'}>
          No Verified Cropping Plans Found
        </Text>
        <Text className={'text-center'} size={'medium'} variant={'bodyText'}>
          The cropping plans found for this farm have not been verified. Please ensure that your
          cropping plan has been approved by your extension officer and that it meets all
          requirements.
        </Text>
      </div>
    )
  }

  const validCroppingPlans = verifiedCroppingPlans.filter((item) => item.hectarage <= 4)

  // no cropping plans <= 4 ha
  if (validCroppingPlans.length === 0) {
    return (
      <div className={'flex flex-col items-center gap-4'}>
        <Text className={'text-center'} size={'large'} variant={'error'}>
          No Valid Cropping Plan Found
        </Text>
        <Text className={'text-center'} size={'medium'} variant={'bodyText'}>
          The cropping plans found for this farm did not meet the requirements necessary for a grant
          application. Please ensure that you have a cropping plan that uses 4 or less hectares.
        </Text>
      </div>
    )
  }

  if (lease != null) {
    return <GrantForm lease={lease} cancel={cancel} croppingPlans={validCroppingPlans} />
  }

  return <GrantForm farm={farm} cancel={cancel} croppingPlans={validCroppingPlans} />
}
