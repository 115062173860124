import type React from 'react'
import { Text } from 'components/ui'

interface LoginSeparatorProps {
  label: string
}

export const LoginSeparator: React.FC<LoginSeparatorProps> = ({ label }) => {
  return (
    <div className='flex items-center flex-row gap-2 font-bold'>
      <div className='flex-1 border-b-2 border-solid border-muted-foreground'></div>
      <div className='mx-auto'>
        <Text>{label}</Text>
      </div>
      <div className='flex-1 border-b-2 border-solid border-muted-foreground'></div>
    </div>
  )
}
