import type { ColumnDef } from '@tanstack/react-table'
import type { CroppingPlan } from '../../../../../types'
import { DataTableColumnHeader } from '../../../../../components/Data-Table/datatable-header'

export const croppingPlanColumns: Array<ColumnDef<CroppingPlan>> = [
  {
    accessorKey: 'farmer_id',
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Farmer ID' />,
    cell: ({ row }) => <div className='lowercase'>{row.getValue('farmer_id')}</div>
  },
  {
    accessorKey: 'plough_season',
    enableHiding: false,
    header: 'Plough Season'
  },
  {
    accessorKey: 'crop_name',
    filterFn: 'includesString',
    enableHiding: false,
    header: 'Crop Name'
  },
  {
    accessorKey: 'family_name',
    filterFn: 'includesString',
    enableHiding: true,
    header: 'Family Name'
  },
  {
    accessorKey: 'hectarage',
    enableHiding: true,
    header: 'Hectarage'
  },
  {
    accessorKey: 'total_cost',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Total Cost' />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('total_cost'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]
