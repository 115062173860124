import type React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { Spinner } from '../../../../components/Elements/Spinner'
import { LoanApplicationStatus } from './LoanApplicationStatus'
import moment from 'moment'
import { type LoanHeader } from '../../../../types'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai/index'
import { applicationHeader } from '../stepper/stores/applicationHeader'

interface LoanCardProps {
  loanHeader: LoanHeader
  isLoading: boolean
}

export const LoanCard: React.FC<LoanCardProps> = ({ loanHeader, isLoading }) => {
  if (isLoading) {
    return (
      <div className={'w-full grid place-items-center h-full flex-1'}>
        <Spinner size={'sm'} />
      </div>
    )
  }
  const [, setApplicationHead] = useAtom(applicationHeader)
  const navigate = useNavigate()
  const onClick = (): void => {
    if (loanHeader.status_id === 0) {
      setApplicationHead((prev) => ({
        ...prev,
        loan_application_id: Number(loanHeader.loan_application_id)
      }))
      navigate('/farmer/loans/completeApplication')
    }
  }

  return (
    <div onClick={onClick} style={{ cursor: 'pointer', width: '100%' }}>
      <TableContainer component={Paper}>
        <Table sx={{ Width: '100%' }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <TableCell>Loan Reference</TableCell>
              <TableCell>Date Created</TableCell>
              <TableCell>Loan Amount (BWP)</TableCell>
              <TableCell> Loan Status</TableCell>
              <TableCell> Status Update Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <div className={'grid place-items-center h-full flex-1'}>
                <Spinner size={'sm'} />
              </div>
            ) : (
              <TableRow>
                <TableCell>{loanHeader?.loan_application_id}</TableCell>
                <TableCell>{moment(loanHeader?.create_date).format('DD-MM-YYYY')}</TableCell>
                <TableCell>{loanHeader?.loan_requirement1}</TableCell>
                <TableCell>
                  {
                    <LoanApplicationStatus
                      loanHeader={loanHeader}
                      className={'flex justify-start items-center gap-2 w-full'}
                    />
                  }
                </TableCell>
                <TableCell>{moment(loanHeader?.last_update_date).format('DD-MM-YYYY')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
