import { SwiperHero } from 'features/landing/components/index'
import { useNavigate } from 'react-router-dom'
import { Layout, Button, Text } from 'components/ui'
import type React from 'react'
import { useAuthState } from 'providers/auth.context'
export const Banner: React.FC = () => {
  const navigate = useNavigate()
  const { user, role } = useAuthState()
  const navigateTo = (routeOrEmail: string): void => {
    if (routeOrEmail.startsWith('mailto:')) {
      window.location.href = routeOrEmail
    } else {
      navigate(routeOrEmail)
    }
  }
  return (
    <Layout className={'w-full flex items-center flex-wrap mt-[clamp(2rem,5vh,10rem)]'}>
      <div
        className={
          'w-full self-content flex-[1_0_360px] sm:flex-[1_0_450px] order-2 aspect-[4/3] md:order-1 px-2 overflow-hidden'
        }
      >
        <SwiperHero />
      </div>

      <div
        className={
          'flex flex-col self-center flex-[1_0_360px] sm:flex-[1_0_450px] text-center md:text-left mx-[auto] gap-4  p-4 px-4 md:px-8 order-1 md:order-2'
        }
      >
        <Text size='xLarge' variant='primaryDark'>
          Your Crop Management System for Sustainable Farming Success, Welcome!
        </Text>
        <Text size='medium'>
          Explore a new era of efficient farming with our innovative tools and resources.
        </Text>
        <div
          className={
            'flex max-w-[500px] w-full mx-[auto] md:mx-0 flex-wrap gap-2 sm:gap-4 sm:mt-8 mt-4 justify-center md:justify-start'
          }
        >
          <Button
            variant={'secondary'}
            onClick={() => {
              navigateTo('mailto:Moa17755@gov.bw')
            }}
            className='flex-[1_0_225px]'
          >
            <p className='material-symbols-outlined'>call</p>
            get in touch
          </Button>
          <Button
            onClick={() => {
              navigateTo('/registration')
            }}
            className='flex-[1_0_225px]'
          >
            {user === null ? 'get started' : 'dashboard'}
            <p className='material-symbols-outlined'>north_east</p>
          </Button>
        </div>
      </div>
    </Layout>
  )
}
