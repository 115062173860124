/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access */
import type React from 'react'

type PartialInputProps = Pick<React.ComponentPropsWithoutRef<'input'>, 'className' | 'style'>
type OtpFieldProps = {
  value: string
  size?: number
  validationPattern?: RegExp
  onChange: (value: string) => void
} & PartialInputProps

export const OtpField: React.FC<OtpFieldProps> = ({
  size = 6,
  value,
  onChange,
  className,
  validationPattern = /[0-9]/,
  ...restProps
}: OtpFieldProps) => {
  const arr = new Array(size).fill('-')
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const elem = e.target
    const val = e.target.value
    // check if the value is valid
    if (!validationPattern.test(val) && val !== '') return

    // change the value of the upper state using onChange
    const valueArr = value.split('')
    valueArr[index] = val
    const newVal = valueArr.join('').slice(0, 6)
    onChange(newVal)

    // focus the next element if there's a value
    if (val !== '') {
      const next = elem.nextElementSibling as HTMLInputElement | null
      next?.focus()
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const current = e.currentTarget
    if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
      const prev = current.previousElementSibling as HTMLInputElement | null
      prev?.focus()
      prev?.setSelectionRange(0, 1)
      return
    }

    if (e.key === 'ArrowRight') {
      const prev = current.nextSibling as HTMLInputElement | null
      prev?.focus()
      prev?.setSelectionRange(0, 1)
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const val = e.clipboardData.getData('text').substring(0, size)
    onChange(val)
  }

  return (
    <div className={'flex justify-center gap-4'}>
      {arr.map((_, index) => {
        return (
          <input
            {...restProps}
            key={index}
            className={
              'dark:bg-card bg-muted w-10 h-12 text-[1.2rem] text-md font-redHat font-bold text-foreground rounded-md px-0  text-center border border-input focus:border-2 focus:border-primary'
            }
            type='text'
            inputMode='numeric'
            placeholder={'-'}
            autoComplete='one-time-code'
            maxLength={6}
            onChange={(e) => {
              handleInputChange(e, index)
            }}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            value={value.at(index) ?? ''}
          />
        )
      })}
    </div>
  )
}
