import { Paper, Tab, Tabs, tabsClasses } from '@mui/material'
import type React from 'react'

interface CustomTabProps {
  value: number
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined
  onChange: (event: React.SyntheticEvent, newValue: number) => void
  children: React.ReactNode
  className?: string
}

export const CustomTab: React.FC<CustomTabProps> = ({
  variant = 'scrollable',
  children,
  className,
  onChange,
  value
}) => {
  return (
    <Paper className={`w-full ${className ?? ''}`} elevation={2}>
      <Tabs
        value={value}
        onChange={onChange}
        variant={variant}
        scrollButtons
        allowScrollButtonsMobile
        aria-label={'scrollable tabs'}
        sx={{
          fontFamily: 'Red Hat Display',
          backgroundColor: 'var(--card)',
          borderRadius: 'calc(var(--radius) - 2px)',
          padding: '0',
          border: 'none',
          color: 'var(--foreground)',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
      >
        {children}
      </Tabs>
    </Paper>
  )
}