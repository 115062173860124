import type React from 'react'
import { type Lease } from 'types'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import dayjs from 'dayjs'

interface LeaseTableProps {
  leases: Lease[]
  onClick: (item: Lease) => void
}

export const LeaseTable: React.FC<LeaseTableProps> = ({ leases, onClick }) => {
  return (
    <Table>
      <TableCaption>List of leases pending verification.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>Leasee ID</TableHead>
          <TableHead className='font-bold text-color-text'>Leasee Name</TableHead>
          <TableHead className='font-bold text-color-text'>Lessor Name</TableHead>
          <TableHead className='font-bold text-color-text'>Village</TableHead>
          <TableHead className='font-bold text-color-text'>Hectares</TableHead>
          <TableHead className='font-bold text-color-text'>Start Date</TableHead>
          <TableHead className='font-bold text-color-text'>End Date</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {leases.map((lease) => (
          <TableRow
            onClick={() => {
              onClick(lease)
            }}
            key={lease.lease_id}
            role={'listitem'}
            className={'hover:cursor-pointer hover:bg-muted-hover'}
          >
            <TableCell className='font-medium'>{lease.dest_farmer_id}</TableCell>
            <TableCell className='font-medium'>{lease.dest_farmer_name}</TableCell>
            <TableCell className='font-medium'>{lease.origin_farmer_name}</TableCell>
            <TableCell className='font-medium'>{lease.village}</TableCell>
            <TableCell className='font-medium'>{lease.leased_size}</TableCell>
            <TableCell className='font-medium'>
              {dayjs(lease.commencement_date).format('DD-MM-YYYY')}
            </TableCell>
            <TableCell className='font-medium'>
              {lease.new_termination_date != null
                ? dayjs(lease.new_termination_date).format('DD-MM-YYYY')
                : dayjs(lease.expiry_date).format('DD-MM-YYYY')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
