import { type ApiResponse, type Officer } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getOfficer = async (
  areaId: number | null | undefined
): Promise<ApiResponse<Officer>> => {
  return axios.get(`/officer?USER_ROLE=1001&EXT_AREA_ID=${areaId ?? ''}`)
}

export const useGetOfficer = (
  areaId: number | null | undefined
): UseQueryResult<ApiResponse<Officer>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['officer', areaId],
    queryFn: async () => getOfficer(areaId),
    enabled: areaId != null
  })
}
