import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreateSSRDTO {
  user_id: number | null | undefined
  farm_id: number | null | undefined
  lease_id: number | null | undefined
  farmer_id: number | null | undefined
  merchant_id: number | null | undefined
}

export const createSSR = async (params: CreateSSRDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/ssr', params)
}
