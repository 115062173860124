import type { ApiResponseWithObject, IsVerifiedDTO } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getVoucherEligibility = async (
  omang: string | null | undefined
): Promise<ApiResponseWithObject<IsVerifiedDTO>> => {
  return axios.get(`/grant/check?omang=${omang ?? ''}`)
}

export const useGetVoucherEligibility = (
  omang: string | null | undefined
): UseQueryResult<ApiResponseWithObject<IsVerifiedDTO>> => {
  return useQuery({
    queryKey: ['voucher-eligibility', omang],
    queryFn: async () => getVoucherEligibility(omang),
    enabled: omang != null
  })
}
