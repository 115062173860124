import type { ColumnDef } from '@tanstack/react-table'
import type { CroppingPlan } from 'types'
import { DataTableColumnHeader } from '../../../../../components/Data-Table/datatable-header'
import { CroppingPlanStatuses } from './CroppingPlanStatuses'
import { CroppingPlanActions } from './cropping-plan-actions'

export const croppingPlansColumns: Array<ColumnDef<CroppingPlan>> = [
  {
    id: 'plough season',
    accessorFn: (row) => row.plough_season,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('plough season')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Plough Season' />
  },
  {
    id: 'crop',
    accessorFn: (row) => row.crop_name,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('crop')}</div>,
    header: 'Crop Name'
  },
  {
    id: 'family name',
    accessorFn: (row) => row.family_name,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('family name')}</div>,
    header: 'Family Name'
  },
  {
    id: 'hectarage',
    accessorFn: (row) => row.hectarage,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('hectarage')}</div>,
    header: 'Hectarage'
  },
  {
    accessorKey: 'total costs',
    accessorFn: (row) => row.total_cost,
    enableHiding: false,
    header: 'Total Cost',
    cell: ({ row }) => {
      const amount = parseFloat(String(row.getValue('total costs')).replace(',', ''))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  },
  {
    id: 'status',
    accessorFn: (row) => row.is_verified,
    cell: ({ row }) => <CroppingPlanStatuses croppingPlan={row.original} />,
    header: 'Status'
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      return <CroppingPlanActions croppingPlan={row.original} />
    }
  }
]
