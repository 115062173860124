import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type Lease } from 'types'

export const getLeases = async (
  extAreaId: number | null | undefined,
  farmerId: string | null | undefined
): Promise<ApiResponse<Lease>> => {
  return axios.get(`/lease?EXT_AREA_ID=${extAreaId ?? ''}&DEST_FARMER_ID=${farmerId ?? ''}`)
}

export const useGetLeases = (
  farmerId: string | undefined,
  extAreaId: number | null | undefined
): UseQueryResult<ApiResponse<Lease>, AxiosError> => {
  return useQuery({
    queryKey: ['leased', farmerId, extAreaId],
    queryFn: async () => getLeases(extAreaId, farmerId),
    enabled: farmerId != null && extAreaId != null
  })
}
