import { useAtom } from 'jotai'
import type React from 'react'
import z, { coerce, number, object, string, type TypeOf } from 'zod'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { MenuItem } from '@mui/material'
import { CustomDatePicker, InputField, SelectField } from 'components/Form'
import { RowContainer } from '../../generic/Containers'
import { BasicButton } from 'components/Elements/Button'
import dayjs from 'dayjs'
import { Text } from '../../../../../components/Elements/Text'
import { type LoanStepperProps } from '../../../types'
import { companyLoanStore } from '../stores'

export const CompanyApplicantEmploymentInfo: React.FC<LoanStepperProps> = ({
  className,
  onBack,
  onNext,
  activeStep,
  steps
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)

  const schema = object({
    app_employer: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    employment_status: z.enum(['Contract', 'Permanent', 'Self Employed', 'Pensioner']),
    job_title: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    employment_start_date: coerce.date({
      required_error: 'Field is required',
      invalid_type_error: 'Invalid Date'
    }),
    monthly_income: number({ invalid_type_error: 'This should be a number' }),
    other_income: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    no_of_permanent_employees: number({
      invalid_type_error: 'This should be a number'
    }).nonnegative(),
    no_of_part_time_employees: number({
      invalid_type_error: 'This should be a number'
    }).nonnegative(),
    previous_year_income: number({ invalid_type_error: 'This should be a number' }),
    employee_other_income: number({ invalid_type_error: 'This should be a number' })
  })

  type CompanyEmploymentDetailsInput = TypeOf<typeof schema>
  const onSubmit: SubmitHandler<CompanyEmploymentDetailsInput> = (
    data: CompanyEmploymentDetailsInput
  ) => {
    console.log(data)

    setLoanData((prev) => ({
      ...prev!,
      employee_information: {
        applicant_employer: data.app_employer,
        applicant_employment_start_date: data.employment_start_date,
        applicant_employment_status: data.employment_status,
        applicant_job_title: data.job_title,
        applicant_monthly_income: data.monthly_income,
        applicant_other_income_source: data.other_income,
        casual_employee_number: data.no_of_part_time_employees,
        farm_other_income: data.employee_other_income,
        permenant_employee_number: data.no_of_permanent_employees,
        previous_year_turnover: data.previous_year_income
      }
    }))

    console.log(loanData?.employee_information)
    onNext()
  }

  return (
    <FormWithStepper<CompanyEmploymentDetailsInput, typeof schema>
      className={className}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ control, register, formState: { errors }, handleSubmit }) => (
        <div className={'w-full flex flex-col gap-8 p-2 px-4'}>
          <div className='w-full flex flex-col gap-2'>
            <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
              Employment Information
            </Text>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px] capitalize'
                label='Employer'
                defaultValue={loanData?.employee_information?.applicant_employer}
                error={!(errors.app_employer == null)}
                helperText={errors?.app_employer?.message ?? ''}
                register={register('app_employer')}
              />
              <SelectField
                className='flex-[1_0_230px] capitalize'
                label='Employment Status'
                defaultValue={loanData?.employee_information?.applicant_employment_status}
                error={!(errors.employment_status == null)}
                helperText={errors?.employment_status?.message ?? ''}
                register={register('employment_status')}
              >
                <MenuItem value='Contract'>
                  <Text>Contract</Text>
                </MenuItem>
                <MenuItem value='Permanent'>
                  <Text>Permanent</Text>
                </MenuItem>
                <MenuItem value='Self Employed'>
                  <Text> Employed</Text>
                </MenuItem>
                <MenuItem value='Pensioner'>
                  <Text>Pensioner</Text>
                </MenuItem>
              </SelectField>
            </RowContainer>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px] capitalize'
                label='Job Title'
                defaultValue={loanData?.employee_information?.applicant_job_title}
                error={!(errors.job_title == null)}
                helperText={errors?.job_title?.message ?? ''}
                register={register('job_title')}
              />

              <CustomDatePicker
                className={'flex-[1_0_230px] capitalize'}
                error={!(errors.employment_start_date == null)}
                helperText={errors?.employment_start_date?.message ?? ''}
                label={'Employment Start Date'}
                defaultValue={
                  loanData?.employee_information?.applicant_employment_start_date === undefined
                    ? undefined
                    : dayjs(loanData?.employee_information?.applicant_employment_start_date)
                }
                disableFuture
                name={'employment_start_date'}
                control={control}
              />
            </RowContainer>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px] capitalize'
                label='Applicant Other Income Source'
                defaultValue={loanData?.employee_information?.applicant_other_income_source}
                error={!(errors.other_income == null)}
                helperText={errors?.other_income?.message ?? ''}
                register={register('other_income')}
              />
              <InputField
                className='flex-[1_0_230px] capitalize'
                label='Monthly Income(BWP)'
                type='number'
                defaultValue={loanData?.employee_information?.applicant_monthly_income}
                error={!(errors.monthly_income == null)}
                helperText={errors?.monthly_income?.message ?? ''}
                register={register('monthly_income', { valueAsNumber: true })}
              />
            </RowContainer>

            <>
              <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
                Farm Annual And Farm Employees' Information
              </Text>
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'Number Of Permanent Employees '}
                  type='number'
                  defaultValue={loanData?.employee_information?.permenant_employee_number}
                  error={!(errors.no_of_permanent_employees == null)}
                  helperText={errors?.no_of_permanent_employees?.message ?? ''}
                  register={register('no_of_permanent_employees', { valueAsNumber: true })}
                />
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  defaultValue={loanData?.employee_information?.casual_employee_number}
                  type={'number'}
                  label={'Number Of Casual/Part Time employees '}
                  error={!(errors.no_of_part_time_employees == null)}
                  helperText={errors?.no_of_part_time_employees?.message ?? ''}
                  register={register('no_of_part_time_employees', { valueAsNumber: true })}
                />
              </RowContainer>
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px]  capitalize'
                  defaultValue={loanData?.employee_information?.previous_year_turnover}
                  type={'number'}
                  label={'Previous Year Turnover'}
                  error={!(errors.previous_year_income == null)}
                  helperText={errors?.previous_year_income?.message ?? ''}
                  register={register('previous_year_income', { valueAsNumber: true })}
                />
                <InputField
                  className='flex-[1_0_230px]  capitalize'
                  label={'Other Income(BWP)'}
                  defaultValue={loanData?.employee_information?.farm_other_income}
                  type={'number'}
                  error={!(errors.employee_other_income == null)}
                  helperText={errors?.employee_other_income?.message ?? ''}
                  register={register('employee_other_income', { valueAsNumber: true })}
                />
              </RowContainer>
            </>
          </div>
          <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-10'}>
            <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
            <BasicButton
              label={'Next'}
              variant={'primary'}
              onClick={handleSubmit(onSubmit)}
              size={'xs'}
            />
          </div>
        </div>
      )}
    </FormWithStepper>
  )
}
