import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateHarvestingDTO {
  cp_id: number | null | undefined
  method: string
  user_id: number | null | undefined
  farmer_id: number | null | undefined
}

export const updateHarvesting = async (params: UpdateHarvestingDTO): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/harvesting`, params)
}
