import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface DeleteFarmDTO {
  farmId: number | null | undefined
  farmerId: number | null | undefined
  userId: number | null | undefined
}
export const deleteFarm = async (params: DeleteFarmDTO): Promise<ApiResponse<[]>> => {
  return axios.delete(
    `/farm?FARM_ID=${params.farmId ?? ''}&FARMER_ID=${params.farmerId ?? ''}&LAST_UPDATED_BY=${
      params.userId ?? ''
    }`
  )
}
