import { ShareHolderDetails } from 'features/authentication/components/company/ShareHolderDetails'
import { useCompanyRegistrationStore } from 'features/authentication/stores/useCompanyRegistrationStore'
import type React from 'react'
import { useState } from 'react'

interface CompanyShareHoldersProps {
  onNext: () => void
  onBack: () => void
}

export const CompanyShareHolders: React.FC<CompanyShareHoldersProps> = ({ onBack, onNext }) => {
  const { companyDetails } = useCompanyRegistrationStore()
  const shareHoldersLength = companyDetails!.shareholder?.length
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
    if (shareHoldersLength != null && activeStep === shareHoldersLength - 1) {
      onNext()
    }
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
    if (activeStep === 0) {
      onBack()
    }
  }

  const returnComponent = (id: number): React.ReactNode => {
    return (
      <ShareHolderDetails
        className={'flex flex-col gap-2 md:gap-4 lg:gap-6 w-full'}
        key={id}
        shareHolderName={companyDetails!.shareholder[activeStep]}
        shareHolderNumber={activeStep}
        totalShareHolders={shareHoldersLength}
        onNext={handleNext}
        onBack={handleBack}
      />
    )
  }

  return returnComponent(activeStep)
}
