import type React from 'react'
import { Typography } from '@mui/material'
import { CheckCircle, HighlightOff, PendingActions } from '@mui/icons-material'

import { type GetInventoryRes } from '../../../farmer/inventory/types'

interface LabInventoryStatusProps {
  inventory: GetInventoryRes
  className: string
}

export const LabInventoryStatus: React.FC<LabInventoryStatusProps> = ({ inventory, className }) => {
  if (inventory.item_verification_status === 'VERIFIED') {
    return (
      <div className={className}>
        <CheckCircle sx={{ color: 'success.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'success.main' }}>
          Approved
        </Typography>
      </div>
    )
  }

  if (inventory.item_verification_status === 'TO BE REASSESSED') {
    return (
      <div className={className}>
        <HighlightOff sx={{ color: 'error.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'error.main' }} noWrap>
          Pending Re-Assessment
        </Typography>
      </div>
    )
  }

  if (inventory.item_verification_status === 'REJECTED') {
    return (
      <div className={className}>
        <HighlightOff sx={{ color: 'error.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'error.main' }} noWrap>
          Rejected
        </Typography>
      </div>
    )
  }

  return (
    <div className={className}>
      <PendingActions sx={{ color: 'warning.main' }} fontSize={'small'} />
      <Typography variant={'h6'} sx={{ color: 'warning.main' }}>
        Awaiting Verification
      </Typography>
    </div>
  )
}
