interface District {
  district_id: number
  district_name: string
}
export const Districts: District[] = [
  {
    district_id: 5,
    district_name: 'KGATLENG'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)'
  },
  {
    district_id: 3,
    district_name: 'GHANZI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN'
  },
  {
    district_id: 6,
    district_name: 'KWENENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST'
  },
  {
    district_id: 2,
    district_name: 'CHOBE'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST'
  }
]
