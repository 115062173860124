import type React from 'react'
import { addZeroToTime } from 'utils/utilFunctions'
import { Text } from 'components/Elements/Text'
interface ResendOtpProps {
  seconds: number
  minutes: number
  hours: number
  isDisabled: boolean
  onResend: () => void
}

export const ResendOtp: React.FC<ResendOtpProps> = ({
  hours,
  minutes,
  seconds,
  isDisabled,
  onResend
}) => {
  return (
    <div className={'flex gap-2'}>
      {isDisabled ? (
        <Text size={'medium'} className={'flex gap-2'}>
          Resend OTP in{' '}
          <Text variant={'success'} size={'medium'}>
            {hours > 0
              ? `${addZeroToTime(hours)}:${addZeroToTime(minutes)}:${addZeroToTime(seconds)}`
              : `${addZeroToTime(minutes)}:${addZeroToTime(seconds)}`}
          </Text>
        </Text>
      ) : null}
      <button
        className={`flex items-center ${
          isDisabled ? 'text-primary hidden' : ' flex cursor-pointer'
        }`}
        disabled={isDisabled}
        aria-disabled={isDisabled}
        type={'button'}
        onClick={isDisabled ? undefined : onResend}
      >
        <Text
          size={'medium'}
          variant={'primary'}
          className={'flex items-center hover:text-primary-focus/70'}
        >
          Resend OTP
          <p className='material-symbols-outlined'>restart_alt</p>
        </Text>
      </button>
    </div>
  )
}
