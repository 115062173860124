import { useOtpVerification } from 'hooks/useOtpVerification'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useOfficerStore } from 'stores/useOfficerStore'
import { type Farm } from 'types'
import type z from 'zod'
import { boolean, object } from 'zod'
import { Button, Form, FormControl, FormField, FormItem, FormLabel, Text } from 'components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox } from 'components/ui/checkbox'

interface ProvidedInformationProps {
  farm: Farm
  className?: string
  onApprove: () => void
  onCancel: () => void
}

const declarationsSchema = object({
  omang: boolean()
    .default(false)
    .refine((value) => value, {
      message: 'Option is required.'
    }),
  certificates: boolean()
    .default(false)
    .refine((value) => value, {
      message: 'Option is required.'
    }),
  info: boolean()
    .default(false)
    .refine((value) => value, {
      message: 'Option is required.'
    })
})

const FormSchema = object({
  declarations: declarationsSchema.refine(
    (value) => value.omang && value.info && value.certificates
  )
})

export const ProvidedInformation: React.FC<ProvidedInformationProps> = ({
  className,
  farm,
  onApprove,
  onCancel
}) => {
  const { officer } = useOfficerStore()
  const { isLoading, sendOtpMessage } = useOtpVerification('', farm.farmer_contact)

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      declarations: { omang: false, certificates: false, info: false }
    }
  })

  const onSubmit = (data: z.infer<typeof FormSchema>): void => {
    sendOtpMessage(onApprove)
  }

  return (
    <Form {...form}>
      <form
        className={`w-full flex-col gap-4 sm:gap-8 ${className ?? ''}`}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className={'flex flex-col justify-center items-center gap-8'}>
          <Text size={'medium'}>Declaration by Extension Officer</Text>
        </div>

        <FormField
          control={form.control}
          name='declarations'
          render={() => (
            <FormItem className='flex flex-col items-start space-x-3 space-y-0 p-4'>
              <FormItem className='w-full flex flex-col items-start space-x-3 space-y-0 gap-4'>
                <Text>
                  I certify that the information given is true and complete to the best of my
                  knowledge. I understand that if I have deliberately given any false information or
                  have withheld any information regarding any Temo Letlotlo Programme transactions,
                  I am liable for prosecution for fraud and/or perjury.
                  <br /> <br />I{' '}
                  <span className={'text-primary text-semibold'}>
                    {officer?.firstname} {officer?.surname}
                  </span>
                  , acknowledge receipt of the following:
                </Text>
              </FormItem>
              <FormField
                key={React.useId()}
                control={form.control}
                name='declarations.omang'
                render={({ field }) => (
                  <FormItem className='flex flex-row items-start space-x-3 space-y-0 p-2'>
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <div className='space-y-1 leading-none'>
                      <FormLabel>Certified copy of OMANG</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                key={React.useId()}
                control={form.control}
                name='declarations.certificates'
                render={({ field }) => (
                  <FormItem className='flex flex-row items-start space-x-3 space-y-0 p-2'>
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <div className='space-y-1 leading-none'>
                      <FormLabel>Certified copy of certificates</FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                key={React.useId()}
                control={form.control}
                name='declarations.info'
                render={({ field }) => (
                  <FormItem className='flex flex-row items-start space-x-3 space-y-0 p-2'>
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <div className='space-y-1 leading-none'>
                      <FormLabel>
                        Land and Farmer details on the system correspond to the submitted documents
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
            </FormItem>
          )}
        />

        <div className={'flex items-center gap-8'}>
          <Button variant={'destructive'} onClick={onCancel} size={'lg'} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'lg'}
            disabled={isLoading}
            loading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </Form>
  )
}
