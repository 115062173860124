import { MainContainer } from 'features/loan-management/components/stepper'
import type React from 'react'
import { useState } from 'react'
import { BasicButton } from 'components/Elements/Button'
import { LoanStepper } from './LoanStepper'
import 'primereact/resources/themes/luna-blue/theme.css'
import './flags.css'
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import { type PostToNDB } from '../../../types/individual'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { postToNDB } from '../../../api/postToNDB'
import { applicationHeader } from '../stores/applicationHeader'
import { Typography } from '@mui/material'
import { type LoanStepperProps } from '../../../types'

interface DocumentUpload {
  name: string
  uploadedFile: File | null
  uploadedFileName: string
  base64Content?: string
}

export const AttachmentRepo: React.FC<LoanStepperProps> = ({
  steps,
  activeStep,
  onBack,
  onNext
}) => {
  const initialDocuments: DocumentUpload[] = [
    { name: 'loan_application_form', uploadedFile: null, uploadedFileName: '' },
    {
      name: 'confirmation_of_membership_grain_members_association',
      uploadedFile: null,
      uploadedFileName: ''
    },
    { name: 'identity_documents', uploadedFile: null, uploadedFileName: '' },
    { name: 'marriage_certificate', uploadedFile: null, uploadedFileName: '' },
    { name: 'letter_of_consent', uploadedFile: null, uploadedFileName: '' },
    { name: 'proof_of_marriage_regime', uploadedFile: null, uploadedFileName: '' },
    { name: 'tax_clearance_certificate', uploadedFile: null, uploadedFileName: '' },
    { name: 'acknowlegment_of_application', uploadedFile: null, uploadedFileName: '' },
    { name: 'soil_test', uploadedFile: null, uploadedFileName: '' },
    { name: 'land_board_certificate', uploadedFile: null, uploadedFileName: '' },
    { name: 'title_deed', uploadedFile: null, uploadedFileName: '' },
    { name: 'lease_management', uploadedFile: null, uploadedFileName: '' },
    { name: 'loan_statement', uploadedFile: null, uploadedFileName: '' },
    { name: 'utility_bill', uploadedFile: null, uploadedFileName: '' },
    { name: 'confirmation_letter', uploadedFile: null, uploadedFileName: '' }
  ]

  const [documents, setDocuments] = useState<DocumentUpload[]>(initialDocuments)
  const navigate = useNavigate()

  const handleFileChange = (index: number, event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]

    if (file != null) {
      const uploadedFileName = file.name !== undefined ? file.name : ''
      const reader = new FileReader()
      reader.onload = () => {
        const result = reader.result as ArrayBuffer
        setDocuments((prevDocuments) => {
          const newDocuments = [...prevDocuments]
          newDocuments[index].uploadedFile = file
          newDocuments[index].uploadedFileName = uploadedFileName
          newDocuments[index].base64Content = btoa(
            new Uint8Array(result).reduce((data, byte) => data + String.fromCharCode(byte), '')
          )
          return newDocuments
        })
      }

      reader.readAsArrayBuffer(file)
    }
  }

  const handleDelete = (index: number): void => {
    setDocuments((prevDocuments) => {
      const newDocuments = [...prevDocuments]
      newDocuments[index].uploadedFile = null
      newDocuments[index].uploadedFileName = ''
      return newDocuments
    })
  }

  const mutation = useMutation({
    mutationFn: async (params: PostToNDB) => {
      return postToNDB(params)
    }
  })
  const [loanId] = useAtom(applicationHeader)

  const onSubmit = (): void => {
    const requiredDocuments = [
      'loan_application_form',
      'identity_documents',
      'tax_clearance_certificate',
      'acknowlegment_of_application',
      'soil_test'
    ]

    const missingDocuments = requiredDocuments.filter((docName) => {
      const document = documents.find((d) => d.name === docName)
      return document == null || document.uploadedFile === null
    })

    if (missingDocuments.length > 0) {
      // Display an error message with the names of missing documents
      const missingDocumentNames = requiredDocuments.filter((docName) => {
        const document = documents.find((d) => d.name === docName)
        return document === undefined || document.uploadedFile === null
      })

      if (missingDocumentNames.length > 0) {
        toast.error(
          `Please upload the following required documents: ${missingDocumentNames.join(', ')}`
        )
      } else {
        // Handle the case where missingDocumentNames is empty
        toast.error('Please upload all required documents.')
      }

      return
    }

    const loanApplication: PostToNDB = {
      loan_application_id: 0,
      documents: {
        loan_application_form: '',
        confirmation_of_membership_grain_members_association: '',
        identity_documents: '',
        marriage_certificate: '',
        letter_of_consent: '',
        proof_of_marriage_regime: '',
        tax_clearance_certificate: '',
        acknowlegment_of_application: '',
        soil_test: '',
        land_board_certificate: '',
        title_deed: '',
        lease_management: '',
        loan_statement: '',
        utility_bill: '',
        confirmation_letter: ''
      }
    }

    documents.forEach((document) => {
      if (document.base64Content != null) {
        loanApplication.documents[document.name] = document.base64Content
      } else {
        if (!requiredDocuments.includes(document.name)) {
          loanApplication.documents[document.name] = 'empty'
        } else {
          loanApplication.documents[document.name] = ''
        }
      }
    })

    mutation.mutate(
      {
        loan_application_id: loanId?.loan_application_id as number,
        documents: {
          loan_application_form: loanApplication.documents.loan_application_form,
          acknowlegment_of_application: loanApplication.documents.acknowlegment_of_application,
          confirmation_letter: loanApplication.documents.confirmation_letter,
          identity_documents: loanApplication.documents.identity_documents,
          confirmation_of_membership_grain_members_association:
            loanApplication.documents.confirmation_of_membership_grain_members_association,
          land_board_certificate: loanApplication.documents.land_board_certificate,
          lease_management: loanApplication.documents.lease_management,
          letter_of_consent: loanApplication.documents.letter_of_consent,
          loan_statement: loanApplication.documents.loan_statement,
          marriage_certificate: loanApplication.documents.marriage_certificate,
          proof_of_marriage_regime: loanApplication.documents.proof_of_marriage_regime,
          soil_test: loanApplication.documents.soil_test,
          tax_clearance_certificate: loanApplication.documents.tax_clearance_certificate,
          title_deed: loanApplication.documents.title_deed,
          utility_bill: loanApplication.documents.utility_bill
        }
      },
      {
        onSuccess: (result) => {
          if (result.status === 201 && result.success) {
            toast.success('Your loan is submitted to the NDB!')
            navigate('/farmer/loans')
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast.error(
              mapCmsErrorToMessage(error.status.toString() ?? err.code ?? error.data.message ?? '')
            )
          } else {
            const error = err as Error
            toast.error(mapCmsErrorToMessage(error.message))
          }
        }
      }
    )
  }
  return (
    <LoanStepper
      steps={steps}
      activeStep={activeStep}
      next={onNext}
      back={onBack}
      stepperKey={'attachments'}
    >
      <MainContainer>
        <form onSubmit={onSubmit}>
          <Paper style={{ padding: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>File</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {documents.map((document, index) => (
                  <TableRow key={index}>
                    <TableCell>{document.name}</TableCell>
                    <TableCell>
                      {document.uploadedFileName !== '' ? (
                        <>
                          <Typography>{document.uploadedFileName}</Typography>
                        </>
                      ) : (
                        <>
                          <label htmlFor={`file-input-${index}`}>
                            <Button
                              component='span'
                              variant='contained'
                              color='primary'
                              startIcon={<CloudUploadIcon />}
                            >
                              Upload
                            </Button>
                          </label>
                          <input
                            id={`file-input-${index}`}
                            type='file'
                            accept='.pdf,.doc,.docx,.jpg,.jpeg,.png'
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              handleFileChange(index, e)
                            }}
                          />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {document.uploadedFileName !== '' && (
                        <IconButton
                          onClick={() => {
                            handleDelete(index)
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </form>
        <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
          <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
          <BasicButton
            label={'Next'}
            variant={'primary'}
            onClick={onSubmit}
            size={'xs'}
            disabled={mutation.isLoading}
            isLoading={mutation.isLoading}
          />
        </div>
      </MainContainer>
    </LoanStepper>
  )
}
