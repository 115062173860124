import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type FarmerContact } from 'types'

export const getFarmerContact = async (
  omang: string | null | undefined
): Promise<ApiResponseWithObject<FarmerContact>> => {
  return axios.get(`/farmer/contact?OMANG=${omang ?? ''}`)
}

export const useGetFarmerContact = (
  omang: string | null | undefined
): UseQueryResult<ApiResponseWithObject<FarmerContact>> => {
  return useQuery({
    queryKey: ['redemption-contact', omang],
    queryFn: async () => getFarmerContact(omang),
    enabled: omang != null
  })
}
