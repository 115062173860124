import type { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import dayjs from 'dayjs'
import type { FarmerVoucherTransaction } from 'types'
import { getMerchantType } from 'utils/getMerchantTypes'

export const historyColumns: Array<ColumnDef<FarmerVoucherTransaction>> = [
  {
    id: 'transaction id',
    accessorFn: (row) => row.transaction_id,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('transaction id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Transaction ID' />
  },
  {
    id: 'date',
    accessorFn: (row) => row.date,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => (
      <div className='uppercase'>{dayjs(row.getValue('date')).format('DD-MM-YYYY')}</div>
    ),
    header: ({ column }) => <DataTableColumnHeader column={column} title='Date' />
  },
  {
    id: 'merchant',
    accessorFn: (row) => row.merchant_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('merchant')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Merchant' />
  },
  {
    id: 'type',
    accessorFn: (row) => row.merchant_type_id,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => (
      <div className='uppercase'>{getMerchantType(parseInt(row.getValue('type')))}</div>
    ),
    header: ({ column }) => <DataTableColumnHeader column={column} title='Type' />
  },
  {
    id: 'disbursed',
    accessorFn: (row) => row.voucher_expensed_amount,
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Disbursed' />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('disbursed'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  },
  {
    id: 'available',
    accessorFn: (row) => row.voucher_avail_balance,
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Available' />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('available'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]
