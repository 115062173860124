import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type AxiosError } from 'axios'
import { type InventoryTransfer } from '../types'

export const getOriginFarmerTransferRequest = async (
  destFarmerId: number | null | undefined
): Promise<ApiResponse<InventoryTransfer>> => {
  return axios.get(`/inventory/transfer?origin_farmer_id=${destFarmerId ?? ''}&is_active=true`)
}
export const useOriginFarmerTransfers = (
  farmerId: number | null | undefined
): UseQueryResult<ApiResponse<InventoryTransfer>, AxiosError> => {
  return useQuery({
    queryKey: ['originInvTrans', farmerId],
    queryFn: async () => getOriginFarmerTransferRequest(farmerId),
    enabled: farmerId != null
  })
}
