import React from 'react'
import { ROLES } from '../../types'
import { ErrorPage } from '../Errors/ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import { RequiresAuth } from 'features/authentication/components/RequiresAuth'

const MechanizationDashboardLayout = React.lazy(
  async () => import('../Layout/MechanizationDashboardLayout')
)

export const Mechanization: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <RequiresAuth allowedRoles={[ROLES.MECHANIZATION]}>
        <MechanizationDashboardLayout />
      </RequiresAuth>
    </ErrorBoundary>
  )
}
