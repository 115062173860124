import { type Officer } from 'types'
import { create } from 'zustand'

interface OfficerStoreState {
  officer: Officer | null
  setOfficer: (officer: Officer | null) => void
}

export const useOfficerStore = create<OfficerStoreState>()((set) => ({
  officer: null,
  setOfficer: (officer: Officer | null) => {
    set((state) => ({ ...state, officer }))
  }
}))
