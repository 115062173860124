import type React from 'react'
import { type SoilSampleAnalysis } from 'types'
import { ScrollArea, Text } from 'components/ui'
import { Separator } from 'components/ui/separator'
import { ChevronDown } from 'lucide-react'
import dayjs from 'dayjs'

interface ViewSoilSampleAnalysisProps {
  ssa: SoilSampleAnalysis
}

export const ViewSoilSampleAnalysis: React.FC<ViewSoilSampleAnalysisProps> = ({ ssa }) => {
  return (
    <div className={'relative w-full h-[600px] flex flex-col items-center gap-2'}>
      <Text size={'medium'}>
        {/* {ssa.farm_name} */}
        Soil Sample Analysis
      </Text>
      <Separator />
      <ScrollArea className='h-[560px] w-full border-b p-4'>
        <div className={'flex flex-col gap-4'}>
          <Text size={'medium'} variant={'secondary'}>
            General Information
          </Text>
          <div className={'flex'}>
            <div className={'flex flex-1 flex-col'}>
              <Text size={'small'}>Ploughing Season</Text>
              <Text className={'font-semibold'}>{ssa.plough_season}</Text>
              {/* <p>{ssa.plough_season}</p> */}
            </div>
            <div className={'flex flex-1 flex-col'}>
              <Text size={'small'}>Soil Sample Expiry Date</Text>
              <Text className={'font-semibold'}>{dayjs(ssa.expiry_date).format('DD-MM-YYYY')}</Text>
              {/* <p>{moment(ssa.expiry_date).format('DD-MM-YYYY')}</p> */}
            </div>
          </div>
          <div className={'flex flex-1 flex-col'}>
            <Text size={'small'}>Laboratory Name</Text>
            <Text className={'font-semibold'}>{ssa.laboratory_name}</Text>
            {/* {ssa.laboratory_name} */}
          </div>
        </div>

        <div className={'flex flex-col gap-4 mt-8'}>
          <Text size={'medium'} variant={'secondary'}>
            Soil Sample Analysis
          </Text>
          <div className={'flex flex-wrap gap-8'}>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Electrical Conductivity, EC (mSm-1)</Text>
              <Text className={'font-semibold'}>{ssa.ec_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Magnesium, Mg (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.mg_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Manganese,Mn (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.mn_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Sodium, Na (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.na_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Copper, Cu (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.cu_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Potassium, K (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.k_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Calcium, Ca (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.ca_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Iron, Fe (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.fe_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>pH</Text>
              <Text className={'font-semibold'}>{ssa.ph_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Zinc, Zn (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.zn_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Phosphorus, P (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.p_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Sulphur, S (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.s_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Organic Carbon (%)</Text>
              <Text className={'font-semibold'}>{ssa.c_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Cation Exchange Capacity, CEC (mol/kg)</Text>
              <Text className={'font-semibold'}>{ssa.cec_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Bulk Density, (g/cm3)</Text>
              <Text className={'font-semibold'}>{ssa.bulk_density}</Text>
            </div>
          </div>
        </div>
      </ScrollArea>
      <ChevronDown className={'absolute bottom-[-25px]'} />
    </div>
  )
}
