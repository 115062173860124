import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createThreshing,
  type CreateThreshingDTO
} from 'features/farmer/cropping-plan/api/threshing/createThreshing'
import {
  updateThreshing,
  type UpdateThreshingDTO
} from 'features/farmer/cropping-plan/api/threshing/updateThreshing'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, type ShellingAndThreshing } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from 'components/ui'
import { type z } from 'zod'
import { type shellingAndThreshingSchema } from '../components/steps/cleaning/ShellingAndThreshing'

interface UseCroppingPlanShellingAndThreshing {
  isLoading: boolean
  createShellingAndThreshing: (data: z.infer<typeof shellingAndThreshingSchema>) => void
  updateShellingAndThreshing: (data: z.infer<typeof shellingAndThreshingSchema>) => void
  setShellingAndThreshing: (shelling: ShellingAndThreshing | null) => void
  header: CroppingPlan | null
}

export const useCroppingPlanShellingAndThreshing = (
  next: () => void
): UseCroppingPlanShellingAndThreshing => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { header, setShellingAndThreshing } = useCroppingPlanStore()

  const createThreshingMutation = useMutation({
    mutationFn: async (cpDto: CreateThreshingDTO) => {
      return createThreshing(cpDto)
    }
  })

  const updateThreshingMutation = useMutation({
    mutationFn: async (cpDto: UpdateThreshingDTO) => {
      return updateThreshing(cpDto)
    }
  })

  const createShellingAndThreshing = (data: z.infer<typeof shellingAndThreshingSchema>): void => {
    createThreshingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        method: data.technique
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['threshing'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateShellingAndThreshing = (data: z.infer<typeof shellingAndThreshingSchema>): void => {
    updateThreshingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        method: data.technique
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['threshing'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    isLoading: createThreshingMutation.isLoading || updateThreshingMutation.isLoading,
    createShellingAndThreshing,
    updateShellingAndThreshing,
    setShellingAndThreshing,
    header
  }
}
