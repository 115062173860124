import { Mechanization } from 'components/Auth/Mechanization'
import { SearchInventories } from 'features/mechanisationOfficer/inventory/routes'
import { ChangeMechanizationPassword } from 'features/mechanisationOfficer/profile/change-password/routes/ChangeMechanizationPassword'
import { Navigate } from 'react-router-dom'
import { OfficerProfile } from '../features/profile/routes/Officer'

export const inventoryRoutes = {
  path: '/inventory',
  element: <Mechanization />,
  children: [
    { path: '/inventory', element: <SearchInventories /> },
    { path: '/inventory/change-password', element: <ChangeMechanizationPassword /> },
    { path: '/inventory/profile', element: <OfficerProfile /> },
    { path: '*', element: <Navigate to={'.'} /> }
  ]
}
