import type React from 'react'
import { useState } from 'react'
import { type Lease } from 'types'
import { AcceptLease } from './AcceptLease'
import { LeaseTable } from './LeaseTable'
import { Text } from '../../../../components/Elements/Text'
import { Dialog, DialogContent } from '../../../../components/ui'

interface LeaseResultsProps {
  leases: Lease[]
  className: string
  invalidateSearch: () => void
}

export const LeaseResults: React.FC<LeaseResultsProps> = ({
  leases,
  className,
  invalidateSearch
}) => {
  const [open, setOpen] = useState(false)
  const [clickedData, setClickedData] = useState<Lease[]>([])

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  const onClickLease = (item: Lease): void => {
    setClickedData((prevClickedData) => [...prevClickedData, item])
    openDialog()
  }

  const handleAcceptLease = (leaseId: number): void => {
    invalidateSearch()
  }

  return (
    <div className={className}>
      <Text size={'medium'} variant={'primary'} className={'ml-4'}>
        Search results: {leases.length}
      </Text>
      <div className={'bg-background w-full flex flex-col gap-2 sm:gap-4 p-4'}>
        <LeaseTable leases={leases} onClick={onClickLease} />
      </div>

      <Dialog open={open} defaultOpen={false} modal onOpenChange={closeDialog}>
        <DialogContent className={'max-w-[80%] md:max-w-[70%] lg:max-w-[40%]'}>
          <AcceptLease
            lease={clickedData?.[0]}
            className={'flex flex-col gap-4'}
            onClose={closeDialog}
            onStatusChange={handleAcceptLease}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
