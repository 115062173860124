import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import { Text } from 'components/ui'

interface CroppingPlanStatusesProps {
  croppingPlan: CroppingPlan
}

export const CroppingPlanStatuses: React.FC<CroppingPlanStatusesProps> = ({ croppingPlan }) => {
  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Approved) {
    return (
      <Text size={'small'} variant={'success'}>
        Approved
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_CP_Verification) {
    return (
      <Text size={'small'} variant={'error'}>
        Cropping Plan Rejected
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_Farm_Assessment_Verification) {
    return (
      <Text size={'small'} variant={'error'}>
        Assessment Rejected
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment) {
    return (
      <Text size={'small'} variant={'warning'}>
        Awaiting Assessment
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_Farm_Assessment) {
    return (
      <Text size={'small'} variant={'warning'}>
        Information Required
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Submitted_For_Review) {
    return (
      <Text size={'small'} variant={'warning'}>
        Submitted
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_CP_Verification) {
    return (
      <Text size={'small'} variant={'primaryLight'}>
        Information Required
      </Text>
    )
  }

  return (
    <Text size={'small'} variant={'primaryLight'}>
      Draft
    </Text>
  )
}
