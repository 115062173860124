import { RequiresAuth } from 'features/authentication/components/RequiresAuth'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ROLES } from '../../types'
import { ErrorPage } from '../Errors/ErrorPage'

const SeedDealersDashboard = React.lazy(async () => import('../Dashboard/SeedDealer'))

export const Seeds: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <RequiresAuth allowedRoles={[ROLES.SEEDDEALER]}>
        <SeedDealersDashboard />
      </RequiresAuth>
    </ErrorBoundary>
  )
}
