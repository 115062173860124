import { auth } from 'config/firebase'
import { signOut } from 'firebase/auth'
import type React from 'react'
import { useNavigate } from 'react-router-dom'

export const Unauthorized: React.FC = () => {
  const navigate = useNavigate()

  const handleGoToLogin = (): void => {
    void signOut(auth)
    navigate('/login/phone', { replace: true })
  }

  return (
    <main className={'flex flex-col gap-4 h-screen justify-center items-center'}>
      <h1 className={'text-error text:lg md:text-2xl font-bold'}>
        Error 401 - Unauthorized Access
      </h1>
      <p className={'text-md md:text-xl w-[15rem] md:w-[25rem] text-center'}>
        Sorry your account does not have access to this page.
      </p>
      <p className={'text-md md:text-xl'}>
        Go to login{' '}
        <a className={'cursor-pointer'} onClick={handleGoToLogin}>
          page
        </a>
      </p>
    </main>
  )
}
