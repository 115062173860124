import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Lease } from 'types'
import { type AxiosError } from 'axios'

export const getLeases = async (
  farmerId: number | null | undefined
): Promise<ApiResponse<Lease>> => {
  return axios.get(`/lease?DEST_FARMER_ID=${farmerId ?? ''}`)
}

export const useGetLeases = (
  farmerId: number | null | undefined
): UseQueryResult<ApiResponse<Lease>, AxiosError> => {
  return useQuery({
    queryKey: ['leases', farmerId],
    queryFn: async () => getLeases(farmerId),
    enabled: farmerId != null
  })
}
