import type z from 'zod'
import { object, string } from 'zod'
import type React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from '../../../../components/ui'
import { useSoilSampleRequest } from '../hooks/useSoilSampleRequest'
import { type SoilSampleRequest } from '../../../../types'

const rejectSchema = object({
  reason: string().min(1, 'Reason for rejection is required')
})

interface RejectSSRProps {
  ssr: SoilSampleRequest
  cancel: () => void
}

export const RejectSSR: React.FC<RejectSSRProps> = ({ ssr, cancel }) => {
  const { rejectSoilSampleRequest, isLoading } = useSoilSampleRequest()

  const form = useForm<z.infer<typeof rejectSchema>>({
    resolver: zodResolver(rejectSchema),
    defaultValues: {
      reason: ''
    }
  })
  const onSubmit = (data: z.infer<typeof rejectSchema>): void => {
    rejectSoilSampleRequest(ssr, data.reason)
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={'reason'}
          render={({ field }) => (
            <FormItem className='flex flex-col gap-2'>
              <FormDescription className={'text-center'}>
                Please provide the reason for rejecting the Soil Sample Request
              </FormDescription>
              <FormLabel className={'font-redHat'}>Rejection Reason</FormLabel>
              <FormControl>
                <Input
                  type={'text'}
                  placeholder=''
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex justify-between'>
          <Button
            variant={'destructive'}
            onClick={cancel}
            disabled={isLoading}
            className={'min-w-24'}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            type={'submit'}
            className={'min-w-24'}
            variant={'success'}
          >
            Reject SSR
          </Button>
        </div>
      </form>
    </Form>
  )
}
