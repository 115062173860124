import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createLandPreparation,
  type CreateLandPreparationDTO
} from 'features/farmer/cropping-plan/api/land-preparation/createLandPreparation'
import {
  updateLandPreparation,
  type UpdateLandPreparationDTO
} from 'features/farmer/cropping-plan/api/land-preparation/updateLandPreparation'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, type LandPreparation } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { type z } from 'zod'
import { type landPreparationSchema, toBoolean } from '../components/steps/land/LandPreparation'
import { useToast } from '../../../../components/ui'

interface UseCroppingPlanLandPreparation {
  isLoading: boolean
  createLandPreparationPlan: (data: z.infer<typeof landPreparationSchema>) => void
  updateLandPreparationPlan: (data: z.infer<typeof landPreparationSchema>) => void
  landPreparation: LandPreparation | null
  setLandPreparation: (landPreparation: LandPreparation | null) => void
  header: CroppingPlan | null
}

export const useCroppingPlanLandPreparation = (
  next: () => void
): UseCroppingPlanLandPreparation => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { header, landPreparation, setLandPreparation } = useCroppingPlanStore()

  const createLandPreparationMutation = useMutation({
    mutationFn: async (cpDto: CreateLandPreparationDTO) => {
      return createLandPreparation(cpDto)
    }
  })

  const updateLandPreparationMutation = useMutation({
    mutationFn: async (cpDto: UpdateLandPreparationDTO) => {
      return updateLandPreparation(cpDto)
    }
  })

  const createLandPreparationPlan = (data: z.infer<typeof landPreparationSchema>): void => {
    createLandPreparationMutation.mutate(
      {
        tillage_method: data.technique,
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        is_harrowing: data.technique !== 'No Till' ? toBoolean(data.harrowing) : false
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['land-preparation'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateLandPreparationPlan = (data: z.infer<typeof landPreparationSchema>): void => {
    updateLandPreparationMutation.mutate(
      {
        tillage_method: data.technique,
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        is_harrowing: data.technique !== 'No Till' ? toBoolean(data.harrowing) : false
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['land-preparation'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    isLoading: createLandPreparationMutation.isLoading || updateLandPreparationMutation.isLoading,
    createLandPreparationPlan,
    updateLandPreparationPlan,
    landPreparation,
    setLandPreparation,
    header
  }
}
