import { axios } from 'lib/axios'
import { type ApiResponse, type FileReference } from 'types'

export interface VerifyLeaseDTO {
  LEASE_ID: number
  LAST_UPDATED_BY: number | null | undefined
  STATUS_ID: number
  LEASE_MESSAGE: string
}

export const verifyLease = async (params: VerifyLeaseDTO): Promise<ApiResponse<FileReference>> => {
  return axios.put(`/lease/verify`, params)
}
