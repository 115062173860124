import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type SoilSampleAnalysis } from 'types'

export const getSoilAnalysis = async (
  farmId: string | null | undefined,
  farmerId: string | null | undefined,
  ssrId: string | null | undefined,
  merchantId: number | null | undefined
): Promise<ApiResponse<SoilSampleAnalysis>> => {
  return axios.get(
    `ssr/analysis?farmer_id=${farmerId ?? ''}&farm_id=${farmId ?? ''}&ssr_id=${
      ssrId ?? ''
    }&merchant_id=${merchantId ?? ''}`
  )
}

export const useGetSoilAnalysis = (
  farmId: string | null | undefined,
  farmerId: string | null | undefined,
  ssrId: string | null | undefined,
  merchantId: number | null | undefined
): UseQueryResult<ApiResponse<SoilSampleAnalysis>, AxiosError> => {
  return useQuery({
    queryKey: ['soil-analysis', ssrId, farmerId, farmId],
    queryFn: async () => getSoilAnalysis(farmId, farmerId, ssrId, merchantId),
    enabled: farmerId != null && farmId != null && ssrId != null && merchantId != null
  })
}
