import { axios } from 'lib/axios'
import { type ApiResponseWithObject } from 'types'
import { type SelectedSeeds } from '../types'
import { type SuccessfulVoucherRedemption } from 'features/merchant/types'

export interface SeedsVoucherDTO {
  merchant_id: number | null | undefined
  omang: string | null | undefined
  seeds: SelectedSeeds[]
}

export const redeemSeedsVoucher = async (
  payload: SeedsVoucherDTO
): Promise<ApiResponseWithObject<SuccessfulVoucherRedemption>> => {
  return axios.post('/voucher/redemption/seeding', payload)
}
