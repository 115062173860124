/* eslint-disable prettier/prettier */
interface StepsLabelProps {
  label: string
  value: string
  variant?: 'default' | 'secondary'
}
export const FieldValue: React.FC<StepsLabelProps> = ({ label, value }) => {
  return (
    <div className='flex-[1_0_160px]'>
      <p className='text-[color:var(--color-grey)] text-xs font-medium capitalize'>{label}</p>
      <p className='text-[color:var(--color-secondary-light)] text-md font-medium uppercase'>
        {value}
      </p>
    </div>
  )
}
