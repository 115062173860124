import type React from 'react'
import { Text } from 'components/ui'
interface LeaseStatusProps {
  status:
    | 'INITIATED BY LESSOR'
    | 'ACCEPTED BY LESSEE'
    | 'REJECTED BY LESSEE'
    | 'VERIFIED BY EXTENSION OFFICER'
    | 'TERMINATION INITIATED BY LESSOR'
    | 'TERMINATION INITIATED BY LESSEE'
    | 'TERMINATION ACCEPTED BY LESSEE'
    | 'TERMINATION ACCEPTED BY LESSOR'
    | 'RENEWAL INITIATED BY LESSEE'
    | 'RENEWAL ACCEPTED BY LESSOR'
    | 'EXPIRED'
}

export const LeaseStatus: React.FC<LeaseStatusProps> = ({ status }) => {
  if (status === 'INITIATED BY LESSOR') {
    return (
      <Text size={'medium'} variant={'warning'} className={'font-normal flex gap-2 items-center'}>
        <p className='material-symbols-outlined'>pending_actions</p> Pending
      </Text>
    )
  }

  if (status === 'REJECTED BY LESSEE') {
    return (
      <Text size={'medium'} variant={'error'} className={'font-normal flex gap-2 items-center'}>
        <p className='fa-solid fa-circle-xmark' /> Rejected
      </Text>
    )
  }

  if (status === 'EXPIRED') {
    return (
      <Text size={'medium'} variant={'error'} className={'font-normal flex gap-2 items-center'}>
        <p className='fa-solid fa-circle-xmark' /> Expired
      </Text>
    )
  }

  if (status === 'VERIFIED BY EXTENSION OFFICER') {
    return (
      <Text size={'medium'} variant={'success'} className={'font-normal flex gap-2 items-center'}>
        <p className='fa-solid fa-circle-check' />
        Verified
      </Text>
    )
  }

  return (
    <div className={'flex justify-start gap-2 items-center w-full'}>
      {status === 'ACCEPTED BY LESSEE' && (
        <Text size={'medium'} variant={'success'} className={'font-normal flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-check' />
          Leased
        </Text>
      )}
    </div>
  )
}
