interface SubDistrict {
  sub_district_id: number
  sub_district_name: string
  district_id: number
  district_name: string
}

export const SubDistricts: SubDistrict[] = [
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME'
  }
]
