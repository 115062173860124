import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createInventoryItem } from '../api/createInventoryItem'
import { updateInventoryItem } from '../api/updateInventoryItem'
import { type ApiResponse } from 'types'
import { AxiosError, type AxiosResponse } from 'axios'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { type CreateInventoryItemDTO, type UpdateInventoryItemDTO } from '../types'
import { useNavigate } from 'react-router-dom'
import { type UseMutationResult } from 'react-query'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { toast } from '../../../../components/ui'

interface UseInventoryItemParams {
  isEdit: boolean
  onClose: () => void
}

export const createUpateInventoryItem = ({
  isEdit,
  onClose
}: UseInventoryItemParams): UseMutationResult<
  ApiResponse<[]>,
  unknown,
  CreateInventoryItemDTO | UpdateInventoryItemDTO
> => {
  const { farmer } = useFarmerStore()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: CreateInventoryItemDTO | UpdateInventoryItemDTO) => {
      if (isEdit) {
        return updateInventoryItem(params as UpdateInventoryItemDTO)
      } else {
        return createInventoryItem(params as CreateInventoryItemDTO)
      }
    },
    onSuccess: (result: ApiResponse<[]>) => {
      if (result.status === 201 && result.success) {
        toast({
          variant: 'success',
          title: `Inventory item successfully ${isEdit ? 'Updated' : 'Registered'}.`
        })
        void queryClient.invalidateQueries({ queryKey: [`inventories`, farmer?.farmer_id] })
        if (isEdit) {
          onClose?.()
        } else {
          navigate('/farmer/inventory')
        }
      }
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        const error = err.response as AxiosResponse<ApiResponse<[]>>

        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            error.status.toString() ?? err.code ?? error.data.message ?? ''
          )
        })
      }
    }
  })
}
