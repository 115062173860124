import { type FC } from 'react'
import type { Lease } from '../../../../../types'
import { Button, Text } from '../../../../../components/ui'
import { useRenewLease } from '../../../hooks/useRenewLease'

interface LeaseRenewalProps {
  lease: Lease
  state: 'accept' | 'reject'
  next: () => void
}

export const LeaseRenewal: FC<LeaseRenewalProps> = ({ next, lease, state }) => {
  const { acceptLeaseRenewal, isLoading } = useRenewLease(next)

  const onSubmit = (): void => {
    acceptLeaseRenewal(lease, {
      LAST_UPDATED_BY: undefined,
      LEASE_ID: undefined,
      FARMER_ID: undefined,
      ACCEPTANCE: state === 'accept'
    })
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        {state === 'accept' ? 'Accept' : 'Reject'} Renewal for this lease?
      </Text>

      {state === 'accept' ? (
        <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
          Are you sure you want to <strong>accept</strong> the lease renewal request from
          <strong>{lease.dest_farmer_name}</strong> for this farm?.
        </Text>
      ) : (
        <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
          Are you sure you want to <strong>reject</strong> the lease renewal request from
          <strong>{lease.dest_farmer_name}</strong> for this farm?.
        </Text>
      )}

      {state === 'accept' ? (
        <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
          NOTE: This is an irreversible action.
        </Text>
      ) : null}

      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button disabled={isLoading} variant={'secondary'} className={'w-1/2'} onClick={next}>
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          loading={isLoading}
          className={'w-1/2'}
          variant={'success'}
          onClick={onSubmit}
        >
          {state === 'accept' ? 'Accept' : 'Reject'}
        </Button>
      </div>
    </div>
  )
}
