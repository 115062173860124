import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreateHeaderDTO {
  plough_season: string
  farm_id: number | null | undefined
  farmer_id: number | null | undefined
  crop_id: number
  user_id: number | null | undefined
  hectarage: number
  ssr_id: string | null | undefined
}

export interface CreateHeaderResponse {
  cropping_plan_id: number
}

export const createHeader = async (
  params: CreateHeaderDTO
): Promise<ApiResponse<CreateHeaderResponse>> => {
  return axios.post('/production/header', params)
}
