import { type GetInventoryRes, type Implement, type PoweredImplement, type Tractor } from '../types'
import type React from 'react'
import { RegisterTractors } from './RegisterTractors'
import { ViewTractor } from './ViewTractor'
import { RegisterPoweredImplements } from './RegisterPoweredImplements'
import { ViewPoweredImplement } from './ViewPoweredImplement'
import { RegisterNonPoweredImplements } from './RegisterNonPoweredImplements'
import { ViewImplement } from './ViewImplement'
import { TransferInventory } from './initiate-transfer/TransferInventory'

interface InventoryDetailsProps {
  inventoryData: GetInventoryRes
  inventoryItem: Tractor | Implement | PoweredImplement | undefined
  onClose: () => void
  isModify: boolean
  isMerchant?: boolean
  isTransfer?: boolean
  isView?: boolean
}

export const ModifyInventory: React.FC<InventoryDetailsProps> = ({
  inventoryData,
  inventoryItem,
  onClose,
  isModify,
  isMerchant,
  isTransfer,
  isView
}) => {
  if (inventoryData.inventory_type_id === 1003) {
    // Tractors
    if (isModify) {
      return (
        <RegisterTractors
          key={inventoryData.inventory_id}
          inventoryData={inventoryData}
          inventoryItem={inventoryItem}
          isEdit={true}
          onClose={onClose}
        />
      )
    } else if (isTransfer === true) {
      return (
        <TransferInventory
          inventoryData={inventoryData}
          inventoryItem={inventoryItem!}
          onClose={onClose}
        />
      )
    } else if (isView === true) {
      return (
        <ViewTractor
          inventoryData={inventoryData}
          inventoryItem={inventoryItem!}
          onClose={onClose}
          isMerchant={isMerchant}
          isTransfer={isTransfer}
        />
      )
    }
  } else if (inventoryData.inventory_type_id === 1001) {
    // Powered Implements
    if (isModify) {
      return (
        <RegisterPoweredImplements
          key={inventoryData.inventory_id}
          inventoryData={inventoryData}
          inventoryItem={inventoryItem}
          onClose={onClose}
          isEdit={true}
        />
      )
    } else if (isTransfer === true) {
      return (
        <TransferInventory
          inventoryData={inventoryData}
          inventoryItem={inventoryItem!}
          onClose={onClose}
        />
      )
    } else if (isView === true) {
      return (
        <ViewPoweredImplement
          inventoryData={inventoryData}
          inventoryItem={inventoryItem!}
          onClose={onClose}
          isMerchant={isMerchant}
        />
      )
    }
  } else if (inventoryData.inventory_type_id === 1002) {
    // Non-Powered Implements
    if (isModify) {
      return (
        <RegisterNonPoweredImplements
          key={inventoryData.inventory_id}
          inventoryData={inventoryData}
          inventoryItem={inventoryItem}
          onClose={onClose}
          isEdit={true}
        />
      )
    } else if (isView === true) {
      return (
        <ViewImplement
          inventoryData={inventoryData}
          inventoryItem={inventoryItem}
          onClose={onClose}
          isMerchant={isMerchant}
        />
      )
    } else if (isTransfer === true) {
      return (
        <TransferInventory
          inventoryData={inventoryData}
          inventoryItem={inventoryItem!}
          onClose={onClose}
        />
      )
    }
  }

  return null
}
