import { axios } from 'lib/axios'
import { type ApiResponse, type SoilSampleRequest } from 'types'

export const searchSSR = async (
  userType: string,
  userInput: string,
  landType: string,
  landInput: string,
  merchantId: number | null | undefined
): Promise<ApiResponse<SoilSampleRequest>> => {
  const queryParams = userType === 'omang' ? `omang=${userInput}` : `farmer_id=${userInput}`

  const landParams = landType === 'farmId' ? `farm_id=${landInput}` : `lease_id=${landInput}`
  return axios.get(`ssr?${queryParams}&${landParams}&merchant_id=${merchantId ?? ''}`)
}
