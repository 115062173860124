import { IndividualRegistration } from 'features/authentication/components/individual/IndividualRegistration'
import type React from 'react'
import { useNavigate } from 'react-router-dom'

export const IndividualRegistrationPage: React.FC = () => {
  const navigate = useNavigate()

  const handleBack = (): void => {
    navigate(-1)
  }

  return <IndividualRegistration goBack={handleBack} />
}
