import type React from 'react'
import { useEffect } from 'react'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Label,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from '../../../../../components/ui'
import type {
  GetInventoryRes,
  Implement,
  PoweredImplement,
  Tractor
} from '../../../../farmer/inventory/types'
import { selfPropelledSprayers } from '../../types'
import { useMutation } from '@tanstack/react-query'
import type z from 'zod'
import { array, boolean, coerce, object, string } from 'zod'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { Textarea } from '../../../../../components/ui/textarea'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-hot-toast'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import {
  type PostSelfPropelledSprayersChecklist,
  postSelfPropelledSprayersChecklist
} from '../../api/postPropelledSprayers'
import { useGetPropelledSprayerChecklist } from '../../api/getPropelledSprayers'

interface ChecklistProps {
  onBack: () => void
  className: string
  inventoryData: GetInventoryRes
  inventoryItem: Tractor | Implement | PoweredImplement | undefined
  onClose: () => void
  isEdit: boolean
}

const ChecklistItemSchema = object({
  score: coerce.number().min(1, 'Field is required').or(string()).or(boolean())
})

const ChecklistSchema = object({
  checklistState: array(ChecklistItemSchema),
  comments: string().min(1, 'Field is Required'),
  result: boolean().or(string())
})

export const SelfPropelledSprayers: React.FC<ChecklistProps> = ({
  className,
  inventoryData,
  onClose,
  onBack,
  isEdit
}) => {
  const { officer } = useOfficerStore()
  const defaultValues = selfPropelledSprayers.map((s, i) => {
    return { score: 0 }
  })
  const { data: data1, isLoading } = useGetPropelledSprayerChecklist(inventoryData.inventory_id)
  const form = useForm<z.infer<typeof ChecklistSchema>>({
    resolver: zodResolver(ChecklistSchema),
    defaultValues: {
      checklistState: defaultValues,
      comments: '',
      result: undefined
    }
  })
  const { fields } = useFieldArray({
    control: form.control,
    name: 'checklistState'
  })
  const resultValue = useWatch({
    control: form.control,
    name: 'result'
  })

  const mutation = useMutation({
    mutationFn: async (params: PostSelfPropelledSprayersChecklist) => {
      return postSelfPropelledSprayersChecklist(params)
    }
  })
  useEffect(() => {
    if (isEdit && data1 !== undefined) {
      const latestData = data1.data[data1.data.length - 1]
      // Get the item with the highest index
      form.reset({
        checklistState: [
          { score: latestData.nozel_output },
          { score: latestData.boom },
          { score: latestData.hoses },
          { score: latestData.pump_o_rings },
          { score: latestData.tank },
          { score: latestData.gasket },
          { score: latestData.regultors_guages_controls },
          { score: latestData.belts },
          { score: latestData.propeller_and_agitation },
          { score: latestData.tires },
          { score: latestData.filters },
          { score: latestData.dispenser },
          { score: latestData.measuring_device },
          { score: latestData.gears },
          { score: latestData.lights },
          { score: latestData.starting },
          { score: latestData.gauges },
          { score: latestData.cooling_leakages },
          { score: latestData.fuel_leakages },
          { score: latestData.lubrication_leakages },
          { score: latestData.hydraulic_oil_leakages },
          { score: latestData.transmission_leakages },
          { score: latestData.smoke_color },
          { score: latestData.oil_check },
          { score: latestData.greasing },
          { score: latestData.pto },
          { score: latestData.gear_shifting },
          { score: latestData.lifting_and_releasing },
          { score: latestData.hitch_pins },
          { score: latestData.bearings },
          { score: latestData.mirrors },
          { score: latestData.engine_stalling },
          { score: latestData.positioning_and_draft_control },
          { score: latestData.breaking_system },
          { score: latestData.steering_system },
          { score: latestData.diff_loc }
        ],
        comments: latestData.comments,
        result: latestData.result
      })
    }
  }, [isEdit, data1, form])

  const onSubmit = (data: z.infer<typeof ChecklistSchema>, status: number): void => {
    const result = data.result === 'true'
    mutation.mutate(
      {
        inventory_id: inventoryData.inventory_id,
        nozel_output: Number(data.checklistState[0].score),
        boom: Number(data.checklistState[1].score),
        hoses: Number(data.checklistState[2].score),
        pump_o_rings: Number(data.checklistState[3].score),
        tank: Number(data.checklistState[4].score),
        gasket: Number(data.checklistState[5].score),
        regultors_guages_controls: Number(data.checklistState[6].score),
        belts: Number(data.checklistState[7].score),
        propeller_and_agitation: Number(data.checklistState[8].score),
        tyres: Number(data.checklistState[9].score),
        filters: Number(data.checklistState[10].score),
        dispenser: Number(data.checklistState[11].score),
        measuring_device: Number(data.checklistState[12].score),
        gears: Number(data.checklistState[13].score),
        lights: Number(data.checklistState[14].score),
        starting: Number(data.checklistState[15].score),
        gauges: Number(data.checklistState[16].score),
        cooling_leakages: Number(data.checklistState[17].score),
        fuel_leakages: Number(data.checklistState[18].score),
        lubrication_leakages: Number(data.checklistState[19].score),
        hydraulic_oil_leakages: Number(data.checklistState[20].score),
        transmission_leakages: Number(data.checklistState[21].score),
        smoke_color: Number(data.checklistState[22].score),
        oil_check: Number(data.checklistState[23].score),
        greasing: Number(data.checklistState[24].score),
        pto: Number(data.checklistState[25].score),
        gear_shifting: Number(data.checklistState[26].score),
        lifting_and_releasing: Number(data.checklistState[27].score),
        hitch_pins: Number(data.checklistState[28].score),
        bearings: Number(data.checklistState[29].score),
        mirrors: Number(data.checklistState[30].score),
        engine_stalling: Boolean(data.checklistState[31].score),
        positioning_and_draft_control: Boolean(data.checklistState[32].score),
        breaking_system: Boolean(data.checklistState[33].score),
        steering_system: Boolean(data.checklistState[34].score),
        diff_loc: Boolean(data.checklistState[35].score),

        last_update_by: Number(officer?.user_id),
        result,
        item_verification_status: status,
        comments: data.comments
      },
      {
        onSuccess: (res) => {
          if (res.status === 201 && res.success) {
            toast.success('Inventory Item Processed Successfully!')
            onClose()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast.error(
              mapCmsErrorToMessage(error.status.toString() ?? err.code ?? error.data.message ?? '')
            )
          } else {
            const error = err as Error
            toast.error(mapCmsErrorToMessage(error.message))
          }
        }
      }
    )
  }
  if (isLoading) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={'max-h-[250px] overflow-auto'}>
      <Form {...form}>
        <form className={className}>
          <FormField
            control={form.control}
            name={`checklistState`}
            render={({ field: priceField }) => (
              <FormItem className='flex flex-col gap-4'>
                <FormLabel>Self Propelled Sprayers Checklist </FormLabel>
                {fields.map((arr, index) => (
                  <div key={index} className={'flex flex-col'}>
                    <FormField
                      key={arr.id}
                      control={form.control}
                      name={`checklistState.${index}.score`}
                      render={({ field: scoreField }) => (
                        <FormItem className='grid grid-cols-2 gap-8 p-2'>
                          <div className='flex items-center'>
                            <FormLabel>{selfPropelledSprayers[index]}</FormLabel>
                          </div>
                          <FormControl>
                            {index >= 31 ? (
                              <RadioGroup
                                className={'flex flex-row items-center gap-4'}
                                onValueChange={scoreField.onChange}
                                value={scoreField.value?.toString() ?? ''}
                              >
                                <div className='flex items-center space-x-2'>
                                  <RadioGroupItem value='true' id={`pass-${index}`} />
                                  <Label htmlFor={`pass-${index}`}>
                                    <Text size={'medium'}>Pass</Text>
                                  </Label>
                                </div>
                                <div className='flex items-center space-x-2'>
                                  <RadioGroupItem value='false' id={`fail-${index}`} />
                                  <Label htmlFor={`fail-${index}`}>
                                    <Text size={'medium'}>Fail</Text>
                                  </Label>
                                </div>
                              </RadioGroup>
                            ) : (
                              <Select
                                onValueChange={scoreField.onChange}
                                value={isEdit ? scoreField.value?.toString() : undefined}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder='Select Score' />
                                </SelectTrigger>
                                <SelectContent className={'z-[9999]'}>
                                  <SelectItem value='1'>Good</SelectItem>
                                  <SelectItem value='2'>Fair</SelectItem>
                                  <SelectItem value='3'>Bad</SelectItem>
                                  <SelectItem value='4'>N/A</SelectItem>
                                </SelectContent>
                              </Select>
                            )}
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                ))}
              </FormItem>
            )}
          />

          <div className='grid w-full gap-1.5'>
            <FormField
              control={form.control}
              name={`comments`}
              defaultValue={data1?.data[data1.data.length - 1]?.comments}
              render={({ field: comment }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel>Comments</FormLabel>
                  <FormControl>
                    <Textarea placeholder='Type your comments here.' id='message' {...comment} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={`result`}
              render={({ field: result }) => (
                <FormItem className='grid grid-cols-2 gap-10 p-2'>
                  <FormLabel>
                    {' '}
                    <Text size={'large'}>Overall Results</Text>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={result.onChange}
                      className={'flex flex-row items-center gap-4'}
                      defaultValue={
                        result.value?.toString() ??
                        (isEdit && data1 != null
                          ? data1.data[data1.data.length - 1]?.result?.toString()
                          : '')
                      }
                    >
                      <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='true' id={`pass`} />
                        <Label htmlFor={`pass`}>
                          <Text size={'medium'}>Pass</Text>
                        </Label>
                      </div>
                      <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='false' id={`fail`} />
                        <Label htmlFor={`fail`}>
                          <Text size={'medium'}>Fail</Text>
                        </Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className={'flex flex-row justify-between gap-20'}>
              <Button
                variant={'outlined'}
                type={'button'}
                size={'xs'}
                color={'error'}
                onClick={onBack}
              >
                Back
              </Button>

              {resultValue !== undefined && (
                <>
                  <Button
                    onClick={form.handleSubmit((data) => {
                      const result =
                        resultValue === 'true' || (isEdit && data1?.data[0].result === true) ? 2 : 3
                      onSubmit(data, result)
                    })}
                    variant={
                      resultValue === 'true' || (isEdit && data1?.data[0].result === true)
                        ? 'success'
                        : 'destructive'
                    }
                    size='xs'
                    color={
                      resultValue === 'true' || (isEdit && data1?.data[0].result === true)
                        ? 'success'
                        : 'error'
                    }
                    type='submit'
                    loading={mutation.isLoading}
                    disabled={mutation.isLoading || (isEdit && !form.formState.isDirty)}
                  >
                    {resultValue === 'true' || (isEdit && data1?.data[0].result === true)
                      ? 'Accept'
                      : 'Reject'}
                  </Button>

                  {resultValue === 'false' && (
                    <Button
                      onClick={form.handleSubmit((data) => {
                        onSubmit(data, 4)
                      })}
                      variant='destructive'
                      type='button'
                      size='xs'
                      color='error'
                      loading={mutation.isLoading}
                      disabled={mutation.isLoading || (isEdit && !form.formState.isDirty)}
                    >
                      Re-Assessment
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}
