import type React from 'react'
import { BasicButton } from '../../../../../components/Elements/Button'

interface ButtonGroupProps {
  onBack: () => void
  onSubmit: () => void
  disabled?: boolean
  isLoading?: boolean
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ onBack, onSubmit, disabled, isLoading }) => (
  <div className='w-full p-8 hidden lg:flex flex-row justify-between gap-12'>
    <BasicButton label='Back' variant='secondary' onClick={onBack} size='xs' />
    <BasicButton
      label='Next'
      variant='primary'
      onClick={onSubmit}
      size='xs'
      disabled={disabled}
      isLoading={isLoading}
    />
  </div>
)

export default ButtonGroup
