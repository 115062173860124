import type React from 'react'
import { Button, Input } from '../ui'

interface SearchBarProps {
  className: string
  searchQuery: string
  disabled?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSearch: () => void
  isSearching: boolean
  label?: string
}

export const SearchBar: React.FC<SearchBarProps> = ({
  className,
  searchQuery,
  onChange,
  disabled = false,
  onSearch,
  isSearching,
  label
}) => {
  return (
    <form
      className={`flex items-center ${className}`}
      onSubmit={(e) => {
        e.preventDefault()
        onSearch()
      }}
    >
      <Input
        value={searchQuery}
        type={'number'}
        inputMode={'numeric'}
        onChange={onChange}
        placeholder={label === undefined ? 'Search' : label}
      />
      <Button className={'min-w-[100px]'} type={'submit'} disabled={disabled || isSearching}>
        Search
      </Button>
    </form>
  )
}
