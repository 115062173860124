import { Link } from 'react-router-dom'
import type React from 'react'

interface Manual {
  id: number
  title: string
  subTitle: string
  description: string
  image: string
  link: string
}
export const Manuals: Manual[] = [
  {
    id: 1,
    image: '/login.png',
    title: 'User Access Basis',
    link: '/user-manuals/user-access-basics',
    subTitle: 'Learn how to get into the system and keep your account safe.',
    description:
      "In this section, we'll cover the basics of using the app safely. First, <b>log in</b> by entering your username or email and password. If needed, we may ask for an extra code for security. Next, <span class='text-primary'>create an account</span> by providing your name, email, and choosing a password. Finally, always remember to log out when you're done. This helps keep your account safe, especially on shared computers."
  },
  {
    id: 2,
    image: '/farm_management.png',
    title: 'Farm Management',
    link: 'user-manuals/farm-management',
    subTitle: 'Learn how to manage your farms effectively within the app.',
    description:
      'This section focuses on managing your farms effectively within the app. It covers various tasks such as adding new farms, leasing farms, and creating cropping plans to optimize agricultural activities.'
  },
  {
    id: 3,
    image: '/farm_management.png',
    title: 'Farm Inventories',
    link: 'user-manuals/farm-management',
    subTitle: 'Learn how to manage your farms inventories effectively within the app.',
    description:
      '////This section focuses on managing your farms effectively within the app. It covers various tasks such as adding new farms, leasing farms, and creating cropping plans to optimize agricultural activities.'
  }
]

export const loginManual: string[] = [
  "Click on <a href='login'>log in</a> button",
  'Enter the mobile number that you registered with (an OTP will be sent to the provided mobile number)',
  'Enter the OTP, in case OTP is not sent, click <b>Request Code</b> .An OTP will be sent to the provided mobile number.',
  'Click <b>Verify</b> and your will be directed to the landing page.'
]
export const signUpManual: string[] = [
  "Click <a href='registration'>Get Started</a> and your will be directed to the sign up page.",
  'Select <b>Individual Account</b> and click <b>Next</b>.',
  'Fill in details details as displayed on your <b>National Identity Card(Omang).</b>',
  'Enter your contact number and press "Next" (the system will send a six-digit OTP to the phone number provided).',
  'Complete this stage by entering the OTP from your mobile phone.',
  'Enter your address information and press "Save.'
]
