import type React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Chip, useMediaQuery } from '@mui/material'

interface FilterMenuProps {
  all: number
  verified: number
  rejected: number
  pending: number
  filterBy: 'all' | 'verified' | 'rejected' | 'pending'
  setFilterBy: Dispatch<SetStateAction<'all' | 'verified' | 'rejected' | 'pending'>>
}

export const FilterMenu: React.FC<FilterMenuProps> = ({
  all,
  verified,
  pending,
  rejected,
  setFilterBy,
  filterBy
}) => {
  const handleFilter = (type: 'all' | 'verified' | 'rejected' | 'pending'): void => {
    setFilterBy(type)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  return (
    <div className={'w-full flex justify-start items-center gap-2'}>
      <Chip
        label={isSmallScreen ? `All(${all})` : `All Farms (${all})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'all' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'all' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'all' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('all')
        }}
        clickable={true}
      />

      <Chip
        label={isSmallScreen ? `Verified(${verified})` : `Verified Farms (${verified})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'verified' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'verified' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'verified' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'verified' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'verified' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('verified')
        }}
        clickable={true}
      />

      <Chip
        label={isSmallScreen ? `Pending(${pending})` : `Pending Verification (${pending})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'pending' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'pending' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'pending' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'pending' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'pending' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('pending')
        }}
        clickable={true}
      />

      <Chip
        label={`Rejected (${rejected})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'rejected' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'rejected' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'rejected' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'rejected' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'rejected' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('rejected')
        }}
        clickable={true}
      />
    </div>
  )
}
