import { type FC, useEffect } from 'react'
import { type Lease } from 'types'
import { RenewLease } from '../lease-details/RenewLease'
import { TerminateLease } from '../lease-details/TerminateLease'
import { TabView } from 'components/App/Layout'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

interface RenewalAndTerminationProps {
  lease: Lease
}

export const RenewalAndTermination: FC<RenewalAndTerminationProps> = ({ lease }) => {
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  return (
    <TabView className={'flex flex-col md:gap-8 lg:gap-12'}>
      <RenewLease lease={lease} />
      {flags.leaseTermination === false ? null : <TerminateLease lease={lease} />}
    </TabView>
  )
}
