import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface PostHarvestingChecklist {
  inventory_item_type: number
  cutting_unit: number
  inventory_id: number
  picker_unit: number
  auger: number
  drive_chain_belts: number
  conveying_mechanism: number
  bearings: number
  threshing_drum: number
  beaters: number
  sieve: number
  fan: number
  grain_tank: number
  discharge_unit: number
  safety_covers: number
  gears: number
  pto_shaft: number
  result: boolean
  comments: string
  item_verification_status: number
  last_update_by: number
}

export const postHarvestingChecklist = async (
  params: PostHarvestingChecklist
): Promise<ApiResponse<[]>> => {
  return axios.post('/assessment/harvestingprocessing', params)
}
