import { axios } from 'lib/axios'
import { type RegistrationResponse } from '../types'
import { type ApiResponseWithObject } from 'types'

export interface RegisterIndividualFarmerDTO {
  USER_ROLE: number
  IND_FIRST_NAME: string
  IND_SURNAME: string
  ID: string
  IND_EXP_DATE: string
  FARMER_TYPE: 1
  IND_POB: string
  IND_GENDER: string
  IND_DOB: string
  VILLAGE_ID: 1
  CONTACT: string
  POSTAL_ADDRESS?: string
  PHYSICAL_ADDRESS?: string
  EMAIL?: string
}

export const createFarmer = async (
  params: RegisterIndividualFarmerDTO
): Promise<ApiResponseWithObject<RegistrationResponse>> => {
  return axios.post(`/farmer/`, params)
}
