import type React from 'react'
import { Paper } from '@mui/material'

interface BasicCardProps {
  elevation?: number
  className?: string
  children: React.ReactNode
}

export const BasicCard: React.FC<BasicCardProps> = ({ elevation = 0, className, children }) => {
  return (
    <Paper elevation={elevation} className={className} sx={{ borderRadius: '1rem' }}>
      {children}
    </Paper>
  )
}
