import { type ChemicalList } from 'features/farmer/cropping-plan/types'
import { axios } from 'lib/axios'
import {
  type ApiResponse,
  type ManualWeedMethods,
  type WeedAndPestApplicationStage,
  type WeedingChemicalMethods
} from 'types'

export interface CreateWeedingDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  user_id: number | null | undefined
  type: string | null | undefined
  manual_method?: ManualWeedMethods | null
  chemical_method?: WeedingChemicalMethods
  chemical_schedule?: WeedAndPestApplicationStage | null
  chemicals?: ChemicalList[] | null
}

export const createWeeding = async (params: CreateWeedingDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/production/weeding', params)
}
