import { Agriculture } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { sendOtp, type SendOtpDTO } from 'api/sendOtp'
import { AxiosError, type AxiosResponse } from 'axios'
import { BasicButton } from 'components/Elements/Button'
import { Form, InputField } from 'components/Form'
import { newFarmAtom } from 'features/farmer/land-management/stores/newFarm'
import { useSetAtom } from 'jotai'
import type React from 'react'
import { type SubmitHandler } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type ApiResponse } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { object, string, type TypeOf } from 'zod'

interface FarmNameProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

const schema = object({
  farmName: string().nonempty('Farm Name is Required!')
})
type FarmNameInput = TypeOf<typeof schema>
export const FarmName: React.FC<FarmNameProps> = ({ className, onNext, onBack }) => {
  const { farmer } = useFarmerStore()
  const setNewFarmDetails = useSetAtom(newFarmAtom)
  const mutation = useMutation({
    mutationFn: async (otpDTO: SendOtpDTO) => {
      return sendOtp(otpDTO)
    }
  })
  const onSubmit: SubmitHandler<FarmNameInput> = (data: FarmNameInput) => {
    setNewFarmDetails((prev) => {
      return {
        ...prev,
        farmName: data.farmName
      }
    })
    mutation.mutate(
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        number: farmer!.contact
      },
      {
        onSuccess: (result) => {
          if (result.status === 201 && result.success) {
            onNext()
          } else {
            const error = result.message
            toast.error(error ?? 'An error occurred')
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast.error(mapCmsErrorToMessage(error.data.message))
          } else {
            const error = err as Error
            toast.error(mapCmsErrorToMessage(error.message))
          }
        }
      }
    )
  }
  return (
    <Form<FarmNameInput, typeof schema> className={className} onSubmit={onSubmit} schema={schema}>
      {({ register, formState: { errors }, handleSubmit }) => (
        <>
          <div className={'flex flex-row justify-center'}>
            <InputField
              label={'Farm Name'}
              register={register('farmName')}
              icon={<Agriculture color={'primary'} />}
              error={!(errors.farmName == null)}
              helperText={errors?.farmName?.message ?? ''}
            />
          </div>
          <div className={'flex flex-row justify-between gap-8'}>
            <BasicButton
              label={'Back'}
              variant={'secondary'}
              isDisabled={mutation.isLoading}
              onClick={onBack}
              size={'xs'}
            />
            <BasicButton
              label={'Next'}
              variant={'primary'}
              onClick={handleSubmit(onSubmit)} // handleSubmit(onSubmit)
              size={'xs'}
              type={'submit'}
              isDisabled={mutation.isLoading}
              isLoading={mutation.isLoading}
            />
          </div>
        </>
      )}
    </Form>
  )
}
