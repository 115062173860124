import type React from 'react'
import { Typography } from '@mui/material'
import { CheckCircle, HighlightOff, Mode, PendingActions } from '@mui/icons-material'
import { type LoanHeader } from 'types'
import { string } from 'zod'

interface LoanApplicationStatusProps {
  loanHeader: LoanHeader
  className: string
}

export const LoanApplicationStatus: React.FC<LoanApplicationStatusProps> = ({
  loanHeader,
  className
}): React.JSX.Element => {
  if (loanHeader.status_id === 4) {
    return (
      <div className={className}>
        <CheckCircle sx={{ color: 'success.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'success.main' }}>
          Approved
        </Typography>
      </div>
    )
  }

  if (loanHeader.status_id === 5) {
    return (
      <div className={className}>
        <HighlightOff sx={{ color: 'error.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'error.main' }} noWrap>
          Rejected
        </Typography>
      </div>
    )
  }

  if (loanHeader.status_id === 6) {
    return (
      <div className={className}>
        <HighlightOff sx={{ color: 'error.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'error.main' }} noWrap>
          Withdrawn
        </Typography>
      </div>
    )
  }

  if (loanHeader.status_id === 3) {
    return (
      <div className={className}>
        <HighlightOff sx={{ color: 'error.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'error.main' }} noWrap>
          Deferred
        </Typography>
      </div>
    )
  }

  if (loanHeader.status_id === 1) {
    return (
      <div className={className}>
        <PendingActions sx={{ color: 'warning.main' }} fontSize={'small'} />
        <Typography variant={'h6'} sx={{ color: 'warning.main' }}>
          Initiated
        </Typography>
      </div>
    )
  }

  return (
    <div className={className}>
      <Mode sx={{ color: 'primary.light' }} fontSize={'small'} />
      <Typography variant={'h6'} sx={{ color: 'primary.light' }}>
        Draft
      </Typography>
    </div>
  )
}
