import { CheckListItem } from 'components/Change-Password/CheckListItem'
import type React from 'react'
import { hasLowerCase, hasUpperCase } from 'utils/utilFunctions'

interface PasswordCheckListProps {
  password: string
}

export const PasswordCheckList: React.FC<PasswordCheckListProps> = ({ password }) => {
  const specialChar = /[~`¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|":<>?._]/g

  return (
    <div className={'flex flex-col gap-2'}>
      <h4>Your Password Must:</h4>

      <CheckListItem label={'include at least 1 number (0-9)'} isValid={/\d/g.test(password)} />
      <CheckListItem
        label={'include at least 1 special character (#$%&*/?@)'}
        isValid={specialChar.test(password)}
      />
      <CheckListItem
        label={'include at least 1 lowercase character (a-z)'}
        isValid={hasLowerCase(password)}
      />
      <CheckListItem
        label={'include at least 1 uppercase character (A-Z)'}
        isValid={hasUpperCase(password)}
      />
      <CheckListItem label={'contain at least 8 characters'} isValid={password.length >= 8} />
    </div>
  )
}
