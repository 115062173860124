import type React from 'react'
import { Button, Text, useToast } from 'components/ui'
import { CheckCheck } from 'lucide-react'

interface AcceptedFarmProps {
  reference: string
  onComplete: () => void
}

export const AcceptedFarm: React.FC<AcceptedFarmProps> = ({ onComplete, reference }) => {
  const { toast } = useToast()

  return (
    <div className={'flex flex-col justify-center items-center gap-8'}>
      <div className={'w-full flex flex-col items-center gap-4'}>
        <Text size={'medium'} variant={'success'} className={'flex gap-2 items-center'}>
          <CheckCheck />
          Farm Verified
        </Text>
      </div>
      <div className={'text-center w-4/5 rounded p-2'}>
        <Text>
          Store any physical documents with following reference number:{' '}
          <b className={'text-success'}>{reference}</b>
        </Text>
      </div>
      <Button
        variant={'success'}
        onClick={() => {
          onComplete()
          toast({
            variant: 'success',
            description: 'Farm successfully verified.'
          })
        }}
        size={'lg'}
        color={'success'}
      >
        Complete
      </Button>
    </div>
  )
}
