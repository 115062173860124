import type React from 'react'
import { Button, Text } from 'components/ui'
import type { Lease } from 'types'
import { useTerminateLease } from 'features/farmer/hooks/useTerminateLease'

interface TerminateLeaseProps {
  lease: Lease
  next: () => void
}

export const TerminateLease: React.FC<TerminateLeaseProps> = ({ lease, next }) => {
  const { lessorInitiateTermination, isLoading } = useTerminateLease(next)

  const onTermination = (): void => {
    lessorInitiateTermination(lease)
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        Terminate this lease?
      </Text>

      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        Are you sure you want to end the lease for this farm? This means that the lease for this
        farm will be terminated upon acceptance by, <strong>{lease.dest_farmer_name}</strong>,
        within the next five (5) days.
      </Text>
      <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
        NOTE: This is an irreversible action.
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} disabled={isLoading} className={'w-1/2'} onClick={next}>
          Cancel
        </Button>
        <Button
          className={'w-1/2'}
          variant={'destructive'}
          disabled={isLoading}
          loading={isLoading}
          onClick={onTermination}
        >
          Terminate
        </Button>
      </div>
    </div>
  )
}
