import type React from 'react'
import { useEffect, useState } from 'react'
import { type GetInventoryRes, type Implement, type PoweredImplement, type Tractor } from '../types'
import { ModalLayout } from './ModalLayout'
import { ModifyInventory } from './ModifyInventory'
import { Button, Card, Text } from 'components/ui'
import { EditIcon } from 'lucide-react'
import { useGetStaticInventory } from '../api/getInventoryData'
import { LabInventoryStatus } from '../../../mechanisationOfficer/inventory/components/LabInventoryStatus'

interface InventoryCardProps {
  inventoryData: GetInventoryRes
  isTransfer?: boolean
}

export const InventoryCard: React.FC<InventoryCardProps> = ({ inventoryData, isTransfer }) => {
  const [view, setView] = useState(false)
  const [action, setAction] = useState<'VIEW' | 'TRANSFER' | 'MODIFY'>('VIEW')
  const [matchingItem, setMatchingItem] = useState<Tractor | PoweredImplement | Implement>()
  const { data: staticInvData } = useGetStaticInventory()

  useEffect(() => {
    if (staticInvData == null) return

    if (inventoryData.inventory_type_id === 1003) {
      setMatchingItem(
        staticInvData.data?.tractors?.find((item) => item.model_id === inventoryData.resource_item)
      )
    } else if (inventoryData.inventory_type_id === 1002) {
      setMatchingItem(
        staticInvData.data?.implements?.find(
          (item) => item.model_id === inventoryData.resource_item
        )
      )
    } else if (inventoryData.inventory_type_id === 1001) {
      setMatchingItem(
        staticInvData.data?.powered_implements?.find(
          (item) => item.model_id === inventoryData.resource_item
        )
      )
    }
  }, [staticInvData, inventoryData.inventory_type_id, inventoryData.resource_item])

  const openView = (): void => {
    setAction('VIEW')
    setView(true)
  }

  const openTransfer = (): void => {
    setAction('TRANSFER')
    setView(true)
  }
  const openModify = (): void => {
    setAction('MODIFY')
    setView(true)
  }

  const closeView = (): void => {
    setView(false)
  }

  // const queryClient = useQueryClient()
  //
  // const mutation = useMutation({
  //   mutationFn: async (params: DeleteInventory) => {
  //     return deleteInventory(params)
  //   },
  //   onSuccess: (result) => {
  //     if (result.status === 200 && result.success) {
  //       toast.success('Inventory item deleted successfully!')
  //       void queryClient.invalidateQueries({ queryKey: [`${inventoryData.farmer_id}`] })
  //     }
  //   },
  //   onError: (err) => {
  //     if (err instanceof AxiosError) {
  //       const error = err?.response as AxiosResponse<ApiResponse<[]>>
  //       toast.error(
  //         mapCmsErrorToMessage(error.status.toString() ?? err.code ?? error.data.message ?? '')
  //       )
  //     } else {
  //       const error = err as Error
  //       toast.error(mapCmsErrorToMessage(error.message))
  //     }
  //   }
  // })

  return (
    <Card className={'flex w-full flex-col gap-1'}>
      <div className={'flex justify-between'}>
        <div className={'flex items-baseline gap-2'}>
          <Text size={'medium'}>{matchingItem?.make}</Text>
          <Text size={'small'} variant={'muted'}>
            {inventoryData.inventory_type_id === 1003
              ? 'Tractor'
              : inventoryData.inventory_type_id === 1002
              ? 'Non-Powered Implement'
              : 'Powered Implement'}
          </Text>
        </div>
        <LabInventoryStatus inventory={inventoryData} className={'flex items-center gap-4'} />
      </div>
      <div className={'w-full justify-between gap-2 flex items-center rounded bg-background p-2'}>
        <div className={'flex flex-col items-left w-[30%]'}>
          <Text size={'small'} variant={'muted'}>
            Model
          </Text>
          <Text className={'sm:hidden flex'} size={'small'}>
            {matchingItem?.model}
          </Text>
          <Text className={'hidden sm:flex lg:hidden'}>{matchingItem?.model}</Text>
          <Text className={'hidden lg:flex '} size={'medium'}>
            {matchingItem?.model}
          </Text>
        </div>
        <div className={'flex flex-col items-left w-[20%]'}>
          <Text size={'small'} variant={'muted'}>
            {inventoryData.inventory_type_id === 1003 ? 'Registration Number' : 'Serial Number'}
          </Text>
          <Text className={'sm:hidden flex'} size={'small'}>
            {inventoryData.reg_number}
          </Text>
          <Text className={'hidden sm:flex lg:hidden'}>{inventoryData.reg_number}</Text>
          <Text className={'hidden lg:flex '} size={'medium'}>
            {inventoryData.reg_number}
          </Text>
        </div>
        <div className={'flex flex-col items-left w-[15%]'}>
          <Text size={'small'} variant={'muted'}>
            Status
          </Text>
          <Text className={'sm:hidden flex'} size={'small'}>
            {inventoryData.item_status === 'IN_USE' ? 'In use' : 'Not in use'}
          </Text>
          <Text className={'hidden sm:flex lg:hidden'}>
            {inventoryData.item_status === 'IN_USE' ? 'In use' : 'Not in use'}
          </Text>
          <Text className={'hidden lg:flex '} size={'medium'}>
            {inventoryData.item_status === 'IN_USE' ? 'In use' : 'Not in use'}
          </Text>
        </div>

        {inventoryData.item_verification_status !== 'PENDING_VERIFICATION' ? (
          <div className={'flex justify-between w-[22%] md:w-auto'}>
            {inventoryData.item_verification_status === 'VERIFIED' && (
              <Button
                className={'w-full max-w-16 mr-5'}
                variant={'success'}
                size={'xs'}
                onClick={openTransfer}
                disabled={isTransfer}
              >
                Transfer
              </Button>
            )}
            <Button
              className={'w-full max-w-16'}
              variant={'primary'}
              size={'xs'}
              onClick={openView}
              id={'view'}
            >
              View
            </Button>
          </div>
        ) : (
          <div className={'flex gap-2 sm:gap-4 justify-end w-[22%] md:w-auto'}>
            <Button variant={'ghost'} size={'icon'} onClick={openModify}>
              <EditIcon className={'h-[1rem]'} />
            </Button>
          </div>
        )}
      </div>

      <ModalLayout open={view} onClose={closeView}>
        {action === 'VIEW' && (
          <ModifyInventory
            inventoryData={inventoryData}
            inventoryItem={matchingItem}
            isModify={false}
            onClose={closeView}
            isTransfer={false}
            isView={true}
          />
        )}
        {action === 'TRANSFER' && (
          <ModifyInventory
            inventoryData={inventoryData}
            inventoryItem={matchingItem}
            isModify={false}
            onClose={closeView}
            isTransfer={true}
            isView={false}
          />
        )}
        {action === 'MODIFY' && (
          <ModifyInventory
            inventoryData={inventoryData}
            inventoryItem={matchingItem}
            isModify={true}
            onClose={closeView}
            isTransfer={false}
            isView={false}
          />
        )}
      </ModalLayout>
    </Card>
  )
}
