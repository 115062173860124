import 'react-international-phone/style.css'

import {
  type BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import type React from 'react'
import {
  type CountryIso2,
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput
} from 'react-international-phone'
import { Phone } from '@mui/icons-material'

export interface PhoneInputFieldProps extends BaseTextFieldProps {
  value: string
  label?: string
  onChange: (phone: string) => void
}

export const PhoneInputField: React.FC<PhoneInputFieldProps> = ({
  value,
  onChange,
  label = 'Phone Number',
  ...restProps
}) => {

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'bw',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone)
    }
  })

  return (
    <TextField
      variant='outlined'
      label={label}
      color='primary'
      placeholder='Phone Number'
      value={phone}
      onChange={handlePhoneValueChange}
      type='tel'
      size={'small'}
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' style={{ marginRight: '2px', marginLeft: '-8px' }}>
            <Phone color={'primary'} />
            <Select
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
              sx={{
                width: 'max-content',
                // Remove default outline (display only on focus)
                fieldset: {
                  display: 'none'
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block'
                  }
                },
                // Update default spacing
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important'
                },
                svg: {
                  right: 0
                }
              }}
              value={country}
              onChange={(e) => {
                setCountry(e.target.value as CountryIso2)
              }}
              renderValue={(val) => <FlagEmoji iso2={val} style={{ display: 'flex' }} />}
            >
              {defaultCountries.map((c) => {
                const selectedCountry = parseCountry(c)
                return (
                  <MenuItem key={selectedCountry.iso2} value={selectedCountry.iso2}>
                    <FlagEmoji iso2={selectedCountry.iso2} style={{ marginRight: '8px' }} />
                    <Typography marginRight='8px'>{selectedCountry.name}</Typography>
                    <Typography color='gray'>+{selectedCountry.dialCode}</Typography>
                  </MenuItem>
                )
              })}
            </Select>
          </InputAdornment>
        )
      }}
      {...restProps}
    />
  )
}
