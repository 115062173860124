import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Farm } from 'types'

export const getFarm = async (
  farmId: string | number | null | undefined,
  farmerId: string | number | null | undefined,
  extAreaId?: number | null | undefined
): Promise<ApiResponse<Farm>> => {
  return axios.get(
    `/farm?FARM_ID=${farmId ?? ''}&FARMER_ID=${farmerId ?? ''}&EXT_AREA_ID=${extAreaId ?? ''}`
  )
}

export const useGetFarm = (
  farmId: string | number | null | undefined,
  farmerId: string | number | null | undefined,
  extAreaId?: number | null | undefined
): UseQueryResult<ApiResponse<Farm>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farmer-farm', farmId, farmerId, extAreaId],
    queryFn: async () => getFarm(farmId, farmerId, extAreaId),
    enabled:
      (farmerId != null && farmId != null) ||
      (extAreaId != null && farmerId != null && farmId != null)
  })
}
