import type React from 'react'
import { BasicModal } from 'components/Elements/Modal/Modal'
import { Card } from '../../../../components/ui'

interface LayoutProps {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const ModalLayout: React.FC<LayoutProps> = ({ open, onClose, children }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <Card
        className={
          'p-4 md:p-6 lg:p-8 w-[24rem] md:min-w-[35rem]  min-h-[50%] grid place-items-center'
        }
      >
        {children}
      </Card>
    </BasicModal>
  )
}