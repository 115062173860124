import type React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { coerce, object, string } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from '../../../../components/ui'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useGetStaticInventory } from '../api/getInventoryData'
import { uniqBy } from 'lodash'
import { YearPicker } from '../../../../components/Form/YearPicker'
import { type GetInventoryRes, type Tractor } from '../types'
import { Box, TabView } from '../../../../components/App/Layout'
import dayjs from 'dayjs'
import { useSubmitInventoryItem } from '../hooks/submitInvetoryItem'
import { useNavigate } from 'react-router-dom'

const registrationNumberRegex = /^B \d{3} [A-Z]{3}$/

const schemaTractor = object({
  inventorystatus: string().min(1, 'Field is required'),
  yom: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  model: coerce.number().min(1, 'Field is required'),
  make: coerce.number().min(1, 'Field is required'),
  horsepower: coerce.number().min(1, 'Field is required').optional(),
  drive: string().min(1, 'Field is required').optional(),
  reg_number: string().refine((value) => registrationNumberRegex.test(value), {
    message: 'Registration number must be in the format "B 123 CDE"'
  })
})

type TractorInput = z.infer<typeof schemaTractor>

interface RegisterTractorsProps {
  isEdit: boolean
  inventoryData?: GetInventoryRes
  inventoryItem?: Tractor
  onClose?: () => void
}

export const RegisterTractors: React.FC<RegisterTractorsProps> = ({
  isEdit,
  inventoryData,
  inventoryItem,
  onClose = () => {}
}) => {
  const navigate = useNavigate()
  const [selectedMake, setSelectedMake] = useState(isEdit ? inventoryItem?.make_id : '')
  const [selectedModel, setSelectedModel] = useState(isEdit ? inventoryItem?.model_id : '')
  const [isFormChanged, setIsFormChanged] = useState(false)
  const { farmer } = useFarmerStore()
  const { data: staticInvData } = useGetStaticInventory()
  const form = useForm<TractorInput>({
    resolver: zodResolver(schemaTractor),
    defaultValues:
      isEdit && inventoryItem != null
        ? {
            inventorystatus: inventoryData?.item_status,
            // yom: inventoryData?.year_of_manufacture ? new Date(inventoryData.year_of_manufacture) : undefined,
            model: inventoryItem.model_id,
            make: inventoryItem.make_id,
            horsepower: inventoryItem.tractor_power_hp,
            drive: inventoryItem.drive,
            reg_number: inventoryData?.reg_number
          }
        : undefined
  })

  useEffect(() => {
    if (isEdit && inventoryItem != null) {
      setSelectedMake(inventoryItem.make_id.toString())
      setSelectedModel(inventoryItem.model_id.toString())
    }
  }, [isEdit, inventoryItem])

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      setIsFormChanged(true)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [form])

  const findSelectedItem = (): Tractor | undefined => {
    return staticInvData?.data?.tractors.find(
      (item) =>
        item.make_id.toString() === selectedMake && item.model_id.toString() === selectedModel
    )
  }

  const selectedItem = findSelectedItem()

  const makes = uniqBy(staticInvData?.data.tractors, (item) => {
    return item.make
  }).sort((a, b) => a.make.localeCompare(b.make))

  const models = staticInvData?.data?.tractors
    .filter((sub) => sub.make_id.toString() === selectedMake?.toString())
    .sort((a, b) => a.model.localeCompare(b.model))

  const { onSubmit, mutation } = useSubmitInventoryItem({
    farmer: { farmer_id: Number(farmer?.farmer_id), user_id: Number(farmer?.user_id) },
    isEdit,
    inventoryData: { inventory_id: inventoryData?.inventory_id ?? 0 },
    schema: schemaTractor,
    selectedItem: {
      model_id: Number(selectedItem?.model_id)
    },
    inventoryTypeId: 1003,
    onClose
  })

  return (
    <Box>
      <div className={'flex justify-between items-center'}>
        <Text size={'large'}>
          {isEdit ? 'Edit Tractor Information' : 'Register new Tractor Information'}
        </Text>
      </div>
      <TabView>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div
              className={
                'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] items-center gap-2 sm:gap-4 list-none mb-4 max-h-[400px] overflow-auto'
              }
            >
              <FormField
                control={form.control}
                name={`reg_number`}
                defaultValue={inventoryData?.reg_number}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Registration Number</FormLabel>
                    <FormControl>
                      <Input type={'text'} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <YearPicker
                error={!(form.formState.errors.yom == null)}
                helperText={form.formState.errors?.yom?.message ?? ''}
                label={'Manufacture Year'}
                name={'yom'}
                control={form.control}
                defaultValue={inventoryData !== null ? dayjs(inventoryData?.yom) : undefined}
              />

              <FormField
                control={form.control}
                name='make'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Make</FormLabel>
                    <Select
                      onValueChange={(val) => {
                        setSelectedMake(val)
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      defaultValue={selectedMake?.toString() ?? ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Make' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {makes?.map((disc) => (
                          <SelectItem key={disc.make_id} value={disc.make_id.toString()}>
                            {disc.make}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='model'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Model</FormLabel>
                    <Select
                      disabled={selectedMake === ''}
                      onValueChange={(val) => {
                        setSelectedModel(val)
                        field.onChange(val)
                      }}
                      defaultValue={selectedModel?.toString() ?? ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Model' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {models?.map((disc) => (
                          <SelectItem key={disc.model_id} value={disc.model_id.toString()}>
                            {disc.model}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`horsepower`}
                render={({ field: horsepower }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Power(hp)</FormLabel>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...horsepower}
                        value={
                          selectedItem != null
                            ? (selectedItem?.tractor_power_hp as unknown as string)
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='drive'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Drive</FormLabel>
                    <Input
                      placeholder={'Tractor power'}
                      value={
                        selectedItem != null ? (selectedItem?.drive as unknown as string) : 'N/A'
                      }
                      classLabel={'bg-card'}
                      disabled
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='inventorystatus'
                defaultValue={inventoryData?.item_status}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Inventory Status</FormLabel>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value)
                      }}
                      value={field.value}
                    >
                      <SelectTrigger className={'relative'}>
                        <SelectValue placeholder={<Text>Select Inventory Status</Text>} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem className={'w-full'} value={'IN_USE'}>
                          <Text>In Use</Text>
                        </SelectItem>
                        <SelectItem className={'w-full'} value={'BOARDED'}>
                          <Text>Boarded</Text>
                        </SelectItem>
                        <SelectItem className={'w-full'} value={'UNDER_MAINTENANCE'}>
                          <Text>Under Maintenance</Text>
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className={'flex w-full justify-between space-x-4'}>
              <Button
                className={'w-full sm:w-[18rem] '}
                variant={'secondary'}
                onClick={() => {
                  if (isEdit) {
                    onClose?.()
                  } else {
                    navigate(-1)
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={'w-full sm:w-[18rem] '}
                type={'submit'}
                disabled={mutation.isLoading || (isEdit && !isFormChanged)}
                loading={mutation.isLoading}
              >
                {isEdit ? 'Update' : 'Submit'}
              </Button>
            </div>
          </form>
        </Form>
      </TabView>
    </Box>
  )
}
