import type React from 'react'
import { useState } from 'react'
import { type Farm } from 'types'
import { AcceptedFarm } from './accept-farm/AcceptedFarm'
import { AcceptFarmOtpForm } from './accept-farm/AcceptFarmOtpForm'
import { FarmInformation } from './accept-farm/FarmInformation'
import { ProvidedInformation } from './accept-farm/ProvidedInformation'
import { RejectedFarm } from './reject-farm/RejectedFarm'
import { RejectFarmOtpForm } from './reject-farm/RejectFarmOtpForm'
import { RejectReasonForm } from './reject-farm/RejectReasonForm'

interface AcceptFarmProps {
  farm: Farm
  className: string
  onClose: () => void
  onStatusChange: (farmId: number) => void
}

export const AcceptFarm: React.FC<AcceptFarmProps> = ({
  farm,
  onClose,
  className,
  onStatusChange
}) => {
  const [showReason, setShowReason] = useState(false)
  const [showAcceptOtpForm, setShowAcceptOtpForm] = useState(false)
  const [showProvideInformation, setShowProvidedInformation] = useState(false)
  const [showRejectOtpForm, setShowRejectOtpForm] = useState(false)
  const [showAcceptedFarm, setShowAcceptedFarm] = useState(false)
  const [showRejectedFarm, setShowRejectedFarm] = useState(false)

  const [reference, setReference] = useState('')
  const [rejectReason, setRejectReason] = useState('')

  // handling farm rejection
  const handleRejectFarmOTP = (hasReference: boolean): void => {
    // check if we have a reference number and if so show the reference page
    // else close the process
    if (hasReference) {
      setShowRejectOtpForm(false)
      setShowRejectedFarm(true)
    } else {
      onClose()
    }
  }

  if (showReason) {
    return (
      <div className={className}>
        <RejectReasonForm
          farm={farm}
          className={'flex flex-col gap-4'}
          rejectReason={setRejectReason}
          onReject={() => {
            setShowReason(false)
            setShowRejectOtpForm(true)
          }}
          onCancel={onClose}
        />
      </div>
    )
  }

  if (showRejectOtpForm) {
    return (
      <div className={className}>
        <RejectFarmOtpForm
          farm={farm}
          reason={rejectReason}
          reference={setReference}
          onSuccess={handleRejectFarmOTP}
        />
      </div>
    )
  }
  if (showProvideInformation) {
    return (
      <div className={className}>
        <ProvidedInformation
          farm={farm}
          onApprove={() => {
            setShowProvidedInformation(false)
            setShowAcceptOtpForm(true)
          }}
          className={'flex flex-col gap-4'}
          onCancel={onClose}
        />
      </div>
    )
  }

  if (showAcceptOtpForm) {
    return (
      <div className={className}>
        <AcceptFarmOtpForm
          farm={farm}
          reference={setReference}
          onSuccess={() => {
            setShowAcceptOtpForm(false)
            setShowAcceptedFarm(true)
          }}
        />
      </div>
    )
  }

  if (showAcceptedFarm) {
    return (
      <div className={className}>
        <AcceptedFarm
          reference={reference}
          onComplete={() => {
            onStatusChange(farm.farm_id)
            onClose()
          }}
        />
      </div>
    )
  }

  if (showRejectedFarm) {
    return (
      <div className={className}>
        <RejectedFarm
          reference={reference}
          onComplete={() => {
            onStatusChange(farm.farm_id)
            onClose()
          }}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <FarmInformation
        farm={farm}
        onReject={() => {
          setShowReason(true)
        }}
        onApprove={() => {
          setShowProvidedInformation(true)
        }}
      />
    </div>
  )
}
