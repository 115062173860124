interface LoanElligibilityProps {
  isEligible: boolean
  notEligibleReasons?: string[]
}

export const LoanElligibility: React.FC<LoanElligibilityProps> = ({
  isEligible,
  notEligibleReasons
}) => {
  return (
    <div className='w-full flex flex-col gap-4 justify-between items-center text-[color:var(--color-secondary-light)] text-center'>
      {isEligible ? (
        <>
          <h3 className='text-[color:var(--color-secondary-light)] text-xl'>
            You are eligible for a loan!
          </h3>
          <p>Are you sure you want to continue with the loan application?</p>
        </>
      ) : (
        <>
          <h3 className='text-[color:var(--color-secondary-light)] text-xl'>
            Not eligible for loan application
          </h3>
          <p>You are not eligible to apply for a loan for the following reasons:</p>
          {notEligibleReasons?.map((reason, index) => (
            <li key={index}>{reason}</li>
          ))}
        </>
      )}
    </div>
  )
}
