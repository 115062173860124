import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createWeeding,
  type CreateWeedingDTO
} from 'features/farmer/cropping-plan/api/weeding/createWeeding'
import {
  updateWeeding,
  type UpdateWeedingDTO
} from 'features/farmer/cropping-plan/api/weeding/updateWeeding'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, ManualWeedMethods, type Weeding } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'
import { type z } from 'zod'
import { type weedingSchema } from '../components/steps/weeding/Weeding'

interface UseCroppingPlanWeeding {
  header: CroppingPlan | null
  weeding: Weeding | null
  setWeeding: (weeding: Weeding | null) => void
  isLoading: boolean
  createManualWeeding: (data: z.infer<typeof weedingSchema>) => void
  updateManualWeeding: (data: z.infer<typeof weedingSchema>) => void
}

export const useCroppingPlanWeeding = (next: () => void): UseCroppingPlanWeeding => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { header, weeding, setWeeding } = useCroppingPlanStore()

  const createWeedingMutation = useMutation({
    mutationFn: async (cpDto: CreateWeedingDTO) => {
      return createWeeding(cpDto)
    }
  })

  const updateWeedingMutation = useMutation({
    mutationFn: async (cpDto: UpdateWeedingDTO) => {
      return updateWeeding(cpDto)
    }
  })
  const createManualWeeding = (data: z.infer<typeof weedingSchema>): void => {
    createWeedingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: data.type,
        user_id: farmer?.user_id,
        manual_method:
          data?.method === 'Human Based'
            ? ManualWeedMethods.HumanBased
            : ManualWeedMethods.InterrowCultivation
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['weeding'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateManualWeeding = (data: z.infer<typeof weedingSchema>): void => {
    updateWeedingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: data.type,
        user_id: farmer?.user_id,
        manual_method:
          data?.method === 'Human Based'
            ? ManualWeedMethods.HumanBased
            : ManualWeedMethods.InterrowCultivation
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['weeding'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  // const createChemicalWeeding = (data: WeedingApplicationMethodsInput): void => {
  //   createWeedingMutation.mutate(
  //     {
  //       farmer_id: farmer?.farmer_id,
  //       cp_id: header?.cropping_plan_id,
  //       type: weeding?.type,
  //       user_id: farmer?.user_id,
  //       chemical_method: getChemicalWeedMethod(data.applicationMethod),
  //       chemical_schedule: getManualWeedApplicationStage(weeding?.chemical_schedule),
  //       chemicals: weeding?.chemical_list
  //     },
  //     {
  //       onSuccess: (result) => {
  //         if (result.status === 201) {
  //           next()
  //           toast({
  //             variant: 'success',
  //             title: 'Created Successfully.'
  //           })
  //           void queryClient.invalidateQueries({ queryKey: ['weeding'] })
  //         }
  //       },
  //       onError: (err) => {
  //         if (err instanceof AxiosError) {
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
  //           })
  //         } else {
  //           const err1 = err as Error
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err1.message)
  //           })
  //         }
  //       }
  //     }
  //   )
  // }
  //
  // const updateChemicalWeeding = (data: WeedingApplicationMethodsInput): void => {
  //   updateWeedingMutation.mutate(
  //     {
  //       farmer_id: farmer?.farmer_id,
  //       cp_id: header?.cropping_plan_id,
  //       type: weeding?.type,
  //       user_id: farmer?.user_id,
  //       chemical_method: getChemicalWeedMethod(data.applicationMethod),
  //       chemical_schedule: getManualWeedApplicationStage(weeding?.chemical_schedule),
  //       chemicals: weeding?.chemical_list
  //     },
  //     {
  //       onSuccess: (result) => {
  //         if (result.status === 201) {
  //           next()
  //           toast({
  //             variant: 'success',
  //             title: 'Updated Successfully.'
  //           })
  //           void queryClient.invalidateQueries({ queryKey: ['weeding'] })
  //         }
  //       },
  //       onError: (err) => {
  //         if (err instanceof AxiosError) {
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
  //           })
  //         } else {
  //           const err1 = err as Error
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err1.message)
  //           })
  //         }
  //       }
  //     }
  //   )
  // }

  return {
    header,
    weeding,
    setWeeding,
    isLoading: createWeedingMutation.isLoading || updateWeedingMutation.isLoading,
    createManualWeeding,
    updateManualWeeding
  }
}
