import { SearchBar } from 'components/Form/SearchBar'
import { CroppingPlanResults } from 'features/officer/cropping-plan/components/results/CroppingPlanResults'
import { useSearchCroppingPlan } from 'features/officer/cropping-plan/hooks/useSearchCroppingPlan'
import type React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useOfficerStore } from 'stores/useOfficerStore'
import { Card, Text, Loader } from 'components/ui'
import { useSearchParams } from 'react-router-dom'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { NotFound } from 'components/Errors/NotFound'

export const SearchCroppingPlan: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('q')

  const { officer } = useOfficerStore()
  const { searchCroppingPlan, data, error, isLoading } = useSearchCroppingPlan(officer?.ext_area_id)
  const [searchQuery, setSearchQuery] = useState('')

  const flags = useFlags()
  const ldClient = useLDClient()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value)
  }

  const handleSearchParams = (): void => {
    setSearchParams({ q: searchQuery })
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }

    void ldClient?.identify({ key: 'moa-frontend' })

    // on page refresh search based on query param
    if (searchQuery !== '' && query != null && !isLoading) {
      void searchCroppingPlan(searchQuery)
    }
  }, [error])

  if (flags.croppingPlan === false) {
    return <NotFound />
  }

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'} className={'ml-2'}>
        Cropping Plan Verification
      </Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <div className={'flex flex-col gap-2'}>
          <Text size={'medium'} className={'ml-2'}>
            Search for cropping plans by entering the Farmer ID.
          </Text>
          <SearchBar
            className={'w-full max-w-[700px] flex gap-8'}
            searchQuery={searchQuery}
            disabled={searchQuery.length !== 7}
            onChange={handleChange}
            onSearch={() => {
              handleSearchParams()
              void searchCroppingPlan(searchQuery)
            }}
            isSearching={isLoading}
          />
        </div>

        {isLoading ? (
          <div className={'mt-8 grid place-items-center h-full flex-1'}>
            <Loader />
          </div>
        ) : null}

        {error !== null ? (
          <Text size={'medium'} variant={'error'} className={'ml-4'}>
            {error}
          </Text>
        ) : null}

        {error === null && !isLoading && data?.length === 0 ? (
          <Text className={'text-center'} variant={'primary'} size={'medium'}>
            No Search Results
          </Text>
        ) : null}

        {data !== null && data.length > 0 ? (
          <CroppingPlanResults croppingPlans={data} className={'w-full flex flex-col gap-2'} />
        ) : null}
      </Card>
    </div>
  )
}
