import { LoginOtpForm } from 'features/authentication/components/phone/LoginOtpForm'
import { useAuth } from 'features/authentication/hooks/useAuth'
import { type ConfirmationResult } from 'firebase/auth'
import type React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { PhoneLoginForm } from './PhoneLoginForm'
import { Text } from 'components/ui'

export interface OtpState {
  sent: boolean
  confirm: ConfirmationResult | undefined
}

interface PhoneLoginProps {
  className?: string
  onRegister: () => void
  onEmail: () => void
  onSuccess: () => void
}

export const PhoneLogin: React.FC<PhoneLoginProps> = ({
  className,
  onRegister,
  onEmail,
  onSuccess
}) => {
  const [number, setNumber] = useState<string>('')
  const [showOtpForm, setShowOtpForm] = useState(false)
  const { phoneSignIn, phoneLoginResponse, isLoading } = useAuth()

  const handleShowOtpForm = (): void => {
    setShowOtpForm(!showOtpForm)
  }

  const onLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    event.preventDefault()
    await phoneSignIn(number)
  }

  useEffect(() => {
    if (phoneLoginResponse.status) {
      handleShowOtpForm()
    }
  }, [phoneLoginResponse])

  if (showOtpForm) {
    return (
      <LoginOtpForm
        number={number}
        onSuccess={onSuccess}
        onFailure={handleShowOtpForm}
        confirm={phoneLoginResponse.confirm}
      />
    )
  }

  return (
    <div className={'w-full max-w-[450px] flex flex-col gap-8 md:gap-8 xl:gap-12'}>
      <div className={'w-full flex flex-col sm:text-start ml-2 sm:ml-0'}>
        <Text size={'large'}>Welcome Back</Text>
        <Text>
          Sign in to continue with{' '}
          <span className={'text-primary hidden sm:inline-block'}>Temo Crop Management System</span>
          <span className={'text-primary inline-block sm:hidden'}>Temo CMS</span>
        </Text>
      </div>
      <PhoneLoginForm
        onEmail={onEmail}
        onChange={setNumber}
        className={'w-full flex flex-col gap-4 sm:gap-8'}
        number={number}
        onRegister={onRegister}
        onLogin={onLogin}
        loading={isLoading}
      />
    </div>
  )
}
