import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface AcceptLeaseDTO {
  LEASE_ID: number | null | undefined
  FARMER_ID: number | null | undefined
  STATUS_ID: number
  LAST_UPDATED_BY: number | null | undefined
}

export const acceptLease = async (params: AcceptLeaseDTO): Promise<ApiResponse<[]>> => {
  return axios.put('/lease/accept', params)
}
