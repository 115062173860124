import type React from 'react'
import { useEffect } from 'react'

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Label,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  toast
} from '../../../../../components/ui'
import type {
  GetInventoryRes,
  Implement,
  PoweredImplement,
  Tractor
} from '../../../../farmer/inventory/types'
import { applicators } from '../../types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type z from 'zod'
import { array, boolean, coerce, object, string } from 'zod'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { Textarea } from '../../../../../components/ui/textarea'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { type PostSprayersChecklist, postSprayersChecklist } from '../../api/postSprayers'
import { useGetSprayerChecklist } from '../../api/getSprayerChecklist.'

interface ChecklistProps {
  onBack: () => void
  className: string
  inventoryData: GetInventoryRes
  inventoryItem: Tractor | Implement | PoweredImplement | undefined
  onClose: () => void
  isEdit?: boolean
}

const ChecklistItemSchema = object({
  score: coerce.number().min(1, 'Feild is required').or(string())
})

const ChecklistSchema = object({
  checklistState: array(ChecklistItemSchema),
  comments: string().min(1, 'Field is Required'),
  result: boolean().or(string())
})

export const Applicators: React.FC<ChecklistProps> = ({
  className,
  inventoryData,
  onClose,
  onBack,
  isEdit
}) => {
  const queryClient = useQueryClient()

  const { officer } = useOfficerStore()
  const defaultValues = applicators.map((s, i) => {
    return { score: '' }
  })
  const form = useForm<z.infer<typeof ChecklistSchema>>({
    resolver: zodResolver(ChecklistSchema),
    defaultValues: {
      checklistState: defaultValues
    }
  })
  const { fields } = useFieldArray({
    control: form.control,
    name: 'checklistState'
  })
  const { data: data1, isLoading } = useGetSprayerChecklist(inventoryData.inventory_id)

  const mutation = useMutation({
    mutationFn: async (params: PostSprayersChecklist) => {
      return postSprayersChecklist(params)
    }
  })

  const resultValue = useWatch({
    control: form.control,
    name: 'result'
  })

  useEffect(() => {
    if (isEdit === true && data1 !== undefined) {
      const latestData = data1.data[data1.data.length - 1]
      form.reset({
        checklistState: [
          { score: latestData.nozel_output },
          { score: latestData.boom },
          { score: latestData.hoses },
          { score: latestData.pump_o_rings },
          { score: latestData.tank },
          { score: latestData.gasket },
          { score: latestData.regultors_guages_controls },
          { score: latestData.belts },
          { score: latestData.propeller_and_agitation },
          // { score: latestData.tires },
          { score: latestData.filters },
          { score: latestData.dispenser },
          { score: latestData.measuring_device },
          { score: latestData.gears }
        ],
        comments: latestData.comments,
        result: latestData.result
      })
    }
  }, [isEdit, data1, form])
  const onSubmit = (data: z.infer<typeof ChecklistSchema>, status: number): void => {
    const result = data.result === 'true'
    mutation.mutate(
      {
        inventory_item_type: inventoryData.inventory_type_id,
        inventory_id: inventoryData.inventory_id,
        nozel_output: Number(data.checklistState[0].score),
        boom: Number(data.checklistState[1].score),
        hoses: Number(data.checklistState[2].score),
        pump_o_rings: Number(data.checklistState[3].score),
        tank: Number(data.checklistState[4].score),
        gasket: Number(data.checklistState[5].score),
        regultors_guages_controls: Number(data.checklistState[6].score),
        belts: Number(data.checklistState[7].score),
        propeller_and_agitation: Number(data.checklistState[8].score),
        // tires: Number(data.checklistState[9].score),
        filters: Number(data.checklistState[10].score),
        dispenser: Number(data.checklistState[11].score),
        measuring_device: Number(data.checklistState[12].score),
        gears: Number(data.checklistState[13].score),

        last_update_by: Number(officer?.user_id),
        result,
        item_verification_status: status,
        comments: data.comments
      },
      {
        onSuccess: (res) => {
          if (res.status === 201 && res.success) {
            void queryClient.invalidateQueries({ queryKey: [`inventories, inventory`] })
            toast({
              variant: 'success',
              title: `Inventory item processed successfully.`
            })
            onClose()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  if (isLoading) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <Form {...form}>
      <form className={className}>
        <FormLabel>Sprayers Checklist </FormLabel>

        <FormField
          control={form.control}
          name={`checklistState`}
          render={({ field: priceField }) => (
            <FormItem className='flex flex-col gap-4 max-h-[250px] overflow-auto'>
              {fields.map((arr, index) => (
                <div key={index} className={' flex flex-col'}>
                  <FormField
                    key={arr.id}
                    control={form.control}
                    name={`checklistState.${index}.score`}
                    render={({ field: scoreField }) => (
                      <FormItem className='grid grid-cols-2 gap-8 p-2'>
                        <div className='flex items-center'>
                          <FormLabel>{applicators[index]}</FormLabel>
                        </div>

                        <FormControl>
                          <Select
                            onValueChange={scoreField.onChange}
                            value={isEdit === true ? scoreField.value?.toString() : undefined}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder='Select Score' />
                            </SelectTrigger>
                            <SelectContent className={' z-[9999]'}>
                              <SelectItem value='1'>Good</SelectItem>
                              <SelectItem value='2'>Fair</SelectItem>
                              <SelectItem value='3'>Bad</SelectItem>
                              <SelectItem value='4'>N/A</SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  ></FormField>
                </div>
              ))}
            </FormItem>
          )}
        ></FormField>

        <div className='grid w-full gap-1.5'>
          <FormField
            control={form.control}
            name={`comments`}
            defaultValue={data1?.data[data1.data.length - 1]?.comments}
            render={({ field: comment }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Comments</FormLabel>
                {/* <FormDescription>Max Price {seedTypes[index].price}</FormDescription> */}
                <FormControl>
                  <Textarea
                    placeholder='Type your comments here.'
                    id='message'
                    className='resize-none border rounded-md p-2 w-full max-h-24 overflow-auto'
                    {...comment}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>

          <FormField
            control={form.control}
            name={`result`}
            render={({ field: result }) => (
              <FormItem className='grid grid-cols-2 gap-10 p-2'>
                <FormLabel>
                  {' '}
                  <Text size={'large'}>Overall Results</Text>
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={result.onChange}
                    className={'flex flex-row items-center gap-4'}
                    defaultValue={
                      result.value?.toString() ??
                      (isEdit === true && data1 != null
                        ? data1.data[data1.data.length - 1]?.result?.toString()
                        : '')
                    }
                  >
                    <div className='flex items-center space-x-2'>
                      <RadioGroupItem value='true' id={`pass`} />
                      <Label htmlFor={`pass`}>
                        <Text size={'medium'}>Pass</Text>
                      </Label>
                    </div>
                    <div className='flex items-center space-x-2'>
                      <RadioGroupItem value='false' id={`fail`} />
                      <Label htmlFor={`fail`}>
                        <Text size={'medium'}>Fail</Text>
                      </Label>
                    </div>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={'flex flex-row justify-between gap-20'}>
          <Button variant={'outlined'} type={'button'} size={'xs'} color={'error'} onClick={onBack}>
            Back
          </Button>

          {resultValue !== undefined && (
            <>
              <Button
                onClick={form.handleSubmit((data) => {
                  const result =
                    resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                      ? 2
                      : 3
                  onSubmit(data, result)
                })}
                variant={
                  resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                    ? 'success'
                    : 'destructive'
                }
                size='xs'
                color={
                  resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                    ? 'success'
                    : 'error'
                }
                type='submit'
                loading={mutation.isLoading}
                disabled={mutation.isLoading || (isEdit === true && !form.formState.isDirty)}
              >
                {resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                  ? 'Accept'
                  : 'Reject'}
              </Button>

              {resultValue === 'false' && (
                <Button
                  onClick={form.handleSubmit((data) => {
                    onSubmit(data, 4)
                  })}
                  variant='destructive'
                  type='button'
                  size='xs'
                  color='error'
                  loading={mutation.isLoading}
                  disabled={mutation.isLoading || (isEdit === true && !form.formState.isDirty)}
                >
                  Re-Assessment
                </Button>
              )}
            </>
          )}
        </div>
      </form>
    </Form>
  )
}
