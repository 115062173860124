import { type ContactDetails, type OmangDetails } from 'features/authentication/types'
import { create } from 'zustand'

interface UseIndividualRegistrationStore {
  omangDetails: OmangDetails | null
  setOmangDetails: (omangDetails: OmangDetails | null) => void
  contactDetails: ContactDetails | null
  setContactDetails: (contactDetails: ContactDetails | null) => void
}

export const useIndividualRegistrationStore = create<UseIndividualRegistrationStore>()((set) => ({
  omangDetails: null,
  contactDetails: null,
  setContactDetails: (contactDetails: ContactDetails | null) => {
    set((state) => ({ ...state, contactDetails }))
  },
  setOmangDetails: (omangDetails: OmangDetails | null) => {
    set((state) => ({ ...state, omangDetails }))
  }
}))
