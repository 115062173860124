/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CustomDatePicker, InputField, PhoneInputField, SelectField } from 'components/Form'
import { RowContainer } from 'features/loan-management/components/stepper'
import type React from 'react'
import { useState } from 'react'
import { coerce, discriminatedUnion, literal, object, string, type TypeOf, z } from 'zod'

import { type SubmitHandler } from 'react-hook-form'
import { BasicButton } from 'components/Elements/Button'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import { MenuItem } from '@mui/material'
import dayjs from 'dayjs'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { Text } from 'components/Elements/Text/Text'
import { type LoanStepperProps } from '../../../types'

const phoneUtil = PhoneNumberUtil.getInstance()

const usePhoneValidation = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const MaritalInformation: React.FC<LoanStepperProps> = ({
  onBack,
  onNext,
  steps,
  activeStep
}) => {
  const [, setLoanData] = useAtom(individualLoanItem)
  const [loanData] = useAtom(individualLoanItem)
  const [defaultPhoneNumber] = useState<string>(
    loanData?.loan_spouse?.spouse_phone !== undefined ? loanData?.loan_spouse?.spouse_phone : ''
  )
  const [numbers, setNumbers] = useState<string>(defaultPhoneNumber)
  const isPhoneValid = usePhoneValidation(numbers)
  const base = object({
    firstName: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    middleName: string().optional().default(''),
    lastName: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    spouse_id: string()
      .nonempty('Omang is required')
      .min(9, 'Invalid omang number')
      .max(9, 'Invalid omang number')
      .regex(/[0-9]/, 'Omang should only contain numbers'),
    physicalAddress: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    monthlyIncome: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    shareholderCompanies: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    email: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    spouse_employer: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    spouse_employment_status: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    spouse_job_title: string().refine((value) => value.trim() !== '', {
      message: 'Field is required'
    }),
    spouse_employment_start_date: coerce.date({
      required_error: 'Field is required',
      invalid_type_error: 'Invalid Date'
    })
  })

  const schema = discriminatedUnion('marital_status', [
    object({
      marital_status: z.enum(['single', 'divorced', 'widowed'])
    }).merge(
      object({
        firstName: string().default(''),
        middleName: string().default(''),
        lastName: string().default(''),
        spouse_employment_status: string().default('Permanent'),
        spouse_id: string().default(''),
        physicalAddress: string().default(''),
        monthlyIncome: string().default(''),
        shareholderCompanies: string().default(''),
        email: string().default('tear@moa.bw'),
        spouse_employer: string().default(''),
        spouse_job_title: string().default(''),
        spouse_employment_start_date: coerce
          .date({
            required_error: 'Field is required',
            invalid_type_error: 'Invalid Date'
          })
          .optional()
      })
    ),
    object({
      marital_status: literal('married')
    }).merge(base)
  ])

  type MaritalInformationDetailsInput = TypeOf<typeof schema>

  const onSubmit: SubmitHandler<MaritalInformationDetailsInput> = (
    data: MaritalInformationDetailsInput
  ) => {
    setLoanData((prev) => ({
      ...prev!,
      loan_spouse: {
        marital_status: data.marital_status,
        spouse_mid_name: data.middleName ?? '',
        spouse_id: data.spouse_id as unknown as number,
        spouse_first_name: data.firstName,
        spouse_last_name: data.lastName,
        spouse_physical_address: data.physicalAddress,
        spouse_employment_status: data.spouse_employment_status,
        spouse_shareholding_value: data.shareholderCompanies,
        spouse_employer: data.spouse_employer,
        spouse_job_title: data.spouse_job_title,
        spouse_income: data.monthlyIncome as unknown as number,
        spouse_phone: numbers,
        spouse_email: data.email,
        spouse_employment_start_date: data.spouse_employment_start_date
      }
    }))
    onNext()
  }

  return (
    <FormWithStepper<MaritalInformationDetailsInput, typeof schema>
      className={'w-full flex flex-col flex-grow-[3] justify-between'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ control, register, watch, formState: { errors }, handleSubmit, reset }) => (
        <div className={'w-full flex flex-col gap-8'}>
          <div className='w-full flex flex-col gap-2'>
            <Text size={'medium'} variant={'bodyTextLight'} className={'ml-8'}>
              {' '}
              Marital Information
            </Text>
            <RowContainer>
              <SelectField
                label='Marital Status'
                defaultValue={
                  loanData?.loan_spouse?.marital_status !== undefined
                    ? loanData?.loan_spouse?.marital_status
                    : 'single'
                }
                error={!(errors.marital_status == null)}
                helperText={errors?.marital_status?.message ?? ''}
                register={register('marital_status')}
                fullWidth
              >
                <MenuItem value='married'>
                  <Text>Married</Text>
                </MenuItem>
                <MenuItem value='single'>
                  <Text>Single</Text>
                </MenuItem>
                <MenuItem value='divorced'>
                  <Text>Divorced</Text>
                </MenuItem>
                <MenuItem value='widowed'>
                  <Text>Widowed</Text>
                </MenuItem>
              </SelectField>
            </RowContainer>

            {watch('marital_status') !== 'married' ? null : (
              <div className='w-full flex flex-col gap-2'>
                <Text size={'medium'} variant={'bodyTextLight'} className={'ml-8'}>
                  Particulars Of Spouse
                </Text>
                <RowContainer>
                  <InputField
                    className='flex-[1_0_230px] capitalize'
                    label={'First Name'}
                    error={!(errors.firstName == null)}
                    helperText={errors?.firstName?.message ?? ''}
                    register={register('firstName')}
                    defaultValue={loanData?.loan_spouse?.spouse_first_name}
                  />
                  <InputField
                    className='flex-[1_0_230px] capitalize'
                    label={'Middle Name'}
                    error={!(errors.middleName == null)}
                    helperText={errors?.middleName?.message ?? ''}
                    register={register('middleName')}
                    defaultValue={loanData?.loan_spouse?.spouse_mid_name}
                  />
                  <InputField
                    className='flex-[1_0_230px] capitalize'
                    label={'Last Name'}
                    error={!(errors.lastName == null)}
                    helperText={errors?.lastName?.message ?? ''}
                    register={register('lastName')}
                    defaultValue={loanData?.loan_spouse?.spouse_last_name}
                  />
                </RowContainer>
                <RowContainer>
                  <InputField
                    className='flex-[1_0_230px] capitalize'
                    label={'Omang'}
                    type={'number'}
                    error={!(errors.spouse_id == null)}
                    helperText={errors?.spouse_id?.message ?? ''}
                    register={register('spouse_id')}
                    defaultValue={loanData?.loan_spouse?.spouse_id}
                  />
                  <InputField
                    className='flex-[1_0_230px]  capitalize'
                    label={'Email'}
                    type={'email'}
                    error={!(errors.email == null)}
                    helperText={errors?.email?.message ?? ''}
                    register={register('email')}
                    defaultValue={loanData?.loan_spouse?.spouse_email}
                  />

                  <PhoneInputField
                    onChange={(num) => {
                      setNumbers(num)
                    }}
                    value={numbers}
                    className={'flex-[1_0_230px] capitalize'}
                  />
                </RowContainer>
                <RowContainer>
                  <SelectField
                    className='flex-[1_0_230px]  capitalize'
                    label={'Spouse Employment Status'}
                    defaultValue={loanData?.loan_spouse?.spouse_employment_status}
                    error={!(errors.spouse_employment_status == null)}
                    helperText={errors?.spouse_employment_status?.message ?? ''}
                    register={register('spouse_employment_status')}
                  >
                    <MenuItem value={'Permanent'}>
                      <Text>Permanent</Text>
                    </MenuItem>
                    <MenuItem value={'Contract'}>
                      <Text>Contract</Text>
                    </MenuItem>
                    <MenuItem value={'Self Employed'}>
                      <Text>Self Employed</Text>
                    </MenuItem>
                    <MenuItem value={'Pensioner'}>
                      <Text>Pensioner</Text>
                    </MenuItem>
                  </SelectField>

                  <InputField
                    className='flex-[1_0_230px] capitalize'
                    label={'Spouse Employer'}
                    error={!(errors.spouse_employer == null)}
                    helperText={errors?.spouse_employer?.message ?? ''}
                    register={register('spouse_employer')}
                    defaultValue={loanData?.loan_spouse?.spouse_employer}
                  />
                  <InputField
                    className='flex-[1_0_230px]  capitalize'
                    label={'Spouse Job Title'}
                    error={!(errors.spouse_job_title == null)}
                    helperText={errors?.spouse_job_title?.message ?? ''}
                    register={register('spouse_job_title')}
                    defaultValue={loanData?.loan_spouse?.spouse_job_title}
                  />
                </RowContainer>
                <RowContainer>
                  <CustomDatePicker
                    error={!(errors.spouse_employment_start_date == null)}
                    helperText={errors?.spouse_employment_start_date?.message ?? ''}
                    label={'Employment Start Date'}
                    name={'spouse_employment_start_date'}
                    control={control}
                    disableFuture={true}
                    defaultValue={
                      loanData?.loan_spouse?.spouse_employment_start_date !== undefined
                        ? dayjs(loanData?.loan_spouse.spouse_employment_start_date)
                        : dayjs(new Date(Date.now()))
                    }
                    className='flex-[1_0_230px]  capitalize'
                  />
                  <InputField
                    className='flex-[1_0_230px] capitalize'
                    label={'Monthly Income (BWP)'}
                    type={'number'}
                    error={!(errors.monthlyIncome == null)}
                    helperText={errors?.monthlyIncome?.message ?? ''}
                    register={register('monthlyIncome')}
                    defaultValue={loanData?.loan_spouse?.spouse_income}
                  />
                  <InputField
                    className='flex-[1_0_230px]  capitalize'
                    label={'Shareholder in Other Companies, Value (BWP)'}
                    type={'number'}
                    defaultValue={loanData?.loan_spouse?.spouse_shareholding_value}
                    error={!(errors.shareholderCompanies == null)}
                    helperText={errors?.shareholderCompanies?.message ?? ''}
                    register={register('shareholderCompanies')}
                  />
                </RowContainer>
                <RowContainer>
                  <InputField
                    defaultValue={loanData?.loan_spouse?.spouse_physical_address}
                    className='flex-[1_0_230px] capitalize'
                    label={'Physical Address'}
                    error={!(errors.physicalAddress == null)}
                    helperText={errors?.physicalAddress?.message ?? ''}
                    register={register('physicalAddress')}
                  />
                </RowContainer>
              </div>
            )}
          </div>

          <div className={'w-full hidden lg:flex flex-row justify-between'}>
            <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
            <BasicButton
              // disabled={watch('marital_status') !== 'single' && !isPhoneValid}
              label={'Next'}
              variant={'primary'}
              onClick={handleSubmit(onSubmit)}
              size={'xs'}
            />
          </div>
        </div>
      )}
    </FormWithStepper>
  )
}
