import { axios } from 'lib/axios'
import { type ApiResponse, type Lease } from 'types'

export interface GetLeaseDTO {
  farmerId: string
  extAreaId: number | null | undefined
}

export const getLease = async (params: GetLeaseDTO): Promise<ApiResponse<Lease>> => {
  return axios.get(
    `/lease?ORIGIN_FARMER_ID=${params.farmerId}&EXT_AREA_ID=${params.extAreaId ?? ''}`
  )
}
