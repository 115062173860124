import type React from 'react'
import { OTPInput } from 'components/Otp/InputOTP'

interface RedeemVoucherOtpFormProps {
  contact: string
  redeem: () => void
}

export const RedeemVoucherOtpForm: React.FC<RedeemVoucherOtpFormProps> = ({ redeem, contact }) => {
  return (
    <div className={'flex flex-col gap-4'}>
      <OTPInput isLoading={false} number={contact} next={redeem} />
    </div>
  )
}
