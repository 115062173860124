import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { EmailLogin } from '../components/email/EmailLogin'
import { AuthLayout } from '../components/layout/AuthLayout'

export const EmailLoginPage: React.FC = () => {
  const navigate = useNavigate()

  const onRegister = (): void => {
    navigate('/registration')
  }

  const onLogin = (role: number): void => {
    if (role === 1001) {
      navigate('/officer', { replace: true })
    } else if (role === 1000) {
      navigate('/farmer/farms', { replace: true })
    } else if (role === 2000) {
      navigate('/soil-lab', { replace: true })
    } else if (role === 1010) {
      navigate('/inventory', { replace: true })
    } else if (role === 2001) {
      navigate('/service-provider', { replace: true })
    } else if (role === 2002) {
      navigate('/seed-dealer', { replace: true })
    } else if (role === 2003) {
      navigate('/agrochemicals', { replace: true })
    } else {
      navigate('/officer/farms', { replace: true })
    }
  }

  const handleLoginType = (): void => {
    navigate('/login/phone', { replace: true })
  }

  return (
    <AuthLayout
      className={'w-full sm:max-w-[450px] flex flex-col justify-center items-center p-4 py-8'}
    >
      <EmailLogin onRegister={onRegister} onPhone={handleLoginType} onSuccess={onLogin} />
    </AuthLayout>
  )
}
