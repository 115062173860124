import type React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Chip, useMediaQuery } from '@mui/material'

interface FilterMenuProps {
  all: number
  rejected: number
  verified: number
  offers: number
  filterBy:
    | 'all'
    | 'REJECTED BY LESSEE'
    | 'verified'
    | 'INITIATED BY LESSOR'
    | 'VERIFIED BY EXTENSION OFFICER'
    | 'ACCEPTED BY LESSEE'
    | 'RENEWAL INITIATED BY LESSEE'
  setFilterBy: Dispatch<
    SetStateAction<
      | 'all'
      | 'REJECTED BY LESSEE'
      | 'verified'
      | 'INITIATED BY LESSOR'
      | 'VERIFIED BY EXTENSION OFFICER'
      | 'ACCEPTED BY LESSEE'
      | 'RENEWAL INITIATED BY LESSEE'
    >
  >
}

export const LeaseFilterMenu: React.FC<FilterMenuProps> = ({
  all,
  rejected,
  verified,
  offers,
  setFilterBy,
  filterBy
}) => {
  const handleFilter = (
    type:
      | 'all'
      | 'REJECTED BY LESSEE'
      | 'verified'
      | 'INITIATED BY LESSOR'
      | 'VERIFIED BY EXTENSION OFFICER'
      | 'ACCEPTED BY LESSEE'
  ): void => {
    setFilterBy(type)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  return (
    <div className={'w-full flex justify-start items-center gap-2'}>
      <Chip
        label={isSmallScreen ? `All(${all})` : `All Farms (${all})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'all' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'all' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'all' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('all')
        }}
        clickable={true}
      />

      <Chip
        label={`Verified (${verified})`}
        size={'small'}
        sx={{
          backgroundColor:
            filterBy === 'VERIFIED BY EXTENSION OFFICER' || filterBy === 'ACCEPTED BY LESSEE'
              ? 'var(--success)'
              : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'VERIFIED BY EXTENSION OFFICER' || filterBy === 'ACCEPTED BY LESSEE'
                ? 'var(--success-hover)'
                : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius:
            filterBy === 'VERIFIED BY EXTENSION OFFICER' || filterBy === 'ACCEPTED BY LESSEE'
              ? '0.4rem'
              : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'VERIFIED BY EXTENSION OFFICER' || filterBy === 'ACCEPTED BY LESSEE'
                ? 'var(--success-foreground)'
                : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'VERIFIED BY EXTENSION OFFICER' || filterBy === 'ACCEPTED BY LESSEE'
                  ? 'var(--success-foreground)'
                  : 'var(--success)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('ACCEPTED BY LESSEE')
        }}
        clickable={true}
      />

      <Chip
        label={`Active Offers (${offers})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'INITIATED BY LESSOR' ? 'var(--warning)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'INITIATED BY LESSOR' ? 'var(--warning-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'INITIATED BY LESSOR' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'INITIATED BY LESSOR'
                ? 'var(--warning-foreground)'
                : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'INITIATED BY LESSOR' ? 'var(--warning-foreground)' : 'var(--warning)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('INITIATED BY LESSOR')
        }}
        clickable={true}
      />

      <Chip
        label={`Rejected (${rejected})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'REJECTED BY LESSEE' ? 'var(--destructive)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'REJECTED BY LESSEE' ? 'var(--destructive-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'REJECTED BY LESSEE' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'REJECTED BY LESSEE'
                ? 'var(--destructive-foreground)'
                : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'REJECTED BY LESSEE'
                  ? 'var(--destructive-foreground)'
                  : 'var(--destructive)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('REJECTED BY LESSEE')
        }}
        clickable={true}
      />
    </div>
  )
}