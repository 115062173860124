import type React from 'react'
import { useState, useEffect } from 'react'
import { Button, Input, Text } from 'components/ui'
import type { Control, UseFormRegisterReturn } from 'react-hook-form'

interface InputAutoProps {
  withLabel?: boolean
  register?: Partial<UseFormRegisterReturn>
  label: string
  error?: boolean
  helperText?: string
  defaultValue?: string
  control?: Control<any, any>
  name: string
  placeholder: string
  data: string[]
  disabled?: boolean
  // onSelected: (value: string) => void
  // onChange: (value: string) => void
}
export const SearchInput: React.FC<InputAutoProps> = ({
  register,
  withLabel = false,
  helperText,
  error = false,
  label,
  name,
  control,
  defaultValue,
  placeholder,
  data,
  // onSelected,
  disabled = false
  // onChange
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [isHideSuggs, setIsHideSuggs] = useState<boolean>(false)
  const [selectedVal, setSelectedVal] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e.target.value.toUpperCase()
    setIsHideSuggs(false)
    setSelectedVal(input)
    // onChange(input)
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const inputValue = e.currentTarget.value.toUpperCase()
    if (inputValue.length >= 3) {
      setSuggestions(data.filter((i) => i.startsWith(inputValue)))
    } else {
      setSuggestions([])
    }

    if (e.key === 'Enter') {
      // onSelected(selectedVal)
      setSuggestions([])
      // setIsHideSuggs(true)
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const hideSuggs = (value: string) => {
    // onSelected(value)
    setSelectedVal(value.toUpperCase())
    setIsHideSuggs(true)
    setSuggestions([])
  }

  useEffect(() => {
    if (selectedVal.length > 0) {
      hideSuggs(selectedVal)
    }
  }, [selectedVal])

  return (
    <div className='w-full relative'>
      <div className='w-full relative'>
        <Input
          type='search'
          label={label}
          withLabel={withLabel}
          error={error}
          name={name}
          defaultValue={selectedVal}
          helperText={helperText}
          placeholder={placeholder}
          disabled={disabled}
          value={selectedVal}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          {...register}
        />

        <Button variant={'ghost'} className={'absolute right-1 top-1'} disabled>
          <span className={'fa-solid fa-search'}></span>
        </Button>
      </div>

      {suggestions.length > 0 && (
        <div
          className={`w-[300px] bg-card absolute left-4 top-[3.4rem]  p-4 rounded-md border outline-none z-50 dark:shadow-[0px_10px_15px_0px_black] shadow-[0px_10px_10px_0px_grey] ${
            isHideSuggs ? 'flex' : ' hidden'
          } flex-col gap-2`}
        >
          {suggestions.map((item, idx) => (
            <Text
              key={idx}
              onClick={() => {
                hideSuggs(item)
              }}
              className=' mx-2 py-1 p-2 cursor-pointer rounded shadow-md hover:bg-primary-hover hover:text-primary-foreground'
            >
              {item}
            </Text>
          ))}
        </div>
      )}
    </div>
  )
}