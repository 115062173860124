import { cn } from 'lib/utils'
import { Loader as Loader2 } from 'lucide-react'
import type React from 'react'

export interface ISVGProps extends React.SVGProps<SVGSVGElement> {
  size?: number
  className?: string
}

export const Loader: React.FC<ISVGProps> = ({ className, size = 24 }) => {
  return <Loader2 size={size} className={cn('animate-spin text-primary', className)} />
}
