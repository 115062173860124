import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { cancelSSR, type CancelSSRDTO } from 'features/farmer/ssr/api/cancelSSR'
import { createSSR, type CreateSSRDTO } from 'features/farmer/ssr/api/createSSR'
import { updateRequest, type UpdateRequest } from 'features/farmer/ssr/api/updateRequest'
import { type ApiResponse, type Farmer, type SoilSampleRequest } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'
import type z from 'zod'
import { type newSSRSchema } from '../components/new-ssr/NewSSR'

interface UseSSR {
  isLoading: boolean
  newSSR: (
    data: z.infer<typeof newSSRSchema>,
    farmId: number | null | undefined,
    leaseId?: number | null | undefined
  ) => void
  updateSSR: (data: z.infer<typeof newSSRSchema>, ssr: SoilSampleRequest) => void
  deleteSSR: (ssr: SoilSampleRequest) => void
}

export const useSSR = (farmer: Farmer | null, next: () => void): UseSSR => {
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const requestMutation = useMutation({
    mutationFn: async (data: CreateSSRDTO) => {
      return createSSR(data)
    }
  })

  const updateRequestMutation = useMutation({
    mutationFn: async (data: UpdateRequest) => {
      return updateRequest(data)
    }
  })

  const deleteRequestMutation = useMutation({
    mutationFn: async (params: CancelSSRDTO) => {
      return cancelSSR(params)
    }
  })
  const newSSR = (
    data: z.infer<typeof newSSRSchema>,
    farmId: number | null | undefined,
    leaseId?: number | null | undefined
  ): void => {
    requestMutation.mutate(
      {
        user_id: farmer?.user_id,
        farm_id: farmId,
        lease_id: leaseId,
        farmer_id: farmer?.farmer_id,
        merchant_id: data.merchantID
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Soil Sample Requested Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['ssr'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const updateSSR = (data: z.infer<typeof newSSRSchema>, ssr: SoilSampleRequest): void => {
    updateRequestMutation.mutate(
      {
        user_id: farmer?.user_id,
        farm_id: ssr.farm_id,
        farmer_id: ssr.farmer_id,
        merchant_id: data.merchantID,
        ssr_id: ssr.ssr_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 200) {
            next()
            toast({
              variant: 'success',
              description: result.message
            })
            void queryClient.invalidateQueries({
              queryKey: ['ssr']
            })
          }
        },
        onError: (err: unknown) => {
          if (err instanceof AxiosError) {
            const errorMsg = err.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errorMsg.data.message
            })
          }
        }
      }
    )
  }

  const deleteSSR = (ssr: SoilSampleRequest): void => {
    deleteRequestMutation.mutate(
      {
        userId: farmer?.user_id,
        farmId: ssr.farm_id,
        farmerId: ssr.farmer_id,
        merchantId: ssr.merchant_id,
        ssrId: ssr.ssr_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 200) {
            next()
            toast({
              variant: 'success',
              description: result.message
            })
            void queryClient.invalidateQueries({
              queryKey: ['ssr']
            })
          }
        },
        onError: (err: unknown) => {
          if (err instanceof AxiosError) {
            const errorMsg = err.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errorMsg.data.message
            })
          }
        }
      }
    )
  }

  return {
    isLoading:
      requestMutation.isLoading ||
      updateRequestMutation.isLoading ||
      deleteRequestMutation.isLoading,
    deleteSSR,
    updateSSR,
    newSSR
  }
}
