import type React from 'react'
import { useState } from 'react'
import { type Farmer } from 'types'
import { TitleDeed } from './TitleDeed'
import { RegWithDeed } from './with-titledeed/RegWithDeed'
import { RegWithoutDeed } from './without-titledeed/RegWithoutDeed'
import { useNewFarmStore } from '../../stores/newFarm'

interface RegWithoutTitleDeedProps {
  farmer: Farmer
  onClose: () => void
}

export const RegistrationContainer: React.FC<RegWithoutTitleDeedProps> = ({ farmer, onClose }) => {
  const { setLocationDetails, setFarmDetails } = useNewFarmStore()
  const [usingTitleDeed, setUsingTitleDeed] = useState(false)
  const [withoutTitleDeed, setWithoutTitleDeed] = useState(false)

  const newFarm = (): void => {
    setLocationDetails(null)
    setFarmDetails(null)
  }

  if (usingTitleDeed) {
    return (
      <RegWithDeed
        onBack={() => {
          setUsingTitleDeed(false)
        }}
        onClose={onClose}
        farmer={farmer}
      />
    )
  }
  if (withoutTitleDeed) {
    return <RegWithoutDeed onClose={onClose} />
  }

  return (
    <TitleDeed
      onClickNo={() => {
        newFarm()
        setWithoutTitleDeed(true)
      }}
      onClickYes={() => {
        newFarm()
        setUsingTitleDeed(true)
      }}
    />
  )
}
