import { ErrorPage } from 'components/Errors/ErrorPage'
import { useGetRequests } from 'features/lab/ssr/api/getRequests'
import type React from 'react'
import { useState } from 'react'
import { useMerchantStore } from 'stores/useMerchantStore'
import { Button, Card, Dialog, DialogContent, Loader, Text } from 'components/ui'
import { SearchRequests } from './SearchRequests'
import { ssrColumns } from '../components/table/column'
import { Box } from '../../../../components/App/Layout'
import { SSRTable } from '../components/table/ssrTable'

export const SoilSampleRequests: React.FC = () => {
  const [newRequest, setNewRequest] = useState(false)
  const { merchant } = useMerchantStore()
  const { data: requests, isLoading, error } = useGetRequests(merchant?.merchant_id)

  const handleCloseNewRequest = (): void => {
    setNewRequest(false)
  }

  const handleOpenNewRequest = (): void => {
    setNewRequest(true)
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null && error?.response?.status !== 404) {
    return <ErrorPage />
  }

  if (requests?.data === undefined) {
    return (
      <Box>
        <Text size={'large'} className={'ml-8'}>
          My Soil Sample Analyses
        </Text>
        <div
          className={
            'w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8 '
          }
        >
          <Text size={'medium'} variant={'bodyTextLight'}>
            No Soil Sample Analyses found
          </Text>
        </div>
      </Box>
    )
  }

  return (
    <Box>
      <div className={'flex justify-between items-center'}>
        <Text size={'large'}>My Soil Sample Analyses</Text>
        <Button size={'xs'} variant={'success'} onClick={handleOpenNewRequest}>
          New Request
          <p className={'fa-solid fa-add'} />
        </Button>
      </div>

      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <SSRTable columns={ssrColumns} data={requests.data} />
      </Card>

      <Dialog open={newRequest} defaultOpen={false} modal onOpenChange={handleCloseNewRequest}>
        <DialogContent className={'bg-card  w-full md:w-[50%] lg:w-[35%] max-w-[430px]'}>
          <Text>Search for New Soil Sample Requests</Text>
          <SearchRequests />
        </DialogContent>
      </Dialog>
    </Box>
  )
}
