import { type FC } from 'react'
import { type Lease } from 'types'
import { LeaseInformation } from '../lease-details/LeaseInformation'
import { OwnerInformation } from '../lease-details/OwnerInformation'
import { FarmInformation } from '../lease-details/FarmInformation'

interface InformationProps {
  lease: Lease
}

export const Information: FC<InformationProps> = ({ lease }) => {
  return (
    <>
      <LeaseInformation lease={lease} />
      <FarmInformation lease={lease} />
      <OwnerInformation lease={lease} />
    </>
  )
}
