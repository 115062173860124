import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { AccountType } from '../components/layout/AccountType'

export const Register: React.FC = () => {
  const { setContactDetails, setOmangDetails } = useIndividualRegistrationStore()
  const navigate = useNavigate()

  const handleIndividual = (): void => {
    setContactDetails(null)
    setOmangDetails(null)
    navigate('/registration/individual')
  }

  const handleCompany = (): void => {
    navigate('/registration/company')
  }

  return <AccountType onClickIndividual={handleIndividual} onClickCompany={handleCompany} />
}
