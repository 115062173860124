import type React from 'react'
import { Text } from 'components/ui'

interface VerifiedStatusProps {
  status_desc:
    | 'INITIATED BY LESSOR'
    | 'ACCEPTED BY LESSEE'
    | 'REJECTED BY LESSEE'
    | 'VERIFIED BY EXTENSION OFFICER'
    | 'TERMINATION INITIATED BY LESSOR'
    | 'TERMINATION INITIATED BY LESSEE'
    | 'TERMINATION ACCEPTED BY LESSEE'
    | 'TERMINATION ACCEPTED BY LESSOR'
    | 'RENEWAL INITIATED BY LESSEE'
    | 'RENEWAL ACCEPTED BY LESSOR'
    | 'EXPIRED'
  className?: string
}

export const LeaseVerificationStatus: React.FC<VerifiedStatusProps> = ({
  status_desc,
  className
}) => {
  return (
    <div className={'flex gap-2 items-center w-full'}>
      {(status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
        status_desc === 'RENEWAL ACCEPTED BY LESSOR') && (
        <Text size={'medium'} variant={'success'} className={'font-normal flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-check' />
          Verified
        </Text>
      )}
      {status_desc === 'ACCEPTED BY LESSEE' && (
        <Text size={'medium'} variant={'warning'} className={'font-normal flex gap-2 items-center'}>
          Pending Verification by EO
        </Text>
      )}

      {status_desc === 'INITIATED BY LESSOR' && (
        <Text size={'medium'} variant={'warning'} className={'font-normal flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> Pending Acceptance
        </Text>
      )}
      {status_desc === 'EXPIRED' && (
        <Text size={'medium'} variant={'error'} className={'font-normal flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-xmark' /> Expired
        </Text>
      )}
      {status_desc === 'RENEWAL INITIATED BY LESSEE' && (
        <Text size={'medium'} variant={'warning'} className={'font-normal flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> Awaiting Approval
        </Text>
      )}
      {(status_desc === 'TERMINATION INITIATED BY LESSOR' ||
        status_desc === 'TERMINATION INITIATED BY LESSEE') && (
        <Text size={'medium'} variant={'warning'} className={'font-normal flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> Awaiting Termination
        </Text>
      )}
      {status_desc === 'REJECTED BY LESSEE' && (
        <Text size={'medium'} variant={'error'} className={'font-normal flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-xmark' /> Rejected
        </Text>
      )}
      {(status_desc === 'TERMINATION ACCEPTED BY LESSOR' ||
        status_desc === 'TERMINATION ACCEPTED BY LESSEE') && (
        <Text size={'medium'} variant={'error'} className={'font-normal flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-xmark' /> Terminated
        </Text>
      )}
    </div>
  )
}
