import type React from 'react'
import type {
  GetInventoryRes,
  Implement,
  PoweredImplement,
  Tractor
} from '../../../../farmer/inventory/types'
import { TractorChecklist } from './TractorChecklist'
import { useGetStaticInventory } from '../../../../farmer/inventory/api/getInventoryData'
import { TilageChecklist } from './TilageChecklist'
import { HarvestingProcessingChecklist } from './HarvestingProcessingChecklist'
import { Applicators } from './SprayersChecklist'
import { Loader } from '../../../../../components/ui'
import { SeederChecklist } from './SeedersChecklist'
import { SelfPropelledSprayers } from './PropelledSprayersChecklist'

interface ChecklistProps {
  onBack: () => void
  className: string
  inventoryData: GetInventoryRes
  inventoryItem: Tractor | Implement | PoweredImplement | undefined
  onClose: () => void
}

export const Checklist: React.FC<ChecklistProps> = ({
  className,
  inventoryData,
  onClose,
  inventoryItem,
  onBack
}) => {
  const { data: staticInvData, isLoading, error } = useGetStaticInventory()

  if (isLoading) {
    return (
      <div className={'mt-8 grid place-items-center h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    return <div>Error loading inventory data.</div>
  }
  if (inventoryData.inventory_type_id === 1001) {
    const data = inventoryItem as PoweredImplement
    const checklistType = staticInvData?.data?.powered_implements.find(
      (item) => item.implement_class === data?.implement_class
    )

    if (checklistType != null) {
      switch (checklistType.implement_class) {
        case 'SECONDARY':
        case 'PRIMARY':
          return (
            <TilageChecklist
              onBack={onBack}
              className={className}
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              onClose={onClose}
              isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
            />
          )
        case 'PROCESSOR':
        case 'HARVESTER': {
          return (
            <HarvestingProcessingChecklist
              onBack={onBack}
              className={className}
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              onClose={onClose}
              isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
            />
          )
        }
        case 'APPLICATION EQUIPMENT':
          if (checklistType.implement_sub_type === 'SELF PROPELLED') {
            return (
              <SelfPropelledSprayers
                onBack={onBack}
                className={className}
                inventoryData={inventoryData}
                inventoryItem={inventoryItem}
                onClose={onClose}
                isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
              />
            )
          } else {
            return (
              <Applicators
                onBack={onBack}
                className={className}
                inventoryData={inventoryData}
                inventoryItem={inventoryItem}
                onClose={onClose}
                isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
              />
            )
          }

        case 'SEEDER':
          return (
            <SeederChecklist
              onBack={onBack}
              className={className}
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              onClose={onClose}
              isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
            />
          )
        default:
          break
      }
    }
  }
  if (inventoryData.inventory_type_id === 1002) {
    const data = inventoryItem as Implement
    const checklistType = staticInvData?.data?.implements.find(
      (item) => item.implement_class === data?.implement_class
    )

    if (checklistType != null) {
      switch (checklistType.implement_class) {
        case 'SECONDARY':
        case 'PRIMARY':
          return (
            <TilageChecklist
              onBack={onBack}
              className={className}
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              onClose={onClose}
              isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
            />
          )
        case 'PROCESSOR':
        case 'HARVESTER':
          return (
            <HarvestingProcessingChecklist
              onBack={onBack}
              className={className}
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              onClose={onClose}
              isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
            />
          )

        case 'APPLICATION EQUIPMENT':
          return (
            <Applicators
              onBack={onBack}
              className={className}
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              onClose={onClose}
              isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
            />
          )
        case 'SEEDER':
          return (
            <SeederChecklist
              onBack={onBack}
              className={className}
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              onClose={onClose}
              isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
            />
          )
        default:
          break
      }
    }
  }

  return (
    <TractorChecklist
      onBack={onBack}
      className={className}
      inventoryData={inventoryData}
      inventoryItem={inventoryItem}
      onClose={onClose}
      isEdit={inventoryData.item_verification_status !== 'PENDING_VERIFICATION'}
    />
  )
}
