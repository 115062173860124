import { type Grant } from 'types'
import { useGetFarmerGrants } from '../api/getFarmerGrants'
import { useGetFarmGrant } from '../api/getFarmGrant'
import { useFarmerStore } from '../../../../stores/useFarmerStore'

interface UseGrants {
  isLoading: boolean
  error: boolean
  grants: Grant[] | undefined
  farmGrants: Grant[] | undefined
}

export const useGrants = (farmId: string | number | null | undefined): UseGrants => {
  const { farmer } = useFarmerStore()

  // get all grants for farmer
  const {
    data: grants,
    isLoading: grantsIsLoading,
    error: grantsError
  } = useGetFarmerGrants(farmer?.farmer_id)

  // get all grants for a specific farm
  const {
    data: farmGrants,
    isLoading: farmGrantsIsLoading,
    error: farmGrantsError
  } = useGetFarmGrant(farmer?.farmer_id, farmId)

  const isError =
    grantsError != null &&
    grantsError?.response?.status !== 404 &&
    farmGrantsError != null &&
    farmGrantsError?.response?.status !== 404

  return {
    isLoading: grantsIsLoading || farmGrantsIsLoading,
    error: isError,
    farmGrants: farmGrants?.data,
    grants: grants?.data
  }
}
