import type React from 'react'
import { ThreeCircles } from 'react-loader-spinner'

export const Loader: React.FC = () => {
  return (
    <div className={'h-screen flex justify-center items-center'}>
      <ThreeCircles
        height='100'
        width='100'
        color='#0081CCFF'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
        ariaLabel='three-circles-rotating'
        outerCircleColor=''
        innerCircleColor=''
        middleCircleColor=''
      />
    </div>
  )
}
