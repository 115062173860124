import { useSoilSampleRequest } from 'features/lab/ssr/hooks/useSoilSampleRequest'
import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import type z from 'zod'
import { object, string } from 'zod'
import {
  Input,
  Button,
  FormField,
  FormItem,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  useToast,
  FormLabel,
  FormControl,
  FormMessage,
  Form
} from 'components/ui'
import { zodResolver } from '@hookform/resolvers/zod'

const schema = object({
  userType: string().min(1, 'Field is required'),
  userInput: string().min(1, 'Field is required'),
  landType: string().min(1, 'Field is required'),
  landInput: string().min(1, 'Field is required')
}).refine(
  (data) => {
    if (data.userType === 'omang') {
      return data.userInput.length === 9
    }
    if (data.userType === 'farmerId') {
      return data.userInput.length === 7
    }
    return false
  },
  {
    message: 'Invalid input length',
    path: ['userInput']
  }
)

export const SearchRequests: React.FC = () => {
  const { toast } = useToast()
  const { searchSoilSampleRequest, soilSampleRequest, isLoading } = useSoilSampleRequest()
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      landInput: '',
      landType: '',
      userInput: '',
      userType: ''
    }
  })

  useEffect(() => {
    if (
      soilSampleRequest !== null &&
      soilSampleRequest?.status_name !== 'SUBMITTED' &&
      soilSampleRequest?.status_name !== 'REJECTED'
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Not Found'
      })
    }

    if (
      soilSampleRequest !== null &&
      (soilSampleRequest.status_name === 'SUBMITTED' ||
        soilSampleRequest?.status_name === 'REJECTED')
    ) {
      navigate(
        `/soil-lab/request/${soilSampleRequest?.farmer_id}/${soilSampleRequest?.farm_id}/${soilSampleRequest?.ssr_id}`
      )
    }
  }, [soilSampleRequest])

  const onSubmit = async (data: z.infer<typeof schema>): Promise<void> => {
    await searchSoilSampleRequest(data.userType, data.userInput, data.landType, data.landInput)
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className={'w-full flex gap-4 items-end'}>
          <FormField
            control={form.control}
            name='userType'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className={'ml-2 font-redHat'}>ID Type</FormLabel>
                <Select onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='Omang/Farmer ID' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem key={'omang'} value={'omang'}>
                      Omang ID
                    </SelectItem>
                    <SelectItem key={'farmerId'} value={'farmerId'}>
                      Farmer ID
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={'userInput'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className={'ml-2 font-redHat '}>Omang /Farmer ID</FormLabel>
                <FormControl>
                  <Input
                    type={'text'}
                    placeholder='987615432'
                    classLabel={'bg-background-dark sm:bg-card'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={'w-full flex gap-4 items-end'}>
          <FormField
            control={form.control}
            name='landType'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className={'ml-2 font-redHat'}>Land ID Type</FormLabel>
                <Select onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='Lease ID / Farm ID' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem key={'farmId'} value={'farmId'}>
                      Farm ID
                    </SelectItem>
                    <SelectItem key={'leaseId'} value={'leaseId'}>
                      Lease ID
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={'landInput'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className={'ml-2 font-redHat '}>Lease/Farm ID</FormLabel>
                <FormControl>
                  <Input
                    type={'text'}
                    placeholder='455'
                    classLabel={'bg-background-dark sm:bg-card'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={'max-w-[200px] w-full flex self-end'}>
          <Button size={'lg'} type={'submit'} loading={isLoading} disabled={isLoading}>
            Search
          </Button>
        </div>
      </form>
    </Form>
  )
}
