import { NotFound } from 'components/Errors/NotFound'
import { Unauthorized } from 'components/Errors/Unauthorized'
import { PhoneLoginPage, Register } from 'features/authentication/routes'
import { CompanyRegistrationPage } from 'features/authentication/routes/CompanyRegistrationPage'
import { EmailLoginPage } from 'features/authentication/routes/EmailLoginPage'
import { IndividualRegistrationPage } from 'features/authentication/routes/IndividualRegistrationPage'
import { ManualNavigation, UserAccess } from 'features/landing/components'
import { Homepage, UserManuals } from 'features/landing/routes'
import { Test } from 'pages/Test'

export const publicRoutes = [
  {
    path: '/',
    element: <Homepage />
  },
  {
    path: '/user-manuals/',
    element: <UserManuals />,
    children: [
      { path: '', element: <ManualNavigation /> },
      { path: 'user-access-basics', element: <UserAccess /> }
    ]
  },
  {
    path: '/login/phone',
    element: <PhoneLoginPage />
  },
  {
    path: '/login/email',
    element: <EmailLoginPage />
  },
  {
    path: '/registration',
    element: <Register />
  },
  {
    path: '/registration/individual',
    element: <IndividualRegistrationPage />
  },
  {
    path: '/registration/company',
    element: <CompanyRegistrationPage />
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />
  },
  {
    path: '/test',
    element: <Test />
  },
  {
    path: '/*',
    element: <NotFound />
  }
]
