import { Farmer } from 'components/Auth/Farmer'
import { Inventories } from 'features/farmer/inventory/routes'
import { LandManagement, FarmView } from 'features/farmer/land-management/routes'
import { LeaseManagement, LeaseView } from 'features/farmer/lease-management/routes'
import { LoanApplication, LoanView } from 'features/loan-management/routes'
import { Profile } from 'features/profile/routes'
import { Navigate } from 'react-router-dom'
import { CompleteApplication } from '../features/loan-management/components/stepper/individual/CompleteAplication'
import { InventoryForm } from 'features/farmer/inventory/components'

export const farmerRoutes = {
  path: '/',
  element: <Farmer />,
  children: [
    { path: '/farmer/profile', element: <Profile /> },
    { path: '/farmer/farms', element: <LandManagement /> },
    { path: '/farmer/farms/:farmId', element: <FarmView /> },
    { path: '/farmer/lease', element: <LeaseManagement /> },
    { path: '/farmer/lease/:leaseId', element: <LeaseView /> },
    { path: '/farmer/loans', element: <LoanView /> },
    { path: '/farmer/loans/newloan', element: <LoanApplication /> },
    { path: '/farmer/loans/completeApplication', element: <CompleteApplication /> },
    { path: '/farmer/inventory', element: <Inventories /> },
    { path: '/farmer/inventory/register-new/:inventoryType', element: <InventoryForm /> },
    { path: '/farmer/inventory/register-new/*', element: <InventoryForm /> },
    { path: '/farmer/*', element: <Navigate to={'/farmer/farms'} /> },
    { path: '*', element: <Navigate to={'.'} /> }
  ]
}
