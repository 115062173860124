import { discriminatedUnion, literal, object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import {
  useStepper,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { StepperFooter } from '../Footer'
import { useGetWeeding } from '../../../api/weeding/getWeeding'
import { useCroppingPlanWeeding } from '../../../hooks/useCroppingPlanWeeding'
import { getManualWeedMethodValue } from '../../../utils/functions'

const base = object({
  method: z
    .enum(['Interrow Cultivation', 'Human Based'], {
      required_error: 'You need to select a manual weeding method'
    })
    .optional()
})

export const weedingSchema = discriminatedUnion('type', [
  object({
    type: z.enum(['Manual'], {
      required_error: 'You need to select a weeding control type'
    })
  }).merge(
    object({
      method: z.enum(['Interrow Cultivation', 'Human Based'], {
        required_error: 'You need to select a manual weeding method'
      })
    })
  ),
  object({
    type: literal('Chemical')
  }).merge(base)
])

export const Weeding: React.FC = () => {
  const { nextStep } = useStepper()
  const { header, isLoading, setWeeding, createManualWeeding, updateManualWeeding } =
    useCroppingPlanWeeding(nextStep)

  const {
    data: apiWeeding,
    isInitialLoading: apiIsLoading,
    error: weedingError
  } = useGetWeeding(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof weedingSchema>>({
    resolver: zodResolver(weedingSchema),
    defaultValues: {
      type: apiWeeding != null ? apiWeeding?.data?.type : 'Manual',
      method:
        apiWeeding != null
          ? getManualWeedMethodValue(apiWeeding?.data.manual_method)
          : 'Interrow Cultivation'
    }
  })

  const onSubmit = (data: z.infer<typeof weedingSchema>): void => {
    if (data.type === 'Manual') {
      if (apiWeeding == null) {
        createManualWeeding(data)
      } else if (
        apiWeeding.data.type !== data.type ||
        getManualWeedMethodValue(apiWeeding.data.manual_method) !== data.method
      ) {
        updateManualWeeding(data)
      } else {
        nextStep()
      }
    }
  }

  useEffect(() => {
    if (apiWeeding != null) {
      setWeeding(apiWeeding.data)
      form.reset()
    }
  }, [isLoading])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (weedingError != null) {
    if (weedingError?.response?.status !== 404) {
      return <Error />
    }
  }

  const isManual = form.watch('type') === 'Manual' || form.getValues('type') === 'Manual'

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='type'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>Select a Weeding technique...</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem disabled value='Chemical' />
                      </FormControl>
                      <FormLabel className='font-normal'>Chemical</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Manual' />
                      </FormControl>
                      <FormLabel className='font-normal'>Manual</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {isManual ? (
            <FormField
              control={form.control}
              name='method'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel>Select a Manual Weeding method...</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Interrow Cultivation' />
                        </FormControl>
                        <FormLabel className='font-normal'>Interrow Cultivation</FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Human Based' />
                        </FormControl>
                        <FormLabel className='font-normal'>Human Based</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}
        </div>
        <StepperFooter isLoading={isLoading} />
      </form>
    </Form>
  )
}
