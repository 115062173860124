import { NotFound } from 'components/Errors/NotFound'
import { useGetSSR } from 'features/lab/ssr/api/getSSR'
import { useSoilSampleRequest } from 'features/lab/ssr/hooks/useSoilSampleRequest'
import moment from 'moment/moment'
import type React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMerchantStore } from 'stores/useMerchantStore'
import { ArrowLeft, XCircle } from 'lucide-react'
import { Text, Button, Loader, DialogContent, Dialog } from 'components/ui'
import { Box, TabView } from 'components/App/Layout'
import { RejectSSR } from '../components/RejectSSR'
interface InfoProps {
  label: string
  name: string
}
const Info: React.FC<InfoProps> = ({ label, name }) => {
  return (
    <div className={'flex flex-col'}>
      <Text size={'small'} className={'ml-1 capitalize'} variant={'primary'}>
        {label}
      </Text>
      <Text size={'medium'} className={label !== 'email address' ? 'capitalize' : ''}>
        {name}
      </Text>
    </div>
  )
}
export const SoilSampleRequest: React.FC = () => {
  const { merchant } = useMerchantStore()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { ssrId } = useParams<{ ssrId: string }>()
  const { farmId } = useParams<{ farmId: string }>()

  const navigate = useNavigate()
  const {
    data: SSR,
    isLoading: ssrIsLoading,
    error: ssrError
  } = useGetSSR(farmerId, ssrId, farmId, merchant?.merchant_id)
  const { acceptSoilSampleRequest, isLoading } = useSoilSampleRequest()
  const [rejectSsr, setRejectSsr] = useState(false)

  const handleCloseRejectSsr = (): void => {
    setRejectSsr(false)
  }

  const handleOpenRejectSsr = (): void => {
    setRejectSsr(true)
  }

  const acceptSSR = (): void => {
    acceptSoilSampleRequest(SSR?.data[0])
  }
  const handleBack = (): void => {
    navigate('/soil-lab', { replace: true })
  }

  if (ssrIsLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (ssrError !== null && SSR == null) {
    return <NotFound />
  }

  if (
    ssrError !== null &&
    SSR?.data[0].status_name !== 'SUBMITTED' &&
    SSR?.data[0].status_name !== 'REJECTED'
  ) {
    return <NotFound />
  }

  return (
    <Box>
      <div className={'flex items-center gap-2 mt-2 sm:mt-4'}>
        <Button
          onClick={handleBack}
          variant={'ghost'}
          className={
            'bg-transparent gap-2 py-1 text-[clamp(1.3rem,4vw,1.5rem)] leading-[70%] font-semibold capitalize'
          }
        >
          <ArrowLeft />
        </Button>
        <Text size={'large'}>Soil profile</Text>
      </div>
      <TabView>
        <div className={'w-full flex items-center justify-between'}>
          <Text size={'medium'} className={'capitalize'}>
            {SSR?.data[0]?.farm_name.toLowerCase()} of SSR ID {SSR?.data[0].ssr_id}
          </Text>
          {SSR?.data[0]?.status_name === 'REJECTED' && (
            <Text variant={'error'} className={'flex items-center gap-2'}>
              <XCircle size={20} /> SSR Rejected
            </Text>
          )}
        </div>

        <div className={'flex flex-col border-t border-input py-4'}>
          <Text size={'medium'}>Personal Information</Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'Farmer ID'} name={`${SSR?.data[0].farmer_id ?? ''}`} />
            <Info label={'ploughing season'} name={`${SSR?.data[0].plough_season ?? ''}`} />
            <Info label={'farm id'} name={`${SSR?.data[0].farm_id ?? ''}`} />
            <Info
              label={'farmer name'}
              name={`${SSR?.data[0].farmer_firstname ?? ''} ${
                SSR?.data[0].farmer_middlename ?? ''
              } ${SSR?.data[0].farmer_surname ?? ''}`}
            />
            <Info
              label={'soil sample creation date'}
              name={`${moment(SSR?.data[0].create_date).format('DD-MM-YYYY') ?? ''}`}
            />
            <Info label={'farmer contact'} name={`${SSR?.data[0].farmer_contact ?? ''}`} />
            <Info
              label={'extension officer'}
              name={`${SSR?.data[0].eo_firstname ?? ''} ${SSR?.data[0].eo_middlename ?? ''} ${
                SSR?.data[0].eo_surname ?? ''
              }`}
            />
            {/* <Info label={'farmer email'} name={`${SSR?.data[0].farmer_contact ?? ''}`} /> */}
          </div>
          {SSR?.data[0]?.status_name === 'REJECTED' && (
            <div className={'flex flex-col border-t border-input py-4 gap-2'}>
              <Text size={'medium'}>Rejection Message</Text>
              <Text>{SSR?.data[0].comment}</Text>
            </div>
          )}
        </div>

        {SSR?.data[0]?.status_name === 'REJECTED' ? (
          <div className='w-full flex justify-end '>
            <Button
              onClick={handleBack}
              loading={isLoading}
              disabled={isLoading}
              className={'min-w-24'}
            >
              Ok
            </Button>
          </div>
        ) : (
          <div className={'flex justify-between'}>
            <Button
              variant={'destructive'}
              onClick={handleOpenRejectSsr}
              disabled={isLoading}
              className={'min-w-24'}
            >
              Reject
            </Button>
            <Button
              onClick={acceptSSR}
              loading={isLoading}
              disabled={isLoading}
              className={'min-w-24'}
              variant={'success'}
            >
              Accept SSR
            </Button>
          </div>
        )}

        <Dialog open={rejectSsr} defaultOpen={false} modal onOpenChange={handleCloseRejectSsr}>
          <DialogContent className={'bg-card w-full md:w-[50%] lg:w-[35%] max-w-[430px]'}>
            <RejectSSR cancel={handleCloseRejectSsr} ssr={SSR?.data[0]} />
          </DialogContent>
        </Dialog>
      </TabView>
    </Box>
  )
}
