import { type Service } from '../types'

export const Services: Service[] = [
  {
    service_header_id: 1,
    service_name: 'Ploughing',
    service_type: 'Land Preparation',
    plough_season: '2022/23',
    price: 950.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 2,
    service_name: 'Harrowing',
    service_type: 'Land Preparation',
    plough_season: '2022/23',
    price: 450.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 3,
    service_name: 'Minimum Tillage',
    service_type: 'Land Preparation',
    plough_season: '2022/23',
    price: 900.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 52,
    service_name: 'Planting',
    service_type: 'Land Preparation',
    plough_season: '2022/23',
    price: 450.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 37,
    service_name: 'A6',
    service_type: 'Weed Control - Manual',
    plough_season: '2022/23',
    price: 330,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 39,
    service_name: 'A5',
    service_type: 'Weed Control - Manual',
    plough_season: '2022/23',
    price: 330,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 40,
    service_name: 'A4',
    service_type: 'Application Method',
    plough_season: '2022/23',
    price: 330,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 41,
    service_name: 'A3',
    service_type: 'Application Method',
    plough_season: '2022/23',
    price: 170,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 42,
    service_name: 'A1',
    service_type: 'Application Method',
    plough_season: '2022/23',
    price: 440,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 43,
    service_name: 'Labour',
    service_type: 'Pest Control - Manual',
    plough_season: '2022/23',
    price: 330,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 56,
    service_name: 'Biological Pest Control',
    service_type: 'Pest Control - Manual',
    plough_season: '2022/23',
    price: 80.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 57,
    service_name: 'Physical Pest Control',
    service_type: 'Pest Control - Manual',
    plough_season: '2022/23',
    price: 80.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 44,
    service_name: 'Human Based Harvesting',
    service_type: 'Harvesting',
    plough_season: '2022/23',
    price: 500.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 45,
    service_name: 'Machine Based Harvesting',
    service_type: 'Harvesting',
    plough_season: '2022/23',
    price: 500.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 46,
    service_name: 'Machinery - 50 KG Bags',
    service_type: 'Packaging',
    plough_season: '2022/23',
    price: 80.0,
    unit_of_measure: 'bag',
    uom_count: 1
  },
  {
    service_header_id: 47,
    service_name: 'Labour - 50 KG Bags',
    service_type: 'Packaging',
    plough_season: '2022/23',
    price: 80.0,
    unit_of_measure: 'bag',
    uom_count: 1
  },
  {
    service_header_id: 48,
    service_name: 'Machinery - 1 Tonne Bags',
    service_type: 'Packaging',
    plough_season: '2022/23',
    price: 80.0,
    unit_of_measure: 'bag',
    uom_count: 1
  },
  {
    service_header_id: 49,
    service_name: 'Labour - 1 Tonne Bags',
    service_type: 'Packaging',
    plough_season: '2022/23',
    price: 80.0,
    unit_of_measure: 'bag',
    uom_count: 1
  },
  {
    service_header_id: 50,
    service_name: 'Labour',
    service_type: 'Shelling & Threshing',
    plough_season: '2022/23',
    price: 300.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 51,
    service_name: 'Machinery',
    service_type: 'Shelling & Threshing',
    plough_season: '2022/23',
    price: 300.0,
    unit_of_measure: 'ha',
    uom_count: 1
  },
  {
    service_header_id: 54,
    service_name: 'Transportation',
    service_type: 'Transportation',
    plough_season: '2022/23',
    price: 90.0,
    unit_of_measure: 'ton',
    uom_count: 1
  }
]
