import { useCroppingPlanSeeding } from '../../../hooks/useCroppingPlanSeeding'
import type React from 'react'
import { useEffect } from 'react'
import { useStepper } from 'components/ui/stepper'
import z, { coerce, object } from 'zod'
import { useGetCroppingPlanData } from '../../../../../../api/getStaticData'
import { useStaticDataStore } from '../../../../../../stores/useStaticDataStore'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Loader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { useGetSeeding } from '../../../api/seeding/getSeeding'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CroppingPlanSeedsTable } from './CroppingPlanSeedsTable'
import { CroppingPlanSeedsColumns } from './columns'

export const seedingSchema = object({
  seedType: z.enum(['OPV', 'HYBRID'], {
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  targetYield: coerce
    .number({
      required_error: 'target yield is required',
      invalid_type_error: 'target yield should be a number'
    })
    .min(1, 'target yield should be greater than 1')
    .nonnegative('target yield cannot be smaller than 1')
})
export const SeedSelection: React.FC = () => {
  const { nextStep } = useStepper()
  const { seeds, setSeeds } = useStaticDataStore()

  const { header, setSeeding } = useCroppingPlanSeeding(nextStep)

  const {
    data: staticData,
    isInitialLoading: loadingStaticData,
    error: staticDataError
  } = useGetCroppingPlanData(seeds === null)

  const {
    data: apiSeeding,
    isInitialLoading: apiIsLoading,
    error: seedingError
  } = useGetSeeding(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof seedingSchema>>({
    resolver: zodResolver(seedingSchema),
    defaultValues: {
      seedType: apiSeeding != null ? apiSeeding?.data.seed_type : 'HYBRID',
      targetYield: apiSeeding?.data != null ? parseInt(apiSeeding?.data?.yield) : 1
    }
  })

  useEffect(() => {
    if (staticData != null) {
      setSeeds(staticData.data.seeds)
    }

    if (apiSeeding != null) {
      setSeeding(apiSeeding.data)
      form.reset()
    }
  }, [staticData, apiIsLoading])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading || loadingStaticData) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (seedingError != null) {
    if (seedingError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (staticDataError != null) {
    if (staticDataError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (staticData?.data?.seeds == null) {
    return <Error />
  }

  const cropSeeds =
    seeds?.filter((seed) => seed.crop_id === header?.crop_id) ??
    staticData.data.seeds.filter((seed) => seed.crop_id === header?.crop_id)

  const isNewSeeding = (): boolean => {
    return apiSeeding?.data == null
  }

  const filteredSeeds =
    form.watch('seedType') === 'HYBRID'
      ? cropSeeds.filter((seed) => seed.seed_type === 'HYBRID')
      : cropSeeds.filter((seed) => seed.seed_type === 'OPV')

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full p-2 sm:p-4 gap-4'} id={'seed-selection'}>
        <FormField
          control={form.control}
          name='seedType'
          render={({ field }) => (
            <FormItem>
              <FormLabel className={'font-redHat'}>Seed Type</FormLabel>
              <Select
                onValueChange={(val) => {
                  field.onChange(val)
                }}
                defaultValue={apiSeeding != null ? apiSeeding?.data.seed_type : 'HYBRID'}
              >
                <FormControl>
                  <SelectTrigger className='w-[200px]'>
                    <SelectValue placeholder='Seed Type' />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value={'HYBRID'}>HYBRID</SelectItem>
                  <SelectItem value={'OPV'}>OPV</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={'targetYield'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel className={'font-redHat'}>Target Yield</FormLabel>
              <FormControl>
                <Input
                  classLabel={'bg-background-dark sm:bg-card'}
                  className={'w-[200px]'}
                  placeholder={'Target Yield'}
                  type={'text'}
                  inputMode={'numeric'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
      <div className={'flex flex-col p-2 sm:p-4 gap-2'}>
        <CroppingPlanSeedsTable
          isNewSeeding={isNewSeeding()}
          seeding={apiSeeding?.data}
          columns={CroppingPlanSeedsColumns}
          data={filteredSeeds}
          form={form}
        />
      </div>
    </Form>
  )
}
