import { type FC } from 'react'
import { type Farm } from 'types'
import { FarmInformation } from '../farm-details/FarmInformation'
import { Ownership } from '../farm-details/Ownership'

interface InformationProps {
  farm: Farm
}

export const Information: FC<InformationProps> = ({ farm }) => {
  return (
    <>
      <FarmInformation farm={farm} />
      <Ownership />
    </>
  )
}
