/* eslint-disable prettier/prettier */
interface ContainerProps {
  children: React.ReactNode
}
export const RowContainer: React.FC<ContainerProps> = ({ children }) => {
  return <div className='flex flex-wrap items-center gap-4 mt-2 mb-4'>{children}</div>
}

export const MainContainer: React.FC<ContainerProps> = ({ children }) => {
  return <div className='w-full flex flex-col gap-4 p-2'>{children}</div>
}
