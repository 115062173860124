import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateLandPreparationDTO {
  cp_id: number | null | undefined
  tillage_method: string | null | undefined
  is_harrowing: boolean
  user_id: number | null | undefined
  farmer_id: number | null | undefined
}

export const updateLandPreparation = async (
  params: UpdateLandPreparationDTO
): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/landpreparation`, params)
}
