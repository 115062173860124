import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'

export interface EditFarmDTO {
  FARMER_ID: number | null | undefined
  FARM_NAME: string
  LAST_UPDATED_BY: number | null | undefined
  PLOT_NO: string
  DISTRICT_ID: number
  SUB_DISTRICT_ID: number
  EXT_AREA_ID: number
  VILLAGE_ID: number
  SIZE: number
  NICKNAME: string
  UNIT_OF_MEASURE: number // 1 is the default
}

export const editFarm = async (
  farmId: number | null | undefined,
  params: EditFarmDTO
): Promise<ApiResponse<[]>> => {
  return axios.put(`/farm/${farmId ?? ''}`, params)
}
