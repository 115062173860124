import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type GetInventoryRes } from '../../../farmer/inventory/types'

export const getLabInventoryItem = async (
  idType: string,
  idInput: string
): Promise<ApiResponse<GetInventoryRes>> => {
  const queryParams = idType === 'merchantId' ? `merchant_id=${idInput}` : `farmer_id=${idInput}`

  return axios.get(`inventory?${queryParams}`)
}

export const useGetSoilSampleRequest = (
  idType: string,
  idInput: string
): UseQueryResult<ApiResponse<GetInventoryRes>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['inventory', idInput],
    queryFn: async () => getLabInventoryItem(idType, idInput),
    enabled: idInput != null && idType != null
  })
}
