import type React from 'react'
import { useEffect } from 'react'
import { useOtpStore } from '../../stores/useOtpStore'
import { useTimer } from 'react-timer-hook'
import { getTimerTime } from '../../features/authentication/utils/otp'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from 'components/ui'
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp'
import { ResendOtp } from '../Generic/ResendOtp'
import { useOTP } from '../../hooks/useOTP'

interface InputOTPProps {
  number: string
  // after successful otp verification
  next: () => void
  // any additional loading after successful otp verification
  isLoading?: boolean
}

const FormSchema = z.object({
  otp: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.'
  })
})

export const OTPInput: React.FC<InputOTPProps> = ({ number, next, isLoading = false }) => {
  const { resendOtpMessage, verifyOtpMessage, loading, status } = useOTP()
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      otp: ''
    }
  })

  const {
    setExpiryTimeOfResend,
    resendCounter,
    expiryTimeOfResend,
    setTimeOfFirstResend,
    setResendCounter
  } = useOtpStore()
  const { hours, minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp:
      expiryTimeOfResend !== null ? new Date(expiryTimeOfResend) : new Date(Date.now())
  })

  const hiddenNumber = String(number).slice(-3)

  useEffect(() => {
    if (
      resendCounter > 1 &&
      expiryTimeOfResend !== null &&
      new Date(Date.now()) < expiryTimeOfResend
    ) {
      restart(expiryTimeOfResend)
    }

    // only called when otp successfully verified
    if (status === 'success') {
      next()
    }
  }, [resendCounter, expiryTimeOfResend, status])

  const onSubmit = (data: z.infer<typeof FormSchema>): void => {
    verifyOtpMessage(data.otp, number)
  }

  const handleResendOtp = (): void => {
    if (resendCounter === 0) {
      setTimeOfFirstResend(new Date(Date.now()))
    }
    const counter = resendCounter + 1
    setResendCounter(resendCounter + 1)
    resendOtpMessage(number)
    setExpiryTimeOfResend(getTimerTime(new Date(Date.now()), counter))
    restart(getTimerTime(new Date(Date.now()), counter))
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={'flex flex-col gap-4 md:gap-6 lg:gap-8 items-center'}
      >
        <FormField
          control={form.control}
          name='otp'
          render={({ field }) => (
            <FormItem className='flex flex-col gap-2 items-center'>
              <FormLabel className={'text-center text-lg font-bold'}>One-Time Password</FormLabel>
              <FormDescription className={'text-center'}>
                Enter OTP sent to a phone number that ends with{' '}
                <span className={'text-primary'}>*****{hiddenNumber}</span>
              </FormDescription>
              <FormControl>
                <InputOTP maxLength={6} {...field}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <p className={'text-center'}>Didn't Get Any Code? Request A New Code.</p>
        <ResendOtp
          seconds={seconds}
          minutes={minutes}
          hours={hours}
          isDisabled={(isRunning && seconds + minutes + hours > 0) || loading || isLoading}
          onResend={handleResendOtp}
        />
        <div className='w-full flex justify-center'>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'lg'}
            disabled={loading || isLoading}
            loading={loading || isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
    </Form>
  )
}
