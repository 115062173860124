import type React from 'react'
import { EmailLoginForm } from './EmailLoginForm'
import { Text } from 'components/ui'

interface EmailLoginProps {
  className?: string
  onRegister: () => void
  onPhone: () => void
  onSuccess: (role: number) => void
}

export const EmailLogin: React.FC<EmailLoginProps> = ({
  className,
  onPhone,
  onRegister,
  onSuccess
}) => {
  return (
    <div className={'w-full max-w-[450px] flex flex-col gap-8 md:gap-8 xl:gap-12'}>
      <div className={'w-full flex flex-col sm:text-start ml-2 sm:ml-0'}>
        <Text size={'large'}>Welcome Back</Text>
        <Text>
          Sign in to continue with{' '}
          <span className={'text-primary hidden sm:inline-block'}>Temo Crop Management System</span>
          <span className={'text-primary inline-block sm:hidden'}>Temo CMS</span>
        </Text>
      </div>
      <EmailLoginForm
        className={'w-full flex flex-col gap-4 sm:gap-8'}
        onPhone={onPhone}
        onRegister={onRegister}
        onSuccess={onSuccess}
      />
    </div>
  )
}
