import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { Spinner } from 'components/Elements/Spinner'
import { ErrorPage } from 'components/Errors/ErrorPage'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { routes } from 'routes'
import { useOtpStore } from 'stores/useOtpStore'
import { theme } from 'styles/Theme'
import { AuthProvider } from './auth.context'
import { ThemeModeProvider } from 'components/ui/themeProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (isAxiosError(error) && failureCount <= 1) {
          if (error?.response?.status === 404) {
            return false
          } else if (error?.response?.status === 500) {
            return false
          } else if (error?.response?.status === 503) {
            return false
          } else if (error?.response?.status === 501) {
            return false
          } else if (error?.response?.status === 504) {
            return false
          } else if (error?.response?.status === 401) {
            return true
          } else if (error?.response?.status === 403) {
            return true
          }
          return false
        } else if (failureCount > 1) {
          return true
        }
        return true
      }
    },
    mutations: {
      retry: (failureCount, error) => {
        if (isAxiosError(error) && failureCount <= 1) {
          if (error?.response?.status === 404) {
            return false
          } else if (error?.response?.status === 500) {
            return false
          } else if (error?.response?.status === 503) {
            return false
          } else if (error?.response?.status === 501) {
            return false
          }
          return true
        } else if (failureCount > 1) {
          return false
        }
        return true
      }
    }
  }
})

export const AppProvider: React.FC = () => {
  const { timeOfFirstResend, setExpiryTimeOfResend, setTimeOfFirstResend, setResendCounter } =
    useOtpStore()

  // reset the otp timer after day has passed since the first resend
  useEffect(() => {
    const currentTime = Date.now()
    if (timeOfFirstResend === null) {
      return
    }
    const timerDatePlusOneDay = new Date(timeOfFirstResend).getTime() + 24 * 60 * 60 * 1000

    if (currentTime > timerDatePlusOneDay) {
      setTimeOfFirstResend(null)
      setExpiryTimeOfResend(null)
      setResendCounter(0)
    }
  }, [timeOfFirstResend])

  return (
    <React.Suspense
      fallback={
        <div className='flex items-center justify-center w-screen h-screen'>
          <Spinner size={'sm'} />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <ThemeModeProvider
                attribute='class'
                defaultTheme='system'
                enableSystem
                disableTransitionOnChange
              >
                <RouterProvider router={routes} />
                <Toaster
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff'
                    },

                    // Default options for specific types
                    success: {
                      duration: 3000,
                      style: { color: '#fff' }
                    }
                  }}
                />
                <div id='recaptcha-container'></div>
              </ThemeModeProvider>
            </ThemeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  )
}
