import Autoplay from 'embla-carousel-autoplay'
import {
  Layout,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext
} from 'components/ui'
// images
import img1 from 'assets/landing/gallery/IMG_0844.jpg'
import img2 from 'assets/landing/gallery/IMG_0846.jpg'
import img3 from 'assets/landing/gallery/IMG_0847.jpg'
import img4 from 'assets/landing/gallery/IMG_0858.jpg'
import img5 from 'assets/landing/gallery/IMG_0984.jpg'
import img6 from 'assets/landing/gallery/IMG_1040.jpg'
import img7 from 'assets/landing/gallery/IMG_0988.jpg'
import img8 from 'assets/landing/gallery/IMG_1042.jpg'
import img9 from 'assets/landing/gallery/IMG_0994.jpg'
import img10 from 'assets/landing/gallery/IMG_1028.jpg'
import img11 from 'assets/landing/gallery/IMG_1031.jpg'
import img12 from 'assets/landing/gallery/IMG_1139.jpg'
import img13 from 'assets/landing/gallery/IMG_1141.jpg'
import img14 from 'assets/landing/gallery/IMG_1032.jpg'
import img15 from 'assets/landing/gallery/IMG_0848.jpg'
import { Card, CardContent } from 'components/ui/card'

const images = [
  {
    id: 1,
    url: img1
  },
  {
    id: 2,
    url: img2
  },
  {
    id: 3,
    url: img3
  },
  {
    id: 4,
    url: img4
  },
  {
    id: 5,
    url: img5
  },
  {
    id: 6,
    url: img6
  },
  {
    id: 7,
    url: img7
  },
  {
    id: 8,
    url: img8
  },
  {
    id: 9,
    url: img9
  },
  {
    id: 10,
    url: img10
  },
  {
    id: 11,
    url: img11
  },
  {
    id: 12,
    url: img12
  },
  {
    id: 13,
    url: img13
  },
  {
    id: 14,
    url: img14
  },
  {
    id: 15,
    url: img15
  }
]

export const Gallery: React.FC = () => {
  return (
    <Layout className={'min-h-[50vh] py-[15vh] w-full flex items-center justify-center'}>
      <Carousel
        opts={{
          align: 'start',
          loop: true
        }}
        plugins={[
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          Autoplay({
            delay: 4000
          })
        ]}
        className='w-full max-w-[80vw] sm:max-w-[86vw] md:max-w-[90vw]'
      >
        <CarouselContent>
          {images.map((image, index) => (
            <CarouselItem className='sm:basis-1/2 md:basis-1/3 lg:basis-1/5'>
              <div key={index}>
                <Card className={'w-full bg-transparent overflow-hidden'}>
                  <CardContent className='aspect-square'>
                    <img
                      src={image?.url}
                      className='w-full max-h-full object-contain'
                      alt={'images of temo letlotlo journey'}
                    />
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </Layout>
  )
}
