import { useGetFarmer } from 'features/authentication/api/getFarmer'
import { useGetMerchant } from 'features/authentication/api/getMerchant'
import { useGetOfficer } from 'features/authentication/api/getOfficer'
import { useAuth } from 'features/authentication/hooks/useAuth'
import { useEffect, useState } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useMerchantStore } from 'stores/useMerchantStore'
import { useOfficerStore } from 'stores/useOfficerStore'
import { useToast } from '../../../components/ui/use-toast'

interface UseEmailLogin {
  isUser: boolean
  error: string | null
  loading: boolean
}

export const useEmailLogin = (role: number | null, userId: number | null): UseEmailLogin => {
  const { toast } = useToast()
  const { signOutUser } = useAuth()
  const [error, setError] = useState<string | null>(null)
  const [isUser, setIsUser] = useState(false)

  const { setFarmer } = useFarmerStore()
  const { setOfficer } = useOfficerStore()
  const { setMerchant } = useMerchantStore()

  const {
    data: farmer,
    isInitialLoading: loadingFarmer,
    error: farmerError
  } = useGetFarmer(userId, role)

  const {
    data: officer,
    isInitialLoading: loadingOfficer,
    error: officerError
  } = useGetOfficer(userId, role)

  const {
    data: merchant,
    isInitialLoading: loadingMerchant,
    error: merchantError
  } = useGetMerchant(userId, role)

  useEffect(() => {
    if (loadingOfficer || loadingMerchant || loadingFarmer) {
      return
    }

    if (role !== null && role >= 1001 && role <= 1010 && officer?.status === 200) {
      setOfficer(officer?.data[0])
      setIsUser(true)
    } else if (
      (role !== null && role >= 1001 && role <= 1010 && officer?.status !== 200) ||
      officerError !== null
    ) {
      signOutUser()
      setIsUser(false)
      setError('user does not exist.')
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'user does not exist.'
      })
    }

    if (role === 1000 && farmer?.status === 200) {
      setFarmer(farmer?.data[0])
      setIsUser(true)
    } else if ((role === 1000 && farmer?.status !== 200) || farmerError !== null) {
      signOutUser()
      setIsUser(false)
      setError('user does not exist.')
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'user does not exist.'
      })
    }

    if (role !== null && role >= 2000 && role <= 2005 && merchant?.status === 200) {
      setMerchant(merchant?.data[0])
      setIsUser(true)
    } else if (
      (role !== null && role >= 2000 && role <= 2005 && merchant?.status !== 200) ||
      merchantError !== null
    ) {
      signOutUser()
      setIsUser(false)
      setError('user does not exist.')
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'user does not exist.'
      })
    }
  }, [role, userId, officer, merchant, loadingMerchant, loadingOfficer, farmer, loadingFarmer])

  return { error, loading: loadingOfficer || loadingMerchant || loadingFarmer, isUser }
}
