import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createPackaging,
  type CreatePackagingDTO
} from 'features/farmer/cropping-plan/api/packaging/createPackaging'
import {
  updatePackaging,
  type UpdatePackagingDTO
} from 'features/farmer/cropping-plan/api/packaging/updatePackaging'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, type Packaging } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from 'components/ui'
import { type z } from 'zod'
import { type packagingSchema } from '../components/steps/packaging/Packaging'

interface UseCroppingPlanPackaging {
  isLoading: boolean
  createPackagingPlan: (data: z.infer<typeof packagingSchema>) => void
  updatePackagingPlan: (data: z.infer<typeof packagingSchema>) => void
  setPackaging: (packaging: Packaging | null) => void
  header: CroppingPlan | null
}

export const useCroppingPlanPackaging = (next: () => void): UseCroppingPlanPackaging => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { header, setPackaging } = useCroppingPlanStore()

  const createPackagingMutation = useMutation({
    mutationFn: async (cpDto: CreatePackagingDTO) => {
      return createPackaging(cpDto)
    }
  })

  const updatePackagingMutation = useMutation({
    mutationFn: async (cpDto: UpdatePackagingDTO) => {
      return updatePackaging(cpDto)
    }
  })

  const createPackagingPlan = (data: z.infer<typeof packagingSchema>): void => {
    createPackagingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        method: data.method
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['packaging'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updatePackagingPlan = (data: z.infer<typeof packagingSchema>): void => {
    updatePackagingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        method: data.method
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['packaging'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    isLoading: createPackagingMutation.isLoading || updatePackagingMutation.isLoading,
    createPackagingPlan,
    updatePackagingPlan,
    setPackaging,
    header
  }
}
