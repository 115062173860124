import { Text, Input } from 'components/ui'
import { TabView } from 'components/App/Layout'
import type React from 'react'
import { type Lease } from 'types'

interface OwnerInformationProps {
  lease: Lease
}

export const OwnerInformation: React.FC<OwnerInformationProps> = ({ lease }) => {
  return (
    <TabView>
      <Text size={'medium'}>Owner Information</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={'Farmer ID'}
          value={lease.origin_farmer_id ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Name'}
          value={lease.origin_farmer_firstname ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Surname'}
          value={lease.origin_farmer_surname ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Contact'}
          value={lease.origin_farmer_contact ?? ''}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}
