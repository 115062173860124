import type React from 'react'
import { type Control, Controller } from 'react-hook-form'
import { TextField } from '@mui/material'

interface LocationSelectProps {
  disabled?: boolean
  onSelect: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  label: string
  value: unknown
  name: string
  control?: Control<any, any>
  children: React.ReactNode
  error: boolean
  helperText: string
}
export const LocationSelect: React.FC<LocationSelectProps> = ({
  name,
  disabled = false,
  value,
  onSelect,
  label,
  control,
  helperText,
  error,
  children
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field } }) => (
        <TextField
          select
          fullWidth
          size={'small'}
          label={label}
          defaultValue={''}
          disabled={disabled}
          variant={'outlined'}
          sx={{
            '& .MuiInputBase-input': {
              color: 'var(--foreground)',
              fontFamily: 'var(--font-family)'
            },
            '& .MuiInputLabel-root': {
              color: 'var(--foreground)',
              fontFamily: 'var(--font-family)'
            },
            '& .Mui-disabled .MuiInputLabel-root': {
              color: 'var(--secondary-disabled)'
            },
            '& .Mui-error .MuiInputLabel-root': {
              color: 'var(--destructive)'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'var(--input)',
                transition: 'border-color 0.2s'
              },

              '&.Mui-focused fieldset': {
                borderColor: 'var(--primary)'
              },
              '&.Mui-hover fieldset': {
                borderColor: 'var(--primary)'
              },

              '&.Mui-error fieldset': {
                borderColor: 'var(--destructive)'
              },

              '&.Mui-disabled fieldset': {
                borderColor: 'var(--secondary-disabled)',
                hover: 'none'
              },
              '&:hover fieldset': {
                borderColor: 'var(--primary)'
              }
            }
          }}
          {...field}
          onChange={(event) => {
            onSelect(event)
            onChange(event)
          }}
          value={value}
          error={error}
          helperText={helperText}
        >
          {children}
        </TextField>
      )}
    />
  )
}
