import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type ApiResponseWithObject, type Farmer, type Lessee } from 'types'

export const getFarmer = async (
  farmerId: string | number | null | undefined
): Promise<ApiResponseWithObject<Lessee>> => {
  return axios.get(`/farmer?FARMER_ID=${farmerId ?? ''}`)
}

export const useGetFarmer = (
  userId: number
): UseQueryResult<ApiResponseWithObject<Farmer>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['getFarmer', userId],
    queryFn: async () => getFarmer(userId)
  })
}
