/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type React from 'react'

interface HomeButtonProp {
  className?: string
  icon1?: React.ReactNode
  icon2?: React.ReactNode
  title?: string
  hint?: React.ReactNode
  variant?: 'filled' | 'filledSecondary' | 'outlined' | 'outlinedSecondary'
  size?: 'large' | 'small'
  onClick?: () => void
}

export const LandingButton: React.FC<HomeButtonProp> = ({
  title,
  hint,
  variant = 'filled',
  size = 'small',
  className,
  icon2,
  icon1,
  onClick
}) => {
  const classMappings = {
    small:
      'flex relative items-center justify-center  text-[0.8rem] sm:text-[1rem] font-normal  gap-4 rounded px-2 py-[0.3rem]; cursor-pointer',
    large:
      'flex relative items-center justify-center  text-[1.2rem] sm:text-[1.3rem]  font-medium  gap-4 rounded sm:py-2  py-1 cursor-pointer',
    filled:
      'text-white bg-[color:var(--color-primary)] text-[color:var(--color-background)] transition-[0.3s] duration-[ease-in-out] border-[none] hover:bg-[color:var(--color-primary-light)] cursor-pointer',
    filledSecondary: 'bg-[color:var(--color-secondary-light)] cursor-pointer',
    outlined:
      'border border-[color:var(--color-secondary)] bg-transparent text-[color:var(--color-secondary)] transition-[0.5s] duration-[ease-in] border-solid hover:border hover:border-[color:var(--color-secondary-light)] hover:bg-[color:var(--color-secondary-light)] hover:text-[color:var(--color-background)] hover:border-solid',
    outlinedSecondary:
      'border border-[color:var(--color-background)] bg-transparent text-[color:var(--color-background)] transition-[0.5s] duration-[ease-in] border-solid hover:border hover:border-[color:var(--color-secondary-light)] hover:bg-[color:var(--color-secondary-light)] hover:text-[color:var(--color-background)] hover:border-solid'
  }

  // Create an array of class names based on the selected variant and size
  const classNames = [
    classMappings[size],
    classMappings[variant],
    className ?? '' // Include any custom className if provided
  ].join(' ')

  return (
    <button
      className={`text-secondary relative items-center text-center group ${classNames}`}
      onClick={onClick}
    >
      <div className={'relative flex gap-4 items-center capitalize'}>
        {icon1}
        {title}
        {icon2}
      </div>
      <div
        className={
          'w-full items-start h-10 hidden absolute bottom-[-3rem] group-hover:flex transition-flex duration-300'
        }
      >
        <p
          className={
            'w-full text-center text-[var(--color-secondary-light)] text-[0.8rem] text-thin capitalize'
          }
        >
          {hint}
        </p>
      </div>
    </button>
  )
}
