import {
  AttachmentRepo,
  ConfirmDetails
} from 'features/loan-management/components/stepper/individual'
import type React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BasicButton } from '../../../../../components/Elements/Button'
import { Text } from '../../../../../components/Elements/Text/Text'

export const steps = ['1', '2']

export const CompleteApplication: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const handleNextLAF = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const navigate = useNavigate()

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }
  const handleCancelLAF = (): void => {
    navigate('/farmer/loans')
  }
  const saveAndExist = (): void => {
    // save and exit logic
    console.log('Save button clicked!')
  }

  return (
    <div className='w-full flex flex-col justify-center p-0 px-2 md:p-8'>
      <div
        className={
          'w-full max-w-[1500px] flex flex-col mx-auto gap-4 md:gap-8 md:rounded-md lg:rounded-xl p-4 py-6 sm:p-6 md:px-12'
        }
      >
        <div className={'flex justify-between'}>
          <Text size={'xLarge'}>Complete Loan Application</Text>
          <BasicButton
            label={'save'}
            onClick={saveAndExist}
            size={'xs'}
            customSx={{
              padding: '0.3rem, 0.8rem',
              color: 'white',
              backgroundColor: 'var(--color-primary)'
            }}
            textColor={'var(--color-white)'}
          />
        </div>
      </div>
      <div className='w-full max-w-[1500px] flex flex-col mx-auto'>
        <div className={'flex flex-col gap-8'}>
          {activeStep === 0 ? (
            <ConfirmDetails
              activeStep={activeStep}
              steps={steps}
              onBack={handleCancelLAF}
              onNext={handleNextLAF}
            />
          ) : (
            <AttachmentRepo
              activeStep={activeStep}
              steps={steps}
              onBack={handleBack}
              onNext={handleCancelLAF}
            />
          )}
        </div>
      </div>
    </div>
  )
}
