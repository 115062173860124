import { CustomStepper } from 'components/Elements/Stepper'
import type React from 'react'
import { useState } from 'react'
import { AuthLayout } from '../layout/AuthLayout'
import { AddressesForm } from './Addresses'
import { ContactDetailsForm } from './ContactDetailsForm'
import { PersonalDetailsForm } from './PersonalDetailsForm'
import { SuccessfulRegistration } from './SuccessfulRegistration'

const steps = ['Personal details', 'Contact Number', 'Addresses']

interface IndividualRegistrationProps {
  goBack: () => void
}

export const IndividualRegistration: React.FC<IndividualRegistrationProps> = ({
  goBack
}: IndividualRegistrationProps) => {
  const [activeStep, setActiveStep] = useState(0)
  const [successfulRegistration, setSuccessfulRegistration] = useState(false)

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }

  if (successfulRegistration) {
    return <SuccessfulRegistration />
  }

  if (activeStep === 1) {
    return (
      <AuthLayout
        className={'w-full max-w-[430px] flex gap-8 flex-col justify-center items-center p-4 py-8'}
      >
        <CustomStepper steps={steps} activeStep={activeStep} />
        <ContactDetailsForm
          className={'w-full flex flex-col gap-4'}
          onNext={handleNext}
          onBack={handleBack}
        />
      </AuthLayout>
    )
  }

  if (activeStep === steps.length - 1) {
    return (
      <AuthLayout
        className={
          'w-full max-w-[430px] flex gap-8 flex-col justify-center items-center p-4 py-8'
        }
      >
        <CustomStepper steps={steps} activeStep={activeStep} />
        <AddressesForm
          onSuccess={() => {
            setSuccessfulRegistration(!successfulRegistration)
          }}
          onBack={handleBack}
          className={'flex flex-col gap-2 md:gap-4 lg:gap-6 w-full'}
        />
      </AuthLayout>
    )
  }

  return (
    <AuthLayout
      className={
        'w-full max-w-[430px] flex gap-8 flex-col justify-center items-center p-4 py-8'
      }
    >
      <CustomStepper steps={steps} activeStep={activeStep} />
      <PersonalDetailsForm
        onBack={goBack}
        onNext={handleNext}
        className={'w-full flex flex-col gap-4'}
      />
    </AuthLayout>
  )
}
