export function mapAuthErrorToMessage(error: string): string {
  switch (error) {
    case 'auth/wrong-password':
      return 'Incorrect email or password!'
    case 'auth/invalid-email':
      return 'Invalid email address'
    case 'auth/invalid-phone-number':
      return 'Invalid phone number!'
    case 'user does not exist.':
      return 'User does not exist.'
    case 'auth/phone-number-already-exists':
      return 'An account with the number already exists'
    case 'auth/user-not-found':
      return 'User does no exist'
    case 'auth/invalid-verification-code':
      return 'Invalid verification code'
    case 'auth/app-not-authorized':
      return 'not authorized'
    case 'auth/captcha-check-failed':
      return 'recaptcha check failed, please try again'
    case 'auth/weak-password':
      return 'Weak Password'
    case 'auth/network-request-failed':
      return 'Network request failed. Please check your internet connection.'
    case 'auth/email-already-in-use':
      return 'An account with the email already exists.'
    case 'auth/requires-recent-login':
      return 'Please login again.'
    case 'auth/too-many-requests':
      return 'too many request. try again later'
    case 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN':
      return 'Please login again.'
    default:
      return 'An error occurred try again!'
  }
}
