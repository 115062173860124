import type React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RegisterPoweredImplements } from '../components/RegisterPoweredImplements'
import { RegisterNonPoweredImplements } from '../components/RegisterNonPoweredImplements'
import { RegisterTractors } from '../components/RegisterTractors'

export const ServiceProviderInventoryForm: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    if (
      params.inventoryType == null ||
      !['pwrd-implements', 'trd-implements', 'tractor'].includes(params.inventoryType)
    ) {
      navigate('/service-provider/inventory')
    }
  }, [params.inventoryType, navigate])

  if (params.inventoryType === 'pwrd-implements') {
    return <RegisterPoweredImplements isEdit={false} />
  }

  if (params.inventoryType === 'trd-implements') {
    return <RegisterNonPoweredImplements isEdit={false} />
  }

  return <RegisterTractors isEdit={false} />
}
