import { Services } from 'components/Auth/ServicesProviders'
import { ChangeServicePassword } from 'features/vendor/services-providers/profile/change-password/routes/ChangeMerchantPassword'
import { Navigate } from 'react-router-dom'
import { SearchEligibility } from '../features/merchant/components/SearchEligibility'
import { ServiceProviderProfile } from 'features/profile/routes/ServiceProvider'
import { ServiceProviderReports } from 'features/merchant/service-provider/routes/Reports'
import { ServicesVoucherRedemption } from '../features/merchant/service-provider/routes/Redemption'
import { ServiceProviderInventories } from '../features/merchant/service-provider/components/inventory/routes'
import { ServiceProviderInventoryForm } from '../features/merchant/service-provider/components/inventory/routes/ServiceProviderInventoryForm'

export const servicesprovidersRoutes = {
  path: '/service-provider',
  element: <Services />,
  children: [
    { index: true, element: <SearchEligibility route={'/service-provider/redeem'} /> },
    { path: '/service-provider/profile', element: <ServiceProviderProfile /> },
    { path: '/service-provider/change-password', element: <ChangeServicePassword /> },
    { path: '/service-provider/redeem', element: <ServicesVoucherRedemption /> },
    { path: '/service-provider/reports', element: <ServiceProviderReports /> },
    { path: '/service-provider/*', element: <Navigate to={'/service-provider'} /> },
    { path: '/service-provider/inventory', element: <ServiceProviderInventories /> },
    {
      path: '/service-provider/inventory/register-new/:inventoryType',
      element: <ServiceProviderInventoryForm />
    },
    {
      path: '/service-provider/inventory/register-new/*',
      element: <ServiceProviderInventoryForm />
    },
    { path: '*', element: <Navigate to={'.'} /> }
  ]
}
