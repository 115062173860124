import type { ButtonHTMLAttributes, FC, ReactNode, CSSProperties } from 'react'
import { Button } from '@mui/material'
import { Spinner } from '../Spinner'

type Variants = 'primary' | 'secondary'

type SpinnerSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type Type = 'button' | 'submit'

const sizes = {
  xs: {
    x: '1rem',
    y: '0.3rem',
    spinnerSize: 'sm'
  },
  sm: {
    x: '2.5rem',
    y: '0.4rem',
    spinnerSize: 'sm'
  },
  md: {
    x: '3rem',
    y: '0.5rem',
    spinnerSize: 'md'
  },
  lg: {
    x: '3.5rem',
    y: '1rem',
    spinnerSize: 'lg'
  },
  xl: {
    x: '4rem',
    y: '1.2rem',
    spinnerSize: 'xl'
  }
}

function getSpinnerSize(size: string): SpinnerSizes {
  switch (size) {
    case 'xs':
      return 'xs'
    case 'sm':
      return 'sm'
    case 'md':
      return 'md'
    case 'lg':
      return 'lg'
    case 'xl':
      return 'xl'
    default:
      return 'sm'
  }
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variants
  label: string
  isLoading?: boolean
  isDisabled?: boolean
  size?: keyof typeof sizes
  icon?: ReactNode
  startIcon?: ReactNode
  type?: Type
  customSx?: CSSProperties
  textColor?: string
  color?: 'warning' | 'error' | 'success'
}

export const BasicButton: FC<ButtonProps> = ({
  variant = 'primary',
  label,
  isLoading = false,
  isDisabled = false,
  size = 'xs',
  type = 'button',
  color,
  customSx,
  textColor,
  icon,
  startIcon,
  ...props
}: ButtonProps) => {
  return (
    <Button
      variant={variant === 'primary' ? 'contained' : 'outlined'}
      disabled={isDisabled}
      startIcon={startIcon}
      endIcon={icon}
      type={type}
      color={color}
      sx={{
        paddingX: sizes[size].x,
        paddingY: sizes[size].y,
        height: '2.5rem',
        textTransform: 'capitalize',
        color: textColor ?? undefined,
        ...customSx
      }}
      {...props}
    >
      {!isLoading && label}
      {isLoading && <Spinner size={getSpinnerSize(sizes[size].spinnerSize)} color={textColor} />}
    </Button>
  )
}
