interface ExtensionArea {
  district_id: number
  district_name: string
  extension_area_id: number
  extension_area_name: string
  sub_district_id: number
  sub_district_name: string
}

export const ExtensionAreas: ExtensionArea[] = [
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 1,
    extension_area_name: 'ARTESIA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 2,
    extension_area_name: 'BEETSHA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 3,
    extension_area_name: 'BIKWE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 4,
    extension_area_name: 'BOATLANAME'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 5,
    extension_area_name: 'BOBONONG NORTH'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 6,
    extension_area_name: 'BOBONONG SOUTH'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 7,
    extension_area_name: 'BOKAA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 8,
    extension_area_name: 'BOROBADILEPE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 9,
    extension_area_name: 'BOROLONG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 10,
    extension_area_name: 'BOTLHAPATLOU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 11,
    extension_area_name: 'CHADIBE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 12,
    extension_area_name: 'CHANOGA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 13,
    extension_area_name: 'CHARLESHILL'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 14,
    extension_area_name: 'DIABO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 15,
    extension_area_name: 'DIKHUKHUNG'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 16,
    extension_area_name: 'DINOGENG NORTH'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 17,
    extension_area_name: 'DINOGENG SOUTH'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 18,
    extension_area_name: 'DITLHARAPA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 19,
    extension_area_name: 'DITSHEGWANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 20,
    extension_area_name: 'DITSHUKUDU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 21,
    extension_area_name: 'DOVEDALE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 22,
    extension_area_name: 'DUTLWE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 23,
    extension_area_name: 'ETSHA 13'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 25,
    extension_area_name: 'GABANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 26,
    extension_area_name: 'GAKGATLA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 27,
    extension_area_name: 'GAKUTLO'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 28,
    extension_area_name: 'GANTSI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 29,
    extension_area_name: 'GASITA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 30,
    extension_area_name: 'GATHWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 31,
    extension_area_name: 'GOJWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 32,
    extension_area_name: 'GOO TAU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 33,
    extension_area_name: 'GOODHOPE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 34,
    extension_area_name: 'GROOTLAAGTE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 35,
    extension_area_name: 'GUMARE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 36,
    extension_area_name: 'GWETA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 37,
    extension_area_name: 'HANAHAI'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 38,
    extension_area_name: 'HATSALATLADI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 39,
    extension_area_name: 'HEBRON'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 40,
    extension_area_name: 'HUKUNTSI'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 41,
    extension_area_name: 'KACHIKAU'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 42,
    extension_area_name: 'KALAKAMATI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 43,
    extension_area_name: 'KALAMARE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 44,
    extension_area_name: 'KANG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 45,
    extension_area_name: 'KANNGWE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 46,
    extension_area_name: 'KARAKUBIS'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 47,
    extension_area_name: 'KARENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 48,
    extension_area_name: 'KENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 49,
    extension_area_name: 'KGOMOKASITWA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 50,
    extension_area_name: 'KGOPE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 51,
    extension_area_name: 'KHAKHEA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 52,
    extension_area_name: 'KHUDUMELAPYE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 53,
    extension_area_name: 'KHUMAGA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 54,
    extension_area_name: 'GOBOJANGO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 55,
    extension_area_name: 'KODIBELENG'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 56,
    extension_area_name: 'KOMANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 57,
    extension_area_name: 'KOPONG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 58,
    extension_area_name: 'KOTOLANAME'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 59,
    extension_area_name: 'KUBUNG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 60,
    extension_area_name: 'KUDUMATSE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 61,
    extension_area_name: 'KUMAKWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 62,
    extension_area_name: 'LECHENG'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 63,
    extension_area_name: 'LEHUTUTU'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 64,
    extension_area_name: 'LEPHEPHE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 65,
    extension_area_name: 'LERALA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 66,
    extension_area_name: 'LESENEPOLE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 67,
    extension_area_name: 'LESHIBITSE'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 68,
    extension_area_name: 'LESOMA- KAZUNGULA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 69,
    extension_area_name: 'LETLHAKANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 70,
    extension_area_name: 'LETLHAKANE WEST'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 71,
    extension_area_name: 'LETLHAKENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 72,
    extension_area_name: 'LOGAGANE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 73,
    extension_area_name: 'LOKGWABE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 74,
    extension_area_name: 'LOROLWANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 75,
    extension_area_name: 'LOSILAKGOKONG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 76,
    extension_area_name: 'LOTLHAKANE EAST'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 77,
    extension_area_name: 'MABELE - KAVIMBA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 78,
    extension_area_name: 'MABESEKWA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 79,
    extension_area_name: 'MABOANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 80,
    extension_area_name: 'MABULE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 81,
    extension_area_name: 'MABUTSANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 82,
    extension_area_name: 'MACHANENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 83,
    extension_area_name: 'MAGAPATONA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 84,
    extension_area_name: 'MAGORIAPITSE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 85,
    extension_area_name: 'MAHETLWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 86,
    extension_area_name: 'MAITENGWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 87,
    extension_area_name: 'MAKALAMABEDI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 88,
    extension_area_name: 'MAKALAMABEDI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 90,
    extension_area_name: 'MAKOPONG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 91,
    extension_area_name: 'MAKORO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 92,
    extension_area_name: 'MAKWATE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 93,
    extension_area_name: 'MALOLWANE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 94,
    extension_area_name: 'MALOTWANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 95,
    extension_area_name: 'MALWELWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 96,
    extension_area_name: 'MANYANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 97,
    extension_area_name: 'MAOKANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 98,
    extension_area_name: 'MAOKATUMO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 99,
    extension_area_name: 'MAPOKA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 100,
    extension_area_name: 'MARAPONG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 101,
    extension_area_name: 'MASOKE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 102,
    extension_area_name: 'MASUNGA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 103,
    extension_area_name: 'MATAPANA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 104,
    extension_area_name: 'MATHANGWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 105,
    extension_area_name: 'MATHATHANE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 106,
    extension_area_name: 'MATLHAGE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 107,
    extension_area_name: 'MATOBO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 108,
    extension_area_name: 'MATSHELAGABEDI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 109,
    extension_area_name: 'MATSILOJE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 110,
    extension_area_name: 'MATSITAMA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 111,
    extension_area_name: 'MAUNATLALA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 112,
    extension_area_name: 'MEDIE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 113,
    extension_area_name: 'METLOBO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 114,
    extension_area_name: 'METLOJANE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 116,
    extension_area_name: 'MMADIKOLA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 117,
    extension_area_name: 'MMADINARE NORTH'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 118,
    extension_area_name: 'MMADINARE SOUTH'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 119,
    extension_area_name: 'MMALORE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 120,
    extension_area_name: 'MMAMAROBOLWE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 121,
    extension_area_name: 'MMANKGODI'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 122,
    extension_area_name: 'MMANOKO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 123,
    extension_area_name: 'MMAPHASHALALA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 124,
    extension_area_name: 'MMASHORO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 125,
    extension_area_name: 'MMATHETHE NORTH'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 126,
    extension_area_name: 'MMATHETHE SOUTH'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 127,
    extension_area_name: 'MMATHUBUDUKWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 128,
    extension_area_name: 'MMATSHUMU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 129,
    extension_area_name: 'MMUTLANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 130,
    extension_area_name: 'MODIANE NORTH'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 131,
    extension_area_name: 'MODIANE SOUTH'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 132,
    extension_area_name: 'MODIPANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 133,
    extension_area_name: 'MOGAPI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 134,
    extension_area_name: 'MOGAPINYANA (SEGWAGWA)'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 135,
    extension_area_name: 'MOGOBANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 136,
    extension_area_name: 'MOGOJOGOJO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 137,
    extension_area_name: 'MOGONONO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 138,
    extension_area_name: 'MOGOROSI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 139,
    extension_area_name: 'MOHEMBO EAST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 140,
    extension_area_name: 'MOIYABANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 141,
    extension_area_name: 'MOKATAKO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 142,
    extension_area_name: 'MOKGOMANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 143,
    extension_area_name: 'MOKHOMMA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 144,
    extension_area_name: 'MOKOBENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 145,
    extension_area_name: 'MOKUBILO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 146,
    extension_area_name: 'MOLALATAU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 147,
    extension_area_name: 'MOLAPOWABOJANG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 148,
    extension_area_name: 'MOLEPOLOLE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 149,
    extension_area_name: 'MONWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 150,
    extension_area_name: 'MOOKANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 151,
    extension_area_name: 'MOPIPI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 152,
    extension_area_name: 'MOREOMATO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 153,
    extension_area_name: 'MOROKA'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 154,
    extension_area_name: 'MORWA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 155,
    extension_area_name: 'MOSETSE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 156,
    extension_area_name: 'MOSHANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 157,
    extension_area_name: 'MOSHAWENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 158,
    extension_area_name: 'MOSHOPHA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 159,
    extension_area_name: 'MOSHUPA NORTH'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 160,
    extension_area_name: 'MOSHUPA SOUTH'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 161,
    extension_area_name: 'MOSOJANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 162,
    extension_area_name: 'MOSOLOTSHANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 163,
    extension_area_name: 'MOSU'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 164,
    extension_area_name: 'MOTOKWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 165,
    extension_area_name: 'NATA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 166,
    extension_area_name: 'NCOJANE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 167,
    extension_area_name: 'NEW XADE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 168,
    extension_area_name: 'NGARANGE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 169,
    extension_area_name: 'NGWARE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 170,
    extension_area_name: 'NGWATSAO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 171,
    extension_area_name: 'NKANGE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 172,
    extension_area_name: 'NLAPKWANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 173,
    extension_area_name: 'NOKANENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 174,
    extension_area_name: 'NSHAKAZHOGWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 175,
    extension_area_name: 'NSWAZWI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 176,
    extension_area_name: 'NTLHANTLHE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 177,
    extension_area_name: 'NXAMASERE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 178,
    extension_area_name: "OLIPHANT''S DRIFT"
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 179,
    extension_area_name: 'OMAWENENO'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 180,
    extension_area_name: 'OODI'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 181,
    extension_area_name: 'OTSE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 182,
    extension_area_name: 'OTSE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 183,
    extension_area_name: 'PAJE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 184,
    extension_area_name: 'PALAPYE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 185,
    extension_area_name: 'PALLAROAD'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 186,
    extension_area_name: 'PANDAMATENGA'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 187,
    extension_area_name: 'PARAKARUNGU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 188,
    extension_area_name: 'PHITSHANE MOLOPO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 189,
    extension_area_name: 'PILIKWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 190,
    extension_area_name: 'PITSANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 191,
    extension_area_name: 'PITSENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 192,
    extension_area_name: 'POLOKA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 194,
    extension_area_name: 'RADISELE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 195,
    extension_area_name: 'RAKOPS'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 196,
    extension_area_name: 'RALEKGETHO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 197,
    extension_area_name: 'RAMOKGONAMI'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 198,
    extension_area_name: 'RAMOTSWA NORTH'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 199,
    extension_area_name: 'RAMOTSWA SOUTH'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 200,
    extension_area_name: 'RANAKA'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 201,
    extension_area_name: 'RASESA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 202,
    extension_area_name: 'RATHOLO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 203,
    extension_area_name: 'SALAJWE'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 204,
    extension_area_name: 'SATAU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 205,
    extension_area_name: 'SEBINA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 206,
    extension_area_name: 'SEFHARE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 207,
    extension_area_name: 'SEFHOPHE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 208,
    extension_area_name: 'SEGWAGWA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 209,
    extension_area_name: 'SEHITHWA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 210,
    extension_area_name: 'SEKOMA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 211,
    extension_area_name: 'SELOLWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 212,
    extension_area_name: 'SEMOLALE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 213,
    extension_area_name: 'SENYAWE- BUTALE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 214,
    extension_area_name: 'SEPOPA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 215,
    extension_area_name: 'SERONGA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 216,
    extension_area_name: 'SEROWE EAST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 217,
    extension_area_name: 'SEROWE WEST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 218,
    extension_area_name: 'SERULE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 219,
    extension_area_name: 'SESE NORTH -MACHANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 220,
    extension_area_name: 'SESE SOUTH - SESE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 221,
    extension_area_name: 'SESUNG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 222,
    extension_area_name: 'SHADISHADI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 223,
    extension_area_name: 'SHAKAWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 224,
    extension_area_name: 'SHAKWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 225,
    extension_area_name: 'SHASHE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 226,
    extension_area_name: 'SHASHE MAUN'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 227,
    extension_area_name: 'SHASHEMOOKE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 228,
    extension_area_name: 'SHOROBE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 229,
    extension_area_name: 'SHOSHONG EAST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 230,
    extension_area_name: 'SHOSHONG WEST'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 231,
    extension_area_name: 'SIKWANE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 232,
    extension_area_name: 'SIVIYA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 233,
    extension_area_name: 'TAKATOKWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 234,
    extension_area_name: 'TAMASANE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 235,
    extension_area_name: 'TATI SIDING'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 236,
    extension_area_name: 'HUKUNG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 237,
    extension_area_name: 'TAUPYE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 238,
    extension_area_name: 'THABALA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 239,
    extension_area_name: 'THAMAGA EAST'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 240,
    extension_area_name: 'THAMAGA WEST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 241,
    extension_area_name: 'THOKOLO'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 242,
    extension_area_name: 'TLOKWENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 243,
    extension_area_name: 'TOBANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 244,
    extension_area_name: 'TONOTA EAST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 245,
    extension_area_name: 'TONOTA WEST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 246,
    extension_area_name: 'TOPISI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 247,
    extension_area_name: 'TOTENG'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 248,
    extension_area_name: 'TSABONG'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 249,
    extension_area_name: 'TSAMAYA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 250,
    extension_area_name: 'TSAU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 251,
    extension_area_name: 'TSETSEBJWE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 252,
    extension_area_name: 'TSHANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 253,
    extension_area_name: 'TSHETHONG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 254,
    extension_area_name: 'TSHIMOYAPULA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 255,
    extension_area_name: 'TSHWAANE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 256,
    extension_area_name: 'TSOOTSHA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 257,
    extension_area_name: 'TSWIDI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 258,
    extension_area_name: 'TUMASERA-SELEKA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 260,
    extension_area_name: 'WERDA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 261,
    extension_area_name: 'XHUMO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 262,
    extension_area_name: 'ZWENSHAMBE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 263,
    extension_area_name: 'HUNHUKWE'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 267,
    extension_area_name: 'DIKGATLHONG'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 268,
    extension_area_name: 'KOLE'
  }
]
