import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreateLeaseDTO {
  ORIGIN_FARMER_ID: number | null | undefined
  LEASED_AREA: number
  COMMENCEMENT_DATE: string
  TERMINATION_DATE: string
  DEST_FARMER_ID: string | number | null | undefined
  FARM_ID: number | null | undefined
  LAST_UPDATED_BY: number | null | undefined
}

export const createLease = async (params: CreateLeaseDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/lease', params)
}
