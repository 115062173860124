import { BasicButton } from 'components/Elements/Button'
import { PhoneInputField } from 'components/Form'
import { RegistrationOtpForm } from 'features/authentication/components/individual/RegistrationOtpForm'
import { useAuth } from 'features/authentication/hooks/useAuth'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import { PhoneNumberUtil } from 'google-libphonenumber'
import type React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { Phone, Button } from 'components/ui'
interface ContactDetailsFormProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

const phoneUtil = PhoneNumberUtil.getInstance()

const usePhoneValidation = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  className,
  onBack,
  onNext
}: ContactDetailsFormProps) => {
  const { contactDetails } = useIndividualRegistrationStore()
  const [number, setNumber] = useState<string>(contactDetails !== null ? contactDetails.number : '')
  const [showOtpForm, setShowOtpForm] = useState(false)
  const isPhoneValid = usePhoneValidation(number)

  const { phoneSignUp, phoneLoginResponse, isLoading, error } = useAuth()

  const handleShowOtpForm = (): void => {
    setShowOtpForm(!showOtpForm)
  }

  const onRegister = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault()

    if (contactDetails !== null && contactDetails.number === number) {
      onNext()
    } else {
      await phoneSignUp(number)
    }
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }

    if (phoneLoginResponse.status) {
      handleShowOtpForm()
    }
  }, [error, phoneLoginResponse])

  if (showOtpForm) {
    return (
      <RegistrationOtpForm
        className={'flex flex-col gap-4 sm:gap-6'}
        number={number}
        onSuccess={onNext}
        confirm={phoneLoginResponse.confirm}
      />
    )
  }

  return (
    <form className={'w-full flex flex-col gap-8'}>
      <Phone
        onChange={(num) => {
          setNumber(num)
        }}
        value={number}
      />
      {/* <PhoneInputField */}
      {/*   onChange={(num) => { */}
      {/*     setNumber(num) */}
      {/*   }} */}
      {/*   value={number} */}
      {/*   className={'w-full'} */}
      {/* /> */}

      <div className={'flex flex-row justify-between w-full'}>
        <Button
          variant={'ghost'}
          onClick={onBack}
          disabled={isLoading}
          className={'w-[40%]'}
        >
          Back
        </Button>
        <Button
          onClick={onRegister}
          disabled={!isPhoneValid || isLoading}
          loading={isLoading}
          className={'w-[40%]'}
        >
          Next
        </Button>

        {/* <BasicButton */}
        {/*   label={'Next'} */}
        {/*   variant={'primary'} */}
        {/*   isDisabled={!isPhoneValid || isLoading} */}
        {/*   isLoading={isLoading} */}
        {/*   onClick={onRegister} */}
        {/*   type={'button'} */}
        {/*   customSx={{ width: '40%' }} */}
        {/* /> */}
      </div>
    </form>
  )
}
