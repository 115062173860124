import { useLeaseStore } from 'features/farmer/land-management/stores/leaseStore'
import type React from 'react'
import { Button, Text } from 'components/ui'
import { type GetInventoryRes } from '../../types'
import { useTransferInventory } from '../../hooks/useTransferInventory'

interface TransferConfirmationProps {
  inventoryData: GetInventoryRes
  next: () => void
  back: () => void
}

export const TransferConfirmation: React.FC<TransferConfirmationProps> = ({
  inventoryData,
  next,
  back
}) => {
  const { lesseeDetails } = useLeaseStore()
  const { initiateNewTransfer, isLoading } = useTransferInventory()
  const onSubmit = (): void => {
    initiateNewTransfer(
      {
        origin_farmer_id: undefined,
        transaction_status: undefined,
        dest_farmer_id: undefined,
        inventory_id: Number(inventoryData?.inventory_id),
        last_update_by: undefined,
        message: undefined
      },
      next
    )
  }
  return (
    <div className={'w-full flex flex-col gap-4 md:gap-8'}>
      <Text size={'medium'} className={'text-center'} variant={'bodyText'}>
        Is this the person you want to transfer your inventory to?
      </Text>
      <Text size={'large'} className={'text-center'} variant={'primaryLight'}>
        {`${lesseeDetails?.firstname?.trim().toUpperCase() ?? ''} ${
          lesseeDetails?.surname?.trim().toUpperCase() ?? ''
        }`}
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} onClick={back} className={'w-1/2'}>
          Back
        </Button>
        <Button
          type={'button'}
          variant={'primary'}
          onClick={onSubmit}
          className={'w-1/2'}
          loading={isLoading}
          disabled={isLoading}
        >
          Transfer
        </Button>
      </div>
    </div>
  )
}
