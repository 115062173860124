import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Grant } from 'types'

export const getGrants = async (
  extensionAreaId: number | null | undefined
): Promise<ApiResponse<Grant>> => {
  return axios.get(`/grant?extension_area_id=${extensionAreaId ?? ''}`)
}

export const useGetGrant = (
  extensionAreaId: number | null | undefined
): UseQueryResult<ApiResponse<Grant>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['grant-extension-area', extensionAreaId],
    queryFn: async () => getGrants(extensionAreaId),
    enabled: extensionAreaId !== undefined
  })
}
