import type React from 'react'
import { useEffect, useState } from 'react'
import { coerce, object, string, z } from 'zod'
import { useForm } from 'react-hook-form'

import { useGetStaticInventory } from '../api/getInventoryData'
import { type GetInventoryRes, type PoweredImplement } from '../types'
import { zodResolver } from '@hookform/resolvers/zod'
import { uniqBy } from 'lodash'
import { useSubmitInventoryItem } from '../hooks/submitInvetoryItem'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { Box } from 'lucide-react'
import { TabView } from 'components/App/Layout'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from '../../../../../../components/ui'
import { YearPicker } from '../../../../../../components/Form/YearPicker'
import { useMerchantStore } from '../../../../../../stores/useMerchantStore'

export const poweredImpSchema = object({
  inventorystatus: string().min(1, 'Field is required'),
  yom: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  reg_number: string().min(1, 'Field is required'),
  model: z.coerce.number().min(1, 'Field is required'),
  make: z.coerce.number().min(1, 'Field is required'),
  class: string().min(1, 'Field is required').optional(),
  type: string().min(1, 'Field is required').optional(),
  subType: string().min(1, 'Field is required').optional(),
  capacity: string().min(1, 'Field is required').optional(),
  power: string().min(1, 'Field is required').optional()
})
export type InventoryInput = z.infer<typeof poweredImpSchema>

interface RegisterPoweredImpProps {
  isEdit: boolean
  inventoryData?: GetInventoryRes
  inventoryItem?: PoweredImplement
  onClose?: () => void
}

export const RegisterPoweredImplements: React.FC<RegisterPoweredImpProps> = ({
  isEdit,
  inventoryData,
  inventoryItem,
  onClose
}) => {
  const navigate = useNavigate()
  const [selectedMake, setSelectedMake] = useState(isEdit ? inventoryItem?.make_id : '')
  const [selectedModel, setSelectedModel] = useState(isEdit ? inventoryItem?.model_id : '')
  const { merchant } = useMerchantStore()

  const { data: staticInvData } = useGetStaticInventory()
  const form = useForm<InventoryInput>({
    resolver: zodResolver(poweredImpSchema),
    defaultValues:
      isEdit && inventoryItem != null
        ? {
            inventorystatus: inventoryData?.item_status,
            // yom: new Date(inventoryData?.year_of_manufacture as unknown as Date),
            model: inventoryItem.model_id,
            make: inventoryItem.make_id,
            class: inventoryItem.implement_class,
            power: inventoryItem.implement_power_hp,
            subType: inventoryItem.implement_sub_type,
            type: inventoryItem.implement_type,
            capacity: inventoryItem.implement_type,
            reg_number: inventoryData?.reg_number
          }
        : undefined
  })

  useEffect(() => {
    if (isEdit && inventoryItem != null) {
      setSelectedMake(inventoryItem.make_id.toString())
      setSelectedModel(inventoryItem.model_id.toString())
    }
  }, [isEdit, inventoryItem])

  const findSelectedItem = (): PoweredImplement | undefined => {
    return staticInvData?.data?.powered_implements.find(
      (item) =>
        item.make_id.toString() === selectedMake && item.model_id.toString() === selectedModel
    )
  }

  const selectedItem = findSelectedItem()

  const makes = uniqBy(staticInvData?.data.powered_implements, (item) => {
    return item.make
  }).sort((a, b) => a.make.localeCompare(b.make))

  const models = staticInvData?.data?.powered_implements
    .filter((sub) => sub.make_id.toString() === selectedMake?.toString())
    .sort((a, b) => a.model.localeCompare(b.model))
  const { onSubmit, mutation } = useSubmitInventoryItem({
    merchant: { merchant_id: Number(merchant?.merchant_id), user_id: Number(merchant?.user_id) },
    isEdit,
    inventoryData: { inventory_id: inventoryData?.inventory_id ?? 0 },
    schema: poweredImpSchema,
    selectedItem: {
      model_id: Number(selectedItem?.model_id)
    },
    inventoryTypeId: 1001
  })

  return (
    <Box>
      <div className={'flex justify-between items-center'}>
        <Text size={'large'}>{isEdit ? 'Edit Inventory' : 'New Inventory'}</Text>
      </div>

      <TabView>
        <Text size={'medium'}>
          {isEdit
            ? 'Edit Powered Implement Information'
            : 'Register New Powered Implement Information'}
        </Text>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div
              className={
                'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] items-center gap-2 sm:gap-4 list-none mb-4'
              }
            >
              <FormField
                control={form.control}
                name={`reg_number`}
                defaultValue={inventoryData?.reg_number}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Serial Number</FormLabel>
                    <FormControl>
                      <Input type={'text'} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <YearPicker
                error={!(form.formState.errors.yom == null)}
                helperText={form.formState.errors?.yom?.message ?? ''}
                label={'Manufacture Year'}
                name={'yom'}
                control={form.control}
                defaultValue={inventoryData !== null ? dayjs(inventoryData?.yom) : undefined}
              />

              <FormField
                control={form.control}
                name='make'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Make</FormLabel>
                    <Select
                      onValueChange={(val) => {
                        setSelectedMake(val)
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      defaultValue={selectedMake?.toString() ?? ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Make' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {makes?.map((disc) => (
                          <SelectItem key={disc.make_id} value={disc.make_id.toString()}>
                            {disc.make}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='model'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Model</FormLabel>
                    <Select
                      disabled={selectedMake === ''}
                      onValueChange={(val) => {
                        setSelectedModel(val)
                        field.onChange(val)
                      }}
                      defaultValue={selectedModel?.toString() ?? ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Model' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {models?.map((disc) => (
                          <SelectItem key={disc.model_id} value={disc.model_id.toString()}>
                            {disc.model}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`class`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Implement Class</FormLabel>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem != null
                            ? (selectedItem?.implement_class as unknown as string)
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`type`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Implement Type</FormLabel>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem != null
                            ? (selectedItem?.implement_type as unknown as string)
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`subType`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Implement Sub Type</FormLabel>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem != null
                            ? (selectedItem?.implement_sub_type as unknown as string)
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`capacity`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Capacity</FormLabel>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem != null
                            ? (selectedItem?.volume_m3 as unknown as string)
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`power`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel>Power(hp)</FormLabel>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem != null
                            ? (selectedItem?.implement_power_hp as unknown as string)
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='inventorystatus'
                defaultValue={inventoryData?.item_status}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Inventory Status</FormLabel>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value)
                      }}
                      value={field.value}
                    >
                      <SelectTrigger className={'relative'}>
                        <SelectValue placeholder={<Text>Select Inventory Status</Text>} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem className={'w-full'} value={'IN_USE'}>
                          <Text>In Use</Text>
                        </SelectItem>
                        <SelectItem className={'w-full'} value={'BOARDED'}>
                          <Text>Boarded</Text>
                        </SelectItem>
                        <SelectItem className={'w-full'} value={'UNDER_MAINTENANCE'}>
                          <Text>Under Maintenance</Text>
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className={'flex w-full justify-between space-x-4'}>
              <Button
                className={'w-full sm:w-[18rem] '}
                variant={'secondary'}
                onClick={() => {
                  if (isEdit) {
                    onClose?.()
                  } else {
                    navigate(-1)
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={'w-full sm:w-[18rem] '}
                type={'submit'}
                disabled={mutation.isLoading}
                loading={mutation.isLoading}
              >
                {isEdit ? 'Update' : 'Submit'}
              </Button>
            </div>
          </form>
        </Form>
      </TabView>
    </Box>
  )
}
