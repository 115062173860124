import type React from 'react'
import { useEffect } from 'react'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Label,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  toast
} from '../../../../../components/ui'
import type { GetInventoryRes, Tractor } from '../../../../farmer/inventory/types'
import { tractors } from '../../types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type PostTractorChecklist, postTractorChecklist } from '../../api/postTractorChecklist'
import type z from 'zod'
import { array, boolean, coerce, object, string } from 'zod'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { Textarea } from '../../../../../components/ui/textarea'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { useGetTractorChecklist } from '../../api/getTractorChecklist'

interface ChecklistProps {
  onBack: () => void
  className: string
  inventoryData: GetInventoryRes
  inventoryItem: Tractor | undefined
  onClose: () => void
  isEdit?: boolean
}

const tractorItemSchema = object({
  score: coerce.number().min(1, 'Field is required').or(string()).or(boolean())
})

const tractorChecklistSchema = object({
  tractorsState: array(tractorItemSchema),
  comments: string().min(1, 'Field is Required'),
  result: boolean().or(string())
})

export const TractorChecklist: React.FC<ChecklistProps> = ({
  className,
  inventoryData,
  onClose,
  onBack,
  isEdit
}) => {
  const queryClient = useQueryClient()
  const { officer } = useOfficerStore()
  const { data: data1, isLoading } = useGetTractorChecklist(inventoryData.inventory_id)
  const defaultValues = tractors.map((s, i) => {
    return { score: '' }
  })
  const form = useForm<z.infer<typeof tractorChecklistSchema>>({
    resolver: zodResolver(tractorChecklistSchema),
    defaultValues: {
      tractorsState: defaultValues,
      comments: '',
      result: undefined
    }
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: 'tractorsState'
  })

  const resultValue = useWatch({
    control: form.control,
    name: 'result'
  })

  useEffect(() => {
    if (isEdit === true && data1 !== undefined) {
      const latestData = data1.data[data1.data.length - 1]
      // Get the item with the highest index
      form.reset({
        tractorsState: [
          { score: latestData.lights },
          { score: latestData.starting },
          { score: latestData.gauges },
          { score: latestData.cooling_leakages },
          { score: latestData.fuel_leakages },
          { score: latestData.lubrication_leakages },
          { score: latestData.hydraulic_oil_leakages },
          { score: latestData.transmission_leakages },
          { score: latestData.smoke_color },
          { score: latestData.oil_check },
          { score: latestData.greasing },
          { score: latestData.pto },
          { score: latestData.gear_shifting },
          { score: latestData.lifting_and_releasing },
          { score: latestData.hitch_pins },
          { score: latestData.bearings },
          { score: latestData.tyres },
          { score: latestData.mirrors },
          { score: latestData.functional_gps },
          { score: latestData.engine_stalling },
          { score: latestData.positioning_and_draft_control },
          { score: latestData.breaking_system },
          { score: latestData.steering_system },
          { score: latestData.diff_loc }
        ],
        comments: latestData.comments,
        result: latestData.result
      })
    }
  }, [isEdit, data1, form])

  const mutation = useMutation({
    mutationFn: async (params: PostTractorChecklist) => {
      return postTractorChecklist(params)
    }
  })

  const onSubmit = (data: z.infer<typeof tractorChecklistSchema>, status: number): void => {
    const result = data.result === 'true'
    mutation.mutate(
      {
        inventory_id: inventoryData.inventory_id,
        lights: Number(data.tractorsState[0].score),
        starting: Number(data.tractorsState[1].score),
        gauges: Number(data.tractorsState[2].score),
        cooling_leakages: Number(data.tractorsState[3].score),
        fuel_leakages: Number(data.tractorsState[4].score),
        lubrication_leakages: Number(data.tractorsState[5].score),
        hydraulic_oil_leakages: Number(data.tractorsState[6].score),
        transmission_leakages: Number(data.tractorsState[7].score),
        smoke_color: Number(data.tractorsState[8].score),
        oil_check: Number(data.tractorsState[9].score),
        greasing: Number(data.tractorsState[10].score),
        pto: Number(data.tractorsState[11].score),
        gear_shifting: Number(data.tractorsState[12].score),
        lifting_and_releasing: Number(data.tractorsState[13].score),
        hitch_pins: Number(data.tractorsState[14].score),
        bearings: Number(data.tractorsState[15].score),
        tyres: Number(data.tractorsState[16].score),
        mirrors: Number(data.tractorsState[17].score),
        functional_gps: Boolean(data.tractorsState[18].score),
        engine_stalling: Boolean(data.tractorsState[19].score),
        positioning_and_draft_control: Boolean(data.tractorsState[20].score),
        breaking_system: Boolean(data.tractorsState[21].score),
        steering_system: Boolean(data.tractorsState[22].score),
        diff_loc: Boolean(data.tractorsState[23].score),
        last_update_by: Number(officer?.user_id),
        result,
        item_verification_status: status,
        comments: data.comments
      },
      {
        onSuccess: (res) => {
          if (res.status === 201 && res.success) {
            void queryClient.invalidateQueries({ queryKey: [`inventories, inventory`] })
            toast({
              variant: 'success',
              title: `Inventory item processed successfully.`
            })
            onClose()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  if (isLoading) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <Form {...form}>
      <form className={className}>
        <FormLabel>Tractor Checklist </FormLabel>
        <FormField
          control={form.control}
          name={`tractorsState`}
          render={({ field: priceField }) => (
            <FormItem className='flex flex-col gap-4 max-h-[250px] overflow-auto'>
              {fields.map((arr, index) => (
                <div key={index} className={'flex flex-col'}>
                  <FormField
                    key={arr.id}
                    control={form.control}
                    name={`tractorsState.${index}.score`}
                    render={({ field: scoreField }) => (
                      <FormItem className='grid grid-cols-2 gap-8 p-2'>
                        <div className='flex items-center'>
                          <FormLabel>{tractors[index]}</FormLabel>
                        </div>
                        <FormControl>
                          {index >= 18 ? (
                            <RadioGroup
                              className={'flex flex-row items-center gap-4'}
                              onValueChange={scoreField.onChange}
                              value={scoreField.value?.toString() ?? ''}
                            >
                              <div className='flex items-center space-x-2'>
                                <RadioGroupItem value='true' id={`pass-${index}`} />
                                <Label htmlFor={`pass-${index}`}>
                                  <Text size={'medium'}>Pass</Text>
                                </Label>
                              </div>
                              <div className='flex items-center space-x-2'>
                                <RadioGroupItem value='false' id={`fail-${index}`} />
                                <Label htmlFor={`fail-${index}`}>
                                  <Text size={'medium'}>Fail</Text>
                                </Label>
                              </div>
                            </RadioGroup>
                          ) : (
                            <Select
                              onValueChange={scoreField.onChange}
                              value={isEdit === true ? scoreField.value?.toString() : undefined}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder='Select Score' />
                              </SelectTrigger>
                              <SelectContent className={'z-[9999]'}>
                                <SelectItem value='1'>Good</SelectItem>
                                <SelectItem value='2'>Fair</SelectItem>
                                <SelectItem value='3'>Bad</SelectItem>
                                <SelectItem value='4'>N/A</SelectItem>
                              </SelectContent>
                            </Select>
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              ))}
            </FormItem>
          )}
        />

        <div className='grid w-full gap-1.5'>
          <FormField
            control={form.control}
            name={`comments`}
            defaultValue={data1?.data[data1.data.length - 1]?.comments}
            render={({ field: comment }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Comments</FormLabel>
                <FormControl>
                  <Textarea placeholder='Type your comments here.' id='message' {...comment} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`result`}
            render={({ field: result }) => (
              <FormItem className='grid grid-cols-2 gap-10 p-2'>
                <FormLabel>
                  {' '}
                  <Text size={'large'}>Overall Results</Text>
                </FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={result.onChange}
                    className={'flex flex-row items-center gap-4'}
                    defaultValue={
                      result.value?.toString() ??
                      (isEdit === true && data1 != null
                        ? data1.data[data1.data.length - 1]?.result?.toString()
                        : '')
                    }
                  >
                    <div className='flex items-center space-x-2'>
                      <RadioGroupItem value='true' id={`pass`} />
                      <Label htmlFor={`pass`}>
                        <Text size={'medium'}>Pass</Text>
                      </Label>
                    </div>
                    <div className='flex items-center space-x-2'>
                      <RadioGroupItem value='false' id={`fail`} />
                      <Label htmlFor={`fail`}>
                        <Text size={'medium'}>Fail</Text>
                      </Label>
                    </div>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className={'flex flex-row justify-between gap-20'}>
            <Button
              variant={'outlined'}
              type={'button'}
              size={'xs'}
              color={'error'}
              onClick={onBack}
            >
              Back
            </Button>

            {resultValue !== undefined && (
              <>
                <Button
                  onClick={form.handleSubmit((data) => {
                    const result =
                      resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                        ? 2
                        : 3
                    onSubmit(data, result)
                  })}
                  variant={
                    resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                      ? 'success'
                      : 'destructive'
                  }
                  size='xs'
                  color={
                    resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                      ? 'success'
                      : 'error'
                  }
                  type='submit'
                  loading={mutation.isLoading}
                  disabled={mutation.isLoading || (isEdit === true && !form.formState.isDirty)}
                >
                  {resultValue === 'true' || (isEdit === true && data1?.data[0].result === true)
                    ? 'Accept'
                    : 'Reject'}
                </Button>

                {resultValue === 'false' && (
                  <Button
                    onClick={form.handleSubmit((data) => {
                      onSubmit(data, 4)
                    })}
                    variant='destructive'
                    type='button'
                    size='xs'
                    color='error'
                    loading={mutation.isLoading}
                    disabled={mutation.isLoading || (isEdit === true && !form.formState.isDirty)}
                  >
                    Re-Assessment
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </Form>
  )
}
