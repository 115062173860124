/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type React from 'react'

interface TextProp {
  className?: string
  variant?:
    | 'primaryLight'
    | 'primary'
    | 'primaryDark'
    | 'secondary'
    | 'bodyText'
    | 'bodyTextlight'
    | 'secondaryLight'
    | 'colorText'
  children?: React.ReactNode
  size?: 'large' | 'small' | 'body' | 'medium' | 'xLarge'
}

export const Text: React.FC<TextProp> = ({
  children,
  size = 'body',
  className,
  variant = 'bodyText'
}) => {
  const classMappings = {
    small: 'text-[0.7rem] sm:text-[0.8rem] font-normal',
    body: 'text-[0.875rem] sm:text-[0.9rem] font-normal',
    medium: 'text-[1.1rem] sm:text-[1.2rem]  font-medium ',
    large: 'text-[clamp(1.3rem,5vw,1.5rem)] leading-[120%] font-semibold capitalize',
    xLarge: 'text-[clamp(1.7rem,5vw,2.8rem)] leading-[120%] font-semibold capitalize'
  }
  const colorClass = {
    primaryLight: 'text-[color:var(--color-primary-light)]',
    primary: 'text-[color:var(--color-primary)]',
    primaryDark: 'text-[color:var(--color-primary-dark)]',
    secondary: '',
    secondaryLight: 'text-[#afafb0]',
    bodyText: 'text-[#1d1d1f]',
    bodyTextlight: 'text-[#ffffff]',
    colorText: 'text-[color:var(--color-text)]'
  }
  // Create an array of class names based on the selected variant and size
  const classNames = [
    classMappings[size],
    colorClass[variant],
    className ?? '' // Include any custom className if provided
  ].join(' ')

  return <div className={classNames}>{children}</div>
}