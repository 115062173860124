import type React from 'react'
import { type Farm } from 'types'
import { Button, Text } from 'components/ui'
import { useNewFarm } from '../../hooks/useNewFarm'

interface DeleteFarmProps {
  next: () => void
  farm: Farm
}

export const DeleteFarm: React.FC<DeleteFarmProps> = ({ farm, next }) => {
  const { loading, deleteNewFarm } = useNewFarm(farm)

  const onDelete = (): void => {
    deleteNewFarm(next)
  }

  return (
    <div className={'w-full flex flex-col gap-4 md:gap-6'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        Delete Farm
      </Text>

      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        Are you sure you want to delete this farm?
      </Text>

      <Text variant={'error'} size={'medium'} className={'text-center'}>
        NOTE: This action is not reversible.
      </Text>

      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button className={'w-1/2'} variant='secondary' onClick={next} disabled={loading}>
          Cancel
        </Button>
        <Button
          className={'w-1/2'}
          variant='destructive'
          onClick={onDelete}
          loading={loading}
          disabled={loading}
        >
          Delete
        </Button>
      </div>
    </div>
  )
}
