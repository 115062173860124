import type React from 'react'
import { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { BasicButton } from '../../../../components/Elements/Button'
import { Button, Text } from '../../../../components/ui'

interface SignaturePadProps {
  setSignature: (signature: string) => void
}

const SigningPad: React.FC<SignaturePadProps> = ({ setSignature }) => {
  const sigPadRef = useRef<SignatureCanvas>(null)

  const clear = (): void => {
    sigPadRef.current?.clear()
  }

  const save = (): void => {
    if (sigPadRef.current != null) {
      setSignature(sigPadRef.current.toDataURL())
    }
  }

  return (
    <div className='flex flex-col align-center w-full gap-4'>
      <Text variant={'colorText'} size={'body'}>
        Sign Below:
      </Text>
      <div className='flex flex-row justify-center w-full h-full border-1 border-black rounded-md'>
        <SignatureCanvas
          penColor='black'
          canvasProps={{
            height: 150,
            className: 'sigCanvas border-2 border-black rounded-md w-[75%] '
          }}
          ref={sigPadRef}
        />
      </div>
      {/* Adjust this div to match the canvas width */}
      <div className='flex flex-row justify-between pb-4 w-full'>
        <Button variant={'destructiveOutlined'} size={'xs'} onClick={clear}>
          Clear
        </Button>
        <Button variant={'success'} size={'xs'} onClick={save}>
          Set Signature
        </Button>
      </div>
    </div>
  )
}

export default SigningPad