import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type Packaging } from 'types'

export const getPackaging = async (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): Promise<ApiResponseWithObject<Packaging>> => {
  return axios.get(`/production/packaging?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}

export const useGetPackaging = (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): UseQueryResult<ApiResponseWithObject<Packaging>, AxiosError> => {
  return useQuery({
    queryKey: ['packaging', farmerId, cp_id],
    queryFn: async () => getPackaging(farmerId, cp_id),
    enabled: cp_id != null && farmerId != null
  })
}
