import { useGetFarm } from 'features/officer/farmers/api/getFarm'
import { useGetFarmer } from 'features/officer/farmers/api/getFarmer'
import { useGetFarms } from 'features/officer/farmers/api/getFarms'
import { type Farm, type Farmer } from 'types'

interface UseFarmers {
  isLoading: boolean
  error: boolean
  farmer: Farmer | undefined
  farms: Farm[] | undefined
  farm: Farm | undefined
}

export const useFarmers = (
  extAreaId: number | null | undefined,
  farmerId: string | number | null | undefined,
  farmId?: string | number | null | undefined
): UseFarmers => {
  const {
    data: farms,
    error: farmsError,
    isInitialLoading: loadingFarms
  } = useGetFarms(farmerId, extAreaId)

  const {
    data: farmer,
    error: farmerError,
    isInitialLoading: loadingFarmer
  } = useGetFarmer(farmerId, extAreaId)

  const {
    data: farm,
    error: farmError,
    isInitialLoading: loadingFarm
  } = useGetFarm(farmId, farmerId, extAreaId)

  return {
    isLoading: loadingFarms || loadingFarmer || loadingFarm,
    farms: farms?.data,
    farmer: farmer?.data,
    error: farmerError !== null || farmsError !== null || farmError !== null,
    farm: farm?.data[0]
  }
}
