import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Grant } from 'types'
import type { AxiosError } from 'axios'

// get farm specific grants
export const getFarmGrant = async (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined
): Promise<ApiResponse<Grant>> => {
  return axios.get(`/grant?farmer_id=${farmerId ?? ''}&farm_id=${farmId ?? ''}`)
}

export const useGetFarmGrant = (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined
): UseQueryResult<ApiResponse<Grant>, AxiosError> => {
  return useQuery({
    queryKey: [`grant-${farmId ?? ''}`, farmerId],
    queryFn: async () => getFarmGrant(farmerId, farmId),
    enabled: farmerId != null && farmId != null
  })
}
