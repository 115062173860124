import { type User } from 'firebase/auth'
import { create } from 'zustand'

interface AuthState {
  user: User | null
  isLoading: boolean
  error: string | null
  role: number | null
  userId: number | null
  isFirstLogin: boolean | null
  setUser: (user: User | null) => void
  setIsLoading: (loading: boolean) => void
  setError: (error: string | null) => void
  setRole: (role: number | null) => void
  setUserID: (userId: number | null) => void
  setIsFirstLogin: (isFirstLogin: boolean | null) => void
}

export const useAuthStore = create<AuthState>()((set) => ({
  isLoading: false,
  error: null,
  user: null,
  isFirstLogin: null,
  role: null,
  userId: null,
  setError: (err: string | null) => {
    set((state) => ({ ...state, error: err }))
  },
  setUser: (usr: User | null) => {
    set((state) => ({ ...state, user: usr }))
  },
  setIsLoading: (bool: boolean) => {
    set((state) => ({ ...state, isLoading: bool }))
  },
  setRole: (role: number | null) => {
    set((state) => ({ ...state, role }))
  },
  setUserID: (userId: number | null) => {
    set((state) => ({ ...state, userId }))
  },
  setIsFirstLogin: (bool: boolean | null) => {
    set((state) => ({ ...state, isFirstLogin: bool }))
  }
}))
