import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import {
  type ApiResponse,
  type ApiResponseWithObject,
  type CroppingPlan,
  type CroppingPlanDetails
} from 'types'
import { type AxiosError } from 'axios'

export const getHeader = async (
  farmerId: number | null | undefined,
  farmId: number | null | undefined
): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(`/production/header?farmer_id=${farmerId ?? ''}&farm_id=${farmId ?? ''}`)
}

export const getIndividualHeader = async (
  cp_id: number | null | undefined,
  farmerId: number | null | undefined
): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(`/production/header?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}
export const getCroppingPlan = async (
  cp_id: number | null | undefined,
  farmerId: number | null | undefined
): Promise<ApiResponseWithObject<CroppingPlanDetails>> => {
  return axios.get(
    `/production/header/details?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}&user_role=1000`
  )
}

export const useGetHeader = (
  farmerId: number | null | undefined,
  farmId: number | null | undefined
): UseQueryResult<ApiResponse<CroppingPlan>, AxiosError> => {
  return useQuery({
    queryKey: ['cropping-plans', farmerId, farmId],
    queryFn: async () => getHeader(farmerId, farmId),
    enabled: farmerId != null || farmId != null
  })
}

export const useGetIndividualHeader = (
  cp_id: number | null | undefined,
  farmerId: number | null | undefined
): UseQueryResult<ApiResponse<CroppingPlan>, AxiosError> => {
  return useQuery({
    queryKey: ['cropping-plan-header', farmerId, cp_id],
    queryFn: async () => getIndividualHeader(cp_id, farmerId),
    enabled: farmerId != null || cp_id != null
  })
}

export const useGetCroppingPlan = (
  cp_id: number | null | undefined,
  farmerId: number | null | undefined
): UseQueryResult<ApiResponseWithObject<CroppingPlanDetails>, AxiosError> => {
  return useQuery({
    queryKey: ['cropping-plan-details', farmerId, cp_id],
    queryFn: async () => getCroppingPlan(cp_id, farmerId),
    enabled: farmerId != null || cp_id != null
  })
}
