import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface PostSeederChecklist {
  inventory_item_type: number
  inventory_id: number
  farrow_opener: number
  seed_metering: number
  tine: number
  covering_wheels: number
  fertilizer_hopers: number
  seed_hopers: number
  fertilizer_tubes: number
  seed_hoses: number
  greasing: number
  hydraulics: number
  gears: number
  pressure_gauges: number
  hitch_pins: number
  bearings: number
  result: boolean
  comments: string
  item_verification_status: number
  last_update_by: number
}

export const postSeederChecklist = async (
  params: PostSeederChecklist
): Promise<ApiResponse<[]>> => {
  return axios.post('/assessment/seeder', params)
}
