// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react'
import { CLIENT_SIDE_ID } from 'config'
import { API_URL } from 'config/firebase'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { App } from 'pages/App'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import './index.css'
import { Toaster } from './components/ui/toaster'

const DSN = import.meta.env.VITE_SENTRY_DSN as string
const ENV = import.meta.env.VITE_SENTRY_ENV as string

Sentry.init({
  dsn: DSN,
  // use for debugging
  // debug: true,
  environment: ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [API_URL],
      networkRequestHeaders: ['X-Custom-Header', 'app-token', 'authToken'],
      networkResponseHeaders: ['X-Custom-Header', 'correlation-id', 'date'],
      mutationBreadcrumbLimit: 1000,
      mutationLimit: 1500
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://temo-frontend-development.azurewebsites.net/',
    'https://temoletlotlo-training.com/',
    'https://temoletlotlo.com/',
    /^https:\/\/yourserver\.io\/api/
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // 1.0 for 100%
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0
})

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const renderApp = async () => {
  const LProvider = await asyncWithLDProvider({
    clientSideID: CLIENT_SIDE_ID,
    context: {
      kind: 'user',
      key: 'moa-frontend',
      name: 'MOA',
      email: 'user@moa.bw'
    },
    flags: {
      'Landing-Guidelines': true,
      'Cropping-Plan': false,
      'Omang-Validation': true,
      'Company-Registration': false,
      'Lease-Termination': false,
      'Merchant-Inventory': false,
      maintenance: false,
      loan: false,
      INVENTORY: false,
      GRANT: false,
      'CROPPING-PLAN-HEADER': false
    }
  })

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <LProvider>
        <App />
        <Toaster />
      </LProvider>
    </React.StrictMode>
  )
}

void renderApp()
