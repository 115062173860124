import { Text, Input } from 'components/ui'
import { TabView } from 'components/App/Layout'
import type React from 'react'
import { type Farm } from 'types'
import { Districts, ExtensionAreas, locations, SubDistricts } from '../../utils'

interface FarmInformationProps {
  farm: Farm
}

export const FarmInformation: React.FC<FarmInformationProps> = ({ farm }) => {
  const district = Districts.filter((disc) => {
    return farm?.district_id === disc.district_id
  })[0]?.district_name

  const subDistrict = SubDistricts.filter((sub) => {
    return farm?.district_id === sub.district_id && farm.sub_district_id === sub.sub_district_id
  })[0]?.sub_district_name

  const extensionArea = ExtensionAreas.filter((ext) => {
    return (
      farm?.district_id === ext.district_id &&
      farm?.sub_district_id === ext.sub_district_id &&
      farm?.ext_area_id === ext.extension_area_id
    )
  })[0]?.extension_area_name

  const village = locations.filter((vil) => {
    return (
      farm?.district_id === vil.district_id &&
      farm?.sub_district_id === vil.sub_district_id &&
      farm?.ext_area_id === vil.extension_area_id &&
      farm?.village_id === vil.village_id
    )
  })[0]?.village_name

  return (
    <TabView>
      <Text size={'medium'}>Farm Information</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={'district'}
          value={district ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Sub District'}
          value={subDistrict ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Extension Area'}
          value={extensionArea ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={'village'} value={village ?? ''} classLabel={'bg-card'} disabled />

        <Input
          withLabel
          label={'Farm ID'}
          value={farm.farm_id ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Farm Size (ha)'}
          value={farm.size}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}
