import { TabView } from 'components/App/Layout'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import type React from 'react'
import { useState } from 'react'
import { Button, Dialog, DialogContent } from 'components/ui'
import { type Farm, type Lease } from 'types'
import { useGrants } from '../hooks/useGrants'
import { Loader } from 'components/ui/loader'
import { Error } from 'components/Errors/Error'
import { GrantsTab } from '../components/tabs/GrantsTab'
import { HistoryTab } from '../components/tabs/HistoryTab'
import { NewGrant } from '../components/new-grant/NewGrant'

interface WithFarm {
  farm: Farm
  lease?: never
}

interface WithLease {
  farm?: never
  lease: Lease
}

type GrantsProps = WithFarm | WithLease

export const Grants: React.FC<GrantsProps> = ({ farm, lease }) => {
  const [open, setOpen] = useState(false)
  const { grants, farmGrants, isLoading, error } = useGrants(
    farm != null ? farm?.farm_id : lease?.farm_id
  )

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  if (isLoading) {
    return (
      <TabView className={'grid place-items-center'}>
        <Loader />
      </TabView>
    )
  }

  // if any error from backend that is not a 404
  // show error
  if (error) {
    return (
      <TabView className={'grid place-items-center'}>
        <Error />
      </TabView>
    )
  }

  return (
    <TabView>
      <Tabs defaultValue='grants' className='flex flex-col gap-4 w-full'>
        <div
          className={'flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'}
        >
          <TabsList className={'grid w-full sm:w-2/3 md:w-1/2 lg:w-2/5 grid-cols-2'}>
            <TabsTrigger value='grants'>Grants</TabsTrigger>
            <TabsTrigger value='history'>Transaction History</TabsTrigger>
          </TabsList>
          <Button
            onClick={openDialog}
            disabled={grants != null && grants.length > 0}
            size={'xs'}
            variant={'outlined'}
          >
            New Grant
          </Button>
        </div>

        <div>
          <TabsContent value='grants'>
            <GrantsTab grants={farmGrants} />
          </TabsContent>
          <TabsContent value='history'>
            <HistoryTab />
          </TabsContent>
        </div>
      </Tabs>

      <Dialog open={open} defaultOpen={false} modal onOpenChange={closeDialog}>
        <DialogContent
          className={
            'max-w-[80%] md:max-w-[60%] lg:max-w-[50%] overflow-scroll max-h-[90svh] md:max-h-[75svh] lg:max-h-[65svh]'
          }
        >
          {lease != null ? (
            <NewGrant lease={lease} cancel={closeDialog} />
          ) : (
            <NewGrant farm={farm} cancel={closeDialog} />
          )}
        </DialogContent>
      </Dialog>
    </TabView>
  )
}
