import { LandingLayout, LandingButton } from 'features/landing/components/index'
import { Button, Text, Layout } from 'components/ui'
import { useState } from 'react'
export const About: React.FC = () => {
  const [readMore, setReadMore] = useState(false)
  const extraContent = (
    <>
      <br />
      <br />
      The Household Food Security component assists micro-scale Farmers with hundred percent subsidy
      and targets the farming population with no wage or whose monthly income threshold is up to
      P2500. The expectation is that Micro-Scale Farmers will be capacitated through input supplies
      to grow enough crops to meet their household food security needs. The National Food Security
      component targets Individual Small-Scale, Medium-Scale and Large-Scale Farmers, Groups and
      Clusters. For the National Food Security component, farmers will be provided with seasonal
      loans at prime rate through the National Development Bank (NDB) to purchase seasonal inputs.
      Farmers acquiring seasonal loans through the Programme will be required to purchase the
      weather-based Agricultural Credit Guarantee Scheme (ACGS) to mitigate the risks associated
      with crop loss. However, farmers with alternative funding, have an option to purchase the
      weather based ACGS. This component will produce grain on a commercial basis marketed through
      the Botswana Agricultural Marketing Board (BAMB) and other various channels to meet national
      food security needs and ultimately export surplus.
      <br />
      <br />
      Temo Letlotlo has identified fifteen priority crops which have been mapped into twenty-five
      product spaces expected to create employment through value addition. The crops are sorghum,
      maize, millet, mung bean, groundnuts, sunflower, safflower, cowpeas, sugar beans, wheat, soya
      bean, fodder (lablab, sweet sorghum, and Moringa) and rice. Incentives under this Programme
      include a fifty percent fencing, tractor and implements subsidy for both components; improved
      extension farmer ratio for provision of technical advice and guidance. The programme is
      automated for effective data collection and management to facilitate data-driven decision
      making. The incentives are intended to encourage farmers to graduate from one category to
      another.
    </>
  )

  const principles = [
    {
      title: 'Product quality',
      description:
        'Introduction and implementation of standards to encourage grading and to generate incentives for improving product quality. '
    },
    {
      title: 'Efficiency',
      description:
        'Improving the efficiency of farm operations through higher levels of technology adoption and training in good agricultural practices.'
    },
    {
      title: 'Vertical contractualization',
      description:
        'Promoting interaction among value chain actors through clustering to help them develop trust and mutually beneficial relationships. '
    },
    {
      title: 'Environment Conservation',
      description:
        'Managing farm operations in a way that protects the environment while maintaining economic viability of the business enterprise.  To maintain biodiversity and reducing farm activities that increases greenhouse gases.'
    },
    {
      title: 'Inclusivity',
      description:
        'Promoting equal access to opportunities and resources for the special population which includes: destitute persons, persons with disabilities, women, and remote area communities. '
    }
  ]

  const linkName = readMore ? '' : '..read more'
  const iconRotate = readMore ? 'expand_less' : 'expand_more'
  return (
    <Layout
      variant={'body'}
      className='mt-4 pt-[clamp(3rem,8vh,12rem)] bg-muted dark:bg-card py-12 md:py-18'
    >
      <div className={'w-full'}>
        <Layout className='flex flex-col gap-4 sm:gap-8 px-4 sm:px-8 lg:px-12'>
          <div className='flex gap-4'>
            <Text size='xLarge' variant='primaryLight'>
              Discover Us
            </Text>
            <Text size='xLarge' variant='primaryLight' className='material-symbols-outlined '>
              explore
            </Text>
          </div>

          <Text className={`max-w-[1000px]`}>
            Temo Letlotlo is a productivity-output reward based agro-ecological Programme whose main
            aim is to improve food security in Botswana. Temo Letlotlo is a transformational
            multi-sectoral Programme comprised of various ministries, state-owned enterprises, and
            the private sector. The role of Government would be limited to planning, regulation,
            promotion, and coordination to create an enabling business environment in the
            agricultural sector. Its implementation and monitoring processes are based on
            multi-sectoral approach according to the mandate of each stakeholder. It is an automated
            arable Programme with two components being Household Food Security and National Food
            Security.
            <span
              className='text-primary cursor-pointer'
              onClick={() => {
                setReadMore(!readMore)
              }}
            >
              {linkName}
            </span>
            {readMore && extraContent}
            <div
              className={`transition-opacity ${
                readMore ? 'opacity-100' : 'opacity-0'
              } duration-500 ease-in-out`}
            ></div>
            <div className='w-full flex justify-end'>
              <Button
                onClick={() => {
                  setReadMore(!readMore)
                }}
              >
                <span className='material-symbols-outlined text-[color:var(--color-background)] transition-transform transform ease-in-out duration-300'>
                  {iconRotate}
                </span>
              </Button>
            </div>
          </Text>
        </Layout>
      </div>
      <Layout className='flex flex-col gap-4 mt-4 sm:gap-8 px-4 sm:px-8 lg:px-12'>
        <Text size='large' variant='primaryLight' className='flex gap-2 items-center'>
          <p className='fa-regular fa-lightbulb' />
          Guiding principles
        </Text>
        <div className='w-full flex flex-wrap gap-4 '>
          {principles.map((principle, index) => (
            <div
              key={index}
              className='flex flex-col gap-4 max-w-[700px] flex-[1_0_300px] box-border border-[color:var(--color-primary-light)] p-4 border-2 border-solid'
            >
              <Text className='font-semibold capitalize'>{principle.title}</Text>
              <Text size='small'>{principle.description}</Text>
            </div>
          ))}
        </div>
      </Layout>
      <Layout className='flex flex-col gap-4 mt-4 px-4 sm:px-8 lg:px-12 py-12 md:py-18'>
        <Text size='large' variant='primaryLight' className='flex gap-2 items-center'>
          <p className='fa-solid fa-bullseye'></p>
          Programme Objectives
        </Text>
        <div className='max-w-[1000px] flex flex-col gap-2'>
          <Text className='flex items-center gap-2'>
            <Text variant='primary' className='text-[var(--color-grey)]'>
              <p className='fa-solid fa-leaf' />
            </Text>
            Promote household food security by ensuring that micro-scale farmers can produce enough
            output to significantly contribute to household food consumption needs. <br />
          </Text>
          <Text className='flex items-center gap-2'>
            <Text variant='primary' className='text-[var(--color-grey)]'>
              <p className='fa-solid fa-leaf' />
            </Text>
            Promote commercial production of grain by improving crop producer’s access to inputs and
            credit.
          </Text>
          <Text className='flex items-center gap-2'>
            <Text variant='primary' className='text-[var(--color-grey)]'>
              <p className='fa-solid fa-leaf' />
            </Text>{' '}
            Promote inclusivity in agricultural production through building rainfed agricultural
            production systems that are youth, disability, and that are gender sensitive and cater
            for societal groups (including youth and disabled) and safe production environment for
            all.{' '}
          </Text>
          <Text className='flex items-center gap-2'>
            <Text variant='primary' className='text-[var(--color-grey)]'>
              <p className='fa-solid fa-leaf' />
            </Text>
            Improve the social capital base by promoting collective bargaining of rainfed producers.
          </Text>
        </div>
      </Layout>
    </Layout>
  )
}
