export const getMerchantType = (type: number): string => {
  switch (type) {
    case 1:
      return 'agro-chemical'
    case 2:
      return 'seed-dealer'
    case 3:
      return 'soil-lab'
    case 4:
      return 'service-provider'
    default:
      return 'service-provider'
  }
}
