import { useAuth } from 'features/authentication/hooks/useAuth'
import { useAuthState } from 'providers/auth.context'
import { useEffect, useState } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useMerchantStore } from 'stores/useMerchantStore'
import { useOfficerStore } from 'stores/useOfficerStore'
import { useGetFarmer } from '../api/getFarmer'
import { useGetMerchant } from '../api/getMerchant'
import { useGetOfficer } from '../api/getOfficer'

interface RequiresAuthState {
  isUser: boolean
  error: string | null
  // loading data from api
  loading: boolean
  // loading auth status
  isLoading: boolean
  role: number | null
  isFirstLogin: boolean | null
}

export const useRequiresAuth = (): RequiresAuthState => {
  const { setFarmer } = useFarmerStore()
  const { setOfficer } = useOfficerStore()
  const { setMerchant } = useMerchantStore()

  const { signOutUser } = useAuth()
  const { userId, role, isLoading, user, isFirstLogin } = useAuthState()
  const [isUser, setIsUser] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  const {
    data: farmer,
    isInitialLoading: loadingFarmer,
    error: farmerError
  } = useGetFarmer(userId, role)

  const {
    data: officer,
    isInitialLoading: loadingOfficer,
    error: officerError
  } = useGetOfficer(userId, role)

  const {
    data: merchant,
    isInitialLoading: loadingMerchant,
    error: merchantError
  } = useGetMerchant(userId, role)

  useEffect(() => {
    if (loadingFarmer || loadingOfficer || loadingMerchant || isLoading) {
      return
    }

    if (user === null || userId === null || role === null) {
      signOutUser()
      setError('user does not exist.')
      setIsUser(false)
      setLoading(false)
    }

    if (role === 1000 && farmer?.status === 200) {
      setFarmer(farmer?.data[0])
      setIsUser(true)
      setLoading(false)
    } else if ((role === 1000 && farmer?.status !== 200) || farmerError !== null) {
      signOutUser()
      setError('user does not exist.')
      setLoading(false)
    }

    if (role !== null && role >= 1001 && role <= 1010 && officer?.status === 200) {
      setOfficer(officer?.data[0])
      setIsUser(true)
      setLoading(false)
    } else if (
      (role !== null && role >= 1001 && role <= 1010 && officer?.status !== 200) ||
      officerError !== null
    ) {
      signOutUser()
      setError('user does not exist.')
      setLoading(false)
    }

    if (role !== null && role >= 2000 && role <= 2005 && merchant?.status === 200) {
      setMerchant(merchant?.data[0])
      setIsUser(true)
      setLoading(false)
    } else if (
      (role !== null && role >= 2000 && role <= 2005 && merchant?.status !== 200) ||
      merchantError !== null
    ) {
      signOutUser()
      setError('user does not exist.')
      setLoading(false)
    }
  }, [role, userId, farmer, officer, merchant, loadingMerchant, loadingOfficer, loadingFarmer])

  return {
    isUser,
    error,
    loading: loading || loadingMerchant || loadingOfficer || loadingFarmer,
    isLoading,
    isFirstLogin,
    role
  }
}
