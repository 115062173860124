import { ArrowForward } from '@mui/icons-material'
import { BasicButton } from 'components/Elements/Button'
import { useAuth } from 'features/authentication/hooks/useAuth'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import type React from 'react'
import { AuthLayout } from '../layout/AuthLayout'

export const SuccessfulRegistration: React.FC = () => {
  const { setContactDetails, setOmangDetails } = useIndividualRegistrationStore()
  const { logOut } = useAuth()
  const handleRegistration = (): void => {
    setContactDetails(null)
    setOmangDetails(null)
    logOut()
  }

  return (
    <AuthLayout
      className={'flex flex-col justify-center items-center gap-12 py-20 px-14 min-w-[20rem]'}
    >
      <div className='flex flex-col'>
        <h1 className='text-center text-lg'>Welcome To</h1>
        <h1 className='text-center text-lg'>Crop Management System</h1>
      </div>

      <BasicButton
        label={'Continue To Login'}
        icon={<ArrowForward sx={{ color: 'white' }} />}
        onClick={handleRegistration}
      />
    </AuthLayout>
  )
}
