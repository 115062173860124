import { atom } from 'jotai'
import { create } from 'zustand'

interface NewFarm {
  farmName: string
  lastUpdateBy: string | number
  plotNo: number
  districtId: string | number
  subDistrictId: string | number
  extensionAreaId: string | number
  villageId: string | number
  size: number
  nickname: string
  unitOfMeasure: number
}

export const newFarmAtom = atom<NewFarm>({
  farmName: '',
  lastUpdateBy: '',
  plotNo: 1,
  districtId: '',
  subDistrictId: '',
  extensionAreaId: '',
  villageId: '',
  size: 1,
  nickname: '',
  unitOfMeasure: 1
})

interface NewFarmDetails {
  size: number
  nickname: string
}

interface NewFarmLocationDetails {
  districtId: number
  subDistrictId: number
  extensionAreaId: number
  villageId: number
}

interface UseNewFarmStore {
  farmDetails: NewFarmDetails | null
  setFarmDetails: (newFarmDetails: NewFarmDetails | null) => void
  locationDetails: NewFarmLocationDetails | null
  setLocationDetails: (locationDetails: NewFarmLocationDetails | null) => void
}

export const useNewFarmStore = create<UseNewFarmStore>()((set) => ({
  farmDetails: null,
  locationDetails: null,
  setFarmDetails: (farmDetails: NewFarmDetails | null) => {
    set((state) => ({ ...state, farmDetails }))
  },
  setLocationDetails: (locationDetails: NewFarmLocationDetails | null) => {
    set((state) => ({ ...state, locationDetails }))
  }
}))
