import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { type Dayjs } from 'dayjs'
import 'dayjs/locale/en-gb'
import type React from 'react'
import { type Control, Controller } from 'react-hook-form'
interface DatePickerProps {
  label: string
  format?: string
  name: string
  disabled?: boolean
  readonly?: boolean
  disableFuture?: boolean
  disablePast?: boolean
  error: boolean
  helperText: string
  control?: Control<any, any>
  minDate?: Dayjs | null | undefined
  defaultValue?: Dayjs | null | undefined
  className?: string
  isRequired?: boolean
}

export const CustomDatePicker: React.FC<DatePickerProps> = ({
  format = 'DD-MM-YYYY',
  name,
  disabled = false,
  readonly = false,
  disableFuture = false,
  disablePast = false,
  error,
  helperText,
  control,
  label,
  defaultValue,
  minDate,
  className
}: DatePickerProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? new AdapterDayjs()}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={className}
            sx={{
              '& .MuiInputLabel-root': {
                fontFamily: 'Red Hat Display',
                color: 'var(--foreground)',
                ...(error && {
                  color: 'var(--destructive)'
                })
              },
              '& .MuiInputBase-input': {
                color: 'var(--foreground)',
                fontFamily: 'Red Hat Display',
                ...(error && {
                  color: 'var(--destructive)'
                })
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: error ? 'var(--destructive)' : 'var(--input)',
                  ...(disabled && {
                    opacity: '0.5'
                  })
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary)',
                  ...(error && {
                    borderColor: 'var(--destructive)'
                  })
                }
              }
            }}
            label={label}
            format={format}
            disabled={disabled}
            readOnly={readonly}
            disableFuture={disableFuture}
            disablePast={disablePast}
            minDate={minDate}
            slotProps={{
              textField: { size: 'small', error, helperText }
            }}
            {...field}
          />
        </LocalizationProvider>
      )}
    />
  )
}
