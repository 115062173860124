import { type Village } from 'types'

export const locations: Village[] = [
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 1,
    extension_area_name: 'ARTESIA',
    village_id: 1,
    village_name: 'ARTESIA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 2,
    extension_area_name: 'BEETSHA',
    village_id: 2,
    village_name: 'BEETSHA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 37,
    extension_area_name: 'HANAHAI',
    village_id: 3,
    village_name: 'BERE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 220,
    extension_area_name: 'SESE SOUTH - SESE',
    village_id: 4,
    village_name: 'BETESANKWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 190,
    extension_area_name: 'PITSANE',
    village_id: 5,
    village_name: 'BETHEL'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 4,
    extension_area_name: 'BOATLANAME',
    village_id: 6,
    village_name: 'BOATLANAME'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 5,
    extension_area_name: 'BOBONONG NORTH',
    village_id: 7,
    village_name: 'BOBONONG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 6,
    extension_area_name: 'BOBONONG SOUTH',
    village_id: 8,
    village_name: 'BOBONONG'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 209,
    extension_area_name: 'SEHITHWA',
    village_id: 9,
    village_name: 'BODIBENG'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 10,
    village_name: 'BOGOGOBO'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 7,
    extension_area_name: 'BOKAA',
    village_id: 11,
    village_name: 'BOKAA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 12,
    village_name: 'BOKSPITS'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 129,
    extension_area_name: 'MMUTLANE',
    village_id: 13,
    village_name: 'BONWAPITSE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 103,
    extension_area_name: 'MATAPANA',
    village_id: 14,
    village_name: 'BORO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 8,
    extension_area_name: 'BOROBADILEPE',
    village_id: 15,
    village_name: 'BOROBADILEPE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 9,
    extension_area_name: 'BOROLONG',
    village_id: 16,
    village_name: 'BOROLONG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 11,
    extension_area_name: 'CHADIBE',
    village_id: 17,
    village_name: 'BOROTSI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 18,
    village_name: 'BOTALAOTE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 209,
    extension_area_name: 'SEHITHWA',
    village_id: 19,
    village_name: 'BOTHATHOGO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 10,
    extension_area_name: 'BOTLHAPATLOU',
    village_id: 20,
    village_name: 'BOTLHAPATLOU'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 260,
    extension_area_name: 'WERDA',
    village_id: 21,
    village_name: 'BRAY'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 213,
    extension_area_name: 'SENYAWE- BUTALE',
    village_id: 22,
    village_name: 'BUTALE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 9,
    extension_area_name: 'BOROLONG',
    village_id: 23,
    village_name: 'CHADIBE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 11,
    extension_area_name: 'CHADIBE',
    village_id: 24,
    village_name: 'CHADIBE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 171,
    extension_area_name: 'NKANGE',
    village_id: 25,
    village_name: 'CHANGATE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 12,
    extension_area_name: 'CHANOGA',
    village_id: 26,
    village_name: 'CHANOGA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 13,
    extension_area_name: 'CHARLESHILL',
    village_id: 27,
    village_name: 'CHARLESHILL'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 28,
    extension_area_name: 'GANTSI',
    village_id: 28,
    village_name: 'CHOBOKWANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 214,
    extension_area_name: 'SEPOPA',
    village_id: 29,
    village_name: 'CHUKUMUCHU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 114,
    extension_area_name: 'METLOJANE',
    village_id: 30,
    village_name: 'CWAANYANENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 72,
    extension_area_name: 'LOGAGANE',
    village_id: 31,
    village_name: 'CWAGARE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 86,
    extension_area_name: 'MAITENGWE',
    village_id: 32,
    village_name: 'DAGWI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 118,
    extension_area_name: 'MMADINARE SOUTH',
    village_id: 33,
    village_name: 'DAMOCHUJENAA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 173,
    extension_area_name: 'NOKANENG',
    village_id: 34,
    village_name: 'DANEGA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 14,
    extension_area_name: 'DIABO',
    village_id: 35,
    village_name: 'DIABO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 150,
    extension_area_name: 'MOOKANE',
    village_id: 36,
    village_name: 'DIBETE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 136,
    extension_area_name: 'MOGOJOGOJO',
    village_id: 37,
    village_name: 'DIGAWANA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 184,
    extension_area_name: 'PALAPYE',
    village_id: 38,
    village_name: 'DIKABEYA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 50,
    extension_area_name: 'KGOPE',
    village_id: 39,
    village_name: 'DIKGATLHONG'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 201,
    extension_area_name: 'RASESA',
    village_id: 40,
    village_name: 'DIKGONNYE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 15,
    extension_area_name: 'DIKHUKHUNG',
    village_id: 41,
    village_name: 'DIKHUKHUNG'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 132,
    extension_area_name: 'MODIPANE',
    village_id: 42,
    village_name: 'DIKWIDIDI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 98,
    extension_area_name: 'MAOKATUMO',
    village_id: 43,
    village_name: 'DILORO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 124,
    extension_area_name: 'MMASHORO',
    village_id: 44,
    village_name: 'DIMAJWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 190,
    extension_area_name: 'PITSANE',
    village_id: 45,
    village_name: 'DINATSHANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 221,
    extension_area_name: 'SESUNG',
    village_id: 46,
    village_name: 'DINTJANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 169,
    extension_area_name: 'NGWARE',
    village_id: 47,
    village_name: 'DIPHUDUHUDU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 14,
    extension_area_name: 'DIABO',
    village_id: 48,
    village_name: 'DIPOTSANA'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 235,
    extension_area_name: 'TATI SIDING',
    village_id: 49,
    village_name: 'DITLADI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 18,
    extension_area_name: 'DITLHARAPA',
    village_id: 50,
    village_name: 'DITLHARAPA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 19,
    extension_area_name: 'DITSHEGWANE',
    village_id: 51,
    village_name: 'DITSHEGWANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 228,
    extension_area_name: 'SHOROBE',
    village_id: 52,
    village_name: 'DITSHIPING'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 20,
    extension_area_name: 'DITSHUKUDU',
    village_id: 53,
    village_name: 'DITSHUKUDU'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 28,
    extension_area_name: 'GANTSI',
    village_id: 54,
    village_name: "D'KAR"
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 21,
    extension_area_name: 'DOVEDALE',
    village_id: 55,
    village_name: 'DOVEDALE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 90,
    extension_area_name: 'MAKOPONG',
    village_id: 56,
    village_name: 'DRAAIHOEK'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 155,
    extension_area_name: 'MOSETSE',
    village_id: 57,
    village_name: 'DUKWI'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 22,
    extension_area_name: 'DUTLWE',
    village_id: 58,
    village_name: 'DUTLWE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 37,
    extension_area_name: 'HANAHAI',
    village_id: 59,
    village_name: 'EAST HANAHAI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 2,
    extension_area_name: 'BEETSHA',
    village_id: 60,
    village_name: 'ERETSHA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 61,
    village_name: 'ETSHA 1'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 23,
    extension_area_name: 'ETSHA 13',
    village_id: 63,
    village_name: 'ETSHA 10'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 23,
    extension_area_name: 'ETSHA 13',
    village_id: 64,
    village_name: 'ETSHA 11'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 23,
    extension_area_name: 'ETSHA 13',
    village_id: 65,
    village_name: 'ETSHA 12'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 23,
    extension_area_name: 'ETSHA 13',
    village_id: 66,
    village_name: 'ETSHA 13'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 67,
    village_name: 'ETSHA 2'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 68,
    village_name: 'ETSHA 3'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 69,
    village_name: 'ETSHA 4'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 70,
    village_name: 'ETSHA 5'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 71,
    village_name: 'ETSHA 6'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 72,
    village_name: 'ETSHA 7'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 73,
    village_name: 'ETSHA 8'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 74,
    village_name: 'ETSHA 9'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 244,
    extension_area_name: 'TONOTA EAST',
    village_id: 75,
    village_name: 'FOLE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 25,
    extension_area_name: 'GABANE',
    village_id: 76,
    village_name: 'GABANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 26,
    extension_area_name: 'GAKGATLA',
    village_id: 77,
    village_name: 'GAKGATLA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 78,
    village_name: 'GAKHIBANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 27,
    extension_area_name: 'GAKUTLO',
    village_id: 79,
    village_name: 'GAKUTLO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 262,
    extension_area_name: 'ZWENSHAMBE',
    village_id: 80,
    village_name: 'GAMBULE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 122,
    extension_area_name: 'MMANOKO',
    village_id: 81,
    village_name: 'GAMODUBU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 33,
    extension_area_name: 'GOODHOPE',
    village_id: 82,
    village_name: 'GAMOKOTO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 27,
    extension_area_name: 'GAKUTLO',
    village_id: 83,
    village_name: 'GAMONONYANE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 28,
    extension_area_name: 'GANTSI',
    village_id: 84,
    village_name: 'GANTSI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 29,
    extension_area_name: 'GASITA',
    village_id: 85,
    village_name: 'GASITA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 30,
    extension_area_name: 'GATHWANE',
    village_id: 86,
    village_name: 'GATHWANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 223,
    extension_area_name: 'SHAKAWE',
    village_id: 87,
    village_name: 'GHANI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 31,
    extension_area_name: 'GOJWANE',
    village_id: 88,
    village_name: 'GOJWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 32,
    extension_area_name: 'GOO TAU',
    village_id: 89,
    village_name: 'GOO SEKGWENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 33,
    extension_area_name: 'GOODHOPE',
    village_id: 90,
    village_name: 'GOODHOPE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 32,
    extension_area_name: 'GOO TAU',
    village_id: 91,
    village_name: 'GOO-TAU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 136,
    extension_area_name: 'MOGOJOGOJO',
    village_id: 92,
    village_name: 'GOPONG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 107,
    extension_area_name: 'MATOBO',
    village_id: 93,
    village_name: 'GOSHWE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 34,
    extension_area_name: 'GROOTLAAGTE',
    village_id: 94,
    village_name: 'GROOTLAAGTE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 2,
    extension_area_name: 'BEETSHA',
    village_id: 95,
    village_name: 'GUDIGWA'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 96,
    village_name: 'GULUBANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 35,
    extension_area_name: 'GUMARE',
    village_id: 97,
    village_name: 'GUMARE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 262,
    extension_area_name: 'ZWENSHAMBE',
    village_id: 98,
    village_name: 'GUNGWE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 215,
    extension_area_name: 'SERONGA',
    village_id: 99,
    village_name: 'GUNUTSOGA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 36,
    extension_area_name: 'GWETA',
    village_id: 100,
    village_name: 'GWETA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 173,
    extension_area_name: 'NOKANENG',
    village_id: 101,
    village_name: 'HABU'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 38,
    extension_area_name: 'HATSALATLADI',
    village_id: 102,
    village_name: 'HATSALATLADI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 39,
    extension_area_name: 'HEBRON',
    village_id: 103,
    village_name: 'HEBRON'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 40,
    extension_area_name: 'HUKUNTSI',
    village_id: 104,
    village_name: 'HUKUNTSI'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 263,
    extension_area_name: 'HUNHUKWE',
    village_id: 105,
    village_name: 'HUNHUKWE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 23,
    extension_area_name: 'ETSHA 13',
    village_id: 106,
    village_name: 'IKOGA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 230,
    extension_area_name: 'SHOSHONG WEST',
    village_id: 107,
    village_name: 'IKONGWE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 44,
    extension_area_name: 'KANG',
    village_id: 108,
    village_name: 'INALEGOLO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 51,
    extension_area_name: 'KHAKHEA',
    village_id: 109,
    village_name: 'ITHOLOKE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 153,
    extension_area_name: 'MOROKA',
    village_id: 110,
    village_name: 'JAKALAS 1'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 232,
    extension_area_name: 'SIVIYA',
    village_id: 111,
    village_name: 'JAKALAS 2'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 9,
    extension_area_name: 'BOROLONG',
    village_id: 112,
    village_name: 'JAMATAKA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 23,
    extension_area_name: 'ETSHA 13',
    village_id: 113,
    village_name: 'JAO FLATS'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 41,
    extension_area_name: 'KACHIKAU',
    village_id: 114,
    village_name: 'KACHIKAU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 219,
    extension_area_name: 'SESE NORTH -MACHANA',
    village_id: 115,
    village_name: 'KADUE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 214,
    extension_area_name: 'SEPOPA',
    village_id: 116,
    village_name: 'KAJAJA'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 42,
    extension_area_name: 'KALAKAMATI',
    village_id: 117,
    village_name: 'KALAKAMATI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 43,
    extension_area_name: 'KALAMARE',
    village_id: 118,
    village_name: 'KALAMARE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 81,
    extension_area_name: 'MABUTSANE',
    village_id: 119,
    village_name: 'KANAKU'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 44,
    extension_area_name: 'KANG',
    village_id: 120,
    village_name: 'KANG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 45,
    extension_area_name: 'KANNGWE',
    village_id: 121,
    village_name: 'KANNGWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 257,
    extension_area_name: 'TSWIDI',
    village_id: 122,
    village_name: 'KANYE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 139,
    extension_area_name: 'MOHEMBO EAST',
    village_id: 123,
    village_name: 'KAPUTURA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 46,
    extension_area_name: 'KARAKUBIS',
    village_id: 124,
    village_name: 'KARAKUBIS'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 47,
    extension_area_name: 'KARENG',
    village_id: 125,
    village_name: 'KARENG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 203,
    extension_area_name: 'SALAJWE',
    village_id: 126,
    village_name: 'KAUDWANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 139,
    extension_area_name: 'MOHEMBO EAST',
    village_id: 127,
    village_name: 'KAUXWI'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 77,
    extension_area_name: 'MABELE - KAVIMBA',
    village_id: 128,
    village_name: 'KAVIMBA'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 68,
    extension_area_name: 'LESOMA- KAZUNGULA',
    village_id: 129,
    village_name: 'KAZUNGULA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 151,
    extension_area_name: 'MOPIPI',
    village_id: 130,
    village_name: 'KEDIA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 48,
    extension_area_name: 'KENG',
    village_id: 131,
    village_name: 'KENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 98,
    extension_area_name: 'MAOKATUMO',
    village_id: 132,
    village_name: 'KGAGODI'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 19,
    extension_area_name: 'DITSHEGWANE',
    village_id: 133,
    village_name: 'KGARE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 99,
    extension_area_name: 'MAPOKA',
    village_id: 134,
    village_name: 'KGARI'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 1,
    extension_area_name: 'ARTESIA',
    village_id: 135,
    village_name: 'KGOMODITSHABA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 49,
    extension_area_name: 'KGOMOKASITWA',
    village_id: 136,
    village_name: 'KGOMOKASITWA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 50,
    extension_area_name: 'KGOPE',
    village_id: 137,
    village_name: 'KGOPE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 33,
    extension_area_name: 'GOODHOPE',
    village_id: 138,
    village_name: 'KGORO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 51,
    extension_area_name: 'KHAKHEA',
    village_id: 139,
    village_name: 'KHAKHEA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 140,
    village_name: 'KHAWA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 164,
    extension_area_name: 'MOTOKWE',
    village_id: 141,
    village_name: 'KHEKHENYE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 48,
    extension_area_name: 'KENG',
    village_id: 142,
    village_name: 'KHONKHWA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 52,
    extension_area_name: 'KHUDUMELAPYE',
    village_id: 143,
    village_name: 'KHUDUMELAPYE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 144,
    village_name: 'KHUIS'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 53,
    extension_area_name: 'KHUMAGA',
    village_id: 145,
    village_name: 'KHUMAGA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 228,
    extension_area_name: 'SHOROBE',
    village_id: 146,
    village_name: 'KHWAI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 69,
    extension_area_name: 'LETLHAKANE',
    village_id: 147,
    village_name: 'KHWEE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 179,
    extension_area_name: 'OMAWENENO',
    village_id: 148,
    village_name: 'KISA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 54,
    extension_area_name: 'GOBOJANGO',
    village_id: 149,
    village_name: 'GOBOJANGO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 55,
    extension_area_name: 'KODIBELENG',
    village_id: 150,
    village_name: 'KODIBELENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 81,
    extension_area_name: 'MABUTSANE',
    village_id: 151,
    village_name: 'KOKONG'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 260,
    extension_area_name: 'WERDA',
    village_id: 152,
    village_name: 'KOKOTSHA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 268,
    extension_area_name: 'KOLE',
    village_id: 153,
    village_name: 'KOLE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 154,
    village_name: 'KOLONKWANENG'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 56,
    extension_area_name: 'KOMANA',
    village_id: 155,
    village_name: 'KOMANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 57,
    extension_area_name: 'KOPONG',
    village_id: 156,
    village_name: 'KOPONG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 58,
    extension_area_name: 'KOTOLANAME',
    village_id: 157,
    village_name: 'KOTOLANAME'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 59,
    extension_area_name: 'KUBUNG',
    village_id: 158,
    village_name: 'KUBUNG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 60,
    extension_area_name: 'KUDUMATSE',
    village_id: 159,
    village_name: 'KUDUMATSE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 28,
    extension_area_name: 'GANTSI',
    village_id: 160,
    village_name: 'KUKE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 61,
    extension_area_name: 'KUMAKWANE',
    village_id: 161,
    village_name: 'KUMAKWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 155,
    extension_area_name: 'MOSETSE',
    village_id: 162,
    village_name: 'KUTAMOGORE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 51,
    extension_area_name: 'KHAKHEA',
    village_id: 163,
    village_name: 'KUTUKU'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 112,
    extension_area_name: 'MEDIE',
    village_id: 164,
    village_name: 'KWENENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 221,
    extension_area_name: 'SESUNG',
    village_id: 165,
    village_name: 'LEBUTSE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 246,
    extension_area_name: 'TOPISI',
    village_id: 166,
    village_name: 'LECHANA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 62,
    extension_area_name: 'LECHENG',
    village_id: 167,
    village_name: 'LECHENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 143,
    extension_area_name: 'MOKHOMMA',
    village_id: 168,
    village_name: 'LEFHOKO'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 247,
    extension_area_name: 'TOTENG',
    village_id: 169,
    village_name: 'LEGOTHWANA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 63,
    extension_area_name: 'LEHUTUTU',
    village_id: 170,
    village_name: 'LEHUTUTU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 30,
    extension_area_name: 'GATHWANE',
    village_id: 171,
    village_name: 'LEJWANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 50,
    extension_area_name: 'KGOPE',
    village_id: 172,
    village_name: 'LENTSWELETAU'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 20,
    extension_area_name: 'DITSHUKUDU',
    village_id: 173,
    village_name: 'LENTSWELETAU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 225,
    extension_area_name: 'SHASHE',
    village_id: 174,
    village_name: 'LEOMBOKO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 110,
    extension_area_name: 'MATSITAMA',
    village_id: 175,
    village_name: 'LEPHASHE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 64,
    extension_area_name: 'LEPHEPHE',
    village_id: 176,
    village_name: 'LEPHEPE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 5,
    extension_area_name: 'BOBONONG NORTH',
    village_id: 177,
    village_name: 'LEPOKOLE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 15,
    extension_area_name: 'DIKHUKHUNG',
    village_id: 178,
    village_name: 'LEPORUNG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 65,
    extension_area_name: 'LERALA',
    village_id: 179,
    village_name: 'LERALA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 66,
    extension_area_name: 'LESENEPOLE',
    village_id: 180,
    village_name: 'LESENEPOLE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 67,
    extension_area_name: 'LESHIBITSE',
    village_id: 181,
    village_name: 'LESHIBITSE'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 68,
    extension_area_name: 'LESOMA- KAZUNGULA',
    village_id: 182,
    village_name: 'LESOMA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 69,
    extension_area_name: 'LETLHAKANE',
    village_id: 183,
    village_name: 'LETLHAKANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 70,
    extension_area_name: 'LETLHAKANE WEST',
    village_id: 184,
    village_name: 'LETLHAKANE WEST'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 71,
    extension_area_name: 'LETLHAKENG',
    village_id: 185,
    village_name: 'LETLHAKENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 82,
    extension_area_name: 'MACHANENG',
    village_id: 186,
    village_name: 'LETORENG'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 102,
    extension_area_name: 'MASUNGA',
    village_id: 187,
    village_name: 'LETSHOLATHEBE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 72,
    extension_area_name: 'LOGAGANE',
    village_id: 188,
    village_name: 'LOGAGANE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 73,
    extension_area_name: 'LOKGWABE',
    village_id: 189,
    village_name: 'LOKGWABE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 64,
    extension_area_name: 'LEPHEPHE',
    village_id: 190,
    village_name: 'LOOLOGANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 74,
    extension_area_name: 'LOROLWANE',
    village_id: 191,
    village_name: 'LOROLWANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 136,
    extension_area_name: 'MOGOJOGOJO',
    village_id: 192,
    village_name: 'LORWANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 75,
    extension_area_name: 'LOSILAKGOKONG',
    village_id: 193,
    village_name: 'LOSILAKGOKONG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 76,
    extension_area_name: 'LOTLHAKANE EAST',
    village_id: 194,
    village_name: 'LOTLHAKANE EAST'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 63,
    extension_area_name: 'LEHUTUTU',
    village_id: 195,
    village_name: 'MAAKE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 197,
    extension_area_name: 'RAMOKGONAMI',
    village_id: 196,
    village_name: 'MAAPE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 228,
    extension_area_name: 'SHOROBE',
    village_id: 197,
    village_name: 'MABABE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 231,
    extension_area_name: 'SIKWANE',
    village_id: 198,
    village_name: 'MABALANE'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 77,
    extension_area_name: 'MABELE - KAVIMBA',
    village_id: 199,
    village_name: 'MABELE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 183,
    extension_area_name: 'PAJE',
    village_id: 200,
    village_name: 'MABELEAPODI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 78,
    extension_area_name: 'MABESEKWA',
    village_id: 201,
    village_name: 'MABESEKWA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 79,
    extension_area_name: 'MABOANE',
    village_id: 202,
    village_name: 'MABOANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 212,
    extension_area_name: 'SEMOLALE',
    village_id: 203,
    village_name: 'MABOLWE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 249,
    extension_area_name: 'TSAMAYA',
    village_id: 204,
    village_name: 'MABUDZANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 171,
    extension_area_name: 'NKANGE',
    village_id: 205,
    village_name: 'MABUE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 80,
    extension_area_name: 'MABULE',
    village_id: 206,
    village_name: 'MABULE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 140,
    extension_area_name: 'MOIYABANA',
    village_id: 207,
    village_name: 'MABUO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 81,
    extension_area_name: 'MABUTSANE',
    village_id: 208,
    village_name: 'MABUTSANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 219,
    extension_area_name: 'SESE NORTH -MACHANA',
    village_id: 209,
    village_name: 'MACHANA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 82,
    extension_area_name: 'MACHANENG',
    village_id: 210,
    village_name: 'MACHANENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 91,
    extension_area_name: 'MAKORO',
    village_id: 211,
    village_name: 'MADIAELA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 114,
    extension_area_name: 'METLOJANE',
    village_id: 212,
    village_name: 'MADINGWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 104,
    extension_area_name: 'MATHANGWANE',
    village_id: 213,
    village_name: 'MAFUNGO - HUBONA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 205,
    extension_area_name: 'SEBINA',
    village_id: 214,
    village_name: 'MAFUNGO - HUBONA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 79,
    extension_area_name: 'MABOANE',
    village_id: 215,
    village_name: 'MAGAGARAPANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 84,
    extension_area_name: 'MAGORIAPITSE',
    village_id: 216,
    village_name: 'MAGORIAPITSE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 176,
    extension_area_name: 'NTLHANTLHE',
    village_id: 217,
    village_name: 'MAGOTLHWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 131,
    extension_area_name: 'MODIANE SOUTH',
    village_id: 218,
    village_name: 'MAHALAPYE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 85,
    extension_area_name: 'MAHETLWE',
    village_id: 219,
    village_name: 'MAHETLWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 210,
    extension_area_name: 'SEKOMA',
    village_id: 220,
    village_name: 'MAHOTSHWANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 76,
    extension_area_name: 'LOTLHAKANE EAST',
    village_id: 221,
    village_name: 'MAISANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 86,
    extension_area_name: 'MAITENGWE',
    village_id: 222,
    village_name: 'MAITENGWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 30,
    extension_area_name: 'GATHWANE',
    village_id: 223,
    village_name: 'MAJAALELA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 254,
    extension_area_name: 'TSHIMOYAPULA',
    village_id: 224,
    village_name: 'MAJWANAADIPITSE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 202,
    extension_area_name: 'RATHOLO',
    village_id: 225,
    village_name: 'MAJWANENG'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 250,
    extension_area_name: 'TSAU',
    village_id: 226,
    village_name: 'MAKAKUNG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 87,
    extension_area_name: 'MAKALAMABEDI',
    village_id: 227,
    village_name: 'MAKALAMABEDI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 88,
    extension_area_name: 'MAKALAMABEDI',
    village_id: 228,
    village_name: 'MAKALAMABEDI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 229,
    village_name: 'MAKALENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 145,
    extension_area_name: 'MOKUBILO',
    village_id: 230,
    village_name: 'MAKGABA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 9,
    extension_area_name: 'BOROLONG',
    village_id: 231,
    village_name: 'MAKOBO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 8,
    extension_area_name: 'BOROBADILEPE',
    village_id: 232,
    village_name: 'MAKOKWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 244,
    extension_area_name: 'TONOTA EAST',
    village_id: 233,
    village_name: 'MAKOMOTO'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 90,
    extension_area_name: 'MAKOPONG',
    village_id: 234,
    village_name: 'MAKOPONG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 91,
    extension_area_name: 'MAKORO',
    village_id: 235,
    village_name: 'MAKORO'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 13,
    extension_area_name: 'CHARLESHILL',
    village_id: 236,
    village_name: 'MAKUNDA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 175,
    extension_area_name: 'NSWAZWI',
    village_id: 237,
    village_name: 'MAKUTA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 92,
    extension_area_name: 'MAKWATE',
    village_id: 238,
    village_name: 'MAKWATE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 62,
    extension_area_name: 'LECHENG',
    village_id: 239,
    village_name: 'MALAKA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 124,
    extension_area_name: 'MMASHORO',
    village_id: 240,
    village_name: 'MALATSWAI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 165,
    extension_area_name: 'NATA',
    village_id: 241,
    village_name: 'MALELEJWE-TSHWAANE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 248,
    extension_area_name: 'TSABONG',
    village_id: 242,
    village_name: 'MALESHE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 170,
    extension_area_name: 'NGWATSAO',
    village_id: 243,
    village_name: 'MALOKAGANYANE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 93,
    extension_area_name: 'MALOLWANE',
    village_id: 244,
    village_name: 'MALOLWANE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 94,
    extension_area_name: 'MALOTWANA',
    village_id: 245,
    village_name: 'MALOTWANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 95,
    extension_area_name: 'MALWELWE',
    village_id: 246,
    village_name: 'MALWELWE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 247,
    village_name: 'MAMBO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 225,
    extension_area_name: 'SHASHE',
    village_id: 248,
    village_name: 'MANDUNYANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 95,
    extension_area_name: 'MALWELWE',
    village_id: 249,
    village_name: 'MANTSHWABISI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 165,
    extension_area_name: 'NATA',
    village_id: 250,
    village_name: 'MANXOTAE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 96,
    extension_area_name: 'MANYANA',
    village_id: 251,
    village_name: 'MANYANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 97,
    extension_area_name: 'MAOKANE',
    village_id: 252,
    village_name: 'MAOKANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 98,
    extension_area_name: 'MAOKATUMO',
    village_id: 253,
    village_name: 'MAOKATUMO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 99,
    extension_area_name: 'MAPOKA',
    village_id: 254,
    village_name: 'MAPOKA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 165,
    extension_area_name: 'NATA',
    village_id: 255,
    village_name: 'MAPOSA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 179,
    extension_area_name: 'OMAWENENO',
    village_id: 256,
    village_name: 'MARALALENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 100,
    extension_area_name: 'MARAPONG',
    village_id: 257,
    village_name: 'MARAPONG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 95,
    extension_area_name: 'MALWELWE',
    village_id: 258,
    village_name: 'MARATSWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 205,
    extension_area_name: 'SEBINA',
    village_id: 259,
    village_name: 'MAROBELA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 39,
    extension_area_name: 'HEBRON',
    village_id: 260,
    village_name: 'MAROJANE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 261,
    village_name: 'MASINGWANENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 101,
    extension_area_name: 'MASOKE',
    village_id: 262,
    village_name: 'MASOKE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 172,
    extension_area_name: 'NLAPKWANE',
    village_id: 263,
    village_name: 'MASUKWANE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 102,
    extension_area_name: 'MASUNGA',
    village_id: 264,
    village_name: 'MASUNGA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 103,
    extension_area_name: 'MATAPANA',
    village_id: 265,
    village_name: 'MATAPANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 8,
    extension_area_name: 'BOROBADILEPE',
    village_id: 266,
    village_name: 'MATASALALO'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 180,
    extension_area_name: 'OODI',
    village_id: 267,
    village_name: 'MATEBELE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 268,
    village_name: 'MATENGE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 104,
    extension_area_name: 'MATHANGWANE',
    village_id: 269,
    village_name: 'MATHANGWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 105,
    extension_area_name: 'MATHATHANE',
    village_id: 270,
    village_name: 'MATHATHANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 71,
    extension_area_name: 'LETLHAKENG',
    village_id: 271,
    village_name: 'MATLAGATSE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 72,
    extension_area_name: 'LOGAGANE',
    village_id: 272,
    village_name: 'MATLAKENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 11,
    extension_area_name: 'CHADIBE',
    village_id: 273,
    village_name: 'MATLHAKO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 62,
    extension_area_name: 'LECHENG',
    village_id: 274,
    village_name: 'MATLHAKOLA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 107,
    extension_area_name: 'MATOBO',
    village_id: 275,
    village_name: 'MATOBO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 109,
    extension_area_name: 'MATSILOJE',
    village_id: 276,
    village_name: 'MATOPI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 228,
    extension_area_name: 'SHOROBE',
    village_id: 277,
    village_name: 'MATSAUDI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 108,
    extension_area_name: 'MATSHELAGABEDI',
    village_id: 278,
    village_name: 'MATSHELAGABEDI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 109,
    extension_area_name: 'MATSILOJE',
    village_id: 279,
    village_name: 'MATSILOJE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 110,
    extension_area_name: 'MATSITAMA',
    village_id: 280,
    village_name: 'MATSITAMA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 248,
    extension_area_name: 'TSABONG',
    village_id: 281,
    village_name: 'MAUBELO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 111,
    extension_area_name: 'MAUNATLALA',
    village_id: 282,
    village_name: 'MAUNATALA'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 42,
    extension_area_name: 'KALAKAMATI',
    village_id: 283,
    village_name: 'MBALAMBI'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 112,
    extension_area_name: 'MEDIE',
    village_id: 284,
    village_name: 'MEDIE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 113,
    extension_area_name: 'METLOBO',
    village_id: 285,
    village_name: 'METLOBO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 114,
    extension_area_name: 'METLOJANE',
    village_id: 286,
    village_name: 'METLOJANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 19,
    extension_area_name: 'DITSHEGWANE',
    village_id: 287,
    village_name: 'METSIBOTLHOKO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 25,
    extension_area_name: 'GABANE',
    village_id: 288,
    village_name: 'METSIMOTLHABE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 189,
    extension_area_name: 'PILIKWE',
    village_id: 289,
    village_name: 'MHALAPITSA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 290,
    village_name: 'MIDDLEPITS'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 161,
    extension_area_name: 'MOSOJANE',
    village_id: 291,
    village_name: 'MLAMBAKWENA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 116,
    extension_area_name: 'MMADIKOLA',
    village_id: 292,
    village_name: 'MMADIKOLA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 117,
    extension_area_name: 'MMADINARE NORTH',
    village_id: 293,
    village_name: 'MMADINARE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 118,
    extension_area_name: 'MMADINARE SOUTH',
    village_id: 294,
    village_name: 'MMADINARE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 15,
    extension_area_name: 'DIKHUKHUNG',
    village_id: 295,
    village_name: 'MMAKGORI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 119,
    extension_area_name: 'MMALORE',
    village_id: 296,
    village_name: 'MMALORE (MMATHETHE)'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 120,
    extension_area_name: 'MMAMAROBOLWE',
    village_id: 297,
    village_name: 'MMAMAROBOLWE (MOLEPOLOLE)'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 121,
    extension_area_name: 'MMANKGODI',
    village_id: 298,
    village_name: 'MMANKGODI'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 122,
    extension_area_name: 'MMANOKO',
    village_id: 299,
    village_name: 'MMANOKO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 123,
    extension_area_name: 'MMAPHASHALALA',
    village_id: 300,
    village_name: 'MMAPHASHALALA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 124,
    extension_area_name: 'MMASHORO',
    village_id: 301,
    village_name: 'MMASHORO'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 125,
    extension_area_name: 'MMATHETHE NORTH',
    village_id: 302,
    village_name: 'MMATHETHE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 126,
    extension_area_name: 'MMATHETHE SOUTH',
    village_id: 303,
    village_name: 'MMATHETHE (MOTSENTSHE)'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 127,
    extension_area_name: 'MMATHUBUDUKWANE',
    village_id: 304,
    village_name: 'MMATHUBUDUKWANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 57,
    extension_area_name: 'KOPONG',
    village_id: 305,
    village_name: 'MMATSETLA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 128,
    extension_area_name: 'MMATSHUMU',
    village_id: 306,
    village_name: 'MMATSHUMU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 145,
    extension_area_name: 'MOKUBILO',
    village_id: 307,
    village_name: 'MMEA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 25,
    extension_area_name: 'GABANE',
    village_id: 308,
    village_name: 'MMOKOLODI'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 25,
    extension_area_name: 'GABANE',
    village_id: 309,
    village_name: 'MMOPANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 129,
    extension_area_name: 'MMUTLANE',
    village_id: 310,
    village_name: 'MMUTLANE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 16,
    extension_area_name: 'DINOGENG NORTH',
    village_id: 311,
    village_name: 'MOCHUDI'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 17,
    extension_area_name: 'DINOGENG SOUTH',
    village_id: 312,
    village_name: 'MOCHUDI'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 106,
    extension_area_name: 'MATLHAGE',
    village_id: 313,
    village_name: 'MOCHUDI'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 132,
    extension_area_name: 'MODIPANE',
    village_id: 314,
    village_name: 'MODIPANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 133,
    extension_area_name: 'MOGAPI',
    village_id: 315,
    village_name: 'MOGAPI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 98,
    extension_area_name: 'MAOKATUMO',
    village_id: 316,
    village_name: 'MOGAPINYANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 134,
    extension_area_name: 'MOGAPINYANA (SEGWAGWA)',
    village_id: 317,
    village_name: 'MOGAPINYANA'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 135,
    extension_area_name: 'MOGOBANE',
    village_id: 318,
    village_name: 'MOGOBANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 114,
    extension_area_name: 'METLOJANE',
    village_id: 319,
    village_name: 'MOGOBEWAKGOMO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 25,
    extension_area_name: 'GABANE',
    village_id: 320,
    village_name: 'MOGODITSHANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 136,
    extension_area_name: 'MOGOJOGOJO',
    village_id: 321,
    village_name: 'MOGOJOGOJO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 194,
    extension_area_name: 'RADISELE',
    village_id: 322,
    village_name: 'MOGOME'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 137,
    extension_area_name: 'MOGONONO',
    village_id: 323,
    village_name: 'MOGONONO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 138,
    extension_area_name: 'MOGOROSI',
    village_id: 324,
    village_name: 'MOGOROSI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 114,
    extension_area_name: 'METLOJANE',
    village_id: 325,
    village_name: 'MOGWALALE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 223,
    extension_area_name: 'SHAKAWE',
    village_id: 326,
    village_name: 'MOHEMBO'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 139,
    extension_area_name: 'MOHEMBO EAST',
    village_id: 327,
    village_name: 'MOHEMBO EAST'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 140,
    extension_area_name: 'MOIYABANA',
    village_id: 328,
    village_name: 'MOIYABANA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 141,
    extension_area_name: 'MOKATAKO',
    village_id: 329,
    village_name: 'MOKATAKO'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 132,
    extension_area_name: 'MODIPANE',
    village_id: 330,
    village_name: 'MOKATSE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 215,
    extension_area_name: 'SERONGA',
    village_id: 331,
    village_name: 'MOKGACHA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 192,
    extension_area_name: 'POLOKA',
    village_id: 332,
    village_name: 'MOKGENENE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 142,
    extension_area_name: 'MOKGOMANE',
    village_id: 333,
    village_name: 'MOKGOMANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 168,
    extension_area_name: 'NGARANGE',
    village_id: 334,
    village_name: 'MOKGOTHO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 253,
    extension_area_name: 'TSHETHONG',
    village_id: 335,
    village_name: 'MOKGWARE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 143,
    extension_area_name: 'MOKHOMMA',
    village_id: 336,
    village_name: 'MOKHOMMA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 144,
    extension_area_name: 'MOKOBENG',
    village_id: 337,
    village_name: 'MOKOBENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 151,
    extension_area_name: 'MOPIPI',
    village_id: 338,
    village_name: 'MOKOBOXANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 111,
    extension_area_name: 'MAUNATLALA',
    village_id: 339,
    village_name: 'MOKOKWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 60,
    extension_area_name: 'KUDUMATSE',
    village_id: 340,
    village_name: 'MOKOSWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 145,
    extension_area_name: 'MOKUBILO',
    village_id: 341,
    village_name: 'MOKUBILO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 62,
    extension_area_name: 'LECHENG',
    village_id: 342,
    village_name: 'MOKUNGWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 146,
    extension_area_name: 'MOLALATAU',
    village_id: 343,
    village_name: 'MOLALATAU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 147,
    extension_area_name: 'MOLAPOWABOJANG',
    village_id: 344,
    village_name: 'MOLAPOWABOJANG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 148,
    extension_area_name: 'MOLEPOLOLE',
    village_id: 345,
    village_name: 'MOLEPOLOLE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 141,
    extension_area_name: 'MOKATAKO',
    village_id: 346,
    village_name: 'MOLETE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 251,
    extension_area_name: 'TSETSEBJWE',
    village_id: 347,
    village_name: 'MOLETEMANE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 263,
    extension_area_name: 'HUNHUKWE',
    village_id: 348,
    village_name: 'MONONG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 149,
    extension_area_name: 'MONWANE',
    village_id: 349,
    village_name: 'MONWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 150,
    extension_area_name: 'MOOKANE',
    village_id: 350,
    village_name: 'MOOKANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 151,
    extension_area_name: 'MOPIPI',
    village_id: 351,
    village_name: 'MOPIPI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 162,
    extension_area_name: 'MOSOLOTSHANE',
    village_id: 352,
    village_name: 'MORALANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 66,
    extension_area_name: 'LESENEPOLE',
    village_id: 353,
    village_name: 'MOREMI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 246,
    extension_area_name: 'TOPISI',
    village_id: 354,
    village_name: 'MOREOMABELE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 152,
    extension_area_name: 'MOREOMATO',
    village_id: 355,
    village_name: 'MOREOMATO'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 153,
    extension_area_name: 'MOROKA',
    village_id: 356,
    village_name: 'MOROKA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 170,
    extension_area_name: 'NGWATSAO',
    village_id: 357,
    village_name: 'MOROKOLWANE'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 154,
    extension_area_name: 'MORWA',
    village_id: 358,
    village_name: 'MORWA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 81,
    extension_area_name: 'MABUTSANE',
    village_id: 359,
    village_name: 'MORWAMOSU'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 76,
    extension_area_name: 'LOTLHAKANE EAST',
    village_id: 360,
    village_name: 'MOSAMOWAKWENA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 155,
    extension_area_name: 'MOSETSE',
    village_id: 361,
    village_name: 'MOSETSE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 156,
    extension_area_name: 'MOSHANA',
    village_id: 362,
    village_name: 'MOSHANA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 157,
    extension_area_name: 'MOSHAWENG',
    village_id: 363,
    village_name: 'MOSHAWENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 158,
    extension_area_name: 'MOSHOPHA',
    village_id: 364,
    village_name: 'MOSHOPHA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 159,
    extension_area_name: 'MOSHUPA NORTH',
    village_id: 365,
    village_name: 'MOSHUPA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 142,
    extension_area_name: 'MOKGOMANE',
    village_id: 366,
    village_name: 'MOSI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 161,
    extension_area_name: 'MOSOJANE',
    village_id: 367,
    village_name: 'MOSOJANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 162,
    extension_area_name: 'MOSOLOTSHANE',
    village_id: 368,
    village_name: 'MOSOLOTSHANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 163,
    extension_area_name: 'MOSU',
    village_id: 369,
    village_name: 'MOSU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 111,
    extension_area_name: 'MAUNATLALA',
    village_id: 370,
    village_name: 'MOSWEU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 105,
    extension_area_name: 'MATHATHANE',
    village_id: 371,
    village_name: 'MOTLHABANENG'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 164,
    extension_area_name: 'MOTOKWE',
    village_id: 372,
    village_name: 'MOTOKWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 152,
    extension_area_name: 'MOREOMATO',
    village_id: 373,
    village_name: 'MOTOPI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 238,
    extension_area_name: 'THABALA',
    village_id: 374,
    village_name: 'MOTSHEGALETAU'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 249,
    extension_area_name: 'TSAMAYA',
    village_id: 375,
    village_name: 'MOWANA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 165,
    extension_area_name: 'NATA',
    village_id: 376,
    village_name: 'NATA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 227,
    extension_area_name: 'SHASHEMOOKE',
    village_id: 377,
    village_name: 'NATALE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 263,
    extension_area_name: 'HUNHUKWE',
    village_id: 378,
    village_name: 'NCAANG'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 166,
    extension_area_name: 'NCOJANE',
    village_id: 379,
    village_name: 'NCOJANE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 167,
    extension_area_name: 'NEW XADE',
    village_id: 380,
    village_name: 'NEW XADE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 46,
    extension_area_name: 'KARAKUBIS',
    village_id: 381,
    village_name: 'NEW XANAGAS'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 168,
    extension_area_name: 'NGARANGE',
    village_id: 382,
    village_name: 'NGARANGE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 144,
    extension_area_name: 'MOKOBENG',
    village_id: 383,
    village_name: 'NGWAPA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 169,
    extension_area_name: 'NGWARE',
    village_id: 384,
    village_name: 'NGWARE'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 263,
    extension_area_name: 'HUNHUKWE',
    village_id: 385,
    village_name: 'NGWATLE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 170,
    extension_area_name: 'NGWATSAO',
    village_id: 386,
    village_name: 'NGWATSAO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 171,
    extension_area_name: 'NKANGE',
    village_id: 387,
    village_name: 'NKANGE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 172,
    extension_area_name: 'NLAPKWANE',
    village_id: 388,
    village_name: 'NLAPKWANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 173,
    extension_area_name: 'NOKANENG',
    village_id: 389,
    village_name: 'NOKANENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 174,
    extension_area_name: 'NSHAKAZHOGWE',
    village_id: 390,
    village_name: 'NSHAKAZHOGWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 175,
    extension_area_name: 'NSWAZWI',
    village_id: 391,
    village_name: 'NSWAZWI'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 176,
    extension_area_name: 'NTLHANTLHE',
    village_id: 392,
    village_name: 'NTLHANTLHE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 177,
    extension_area_name: 'NXAMASERE',
    village_id: 393,
    village_name: 'NXAMASERE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 56,
    extension_area_name: 'KOMANA',
    village_id: 394,
    village_name: 'NXARAGA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 24,
    extension_area_name: 'ETSHA 6',
    village_id: 395,
    village_name: 'NXAUNXAU'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 178,
    extension_area_name: "OLIPHANT''S DRIFT",
    village_id: 396,
    village_name: "OLIPHANT'S DRIFT"
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 179,
    extension_area_name: 'OMAWENENO',
    village_id: 397,
    village_name: 'OMAWENENO'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 180,
    extension_area_name: 'OODI',
    village_id: 398,
    village_name: 'OODI'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 181,
    extension_area_name: 'OTSE',
    village_id: 399,
    village_name: 'OTSE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 182,
    extension_area_name: 'OTSE',
    village_id: 400,
    village_name: 'OTSE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 183,
    extension_area_name: 'PAJE',
    village_id: 401,
    village_name: 'PAJE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 184,
    extension_area_name: 'PALAPYE',
    village_id: 402,
    village_name: 'PALAPYE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 185,
    extension_area_name: 'PALLAROAD',
    village_id: 403,
    village_name: 'PALLAROAD'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 186,
    extension_area_name: 'PANDAMATENGA',
    village_id: 404,
    village_name: 'PANDAMATENGA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 8,
    extension_area_name: 'BOROBADILEPE',
    village_id: 405,
    village_name: 'PAPATLO'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 187,
    extension_area_name: 'PARAKARUNGU',
    village_id: 406,
    village_name: 'PARAKARUNGU'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 235,
    extension_area_name: 'TATI SIDING',
    village_id: 407,
    village_name: 'PATA YA MATEBELE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 39,
    extension_area_name: 'HEBRON',
    village_id: 408,
    village_name: 'PHIHETSWANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 188,
    extension_area_name: 'PHITSHANE MOLOPO',
    village_id: 409,
    village_name: 'PHITSHANE MOLOPO'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 44,
    extension_area_name: 'KANG',
    village_id: 410,
    village_name: 'PHUDUHUDU'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 157,
    extension_area_name: 'MOSHAWENG',
    village_id: 411,
    village_name: 'PHUDUHUDU'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 88,
    extension_area_name: 'MAKALAMABEDI',
    village_id: 412,
    village_name: 'PHUDUHUDU'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 7,
    extension_area_name: 'BOKAA',
    village_id: 413,
    village_name: 'PILANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 189,
    extension_area_name: 'PILIKWE',
    village_id: 414,
    village_name: 'PILIKWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 190,
    extension_area_name: 'PITSANE',
    village_id: 415,
    village_name: 'PITSANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 190,
    extension_area_name: 'PITSANE',
    village_id: 416,
    village_name: 'PITSANE-POTLOKWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 191,
    extension_area_name: 'PITSENG',
    village_id: 417,
    village_name: 'PITSENG'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 161,
    extension_area_name: 'MOSOJANE',
    village_id: 418,
    village_name: 'POLE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 192,
    extension_area_name: 'POLOKA',
    village_id: 419,
    village_name: 'POLOKA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 160,
    extension_area_name: 'MOSHUPA SOUTH',
    village_id: 420,
    village_name: 'POLOKWE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 34,
    extension_area_name: 'GROOTLAAGTE',
    village_id: 421,
    village_name: 'QABO'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 173,
    extension_area_name: 'NOKANENG',
    village_id: 422,
    village_name: 'QANGWA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 37,
    extension_area_name: 'HANAHAI',
    village_id: 423,
    village_name: 'QCAGAE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 35,
    extension_area_name: 'GUMARE',
    village_id: 424,
    village_name: 'QURUBE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 194,
    extension_area_name: 'RADISELE',
    village_id: 425,
    village_name: 'RADISELE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 190,
    extension_area_name: 'PITSANE',
    village_id: 426,
    village_name: 'RAKHUNA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 195,
    extension_area_name: 'RAKOPS',
    village_id: 427,
    village_name: 'RAKOPS'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 196,
    extension_area_name: 'RALEKGETHO',
    village_id: 428,
    village_name: 'RALEKGETHO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 50,
    extension_area_name: 'KGOPE',
    village_id: 429,
    village_name: 'RAMANKHUNG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 8,
    extension_area_name: 'BOROBADILEPE',
    village_id: 430,
    village_name: 'RAMATLABAMA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 197,
    extension_area_name: 'RAMOKGONAMI',
    village_id: 431,
    village_name: 'RAMOKGONAMI'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 153,
    extension_area_name: 'MOROKA',
    village_id: 432,
    village_name: 'RAMOKGWEBANA'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 178,
    extension_area_name: "OLIPHANT''S DRIFT",
    village_id: 433,
    village_name: 'RAMONAKA'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 178,
    extension_area_name: "OLIPHANT''S DRIFT",
    village_id: 434,
    village_name: 'RAMOTLABAKI'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 198,
    extension_area_name: 'RAMOTSWA NORTH',
    village_id: 435,
    village_name: 'RAMOTSWA'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 199,
    extension_area_name: 'RAMOTSWA SOUTH',
    village_id: 436,
    village_name: 'RAMOTSWA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 200,
    extension_area_name: 'RANAKA',
    village_id: 437,
    village_name: 'RANAKA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 438,
    village_name: 'RAPPLESPAN'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 201,
    extension_area_name: 'RASESA',
    village_id: 439,
    village_name: 'RASESA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 202,
    extension_area_name: 'RATHOLO',
    village_id: 440,
    village_name: 'RATHOLO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 117,
    extension_area_name: 'MMADINARE NORTH',
    village_id: 441,
    village_name: 'ROBELELA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 203,
    extension_area_name: 'SALAJWE',
    village_id: 442,
    village_name: 'SALAJWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 143,
    extension_area_name: 'MOKHOMMA',
    village_id: 443,
    village_name: 'SAMANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 223,
    extension_area_name: 'SHAKAWE',
    village_id: 444,
    village_name: 'SAMOCHIMA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 228,
    extension_area_name: 'SHOROBE',
    village_id: 445,
    village_name: 'SANKOYO'
  },
  {
    district_id: 2,
    district_name: 'CHOBE',
    sub_district_id: 3,
    sub_district_name: 'CHOBE',
    extension_area_id: 204,
    extension_area_name: 'SATAU',
    village_id: 446,
    village_name: 'SATAU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 205,
    extension_area_name: 'SEBINA',
    village_id: 447,
    village_name: 'SEBINA'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 448,
    village_name: 'SECHELE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 188,
    extension_area_name: 'PHITSHANE MOLOPO',
    village_id: 449,
    village_name: 'SEDIBENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 206,
    extension_area_name: 'SEFHARE',
    village_id: 450,
    village_name: 'SEFHARE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 207,
    extension_area_name: 'SEFHOPHE',
    village_id: 451,
    village_name: 'SEFHOPHE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 208,
    extension_area_name: 'SEGWAGWA',
    village_id: 452,
    village_name: 'SEGWAGWA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 221,
    extension_area_name: 'SESUNG',
    village_id: 453,
    village_name: 'SEHERELELA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 209,
    extension_area_name: 'SEHITHWA',
    village_id: 454,
    village_name: 'SEHITHWA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 140,
    extension_area_name: 'MOIYABANA',
    village_id: 455,
    village_name: 'SEHUNONG'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 42,
    extension_area_name: 'KALAKAMATI',
    village_id: 456,
    village_name: 'SEKAKANGWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 80,
    extension_area_name: 'MABULE',
    village_id: 457,
    village_name: 'SEKHUTLANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 72,
    extension_area_name: 'LOGAGANE',
    village_id: 458,
    village_name: 'SEKOKWANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 210,
    extension_area_name: 'SEKOMA',
    village_id: 459,
    village_name: 'SEKOMA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 168,
    extension_area_name: 'NGARANGE',
    village_id: 460,
    village_name: 'SEKONDOMBORO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 258,
    extension_area_name: 'TUMASERA-SELEKA',
    village_id: 461,
    village_name: 'SELEKA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 16,
    sub_district_name: 'NGWAKETSE CENTRAL (KANYE)',
    extension_area_id: 101,
    extension_area_name: 'MASOKE',
    village_id: 462,
    village_name: 'SELOKOLELA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 250,
    extension_area_name: 'TSAU',
    village_id: 463,
    village_name: 'SEMBOYO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 100,
    extension_area_name: 'MARAPONG',
    village_id: 464,
    village_name: 'SEMITWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 212,
    extension_area_name: 'SEMOLALE',
    village_id: 465,
    village_name: 'SEMOLALE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 225,
    extension_area_name: 'SHASHE',
    village_id: 466,
    village_name: 'SEMOTSWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 171,
    extension_area_name: 'NKANGE',
    village_id: 467,
    village_name: 'SENETE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 213,
    extension_area_name: 'SENYAWE- BUTALE',
    village_id: 468,
    village_name: 'SENYAWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 111,
    extension_area_name: 'MAUNATLALA',
    village_id: 469,
    village_name: 'SEOLWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 165,
    extension_area_name: 'NATA',
    village_id: 470,
    village_name: 'SEPAKO'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 214,
    extension_area_name: 'SEPOPA',
    village_id: 471,
    village_name: 'SEPOPA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 71,
    extension_area_name: 'LETLHAKENG',
    village_id: 472,
    village_name: 'SERINANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 215,
    extension_area_name: 'SERONGA',
    village_id: 473,
    village_name: 'SERONGA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 3,
    extension_area_name: 'BIKWE',
    village_id: 474,
    village_name: 'SEROWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 216,
    extension_area_name: 'SEROWE EAST',
    village_id: 475,
    village_name: 'SEROWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 217,
    extension_area_name: 'SEROWE WEST',
    village_id: 476,
    village_name: 'SEROWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 253,
    extension_area_name: 'TSHETHONG',
    village_id: 477,
    village_name: 'SEROWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 218,
    extension_area_name: 'SERULE',
    village_id: 478,
    village_name: 'SERULE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 220,
    extension_area_name: 'SESE SOUTH - SESE',
    village_id: 479,
    village_name: 'SESE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 19,
    extension_area_name: 'DITSHEGWANE',
    village_id: 480,
    village_name: 'SESUNG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 221,
    extension_area_name: 'SESUNG',
    village_id: 481,
    village_name: 'SESUNG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 241,
    extension_area_name: 'THOKOLO',
    village_id: 482,
    village_name: 'SETSILE (MAHALAPYE)'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 222,
    extension_area_name: 'SHADISHADI',
    village_id: 483,
    village_name: 'SHADISHADI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 223,
    extension_area_name: 'SHAKAWE',
    village_id: 484,
    village_name: 'SHAKAWE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 224,
    extension_area_name: 'SHAKWE',
    village_id: 485,
    village_name: 'SHAKWE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 235,
    extension_area_name: 'TATI SIDING',
    village_id: 486,
    village_name: 'SHASHE BRIDGE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 226,
    extension_area_name: 'SHASHE MAUN',
    village_id: 487,
    village_name: 'SHASHE MAUN'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 227,
    extension_area_name: 'SHASHEMOOKE',
    village_id: 488,
    village_name: 'SHASHEMOOKE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 170,
    extension_area_name: 'NGWATSAO',
    village_id: 489,
    village_name: 'SHEEPFARM'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 223,
    extension_area_name: 'SHAKAWE',
    village_id: 490,
    village_name: 'SHEIKARAWE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 228,
    extension_area_name: 'SHOROBE',
    village_id: 491,
    village_name: 'SHOROBE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 229,
    extension_area_name: 'SHOSHONG EAST',
    village_id: 492,
    village_name: 'SHOSHONG'
  },
  {
    district_id: 5,
    district_name: 'KGATLENG',
    sub_district_id: 7,
    sub_district_name: 'KGATLENG',
    extension_area_id: 231,
    extension_area_name: 'SIKWANE',
    village_id: 493,
    village_name: 'SIKWANE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 232,
    extension_area_name: 'SIVIYA',
    village_id: 494,
    village_name: 'SIVIYA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 8,
    sub_district_name: 'KWENENG NORTH',
    extension_area_id: 64,
    extension_area_name: 'LEPHEPHE',
    village_id: 495,
    village_name: 'SOJWE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 56,
    extension_area_name: 'KOMANA',
    village_id: 496,
    village_name: 'SOMELO'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 52,
    extension_area_name: 'KHUDUMELAPYE',
    village_id: 497,
    village_name: 'SORILATHOLO'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 498,
    village_name: 'STRUIZENDUM'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 137,
    extension_area_name: 'MOGONONO',
    village_id: 499,
    village_name: 'SUPING'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 233,
    extension_area_name: 'TAKATOKWANE',
    village_id: 500,
    village_name: 'TAKATOKWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 234,
    extension_area_name: 'TAMASANE',
    village_id: 501,
    village_name: 'TAMASANE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 235,
    extension_area_name: 'TATI SIDING',
    village_id: 502,
    village_name: 'TATI SIDING'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 183,
    extension_area_name: 'PAJE',
    village_id: 503,
    village_name: 'TAUKOME'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 236,
    extension_area_name: 'HUKUNG',
    village_id: 504,
    village_name: 'TAUNG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 237,
    extension_area_name: 'TAUPYE',
    village_id: 505,
    village_name: 'TAUPYE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 130,
    extension_area_name: 'MODIANE NORTH',
    village_id: 506,
    village_name: 'TEWANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 238,
    extension_area_name: 'THABALA',
    village_id: 507,
    village_name: 'THABALA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 214,
    extension_area_name: 'SEPOPA',
    village_id: 508,
    village_name: 'THAMACHA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 239,
    extension_area_name: 'THAMAGA EAST',
    village_id: 509,
    village_name: 'THAMAGA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 240,
    extension_area_name: 'THAMAGA WEST',
    village_id: 510,
    village_name: 'THAMAGA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 97,
    extension_area_name: 'MAOKANE',
    village_id: 511,
    village_name: 'THANKANE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 1,
    sub_district_name: 'BAROLONG',
    extension_area_id: 190,
    extension_area_name: 'PITSANE',
    village_id: 512,
    village_name: 'THARESELEELE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 249,
    extension_area_name: 'TSAMAYA',
    village_id: 513,
    village_name: 'THEMASHANGA'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 9,
    sub_district_name: 'KWENENG SOUTH',
    extension_area_id: 121,
    extension_area_name: 'MMANKGODI',
    village_id: 514,
    village_name: 'TLOANENG'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 242,
    extension_area_name: 'TLOKWENG',
    village_id: 515,
    village_name: 'TLOKWENG'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 243,
    extension_area_name: 'TOBANE',
    village_id: 516,
    village_name: 'TOBANE'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 13,
    sub_district_name: 'MAHALAPYE',
    extension_area_id: 229,
    extension_area_name: 'SHOSHONG EAST',
    village_id: 517,
    village_name: 'TOBELA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 139,
    extension_area_name: 'MOHEMBO EAST',
    village_id: 518,
    village_name: 'TOBERA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 245,
    extension_area_name: 'TONOTA WEST',
    village_id: 519,
    village_name: 'TONOTA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 26,
    sub_district_name: 'TONOTA',
    extension_area_id: 244,
    extension_area_name: 'TONOTA EAST',
    village_id: 520,
    village_name: 'TONOTA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 22,
    sub_district_name: 'PALAPYE',
    extension_area_id: 246,
    extension_area_name: 'TOPISI',
    village_id: 521,
    village_name: 'TOPISI'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 116,
    extension_area_name: 'MMADIKOLA',
    village_id: 522,
    village_name: 'TOROMOJA'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 89,
    extension_area_name: 'MAKALENG',
    village_id: 523,
    village_name: 'TOTENG'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 247,
    extension_area_name: 'TOTENG',
    village_id: 524,
    village_name: 'TOTENG'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 248,
    extension_area_name: 'TSABONG',
    village_id: 525,
    village_name: 'TSABONG'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 249,
    extension_area_name: 'TSAMAYA',
    village_id: 526,
    village_name: 'TSAMAYA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 14,
    sub_district_name: 'NGAMILAND EAST',
    extension_area_id: 250,
    extension_area_name: 'TSAU',
    village_id: 527,
    village_name: 'TSAU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 2,
    sub_district_name: 'BOBONONG',
    extension_area_id: 251,
    extension_area_name: 'TSETSEBJWE',
    village_id: 528,
    village_name: 'TSETSEBJWE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 164,
    extension_area_name: 'MOTOKWE',
    village_id: 529,
    village_name: 'TSETSENG'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 252,
    extension_area_name: 'TSHANE',
    village_id: 530,
    village_name: 'TSHANE'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 249,
    extension_area_name: 'TSAMAYA',
    village_id: 531,
    village_name: 'TSHESEBE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 80,
    extension_area_name: 'MABULE',
    village_id: 532,
    village_name: 'TSHIDILAMOLOMO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 24,
    sub_district_name: 'SEROWE',
    extension_area_id: 254,
    extension_area_name: 'TSHIMOYAPULA',
    village_id: 533,
    village_name: 'TSHIMOYAPULA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 23,
    sub_district_name: 'S/PHIKWE',
    extension_area_id: 243,
    extension_area_name: 'TOBANE',
    village_id: 534,
    village_name: 'TSHOKWE'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 17,
    sub_district_name: 'NGWAKETSE NORTH (MOSHUPA)',
    extension_area_id: 255,
    extension_area_name: 'TSHWAANE',
    village_id: 535,
    village_name: 'TSHWAANE'
  },
  {
    district_id: 6,
    district_name: 'KWENENG',
    sub_district_id: 10,
    sub_district_name: 'KWENENG WEST',
    extension_area_id: 22,
    extension_area_name: 'DUTLWE',
    village_id: 536,
    village_name: 'TSHWAANE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 214,
    extension_area_name: 'SEPOPA',
    village_id: 537,
    village_name: 'TSODILO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 36,
    extension_area_name: 'GWETA',
    village_id: 538,
    village_name: 'TSOKOTSHA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 19,
    sub_district_name: 'NGWAKETSE WEST (JWANENG)',
    extension_area_id: 221,
    extension_area_name: 'SESUNG',
    village_id: 539,
    village_name: 'TSONYANE'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 256,
    extension_area_name: 'TSOOTSHA',
    village_id: 540,
    village_name: 'TSOOTSHA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 113,
    extension_area_name: 'METLOBO',
    village_id: 541,
    village_name: 'TSWAANENG'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 142,
    extension_area_name: 'MOKGOMANE',
    village_id: 542,
    village_name: 'TSWAANYANA'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 35,
    extension_area_name: 'GUMARE',
    village_id: 543,
    village_name: 'TUBU'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 12,
    sub_district_name: 'MACHANENG',
    extension_area_id: 258,
    extension_area_name: 'TUMASERA-SELEKA',
    village_id: 544,
    village_name: 'TUMASERA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 83,
    extension_area_name: 'MAGAPATONA',
    village_id: 545,
    village_name: 'TUTUME'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 211,
    extension_area_name: 'SELOLWANE',
    village_id: 546,
    village_name: 'TUTUME'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 263,
    extension_area_name: 'HUNHUKWE',
    village_id: 547,
    village_name: 'UKHWI'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 115,
    extension_area_name: 'MIDDLEPITS',
    village_id: 548,
    village_name: 'VAALHOEK'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 102,
    extension_area_name: 'MASUNGA',
    village_id: 549,
    village_name: 'VUKWI'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 6,
    sub_district_name: 'KGALAGADI SOUTH',
    extension_area_id: 260,
    extension_area_name: 'WERDA',
    village_id: 550,
    village_name: 'WERDA'
  },
  {
    district_id: 3,
    district_name: 'GHANZI',
    sub_district_id: 4,
    sub_district_name: 'GHANZI',
    extension_area_id: 37,
    extension_area_name: 'HANAHAI',
    village_id: 551,
    village_name: 'WEST HANAHAI'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 139,
    extension_area_name: 'MOHEMBO EAST',
    village_id: 552,
    village_name: 'XAKAO'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 15,
    sub_district_name: 'NGAMILAND WEST',
    extension_area_id: 173,
    extension_area_name: 'NOKANENG',
    village_id: 553,
    village_name: 'XAXA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 195,
    extension_area_name: 'RAKOPS',
    village_id: 554,
    village_name: 'XERE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 177,
    extension_area_name: 'NXAMASERE',
    village_id: 555,
    village_name: 'XHAOGA'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 11,
    sub_district_name: 'LETLHAKANE',
    extension_area_id: 261,
    extension_area_name: 'XHUMO',
    village_id: 556,
    village_name: 'XHUMO'
  },
  {
    district_id: 1,
    district_name: 'CENTRAL',
    sub_district_id: 27,
    sub_district_name: 'TUTUME',
    extension_area_id: 36,
    extension_area_name: 'GWETA',
    village_id: 557,
    village_name: 'ZOROGA'
  },
  {
    district_id: 4,
    district_name: 'KGALAGADI',
    sub_district_id: 5,
    sub_district_name: 'KGALAGADI NORTH',
    extension_area_id: 40,
    extension_area_name: 'HUKUNTSI',
    village_id: 558,
    village_name: 'ZUTSHWA'
  },
  {
    district_id: 8,
    district_name: 'NORTH EAST',
    sub_district_id: 20,
    sub_district_name: 'NORTH EAST',
    extension_area_id: 262,
    extension_area_name: 'ZWENSHAMBE',
    village_id: 559,
    village_name: 'ZWENSHAMBE'
  },
  {
    district_id: 7,
    district_name: 'NGAMILAND (NORTH WEST)',
    sub_district_id: 21,
    sub_district_name: 'OKAVANGO',
    extension_area_id: 2,
    extension_area_name: 'BEETSHA',
    village_id: 560,
    village_name: 'GABORONE'
  },
  {
    district_id: 9,
    district_name: 'SOUTH EAST',
    sub_district_id: 25,
    sub_district_name: 'SOUTH EAST',
    extension_area_id: 267,
    extension_area_name: 'DIKGATLHONG',
    village_id: 562,
    village_name: 'RAMOTSWA'
  },
  {
    district_id: 10,
    district_name: 'SOUTHERN',
    sub_district_id: 18,
    sub_district_name: 'NGWAKETSE SOUTH (MMATHETHE)',
    extension_area_id: 126,
    extension_area_name: 'MMATHETHE SOUTH',
    village_id: 563,
    village_name: 'MACHANA'
  }
]
