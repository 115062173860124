import type React from 'react'
import { type Lease } from 'types'
import { Badge, Button, Card, DropdownMenu, DropdownMenuTrigger, Text } from 'components/ui'
import dayjs from 'dayjs'
import { MoreVertical } from 'lucide-react'
import { AcceptOrRejectLease } from './AcceptOrRejectLease'

interface LeaseOfferCardProps {
  lease: Lease
}

export const LeaseOfferCard: React.FC<LeaseOfferCardProps> = ({ lease }) => {
  // green for lease offer
  // red for termination
  const borderColor =
    lease.status_desc === 'INITIATED BY LESSOR' ? 'border-success' : 'border-danger'

  return (
    <Card
      className={`md:max-w-[500px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 transition-shadow duration-200 ease-in-out transform ${borderColor}`}
    >
      <Text
        className={'text-center'}
        size={'large'}
        variant={lease.status_desc === 'INITIATED BY LESSOR' ? 'success' : 'error'}
      >
        {lease.status_desc === 'INITIATED BY LESSOR' ? 'Lease Offer' : 'Lease Termination Request'}
      </Text>

      <div className={'w-full flex items-center justify-between gap-4'}>
        <div className={'flex items-center gap-4'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            From
          </Text>
          <Text className={'capitalize'} size={'medium'}>
            {lease.origin_farmer_name.toLowerCase()}
          </Text>
        </div>

        <div className={'flex relative'}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size={'icon'} variant='icon' className='h-8 w-8 p-0'>
                <span className='sr-only'>Open menu</span>
                <MoreVertical className='h-5 w-5' />
              </Button>
            </DropdownMenuTrigger>
            <AcceptOrRejectLease lease={lease} />
          </DropdownMenu>
        </div>
      </div>

      <div className={'flex items-center gap-4'}>
        <div className={'flex items-center gap-4'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            Farm Name
          </Text>
          <Text className={'capitalize'} size={'medium'}>
            {lease.farm_name.toLowerCase()}
          </Text>
        </div>
        <Badge className={'w-1/4 md:w-1/6 flex items-center justify-center'}>
          <Text className={'capitalize text-center text-white overflow-ellipsis'} size={'small'}>
            {lease.leased_size} ha
          </Text>
        </Badge>
      </div>

      <div className={'w-full flex items-center gap-4'}>
        <Text size={'small'} variant={'bodyTextLight'}>
          Village
        </Text>
        <Text className={'capitalize'} size={'medium'}>
          {lease.extension_area.toLowerCase() + ', ' + lease.village.toLowerCase()}
        </Text>
      </div>

      <div className={'bg-primary-background flex justify-between items-center rounded p-2'}>
        <div className={'flex flex-col'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            Lease from
          </Text>
          <Text>{dayjs(lease.commencement_date).format('DD-MM-YYYY')}</Text>
        </div>
        <div className={'flex flex-col'}>
          <Text size={'small'} variant={'bodyTextLight'}>
            Expires
          </Text>
          <Text>
            {/* If termination date is not empty use a default expiry date */}
            {lease.new_termination_date != null
              ? dayjs(lease.new_termination_date).format('DD-MM-YYYY')
              : dayjs(lease.expiry_date).format('DD-MM-YYYY')}
          </Text>
        </div>
      </div>
    </Card>
  )
}
