import { type ApiResponse, type FarmerVoucherTransaction } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

export const getTransactionHistory = async (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined,
  leaseId?: string | number | null | undefined
): Promise<ApiResponse<FarmerVoucherTransaction>> => {
  if (leaseId != null) {
    return axios.get(`/voucher/redemption?lease_id=${leaseId ?? ''}&farmer_id=${farmerId ?? ''}`)
  }
  return axios.get(`/voucher/redemption?farm_id=${farmId ?? ''}&farmer_id=${farmerId ?? ''}`)
}

export const useGetTransactionHistory = (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined,
  leaseId?: string | number | null | undefined
): UseQueryResult<ApiResponse<FarmerVoucherTransaction>, AxiosError> => {
  return useQuery({
    queryKey: [`history-${leaseId != null ? leaseId : farmId ?? ''}`],
    queryFn: async () => getTransactionHistory(farmerId, farmId, leaseId),
    enabled: (farmerId != null && farmId != null) || (farmerId != null && leaseId != null)
  })
}
