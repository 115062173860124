/* eslint-disable */
import bwFlag from 'assets/landing/logos/Flag-Botswana.svg'
import moalogo from 'assets/landing/logos/moalogo.svg'
import { useTheme } from 'next-themes'
import { LandingButton, LandingLayout } from 'features/landing/components/index'
import { useAuthState } from 'providers/auth.context'
import React, { useState } from 'react'
import {Button, Text} from 'components/ui'
import { Link, NavLink } from 'react-router-dom'

export const NavLandingPage = () => {
  const { user, role } = useAuthState()
  const [enabled, setEnabled] = useState(false)
  const { theme, setTheme } = useTheme()

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }
  const onNavigateToDashboard = (): string => {
    if (role === 1001) {
      return '/officer'
    } else if (role === 2000) {
      return '/lab'
    } else if (role === 1010) {
      return '/inventory'
    } else if (role === 1000) {
      return '/farmer'
    } else {
      return '/login/phone'
    }
  }

  const links = [
    { to: '/', title: 'Temo Letlotlo' },
    { to: '/user-manuals/', title: 'Crop Management system' },
    { to: '/contacts', title: 'Contacts' }
  ]

  return (
    <LandingLayout variant='body' className={'border-b border-border dark:shadow-[0px_5px_5px_0px_black] shadow-[0px_2px_5px_0px_grey]'}>
      <div className='bg-muted px-4 py-[4px]'>
        <LandingLayout className={'flex gap-4 items-center'}>
          <img src={bwFlag} alt='bwFlag' className='h-[15px] sm:h-[20px]' />
          <Text size='small'>An official website of the Botswana government</Text>
        </LandingLayout>
      </div>
      <LandingLayout variant='body' className={'bg-card'}>
        <LandingLayout
          className={
            'flex items-center justify-between py-2 md:py-4 px-4'
          }
        >
          <div className="flex gap-4 items-center">
            <Button
              variant={'ghost'}
              className="bg-transparent material-symbols-outlined md:hidden"
              onClick={() => setEnabled(!enabled)}
            >
              <Text
                size="large"
                className="material-symbols-outlined hover:text-primary"
              >
                menu
              </Text>
            </Button>
            <Link to={'/'}>
              <img className="w-[clamp(3rem,8vw,4rem)]" src={moalogo} alt={moalogo} />
            </Link>

            <Text size="small" className="font-semibold hidden sm:block">
              Ministry Of Agriculture
            </Text>
          </div>
          <div className="hidden md:flex gap-6 justify-between">
            {links.map((link, index) => (
              <NavLink
                to={link.to}
                className={({ isActive }) =>
                  isActive
                    ? 'font-medium text-primary-active capitalize'
                    : 'font-medium text-foreground hover:text-primary-hover capitalize'
                }
              >
                {link.title}
              </NavLink>
            ))}
          </div>
          <div className='flex gap-2'>
              <Button size={'icon'} variant={'ghost'} onClick={toggleTheme}>
                  <span
                    className={
                      theme === 'dark'
                        ? 'text-foreground fa-solid fa-moon'
                        : 'text-foreground fa-solid fa-sun'
                    }
                  ></span>
              </Button>

            <Link to={onNavigateToDashboard()}>
              <Button size={'sm'} className='px-4 py-2'>
                {user !== null && user !== undefined ? 'Dashboard' : 'Log In'}
              </Button>
            </Link>
            <div
              className={
                enabled
                  ? 'bg-card flex items-start p-8 flex-col absolute w-full h-screen z-[834789374857348] top-0 inset-x-0 transition-all duration-[0.3s] ease-[ease-in]'
                  : 'hidden h-0'
              }
             >
              <div className='w-full flex justify-end'>
                <Button variant={'ghost'} onClick={() => setEnabled(!enabled)}>
                  <Text
                    variant={'error'}
                    size='large'
                    className='material-symbols-outlined hover:text-destructive'
                  >
                    close
                  </Text>
                </Button>
              </div>

              <div className='w-full flex flex-col gap-2 items-center p-8 '>
                {links.map((link, index) => (
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      isActive
                        ? 'font-medium text-primary capitalize'
                        : 'font-medium text-foreground hover:text-primary-hover capitalize'
                    }
                  >
                    {link.title}
                  </NavLink>
                ))}
              </div>
              <div className='flex justify-center w-full gap-2'>
                <Link to={onNavigateToDashboard()} className='w-full max-w-[300px]'>
                  <LandingButton
                    // onClick={handleClickOpen}
                    variant='filled'
                    size='small'
                    className='w-full px-4 py-2'
                    title={user !== null && user !== undefined ? 'Dashboard' : 'Log In'}
                  />
                </Link>
              </div>
            </div>
          </div>
        </LandingLayout>
      </LandingLayout>
    </LandingLayout>
  )
}
