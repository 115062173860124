/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  CategoryLabel,
  FieldValue,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper'
import type React from 'react'
import { useState } from 'react'
import { LoanStepper } from './LoanStepper'
import { BasicButton } from '../../../../../components/Elements/Button'
import { useAtom } from 'jotai'
import moment from 'moment'
import { usePDF } from 'react-to-pdf'
import toast from 'react-hot-toast'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useGetIndividualLoan } from '../../../api/getIndividualLoan'
import { applicationHeader } from '../stores/applicationHeader'
import { Spinner } from '../../../../../components/Elements/Spinner'
import { type LoanStepperProps } from '../../../types'

export const ConfirmDetails: React.FC<LoanStepperProps> = ({
  steps,
  activeStep,
  onBack,
  onNext
}) => {
  const [loanId] = useAtom(applicationHeader)
  const { toPDF, targetRef } = usePDF({ filename: 'loan_details.pdf' })
  const { farmer } = useFarmerStore()
  const [pdfDownloaded, setPdfDownloaded] = useState(false)
  const {
    data: loanData,
    isLoading,
    error
  } = useGetIndividualLoan(
    Number(farmer?.farmer_id),
    loanId?.loan_application_id as unknown as number
  )

  const handleDownloadPdf = async (): Promise<void> => {
    await new Promise<void>((resolve) => {
      toPDF()
      resolve()
    })
    setPdfDownloaded(true)
    toast.success('Pdf is printing!! Upload it on the next page')
    onNext()
  }

  return (
    <LoanStepper
      steps={steps}
      activeStep={activeStep}
      next={onNext}
      back={onBack}
      stepperKey={'loan'}
    >
      <MainContainer>
        <h1>Please Confirm Your Details Are Correct</h1>
        {isLoading ? (
          <div className={'w-full grid place-items-center h-full flex-1'}>
            <Spinner size={'sm'} />
          </div>
        ) : (
          <div ref={targetRef}>
            <CategoryLabel label='Farmer Details' variant='secondary' />
            <RowContainer>
              {[
                {
                  label: 'Loan Application Id',
                  value: loanData?.data?.application_header?.loan_application_id
                },
                {
                  label: 'Fullnames',
                  value: `${loanData?.data?.application_header?.title as unknown as string} ${
                    loanData?.data?.application_header?.firstname as unknown as string
                  } ${loanData?.data?.application_header?.surname as unknown as string}`
                },
                { label: 'CMS Number', value: loanData?.data?.application_header?.farmer_id },
                { label: 'Farmer Omang', value: loanData?.data?.application_header?.omang }
              ].map((item, index) => (
                <FieldValue
                  key={index}
                  label={item.label}
                  value={item?.value as unknown as string}
                />
              ))}
            </RowContainer>
            <CategoryLabel label='loan details' variant='secondary' />
            <RowContainer>
              <div className='flex gap-2 flex-[1_0_200px]'>
                {loanData?.data?.cropping_plan?.map((id, index) => (
                  <FieldValue
                    key={index}
                    label={`Cropping Plan ${index + 1}`}
                    value={`Cropping Plan ID: ${id.cropping_plan_id}`}
                  />
                ))}
              </div>

              <FieldValue
                label={'loan amount(BWP)'}
                value={loanData?.data?.application_header.loan_requirement1 as unknown as string}
              />
            </RowContainer>

            <div className={'w-full '}>
              <CategoryLabel label='Loan Information' variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: 'Proposed repayment Period',
                    value: loanData?.data?.application_header?.loan_requirement2
                  },
                  {
                    label: 'Purpose of Loan',
                    value: loanData?.data?.application_header?.loan_requirement3
                  },
                  {
                    label: 'Project Directions',
                    value: loanData?.data?.application_header?.loan_requirement4
                  },
                  {
                    label: 'Period at Physical address',
                    value: `${
                      loanData?.data?.application_header?.period_at_physical_address as number
                    } Year(s)`
                  },
                  {
                    label: 'Period at previous physical address',
                    value: `${
                      loanData?.data?.application_header?.period_at_prev_physical_address as number
                    }
                      Year(s)`
                  },
                  {
                    label: 'Source Reference',
                    value: loanData?.data?.application_header?.source_reference
                  },
                  {
                    label: 'Ownership Status',
                    value: loanData?.data?.application_header?.ownership_at_physical_address
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Applicant Employmemt Information' variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: 'Applicant Employer',
                    value: loanData?.data?.applicant_employment_status?.employer
                  },
                  {
                    label: 'Employment Status',
                    value: loanData?.data?.applicant_employment_status?.employement_status
                  },
                  {
                    label: 'Job Title',
                    value: loanData?.data?.applicant_employment_status?.job_title
                  },
                  {
                    label: 'Employment Start Date',
                    value: moment(
                      loanData?.data?.applicant_employment_status?.employment_start_date
                    ).format('DD/MM/YYY')
                  },
                  {
                    label: 'Monthly Income',
                    value: loanData?.data?.applicant_employment_status?.monthly_income
                  },
                  {
                    label: 'Other Income Source',
                    value: loanData?.data?.applicant_employment_status?.other_income_source
                  },
                  {
                    label: 'Other Income',
                    value: loanData?.data?.applicant_employment_status?.other_income_amount
                  },
                  {
                    label: 'Number of permanent employees',
                    value: loanData?.data?.employee_information?.permenant_employee_number
                  },
                  {
                    label: 'Number of casual employees',
                    value: loanData?.data?.employee_information?.casual_employee_number
                  },
                  {
                    label: 'Previous year income',
                    value: loanData?.data?.employee_information?.previous_year_turnover
                  },
                  {
                    label: 'Farm Other Income',
                    value: loanData?.data?.employee_information?.other_income
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Marital Information' variant='secondary' />
              <RowContainer>
                {loanData?.data?.application_header?.marital_status === 'single' ||
                loanData?.data?.application_header?.marital_status === 'divorced' ||
                loanData?.data?.application_header?.marital_status === 'widowed' ? (
                  <FieldValue label='Marital Status' value='Single' />
                ) : (
                  [
                    {
                      label: 'Marital Status',
                      value: loanData?.data?.application_header?.marital_status
                    },
                    {
                      label: 'Spouse First Name',
                      value: loanData?.data?.spouse_details?.spouse_first_name
                    },
                    {
                      label: 'Spouse Middle Name',
                      value: loanData?.data?.spouse_details?.spouse_mid_name
                    },
                    {
                      label: 'Spouse last Name',
                      value: loanData?.data?.spouse_details?.spouse_last_name
                    },
                    { label: 'Omang', value: loanData?.data?.spouse_details?.spouse_id },
                    {
                      label: 'Physical  Address ',
                      value: loanData?.data?.spouse_details?.spouse_physical_address
                    },
                    {
                      label: 'Spouse Phone number ',
                      value: loanData?.data?.spouse_details?.spouse_phone
                    },
                    {
                      label: 'Monthly Income',
                      value: loanData?.data?.spouse_details?.spouse_income
                    },
                    {
                      label: 'Shareholder Value ',
                      value: loanData?.data?.spouse_details?.spouse_shareholding_value
                    },
                    { label: 'Email', value: loanData?.data?.spouse_details?.spouse_email },
                    // {
                    //   label: 'Spouse Employment status ',
                    //   value: loanData?.data?.spouse_details?.e
                    // },
                    {
                      label: 'Spouse Employer',
                      value: loanData?.data?.spouse_details?.spouse_employer
                    },
                    {
                      label: 'Spouse Job Title',
                      value: loanData?.data?.spouse_details?.spouse_job_title
                    },
                    {
                      label: 'Spouse Employment start date',
                      value: moment(
                        loanData?.data?.spouse_details?.spouse_employment_start_date
                      ).format('DD/MM/YYYY')
                    }
                  ].map((item, index) => (
                    <FieldValue
                      key={index}
                      label={item.label}
                      value={item?.value as unknown as string}
                    />
                  ))
                )}
              </RowContainer>
            </div>

            <div className={'w-full '}>
              <CategoryLabel label='Next Of Kin' variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: 'First Name',
                    value: loanData?.data?.next_of_kin?.kin_first_name
                  },
                  {
                    label: 'Last Name',
                    value: loanData?.data?.next_of_kin?.kin_last_name
                  },

                  {
                    label: 'Postal Address ',
                    value: loanData?.data?.next_of_kin?.kin_postal_address
                  },
                  {
                    label: 'Physical Address',
                    value: loanData?.data?.next_of_kin?.kin_physical_address
                  },
                  { label: 'Phone Number', value: loanData?.data?.next_of_kin?.kin_phone },
                  { label: 'Email', value: loanData?.data?.next_of_kin?.kin_email }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Commercial Bank' variant='secondary' />
              <RowContainer>
                {[
                  { label: 'Bank Name', value: loanData?.data?.bank_details?.bank_name },
                  {
                    label: 'Branch Name',
                    value: loanData?.data?.bank_details?.branch_name
                  },
                  {
                    label: 'Branch Location',
                    value: loanData?.data?.bank_details?.branch_location
                  },
                  {
                    label: 'Account Number ',
                    value: loanData?.data?.bank_details?.bank_account_number
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Other Loans' variant='secondary' />
              <RowContainer>
                {loanData?.data.loans_with_other_institutions_ndb?.map((loan, index) => (
                  <FieldValue
                    key={index}
                    label={`Loan ${index + 1}`}
                    value={`Reference Number: ${loan?.ref_no}, Duration: ${
                      loan?.loan_duration
                    }, Interest Rate: ${loan?.int_rate}, Approved Amount: ${
                      loan?.amount_approved
                    }, Outstanding Balance: ${loan?.outstanding_balance}, Installment: ${
                      loan?.installment
                    } Arrears: ${loan?.arrears}, Date Acquired: ${moment(
                      loan?.date_aqcuired
                    ).format('DD/MM/YYYY')}`}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='NDB Loans' variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: 'Do you have any existing loans with NDB?',
                    value: loanData?.data?.history_of_ndb_loans?.loan_exists
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Financial Particulars' variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: 'Criminal Record',
                    value: loanData?.data?.financial_particulars.fp_checklist1
                  },
                  {
                    label: 'Criminal Case',
                    value: loanData?.data?.financial_particulars.fp_checklist2
                  },
                  {
                    label: 'Blacklisted',
                    value: loanData?.data?.financial_particulars.fp_checklist3
                  },
                  {
                    label: 'Blacklisted Amount',
                    value: loanData?.data?.financial_particulars.fp_checklist4
                  },
                  {
                    label: 'Assurity Guarantee',
                    value: loanData?.data?.financial_particulars.fp_checklist5
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Loan Security' variant='secondary' />
              <h5>Immovable Property</h5>
              <RowContainer>
                {loanData?.data?.security_immovable_property.map((loan, index) => (
                  <FieldValue
                    key={index}
                    label={`Property ${index + 1}`}
                    value={`Age: ${loan?.age}, Original Cost: ${loan?.original_cost}, Market Value: ${loan?.market_value} , Estimated Force Sale Value : ${loan?.estimated_forces_sale_value} , Encumberances Amount : ${loan?.encumbrances_amount}`}
                  />
                ))}
              </RowContainer>
              <h5>Loan Machinery</h5>
              <RowContainer>
                {[
                  { label: 'Age', value: loanData?.data?.security_machinery?.age },
                  {
                    label: 'Original Cost',
                    value: loanData?.data?.security_machinery?.original_cost
                  },
                  {
                    label: 'Market Value',
                    value: loanData?.data?.security_machinery?.market_value
                  },
                  {
                    label: 'Estimated Forced Sale Value',
                    value: loanData?.data?.security_machinery?.estimated_forces_sale_value
                  },
                  {
                    label: 'Encumberances Amount',
                    value: loanData?.data?.security_machinery?.encumbrances_amount
                  }
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
              <h5>Loan Motor Vehicle</h5>
              <RowContainer>
                {[
                  {
                    label: 'Vehicle Id',
                    value: loanData?.data?.loan_security_vehicle?.vehicle_id
                  },
                  { label: 'Age', value: loanData?.data?.loan_security_vehicle?.age },
                  {
                    label: 'Original Cost',
                    value: loanData?.data?.loan_security_vehicle?.original_cost
                  },
                  {
                    label: 'Market Value',
                    value: loanData?.data?.loan_security_vehicle?.market_value
                  },
                  {
                    label: 'Estimated Forced Sale Value',
                    value: loanData?.data?.loan_security_vehicle?.estimated_forces_sale_value
                  },
                  {
                    label: 'Encumberances Amount',
                    value: loanData?.data?.loan_security_vehicle?.encumbrances_amount
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
              <RowContainer>
                {[
                  {
                    label: 'Loan Bank Gurantee Value',
                    value: loanData?.data?.security_bank_guarantee.guarantee_value
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}

                {[
                  {
                    label: 'Loan Other Gurantee Value',
                    value: loanData?.data?.security_other_guarantee.fixed_deposit_balance
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
                {[
                  {
                    label: 'Loan Sum Assured',
                    value: loanData?.data?.security_life_insurance.sum_assured
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
                {[
                  {
                    label: 'Fixed Deposit Balance',
                    value: loanData?.data?.security_other_guarantee.fixed_deposit_balance
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
                {[
                  {
                    label: 'Basis of Estimation',
                    value: loanData?.data?.security_basis_of_estimation.basis_of_estimation
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Production History' variant='secondary' />
              <RowContainer>
                {loanData?.data?.production_history.map((loan, index) => (
                  <FieldValue
                    key={index}
                    label={`Production ${index + 1}`}
                    value={`Crop Name: ${loan.crop_name}, Tonnage: ${
                      loan.tonnage
                    }, Production Year: ${moment(loan.production_year).format('YYYY')}`}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Summarized Business Information' variant='secondary' />
              <RowContainer>
                {[
                  {
                    label: 'Background Information',
                    value: loanData?.data?.summarized_business_information?.background_information
                  },
                  {
                    label: 'Process Information',
                    value: loanData?.data?.summarized_business_information?.process_information
                  },
                  {
                    label: 'Key Assumptions',
                    value: loanData?.data?.summarized_business_information?.key_assumption
                  },
                  {
                    label: 'Market Information',
                    value: loanData?.data?.summarized_business_information?.market_information
                  },
                  {
                    label: 'Competitors',
                    value: loanData?.data?.summarized_business_information?.competitors
                  },
                  {
                    label: 'Distribution',
                    value: loanData?.data?.summarized_business_information?.distribution
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <CategoryLabel label='Balance Sheets' variant='secondary' />
              <h5>General Information</h5>
              <RowContainer>
                {[
                  {
                    label: 'Marriage in community of property',
                    value:
                      loanData?.data?.application_header?.marital_status === 'married'
                        ? loanData?.data?.balance_sheet_general_information.marital_regime
                        : 'Not Applicable'
                  },
                  {
                    label: 'Balance Sheet',
                    value: moment(
                      loanData?.data?.balance_sheet_general_information.balance_sheet_date
                    ).format('DD/MM/YYYY')
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>

            <div className={'w-full '}>
              <h5>Immovable Assets</h5>
              <RowContainer>
                {loanData?.data?.balance_sheet_immovable_assets.map((loan, index) => (
                  <FieldValue
                    key={index}
                    label={`Asset ${index + 1}`}
                    value={`plot_number: ${loan?.plot_number},  property_location: ${loan?.property_location}, type_of_land: ${loan?.type_of_land} , property_size : ${loan?.property_size} , remaining_lease_period : ${loan?.remaining_lease_period} ,estimated_value: ${loan?.estimated_value}`}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <h5>Movable Assets</h5>
              <RowContainer>
                {[
                  {
                    label: 'Company Name',
                    value: loanData?.data?.balance_sheet_movable_assets?.company_name
                  },
                  {
                    label: 'Number of Shares',
                    value: loanData?.data?.balance_sheet_movable_assets?.number_of_shares
                  },
                  {
                    label: 'Estimated Share Value',
                    value: loanData?.data?.balance_sheet_movable_assets?.estimated_share_value
                  },
                  {
                    label: 'Total number of shares',
                    value: loanData?.data?.balance_sheet_movable_assets?.total_number_of_shares
                  },
                  {
                    label: 'Type of assets',
                    value: loanData?.data?.balance_sheet_movable_assets?.type_of_assets
                  },
                  {
                    label: 'Year of Acquisition',
                    value: moment(
                      loanData?.data?.balance_sheet_movable_assets?.year_of_acquisition
                    ).format('YYYY')
                  },
                  {
                    label: 'Net Book Value',
                    value: loanData?.data?.balance_sheet_movable_assets?.net_book_value
                  },
                  {
                    label: 'Estimated',
                    value: loanData?.data?.balance_sheet_movable_assets?.estimated_share_value
                  },
                  // {
                  //   label: 'Total Livestock Number',
                  //   value: loanData?.balance_sheets?.movable_assets?.livestock_number
                  // },

                  {
                    label: 'Recievables',
                    value: loanData?.data?.balance_sheet_movable_assets?.recievables
                  },
                  {
                    label: 'Total Cash',
                    value: loanData?.data?.balance_sheet_movable_assets?.total_cash
                  },
                  {
                    label: 'Fixed Deposits',
                    value: loanData?.data?.balance_sheet_movable_assets?.fixed_deposits
                  },
                  {
                    label: 'Other Assets',
                    value: loanData?.data?.balance_sheet_movable_assets?.other_assets
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
                {loanData?.data?.livestock_information.map((id, index) => (
                  <FieldValue
                    key={index}
                    label={`Livestock ${index + 1}`}
                    value={`Livestock Name: ${id.livestock_id}, Livestock Number: ${id.livestock_number}`}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full '}>
              <h5>Liabilities</h5>
              <RowContainer>
                {[
                  {
                    label: 'Mortgage Loans',
                    value: loanData?.data?.balance_sheet_liabilities[0]?.mortgage_loans
                  },
                  {
                    label: 'Hire Purchase Loans',
                    value: loanData?.data?.balance_sheet_liabilities[0]?.hire_purchase_loans
                  },
                  {
                    label: 'Tax Liabilities',
                    value: loanData?.data?.balance_sheet_liabilities[0]?.tax_liabilities
                  },
                  {
                    label: 'Personal Loans',
                    value: loanData?.data?.balance_sheet_liabilities[0]?.personal_loans
                  },
                  {
                    label: 'Creditors',
                    value: loanData?.data?.balance_sheet_liabilities[0]?.creditors
                  },
                  {
                    label: 'Other Liabilities',
                    value: loanData?.data?.balance_sheet_liabilities[0]?.other_liabilities
                  }
                  // ... add data items here ...
                ].map((item, index) => (
                  <FieldValue
                    key={index}
                    label={item.label}
                    value={item?.value as unknown as string}
                  />
                ))}
              </RowContainer>
            </div>
            <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
              <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
              <BasicButton
                label={'Next'}
                variant={'primary'}
                onClick={pdfDownloaded ? onNext : handleDownloadPdf}
                size={'xs'}
                // disabled={!pdfDownloaded && activeStep !== steps.length - 1}
              />
            </div>
          </div>
        )}
      </MainContainer>
    </LoanStepper>
  )
}
