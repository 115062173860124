/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { LandingButton, LandingLayout, Text } from 'features/landing/components/index'
// mui
import { Tab, Tabs } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { type TransitionProps } from '@mui/material/transitions'
// img
import table5 from 'assets/landing/contentImage/table5.png'
import table6 from 'assets/landing/contentImage/table6.png'
import table9 from 'assets/landing/contentImage/table9.png'
import table10 from 'assets/landing/contentImage/table10.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface TabInfo {
  label: string
  content: React.ReactNode
  moreInformation: React.ReactNode
  popupTitle: string
}

const tabsInfo: TabInfo[] = [
  {
    label: 'Seasonal Loan',
    content: (
      <div className='flex flex-col gap-1'>
        <Text>
          This is a secured loan offered at a prime rate up to a maximum of P8,300.00/ha to business
          focused grain producing farmers. The loan will be used to provide working capital for
          farmers including procurement of inputs, farm production services and purchasing of
          Weather based Agricultural Credit Guarantee Scheme (ACGS).
        </Text>
      </div>
    ),
    moreInformation: (
      <div className='flex flex-col w-full items-start gap-4 sm:gap-8 my-2'>
        <div>
          <Text variant='primary' className='flex items-center gap-2'>
            <Text className='text-[var(--color-success)]'>
              <p className='fa-solid fa-leaf' />
            </Text>
            Loan Limit
          </Text>
          <Text>
            The maximum loan limit for each eligible farmer under the programme, will be decided
            based on the farmer’s Cropping Plan and validated land size.
          </Text>
          {/* // */}
          <Text variant='primary' className='flex items-center gap-2 mt-4 '>
            <Text className='text-[var(--color-success)]'>
              <p className='fa-solid fa-leaf' />
            </Text>
            Interest Rate
          </Text>
          <Text>The loan will be administered at prime rate (floating prime rate).</Text>

          {/*  */}
          <Text variant='primary' className='flex items-center gap-2 mt-4 '>
            <Text className='text-[var(--color-success)]'>
              <p className='fa-solid fa-leaf' />
            </Text>
            Tenor
          </Text>
          <Text>
            {' '}
            The tenor of loans under the programme will be based on the gestation of the targeted
            commodity and shall not exceed 12 months.
          </Text>
          {/*  */}
          <Text variant='primary' className='flex items-center gap-2 mt-4'>
            <Text className='text-[var(--color-success)]'>
              <p className='fa-solid fa-leaf' />
            </Text>
            Repayment
          </Text>
          <Text>
            The loans granted under the programme shall be fully repaid within the tenor of the
            loan. Where the loan was accessed through a Commodity Association, the leadership of the
            Association shall be responsible for full repayment of loan granted to its members.
          </Text>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Eligibility Conditions
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Open to individuals who are citizens of Botswana aged 18 years and above.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              100% Batswana owned registered companies.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>{' '}
              A cluster registered as Agricultural Management Association or a Cooperative
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Entity registered in the Crop Management System.{' '}
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              If you are a director of a company or member of a registered cluster who benefited
              from the programme you cannot benefit as individual.
            </Text>
            <Text className='flex items-start gap-2'>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Owner or Tenant of a farm (In case of Lease Agreement, the lease period should not be
              less than 5 years)
            </Text>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Obligations/Requirements
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Lease Agreement validated in the Crop Management System
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Soil Test Result from an accredited Laboratory
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>{' '}
              All fields may be audited.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Must provide the previous year’s production information vetted by the District Crop
              Production Officer. The farmer/cluster should have uploaded the production information
              into the Crop Management System.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Registered for Tax (TIN). Where farmers have not registered for tax (TIN) they should
              undertake to do so in 3 months from date of loan agreement.
            </Text>
            <Text className='flex items-start gap-2'>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Farmers operating as companies should be registered with CIPA.
            </Text>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Insurance
            </Text>
            <Text>Mandatory weather based ACGS</Text>
          </div>
        </div>
      </div>
    ),
    popupTitle: 'Seasonal Loan'
  },
  {
    label: '100% Subsidy',
    content: (
      <div className='flex flex-col gap-1'>
        <Text>
          The programme will give a coupon to Micro Scale farmers to enable them to purchase
          Fertilizer, Seeds and tillage services for a maximum of 4ha. The Coupon is valued at
          P5,500.00/ha. In the context of Temo Letlotlo programme, Micro Scale farmers are farming
          population that has no wage or are earning minimum wage.
        </Text>
      </div>
    ),
    moreInformation: (
      <div className='flex flex-col w-full items-start gap-4 sm:gap-8 my-2'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Temo Letlotlo Wallet
            </Text>
            <Text>
              The funds for 100% subsidy will be disbursed through a Temo Letlotlo wallet/ voucher
              developed and managed by BIUST and NDB. The service will facilitate the micro-scale
              segment to transact with various services providers, in line with prescribed limits as
              per the farmer’s cropping plan. The farmer will be able to pay the service provider in
              real time. This also allows for real-time monitoring and evaluation of input
              distribution to farmers.
            </Text>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Micro Scale Level 1: Obligations/Requirements:
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Identified through the Ministry of Local Government and Rural Development Database in
              collaboration with MoA.{' '}
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Farmers are allowed to use borrowed land.
            </Text>
            <Text size='small' className='flex items-start gap-2 ml-4'>
              <Text size='small'>
                <p className='fa-solid fa-minus' />
              </Text>{' '}
              Village Leadership (Kgosi, Councillor, VDC, Village Extension Team, Farmers committee)
              to facilitate borrowing of land.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Not expected to contribute a bag/ha to the Strategic Grain Reserve (Tlatsa
              difalana-Tema ke Kgetsi).
            </Text>
          </div>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Micro Scale Level 2: Obligations/Requirements:
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Resource-poor individuals aged 18 years and above with monthly income up to P2,500.00.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Farmers are expected to contribute a bag/ha to the Strategic Grain Reserve (Tlatsa
              difalana-Tema ke Kgetsi).
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              To benefit, the farmer is not required to own land, they may borrow.
            </Text>
          </div>
        </div>
      </div>
    ),
    popupTitle: '100% Subsidy'
  },
  {
    label: 'Mmoko Reward Packages',
    content: (
      <div className='flex flex-col gap-1'>
        <Text>
          Mmoko Reward Package is the performance-based reward given to farmers in recognition for
          their productivity and quality of produce. It is a reward given to farmers who meet the
          yield target and quality of the priority crops. Grading of crops will be done by Extension
          Officers at harvesting and those who meet yield target and has Grade 1 grain will be given
          100% of the reward, while grade 2 will get 75% of the value of the reward.
          <br />
          <br />
          The reward package is in the form of Reward Voucher items contextualised to the specific
          needs of farmers. The incentive will be applicable only if the farmer/cluster has met
          their yearly yield targets, which varies depending on the crop planted and the farmer
          category.
          <br />
          <br />
          The Reward Voucher items for clusters will only be issued once the cluster signs the
          Service Level Agreement (SLA) with Government. The SLA is meant to enhance farmers
          commitment on their obligation. Failure to honour the SLA gives Government the right to
          lease the land to interested party for the remaining duration of the SLA.
        </Text>
      </div>
    ),
    moreInformation: (
      <div className='flex flex-col w-full items-start gap-4 sm:gap-8 my-2'>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Text>
              The Reward Voucher items for clusters will only be issued when the cluster signs the
              Service Level Agreement (SLA) with the Government. The SLA is meant to enhance
              farmer’s commitment to their obligation. Failure to honour the SLA gives the
              Government the right to lease the land to the interested party for the remaining
              duration.
            </Text>
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Eligibility Conditions
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              All farmer except Micro Scale
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              100% Batswana owned Registered Company in the business of commercial grain production.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Entity should have registered in the Crop Management System
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Grade 1 grain meeting the annual yield target (Quality will be determined through
              grading by Extension Officer and validated by Agronomist).
            </Text>
          </div>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Obligations/Requirements:
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Upload production information into the system
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Fields inside Production clusters do not qualify for reward packages as individuals,
              they are treated as an entity.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              All fields financed through the programme will be subjected to a random audit.
            </Text>
          </div>
        </div>
        <div className='w-full flex flex-col gap-2'>
          <Text size='small' variant='primary'>
            Mmoko Reward Packages for Individual Farmers
          </Text>
          <img className='w-full' src={table5} alt='Mmoko Reward Packages for Individual Farmers' />
          <Text variant='primary'>
            NB: Micro Scale farmers do not qualify for Mmoko Reward package.
          </Text>
        </div>
        <div className='w-full flex flex-col gap-2'>
          <Text size='small' variant='primary'>
            Mmoko Reward Packages for Clusters
          </Text>
          <img className='w-full' src={table6} alt='Mmoko Reward Packages for Clusters' />
        </div>
      </div>
    ),
    popupTitle: 'Mmoko Reward Packages'
  },
  {
    label: 'Fencing',
    content: (
      <div className='flex flex-col gap-1'>
        <Text>
          The introduction of compulsory insurance in the programme dictates that all fields should
          be protected to guard against moral hazard. Temo Letlotlo has a dispensation to enable
          farmers to fence their fields and protect their crops from animal damage.{' '}
        </Text>
      </div>
    ),
    moreInformation: (
      <div className='flex flex-col w-full items-start gap-4 sm:gap-8 my-2'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Individual Fencing
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              An individual shall be subsidized up to a maximum of 16ha.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A beneficiary is entitled to 50% subsidy on the cost of fencing (material and labour)
              up to a maximum of P150, 000.00 and the 50% Government contribution shall not exceed
              P75, 000.00.{' '}
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Applicants should have access to land (proof of ownership or lease). In case of lease,
              the lease period should not be less than 10 years.{' '}
            </Text>

            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              The farmer is required to apply for fencing through the Crop Management System.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              The farmer is required to apply for fencing through the Crop Management System.
            </Text>
            <Text size='small'>
              NB: Farmers who cannot raise the 50% have an option of contributing by erecting
              traditional poles and clearing the perimeter fence area in lieu of the 50%.{' '}
            </Text>
          </div>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Group Fencing
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A group of farmers who collectively own up to a maximum of 499 Ha will be assisted
              with 50% subsidy on the cost of fencing (material and labour) up to a maximum of
              P4,678,000.00 to construct enclosure fence and the 50% Government contribution shall
              not exceed P2,339,063.00.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A group should have a minimum membership of two (2) who have arable land in proximity
              and shall have a Memorandum of understanding or working constitution.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              he group fence extends to use of electric fence for farmers in areas prone to
              Elephants (This will be done in partnership with the Ministry of Environment and
              Tourism’s Human Wildlife Conflict Fencing Initiative).
            </Text>
          </div>
          <div className='flex flex-col gap-1'>
            <Text variant='primaryDark' size='medium'>
              Cluster Fencing
            </Text>
            <Text>
              A group of at least 10 farmers with interconnected arable production activities
              operating as an Agricultural Management Association or Agricultural Cooperative can
              also apply for the fence.{' '}
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Clusters size range from 500ha up to 12,000ha.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Cluster fence extends to the use of electric fence for farmers in areas prone to
              Elephants (This will be done in partnership with Ministry of Environment and Tourism’s
              Human-Wildlife Conflict Fencing Initiative)
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A Cluster is entitled to 100% subsidy on the cost of fencing (material and labour) up
              to a maximum of P112,000,000.00.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Small Scale Cluster (500ha-2,000ha) will be assisted with 100% subsidy on the cost of
              fencing (material and labour) up to a maximum of P1,700,000.00 to construct enclosure
              fence.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Medium Scale Clusters (2,001ha-6,000ha) will be assisted with 100% subsidy on the cost
              of fencing (material and labor) up to a maximum of P2,950,000.00 to construct
              enclosure fence.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Large Scale Cluster (6,0001ha-12,000ha) will be assisted with 100% subsidy on the cost
              of fencing (material and labour) up to a maximum of P4,200,000.00 to construct
              enclosure fence.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Feasibility studies should be undertaken to confirm suitability of the proposed area
              and the alignment of the perimeter fence.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              To qualify for the 100% subsidy on fencing, 50% of the clustered area should be
              Debushed.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Care and maintenance of the fence is the responsibility of the cluster.
            </Text>
          </div>
          <div className='w-full flex flex-col gap-2'>
            <Text size='small' variant='primary'>
              Summary of Distribution of Extension Officers
            </Text>
            <img
              className='w-full'
              src={table9}
              alt='Mmoko Reward Packages for Individual Farmers'
            />
          </div>
        </div>
      </div>
    ),
    popupTitle: 'Fencing'
  },
  {
    label: 'Tractor And Implement Scheme',
    content: (
      <div className='flex flex-col gap-1'>
        <Text>
          The scheme is aimed at helping farmers access financing for the acquisition of a tractor
          and implements. The country currently has 4,500 tractors of various conditions and horse
          powers.
        </Text>
      </div>
    ),
    moreInformation: (
      <div className='flex flex-col w-full items-start gap-4 sm:gap-8 my-2'>
        <div className='flex flex-col gap-4'>
          <Text>
            More than half of these tractors are poorly maintained and serviced and experience
            frequent problems with hydraulics, power loss, engine oil leakage, front wheel bearings
            and the electric system (Patrick, Tapela, & Baryeh, 2002). Under ideal environment, a
            tractor is expected to plough-plant (ploughing, harrowing, Planting) 3ha per day. As a
            result of inefficiencies which include the condition of our tractors, poor management of
            operations, our current tractor fleet plough-plant an average of 1.5ha per day. This
            means an average tractor plough-plant 67.5ha per 45-day ploughing-planting window. This
            means our current population of tractors is capable of plough-planting 303,750ha per
            cropping season, under ideal environment.
            <br />
            <br />
            The package also finances procurement of minimum tillage equipment which is anticipated
            to promote use of minimum tillage and move away from conventional tillage which is not
            climate smart friendly. The subsidy covers minimum tillage (for ripping, harrowing and
            weeding), Planting, weed control, pest control, harvesting and processing equipment. It
            also caters for procurement of a tractor trailer for ease of carrying equipment. is to
            procure an initial 200 tractors during the 2023/24 cropping season at a total cost of
            P150,000,000.00. The target group is individual farmers, farmer groups, and
            clusters/companies in the business of service centres. The programme will not subsidize
            procurement of used tractors.
            <br />
            <br />
            During the 2024/25 and 2025/26 cropping season 400 tractors will be procured yearly.
            During the 2023/24 cropping season tractors will be bought from open market, while in
            subsequent years the programme will localize tractor assembly in Botswana which has
            evidence of farmer friendly after sale support.
          </Text>
        </div>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Tractor Operations and Maintenance
            </Text>
            <Text>
              All tractor operators transacting under Temo Letlotlo Programme including those
              applying for Tractor and Implement Scheme are required to have Tractor operations and
              Maintenance certification from Botswana University of Agriculture and Natural
              Resources. This upskilling will improve the care and maintenance of tractors thereby
              increasing efficiency of farm operations.
            </Text>{' '}
          </div>
          {/*  */}
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Individual tractor and Implement ownership.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A farmer is entitled to pay 50% of the value of the tractor/tractor combo/implements
              up to a maximum of P500,000.00. The 50% Government contribution should not exceed
              P250, 000.00 for an individual farmer.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A farmer cannot acquire a tractor without implements and those with existing tractors
              are allowed to procure implements provided there is an assessment done on the
              condition of the implements conducted by a qualified technician/Mechanical Engineer{' '}
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A farmer with tractor implements, qualifies to procure a tractor provided there is an
              assessment done on the condition of the tractor conducted by a qualified
              technician/Mechanical Engineer. The Scheme will be administered by National
              Development Bank.
            </Text>
            <Text size='small' variant='primary'>
              NB: A farmer can only benefit once in this tractor subsidy package and tractor or
              implements cannot be sold for 5 years.
            </Text>
            {/*  */}
            <Text variant='primaryDark' size='medium'>
              Eligibility
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              An individual who is a citizen of Botswana aged 18 years and above.
            </Text>{' '}
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              An Individual registered in the Crop Management System as a farmer.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Owner or a tenant of a farm (in case of lease agreement, the lease period should be at
              least 10 years and validated through Crop Management System).
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              The farm owned or leased should be at least 8.1ha for you to qualify for the scheme.
            </Text>
            {/*  */}
            <Text variant='primaryDark' size='medium'>
              Obligations
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              The farmer is required to apply for Tractor and Implement Scheme through the Crop
              Management System.
            </Text>{' '}
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              The farmer is required to fully pay 50% contribution or greater and submit proof of
              payment upon approval of his/her application.
            </Text>
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Group Tractor and Implement Ownership
            </Text>
            <Text>
              Farmers are allowed to group themselves to benefit from this scheme under the same
              conditions as outlined for individual farmers, but they should have a legally binding
              Memorandum of Agreement. The group have an option of Co-owning tractor or implements
              or a combo (both tractor and implements).
            </Text>
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Service Centre Model Ownership
            </Text>
            <Text>
              A 100% citizen owned company providing grain production services to clusters under
              legally binding contracts can apply for a loan at prime rate at National Development
              Bank to procure machineries and equipment required to offer services to farmers. The
              government will provide 50% guarantee to NDB to manage risk associated with the new
              business activity.
            </Text>
            <div className='w-full flex flex-col gap-2'>
              <Text size='small' variant='primary'>
                Tractor and Implement Guide and Prize Capping
              </Text>
              <img className='w-full' src={table10} alt='Mmoko Reward Packages for Clusters' />
            </div>
          </div>
        </div>
      </div>
    ),
    popupTitle: 'Tractor And Implement Scheme'
  },
  {
    label: 'Interest Subsity Facility',
    content: (
      <div className='flex flex-col gap-1'>
        <Text>
          The Temo Letlotlo Interest Subsidy Facility will support the wider program by extending
          the range of farmer categories that may benefit from the program. Under the Facility,
          farmers that are excluded under Temo Letlotlo such as those cultivating more than 500 ha
          and non-citizen commercial farmers will be eligible for subsidized NDB loans under the
          Facility.
        </Text>
      </div>
    ),
    moreInformation: (
      <div className='flex flex-col w-full items-start gap-4 sm:gap-8 my-2'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              The Facility
            </Text>
            <Text>
              The loans under the Temo Letlotlo Interest Subsidy Facility shall be granted for a
              period of up to 12 (twelve) months for arable farming at an interest rate to be
              determined by the Bank from time to time and communicated to the Government of
              Botswana in writing. The interest margin is currently set as 8% (eight percent) and is
              subject to review from time to time.
            </Text>{' '}
          </div>
          {/*  */}
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Eligibility
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Must be a farmer registered in the Crop Management System
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Normal NDB Credit Requirements Apply
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Covers farmers producing one or more of the grain crops identified under Temo
              Letlotlo.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              A customer may only benefit from both a Temo Letlotlo Loan and Interest Subsidy
              Facility at the same time for different ploughing fields
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              May be a citizen, non-citizen, or company
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>{' '}
              Citizens and citizen companies cultivating above 500 ha
            </Text>{' '}
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Non-citizens and non-citizen companies cultivating more than 150 ha (i.e. commercial
              farmers as per Temo Letlotlo definition)
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Pricing at Prime +8{' '}
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Customer Charged Prime Rate
            </Text>
            {/*  */}
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Requirements
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Applicants must be 18 (eighteen) years and above with Omang or residence and work
              permit.
            </Text>{' '}
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Applicants must produce proof of access to land (dully allocated or leased).
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Applicants must own or have access to farm implements and machinery (tractor, planter,
              cultivator, etc) in good working condition.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Applicants will be required to keep farming records accessible to both Agricultural
              Extension Staff and NDB.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              The repayment period is up to 12 (twelve) months and the farmer has the option to
              repay using other sources of income. No penalty will be charged on early repayment.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              There is an option to apply for another loan within the same season, but it will be
              due and payable within the same season.
            </Text>
            {/*  */}
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Scope of the facility
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Seeds/Seedlings
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Fertilizers
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Fuel
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Pesticides
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Repairs and maintenance of farm machinery, irrigation equipment and implements
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Labour costs for planting, weeding, bird scaring, harvesting, threshing, spraying,
              pruning (fruit production) and packaging
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Transportation costs to the market
            </Text>
          </div>

          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Documentation required{' '}
            </Text>

            {/*  */}

            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Farmers will complete application forms available through NDB.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Certified true copy of Identity Documents.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Proof of accessibility to land (e.g., Land Board field certificate, Lease Agreement,
              or Letter of approval to use land from the Landowner).
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Quotations for items required.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>{' '}
              A crop production schedule showing past hectarage planted and yield, types and
              schedule of crops to be planted, and expected total hectarage to be planted
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Current pay slip where farmer wants to pay through monthly deductions from salary
              (list of required supporting documents is enclosed on application forms and will be as
              per advice from the Bank officials, during submission and assessment of loan proposal)
            </Text>
            {/*  */}
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Farmer’s Contract
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Offer letter
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Standard terms and loan conditions
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Deed of Cession over farm produce
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Certificate of Indebtedness
            </Text>
          </div>
          {/*  */}
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Disbursement
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Electronic Funds transfer into the farmer’s account.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Issue Cheque
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Or disburse directly to the Suppliers if need be.
            </Text>
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Obligations to Government of Botswana
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              Undertakes to make payment annually in April or within 30 (thirty) days of receipt of
              an invoice from the Bank indicating an amount paid above prime rate from time to time
              subject to clause 2.2 above.
            </Text>
            <Text className='flex items-start gap-2 '>
              <Text className='text-[var(--color-success)]'>
                <p className='fa-solid fa-leaf' />
              </Text>
              The deadline/latest payment date is the 30th September of every year. Penalty interest
              is charged at 5% (five percent) per annum on late payment.
            </Text>
          </div>
          {/*  */}
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Approval & Disbursement
            </Text>
            <Text>
              The Bank shall decide whether to approve or reject the loan application and what
              security and other loan conditions shall apply to the granting of the loan, as usual
              and subject the approved loans to the reduction in interest rates.
            </Text>
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Credit Management
            </Text>
            <Text>The Bank shall be responsible for the credit management of the Facility. </Text>
          </div>
        </div>
      </div>
    ),
    popupTitle: 'Interest Subsity Facility'
  },
  {
    label: 'Crop Insurance',
    content: (
      <div className='flex flex-col gap-1'>
        <Text>
          Farmers and citizen registered companies taking seasonal loans through Temo Letlotlo will
          be required to purchase the weather based Agricultural Credit Guarantee Scheme (ACGS
          –referred to as the Scheme). The purpose of the weather based ACGS is to assist rain-fed
          farmers to repay part of their loans with National Development Bank (NDB) or any other
          financial institution approved by Government, in case of crop loss due to drought (total
          rainfall below a threshold value, consecutive dry days or spells), high temperatures,
          floods (excess rainfall), frost (low temperatures), hailstorm and crop destruction due to
          animals and wildlife.
        </Text>
      </div>
    ),
    moreInformation: (
      <div className='flex flex-col w-full items-start gap-4 sm:gap-8 my-2'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Seasonal inputs
            </Text>
            <Text>
              fertilizers, seeds, crop production fuel, insecticides, pesticides, and other
              agro-chemicals used to protect crops, workers’ wages and salaries, packaging
              materials, crop transport costs and other similar working capital requirements to
              produce the crops.
            </Text>{' '}
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Farm or field development
            </Text>
            <Text>
              destumping, de-bushing or land clearing, labour for arable rainfed crop operations,
              contract ploughing and planting, fencing of farms or fields, draining expenses, land
              ripping and related operations.
            </Text>{' '}
          </div>
          <div className='flex flex-col gap-2'>
            <Text variant='primaryDark' size='medium'>
              Farm machinery and equipment
            </Text>
            <Text>
              tractors, planters, cultivators, harrows, ploughs, crop spraying equipment, and
              borehole equipment
            </Text>{' '}
          </div>
        </div>
      </div>
    ),
    popupTitle: 'Interest Subsity Facility'
  }
]
const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Text>{children}</Text>
        </div>
      )}
    </div>
  )
}

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
//

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const Guidelines: React.FC = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  return (
    <LandingLayout variant='body' className='bg-[white]'>
      <LandingLayout className='w-full flex flex-col gap-4 px-4 sm:px-8 lg:px-12 py-12 md:py-18'>
        <div className='flex gap-4'>
          <Text size='xLarge' variant='primaryDark' className='fa-solid fa-bullseye' />
          <Text size='xLarge' variant='primaryDark'>
            Guidelines
          </Text>
        </div>
        <div className='hidden sm:flex bg-[white]'>
          <div className='flex'>
            <Tabs
              value={value}
              orientation='vertical'
              onChange={handleChange}
              variant='scrollable'
              aria-label='scrollable'
              sx={{
                minWidth: '200px',
                margin: 0,
                borderRight: 2,
                borderColor: 'divider',
                padding: 0
              }}
            >
              {tabsInfo.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: '1rem',
                    textTransform: 'capitalize',
                    bgcolor: 'var(--color-background)',
                    color: '#1d1d1f'
                  }}
                />
              ))}
            </Tabs>
          </div>
          <div className='w-full h-full p-4 sm:p-8 flex flex-wrap gap-4'>
            {tabsInfo.map((tab, index) => (
              <CustomTabPanel key={index} value={value} index={index}>
                {tab.content}
                <div className='w-full flex justify-end mt-8'>
                  <LandingButton
                    variant='filled'
                    size='small'
                    className='px-4 py-2'
                    title='Discover More'
                    onClick={handleClickOpen}
                    icon1={<p className='material-symbols-outlined'>open_in_new</p>}
                  />
                </div>
              </CustomTabPanel>
            ))}
          </div>
        </div>
        <div className='sm:hidden flex flex-col'>
          <div className='flex '>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons
              allowScrollButtonsMobile
              aria-label='scrollable'
              sx={{
                MaxWidth: '100%',
                bgcolor: 'var(--color-primary)',
                margin: 0,
                borderBottom: 1,
                borderColor: 'divider',
                padding: 0
              }}
            >
              {tabsInfo.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  {...a11yProps(index)}
                  sx={{
                    textTransform: 'capitalize',
                    bgcolor: '#ffffff',
                    color: '#1d1d1f'
                  }}
                />
              ))}
            </Tabs>
          </div>
          <div className='w-full h-full p-4 sm:p-8 flex gap-4'>
            {tabsInfo.map((tab, index) => (
              <CustomTabPanel key={index} value={value} index={index}>
                {tab.content}
                <div className='w-full flex justify-end mt-8'>
                  <LandingButton
                    variant='filled'
                    size='small'
                    className='px-4 py-2'
                    title='Discover More'
                    onClick={handleClickOpen}
                    icon1={<p className='material-symbols-outlined'>open_in_new</p>}
                  />
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                  keepMounted
                >
                  <DialogTitle
                    id='scroll-dialog-title'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      margin: 0,
                      borderBottom: 2,
                      borderColor: 'divider',
                      padding: 1,
                      paddingLeft: 2
                    }}
                  >
                    <Text size='medium'>{tab.popupTitle}</Text>
                    <Text size='small' variant='primary'>
                      continuing...
                    </Text>
                  </DialogTitle>

                  <DialogContent>
                    <div className='w-full flex flex-col justify-center'>{tab.moreInformation}</div>
                  </DialogContent>
                </Dialog>
              </CustomTabPanel>
            ))}
          </div>
        </div>
      </LandingLayout>
    </LandingLayout>
  )
}
