import type React from 'react'
import { useState } from 'react'
import { InputAdornment, MenuItem, TextField } from '@mui/material'
import { useAtomValue, useSetAtom } from 'jotai'
import { newFarmAtom } from '../../../stores/newFarm'
import { Crop, Person, Tag } from '@mui/icons-material'
import { BasicButton } from 'components/Elements/Button'
import { locations } from 'features/farmer/land-management/utils/locations'
import { useGetOfficer } from 'features/farmer/land-management/api/getOfficer'
import { Spinner } from 'components/Elements/Spinner'
import { apiLocationAtom } from 'features/farmer/land-management/stores/apiLocationInfo'
import { LocationSelect } from '../../generic/LocationSelect'
import { number, object, type TypeOf } from 'zod'
import { Form } from 'components/Form'
import { type SubmitHandler } from 'react-hook-form'

interface DeedReviewProps {
  className: string
  onBack: () => void
  onNext: () => void
}

const schema = object({
  district: number(),
  subDistrict: number(),
  extension: number(),
  village: number()
})
type FarmLocationInput = TypeOf<typeof schema>

export const DeedReview: React.FC<DeedReviewProps> = ({ className, onNext, onBack }) => {
  const newFarmDetails = useAtomValue(newFarmAtom)
  const apiLocationInfo = useAtomValue(apiLocationAtom)
  const [district, setDistrict] = useState<number | string>('')
  const [subDistrict, setSubDistrict] = useState<number | string>('')
  const [extensionArea, setExtensionArea] = useState<number | string>('')
  const [village, setVillage] = useState<number | string>('')
  const setFarmDetails = useSetAtom(newFarmAtom)

  const onSubmit: SubmitHandler<FarmLocationInput> = (data: FarmLocationInput) => {
    setFarmDetails((prev) => {
      return {
        ...prev,
        districtId: data.district,
        extensionAreaId: data.extension,
        villageId: data.village,
        subDistrictId: data.subDistrict
      }
    })
    onNext()
  }

  const locationInfo = locations.filter((location) => {
    return (
      location.extension_area_id === newFarmDetails.extensionAreaId &&
      location.district_id === newFarmDetails.districtId &&
      location.sub_district_id === newFarmDetails.subDistrictId &&
      location.extension_area_id === newFarmDetails.extensionAreaId
    )
  })

  const { data, isLoading } = useGetOfficer(locationInfo[0]?.extension_area_id)

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full'}>
        <Spinner size={'sm'} />
      </div>
    )
  }

  return (
    <div className={className}>
      <Form<FarmLocationInput, typeof schema>
        schema={schema}
        onSubmit={onSubmit}
        className={className}
      >
        {({ control, formState: { errors }, handleSubmit }) => (
          <>
            <h3>Add Your Accurate Area Details</h3>

            <div className={'flex flex-col md:flex-row gap-4 md:gap-6 lg:gap-12'}>
              <TextField
                variant={'outlined'}
                label={'Plot No'}
                disabled={true}
                value={newFarmDetails.plotNo}
                size={'small'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Tag />
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                variant={'outlined'}
                label={'Size'}
                disabled={true}
                value={newFarmDetails.size}
                size={'small'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Crop />
                    </InputAdornment>
                  )
                }}
              />
            </div>
            <div className={'flex flex-col md:flex-row gap-4 md:gap-6 lg:gap-12'}>
              <LocationSelect
                onSelect={(event) => {
                  setDistrict(event.target.value as unknown as number)
                  setSubDistrict('')
                  setExtensionArea('')
                  setVillage('')
                }}
                control={control}
                name={'district'}
                label={'District'}
                value={district}
                error={!(errors.district == null)}
                helperText={errors?.district?.message ?? ''}
              >
                {apiLocationInfo
                  .filter(
                    (location, index, self) =>
                      self.findIndex((l) => l.districtId === location.districtId) === index
                  ) // Filter unique districtIds
                  .map((disc) => (
                    <MenuItem key={disc.districtId} value={disc.districtId}>
                      {disc.districtName}
                    </MenuItem>
                  ))}
              </LocationSelect>

              <LocationSelect
                label={'Sub District'}
                control={control}
                onSelect={(event) => {
                  setSubDistrict(event.target.value as unknown as number)
                  setExtensionArea('')
                  setVillage('')
                }}
                disabled={district === ''}
                name={'subDistrict'}
                value={subDistrict}
                error={!(errors.subDistrict == null)}
                helperText={errors?.subDistrict?.message ?? ''}
              >
                {apiLocationInfo
                  .filter((location) => location.districtId === district)
                  .filter(
                    (location, index, self) =>
                      self.findIndex((l) => l.subDistrictId === location.subDistrictId) === index
                  ) // Filter unique subDistrictIds
                  .map((sub) => (
                    <MenuItem key={sub.subDistrictId} value={sub.subDistrictId}>
                      {sub.subDistrictName}
                    </MenuItem>
                  ))}
              </LocationSelect>
            </div>
            <div className={'flex flex-col md:flex-row gap-4 md:gap-6 lg:gap-12'}>
              <LocationSelect
                label={'Extension Area'}
                control={control}
                onSelect={(event) => {
                  setExtensionArea(event.target.value as unknown as number)
                  setVillage('')
                }}
                name={'extension'}
                value={extensionArea}
                disabled={subDistrict === ''}
                error={!(errors.extension == null)}
                helperText={errors?.extension?.message ?? ''}
              >
                {apiLocationInfo
                  .filter((location) => location.subDistrictId === subDistrict)
                  .filter(
                    (location, index, self) =>
                      self.findIndex((l) => l.extensionAreaId === location.extensionAreaId) ===
                      index
                  ) // Filter unique extensionAreaIds
                  .map((area) => (
                    <MenuItem key={area.extensionAreaId} value={area.extensionAreaId}>
                      {area.extensionAreaName}
                    </MenuItem>
                  ))}
              </LocationSelect>
              <LocationSelect
                label={'Village Name'}
                control={control}
                onSelect={(event) => {
                  setVillage(event.target.value as unknown as number)
                }}
                name={'village'}
                value={village}
                disabled={extensionArea === ''}
                error={!(errors.village == null)}
                helperText={errors?.village?.message ?? ''}
              >
                {locations
                  .filter((area) => {
                    return area.extension_area_id === extensionArea
                  })
                  .map((area) => (
                    <MenuItem key={area.village_id} value={area.village_id}>
                      {area.village_name}
                    </MenuItem>
                  ))}
              </LocationSelect>
            </div>
            <TextField
              variant={'outlined'}
              label={'Extension Officer'}
              disabled={true}
              value={data?.data[0]?.firstname ?? 'N/A'}
              size={'small'}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Person />
                  </InputAdornment>
                )
              }}
            />
            <div className={'flex flex-row justify-between gap-8'}>
              <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
              <BasicButton
                label={'Next'}
                variant={'primary'}
                onClick={handleSubmit(onSubmit)}
                size={'xs'}
                type={'submit'}
              />
            </div>
          </>
        )}
      </Form>
    </div>
  )
}
