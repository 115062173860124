import { app } from 'config/firebase'
import { FirebaseError } from 'firebase/app'
import { updatePassword } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from 'stores/useAuthStore'
import { type ROLES } from 'types'
import { mapAuthErrorToMessage } from 'utils'
import { getPath } from 'utils/utilFunctions'

interface UseChangePassword {
  changePassword: (newPassword: string) => Promise<void>
  error: string | null
  isLoading: boolean
}

export const useChangePassword = (role: ROLES): UseChangePassword => {
  const { user, isFirstLogin, setIsFirstLogin } = useAuthStore()
  const functions = getFunctions(app, 'us-central1')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  const changePassword = async (newPassword: string): Promise<void> => {
    setIsLoading(true)
    setError(null)
    const updatedPassword = httpsCallable(functions, 'updateExtensionOfficerPassword')

    try {
      if (user != null && isFirstLogin === true) {
        await updatePassword(user, newPassword).then(async () => {
          await updatedPassword().then((res) => {
            if (res.data !== null) {
              setIsFirstLogin(false)
              toast.success('Password successfully changed')
              navigate(getPath(role))
            }
          })
        })
      }
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        setError(mapAuthErrorToMessage(err.code))
      } else {
        const errorMsg = err as Error
        setError(mapAuthErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return {
    changePassword,
    error,
    isLoading
  }
}
