import { useEmailLogin } from 'features/authentication/hooks/useEmailLogin'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { object, string } from 'zod'
import { useAuthStore } from '../../../../stores/useAuthStore'
import { useAuth } from '../../hooks/useAuth'
import { LoginSeparator } from '../layout/Separator'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text
} from 'components/ui'
import { useToast } from 'components/ui/use-toast'
import { zodResolver } from '@hookform/resolvers/zod'

const emailSchema = object({
  email: string().email('Email is invalid'),
  password: string()
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters')
})

interface EmailLoginFormProps {
  className?: string
  onPhone: () => void
  onRegister: () => void
  onSuccess: (role: number) => void
}

export const EmailLoginForm: React.FC<EmailLoginFormProps> = ({
  className,
  onPhone,
  onRegister,
  onSuccess
}) => {
  const { toast } = useToast()
  const { emailSignIn, signOutUser } = useAuth()
  const { isLoading, role, error, user, userId } = useAuthStore()
  const [showPassword, setShowPassword] = useState(false)
  const { loading, isUser } = useEmailLogin(role, userId)
  const form = useForm<z.infer<typeof emailSchema>>({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onSubmitEmail = async (data: z.infer<typeof emailSchema>): Promise<void> => {
    await emailSignIn(data.email, data.password)
  }

  useEffect(() => {
    if (error !== null) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: error
      })
    }

    if (user !== null && (role === null || userId === null) && !isLoading && error === null) {
      signOutUser()
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'user does not exist.'
      })
    }

    if (isUser && role !== null) {
      onSuccess(role)
    }
  }, [error, user, role, isUser])

  return (
    <Form {...form}>
      <form className={className} onSubmit={form.handleSubmit(onSubmitEmail)}>
        <div className={'flex flex-col gap-4'}>
          <FormField
            control={form.control}
            name={'email'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder='Email'
                    type='email'
                    classLabel={'bg-background-dark sm:bg-card'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>

          <FormField
            control={form.control}
            name={'password'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input
                    classLabel={'bg-background-dark sm:bg-card'}
                    placeholder={'password'}
                    type={showPassword ? 'text' : 'password'}
                    isPassword
                    showPassword={showPassword}
                    onShowPassword={() => {
                      setShowPassword(!showPassword)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>
          <Button
            loading={loading || isLoading}
            disabled={loading || isLoading}
            onClick={form.handleSubmit(onSubmitEmail)}
            type={'submit'}
            size={'lg'}
          >
            login
          </Button>
        </div>
        <LoginSeparator label={'OR'} />
        <div className={'flex flex-col gap-4'}>
          <Button
            variant={'secondary'}
            onClick={() => {
              form.reset()
              onPhone()
            }}
            disabled={loading}
          >
            Sign in with Phone Number
          </Button>
          <div className={'flex items-center sm:justify-center gap-2'} onClick={onRegister}>
            <Text>Don't Have an Account?</Text>
            <Text variant={'primary'}>
              <a className='cursor-pointer font-bold' onClick={onRegister}>
                Sign Up
              </a>
            </Text>
          </div>
        </div>
      </form>
    </Form>
  )
}
