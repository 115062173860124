import { discriminatedUnion, literal, object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import {
  useStepper,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { StepperFooter } from '../Footer'
import { getManualPestStageValue } from '../../../utils/functions'
import { useCroppingPlanPest } from '../../../hooks/useCroppingPlanPest'
import { useGetPestControl } from '../../../api/pest-control/getPestControl'

const base = object({
  chemicalApplicationMethod: z.enum(['Knapsack', 'Tractor', 'Drone', 'Plane'], {
    required_error: 'You need to select an application method'
  }),
  chemicalSchedule: z.enum(['Contact', 'Preventive'], {
    required_error: 'You need to select an application time'
  })
})

export const pestSchema = discriminatedUnion('type', [
  object({
    type: z.enum(['Manual'], {
      required_error: 'You need to select a pest control type'
    })
  }).merge(
    object({
      manual_schedule: z.enum(
        [
          'Pre-Emergence & Post-Emergence',
          'Pre-Emergence',
          'Post-Emergence',
          'Before Planting & Post-Emergence',
          'Before Planting'
        ],
        {
          required_error: 'You need to select an application time'
        }
      ),
      applicationMethod: z.enum(['Physical Pest Control', 'Biological Pest Control'], {
        required_error: 'You need to select an application method.'
      })
    })
  ),
  object({
    type: literal('Chemical')
  }).merge(base)
])

export const Pest: React.FC = () => {
  const { nextStep } = useStepper()
  const { header, setPest, createManualPest, updateManualPest, isLoading } =
    useCroppingPlanPest(nextStep)

  const {
    data: apiPest,
    isInitialLoading: apiIsLoading,
    error: pestError
  } = useGetPestControl(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof pestSchema>>({
    resolver: zodResolver(pestSchema),
    defaultValues: {
      type: apiPest != null ? apiPest?.data?.type : 'Manual',
      manual_schedule:
        apiPest != null
          ? getManualPestStageValue(apiPest?.data.manual_schedule)
          : 'Before Planting',
      applicationMethod:
        apiPest != null
          ? apiPest?.data?.manual_method ?? 'Biological Pest Control'
          : 'Biological Pest Control',
      chemicalSchedule: 'Contact',
      chemicalApplicationMethod: 'Drone'
    }
  })

  const onSubmit = (data: z.infer<typeof pestSchema>): void => {
    if (data.type === 'Manual') {
      if (apiPest == null) {
        createManualPest(data)
      } else if (
        apiPest.data.type !== data.type ||
        apiPest.data.manual_method !== data.applicationMethod ||
        getManualPestStageValue(apiPest.data.manual_schedule) !== data.manual_schedule
      ) {
        updateManualPest(data)
      } else {
        nextStep()
      }
    }
  }

  useEffect(() => {
    if (apiPest != null) {
      setPest(apiPest.data)
      form.reset()
    }
  }, [isLoading])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (pestError != null) {
    if (pestError?.response?.status !== 404) {
      return <Error />
    }
  }

  const isManual = form.watch('type') === 'Manual' || form.getValues('type') === 'Manual'

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='type'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>Select a Pest Control technique...</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem disabled value='Chemical' />
                      </FormControl>
                      <FormLabel className='font-normal'>Chemical</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Manual' />
                      </FormControl>
                      <FormLabel className='font-normal'>Manual</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {isManual ? (
            <FormField
              control={form.control}
              name='manual_schedule'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel>Select a Manual Pest Control application time...</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Before Planting' />
                        </FormControl>
                        <FormLabel className='font-normal'>Before Planting</FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Pre-Emergence' />
                        </FormControl>
                        <FormLabel className='font-normal'>Pre-Emergence</FormLabel>
                      </FormItem>{' '}
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Post-Emergence' />
                        </FormControl>
                        <FormLabel className='font-normal'>Post-Emergence</FormLabel>
                      </FormItem>{' '}
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Before Planting & Post-Emergence' />
                        </FormControl>
                        <FormLabel className='font-normal'>
                          Before Planting & Post-Emergence
                        </FormLabel>
                      </FormItem>{' '}
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Pre-Emergence & Post-Emergence' />
                        </FormControl>
                        <FormLabel className='font-normal'>
                          Pre-Emergence & Post-Emergence
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}

          {isManual ? (
            <FormField
              control={form.control}
              name='applicationMethod'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel>Select a Manual Pest Control application method...</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Physical Pest Control' />
                        </FormControl>
                        <FormLabel className='font-normal'>Physical Pest Control</FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='Biological Pest Control' />
                        </FormControl>
                        <FormLabel className='font-normal'>Biological Pest Control</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}
        </div>
        <StepperFooter isLoading={isLoading} />
      </form>
    </Form>
  )
}
