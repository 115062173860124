import { atom } from 'jotai'

export interface ApiLocationInfo {
  villageId: number
  villageName: string
  extensionAreaId: number
  extensionAreaName: string
  subDistrictId: number
  subDistrictName: string
  districtId: number
  districtName: string
}
export const apiLocationAtom = atom<ApiLocationInfo[]>([
  {
    villageId: 1,
    villageName: '',
    extensionAreaId: 1,
    extensionAreaName: '',
    subDistrictId: 1,
    subDistrictName: '',
    districtId: 1,
    districtName: ''
  }
])
