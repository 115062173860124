import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface TerminateLeaseDTO {
  LEASE_ID: number | null | undefined
  FARMER_ID: number | null | undefined
  STATUS_ID: number
  LAST_UPDATED_BY: number | null | undefined
}

export const terminateLease = async (params: TerminateLeaseDTO): Promise<ApiResponse<[]>> => {
  return axios.put('/lease/terminate', params)
}
