import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type DirectorDetails } from 'types'

export interface DirectorDetailsDTO {
  uin: string | null | undefined
  id: string
}

export const validateCompanyDirectorDetails = async (
  payload: DirectorDetailsDTO
): Promise<ApiResponseWithObject<DirectorDetails>> => {
  return axios.post('/validate/company/director', payload)
}
