import { AlternateEmail, PermContactCalendar, Person } from '@mui/icons-material'
import { BasicButton } from 'components/Elements/Button'
import { Form, InputField } from 'components/Form'
import { useIndividualRegistration } from 'features/authentication/hooks/useIndividualRegistration'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import type React from 'react'
import { useEffect } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { literal, object, optional, string, type TypeOf } from 'zod'
import { Button, Input } from '../../../../components/ui'

const schema = object({
  postalAddress: string().nonempty('Postal Address is required'),
  physicalAddress: string().nonempty('Physical Address is required'),
  email: optional(string().email('Email is invalid')).or(literal(''))
})

export type AddressInputValues = TypeOf<typeof schema>

interface AddressesFormProps {
  className?: string
  onSuccess: () => void
  onBack: () => void
}

export const AddressesForm: React.FC<AddressesFormProps> = ({
  className,
  onBack,
  onSuccess
}: AddressesFormProps) => {
  const { error, isLoading, registerFarmer } = useIndividualRegistration(onSuccess)
  const { contactDetails } = useIndividualRegistrationStore()

  const onSubmit: SubmitHandler<AddressInputValues> = (data: AddressInputValues) => {
    registerFarmer(data)
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(error)
    }
  }, [error])

  return (
    <Form<AddressInputValues, typeof schema>
      className={className}
      onSubmit={onSubmit}
      schema={schema}
    >
      {({ register, handleSubmit, formState: { errors } }) => (
        <>
          <div className={'w-full flex flex-col gap-8'}>
            <div className={'flex flex-col gap-4 sm:gap-6'}>
              <Input
                placeholder={'Postal Address'}
                withLabel
                label={'Postal Address'}
                classLabel={'bg-background-dark sm:bg-card'}
                inputMode={'text'}
                error={!(errors.postalAddress == null)}
                helperText={errors?.postalAddress?.message ?? ''}
                register={register('postalAddress')}
                defaultValue={
                  contactDetails?.postalAddress !== null ? contactDetails?.postalAddress : ''
                }
              />
              <Input
                placeholder={'Physical Address'}
                withLabel
                inputMode={'text'}
                label={'Physical Address'}
                classLabel={'bg-background-dark sm:bg-card'}
                error={!(errors.physicalAddress == null)}
                helperText={errors?.physicalAddress?.message ?? ''}
                defaultValue={
                  contactDetails?.physicalAddress !== null ? contactDetails?.physicalAddress : ''
                }
                register={register('physicalAddress')}
              />
              <Input
                placeholder={'email'}
                withLabel
                inputMode={'email'}
                label={'email'}
                classLabel={'bg-background-dark sm:bg-card'}
                error={!(errors.email == null)}
                helperText={errors?.email?.message ?? ''}
                defaultValue={contactDetails?.email !== null ? contactDetails?.email : undefined}
                register={register('email')}
              />

            </div>
            <div className='flex justify-between'>
              <Button
                variant={'ghost'}
                onClick={onBack}
                disabled={isLoading}
              >
                Back
              </Button>
              <Button
                disabled={isLoading}
                loading={isLoading}
                type={'submit'}
                onClick={handleSubmit(onSubmit)}
                className={'w-40%'}
              >
                Finish
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  )
}
