import { BasicCard } from 'components/Elements/Card'
import { BasicModal } from 'components/Elements/Modal'
import type React from 'react'

interface LoanModalProps {
  open: boolean
  onClose: () => void
  children?: React.JSX.Element
}
export const LoanModal: React.FC<LoanModalProps> = ({ open, onClose, children }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <BasicCard className={'w-min'}>{children}</BasicCard>
    </BasicModal>
  )
}
