import { OTPInput } from 'components/Otp/InputOTP'
import type React from 'react'
import type { Farm, Lease } from 'types'
import { useNewGrant } from '../../hooks/useNewGrant'
import { type SelectedCroppingPlans } from '../../types'

interface Base {
  cancel: () => void
  selectedCroppingPlans: SelectedCroppingPlans[]
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type NewGrantOtpFormProps = WithLease | WithFarm

export const NewGrantOtpForm: React.FC<NewGrantOtpFormProps> = ({
  cancel,
  lease,
  farm,
  selectedCroppingPlans
}) => {
  const { newGrant, isLoading } = useNewGrant()

  const createNewGrant = (): void => {
    newGrant(
      {
        farmer_id: undefined,
        user_id: undefined,
        last_update_by: undefined,
        cropping_plan: selectedCroppingPlans,
        farm_id: lease != null ? lease.farm_id : farm?.farm_id,
        lease_id: lease?.lease_id
      },
      cancel
    )
  }

  return (
    <OTPInput
      isLoading={isLoading}
      number={farm != null ? farm.farmer_contact : lease?.destination_farmer_contact}
      next={createNewGrant}
    />
  )
}
