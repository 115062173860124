import { Grow } from '@mui/material'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { NotFound } from 'components/Errors/NotFound'
import { useFarmers } from 'features/officer/farmers/hooks/useFarmers'
import type React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useOfficerStore } from 'stores/useOfficerStore'
import { useGetLeases } from '../api/getLeases'
import { FarmCardResults } from '../components/FarmCardResults'
import { LeaseCardResults } from '../components/LeaseCardResults'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Text
} from 'components/ui'
import { SkeletonCard } from 'components/SkeletonCardLoader'
import { Skeleton } from 'components/ui/skeleton'

export const FarmersView: React.FC = () => {
  const { officer } = useOfficerStore()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { isLoading, farms, farmer, error } = useFarmers(officer?.ext_area_id, farmerId)
  const {
    data: leases,
    isLoading: leaseIsLoading,
    error: leaseError
  } = useGetLeases(farmerId, officer?.ext_area_id)
  const navigate = useNavigate()

  const handleBack = (): void => {
    navigate('/officer/farmers', { replace: true })
  }

  if (isLoading || leaseIsLoading) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Skeleton className='w-[300px] h-[20px] rounded-full' />
        <Skeleton className='w-[300px] h-[20px] rounded-full' />
        <div
          className={
            'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
          }
        >
          {Array(16)
            .fill(1)
            .map((_, index) => {
              return <SkeletonCard key={index} />
            })}
        </div>
      </div>
    )
  }

  if (error && leaseError != null && leaseError?.response?.status !== 404) {
    return <ErrorPage />
  }

  if (error && leaseError != null && farmer === undefined) {
    return <NotFound />
  }

  if (farms === undefined && leases === undefined) {
    return (
      <div className={'max-w-[1500px] w-full flex-col gap-4 flex justify-center mx-auto px-0 py-4'}>
        <div className={'px-4 w-full flex items-center'}>
          <button onClick={handleBack} className={'flex items-center gap-4 cursor-pointer group'}>
            <Text variant={'bodyTextLight'} size={'medium'} className={'group-hover:text-primary'}>
              <p className='fa-solid fa-arrow-left' />
            </Text>
            <div className={'flex gap-1 items-baseline'}>
              <Text
                size={'medium'}
                variant={'bodyTextLight'}
                className={'group-hover:text-secondary capitalize'}
              >
                {`${farmer?.firstname ?? ''} ${farmer?.surname ?? ''}`}
              </Text>
              <Text variant={'primary'} className={'font-bold group-hover:text-secondary'}>
                {farmerId ?? ''}
              </Text>
            </div>
          </button>
        </div>

        <div className={'flex-1 grid place-items-center'}>
          <Text size={'medium'}>No farms available</Text>
        </div>
      </div>
    )
  }

  return (
    <div className={'max-w-[1500px] w-full flex-col gap-4 flex justify-center mx-auto px-0 py-4'}>
      <Breadcrumb className={'border-b border-border p-4'}>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={'/officer/farmers'}>Farmers</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{farmerId}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className={'px-4 w-full flex items-center'}>
        <div className={'flex gap-1 items-baseline'}>
          <Text
            size={'medium'}
            variant={'bodyTextLight'}
            className={'group-hover:text-secondary capitalize'}
          >
            {`${farmer?.firstname ?? leases?.data[0]?.dest_farmer_name ?? ''} ${
              farmer?.surname ?? ''
            }`}
          </Text>
        </div>
      </div>

      {leases !== undefined || farms !== undefined ? (
        <div className={'w-full flex flex-col gap-4'}>
          <Grow in>
            <div
              className={
                'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
              }
            >
              {farms !== undefined
                ? farms
                    .filter((farm) => {
                      return farm.status === 'verified'
                    })
                    .map((farm) => {
                      return <FarmCardResults farm={farm} key={farm.farm_id} />
                    })
                : null}
              {leases?.data
                .filter((lease) => {
                  return (
                    lease.status_desc === 'ACCEPTED BY LESSEE' ||
                    lease.status_desc === 'VERIFIED BY EXTENSION OFFICER'
                  )
                })
                .map((lease) => {
                  return <LeaseCardResults key={lease.lease_id} lease={lease} />
                })}
            </div>
          </Grow>
        </div>
      ) : null}
    </div>
  )
}
