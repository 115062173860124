import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateHeaderStatusDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  user_id: number | null | undefined
  status: string
}

export const updateHeaderStatus = async (
  params: UpdateHeaderStatusDTO
): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/header/status`, params)
}

export interface UpdateHeaderStatusEODTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  user_id: number | null | undefined
  status: string
  comments: string | null
}

export const updateHeaderStatusEO = async (
  params: UpdateHeaderStatusEODTO
): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/header/verify`, params)
}
