import { type ApiResponseWithObject, type LoanHeader } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getLoanHeader = async (
  farmerId: number
): Promise<ApiResponseWithObject<LoanHeader>> => {
  return axios.get(`/loan/header?farmer_id=${farmerId}`)
}

export const useGetLoanHeader = (
  farmerId: number
): UseQueryResult<ApiResponseWithObject<LoanHeader>, ApiResponseWithObject<[]>> => {
  return useQuery({
    queryKey: ['loan', farmerId],
    queryFn: async () => getLoanHeader(farmerId)
  })
}
