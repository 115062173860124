import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type Merchant } from 'types'

export const getMerchants = async (): Promise<ApiResponse<Merchant>> => {
  return axios.get(`/merchant?merchant_type=3`)
}

export const useGetMerchants = (): UseQueryResult<ApiResponse<Merchant>, AxiosError> => {
  return useQuery({
    queryKey: ['merchants'],
    queryFn: async () => getMerchants()
  })
}
