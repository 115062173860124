import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type Farmer } from 'types'

export const getFarmers = async (
  extAreaId: number | null | undefined
): Promise<ApiResponse<Farmer>> => {
  return axios.get(`/farmer?EXT_AREA_ID=${extAreaId ?? ''}`)
}

export const useGetFarmers = (
  extAreaId: number | null | undefined
): UseQueryResult<ApiResponse<Farmer>, AxiosError<[]>> => {
  return useQuery({
    queryKey: ['my-farmers', extAreaId],
    queryFn: async () => getFarmers(extAreaId)
  })
}
