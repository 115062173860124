import { CroppingPlanTable } from 'features/officer/cropping-plan/components/results/CroppingPlanTable'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { type CroppingPlan } from 'types'
import { Text } from 'components/Elements/Text/Text'
import { Card } from 'components/ui'

interface CroppingPlanResultsProps {
  croppingPlans: CroppingPlan[]
  className: string
}

export const CroppingPlanResults: React.FC<CroppingPlanResultsProps> = ({
  croppingPlans,
  className
}) => {
  const navigate = useNavigate()
  const onClickCroppingPlan = (item: CroppingPlan): void => {
    navigate(`/officer/cropping-plan/${item.farmer_id}/${item.cropping_plan_id}`)
  }

  return (
    <div className={className}>
      <Text size={'medium'} variant={'primary'} className={'ml-4'}>
        Search results: {croppingPlans.length}
      </Text>

      <Card
        className={'bg-card w-full flex flex-col gap-2 sm:gap-4 p-4 shadow-[0_3px_6px_#00000029]'}
      >
        <CroppingPlanTable croppingPlans={croppingPlans} onClick={onClickCroppingPlan} />
      </Card>
    </div>
  )
}
