export const Herbicides = [
  {
    id: 1,
    application_rate: '250L/ha',
    dosage: '0,5 - 0,75L/ha',
    withdrawal_period_in_days: 80,
    name: 'Cypermethrin 200 EC',
    key_ingredients: 'Mesotrione 480g/l',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 2,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Bio Kill Extra GT',
    key_ingredients: 'Mesotrione 480g/l',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 3,
    application_rate: '600L/ha',
    dosage: '0.8/100L water',
    withdrawal_period_in_days: 40,
    name: 'Rosecare Plus',
    key_ingredients: 'Mesotrione 480g/l',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 4,
    application_rate: '600L/ha',
    dosage: '0,5 - 0,75L/ha',
    withdrawal_period_in_days: 40,
    name: 'Karbaspray',
    key_ingredients: 'Mesotrione 480g/l',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 5,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Malasol',
    key_ingredients: 'Mercaptothion 500g/l',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 6,
    application_rate: '250L/ha',
    dosage: '0,5 - 0,75L/ha',
    withdrawal_period_in_days: 120,
    name: 'No-Weed Lawn',
    key_ingredients: 'Mesotrione 480g/l',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 7,
    application_rate: '1000L/ha',
    dosage: '0,5 - 0,75L/ha',
    withdrawal_period_in_days: 120,
    name: 'Milbitraz Spraydip',
    key_ingredients: 'Amitraz 125g/l',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 8,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Drastic Deadline',
    key_ingredients: 'Flumethrin 1% m/v',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 9,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Bayticol 2% EC',
    key_ingredients: 'Flumethrin 2% m/v',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 10,
    application_rate: '250L/ha',
    dosage: '0,5 - 0,75L/ha',
    withdrawal_period_in_days: 80,
    name: 'Dazzel N.F',
    key_ingredients: 'Diazinon 30% m/v',
    crop_id: 10001,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 11,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Advantix',
    key_ingredients: 'Mesotrione 480g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 12,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Bayopet Bacdip Plus',
    key_ingredients: 'Mesotrione 480g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 13,
    application_rate: '250L/ha',
    dosage: '0,5 - 0,75L/ha',
    withdrawal_period_in_days: 40,
    name: 'Cylence',
    key_ingredients: 'Cyfluthrin 1%m/v',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 14,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Zeropar Aerosol',
    key_ingredients: 'Glyphosate 540g/l, Glyphosate Potassium salt 665g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 15,
    application_rate: '500L/ha',
    dosage: '0,5 - 0,75L/ha',
    withdrawal_period_in_days: 40,
    name: 'Herbicide',
    key_ingredients: 'Glyphosate 540g/l, Glyphosate Potassium salt 665g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 16,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Smart KB',
    key_ingredients: 'Glyphosate 540g/l, Glyphosate Potassium salt 665g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 17,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Smart P',
    key_ingredients: 'Glyphosate 540g/l, Glyphosate Potassium salt 665g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 18,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Smart Trio',
    key_ingredients: 'Glyphosate 540g/l, Glyphosate Potassium salt 665g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 19,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Manni-Plex B Moly',
    key_ingredients: 'Glyphosate 540g/l, Glyphosate Potassium salt 665g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 20,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Green Liq Plus',
    key_ingredients: 'Glyphosate 540g/l, Glyphosate Potassium salt 665g/l',
    crop_id: 10003,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 21,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Grazer 200 GG',
    key_ingredients: 'Clethodim 240g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 22,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Piranha 510 SL',
    key_ingredients: 'Clethodim 240g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 23,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Picloram 240 SL',
    key_ingredients: 'Clethodim 240g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 24,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Vulture 480 EC',
    key_ingredients: 'Clethodim 240g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 25,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Bromoxynil 225 EC',
    key_ingredients: 'Clethodim 240g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 26,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Terbuthylazine 500 SC',
    key_ingredients: 'Clethodim 240g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 27,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Buccaneer 960 EC',
    key_ingredients: 'Acetochlor 900g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 28,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Grass Valley 500 SC',
    key_ingredients: 'Acetochlor 900g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 29,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Grazer 800 WP',
    key_ingredients: 'Acetochlor 900g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 30,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Kalahari 200 GG',
    key_ingredients: 'Acetochlor 900g/l',
    crop_id: 10004,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 31,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Grassvalley 200 GG',
    key_ingredients: 'Acetochlor 900g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 32,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Grazer 500 SC',
    key_ingredients: 'Acetochlor 900g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 33,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Grassvalley 500SC',
    key_ingredients: 'Flumetsulam 20g/l, S-metolachlor 630g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 34,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Methomyl 900 SP',
    key_ingredients: 'Flumetsulam 20g/l, S-metolachlor 630g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 35,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Farmazine Super 600',
    key_ingredients: 'Flumetsulam 20g/l, S-metolachlor 630g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 36,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Apache Gold 350 SC',
    key_ingredients: 'Flumetsulam 20g/l, S-metolachlor 630g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 37,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 80,
    name: 'FarmAg Paraquat 200 SL',
    key_ingredients: 'Flumetsulam 20g/l, S-metolachlor 630g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 38,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 40,
    name: 'FarmAg MCPA',
    key_ingredients: 'Flumetsulam 20g/l, S-metolachlor 630g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 39,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 80,
    name: 'FarmAg Azoxystrobin 250 SC',
    key_ingredients: 'Flumetsulam 20g/l, S-metolachlor 630g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 40,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Foxamyl 310 SL',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10005,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 41,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Foxamyl 10 GR',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 42,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Difenoconazole 250 EC',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 43,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Garden Phosphate 8,3',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 44,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 40,
    name: 'Guanoflo',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence'
  },
  {
    id: 45,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 40,
    name: 'NutriGreen Gro Green',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Post-Emergence'
  },
  {
    id: 46,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 40,
    name: '2:3:2 (14)',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 47,
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80,
    name: 'Nutri Green Flower Power',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Before Planting/Post-Emergence'
  },
  {
    id: 48,
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Nutri Green Gwano Pellets',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 49,
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 120,
    name: 'Guanosol',
    key_ingredients: 'Mesotrione 26.8g/l, Metolachlor 208.5/l, Atrazine 208.5g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Pre-Emergence/Post-Emergence'
  },
  {
    id: 50,
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 120,
    name: '2:3:4 (14)',
    key_ingredients: 'Paraquat ion 200g/l',
    crop_id: 10009,
    type: 'Herbicide',
    application_stage: 'Before Planting'
  },
  {
    id: 51,
    name: 'Lawn & Foliage Fertilizer 7:1:3(15)',
    key_ingredients: 'Paraquat ion 200g/l',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Before Planting',
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80
  },
  {
    id: 52,
    name: 'Nitrogreen LAN/KAN 17',
    key_ingredients: 'Paraquat ion 200g/l',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Pre-Emergence',
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 80
  },
  {
    id: 53,
    name: 'Rose & Flower Fertilizer 8:1:15',
    key_ingredients: 'Paraquat ion 200g/l',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Pre-Emergence',
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 40
  },
  {
    id: 54,
    name: 'Avian Control Bird Repellent',
    key_ingredients: 'Paraquat ion 200g/l',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Post-Emergence',
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 40
  },
  {
    id: 55,
    name: 'Benevia 100 OD',
    key_ingredients: 'Paraquat ion 200g/l',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Post-Emergence',
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 80
  },
  {
    id: 56,
    name: 'Coragen',
    key_ingredients: 'Paraquat ion 200g/l',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Before Planting/Post-Emergence',
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 120
  },
  {
    id: 57,
    name: 'Prevathon 5 SC',
    key_ingredients: 'Metalochlor 960 ',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Before Planting/Post-Emergence',
    application_rate: '1000L/ha',
    dosage: '0.32L/100L water',
    withdrawal_period_in_days: 40
  },
  {
    id: 58,
    name: 'Steward 150 EC',
    key_ingredients: 'Metalochlor 960 ',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Pre-Emergence/Post-Emergence',
    application_rate: '500L/ha',
    dosage: '0.64L/100L water',
    withdrawal_period_in_days: 80
  },
  {
    id: 59,
    name: 'Rufast EW',
    key_ingredients: 'Metalochlor 960 ',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage: 'Pre-Emergence/Post-Emergence',
    application_rate: '600L/ha',
    dosage: '0.8L/100L water',
    withdrawal_period_in_days: 120
  },
  {
    id: 60,
    name: 'Marshal 48 EC',
    key_ingredients: 'Metalochlor 960 ',
    type: 'Herbicide',
    crop_id: 10011,
    application_stage:
      'Before Planting, Pre-Emergence, Post-Emergence, Before Planting/Post-Emergence, Pre-Emergence/Post-Emergence',
    application_rate: '250L/ha',
    dosage: '1.2L/100L water',
    withdrawal_period_in_days: 120
  }
]
