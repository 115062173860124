import { axios } from 'lib/axios'
import { type ApiResponse, type FileReference } from 'types'

export interface AcceptFarmDTO {
  FARMER_ID: number
  LAST_UPDATED_BY: number | null | undefined
  STATUS: number
  MESSAGE?: string
}

export const acceptOrRejectFarm = async (
  farmId: number,
  params: AcceptFarmDTO
): Promise<ApiResponse<FileReference>> => {
  return axios.put(`/farm/verify/${farmId}`, params)
}
