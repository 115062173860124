import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type SoilSampleRequest } from 'types'

export const getSSR = async (
  farmerId: string | null | undefined,
  ssrId: string | null | undefined,
  farmId: string | null | undefined,
  merchantId: number | null | undefined
): Promise<ApiResponse<SoilSampleRequest>> => {
  return axios.get(
    `ssr?farmer_id=${farmerId ?? ''}&ssr_id=${ssrId ?? ''}&farm_id=${farmId ?? ''}&merchant_id=${
      merchantId ?? ''
    }`
  )
}

export const useGetSSR = (
  farmerId: string | null | undefined,
  ssrId: string | null | undefined,
  farmId: string | null | undefined,
  merchantId: number | null | undefined
): UseQueryResult<ApiResponse<SoilSampleRequest>, AxiosError> => {
  return useQuery({
    queryKey: ['soil-sample-result', ssrId, farmerId, farmId],
    queryFn: async () => getSSR(farmerId, ssrId, farmId, merchantId),
    enabled: farmerId != null && ssrId != null && farmId != null && merchantId != null
  })
}
