import {
  Banner,
  About,
  NavLandingPage,
  Categories,
  Gallery,
  Footer,
  Guidelines
} from 'features/landing/components'
import { Layout } from 'components/ui'
import type React from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'

export const Homepage: React.FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  return (
    <Layout variant={'bodyMax'} className={'bg-background-dark'}>
      <NavLandingPage />

      <Banner />
      <About />
      <Gallery />
      <Categories />
      {flags.landingGuidelines === true ? <Guidelines /> : null}
      <Guidelines />
      <Footer />
    </Layout>
  )
}
