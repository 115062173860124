import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0081CCFF',
      light: '#40AAE7FF',
      dark: '#024579FF'
    },
    secondary: {
      main: '#36363AFF',
      light: '#545454FF',
      dark: '#171717FF'
    },
    info: {
      main: '#2094F3FF',
      light: '#65B5F6FF',
      dark: '#0D48A0FF'
    },
    error: {
      main: '#E53734FF',
      light: '#E57171FF',
      dark: '#C72929FF'
    },
    success: {
      main: '#4CAE4FFF',
      light: '#80C684FF',
      dark: '#2F7F33FF'
    },
    warning: {
      main: '#FFC105FF',
      light: '#FFD54DFF',
      dark: '#FF9100FF'
    }
  },
  typography: {
    body1: {
      color: '#1F1F1FFF'
    },
    body2: {
      color: '#1F1F1FFF'
    },
    h3: {
      fontSize: '2.5rem'
    },
    h6: {
      fontSize: '1rem'
    }
  },
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          color: 'var(--color-text)'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'var(--color-white)'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          color: 'var(--color-text)'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          color: 'var(--color-primary)'
        },
        sizeMedium: {
          color: 'var(--color-primary)'
        },
        sizeLarge: {
          color: 'var(--color-primary)'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        label: {
          color: 'var(--color-white)'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'var(--color-primary)',
            color: 'var(--color-white)'
          }
        }
      }
    },
    // @ts-expect-error component error
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          color: 'var(--color-text)'
        },
        cellCheckbox: {
          '& input[type="checkbox"]:not(:checked)': {
            color: 'var(--color-text)'
          }
        },
        pagination: {
          color: 'var(--color-text)'
        }
      }
    }
  }
})