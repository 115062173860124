import { Face, Group, LocationOn, Payment, Person } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import { BasicButton } from 'components/Elements/Button'
import { CustomDatePicker, Form, InputField, SearchField, SelectField } from 'components/Form'
import dayjs from 'dayjs'
import { useCompanyRegistration } from 'features/authentication/hooks/useCompanyRegistration'
import { useCompanyRegistrationStore } from 'features/authentication/stores/useCompanyRegistrationStore'
import { villageList } from 'features/authentication/utils/villages'
import type React from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { coerce, discriminatedUnion, literal, object, string, type TypeOf } from 'zod'

interface ShareHolderDetailsProps {
  className?: string
  shareHolderName: string
  shareHolderNumber: number
  totalShareHolders: number
  onNext: () => void
  onBack: () => void
}

const omangSchema = object({
  citizenship: literal('Citizen'),
  surname: string().min(1, 'Surname is required'),
  forenames: string().min(1, 'Forenames are required'),
  placeOfBirth: string().min(1, 'Place of birth is required'),
  dateOfBirth: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  id: string()
    .min(9, 'Invalid omang number')
    .max(9, 'Invalid omang number')
    .regex(/[0-9]/, 'Omang should only contain numbers'),
  id_expiry_date: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  passport: string().optional(),
  passport_expiry_date: coerce
    .date({
      required_error: 'Date is required',
      invalid_type_error: 'Invalid date'
    })
    .optional(),
  gender: string().min(1, 'Gender is required'),
  contact: string().min(1, 'Contact required')
})

const passportSchema = object({
  citizenship: literal('Non-Citizen'),
  surname: string().min(1, 'Surname is required'),
  forenames: string().min(1, 'Forenames are required'),
  placeOfBirth: string().min(1, 'Place of birth is required'),
  dateOfBirth: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  id: string().optional(),
  id_expiry_date: coerce
    .date({
      required_error: 'Date is required',
      invalid_type_error: 'Invalid date'
    })
    .optional(),
  passport: string()
    .min(9, 'Invalid omang number')
    .max(9, 'Invalid omang number')
    .regex(/[0-9]/, 'Omang should only contain numbers'),
  passport_expiry_date: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  gender: string().min(1, 'Gender is required'),
  contact: string().min(1, 'Contact required')
})

const schema = discriminatedUnion('citizenship', [omangSchema, passportSchema], {
  required_error: 'field is required',
  invalid_type_error: 'invalid citizen selected'
})

export type CompanyShareHoldersInput = TypeOf<typeof schema>

export const ShareHolderDetails: React.FC<ShareHolderDetailsProps> = ({
  onBack,
  onNext,
  shareHolderNumber,
  shareHolderName,
  totalShareHolders,
  className
}) => {
  const { error, isLoading, validateShareHolder } = useCompanyRegistration()
  const { shareHoldersDetails } = useCompanyRegistrationStore()

  const onSubmit: SubmitHandler<CompanyShareHoldersInput> = (data: CompanyShareHoldersInput) => {
    try {
      validateShareHolder(data, shareHolderNumber, onNext)
    } catch (er) {
      toast.error(error)
    }
  }

  const getCitizen = (citizen: string): string => {
    if (citizen === 'N') return 'Non-Citizen'
    return 'Citizen'
  }

  return (
    <Form<CompanyShareHoldersInput, typeof schema>
      className={className}
      onSubmit={onSubmit}
      schema={schema}
      key={shareHolderNumber}
    >
      {({ control, register, watch, formState: { errors }, handleSubmit }) => (
        <>
          <div className={'flex items-center justify-between gap-4'}>
            <h3 className={'text-sm'}>Details for {shareHolderName}</h3>
            <p className={'text-sm text-gray-400 font-bold'}>
              {shareHolderNumber + 1}/{totalShareHolders}
            </p>
          </div>

          <SelectField
            label={'Citizen or Non Citizen'}
            register={register('citizenship')}
            icon={<Face color={'primary'} />}
            error={!(errors.citizenship == null)}
            helperText={errors?.citizenship?.message ?? ''}
            defaultValue={
              shareHoldersDetails[shareHolderNumber] != null
                ? getCitizen(shareHoldersDetails[shareHolderNumber].citizen)
                : ''
            }
          >
            <MenuItem value={'Citizen'}>Citizen</MenuItem>
            <MenuItem value={'Non-Citizen'}>Non-Citizen</MenuItem>
          </SelectField>

          {watch('citizenship') === 'Citizen' ||
          (shareHoldersDetails[shareHolderNumber] != null &&
            getCitizen(shareHoldersDetails[shareHolderNumber].citizen) === 'Citizen') ? (
            <>
              <InputField
                label={'Omang'}
                inputMode={'numeric'}
                icon={<Payment color={'primary'} />}
                register={register('id')}
                error={!(errors.id == null)}
                helperText={errors?.id?.message ?? ''}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber]?.id != null
                    ? shareHoldersDetails[shareHolderNumber].id ?? ''
                    : ''
                }
              />
              <CustomDatePicker
                error={!(errors.dateOfBirth == null)}
                helperText={errors?.dateOfBirth?.message ?? ''}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber]?.date_of_birth != null
                    ? dayjs(shareHoldersDetails[shareHolderNumber]?.date_of_birth)
                    : undefined
                }
                label={'Date of Birth'}
                name={'dateOfBirth'}
                control={control}
              />
              <CustomDatePicker
                error={!(errors.id_expiry_date == null)}
                helperText={errors?.id_expiry_date?.message ?? ''}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber]?.id_expiry_date != null
                    ? dayjs(shareHoldersDetails[shareHolderNumber]?.id_expiry_date)
                    : undefined
                }
                label={'Omang Expiry Date'}
                name={'id_expiry_date'}
                control={control}
              />{' '}
              <InputField
                label={'Forenames'}
                icon={<Person color={'primary'} />}
                error={!(errors.forenames == null)}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].firstnames
                    : ''
                }
                helperText={errors?.forenames?.message ?? ''}
                register={register('forenames')}
              />
              <InputField
                label={'Surname'}
                icon={<Group color={'primary'} />}
                error={!(errors.surname == null)}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].surname
                    : ''
                }
                helperText={errors?.surname?.message ?? ''}
                register={register('surname')}
              />
              <SearchField
                options={villageList}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].place_of_birth
                    : ''
                }
                label={'Place of Birth'}
                icon={<LocationOn color={'primary'} className={'pl-2 fa-solid fa-location-dot'} />}
                name={'placeOfBirth'}
                control={control}
                error={!(errors.placeOfBirth == null)}
                helperText={errors?.placeOfBirth?.message ?? ''}
              />
              <SelectField
                label={'Gender'}
                register={register('gender')}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].gender
                    : 'M'
                }
                icon={<Face color={'primary'} />}
                error={!(errors.gender == null)}
                helperText={errors?.gender?.message ?? ''}
              >
                <MenuItem value={'M'}>Male</MenuItem>
                <MenuItem value={'F'}>Female</MenuItem>
              </SelectField>
              <InputField
                label={'Contact'}
                icon={<Group color={'primary'} />}
                error={!(errors.contact == null)}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].contact
                    : ''
                }
                helperText={errors?.contact?.message ?? ''}
                register={register('contact')}
              />
            </>
          ) : null}

          {watch('citizenship') === 'Non-Citizen' ||
          (shareHoldersDetails[shareHolderNumber] != null &&
            getCitizen(shareHoldersDetails[shareHolderNumber].citizen) === 'Non-Citizen') ? (
            <>
              <InputField
                label={'Passport'}
                inputMode={'text'}
                icon={<Payment color={'primary'} />}
                error={!(errors.passport == null)}
                helperText={errors?.passport?.message ?? ''}
                register={register('passport')}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber]?.passport != null
                    ? shareHoldersDetails[shareHolderNumber].passport ?? ''
                    : ''
                }
              />
              <CustomDatePicker
                error={!(errors.dateOfBirth == null)}
                helperText={errors?.dateOfBirth?.message ?? ''}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber]?.date_of_birth != null
                    ? dayjs(shareHoldersDetails[shareHolderNumber]?.date_of_birth)
                    : undefined
                }
                label={'Date of Birth'}
                name={'dateOfBirth'}
                control={control}
              />
              <CustomDatePicker
                error={!(errors.passport_expiry_date == null)}
                helperText={errors?.passport_expiry_date?.message ?? ''}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber]?.passport_expiry_date != null
                    ? dayjs(shareHoldersDetails[shareHolderNumber]?.passport_expiry_date)
                    : undefined
                }
                label={'Passport Expiry Date'}
                name={'passport_expiry_date'}
                control={control}
              />
              <InputField
                label={'Forenames'}
                icon={<Person color={'primary'} />}
                error={!(errors.forenames == null)}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].firstnames
                    : ''
                }
                helperText={errors?.forenames?.message ?? ''}
                register={register('forenames')}
              />
              <InputField
                label={'Surname'}
                icon={<Group color={'primary'} />}
                error={!(errors.surname == null)}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].surname
                    : ''
                }
                helperText={errors?.surname?.message ?? ''}
                register={register('surname')}
              />
              <SearchField
                options={villageList}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].place_of_birth
                    : ''
                }
                label={'Place of Birth'}
                icon={<LocationOn color={'primary'} className={'pl-2 fa-solid fa-location-dot'} />}
                name={'placeOfBirth'}
                control={control}
                error={!(errors.placeOfBirth == null)}
                helperText={errors?.placeOfBirth?.message ?? ''}
              />
              <SelectField
                label={'Gender'}
                register={register('gender')}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].gender
                    : 'M'
                }
                icon={<Face color={'primary'} />}
                error={!(errors.gender == null)}
                helperText={errors?.gender?.message ?? ''}
              >
                <MenuItem value={'M'}>Male</MenuItem>
                <MenuItem value={'F'}>Female</MenuItem>
              </SelectField>
              <InputField
                label={'Contact'}
                icon={<Group color={'primary'} />}
                error={!(errors.contact == null)}
                defaultValue={
                  shareHoldersDetails[shareHolderNumber] != null
                    ? shareHoldersDetails[shareHolderNumber].contact
                    : ''
                }
                helperText={errors?.contact?.message ?? ''}
                register={register('contact')}
              />
            </>
          ) : null}
          <div className={'flex flex-row justify-between gap-8'}>
            <BasicButton
              label={'Back'}
              variant={'secondary'}
              onClick={onBack}
              isDisabled={isLoading}
            />
            <BasicButton
              label={'Next'}
              variant={'primary'}
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
              type={'submit'}
            />
          </div>
        </>
      )}
    </Form>
  )
}
