import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type ShellingAndThreshing } from 'types'
import { type AxiosError } from 'axios'

export const getThreshing = async (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): Promise<ApiResponseWithObject<ShellingAndThreshing>> => {
  return axios.get(`/production/threshing?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}

export const useGetThreshing = (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): UseQueryResult<ApiResponseWithObject<ShellingAndThreshing>, AxiosError> => {
  return useQuery({
    queryKey: ['threshing', farmerId, cp_id],
    queryFn: async () => getThreshing(farmerId, cp_id),
    enabled: cp_id != null && farmerId != null
  })
}
