/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { BasicButton } from 'components/Elements/Button'
import { InputField } from 'components/Form'
import {
  CategoryLabel,
  CustomAccordion,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper'
import type React from 'react'
import { array, number, object, string, type TypeOf } from 'zod'
import { FormProvider, useFieldArray, useFormContext, type UseFormReturn } from 'react-hook-form'
import { useAtom } from 'jotai'

import { Text } from '../../../../../components/Elements/Text/Text'
import { companyLoanStore } from '../stores'

const immmovableSchema = object({
  age: number({ invalid_type_error: 'This should be a number' }).refine((value) => value <= 5, {
    message: 'Age should be 5 years or less'
  }),
  encumbrances_amount: number({ invalid_type_error: 'This should be a number' }),
  estimated_forces_sale_value: number({ invalid_type_error: 'This should be a number' }),
  immovable_property_id: number({ invalid_type_error: 'This should be a number' }),
  market_value: number({ invalid_type_error: 'This should be a number' }),
  original_cost: number({ invalid_type_error: 'This should be a number' })
})

export const schemaS = object({
  immovableProperty: array(immmovableSchema).nonempty('Field is required'),
  machineryAge: number({ invalid_type_error: 'This should be a number' }).refine(
    (value) => value <= 5,
    {
      message: 'Age should be 5 years or less'
    }
  ),
  machineryOriginalCost: number({ invalid_type_error: 'This should be a number' }),
  machineryMarketValue: number({ invalid_type_error: 'This should be a number' }),
  machineryVehicleEstimatedForcedSaleVal: number({ invalid_type_error: 'This should be a number' }),
  machineryVehicleAmountEncumb: number({ invalid_type_error: 'This should be a number' }),
  machineryId: number({ invalid_type_error: 'This should be a number' }),
  motorVehicleAge: number({ invalid_type_error: 'This should be a number' }).refine(
    (value) => value <= 5,
    {
      message: 'Age should be 5 years or less'
    }
  ),
  motorVehicleOriginalCost: number({ invalid_type_error: 'This should be a number' }),
  motorVehicleMarketValue: number({ invalid_type_error: 'This should be a number' }),
  motorVehicleEstimatedForcedSaleVal: number({ invalid_type_error: 'This should be a number' }),
  motorVehicleAmountEncumb: number({ invalid_type_error: 'This should be a number' }),
  motorvehicleId: number({ invalid_type_error: 'This should be a number' }),
  valueOfGurantee: number({ invalid_type_error: 'This should be a number' }),
  valueOfOtherGurantee: number({ invalid_type_error: 'This should be a number' }),
  sumAssured: number({ invalid_type_error: 'This should be a number' }),
  fixedDeposit: number({ invalid_type_error: 'This should be a number' }),
  estimations: string().nonempty('This field is required')
})

export type CompanySecurityDetailsInput = TypeOf<typeof schemaS>

interface CompanySecurityDetailsProps {
  methods: UseFormReturn<CompanySecurityDetailsInput>
}

export const CompanySecurity: React.FC<CompanySecurityDetailsProps> = ({ methods }) => {
  const [loanData] = useAtom(companyLoanStore)
  const { control } = useFormContext<CompanySecurityDetailsInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'immovableProperty'
  })

  const addNewProperty = (): void => {
    append({
      immovable_property_id: 0,
      age: 0,
      estimated_forces_sale_value: 0,
      original_cost: 0,
      market_value: 0,
      encumbrances_amount: 0
    })
  }

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <div className={'w-full flex flex-col gap-4'}>
          <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
            Company Security
          </Text>
          <div className={'w-full'}>
            <CustomAccordion
              sections={[
                {
                  label: 'immovable property',
                  children: (
                    <>
                      <RowContainer>
                        <div className='flex justify-end flex-[1_0_230px] capitalize'>
                          <BasicButton
                            label={'add property'}
                            variant={'primary'}
                            size={'xs'}
                            onClick={addNewProperty}
                          />
                        </div>
                      </RowContainer>

                      {fields.map((field, index) => (
                        <div id={`property_${index + 1}`} className={'w-full mb-8'}>
                          <CategoryLabel label={`Property ${index + 1}`} />

                          <RowContainer>
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              type={'number'}
                              label={'Lot'}
                              defaultValue={
                                loanData?.loan_security?.immovable_property[index]
                                  ?.immovable_property_id
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableProperty?.[index]
                                    ?.immovable_property_id == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableProperty?.[index]
                                  ?.immovable_property_id?.message ?? ''
                              }
                              register={methods.register(
                                `immovableProperty.${index}.immovable_property_id`,
                                {
                                  valueAsNumber: true
                                }
                              )}
                            />
                          </RowContainer>
                          <RowContainer>
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'age'}
                              defaultValue={loanData?.loan_security?.immovable_property[index]?.age}
                              type={'number'}
                              error={
                                !(methods.formState.errors?.immovableProperty?.[index]?.age == null)
                              }
                              helperText={
                                methods.formState.errors?.immovableProperty?.[index]?.age
                                  ?.message ?? ''
                              }
                              register={methods.register(`immovableProperty.${index}.age`, {
                                valueAsNumber: true
                              })}
                            />
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'original cost(BWP)'}
                              type={'number'}
                              defaultValue={
                                loanData?.loan_security?.immovable_property[index]?.original_cost
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableProperty?.[index]
                                    ?.original_cost == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableProperty?.[index]?.original_cost
                                  ?.message ?? ''
                              }
                              register={methods.register(
                                `immovableProperty.${index}.original_cost`,
                                {
                                  valueAsNumber: true
                                }
                              )}
                            />
                          </RowContainer>
                          <RowContainer>
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'Market Value (BWP)'}
                              type={'number'}
                              defaultValue={
                                loanData?.loan_security?.immovable_property[index]?.market_value
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableProperty?.[index]
                                    ?.market_value == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableProperty?.[index]?.market_value
                                  ?.message ?? ''
                              }
                              register={methods.register(
                                `immovableProperty.${index}.market_value`,
                                {
                                  valueAsNumber: true
                                }
                              )}
                            />
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'Estimated Forced Sale Value(BWP)'}
                              type={'number'}
                              defaultValue={
                                loanData?.loan_security?.immovable_property[index]
                                  ?.estimated_forces_sale_value
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableProperty?.[index]
                                    ?.estimated_forces_sale_value == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableProperty?.[index]
                                  ?.estimated_forces_sale_value?.message ?? ''
                              }
                              register={methods.register(
                                `immovableProperty.${index}.estimated_forces_sale_value`,
                                {
                                  valueAsNumber: true
                                }
                              )}
                            />
                          </RowContainer>
                          <RowContainer>
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'Amount Encumbrances'}
                              type={'number'}
                              defaultValue={
                                loanData?.loan_security?.immovable_property[index]
                                  ?.encumbrances_amount
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableProperty?.[index]
                                    ?.encumbrances_amount == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableProperty?.[index]
                                  ?.encumbrances_amount?.message ?? ''
                              }
                              register={methods.register(
                                `immovableProperty.${index}.encumbrances_amount`,
                                {
                                  valueAsNumber: true
                                }
                              )}
                            />
                            <div className='flex justify-end flex-[1_0_230px] capitalize'>
                              <BasicButton
                                label={'Remove Property'}
                                color='error'
                                variant={'secondary'}
                                size={'xs'}
                                onClick={() => {
                                  remove(index)
                                }}
                                disabled={index === 0}
                              />
                            </div>
                          </RowContainer>
                        </div>
                      ))}
                    </>
                  )
                },
                {
                  label: 'Machinery and Equipment (must be 5 years or Less)',
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'machineryId'}
                          defaultValue={loanData?.loan_security?.loan_machinery?.machinery_id}
                          type={'number'}
                          error={!(methods.formState.errors.machineryId == null)}
                          helperText={methods.formState.errors?.machineryId?.message ?? ''}
                          register={methods.register('machineryId', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'age'}
                          defaultValue={loanData?.loan_security?.loan_machinery.age}
                          type={'number'}
                          error={!(methods.formState.errors.machineryAge == null)}
                          helperText={methods.formState.errors?.machineryAge?.message ?? ''}
                          register={methods.register('machineryAge', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'original cost(BWP)'}
                          type={'number'}
                          defaultValue={loanData?.loan_security?.loan_machinery?.original_cost}
                          error={!(methods.formState.errors.machineryOriginalCost == null)}
                          helperText={
                            methods.formState.errors?.machineryOriginalCost?.message ?? ''
                          }
                          register={methods.register('machineryOriginalCost', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>

                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Open Market/Book Value (BWP)'}
                          type={'number'}
                          defaultValue={loanData?.loan_security?.loan_machinery?.market_value}
                          error={!(methods.formState.errors.machineryMarketValue == null)}
                          helperText={methods.formState.errors?.machineryMarketValue?.message ?? ''}
                          register={methods.register('machineryMarketValue', {
                            valueAsNumber: true
                          })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Estimated Forced Sale Value(BWP)'}
                          type={'number'}
                          defaultValue={
                            loanData?.loan_security?.loan_machinery?.estimated_forces_sale_value
                          }
                          error={
                            !(
                              methods.formState.errors.machineryVehicleEstimatedForcedSaleVal ==
                              null
                            )
                          }
                          helperText={
                            methods.formState.errors?.machineryVehicleEstimatedForcedSaleVal
                              ?.message ?? ''
                          }
                          register={methods.register('machineryVehicleEstimatedForcedSaleVal', {
                            valueAsNumber: true
                          })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Amount Encumbrances'}
                          type={'number'}
                          defaultValue={
                            loanData?.loan_security?.loan_machinery?.encumbrances_amount
                          }
                          error={!(methods.formState.errors.machineryVehicleAmountEncumb == null)}
                          helperText={
                            methods.formState.errors?.machineryVehicleAmountEncumb?.message ?? ''
                          }
                          register={methods.register('machineryVehicleAmountEncumb', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'Motor Vehicle ( Should be 5 years or Less)',
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'motorvehicleId'}
                          type={'number'}
                          defaultValue={loanData?.loan_security?.loan_motor_vehicle?.vehicle_id}
                          error={!(methods.formState.errors.motorvehicleId == null)}
                          helperText={methods.formState.errors?.motorvehicleId?.message ?? ''}
                          register={methods.register('motorvehicleId', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'age'}
                          type={'number'}
                          defaultValue={loanData?.loan_security?.loan_motor_vehicle?.age}
                          error={!(methods.formState.errors.motorVehicleAge == null)}
                          helperText={methods.formState.errors?.motorVehicleAge?.message ?? ''}
                          register={methods.register('motorVehicleAge', {
                            valueAsNumber: true
                          })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'original cost(BWP)'}
                          type={'number'}
                          defaultValue={loanData?.loan_security?.loan_motor_vehicle?.original_cost}
                          error={!(methods.formState.errors.motorVehicleOriginalCost == null)}
                          helperText={
                            methods.formState.errors?.motorVehicleOriginalCost?.message ?? ''
                          }
                          register={methods.register('motorVehicleOriginalCost', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>

                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Open Market/Book Value (BWP)'}
                          type={'number'}
                          defaultValue={loanData?.loan_security?.loan_motor_vehicle?.market_value}
                          error={!(methods.formState.errors.motorVehicleMarketValue == null)}
                          helperText={
                            methods.formState.errors?.motorVehicleMarketValue?.message ?? ''
                          }
                          register={methods.register('motorVehicleMarketValue', {
                            valueAsNumber: true
                          })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Estimated Forced Sale Value(BWP)'}
                          type={'number'}
                          defaultValue={
                            loanData?.loan_security?.loan_motor_vehicle?.estimated_forces_sale_value
                          }
                          error={
                            !(methods.formState.errors.motorVehicleEstimatedForcedSaleVal == null)
                          }
                          helperText={
                            methods.formState.errors?.motorVehicleEstimatedForcedSaleVal?.message ??
                            ''
                          }
                          register={methods.register('motorVehicleEstimatedForcedSaleVal', {
                            valueAsNumber: true
                          })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Amount Encumbrances'}
                          type={'number'}
                          defaultValue={
                            loanData?.loan_security?.loan_motor_vehicle?.encumbrances_amount
                          }
                          error={!(methods.formState.errors.motorVehicleAmountEncumb == null)}
                          helperText={
                            methods.formState.errors?.motorVehicleAmountEncumb?.message ?? ''
                          }
                          register={methods.register('motorVehicleAmountEncumb', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'Bank Guarantee',
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'value of guarantee(BWP)'}
                          type={'number'}
                          defaultValue={
                            loanData?.loan_security?.loan_bank_guarantee?.guarantee_value
                          }
                          error={!(methods.formState.errors.valueOfGurantee == null)}
                          helperText={methods.formState.errors?.valueOfGurantee?.message ?? ''}
                          register={methods.register('valueOfGurantee', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'Any other Guarantee',
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'value of guarantee(BWP)'}
                          type={'number'}
                          defaultValue={
                            loanData?.loan_security?.loan_other_guarantee?.guarantee_value
                          }
                          error={!(methods.formState.errors.valueOfOtherGurantee == null)}
                          helperText={methods.formState.errors?.valueOfOtherGurantee?.message ?? ''}
                          register={methods.register('valueOfOtherGurantee', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'Life Insurance policy (Sum Assured)',
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Sum assured ceded to the bank(BWP)'}
                          type={'number'}
                          defaultValue={loanData?.loan_security?.loan_insurance_policy?.sum_assured}
                          error={!(methods.formState.errors.sumAssured == null)}
                          helperText={methods.formState.errors?.sumAssured?.message ?? ''}
                          register={methods.register('sumAssured', { valueAsNumber: true })}
                        />
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'Other( Fixed Deposit, Shares e.t.c)',
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'fixed deposit(BWP)'}
                          type={'number'}
                          defaultValue={
                            loanData?.loan_security?.loan_other_security?.fixed_deposit_balance
                          }
                          error={!(methods.formState.errors.fixedDeposit == null)}
                          helperText={methods.formState.errors?.fixedDeposit?.message ?? ''}
                          register={methods.register('fixedDeposit', { valueAsNumber: true })}
                        />
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'Basis  Of Your Estimation(As per stated security above)',
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Basis of your estimations'}
                          defaultValue={
                            loanData?.loan_security?.basis_of_estimation?.basis_of_estimation
                          }
                          error={!(methods.formState.errors.estimations == null)}
                          helperText={methods.formState.errors?.estimations?.message ?? ''}
                          register={methods.register('estimations')}
                        />
                      </RowContainer>
                    </>
                  ]
                }
              ]}
            />
          </div>
        </div>
      </MainContainer>
    </FormProvider>
  )
}
