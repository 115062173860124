import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface PostSprayersChecklist {
  inventory_item_type: number
  inventory_id: number
  nozel_output: number
  boom: number
  hoses: number
  pump_o_rings: number
  tank: number
  gasket: number
  regultors_guages_controls: number
  belts: number
  propeller_and_agitation: number
  // tires: number
  filters: number
  dispenser: number
  measuring_device: number
  gears: number
  result: boolean
  comments: string
  last_update_by: number
  item_verification_status: number
}

export const postSprayersChecklist = async (
  params: PostSprayersChecklist
): Promise<ApiResponse<[]>> => {
  return axios.post('/assessment/sprayers', params)
}
