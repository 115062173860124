import type React from 'react'
import { ProductionSchedule, type ProductionScheduleInput, schemaPSI } from './ProductionSchedule'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { type SubmitHandler } from 'react-hook-form'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import LoanButtonGroup from '../../generic/form/LoanButtonGroup'

interface ProductionScheduleIndividualBodyProps {
  className?: string
  onNext: () => void
  onBack: () => void
  steps: string[]
  activeStep: number
}

export const ProductionScheduleIndividualBody: React.FC<ProductionScheduleIndividualBodyProps> = ({
  className,
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const [, setLoanData] = useAtom(individualLoanItem)
  const onSubmitPSC: SubmitHandler<ProductionScheduleInput> = (data: ProductionScheduleInput) => {
    setLoanData((prev) => ({
      ...prev!,
      production_history: data.productionSchedule
    }))
    onNext()
  }
  return (
    <FormWithStepper<ProductionScheduleInput, typeof schemaPSI>
      className={'flex flex-col p-4 flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmitPSC}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schemaPSI}
    >
      {(methods) => (
        <>
          <ProductionSchedule methods={methods} />
          <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
            <LoanButtonGroup onBack={onBack} onSubmit={methods.handleSubmit(onSubmitPSC)} />
          </div>
        </>
      )}
    </FormWithStepper>
  )
}
