import type React from 'react'
import { Text } from 'components/Elements/Text/Text'
import { Button, Input } from 'components/ui'
import { Loader } from '../../../../../components/ui/loader'
import { useNewFarm } from '../../hooks/useNewFarm'
import { useNewFarmStore } from '../../stores/newFarm'

interface LandReviewProps {
  onBack: () => void
  onNext: () => void
}

export const LandReview: React.FC<LandReviewProps> = ({ onBack, onNext }) => {
  const { farmDetails } = useNewFarmStore()
  const { isLoading, location, loading, officer, createNewFarm } = useNewFarm()

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full'}>
        <Loader />
      </div>
    )
  }

  const officerName = `${officer?.firstname ?? ''} ${officer?.surname ?? ''}`

  const onSubmit = (): void => {
    createNewFarm(onNext)
  }

  return (
    <div className={'flex flex-col gap-1 w-full'}>
      <Text size={'medium'} variant={'primaryLight'} className={'mb-2 mx-3'}>
        Are details below correct?
      </Text>
      <Input withLabel label={'Farm Name'} disabled={true} value={farmDetails?.nickname} />
      <Input withLabel label={'Size (Ha)'} disabled={true} value={farmDetails?.size.toFixed(4)} />
      <Input withLabel label={'District'} disabled={true} value={location?.district_name} />
      <Input withLabel label={'Sub District'} disabled={true} value={location?.sub_district_name} />
      <Input withLabel label={'Extension'} disabled={true} value={location?.extension_area_name} />
      <Input withLabel label={'Village'} disabled={true} value={location?.village_name} />
      <Input
        withLabel
        label={'Extension Officer'}
        disabled={true}
        value={officerName === ' ' ? 'N/A' : officerName}
      />
      <div className={'w-full flex justify-between gap-4 mt-2'}>
        <Button variant={'secondary'} disabled={loading} className={'w-1/2'} onClick={onBack}>
          back
        </Button>
        <Button className={'w-1/2'} onClick={onSubmit} disabled={loading} loading={loading}>
          Complete
        </Button>
      </div>
    </div>
  )
}
