import type React from 'react'
import { useEffect } from 'react'
import { Card, Text, Loader } from '../../../../components/ui'
import { SeedsTable } from '../components/SeedsTable'
import { seedsColumns } from '../components/columns'
import { useSearchParams } from 'react-router-dom'
import { NotEligible } from '../../components/NotEligible'
import { NotFound } from '../../../../components/Errors/NotFound'
import { ErrorPage } from '../../../../components/Errors/ErrorPage'
import { useGetVoucherEligibility } from '../../api/getVoucherEligibility'
import { useGetCroppingPlanData } from '../../../../api/getStaticData'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { type SeedDealerFilter, type SeedDealerGrantCroppingPlanData } from '../../../../types'
import { find } from 'lodash'

export const SeedsVoucherRedemption: React.FC = () => {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('omang')
  const { data, isLoading, error } = useGetVoucherEligibility(query)
  const { seeds, setSeeds } = useStaticDataStore()
  const {
    data: staticData,
    isLoading: loadingStaticData,
    error: staticDataError
  } = useGetCroppingPlanData(seeds === null)

  useEffect(() => {
    if (staticData != null) {
      setSeeds(staticData.data.seeds)
    }
  }, [staticData])

  if (query == null) {
    return <NotFound />
  }

  if (isLoading || loadingStaticData) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null || staticDataError != null) {
    return <ErrorPage />
  }

  if (data?.data.exists === false) {
    return <NotEligible />
  }

  const seedsToShow: SeedDealerFilter[] = (
    data?.data.grant_cropping_plans_data as SeedDealerGrantCroppingPlanData[]
  ).filter((crops) => {
    return {
      crop_id: crops.crop_id,
      crop_type: crops.crop_type
    }
  })

  const filteredSeeds =
    seeds?.filter((seed) => {
      const res = find(seedsToShow, { crop_id: seed.crop_id, crop_type: seed.seed_type })
      return res != null
    }) ??
    staticData?.data?.seeds?.filter((seed) => {
      const res = find(seedsToShow, { crop_id: seed.crop_id, crop_type: seed.variety })
      return res != null
    })

  return (
    <div
      className={
        'md:max-w-[800px] lg:max-w-[1200px] xl:max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'
      }
    >
      <Text size={'large'}>Seeds Voucher Redemption</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <Text size={'medium'} variant={'bodyTextLight'}>
          Below are a list of seeds eligible for redemption.
        </Text>
        <Text size={'body'} variant={'bodyTextLight'}>
          Please select all applicable seeds to be bought from the table below.
        </Text>
        <SeedsTable columns={seedsColumns} data={filteredSeeds} />
      </Card>
    </div>
  )
}
