import { type ApiResponse, type Lease } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

export const getLeases = async (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined
): Promise<ApiResponse<Lease>> => {
  return axios.get(`/lease?ORIGIN_FARMER_ID=${farmerId ?? ''}&FARM_ID=${farmId ?? ''}`)
}

export const useGetFarmLeases = (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined
): UseQueryResult<ApiResponse<Lease>, AxiosError> => {
  return useQuery({
    queryKey: [`leases-${farmId ?? ''}`, farmerId],
    queryFn: async () => getLeases(farmerId, farmId),
    enabled: farmerId != null
  })
}
