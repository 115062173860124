import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface AcceptRenewalDTO {
  LEASE_ID: number | null | undefined
  FARMER_ID: number | null | undefined
  // STATUS_ID: number
  LAST_UPDATED_BY: number | null | undefined
  ACCEPTANCE: boolean
}

export const acceptRenewal = async (params: AcceptRenewalDTO): Promise<ApiResponse<[]>> => {
  return axios.put('/lease/renewal/accept', params)
}
