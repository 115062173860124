import { BasicButton } from 'components/Elements/Button'
import type React from 'react'
import { Button } from '../../../../../components/ui'

interface ApproveCroppingPlanProps {
  label: string
  onApprove: () => void
  onCancel: () => void
  isLoading: boolean
}

export const ApproveCroppingPlan: React.FC<ApproveCroppingPlanProps> = ({
  label,
  onApprove,
  isLoading,
  onCancel
}) => {
  return (
    <div className={'flex flex-col gap-6 justify-center items-center'}>
      <div className={'flex items-center pt-5 pb-5 text-xl font-bold'}>Approve {label}</div>
      <div className={'flex items-center pb-10 w-4/5 text-center'}>
        <p>Select confirm to approve the {label} and proceed.</p>
      </div>
      <div className={'flex justify-around w-full gap-12 lg:w-[80%]'}>
        <Button variant={'destructive'} onClick={onCancel} disabled={isLoading} size={'xs'}>
          Cancel
        </Button>
        <Button
          variant={'success'}
          onClick={onApprove}
          loading={isLoading}
          disabled={isLoading}
          size={'xs'}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}