/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import type React from 'react'
import { useAtom } from 'jotai/index'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { type BalanceSheetDetailsInput, BalanceSheets, schemaBS } from './BalanceSheet'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useMutation } from '@tanstack/react-query'
import {
  type CroppingPlanId,
  type ImmovablePropertySecurity,
  type Loan,
  type OtherLoansDetails,
  type ProductionDetails
} from 'features/loan-management/types/individual'
import { applicationHeader } from '../stores/applicationHeader'
import { individualLoanItem } from '../stores/individualLoanItem'
import { createLoan } from 'features/loan-management/api/createLoan'
import LoanButtonGroup from '../../generic/form/LoanButtonGroup'
import { type LoanStepperProps } from '../../../types'
import { toast } from 'react-hot-toast'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'

export const BalanceSheetDetailsBody: React.FC<LoanStepperProps> = ({
  className,
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const { farmer } = useFarmerStore()
  const [, setApplicationHead] = useAtom(applicationHeader)
  const mutation = useMutation({
    mutationFn: async (params: Loan) => {
      return createLoan(params)
    }
  })
  const onSubmit: SubmitHandler<BalanceSheetDetailsInput> = (data: BalanceSheetDetailsInput) => {
    mutation.mutate(
      {
        cropping_plan: loanData?.cropping_plan as CroppingPlanId[],
        total_amount: {
          loan_req1: loanData?.total_amount?.loan_req1 as number
        },
        individual_loan: {
          farmer_id: Number(farmer?.farmer_id),
          loan_req2: loanData?.individual_loan?.loan_req2 as number,
          loan_req3: loanData?.individual_loan?.loan_req3 as string,
          loan_req4: loanData?.individual_loan?.loan_req4 as string,
          period_at_physical_addr: loanData?.individual_loan?.period_at_physical_addr as number,
          period_at_prev_physical_addr: loanData?.individual_loan
            ?.period_at_prev_physical_addr as number,
          ownership_at_physical_addr: loanData?.individual_loan
            ?.ownership_at_physical_addr as string,
          source_reference: loanData?.individual_loan?.source_reference as string,
          title_id: loanData?.individual_loan?.title_id as string,
          last_update_by: Number(farmer?.user_id)
        },

        employee_information: {
          applicant_employer: loanData?.employee_information?.applicant_employer as string,
          applicant_employment_status: loanData?.employee_information
            ?.applicant_employment_status as string,
          applicant_job_title: loanData?.employee_information?.applicant_job_title as string,
          applicant_employment_start_date: loanData?.employee_information
            ?.applicant_employment_start_date as Date,
          applicant_monthly_income: loanData?.employee_information
            ?.applicant_monthly_income as number,
          applicant_other_income_source: loanData?.employee_information
            ?.applicant_other_income_source as string,
          permenant_employee_number: loanData?.employee_information
            ?.permenant_employee_number as number,
          casual_employee_number: loanData?.employee_information?.casual_employee_number as number,
          previous_year_turnover: loanData?.employee_information?.previous_year_turnover as number,
          farm_other_income: loanData?.employee_information?.farm_other_income as number
        },
        particulars_of_next_of_kin: {
          kin_first_name: loanData?.particulars_of_next_of_kin?.kin_first_name as string,
          kin_mid_name: loanData?.particulars_of_next_of_kin?.kin_mid_name as string,
          kin_last_name: loanData?.particulars_of_next_of_kin?.kin_last_name as string,
          kin_physical_address: loanData?.particulars_of_next_of_kin
            ?.kin_physical_address as string,
          kin_postal_address: loanData?.particulars_of_next_of_kin?.kin_postal_address as string,
          kin_email: loanData?.particulars_of_next_of_kin?.kin_email as string,
          kin_phone: loanData?.particulars_of_next_of_kin?.kin_phone as string,
          last_update_by: Number(farmer?.user_id)
        },
        commercial_bank_details: {
          bank_id: loanData?.commercial_bank_details?.bank_id as number,
          bank_branch_id: loanData?.commercial_bank_details?.bank_branch_id as number,
          bank_account_number: loanData?.commercial_bank_details?.bank_account_number as number,
          last_update_by: Number(farmer?.user_id)
        },
        loans_with_other_institutions_ndb:
          loanData?.loans_with_other_institutions_ndb as OtherLoansDetails[],
        history_of_ndb_loans: {
          loan_exists: loanData?.history_of_ndb_loans?.loan_exists as string
        },

        loan_spouse: {
          spouse_id: Number(loanData?.loan_spouse?.spouse_id) as unknown as number,
          spouse_first_name: loanData?.loan_spouse.spouse_first_name ?? '',
          spouse_mid_name: loanData?.loan_spouse?.spouse_mid_name ?? '',
          spouse_last_name: loanData?.loan_spouse?.spouse_last_name ?? '',
          spouse_physical_address: loanData?.loan_spouse?.spouse_physical_address ?? '',
          spouse_employment_status: loanData?.loan_spouse?.spouse_employment_status ?? '',
          spouse_shareholding_value: loanData?.loan_spouse?.spouse_shareholding_value ?? '',
          spouse_income: Number((loanData?.loan_spouse?.spouse_income as unknown as number) ?? 0),
          spouse_email: loanData?.loan_spouse?.spouse_email ?? '',
          spouse_phone: loanData?.loan_spouse?.spouse_phone ?? '',
          marital_status: loanData?.loan_spouse?.marital_status as string,
          spouse_job_title: loanData?.loan_spouse?.spouse_job_title ?? '',
          spouse_employer: loanData?.loan_spouse?.spouse_employer ?? '',
          spouse_employment_start_date:
            loanData?.loan_spouse?.spouse_employment_start_date ?? new Date(Date.now())
        },
        financial_particulars: {
          fp_checklist1: loanData?.financial_particulars?.fp_checklist1 as string,
          fp_checklist2: loanData?.financial_particulars?.fp_checklist2 as string,
          fp_checklist3: loanData?.financial_particulars?.fp_checklist3 as string,
          fp_checklist4: loanData?.financial_particulars?.fp_checklist4 as number,
          fp_checklist5: loanData?.financial_particulars?.fp_checklist5 as string,
          last_update_by: Number(farmer?.user_id)
        },
        loan_security: {
          immovable_property: loanData?.loan_security
            ?.immovable_property as ImmovablePropertySecurity[],
          loan_machinery: {
            age: loanData?.loan_security?.loan_machinery?.age as number,
            original_cost: loanData?.loan_security?.loan_machinery?.original_cost as number,
            market_value: loanData?.loan_security?.loan_machinery?.market_value as number,
            estimated_forces_sale_value: loanData?.loan_security?.loan_machinery
              ?.estimated_forces_sale_value as number,
            encumbrances_amount: loanData?.loan_security?.loan_machinery
              ?.encumbrances_amount as number,
            machinery_id: loanData?.loan_security?.loan_machinery?.machinery_id as number
          },
          loan_motor_vehicle: {
            age: loanData?.loan_security?.loan_motor_vehicle?.age as number,
            original_cost: loanData?.loan_security?.loan_motor_vehicle?.original_cost as number,
            market_value: loanData?.loan_security?.loan_motor_vehicle?.market_value as number,
            estimated_forces_sale_value: loanData?.loan_security?.loan_motor_vehicle
              ?.estimated_forces_sale_value as number,
            encumbrances_amount: loanData?.loan_security?.loan_motor_vehicle
              ?.encumbrances_amount as number,
            vehicle_id: loanData?.loan_security?.loan_motor_vehicle?.vehicle_id as number
          },
          loan_bank_guarantee: {
            guarantee_value: loanData?.loan_security?.loan_bank_guarantee?.guarantee_value as number
          },
          loan_other_guarantee: {
            guarantee_value: loanData?.loan_security?.loan_other_guarantee
              ?.guarantee_value as number
          },
          loan_insurance_policy: {
            sum_assured: loanData?.loan_security?.loan_insurance_policy?.sum_assured as number
          },
          loan_other_security: {
            fixed_deposit_balance: loanData?.loan_security?.loan_other_security
              ?.fixed_deposit_balance as number
          },
          basis_of_estimation: {
            basis_of_estimation: loanData?.loan_security?.basis_of_estimation
              ?.basis_of_estimation as string
          }
        },
        production_history: loanData?.production_history as ProductionDetails[],
        summarized_business_information: {
          farmer_id: Number(farmer?.farmer_id),
          background_info: loanData?.summarized_business_information?.background_info as string,
          process_info: loanData?.summarized_business_information?.process_info as string,
          key_assum: loanData?.summarized_business_information?.key_assum as string,
          market_info: loanData?.summarized_business_information?.market_info as string,
          competitors: loanData?.summarized_business_information?.competitors as string,
          distribution: loanData?.summarized_business_information?.distribution as string,
          last_update_by: Number(farmer?.user_id)
        },
        balance_sheets: {
          general_information: {
            marital_regime: data.marital_regime,
            balance_sheet_date: data?.balanceSheetdDate
          },
          immovable_assets: data.immovableAssets,
          movable_assets: {
            movable_property_id: data.propertyId,
            company_name: data.nameOfTheCompany,
            number_of_shares: data.numberOfShares,
            estimated_share_value: data.estimatedValueOfShares,
            total_number_of_shares: data.totalNumbersOfShares,
            type_of_assets: data.typeOfAsserts,
            year_of_acquisition: data.yearOfAcquisition,
            net_book_value: data.netBookValue,
            estimated: data.estimatedVal,
            livestock_number: data.livestock_number,
            livestock: data.livestock,
            recievables: data.debtors,
            total_cash: data.cash,
            fixed_deposits: data.fixedDeposits,
            other_assets: data.otherAssets
          },
          liablities: {
            mortgage_loans: data.loansMortgage,
            hire_purchase_loans: data.loansHirePurchase,
            tax_liabilities: data.taxLiabilities,
            personal_loans: data.personalLoans,
            creditors: data.creditors,
            other_liabilities: data.otherLiabilities
          },
          declaration: {
            plot_number: '',
            location: '',
            type_of_land: '',
            size: 0,
            remaining_lease_period: 0,
            estimated_value: 0
          }
        },

        last_update_by: Number(farmer?.user_id)
      },
      {
        onSuccess: (result) => {
          if (result.status === 201 && result.success) {
            setApplicationHead((prev) => ({
              ...prev,
              loan_application_id: Number(result?.data?.loan_application_id)
            }))
            toast.success('Your loan is submitted to the CMS Database!!')
            onNext()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast.error(
              mapCmsErrorToMessage(error.status.toString() ?? err.code ?? error.data.message ?? '')
            )
          } else {
            const error = err as Error
            toast.error(mapCmsErrorToMessage(error.message))
          }
        }
      }
    )
  }

  return (
    <div className={'flex flex-col gap-8'}>
      <FormWithStepper<BalanceSheetDetailsInput, typeof schemaBS>
        className={'flex flex-col p-4 flex-grow-[3] justify-between w-full'}
        isLoading={false}
        isDisabled={false}
        onSubmit={onSubmit}
        steps={steps}
        activeStep={activeStep}
        onBack={onBack}
        schema={schemaBS}
      >
        {(methods) => (
          <>
            <BalanceSheets methods={methods} />
            <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
              <LoanButtonGroup
                onBack={onBack}
                onSubmit={methods.handleSubmit(onSubmit)}
                isLoading={mutation.isLoading}
                disabled={mutation.isLoading}
              />
            </div>
          </>
        )}
      </FormWithStepper>
    </div>
  )
}
