import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from 'features/landing/components'
import muBanner from 'assets/usermanuals/mubanner.png'
import { Button, Layout } from 'components/ui'
export const ManualBanner: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Layout
      variant={'body'}
      className={'bg-[var(--color-secondary-dark)] w-full flex relative'}
    >
      <Layout className={'flex flex-wrap px-2'}>
        <div
          className={
            'min-h-[40vh] relative flex flex-[1_0_360px] sm:flex-[1_0_450px] order-1 md:order-2 px-2 z-2'
          }
        >
          <img
            src={muBanner}
            alt={'banner image of a user manual'}
            className={'w-full self-center'}
          />
        </div>
        <div
          className={
            'max-w-[500px] flex relative flex-col self-end mb-[10%] flex-[1_0_360px] sm:flex-[1_0_450px] items-center md:text-left mx-[auto] gap-2  p-4 px-4  order-2 md:order-1'
          }
        >
          <Text
            size={'xLarge'}
            variant='primaryLight'
            className={
              'w-[100vw] text-[--color-success] text-center sm:text-left text-[clamp(4rem,10vw,8rem)] sm:text-[clamp(6rem,12vw,12rem)]  md:text-[clamp(8rem,12vw,10rem)] absolute md:z-[0] left-0 bottom-[100%]'
            }
          >
            User Manual
          </Text>

          <Text size='medium' className={'text-center text-white'}>
            Learn to use Crop Management System like a pro!
          </Text>
          <div
            className={
              'flex w-full mx-[auto] md:mx-0 flex-wrap gap-2 sm:gap-4 sm:mt-8 mt-4 justify-center md:justify-start'
            }
          >
            <Button className={'flex-[1_0_150px] text-white p-2'}>
              Download
              <p className='fa-solid fa-download fa-bounce' />
            </Button>
            <Button
              className={'flex-[1_0_150px] text-white dark:text-foreground hover:text-foreground'}
              variant={'secondary'}
            >
              tutorials
              <p className='fa-solid fa-play fa-bounce' />
            </Button>
          </div>
        </div>
      </Layout>
    </Layout>
  )
}
