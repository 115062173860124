import { ResendOtp } from 'components/Generic/ResendOtp'
import { useAuth } from 'features/authentication/hooks/useAuth'
import { usePhoneLogin } from 'features/authentication/hooks/usePhoneLogin'
import { getTimerTime } from 'features/authentication/utils/otp'
import { type ConfirmationResult } from 'firebase/auth'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { useAuthStore } from 'stores/useAuthStore'
import { useOtpStore } from 'stores/useOtpStore'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from 'components/ui'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp'
import { useToast } from 'components/ui/use-toast'

interface LoginOtpFormProps {
  className?: string
  number: string
  onSuccess: () => void
  onFailure: () => void
  confirm: ConfirmationResult | undefined
}

const FormSchema = z.object({
  otp: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.'
  })
})

export const LoginOtpForm: React.FC<LoginOtpFormProps> = ({
  className,
  number,
  onSuccess,
  confirm,
  onFailure
}) => {
  const { toast } = useToast()
  const {
    setExpiryTimeOfResend,
    resendCounter,
    expiryTimeOfResend,
    setTimeOfFirstResend,
    setResendCounter
  } = useOtpStore()
  const [isResend, setIsResend] = useState(false)
  const { verifyOtp, resendOtp, phoneLoginResponse, otpVerificationStatus, signOutUser } = useAuth()
  const { isLoading, role, error, user, userId } = useAuthStore()
  const { loading, isUser } = usePhoneLogin(role, userId)
  const { hours, minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp:
      expiryTimeOfResend !== null ? new Date(expiryTimeOfResend) : new Date(Date.now())
  })
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      otp: ''
    }
  })

  const hiddenNumber = String(number).slice(-3)

  const onSubmit = async (data: z.infer<typeof FormSchema>): Promise<void> => {
    if (!isResend) {
      await verifyOtp(confirm, data.otp)
    } else {
      await verifyOtp(phoneLoginResponse.confirm, data.otp)
    }
  }

  useEffect(() => {
    if (error !== null) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: error
      })
    }

    if (user !== null && (role === null || userId === null)) {
      signOutUser()
      onFailure()
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'user does not exist.'
      })
    }

    if (
      resendCounter > 1 &&
      expiryTimeOfResend !== null &&
      new Date(Date.now()) < expiryTimeOfResend
    ) {
      restart(expiryTimeOfResend)
    }

    if (isUser && role !== null) {
      onSuccess()
    }
  }, [error, isUser, otpVerificationStatus, expiryTimeOfResend, resendCounter])

  const handleResendOtp = async (): Promise<void> => {
    if (resendCounter === 0) {
      setTimeOfFirstResend(new Date(Date.now()))
    }

    const counter = resendCounter + 1
    setResendCounter(resendCounter + 1)

    setExpiryTimeOfResend(getTimerTime(new Date(Date.now()), counter))
    restart(getTimerTime(new Date(Date.now()), counter))

    await resendOtp(number)

    setIsResend(true)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={'flex flex-col gap-4 md:gap-6 lg:gap-8 items-center'}
      >
        <FormField
          control={form.control}
          name='otp'
          render={({ field }) => (
            <FormItem className='flex flex-col gap-2 items-center'>
              <FormLabel className={'text-center text-lg font-bold'}>One-Time Password</FormLabel>
              <FormDescription className={'text-center'}>
                Enter OTP sent to a phone number that ends with{' '}
                <span className={'text-primary'}>*****{hiddenNumber}</span>
              </FormDescription>
              <FormControl>
                <InputOTP maxLength={6} {...field}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <p className={'text-center'}>Didn't Get Any Code? Request A New Code.</p>
        <ResendOtp
          seconds={seconds}
          minutes={minutes}
          hours={hours}
          isDisabled={(isRunning && seconds + minutes + hours > 0) || loading || isLoading}
          onResend={handleResendOtp}
        />
        <div className='w-full flex justify-center'>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'lg'}
            disabled={loading || isLoading}
            loading={loading || isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
    </Form>
  )
}
