import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateSeedingDTO {
  cp_id: number | null | undefined
  seed_type: 'OPV' | 'HYBRID'
  seed_variety: string | null | undefined
  target_yield: number
  last_update_by: number | null | undefined
  farmer_id: number | null | undefined
}

export const updateSeeding = async (params: UpdateSeedingDTO): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/seeding`, params)
}
