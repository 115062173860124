import { BasicButton } from 'components/Elements/Button'
import { Form, InputField } from 'components/Form'
import { useCompanyRegistration } from 'features/authentication/hooks/useCompanyRegistration'
import { useCompanyRegistrationStore } from 'features/authentication/stores/useCompanyRegistrationStore'
import { useOtpVerification } from 'hooks/useOtpVerification'
import type React from 'react'
import { useEffect } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { object, string, type TypeOf } from 'zod'

interface ValidateCompanyDirectorProps {
  className?: string
  showOtp: () => void
  onBack: () => void
  onNext: () => void
}

const schema = object({
  id: string().min(1, 'ID is required')
})

export type CompanyDirectorInput = TypeOf<typeof schema>

export const GetCompanyDirector: React.FC<ValidateCompanyDirectorProps> = ({
  onBack,
  onNext,
  showOtp,
  className
}) => {
  const { error, isLoading, validateDirector } = useCompanyRegistration()
  const { directorDetails, directorIDDetails } = useCompanyRegistrationStore()
  const { isLoading: isLoadingOtp, sendOtpMessage } = useOtpVerification(
    '',
    directorDetails?.director_contact_number ?? ''
  )

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }
  }, [error])

  const sendMessage = (): void => {
    sendOtpMessage(showOtp)
  }

  const onSubmit: SubmitHandler<CompanyDirectorInput> = (data: CompanyDirectorInput): void => {
    const isNumberVerified = directorDetails?.id != null && directorDetails.id !== data.id
    validateDirector(data, isNumberVerified ? onNext : sendMessage)
  }

  return (
    <Form<CompanyDirectorInput, typeof schema>
      className={className}
      onSubmit={onSubmit}
      schema={schema}
    >
      {({ register, formState: { errors }, handleSubmit }) => (
        <>
          <InputField
            label={'Omang/Passport'}
            error={!(errors.id == null)}
            helperText={errors?.id?.message ?? ''}
            register={register('id')}
            defaultValue={directorIDDetails != null ? directorIDDetails : ''}
          />
          <div className={'flex flex-row justify-between gap-8'}>
            <BasicButton
              label={'Back'}
              variant={'secondary'}
              onClick={onBack}
              isDisabled={isLoading || isLoadingOtp}
            />
            <BasicButton
              label={'Next'}
              variant={'primary'}
              isDisabled={isLoading || isLoadingOtp}
              isLoading={isLoading || isLoadingOtp}
              onClick={handleSubmit(onSubmit)}
              type={'submit'}
            />
          </div>
        </>
      )}
    </Form>
  )
}
