import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import {
  type ApiResponse,
  type ApiResponseWithObject,
  type CroppingPlan,
  type CroppingPlanDetails
} from 'types'

export const getCroppingPlanDetails = async (
  cp_id: string | null | undefined,
  farmerId: string | null | undefined
): Promise<ApiResponseWithObject<CroppingPlanDetails>> => {
  return axios.get(
    `/production/header/details?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}&user_role=1001`
  )
}

export const useGetCroppingPlanDetails = (
  cp_id: string | null | undefined,
  farmerId: string | null | undefined
): UseQueryResult<ApiResponseWithObject<CroppingPlanDetails>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['cropping-plan-details', cp_id, farmerId],
    queryFn: async () => getCroppingPlanDetails(cp_id, farmerId),
    enabled: cp_id != null && farmerId != null
  })
}

export const getCroppingPlan = async (
  cp_id: string | null | undefined,
  farmerId: string | null | undefined
): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(`/production/header?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}

export const useGetCroppingPlan = (
  cp_id: string | null | undefined,
  farmerId: string | null | undefined
): UseQueryResult<ApiResponse<CroppingPlan>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['cropping-plan', cp_id, farmerId],
    queryFn: async () => getCroppingPlan(cp_id, farmerId),
    enabled: cp_id != null && farmerId != null
  })
}
