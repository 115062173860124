import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface AcceptRequestDTO {
  user_id: number | null | undefined
  farm_id: number
  farmer_id: number
  merchant_id: number
  ssr_id: number
}

export const acceptRequest = async (params: AcceptRequestDTO): Promise<ApiResponse<[]>> => {
  return axios.put('/ssr/accept', params)
}
