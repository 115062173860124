/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { BasicButton } from 'components/Elements/Button'

import { MainContainer } from 'features/loan-management/components/stepper'
import type React from 'react'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import { Text } from '../../../../../components/Elements/Text/Text'
import { LoanStepper } from './LoanStepper'

interface SecurityOptionalProps {
  className?: string
  onNext: () => void
  onBack: () => void
  steps: string[]
  activeStep: number
}

export const SecurityOptional: React.FC<SecurityOptionalProps> = ({
  onBack,
  onNext,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)

  const onSubmit = (): void => {
    setLoanData((prev) => ({
      ...prev!,
      loan_security: {
        immovable_property: [
          {
            immovable_property_id: 0,
            age: 0,
            estimated_forces_sale_value: 0,
            original_cost: 0,
            market_value: 0,
            encumbrances_amount: 0
          }
        ],
        loan_machinery: {
          age: 0,
          original_cost: 0,
          market_value: 0,
          estimated_forces_sale_value: 0,
          encumbrances_amount: 0,
          machinery_id: 0
        },
        loan_motor_vehicle: {
          age: 0,
          original_cost: 0,
          market_value: 0,
          estimated_forces_sale_value: 0,
          encumbrances_amount: 0,
          vehicle_id: 0
        },
        loan_bank_guarantee: { guarantee_value: 0 },
        loan_other_guarantee: { guarantee_value: 0 },
        loan_insurance_policy: { sum_assured: 0 },
        loan_other_security: { fixed_deposit_balance: 0 },
        basis_of_estimation: {
          basis_of_estimation: '0'
        }
      }
    }))
    onNext()
  }
  return (
    <LoanStepper
      steps={steps}
      activeStep={activeStep}
      next={onNext}
      back={onBack}
      stepperKey={'attachments'}
    >
      <MainContainer>
        <div className={'w-full flex flex-col gap-4'}>
          <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
            Security
          </Text>

          <Text
            size={'large'}
            variant={'bodyTextLight'}
            className={' flex items-center justify-center ml-4'}
          >
            You do not qualify for security
          </Text>
        </div>
        <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
          <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
          <BasicButton label={'Next'} variant={'primary'} onClick={onSubmit} size={'xs'} />
        </div>
      </MainContainer>
    </LoanStepper>
  )
}
