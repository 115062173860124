import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface PostTractorChecklist {
  inventory_id: number
  lights: number
  starting: number
  gauges: number
  cooling_leakages: number
  fuel_leakages: number
  lubrication_leakages: number
  hydraulic_oil_leakages: number
  transmission_leakages: number
  smoke_color: number
  oil_check: number
  greasing: number
  pto: number
  gear_shifting: number
  lifting_and_releasing: number
  hitch_pins: number
  bearings: number
  tyres: number
  mirrors: number
  functional_gps: boolean
  engine_stalling: boolean
  positioning_and_draft_control: boolean
  breaking_system: boolean
  steering_system: boolean
  diff_loc: boolean
  result: boolean
  item_verification_status: number
  comments: string
  last_update_by: number
}

export const postTractorChecklist = async (
  params: PostTractorChecklist
): Promise<ApiResponse<[]>> => {
  return axios.post('/assessment/tractor', params)
}
