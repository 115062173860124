import type React from 'react'
import { Text } from 'components/ui'

export const UnderMaintenance: React.FC = () => {
  return (
    <main className={'flex flex-col gap-4 h-screen justify-center items-center'} role='alert'>
      <Text size={'xLarge'} variant={'primaryLight'}>
        We are Under Maintenance!
      </Text>
      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        The website is currently undergoing a scheduled maintenance. We will be back shortly.
      </Text>
    </main>
  )
}
