import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { type Lease } from 'types'
import { Card, Text } from 'components/ui'
import { LeaseStatus } from './LeaseStatus'
import { ArrowUpRightFromSquare } from 'lucide-react'

interface LeaseCardResultsProps {
  lease: Lease
}

export const LeaseCardResults: React.FC<LeaseCardResultsProps> = ({ lease }) => {
  const navigate = useNavigate()

  const handleClick = (): void => {
    navigate(`/officer/farmers/lease/${lease.dest_farmer_id}/${lease.lease_id}`)
  }

  const hoverStyles =
    lease.status_desc === 'ACCEPTED BY LESSEE'
      ? 'transition-shadow duration-200 ease-in-out transform hover:shadow-lg hover:shadow-md hover:shadow-input hover:border-second dark:hover:border-second-foreground'
      : ''
  const cursorStyle =
    lease.status_desc === 'ACCEPTED BY LESSEE'
      ? 'cursor-pointer'
      : 'shadow-none border-muted cursor-default'

  return (
    <Card
      className={`md:max-w-[500px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 hover:${hoverStyles} ${cursorStyle}`}
      onClick={handleClick}
    >
      <div className={'flex items-center justify-between'}>
        <div className={'flex gap-4 items-center justify-between'}>
          <div className={'flex gap-1 items-baseline'}>
            <Text size={'large'}>{lease.leased_size.toFixed(4)}</Text>
            <Text>ha</Text>
          </div>
        </div>
        {lease.status_desc === 'ACCEPTED BY LESSEE' && <ArrowUpRightFromSquare />}
      </div>

      <div className={'w-full flex flex-col'}>
        <Text size={'medium'} className={'capitalize'}>
          {lease.farm_name.toLowerCase()}
        </Text>
        <Text
          className={'capitalize'}
          variant={'bodyTextLight'}
        >{`${lease.village.toLowerCase()}, ${lease.extension_area.toLowerCase()}`}</Text>
      </div>

      <LeaseStatus status={lease.status_desc} />
    </Card>
  )
}
