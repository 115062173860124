import { OtpForm } from 'components/Form/OtpForm'
import { useOtpVerification } from 'hooks/useOtpVerification'
import type React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { type DirectorDetails } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'

interface VerifyDirectorOtpFormProps {
  className?: string
  directorDetails: DirectorDetails
  onSuccess: () => void
}

export const VerifyDirectorOtpForm: React.FC<VerifyDirectorOtpFormProps> = ({
  directorDetails,
  onSuccess,
  className
}) => {
  const [otp, setOtp] = useState<string>('')
  const { resendOtpMessage, isOtpValid, verifyOtpMessage, isLoading } = useOtpVerification(
    otp,
    directorDetails.director_contact_number
  )

  useEffect(() => {
    if (isOtpValid) {
      onSuccess()
    }
  }, [isOtpValid])

  const handleChange = (newValue: string): void => {
    setOtp(newValue)
  }

  return (
    <OtpForm
      className={className}
      onSubmit={verifyOtpMessage}
      number={directorDetails.director_contact_number}
      otp={otp}
      onChange={handleChange}
      isLoading={isLoading}
      resendOtp={resendOtpMessage}
    />
  )
}
