import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Autoplay, Navigation } from 'swiper'

// Import your images here
import sliderImage1 from 'assets/landing/swiper/20230119_1.jpg'
import sliderImage2 from 'assets/landing/swiper/20230119_2.jpg'
import sliderImage3 from 'assets/landing/swiper/20230119_3.jpg'
import sliderImage4 from 'assets/landing/swiper/20230119_4.jpg'
import sliderImage5 from 'assets/landing/swiper/20230119_5.jpg'
import sliderImage6 from 'assets/landing/swiper/20230119_6.jpg'
import sliderImage7 from 'assets/landing/swiper/20230119_7.jpg'
import sliderImage8 from 'assets/landing/swiper/20230119_8.jpg'
import sliderImage9 from 'assets/landing/swiper/20230119_9.jpg'
import sliderImage10 from 'assets/landing/swiper/20230119_10.jpg'
import sliderImage11 from 'assets/landing/swiper/20230119_11.jpg'
import sliderImage12 from 'assets/landing/swiper/20230119_12.jpg'
import sliderImage13 from 'assets/landing/swiper/20230119_13.jpg'
import sliderImage20 from 'assets/landing/swiper/20230119_20.jpg'
import sliderImage21 from 'assets/landing/swiper/20230119_21.jpg'

const ImageSet = [
  sliderImage2,
  sliderImage3,
  sliderImage4,
  sliderImage6,
  sliderImage7,
  sliderImage8,
  sliderImage1,
  sliderImage5,
  sliderImage21,
  sliderImage9,
  sliderImage10,
  sliderImage12,
  sliderImage20,
  sliderImage11,
  sliderImage13
]

export const SwiperHero: React.FC = () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides
      autoplay={{
        delay: 4000,
        disableOnInteraction: false
      }}
      loop
      pagination={{
        clickable: true
      }}
      navigation
      modules={[Autoplay, Navigation]}
      className='relative flex items-center justify-center'
    >
      {ImageSet.map((url, index) => (
        <SwiperSlide key={index}>
          <img src={url} alt={`Slide ${index}`} className='object-contain w-full h-full' />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
