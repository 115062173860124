import React from 'react'
import type z from 'zod'
import { array, coerce, object } from 'zod'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text
} from 'components/ui'
import { type Table } from '@tanstack/react-table'
import { type SelectedServices } from '../types'
import { type Service } from '../../types'

interface VoucherFormProps<TData> {
  className?: string
  table: Table<TData>
  setSelectedData: React.Dispatch<React.SetStateAction<SelectedServices[]>>
  getContact: () => Promise<void>
  loading: boolean
}

const ServicesVoucherFormSchema = object({
  price: coerce
    .number({ required_error: 'price is required', invalid_type_error: 'price should be a number' })
    .min(1, 'price should be greater than 1')
    .nonnegative('price cannot be smaller than 1'),
  units: coerce
    .number({
      required_error: 'no.Of units is required',
      invalid_type_error: 'no.Of units should be a number'
    })
    .min(1, 'no.Of units should be greater than 1')
    .nonnegative('no.Of units cannot be smaller than 1')
})

const FormSchema = object({
  services: array(ServicesVoucherFormSchema).min(1, 'field is required')
})

export function ServicesVoucherForm<TData>({
  className,
  table,
  setSelectedData,
  loading,
  getContact
}: VoucherFormProps<TData>): React.ReactNode {
  const servicesNumber = table.getSelectedRowModel().rows.length
  const serviceTypes = table.getSelectedRowModel().rows.map((row) => row.original as Service)
  const defaultValues = Array(servicesNumber)
    .fill(1)
    .map((_, i) => {
      return { price: 0, units: 0 }
    })
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      services: defaultValues
    }
  })
  const { fields } = useFieldArray({
    control: form.control,
    name: 'services'
  })

  const onSubmit = (data: z.infer<typeof FormSchema>): void => {
    const selectedServicesData = serviceTypes.map((service, index) => {
      return {
        id: service.service_header_id,
        quantity: data.services[index].units,
        price: data.services[index].price
      }
    })

    setSelectedData(selectedServicesData)
    void getContact()
  }

  const totalPrice = form
    .getValues('services')
    .reduce((a, b) => a + Number(b.price) * Number(b.units), 0)

  return (
    <Form {...form}>
      <form className={className} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name='services'
          render={() => (
            <FormItem className={'flex flex-col gap-4'}>
              <div className='flex flex-col gap-2'>
                <FormLabel className={'text-center text-lg'}>Services Voucher Redemption</FormLabel>
                <FormDescription className={'flex flex-col gap-2 text-center text-md'}>
                  Enter the details for the services below.
                </FormDescription>
              </div>
              {fields.map((arr, index) => (
                <FormField
                  key={arr.id}
                  control={form.control}
                  name={`services.${index}`}
                  render={({ field }) => (
                    <div className={'w-full flex flex-col gap-4'}>
                      <FormLabel
                        className={'font-bold'}
                      >{`${serviceTypes[index].service_type} - ${serviceTypes[index].service_name} - ${serviceTypes[index].plough_season}`}</FormLabel>

                      <div className={'grid grid-cols-1 md:grid-cols-2 gap-4'}>
                        <FormField
                          key={React.useId()}
                          control={form.control}
                          name={`services.${index}.price`}
                          render={({ field: priceField }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel>Price</FormLabel>
                              <FormDescription>
                                Max Price: BWP/Ha {serviceTypes[index].price}
                              </FormDescription>
                              <FormControl>
                                <Input
                                  type={'text'}
                                  inputMode={'numeric'}
                                  pattern='[0-9*]'
                                  {...priceField}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        ></FormField>

                        <FormField
                          key={React.useId()}
                          control={form.control}
                          name={`services.${index}.units`}
                          render={({ field: unitsField }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel>Size</FormLabel>
                              <FormDescription>
                                {serviceTypes[index].unit_of_measure}
                              </FormDescription>
                              <FormControl>
                                <Input
                                  type={'text'}
                                  inputMode={'numeric'}
                                  pattern='[0-9*]'
                                  {...unitsField}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        ></FormField>
                      </div>
                    </div>
                  )}
                />
              ))}
            </FormItem>
          )}
        ></FormField>

        <div className={'flex flex-col md:flex-row justify-between items-center gap-4'}>
          <Text size={'large'} variant={'success'}>
            Total Price: BWP {isNaN(totalPrice) ? 0 : totalPrice.toFixed(2)}
          </Text>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'xs'}
            disabled={loading}
            loading={loading}
          >
            Continue
          </Button>
        </div>
      </form>
    </Form>
  )
}
