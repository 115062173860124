import type React from 'react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import type { Farm } from 'types'

interface FarmTableProps {
  farms: Farm[]
  onClick: (item: Farm) => void
}

export const FarmTable: React.FC<FarmTableProps> = ({ farms, onClick }) => {
  return (
    <Table>
      <TableCaption>List of farms pending verification.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>Farmer ID</TableHead>
          <TableHead className='font-bold text-color-text'>Farmer Name</TableHead>
          <TableHead className='font-bold text-color-text'>Farm Name</TableHead>
          <TableHead className='font-bold text-color-text'>Extension Area</TableHead>
          <TableHead className='font-bold text-color-text'>Name</TableHead>
          <TableHead className='font-bold text-color-text'>Contact</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {farms.map((farm) => (
          <TableRow
            onClick={() => {
              onClick(farm)
            }}
            key={farm.farm_id}
            role={'listitem'}
            className={'hover:cursor-pointer hover:bg-muted-hover'}
          >
            <TableCell className='font-medium'>{farm.farmer_id}</TableCell>
            <TableCell className='font-medium'>{farm.farmer_name}</TableCell>
            <TableCell className='font-medium'>{farm.farm_name}</TableCell>
            <TableCell className='font-medium'>{farm.extension_area_name}</TableCell>
            <TableCell className='font-medium'>{farm.village_name}</TableCell>
            <TableCell className='font-medium'>{farm.farmer_contact}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
