import { GetCompanyDirector } from 'features/authentication/components/company/GetCompanyDirector'
import { VerifyDirectorOtpForm } from 'features/authentication/components/company/VerifyDirectorOtpForm'
import { useCompanyRegistrationStore } from 'features/authentication/stores/useCompanyRegistrationStore'
import type React from 'react'
import { useState } from 'react'
import { object, string, type TypeOf } from 'zod'

interface ValidateCompanyDirectorProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

const schema = object({
  id: string().min(1, 'ID is required')
})

export type CompanyDirectorInput = TypeOf<typeof schema>

export const ValidateCompanyDirector: React.FC<ValidateCompanyDirectorProps> = ({
  onNext,
  onBack,
  className
}) => {
  const { directorDetails } = useCompanyRegistrationStore()
  const [showOtpForm, setShowOtpForm] = useState(false)

  const showOtp = (): void => {
    setShowOtpForm(!showOtpForm)
  }

  if (showOtpForm) {
    return (
      <div className={className}>
        <VerifyDirectorOtpForm
          className={'flex flex-col gap-4 md:gap-6 lg:gap-8'}
          directorDetails={directorDetails!}
          onSuccess={onNext}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <GetCompanyDirector
        className={'flex flex-col gap-4'}
        showOtp={showOtp}
        onBack={onBack}
        onNext={onNext}
      />
    </div>
  )
}
