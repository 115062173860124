import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type PestControl } from 'types'
import { type AxiosError } from 'axios'

export const getPestControl = async (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): Promise<ApiResponseWithObject<PestControl>> => {
  return axios.get(`/production/pest?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}

export const useGetPestControl = (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): UseQueryResult<ApiResponseWithObject<PestControl>, AxiosError> => {
  return useQuery({
    queryKey: ['pest-control', farmerId, cp_id],
    queryFn: async () => getPestControl(farmerId, cp_id),
    enabled: farmerId != null && cp_id != null
  })
}
