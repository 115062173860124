import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type ApiResponseWithObject, type Farmer } from 'types'

export const getFarmer = async (
  farmerId: string | number | null | undefined,
  extAreaId: number | null | undefined
): Promise<ApiResponseWithObject<Farmer>> => {
  return axios.get(`/farmer/${farmerId ?? ''}?EXTENSION_AREA=${extAreaId ?? ''}`)
}

export const useGetFarmer = (
  farmerId: string | number | null | undefined,
  extAreaId: number | null | undefined
): UseQueryResult<ApiResponseWithObject<Farmer>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farmer', farmerId, extAreaId],
    queryFn: async () => getFarmer(farmerId, extAreaId),
    enabled: extAreaId != null && farmerId != null
  })
}
