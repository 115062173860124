import { useCallback } from 'react'
import { type z, type ZodSchema } from 'zod'
import dayjs from 'dayjs'
import { createUpateInventoryItem } from './createUpateInventoryItem'

interface Farmer {
  farmer_id: number
  user_id: number
}

interface SelectedItem {
  model_id: number
}

interface InventoryData {
  inventory_id: number
}

interface UseSubmitInventoryItemParams {
  farmer?: Farmer
  selectedItem?: SelectedItem
  inventoryData?: InventoryData
  isEdit: boolean
  inventoryTypeId: number
  onClose: () => void
}

export const useSubmitInventoryItem = <T extends ZodSchema>({
  schema,
  farmer,
  selectedItem,
  inventoryData,
  isEdit,
  inventoryTypeId,
  onClose
}: UseSubmitInventoryItemParams & { schema: T }): {
  onSubmit: (data: z.infer<T>) => void
  mutation: ReturnType<typeof createUpateInventoryItem>
} => {
  const mutation = createUpateInventoryItem({ isEdit, onClose })

  const onSubmit = useCallback(
    (data: z.infer<T>): void => {
      const commonParams = {
        farmer_id: Number(farmer?.farmer_id),
        yom: dayjs(String(data.yom)).format('YYYY'),
        reg_number: String(data.reg_number),
        inventory_item_status_id: String(data.inventorystatus),
        inventory_item_verification_status_id: 'PENDING_VERIFICATION',
        last_updated_by: Number(farmer?.user_id)
      }

      const params = isEdit
        ? {
            ...commonParams,
            inventory_id: inventoryData?.inventory_id ?? 0,
            resource_item: Number(selectedItem?.model_id),
            inventory_type_id: inventoryTypeId
          }
        : {
            ...commonParams,
            resource_item: Number(selectedItem?.model_id),
            inventory_type_id: inventoryTypeId
          }

      mutation.mutate(params)
    },
    [farmer, selectedItem, inventoryData, isEdit, schema, mutation]
  )

  return { onSubmit, mutation }
}
