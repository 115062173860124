/* eslint-disable prettier/prettier */

interface StepsLabelProps {
  label: string
  variant?: 'default' | 'secondary'
}
export const StepLabel: React.FC<StepsLabelProps> = ({ label }) => {
  return <h3 className=' font-semibold capitalize text-lg sm:text-xl truncate'>{label}</h3>
}
export const CategoryLabel: React.FC<StepsLabelProps> = ({ label, variant = 'default' }) => {
  const defaultClasses =
    'text-[color:var(--color-secondary-light)] text-base font-semibold capitalize'
  const customClasses = 'text-[color:var(--color-primary)] text-base font-semibold uppercase'

  const className = variant === 'secondary' ? customClasses : defaultClasses

  return <p className={className}>{label}</p>
}
