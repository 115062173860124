import { CustomStepper } from 'components/Elements/Stepper'
import type React from 'react'
import { useState } from 'react'
import { type Farm } from 'types'
import { Lessee } from './Lessee'
import { LesseeConfirmation } from './LesseeConfirmation'
import { LeaseDetails } from './LeaseDetails'

const steps = ['Lessee Information', 'Confirm Lessee', 'Lease Details']

interface LeaseFarmProps {
  farm: Farm
  onClose: () => void
}

export const LeaseFarm: React.FC<LeaseFarmProps> = ({ farm, onClose }) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }

  if (activeStep === 1) {
    return (
      <div className={'flex flex-col items-center gap-8 p-4'}>
        <CustomStepper steps={steps} activeStep={activeStep} />
        <LesseeConfirmation next={handleNext} back={handleBack} />
      </div>
    )
  }

  if (activeStep === steps.length - 1) {
    return (
      <div className={'flex flex-col items-center gap-2 md:gap-8 md:p-4'}>
        <CustomStepper steps={steps} activeStep={activeStep} />
        <LeaseDetails back={handleBack} farm={farm} next={onClose} />
      </div>
    )
  }

  return (
    <div className={'flex flex-col items-center gap-8 p-4'}>
      <CustomStepper steps={steps} activeStep={activeStep} />
      <Lessee cancel={onClose} next={handleNext} />
    </div>
  )
}
