import type React from 'react'
import { useAtom } from 'jotai'
import { companyLoanStore } from '../stores'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import {
  CompanyBalanceSheet,
  type CompanyBalanceSheetInput,
  schemaCBS
} from './CompanyBalanceSheet'
import { useMutation } from '@tanstack/react-query'
import LoanButtonGroup from '../../generic/form/LoanButtonGroup'
import { type LoanStepperProps } from '../../../types'
import { createCompanyLoan } from '../../../api/createCompanyLoan'
import {
  type Accountant,
  type Auditor,
  type CompanyLoanType,
  type LoansWithOtherInstitutionsNdb,
  type ProductionHistory
} from '../../../types/company'
import { applicationHeader } from '../stores/applicationHeader'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { type CroppingPlanId } from '../../../types/individual'
import { toast } from 'react-hot-toast'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'

export const CompanyBalanceSheetBody: React.FC<LoanStepperProps> = ({
  className,
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const { farmer } = useFarmerStore()
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const [, setApplicationHead] = useAtom(applicationHeader)
  const mutation = useMutation({
    mutationFn: async (params: CompanyLoanType) => {
      return createCompanyLoan(params)
    }
  })
  const onSubmit: SubmitHandler<CompanyBalanceSheetInput> = (data: CompanyBalanceSheetInput) => {
    // console.log(loanData)
    mutation.mutate(
      {
        cropping_plan: loanData?.cropping_plan as CroppingPlanId[],
        total_amount: {
          loan_req1: loanData?.total_amount?.loan_req1 as number
        },
        company_loan: {
          farmer_id: Number(farmer?.farmer_id),
          company_reg_no: String(farmer?.company_reg_no),
          loan_req2: loanData?.company_loan?.loan_req2 as number,
          loan_req3: loanData?.company_loan?.loan_req3 as string,
          loan_req4: loanData?.company_loan?.loan_req4 as string,
          period_at_physical_addr: loanData?.company_loan?.period_at_physical_addr as number,
          period_at_prev_physical_addr: loanData?.company_loan
            ?.period_at_prev_physical_addr as number,
          ownership_at_physical_addr: loanData?.company_loan?.ownership_at_physical_addr as string,
          source_reference: loanData?.company_loan?.source_reference as string,
          last_update_by: Number(farmer?.user_id)
        },

        commercial_bank_details: {
          bank_id: loanData?.commercial_bank_details?.bank_id as number,
          bank_branch_id: loanData?.commercial_bank_details?.bank_branch_id as number,
          bank_account_number: loanData?.commercial_bank_details?.bank_account_number as number,
          last_update_by: Number(farmer?.user_id)
        },
        loans_with_other_institutions_ndb:
          loanData?.loans_with_other_institutions_ndb as LoansWithOtherInstitutionsNdb[],
        history_of_ndb_loans: {
          loan_exists: loanData?.history_of_ndb_loans?.loan_exists as string
        },

        financial_particulars: {
          fp_checklist1: loanData?.financial_particulars?.fp_checklist1 as string,
          fp_checklist2: loanData?.financial_particulars?.fp_checklist2 as string,
          fp_checklist3: loanData?.financial_particulars?.fp_checklist3 as string,
          fp_checklist4: loanData?.financial_particulars?.fp_checklist4 as number,
          fp_checklist5: loanData?.financial_particulars?.fp_checklist5 as string,
          fp_checklist6: loanData?.financial_particulars?.fp_checklist6 as string
        },
        employee_information: {
          applicant_employer: loanData?.employee_information?.applicant_employer as string,
          previous_year_turnover: loanData?.employee_information?.previous_year_turnover as number,
          permenant_employee_number: loanData?.employee_information
            ?.permenant_employee_number as number,
          farm_other_income: loanData?.employee_information?.farm_other_income as number,
          casual_employee_number: loanData?.employee_information?.casual_employee_number as number,
          applicant_other_income_source: loanData?.employee_information
            ?.applicant_other_income_source as string,
          applicant_monthly_income: loanData?.employee_information
            ?.applicant_monthly_income as number,
          applicant_job_title: loanData?.employee_information?.applicant_job_title as string,
          applicant_employment_status: loanData?.employee_information
            ?.applicant_employment_status as string,
          applicant_employment_start_date: loanData?.employee_information
            ?.applicant_employment_start_date as Date
        },
        loan_security: {
          immovable_property:
            loanData?.loan_security?.immovable_property === undefined
              ? []
              : loanData?.loan_security?.immovable_property,
          loan_machinery: {
            age: loanData?.loan_security?.loan_machinery?.age as number,
            original_cost: loanData?.loan_security?.loan_machinery?.original_cost as number,
            market_value: loanData?.loan_security?.loan_machinery?.market_value as number,
            estimated_forces_sale_value: loanData?.loan_security?.loan_machinery
              ?.estimated_forces_sale_value as number,
            encumbrances_amount: loanData?.loan_security?.loan_machinery
              ?.encumbrances_amount as number,
            machinery_id: loanData?.loan_security?.loan_machinery?.machinery_id as number
          },
          loan_motor_vehicle: {
            age: loanData?.loan_security?.loan_motor_vehicle?.age as number,
            original_cost: loanData?.loan_security?.loan_motor_vehicle?.original_cost as number,
            market_value: loanData?.loan_security?.loan_motor_vehicle?.market_value as number,
            estimated_forces_sale_value: loanData?.loan_security?.loan_motor_vehicle
              ?.estimated_forces_sale_value as number,
            encumbrances_amount: loanData?.loan_security?.loan_motor_vehicle
              ?.encumbrances_amount as number,
            vehicle_id: loanData?.loan_security?.loan_motor_vehicle?.vehicle_id as number
          },
          loan_bank_guarantee: {
            guarantee_value: loanData?.loan_security?.loan_bank_guarantee?.guarantee_value as number
          },
          loan_other_guarantee: {
            guarantee_value: loanData?.loan_security?.loan_other_guarantee
              ?.guarantee_value as number
          },
          loan_insurance_policy: {
            sum_assured: loanData?.loan_security?.loan_insurance_policy?.sum_assured as number
          },
          loan_other_security: {
            fixed_deposit_balance: loanData?.loan_security?.loan_other_security
              ?.fixed_deposit_balance as number
          },
          basis_of_estimation: {
            basis_of_estimation: loanData?.loan_security?.basis_of_estimation
              ?.basis_of_estimation as string
          }
        },
        production_history: loanData?.production_history as ProductionHistory[],
        loan_accountants: loanData?.loan_accountants as Accountant[],
        loan_auditors: loanData?.loan_auditors as Auditor[],
        summarized_business_information: {
          farmer_id: Number(farmer?.farmer_id),
          background_info: loanData?.summarized_business_information?.background_info as string,
          process_info: loanData?.summarized_business_information?.process_info as string,
          key_assum: loanData?.summarized_business_information?.key_assum as string,
          market_info: loanData?.summarized_business_information?.market_info as string,
          competitors: loanData?.summarized_business_information?.competitors as string,
          distribution: loanData?.summarized_business_information?.distribution as string,
          last_update_by: Number(farmer?.user_id)
        },
        balance_sheets: {
          immovable_assets: data.immovableAssets,
          movable_assets: {
            movable_property_id: data.propertyId,
            company_name: data.nameOfTheCompany,
            number_of_shares: data.numberOfShares,
            estimated_share_value: data.estimatedValueOfShares,
            total_number_of_shares: data.totalNumbersOfShares,
            type_of_assets: data.typeOfAsserts,
            year_of_acquisition: data.yearOfAcquisition,
            net_book_value: data.netBookValue,
            estimated: data.estimatedVal,
            livestock_number: data.livestock_number,
            livestock: data.livestock,
            recievables: data.debtors,
            total_cash: data.cash,
            fixed_deposits: data.fixedDeposits,
            other_assets: data.otherAssets
          },
          liablities: {
            mortgage_loans: data.loansMortgage,
            hire_purchase_loans: data.loansHirePurchase,
            tax_liabilities: data.taxLiabilities,
            personal_loans: data.personalLoans,
            creditors: data.creditors,
            other_liabilities: data.otherLiabilities
          },
          declaration: {
            plot_number: '',
            location: '',
            type_of_land: '',
            size: 0,
            remaining_lease_period: 0,
            estimated_value: 0
          }
        },

        last_update_by: Number(farmer?.user_id)
      },
      {
        onSuccess: (result) => {
          if (result.status === 201 && result.success) {
            setApplicationHead((prev) => ({
              ...prev,
              loan_application_id: Number(result?.data?.loan_application_id)
            }))
            toast.success('Your loan is submitted to the CMS Database!!')
            onNext()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast.error(
              mapCmsErrorToMessage(error.status.toString() ?? err.code ?? error.data.message ?? '')
            )
          } else {
            const error = err as Error
            toast.error(mapCmsErrorToMessage(error.message))
          }
        }
      }
    )
  }
  return (
    <FormWithStepper<CompanyBalanceSheetInput, typeof schemaCBS>
      className={'flex flex-col p-4 flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schemaCBS}
    >
      {(methods) => (
        <>
          <CompanyBalanceSheet methods={methods} />
          <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
            <LoanButtonGroup
              onBack={onBack}
              onSubmit={methods.handleSubmit(onSubmit)}
              isLoading={mutation.isLoading}
              disabled={mutation.isLoading}
            />
          </div>
        </>
      )}
    </FormWithStepper>
  )
}
