import React from 'react'
import { ROLES } from '../../types'
import { ErrorPage } from '../Errors/ErrorPage'
import { ErrorBoundary } from 'react-error-boundary'
import { RequiresAuth } from 'features/authentication/components/RequiresAuth'

const LabDashboard = React.lazy(async () => import('../Dashboard/Lab'))

export const Lab: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <RequiresAuth allowedRoles={[ROLES.LAB]}>
        <LabDashboard />
      </RequiresAuth>
    </ErrorBoundary>
  )
}
