import { type Crops } from '../types'

export const CropsList: Crops[] = [
  {
    crop_id: 10001,
    crop_name: 'COWPEAS',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10002,
    crop_name: 'DILOCHOS LAB LAB',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10003,
    crop_name: 'DRY BEAN',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10004,
    crop_name: 'GROUNDNUT',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10005,
    crop_name: 'JUGO BEANS',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10006,
    crop_name: 'LUCERN',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10007,
    crop_name: 'SOYA BEANS',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10008,
    crop_name: 'SUGARBEANS',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10009,
    crop_name: 'FORAGE SORGHUM',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10010,
    crop_name: 'JAPANEASE MILLET',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10011,
    crop_name: 'MAIZE',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10012,
    crop_name: 'PEARL MILLET',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10013,
    crop_name: 'POPCORN',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10014,
    crop_name: 'SORGHUM',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10015,
    crop_name: 'WHEAT',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10016,
    crop_name: 'WHITE MAIZE',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10017,
    crop_name: 'YELLOW MAIZE',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10018,
    crop_name: 'FORAGE CROPS',
    family_name: 'VARIOUS FAMILIES'
  },
  {
    crop_id: 10019,
    crop_name: 'SUNFLOWER',
    family_name: 'ASTERACEAE'
  },
  {
    crop_id: 10036,
    crop_name: 'RICE',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10037,
    crop_name: 'MUNGBEAN',
    family_name: 'FABACEAE'
  },
  {
    crop_id: 10038,
    crop_name: 'SAFFLOWER',
    family_name: 'ASTERACEAE'
  },
  {
    crop_id: 10039,
    crop_name: 'SWEET SORGHUM',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10040,
    crop_name: 'MORINGA',
    family_name: 'POACEAE'
  },
  {
    crop_id: 10041,
    crop_name: 'MILLET',
    family_name: 'POACEAE'
  }
]
