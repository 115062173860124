/* eslint-disable eqeqeq */
import { ROLES } from 'types'
import dayjs from 'dayjs'

export const hasLowerCase = (value: string): boolean => {
  if (value.length === 0) {
    return false
  }
  for (let i = 0; i < value.length; i++) {
    const char = value.charAt(i)
    if (char == char.toUpperCase()) {
      /* empty */
    } else if (char == char.toLowerCase()) {
      return true
    }
  }
  return false
}

export const hasUpperCase = (value: string): boolean => {
  if (value.length === 0) {
    return false
  }
  for (let i = 0; i < value.length; i++) {
    const char = value.charAt(i)
    if (char == char.toLowerCase()) {
      /* empty */
    } else if (char == char.toUpperCase()) {
      return true
    }
  }
  return false
}

export const getPath = (role: ROLES): string => {
  switch (role) {
    case ROLES.MECHANIZATION:
      return '/inventory'
    case ROLES.LAB:
      return '/soil-lab'
    case ROLES.DRAUGHPOWER:
      return '/service'
    case ROLES.SEEDDEALER:
      return '/seed-dealer'
    default:
      return '/officer'
  }
}

export const addZeroToTime = (time: number): string => {
  if (time <= 9) {
    return `0${time}`
  }
  return time.toString()
}

export const maxLeaseYear = (): number => {
  return dayjs(new Date(Date.now())).toDate().getFullYear() + 10
}

export const maxLeaseYearDate = (): Date => {
  return new Date(
    dayjs(new Date(Date.now()))
      .toDate()
      .setFullYear(dayjs(new Date(Date.now())).toDate().getFullYear() + 10)
  )
}

export const minLeaseYear = (): number => {
  return dayjs(new Date(Date.now())).toDate().getFullYear() + 1
}

export const minLeaseYearDate = (): Date => {
  return new Date(
    dayjs(new Date(Date.now()))
      .toDate()
      .setFullYear(dayjs(new Date(Date.now())).toDate().getFullYear() + 1)
  )
}

export const minLeaseYearTaxPayer = (): number => {
  return dayjs(new Date(Date.now())).toDate().getFullYear() + 5
}

export const minLeaseYearTaxPayerDate = (): Date => {
  return new Date(
    dayjs(new Date(Date.now()))
      .toDate()
      .setFullYear(dayjs(new Date(Date.now())).toDate().getFullYear() + 5)
  )
}

export const currentYear = (): number => {
  return dayjs(new Date(Date.now())).toDate().getFullYear()
}

export const convertApplicationMethod = (val: string): string => {
  switch (val) {
    case 'A1':
      return 'Drone'
    case 'A2':
      return 'Plane'
    case 'A3':
      return 'Tractor'
    case 'A4':
      return 'Knapsack'
    case 'A5':
      return 'Human Based'
    case 'A6':
      return 'Interrow Cultivaion'
    default:
      return val
  }
}
