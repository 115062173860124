import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Farm } from 'types'
import { type AxiosError } from 'axios'

export const getFarms = async (farmerId: number | null | undefined): Promise<ApiResponse<Farm>> => {
  return axios.get(`/farm?FARMER_ID=${farmerId ?? ''}`)
}

export const useGetFarms = (
  farmerId: number | null | undefined
): UseQueryResult<ApiResponse<Farm>, AxiosError> => {
  return useQuery({
    queryKey: ['farms', farmerId],
    queryFn: async () => getFarms(farmerId),
    enabled: farmerId != null
  })
}
