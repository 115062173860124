import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateHeaderDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  farm_id: number | null | undefined
  crop_id: number
  user_id: number | null | undefined
  hectarage: number
  ssr_id: string | null | undefined
}

export const updateHeader = async (params: UpdateHeaderDTO): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/header`, params)
}
