import { RequiresAuth } from 'features/authentication/components/RequiresAuth'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ROLES } from 'types'
import { ErrorPage } from '../Errors/ErrorPage'

const AgroChemicalDealersDashboardLayout = React.lazy(
  async () => import('../Layout/AgroChemicalDealersDashboardLayout')
)

export const AgroChemicalDealers: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <RequiresAuth allowedRoles={[ROLES.AGROCHEMICALDEALERS]}>
        <AgroChemicalDealersDashboardLayout />
      </RequiresAuth>
    </ErrorBoundary>
  )
}
