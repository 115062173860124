import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface UseOtpStore {
  timeOfFirstResend: Date | null
  setTimeOfFirstResend: (time: Date | null) => void
  expiryTimeOfResend: Date | null
  setExpiryTimeOfResend: (time: Date | null) => void
  resendCounter: number
  setResendCounter: (counter: number) => void
}

export const useOtpStore = create(
  persist<UseOtpStore>(
    (set, get) => ({
      timeOfFirstResend: null,
      setTimeOfFirstResend: (time: Date | null) => {
        set((state) => ({ ...state, timeOfFirstResend: time }))
      },
      expiryTimeOfResend: null,
      setExpiryTimeOfResend: (time: Date | null) => {
        set((state) => ({ ...state, expiryTimeOfResend: time }))
      },
      resendCounter: 0,
      setResendCounter: (counter: number) => {
        set((state) => ({ ...state, resendCounter: counter }))
      }
    }),
    {
      name: 'state',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
