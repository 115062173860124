import type { ColumnDef } from '@tanstack/react-table'
import type { Lease } from 'types'
import { LeaseActions } from './Actions'
import dayjs from 'dayjs'
import { Statuses } from './Statuses'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'

export const leaseHistoryColumns: Array<ColumnDef<Lease>> = [
  {
    id: 'lease id',
    accessorFn: (row) => row.lease_id,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('lease id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Lease ID' />
  },
  {
    id: 'lessor id',
    accessorFn: (row) => row.dest_farmer_id,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('lessor id')}</div>,
    header: 'Lessee ID'
  },
  {
    id: 'hectares',
    accessorFn: (row) => row.leased_size,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('hectares')}</div>,
    header: 'Hectares'
  },
  {
    id: 'start date',
    accessorFn: (row) => row.commencement_date,
    cell: ({ row }) => (
      <div className='uppercase'>{dayjs(row.getValue('start date')).format('DD-MM-YYYY')}</div>
    ),
    header: 'Start Date'
  },
  {
    id: 'expiry date',
    accessorFn: (row) =>
      row.status_desc === 'RENEWAL ACCEPTED BY LESSOR' ? row.new_termination_date : row.expiry_date,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => (
      <div className='uppercase'>{dayjs(row.getValue('expiry date')).format('DD-MM-YYYY')}</div>
    ),
    header: 'Expiry Date'
  },
  {
    id: 'status',
    accessorFn: (row) => row.status_desc,
    cell: ({ row }) => <Statuses lease={row.original} />,
    header: 'Status'
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      return <LeaseActions lease={row.original} />
    }
  }
]
