import { Skeleton } from 'components/ui/skeleton'
import type React from 'react'

export function SkeletonCard(): React.ReactNode {
  return (
    <div className='flex flex-col space-y-3'>
      <Skeleton className='h-[150px] md:max-w-[500px] rounded-xl' />
      <div className='space-y-2'>
        <Skeleton className='h-4 md:max-w-[500px]' />
        <Skeleton className='h-4 md:max-w-[500px]' />
      </div>
    </div>
  )
}
