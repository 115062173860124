import type React from 'react'
import { useState } from 'react'
import { BasicButton } from 'components/Elements/Button'
import { CustomDatePicker, InputField, SelectField } from 'components/Form'
import {
  CategoryLabel,
  CustomAccordion,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper'
import z, { array, coerce, number, object, string, type TypeOf } from 'zod'
import { FormProvider, useFieldArray, useFormContext, type UseFormReturn } from 'react-hook-form'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import dayjs from 'dayjs'
import { YearPicker } from '../../../../../components/Form/YearPicker'
import { MenuItem } from '@mui/material'
import { CustomRadio, type RadioOptions } from '../../../../../components/Form/Radio'

import { Text } from '../../../../../components/Elements/Text'
import SigningPad from '../../generic/SigningPad'

const livestockSchema = object({
  livestock_number: number({ invalid_type_error: 'This should be a number' }),
  livestock_id: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  })
})
const yesOrNo: Array<RadioOptions<string>> = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
]
const immovableAssetsSchema = object({
  plot_number: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  property_location: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  type_of_land: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  property_size: number({ invalid_type_error: 'This should be a number' }),
  remaining_lease_period: number({ invalid_type_error: 'This should be a number' }),
  estimated_value: number({ invalid_type_error: 'This should be a number' })
})
export const schemaBS = object({
  immovableAssets: array(immovableAssetsSchema).nonempty('Field id required!'),
  balanceSheetdDate: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  propertyId: number({ invalid_type_error: 'This should be a number' }),
  nameOfTheCompany: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  numberOfShares: number({ invalid_type_error: 'This should be a number' }),
  estimatedValueOfShares: number({ invalid_type_error: 'This should be a number' }),
  totalNumbersOfShares: number({ invalid_type_error: 'This should be a number' }),
  yearOfAcquisition: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  typeOfAsserts: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  marital_regime: z.enum(['yes', 'no']),
  netBookValue: number({ invalid_type_error: 'This should be a number' }),
  estimatedVal: number({ invalid_type_error: 'This should be a number' }),
  debtors: number({ invalid_type_error: 'This should be a number' }),
  livestock_number: number({ invalid_type_error: 'This should be a number' }),
  livestock: array(livestockSchema).nonempty('Field is required'),
  cash: number({ invalid_type_error: 'This should be a number' }),
  fixedDeposits: number({ invalid_type_error: 'This should be a number' }),
  otherAssets: number({ invalid_type_error: 'This should be a number' }),
  loansMortgage: number({ invalid_type_error: 'This should be a number' }),
  loansHirePurchase: number({ invalid_type_error: 'This should be a number' }),
  taxLiabilities: number({ invalid_type_error: 'This should be a number' }),
  personalLoans: number({ invalid_type_error: 'This should be a number' }),
  creditors: number({ invalid_type_error: 'This should be a number' }),
  otherLiabilities: number({ invalid_type_error: 'This should be a number' })
})

export type BalanceSheetDetailsInput = TypeOf<typeof schemaBS>

interface BalanceSheetProps {
  methods: UseFormReturn<BalanceSheetDetailsInput>
}

export const BalanceSheets: React.FC<BalanceSheetProps> = ({ methods }) => {
  const [loanData] = useAtom(individualLoanItem)
  const [signature, setSignature] = useState<string>('')

  const { control, setValue, watch } = useFormContext<BalanceSheetDetailsInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'immovableAssets'
  })

  const {
    fields: livestockFields,
    append: appendLivestock,
    remove: removeLivestock
  } = useFieldArray({
    control,
    name: 'livestock'
  })

  // Watch the livestock array changes

  const addNewImmovableAsset = (): void => {
    append({
      plot_number: '',
      property_location: '',
      type_of_land: '',
      property_size: 0,
      remaining_lease_period: 0,
      estimated_value: 0
    })
  }
  const addNewLivestock = (): void => {
    appendLivestock({ livestock_number: 0, livestock_id: '' })
  }
  const handleSetSignature = (newSignature: string): void => {
    setSignature(newSignature)
  }

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <div className={'w-full flex flex-col gap-4'}>
          <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
            Personal Balance Sheet
          </Text>
          <div className={'w-full '}>
            <CustomAccordion
              sections={[
                {
                  label: 'General Information',
                  fields: ['marital_regime', 'balanceSheetdDate'],
                  fieldErrors: methods.formState.errors,
                  children: [
                    <>
                      <RowContainer>
                        <CustomRadio
                          label='Married in community of property?'
                          name={'marital_regime'}
                          control={control}
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          options={yesOrNo}
                          defaultValue={
                            loanData?.balance_sheets?.general_information
                              ?.marital_regime as unknown as string
                          }
                        />
                      </RowContainer>
                      <RowContainer>
                        <CustomDatePicker
                          className='flex-[1_0_230px] capitalize'
                          error={!(methods.formState.errors.balanceSheetdDate == null)}
                          helperText={methods.formState.errors.balanceSheetdDate?.message ?? ''}
                          label={'Balance Sheet Date'}
                          defaultValue={
                            loanData?.balance_sheets?.general_information?.balance_sheet_date ===
                            undefined
                              ? undefined
                              : dayjs(
                                  loanData?.balance_sheets?.general_information?.balance_sheet_date
                                )
                          }
                          disableFuture
                          name={'balanceSheetdDate'}
                          control={methods.control}
                        />
                      </RowContainer>

                      <RowContainer>
                        <Text size={'small'}>
                          Note: If any assets stated are outside Botswana, please stage the country
                          where they are located.
                        </Text>
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'assets: immovable property',
                  fields: ['immovableAssets'],
                  fieldErrors: methods.formState.errors,
                  children: [
                    <>
                      <div className='w-full flex justify-end '>
                        <BasicButton
                          label={'add property'}
                          variant={'primary'}
                          size={'xs'}
                          onClick={addNewImmovableAsset}
                        />
                      </div>

                      {fields.map((field, index) => (
                        <div id={`property_${index + 1}`} className={'w-full flex flex-col mb-8'}>
                          <Text size={'medium'} className={'m-4'}>{`Asset ${index + 1}`}</Text>
                          <RowContainer>
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'plot NO'}
                              defaultValue={
                                loanData?.balance_sheets?.immovable_assets[index]?.plot_number
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableAssets?.[index]?.plot_number ==
                                  null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableAssets?.[index]?.plot_number
                                  ?.message ?? ''
                              }
                              register={methods.register(`immovableAssets.${index}.plot_number`)}
                            />
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'location'}
                              defaultValue={
                                loanData?.balance_sheets?.immovable_assets[index]?.property_location
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableAssets?.[index]
                                    ?.property_location == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableAssets?.[index]
                                  ?.property_location?.message ?? ''
                              }
                              register={methods.register(
                                `immovableAssets.${index}.property_location`
                              )}
                            />
                          </RowContainer>

                          <RowContainer>
                            <SelectField
                              label={'type of land'}
                              defaultValue={
                                loanData?.balance_sheets?.immovable_assets[index]?.type_of_land
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableAssets?.[index]
                                    ?.type_of_land == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableAssets?.[index]?.type_of_land
                                  ?.message ?? ''
                              }
                              register={methods.register(`immovableAssets.${index}.type_of_land`)}
                              className={'flex-[1_0_230px] capitalize'}
                            >
                              <MenuItem value={'commercial'}>
                                <Text>Commercial</Text>
                              </MenuItem>
                              <MenuItem value={'personal'}>
                                <Text>Residential</Text>
                              </MenuItem>
                              <MenuItem value={'personal'}>
                                <Text>Industrial</Text>
                              </MenuItem>
                              <MenuItem value={'personal'}>
                                <Text>Agricultural</Text>
                              </MenuItem>
                            </SelectField>

                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'size(M2)'}
                              type={'number'}
                              defaultValue={
                                loanData?.balance_sheets?.immovable_assets[index]?.property_size
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableAssets?.[index]
                                    ?.property_size == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableAssets?.[index]?.property_size
                                  ?.message ?? ''
                              }
                              register={methods.register(`immovableAssets.${index}.property_size`, {
                                valueAsNumber: true
                              })}
                            />
                          </RowContainer>
                          <RowContainer>
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'remaining lease period(years)'}
                              type={'number'}
                              defaultValue={
                                loanData?.balance_sheets?.immovable_assets[index]
                                  ?.remaining_lease_period
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableAssets?.[index]
                                    ?.remaining_lease_period == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableAssets?.[index]
                                  ?.remaining_lease_period?.message ?? ''
                              }
                              register={methods.register(
                                `immovableAssets.${index}.remaining_lease_period`,
                                {
                                  valueAsNumber: true
                                }
                              )}
                            />
                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'estimated value(bwp)'}
                              type={'number'}
                              defaultValue={
                                loanData?.balance_sheets?.immovable_assets[index]?.estimated_value
                              }
                              error={
                                !(
                                  methods.formState.errors?.immovableAssets?.[index]
                                    ?.estimated_value == null
                                )
                              }
                              helperText={
                                methods.formState.errors?.immovableAssets?.[index]?.estimated_value
                                  ?.message ?? ''
                              }
                              register={methods.register(
                                `immovableAssets.${index}.estimated_value`,
                                {
                                  valueAsNumber: true
                                }
                              )}
                            />
                          </RowContainer>
                          <RowContainer>
                            <div className='flex justify-end flex-[1_0_230px] capitalize'>
                              <BasicButton
                                label={'Remove Property'}
                                color='error'
                                variant={'secondary'}
                                size={'xs'}
                                onClick={() => {
                                  remove(index)
                                }}
                                disabled={index === 0}
                              />
                            </div>
                          </RowContainer>
                        </div>
                      ))}
                    </>
                  ]
                },
                {
                  label: 'assets: movable property',
                  fields: [
                    'propertyId',
                    'nameOfTheCompany',
                    'numberOfShares',
                    'estimatedValueOfShares',
                    'totalNumbersOfShares',
                    'typeOfAsserts',
                    'yearOfAcquisition',
                    'netBookValue',
                    'estimatedVal',
                    'debtors',
                    'cash',
                    'fixedDeposits',
                    'otherAssets',
                    'livestock_number',
                    'livestock'
                  ],
                  fieldErrors: methods.formState.errors,
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Movable Property Id'}
                          type={'number'}
                          defaultValue={
                            loanData?.balance_sheets?.movable_assets?.movable_property_id
                          }
                          error={!(methods.formState.errors.propertyId == null)}
                          helperText={methods.formState.errors.propertyId?.message ?? ''}
                          register={methods.register('propertyId', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Name Of The Company'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.company_name}
                          error={!(methods.formState.errors.nameOfTheCompany == null)}
                          helperText={methods.formState.errors.nameOfTheCompany?.message ?? ''}
                          register={methods.register('nameOfTheCompany')}
                        />
                      </RowContainer>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Number of shares in company(%)'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.number_of_shares}
                          error={!(methods.formState.errors.numberOfShares == null)}
                          helperText={methods.formState.errors.numberOfShares?.message ?? ''}
                          register={methods.register('numberOfShares', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'estimated  value of shares(bWP)'}
                          type={'number'}
                          defaultValue={
                            loanData?.balance_sheets?.movable_assets?.estimated_share_value
                          }
                          error={!(methods.formState.errors.estimatedValueOfShares == null)}
                          helperText={
                            methods.formState.errors.estimatedValueOfShares?.message ?? ''
                          }
                          register={methods.register('estimatedValueOfShares', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Total Numbers Of shares in company'}
                          type={'number'}
                          defaultValue={
                            loanData?.balance_sheets?.movable_assets?.total_number_of_shares
                          }
                          error={!(methods.formState.errors.totalNumbersOfShares == null)}
                          helperText={methods.formState.errors.totalNumbersOfShares?.message ?? ''}
                          register={methods.register('totalNumbersOfShares', {
                            valueAsNumber: true
                          })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'type of asserts'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.type_of_assets}
                          error={!(methods.formState.errors.typeOfAsserts == null)}
                          helperText={methods.formState.errors.typeOfAsserts?.message ?? ''}
                          register={methods.register('typeOfAsserts')}
                        />
                      </RowContainer>
                      <RowContainer>
                        <YearPicker
                          label={'Acquisition Year'}
                          defaultValue={
                            loanData?.balance_sheets?.movable_assets.year_of_acquisition ===
                            undefined
                              ? undefined
                              : dayjs(loanData?.balance_sheets?.movable_assets.year_of_acquisition)
                          }
                          error={!(methods.formState.errors.yearOfAcquisition == null)}
                          helperText={methods.formState.errors.yearOfAcquisition?.message ?? ''}
                          name={'yearOfAcquisition'}
                          control={methods.control}
                          className={'flex-[1_0_230px] capitalize'}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'net book value(BWP)'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.net_book_value}
                          type={'number'}
                          error={!(methods.formState.errors.netBookValue == null)}
                          helperText={methods.formState.errors.netBookValue?.message ?? ''}
                          register={methods.register('netBookValue', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'estimated value(BWP)'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.estimated}
                          type={'number'}
                          error={!(methods.formState.errors.estimatedVal == null)}
                          helperText={methods.formState.errors.estimatedVal?.message ?? ''}
                          register={methods.register('estimatedVal', { valueAsNumber: true })}
                        />
                      </RowContainer>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'debtors/ receivables'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.recievables}
                          error={!(methods.formState.errors.debtors == null)}
                          helperText={methods.formState.errors.debtors?.message ?? ''}
                          register={methods.register('debtors', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'cash at bank and in hand(BWP)'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.total_cash}
                          error={!(methods.formState.errors.cash == null)}
                          helperText={methods.formState.errors.cash?.message ?? ''}
                          register={methods.register('cash', { valueAsNumber: true })}
                        />
                      </RowContainer>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'fixed deposits with banks(BWP)'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.fixed_deposits}
                          error={!(methods.formState.errors.fixedDeposits == null)}
                          helperText={methods.formState.errors.fixedDeposits?.message ?? ''}
                          register={methods.register('fixedDeposits', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'other asserts(BWP)'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.movable_assets?.other_assets}
                          error={!(methods.formState.errors.otherAssets == null)}
                          helperText={methods.formState.errors.otherAssets?.message ?? ''}
                          register={methods.register('otherAssets', { valueAsNumber: true })}
                        />
                      </RowContainer>
                      <div>
                        <Text size={'medium'} className={'mx-8 my-4'}>
                          Livestock
                        </Text>
                      </div>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Total livestock Number'}
                          type={'number'}
                          defaultValue={
                            loanData?.balance_sheets?.movable_assets?.livestock[0]?.livestock_number
                          }
                          error={!(methods.formState.errors.livestock_number == null)}
                          helperText={methods.formState.errors.livestock_number?.message ?? ''}
                          register={methods.register('livestock_number', {
                            valueAsNumber: true
                          })}
                        />
                        <div className='flex justify-end flex-[1_0_230px] capitalize'>
                          <BasicButton
                            label={'Add Livestock'}
                            variant={'primary'}
                            size={'xs'}
                            onClick={addNewLivestock}
                          />
                        </div>
                      </RowContainer>
                      {livestockFields.map((field, index) => (
                        <div id={`livestock_${index + 1}`} className={'w-full mb-8'}>
                          <CategoryLabel label={`Livestock ${index + 1}`} />

                          <RowContainer>
                            <SelectField
                              className='flex-[1_0_230px] capitalize'
                              label={'livestock Name'}
                              defaultValue={
                                loanData?.balance_sheets?.movable_assets.livestock[index]
                                  .livestock_id
                              }
                              error={
                                !(methods.formState.errors.livestock?.[index]?.livestock_id == null)
                              }
                              helperText={
                                methods.formState.errors.livestock?.[index]?.livestock_id
                                  ?.message ?? ''
                              }
                              register={methods.register(`livestock.${index}.livestock_id`)}
                            >
                              {/* ['bulls', 'heifers', 'tollies', 'goats', 'sheep'] */}
                              <MenuItem value={'bulls'}>Bulls</MenuItem>
                              <MenuItem value={'heifers'}> Heifers</MenuItem>
                              <MenuItem value={'tollies'}> Tollies</MenuItem>
                              <MenuItem value={'goats'}> Goats</MenuItem>
                              <MenuItem value={'sheep'}> Sheep</MenuItem>
                            </SelectField>

                            <InputField
                              className='flex-[1_0_230px] capitalize'
                              label={'livestock Number'}
                              type={'number'}
                              defaultValue={
                                loanData?.balance_sheets?.movable_assets.livestock[index]
                                  .livestock_number
                              }
                              error={
                                !(
                                  methods.formState.errors.livestock?.[index]?.livestock_number ==
                                  null
                                )
                              }
                              helperText={
                                methods.formState.errors.livestock?.[index]?.livestock_number
                                  ?.message ?? ''
                              }
                              register={methods.register(`livestock.${index}.livestock_number`, {
                                valueAsNumber: true
                              })}
                            />

                            <BasicButton
                              label={'Remove Livestock'}
                              color='error'
                              variant={'secondary'}
                              size={'xs'}
                              onClick={() => {
                                removeLivestock(index)
                              }}
                              disabled={index === 0}
                            />
                          </RowContainer>
                        </div>
                      ))}
                    </>
                  ]
                },
                {
                  label: 'liabilities',
                  fields: [
                    'loansMortgage',
                    'loansHirePurchase',
                    'taxLiabilities',
                    'personalLoans',
                    'creditors',
                    'otherLiabilities'
                  ],
                  fieldErrors: methods.formState.errors,
                  children: [
                    <>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Loans secured by Mortgage'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.liablities?.mortgage_loans}
                          error={!(methods.formState.errors.loansMortgage == null)}
                          helperText={methods.formState.errors.loansMortgage?.message ?? ''}
                          register={methods.register('loansMortgage', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Loans under Hire Purchase'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.liablities?.hire_purchase_loans}
                          error={!(methods.formState.errors.loansHirePurchase == null)}
                          helperText={methods.formState.errors.loansHirePurchase?.message ?? ''}
                          register={methods.register('loansHirePurchase', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>

                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Tax Liabilities'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.liablities?.tax_liabilities}
                          error={!(methods.formState.errors.taxLiabilities == null)}
                          helperText={methods.formState.errors.taxLiabilities?.message ?? ''}
                          register={methods.register('taxLiabilities', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Personal Loans'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.liablities?.personal_loans}
                          error={!(methods.formState.errors.personalLoans == null)}
                          helperText={methods.formState.errors.personalLoans?.message ?? ''}
                          register={methods.register('personalLoans', { valueAsNumber: true })}
                        />
                      </RowContainer>
                      <RowContainer>
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Creditors'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.liablities?.creditors}
                          error={!(methods.formState.errors.creditors == null)}
                          helperText={methods.formState.errors.creditors?.message ?? ''}
                          register={methods.register('creditors', { valueAsNumber: true })}
                        />
                        <InputField
                          className='flex-[1_0_230px] capitalize'
                          label={'Other Liabilities'}
                          type={'number'}
                          defaultValue={loanData?.balance_sheets?.liablities?.other_liabilities}
                          error={!(methods.formState.errors.otherLiabilities == null)}
                          helperText={methods.formState.errors.otherLiabilities?.message ?? ''}
                          register={methods.register('otherLiabilities', {
                            valueAsNumber: true
                          })}
                        />
                      </RowContainer>
                    </>
                  ]
                },
                {
                  label: 'Declaration',
                  children: [
                    <>
                      <RowContainer>
                        <p>
                          I/We hereby declare that the information above is, to best of my/our
                          knowledge and believe, true and correct. I/We further declare that the
                          money shall be used solely for the purpose indicated above and that there
                          is no legal impediment for the intended use. I/We also authorize the Bank
                          to reschedule the monthly instalment in the event of an increase/decrease
                          in the interest rate.
                        </p>
                      </RowContainer>

                      <SigningPad setSignature={handleSetSignature} />

                      {/* <div className='flex gap-4 items-center justify-end'> */}
                      {/*  <CategoryLabel label='Signature of the Applicant' /> */}
                      {/*  <BasicButton */}
                      {/*    label={'Upload signature'} */}
                      {/*    variant={'primary'} */}
                      {/*    size={'xs'} */}
                      {/*    onClick={() => {}} */}
                      {/*  /> */}
                      {/* </div> */}
                    </>
                  ]
                }
              ]}
            />
          </div>
        </div>
      </MainContainer>
    </FormProvider>
  )
}