import { BasicButton } from 'components/Elements/Button'
import { OtpField } from 'components/Form/OtpField'
import { ResendOtp } from 'components/Generic/ResendOtp'
import { useAuth } from 'features/authentication/hooks/useAuth'
import { getTimerTime } from 'features/authentication/utils/otp'
import { type ConfirmationResult } from 'firebase/auth'
import type React from 'react'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTimer } from 'react-timer-hook'
import { useOtpStore } from 'stores/useOtpStore'
import { Text } from 'components/Elements/Text'
interface RegistrationOtpFormProps {
  className?: string
  number: string
  onSuccess: () => void
  confirm: ConfirmationResult | undefined
}

export const RegistrationOtpForm: React.FC<RegistrationOtpFormProps> = ({
  className,
  number,
  confirm,
  onSuccess
}) => {
  const {
    setExpiryTimeOfResend,
    resendCounter,
    expiryTimeOfResend,
    setTimeOfFirstResend,
    setResendCounter
  } = useOtpStore()
  const [otp, setOtp] = useState<string>('')
  const [isResend, setIsResend] = useState(false)
  const { error, verifyOtp, resendOtp, isLoading, phoneLoginResponse, otpVerificationStatus } =
    useAuth()
  const hiddenNumber = String(number).slice(-3)
  const { hours, minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp:
      expiryTimeOfResend !== null ? new Date(expiryTimeOfResend) : new Date(Date.now())
  })

  const handleChange = (newValue: string): void => {
    setOtp(newValue)
  }

  const handleVerifyOtp = async (): Promise<void> => {
    if (!isResend) {
      await verifyOtp(confirm, otp)
    } else {
      await verifyOtp(phoneLoginResponse.confirm, otp)
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }

    if (
      resendCounter > 1 &&
      expiryTimeOfResend !== null &&
      new Date(Date.now()) < expiryTimeOfResend
    ) {
      restart(expiryTimeOfResend)
    }

    if (otpVerificationStatus) {
      onSuccess()
    }
  }, [error, otpVerificationStatus, resendCounter, expiryTimeOfResend])

  const handleResendOtp = async (): Promise<void> => {
    if (resendCounter === 0) {
      setTimeOfFirstResend(new Date(Date.now()))
    }

    const counter = resendCounter + 1
    setResendCounter(resendCounter + 1)

    setExpiryTimeOfResend(getTimerTime(new Date(Date.now()), counter))
    restart(getTimerTime(new Date(Date.now()), counter))

    await resendOtp(number)

    setOtp('')
    setIsResend(true)
  }

  return (
    <div className={'w-full max-w-[400px] flex flex-col gap-8 md:gap-8 xl:gap-12'}>
      <Text size={'large'} className={'text-center sm:ml-0 w-full'}>
        Verification Code
      </Text>
      <form
        className={'w-full flex flex-col gap-4 sm:gap-8'}
        onSubmit={(e) => {
          e.preventDefault()
          void handleVerifyOtp()
        }}
      >
        <div className={'flex flex-col gap-4'}>
          <Text className={'flex flex-col text-center'}>
            Enter OTP sent to a phone number that ends with
            <div className={'flex justify-center'}>
              <Text variant={'primary'} size={'medium'}>
                *****
              </Text>
              <Text variant={'primary'} size={'medium'}>
                {hiddenNumber}
              </Text>
            </div>
          </Text>
        </div>
        <OtpField value={otp} onChange={handleChange} />
        <p className={'text-center'}>Didn't Get Any Code? Request A New Code.</p>
        <div className={'flex flex-col gap-4'}>
          <BasicButton
            label={'Verify'}
            isLoading={isLoading}
            isDisabled={otp.length !== 6 || isLoading}
            type={'submit'}
          />
          <div className={'w-full flex items-center justify-between gap-4 px-2'}>
            <Text size={'small'}>Didn't Get Any Code?</Text>
            <ResendOtp
              seconds={seconds}
              minutes={minutes}
              hours={hours}
              isDisabled={(isRunning && seconds > 0) || isLoading}
              onResend={handleResendOtp}
            />
          </div>
        </div>
      </form>
    </div>
  )
}
