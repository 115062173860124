import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export interface GetSprayerChecklist {
  non_powered_implement_id: number
  inventory_id: number
  inventory_type: number
  nozel_output: number
  boom: number
  hoses: number
  pump_o_rings: number
  tank: number
  gasket: number
  regultors_guages_controls: number
  belts: number
  propeller_and_agitation: number
  filters: number
  dispenser: number
  measuring_device: number
  gears: number
  result: boolean
  comments: string
  create_date: string
  last_update_by: number
  last_update_date: string
}

export const getSprayerChecklist = async (
  inventoryId: number
): Promise<ApiResponse<GetSprayerChecklist>> => {
  return axios.get(`/assessment/sprayers?inventory_id=${inventoryId}`)
}

export const useGetSprayerChecklist = (
  inventoryId: number
): UseQueryResult<ApiResponse<GetSprayerChecklist>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['seederChecklist'],
    queryFn: async () => getSprayerChecklist(inventoryId)
  })
}
