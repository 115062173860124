import { type FC } from 'react'
import { NoHistory } from './NoHistory'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useGetTransactionHistory } from '../../api/getTransactions'
import { useParams } from 'react-router-dom'
import { TransactionHistoryTable } from '../transaction-history/TransactionHistoryTable'
import { historyColumns } from '../transaction-history/columns'
import { Loader } from 'components/ui/loader'
import { Error } from 'components/Errors/Error'

export const HistoryTab: FC = () => {
  const { farmer } = useFarmerStore()
  const { farmId } = useParams<{ farmId: string }>()
  const { leaseId } = useParams<{ leaseId: string }>()

  const {
    data: history,
    isLoading,
    error
  } = useGetTransactionHistory(farmer?.farmer_id, farmId, leaseId)

  if (isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null && error?.response?.status !== 404) {
    return (
      <div className={'grid place-items-center'}>
        <Error />
      </div>
    )
  }

  if (history == null) {
    return <NoHistory />
  }

  if (history.data.length === 0) {
    return <NoHistory />
  }

  return <TransactionHistoryTable columns={historyColumns} data={history.data} />
}
