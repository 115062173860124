import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import type { SeedTransaction } from '../types'
import { type AxiosError } from 'axios'

export const getReports = async (
  merchant_id: number | null | undefined
): Promise<ApiResponse<[SeedTransaction]>> => {
  return axios.get(`/voucher/redemption?merchant_id=${merchant_id ?? ''}`)
}

export const useGetReports = (
  merchant_id: number | null | undefined
): UseQueryResult<ApiResponse<SeedTransaction>, AxiosError> => {
  return useQuery({
    queryKey: ['seed-dealer-reports', merchant_id],
    queryFn: async () => getReports(merchant_id),
    enabled: merchant_id != null
  })
}
