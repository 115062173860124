import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type AxiosError } from 'axios'
import { type InventoryTransfer } from '../types'

export const getTransferServiceProviderRequest = async (
  destMerchantId: number | null | undefined
): Promise<ApiResponse<InventoryTransfer>> => {
  return axios.get(`/inventory/transfer?destination_merchant_id=${destMerchantId ?? ''}`)
}

export const useGetServiceProviderTransferRequest = (
  merchant_id: number | null | undefined
): UseQueryResult<ApiResponse<InventoryTransfer>, AxiosError> => {
  return useQuery({
    queryKey: ['destinationInvTrans', merchant_id],
    queryFn: async () => getTransferServiceProviderRequest(merchant_id),
    enabled: merchant_id != null
  })
}
