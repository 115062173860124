import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponseWithObject, type Harvesting } from 'types'

export const getHarvesting = async (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): Promise<ApiResponseWithObject<Harvesting>> => {
  return axios.get(`/production/harvesting?farmer_id=${farmerId ?? ''}&cp_id=${cp_id ?? ''}`)
}

export const useGetHarvesting = (
  farmerId: number | null | undefined,
  cp_id: number | null | undefined
): UseQueryResult<ApiResponseWithObject<Harvesting>, AxiosError> => {
  return useQuery({
    queryKey: ['harvesting', farmerId, cp_id],
    queryFn: async () => getHarvesting(farmerId, cp_id),
    enabled: cp_id != null && farmerId != null
  })
}
