import type React from 'react'
import { BasicModal } from 'components/Elements/Modal/Modal'
import { Card } from 'components/ui'

interface LayoutProps {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const ModalLayout: React.FC<LayoutProps> = ({ open, onClose, children }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <Card className={'w-[95vw] min-w-[350px] max-w-[450px] p-4 md:py-6 overflow-auto'}>
        {children}
      </Card>
    </BasicModal>
  )
}
