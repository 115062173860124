import { ReloadIcon } from '@radix-ui/react-icons'
import { useTheme } from 'next-themes'
import type React from 'react'
import { useState } from 'react'
import {
  Button,
  Input,
  Phone,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SearchInput
} from 'components/ui'
import { villageList } from 'features/authentication/utils/villages'
import { useToast } from '../components/ui/use-toast'
export const Test: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { theme, setTheme } = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }
  const handleSelectChange = (selectedOption: string | null) => {
    console.log('Selected Option:', selectedOption)
    // Perform any additional actions on option change
  }
  const data = ['hello', 'false', 'true']
  const [number, setNumber] = useState<string>('')
  const handleChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    console.log('Value changed:', value)
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getSelectedVal = (value: string): void => {
    console.log(value)
  }

  const getChanges = (value: string): void => {
    console.log(value)
  }
  const { toast } = useToast()
  const TestToast2 = (): void => {
    toast({
      variant: 'destructive',
      title: 'Uh oh! Something went wrong.',
      description: 'the best wrong'
    })
  }
  const TestToast3 = (): void => {
    toast({
      variant: 'success',
      title: 'Uh oh! Something went wrong.',
      description: 'the best wrong'
    })
  }
  const TestToast = (): void => {
    toast({
      title: 'Uh oh! Something went wrong.',
      description: 'the best wrong'
    })
  }
  return (
    <div className={'h-screen w-full flex bg-teal-500'}>
      <div className='flex flex-col gap-4 w-[500px] bg-card rounded-lg items-center justify-center m-auto p-8 '>
        <Button size={'icon'} variant={'ghost'} onClick={toggleTheme}>
          <span
            className={
              theme === 'dark'
                ? 'text-foreground fa-solid fa-sun'
                : 'text-foreground fa-solid fa-moon'
            }
          ></span>
        </Button>
        <SearchInput label={'hello'} placeholder={'hello'} data={villageList} name={''} />
        <Button disabled>ghost</Button>
        <Button onClick={TestToast}>test toast</Button>
        <Button variant={'destructive'} onClick={TestToast2}>
          test toast
        </Button>
        <Button variant={'success'} onClick={TestToast3}>
          test toast
        </Button>
        <Button variant={'link'} onClick={TestToast2}>
          link
        </Button>
        <Button variant={'ghost'} onClick={TestToast3}>
          ghost
        </Button>
        <Button
          variant={'warning'}
          size={'default'}
          onClick={() => {
            setTheme('dark')
          }}
        >
          Warning
        </Button>
        <div className={'flex flex-col gap-4'}>
          <Phone value={number} onChange={setNumber} />
          <Phone value={number} onChange={setNumber} disabled />
          <Input
            placeholder={'This default input'}
            type={showPassword ? 'text' : 'password'}
            isPassword
            showPassword={showPassword}
            onShowPassword={() => {
              setShowPassword(!showPassword)
            }}
          />
          <Input disabled placeholder={'disabled'} label={'label'} />
          <Input
            placeholder={'this is number'}
            error
            helperText={'this error message'}
            type={showPassword ? 'text' : 'password'}
            isPassword
            showPassword={showPassword}
            onShowPassword={() => {
              setShowPassword(!showPassword)
            }}
          />
          <Input
            placeholder={'label only'}
            withLabel
            label={'label'}
            type={showPassword ? 'text' : 'password'}
            isPassword
            showPassword={showPassword}
            onShowPassword={() => {
              setShowPassword(!showPassword)
            }}
          />
          <Input
            placeholder={'input has a label and error'}
            withLabel
            label={'Label'}
            helperText={'error'}
            error
            type={showPassword ? 'text' : 'password'}
            isPassword
            showPassword={showPassword}
            onShowPassword={() => {
              setShowPassword(!showPassword)
            }}
          />
        </div>
        <Popover>
          <PopoverTrigger>
            <Button variant={'tertiary'} size={'lg'}>
              tertiary button
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Button
              variant={'ghost'}
              size={'sm'}
              startIcon={<ReloadIcon className='mr-2 h-4 w-4 animate-spin' />}
            >
              destructive
            </Button>
            <Button
              size={'lg'}
              loading={loading}
              startIcon={<ReloadIcon className='mr-2 h-4 w-4 animate-spin' />}
              onClick={() => {
                setLoading(!loading)
              }}
            >
              Click me
            </Button>
          </PopoverContent>
        </Popover>
        <Button variant={'success'}>success</Button>

        <Button
          // disabled
          variant={'secondary'}
          size={'lg'}
          loading={loading}
          onClick={() => {
            setLoading(!loading)
          }}
        >
          <span className={'text-inherit disabled:text-second-disabled fa-solid fa-file'}></span>
          Secondary Button with icon
        </Button>
      </div>
    </div>
  )
}
