import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { acceptLease, type AcceptLeaseDTO } from 'features/farmer/lease-management/api/acceptLease'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type ApiResponse, type Lease } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from 'components/ui/use-toast'

interface UseAcceptOrRejectLease {
  acceptLeaseOffer: (next: () => void) => void
  rejectLeaseOffer: (next: () => void) => void
  isLoading: boolean
}

export const useAcceptOrRejectLease = (lease: Lease): UseAcceptOrRejectLease => {
  const { farmer } = useFarmerStore()
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const acceptOrRejectLeaseMutation = useMutation({
    mutationFn: async (leaseDTO: AcceptLeaseDTO) => {
      return acceptLease(leaseDTO)
    }
  })

  const acceptLeaseOffer = (next: () => void): void => {
    acceptOrRejectLeaseMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: lease?.dest_farmer_id,
        STATUS_ID: 2,
        LAST_UPDATED_BY: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease Accepted.'
            })
            void queryClient.invalidateQueries({ queryKey: ['leases'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const rejectLeaseOffer = (next: () => void): void => {
    acceptOrRejectLeaseMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: lease?.dest_farmer_id,
        STATUS_ID: 3,
        LAST_UPDATED_BY: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease Rejected.'
            })
            void queryClient.invalidateQueries({ queryKey: ['leases'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    isLoading: acceptOrRejectLeaseMutation.isLoading,
    acceptLeaseOffer,
    rejectLeaseOffer
  }
}
