import { type CompanyDetails, type DirectorDetails, type ShareHoldersDetails } from 'types'
import { create } from 'zustand'

interface UseCompanyRegistrationStore {
  companyUIN: string | null
  directorIDDetails: string | null
  companyDetails: CompanyDetails | null
  directorDetails: DirectorDetails | null
  shareHoldersDetails: ShareHoldersDetails[]
  setCompanyUIN: (uin: string | null) => void
  setDirectorIDDetails: (uin: string | null) => void
  setCompanyDetails: (company: CompanyDetails | null) => void
  setDirectorDetails: (director: DirectorDetails | null) => void
  setShareHolderDetails: (shareHolder: ShareHoldersDetails) => void
  deleteShareHolders: () => void
  editShareHolderDetails: (shareHolder: ShareHoldersDetails, id: number) => void
}

export const useCompanyRegistrationStore = create<UseCompanyRegistrationStore>()((set) => ({
  companyUIN: null,
  directorIDDetails: null,
  companyDetails: null,
  directorDetails: null,
  shareHoldersDetails: [],
  setCompanyUIN: (companyUIN: string | null) => {
    set((state) => ({ ...state, companyUIN }))
  },
  setDirectorIDDetails: (directorIDDetails: string | null) => {
    set((state) => ({ ...state, directorIDDetails }))
  },
  setCompanyDetails: (companyDetails: CompanyDetails | null) => {
    set((state) => ({ ...state, companyDetails }))
  },
  setDirectorDetails: (directorDetails: DirectorDetails | null) => {
    set((state) => ({ ...state, directorDetails }))
  },
  setShareHolderDetails: (shareHolder: ShareHoldersDetails) => {
    set((state) => ({ ...state, shareHoldersDetails: [...state.shareHoldersDetails, shareHolder] }))
  },
  deleteShareHolders: () => {
    set((state) => ({ ...state, shareHoldersDetails: [] }))
  },
  editShareHolderDetails: (shareHolderDetails: ShareHoldersDetails, id: number) => {
    set((state) => ({
      shareHoldersDetails: state.shareHoldersDetails.map((shareHolder, index) =>
        index === id ? shareHolderDetails : shareHolder
      )
    }))
  }
}))
