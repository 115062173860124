import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface AcceptOrRejectLeaseTerminationDTO {
  LEASE_ID: number | null | undefined
  FARMER_ID: number | null | undefined
  STATUS_ID: number
  LAST_UPDATED_BY: number | null | undefined
}

export const acceptOrRejectLeaseTerminationDTO = async (
  payload: AcceptOrRejectLeaseTerminationDTO
): Promise<ApiResponse<[]>> => {
  return axios.put('/lease/terminate/accept', payload)
}
