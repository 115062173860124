import moment from 'moment'
import type React from 'react'
import { type Lease } from 'types'
import { Input, Text } from 'components/ui'
import { TabView } from 'components/App/Layout'

interface FarmerInformationProps {
  lease: Lease
}

export const LeasedFarmerInformation: React.FC<FarmerInformationProps> = ({ lease }) => {
  return (
    <TabView>
      <Text size={'medium'}>Lessee Farmer Information</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={'Forenames'}
          value={lease.dest_farmer_firstname}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Surname'}
          value={lease.dest_farmer_surname}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={'Omang'} value={'N/A'} classLabel={'bg-card'} disabled />
        <Input withLabel label={'Place of Birth'} value={'N/A'} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={'Date of Birth'}
          value={moment(lease.dest_farmer_dob).format('DD-MM-YYYY')}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Gender'}
          value={lease.dest_farmer_gender}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Physical Address'}
          value={lease.destination_farmer_physical_address ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Postal Address'}
          value={lease.destination_farmer_postal_address ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={'Email Address'} value={'N/A'} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={'Phone Number'}
          value={lease.destination_farmer_contact}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}
