/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { zodResolver } from '@hookform/resolvers/zod'
import type React from 'react'
import {
  type DefaultValues,
  type FieldValues,
  type SubmitHandler,
  useForm,
  type UseFormProps,
  type UseFormReturn
} from 'react-hook-form'
import { type ZodType, type ZodTypeDef } from 'zod'

interface FormProps<TFormValues extends FieldValues, Schema> {
  className?: string
  onSubmit: SubmitHandler<TFormValues>
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode[] | React.ReactNode
  defaultValues?: DefaultValues<TFormValues> | undefined
  options?: UseFormProps<TFormValues>
  schema: Schema
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Form = <
  TFormValues extends FieldValues,
  Schema extends ZodType<unknown, ZodTypeDef, unknown>
>({
  onSubmit,
  children,
  className,
  defaultValues,
  options,
  schema
}: FormProps<TFormValues, Schema>) => {
  const methods = useForm<TFormValues>({ ...options, resolver: zodResolver(schema), defaultValues })
  return (
    <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
      {children(methods)}
    </form>
  )
}
