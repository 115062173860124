import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type ApiResponse, type Lease } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { terminateLease, type TerminateLeaseDTO } from '../api/terminateLease'
import {
  type AcceptOrRejectLeaseTerminationDTO,
  acceptOrRejectLeaseTerminationDTO
} from '../api/acceptOrRejectLeaseTermination'
import { useToast } from 'components/ui/use-toast'

interface TerminateLeaseProps {
  lessorAcceptTermination: (lease: Lease) => void
  lesseeAcceptTermination: (lease: Lease) => void
  lessorInitiateTermination: (lease: Lease) => void
  lesseeInitiateTermination: (lease: Lease) => void
  isLoading: boolean
}

export const useTerminateLease = (next: () => void): TerminateLeaseProps => {
  const { farmer } = useFarmerStore()
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const initiateLeaseTerminationMutation = useMutation({
    mutationFn: async (leaseDTO: TerminateLeaseDTO) => {
      return terminateLease(leaseDTO)
    }
  })

  const acceptOrRejectLeaseTerminationMutation = useMutation({
    mutationFn: async (payload: AcceptOrRejectLeaseTerminationDTO) => {
      return acceptOrRejectLeaseTerminationDTO(payload)
    }
  })

  const lessorInitiateTermination = (lease: Lease): void => {
    initiateLeaseTerminationMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: lease?.origin_farmer_id,
        STATUS_ID: 5,
        LAST_UPDATED_BY: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease termination request has been sent.'
            })
            void queryClient.invalidateQueries({ queryKey: ['leases'] })
          } else {
            const error = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  const lesseeInitiateTermination = (lease: Lease): void => {
    initiateLeaseTerminationMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: lease?.dest_farmer_id,
        STATUS_ID: 6,
        LAST_UPDATED_BY: farmer?.user_id as number
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease termination request has been sent.'
            })
            void queryClient.invalidateQueries({ queryKey: [`leases-${lease.farm_id}`] })
          } else {
            const error = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  const lessorAcceptTermination = (lease: Lease): void => {
    acceptOrRejectLeaseTerminationMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: lease?.origin_farmer_id,
        STATUS_ID: 8,
        LAST_UPDATED_BY: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease has been terminated.'
            })
            void queryClient.invalidateQueries({ queryKey: ['leases'] })
          } else {
            const error = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  const lesseeAcceptTermination = (lease: Lease): void => {
    acceptOrRejectLeaseTerminationMutation.mutate(
      {
        LEASE_ID: lease?.lease_id,
        FARMER_ID: lease?.dest_farmer_id,
        STATUS_ID: 7,
        LAST_UPDATED_BY: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Lease has been terminated.'
            })
            void queryClient.invalidateQueries({ queryKey: [`leases-${lease.farm_id}`] })
          } else {
            const error = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  return {
    isLoading:
      acceptOrRejectLeaseTerminationMutation.isLoading ||
      initiateLeaseTerminationMutation.isLoading,
    lessorInitiateTermination,
    lesseeInitiateTermination,
    lessorAcceptTermination,
    lesseeAcceptTermination
  }
}
