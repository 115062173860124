import { ChangePasswordForm } from 'components/Change-Password/ChangePasswordForm'
import { NotFound } from 'components/Errors/NotFound'
import { Text } from 'components/Elements/Text/Text'
import type React from 'react'
import { useAuthStore } from 'stores/useAuthStore'
import { ROLES } from 'types'

export const ChangePassword: React.FC = () => {
  const { isFirstLogin, isLoading } = useAuthStore()
  if (isFirstLogin === false && !isLoading) {
    return <NotFound />
  }

  return (
    <div className={'w-full flex justify-center p-0 md:p-8'}>
      <div
        className={
          'w-full max-w-[1500px] flex flex-col bg-white gap-4 md:gap-8 md:rounded-md lg:rounded-xl p-4 py- sm:p-8 md:px-12'
        }
      >
        <Text size={'xLarge'}>Change Password</Text>
        <ChangePasswordForm role={ROLES.OFFICER} />
      </div>
    </div>
  )
}
