import Axios, { type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'

import { API_URL } from 'config'
import { appCheck, auth } from 'config/firebase'
import { type AppCheckTokenResult, getToken } from 'firebase/app-check'

async function authRequestInterceptor(
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const url = `${config?.baseURL ?? ''}${config?.url ?? ''}`
  let appCheckResponse: AppCheckTokenResult | undefined
  let authToken: string | undefined

  if (url?.includes(API_URL)) {
    try {
      await auth?.currentUser?.getIdToken(true).then((idToken) => {
        authToken = idToken
      })
      appCheckResponse = await getToken(appCheck, true)
    } catch (e) {
      return config
    }
  }

  config.headers['app-token'] = appCheckResponse?.token
  config.headers.authToken = authToken
  return config
}

export const axios = Axios.create({
  baseURL: API_URL
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  async (response) => {
    return Promise.resolve(response.data)
  },
  async (error: AxiosResponse) => {
    return Promise.reject(error)
  }
)
