import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface SendOtpDTO {
  number: string
}

export const sendOtp = async (params: SendOtpDTO): Promise<ApiResponse<[]>> => {
  const request = {
    TO: params.number,
    CHANNEL: 'sms'
  }

  return axios.post('/messaging/send/otp', request)
}
