import { BasicButton } from 'components/Elements/Button'
import { Form, InputField } from 'components/Form'
import { useCompanyRegistration } from 'features/authentication/hooks/useCompanyRegistration'
import { useCompanyRegistrationStore } from 'features/authentication/stores/useCompanyRegistrationStore'
import type React from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { object, string, type TypeOf } from 'zod'

interface CompanyUINProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

const schema = object({
  uin: string().min(1, 'Company UIN is required')
})

export type CompanyUINInput = TypeOf<typeof schema>

export const CompanyUIN: React.FC<CompanyUINProps> = ({ onNext, onBack, className }) => {
  const { error, isLoading, checkCompanyUIN } = useCompanyRegistration()
  const { setCompanyUIN, companyUIN } = useCompanyRegistrationStore()

  const onSubmit: SubmitHandler<CompanyUINInput> = async (data: CompanyUINInput) => {
    try {
      const response = await checkCompanyUIN(data.uin)
      if (response === true) {
        setCompanyUIN(data.uin)
        onNext()
      }
    } catch (er) {
      toast.error(error)
    }
  }

  return (
    <Form<CompanyUINInput, typeof schema> className={className} onSubmit={onSubmit} schema={schema}>
      {({ register, formState: { errors }, handleSubmit }) => (
        <>
          <InputField
            label={'Company UIN'}
            error={!(errors.uin == null)}
            helperText={errors?.uin?.message ?? ''}
            register={register('uin')}
            defaultValue={companyUIN != null ? companyUIN : ''}
          />
          <div className={'flex flex-row justify-between gap-8'}>
            <BasicButton
              label={'Back'}
              variant={'secondary'}
              onClick={onBack}
              isDisabled={isLoading}
            />
            <BasicButton
              label={'Next'}
              variant={'primary'}
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
              type={'submit'}
            />
          </div>
        </>
      )}
    </Form>
  )
}
