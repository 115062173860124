import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreateLandPreparationDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  user_id: number | null | undefined
  is_harrowing: boolean
  tillage_method: string | null | undefined
}

export const createLandPreparation = async (
  params: CreateLandPreparationDTO
): Promise<ApiResponse<[]>> => {
  return axios.post('/production/landpreparation', params)
}
