import type React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthLayout } from './AuthLayout'
import { Text, Button } from 'components/ui'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
interface AccountTypeProps {
  onClickIndividual: () => void
  onClickCompany: () => void
}

export const AccountType: React.FC<AccountTypeProps> = ({
  onClickIndividual,
  onClickCompany
}: AccountTypeProps) => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const navigate = useNavigate()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const handleRegistration = (): void => {
    navigate('/login/phone')
  }

  return (
    <AuthLayout
      className={'w-full max-w-[350px] flex flex-col gap-8 justify-center items-center p-8'}
    >
      <Text size={'medium'} className={'text-center ml-2 sm:ml-0 w-full'}>
        Select Account Type
      </Text>
      <div className={'w-full flex justify-between'}>
        <Button onClick={onClickIndividual} className={'w-[46%]'}>
          individual
        </Button>
        <Button
          disabled={flags.companyRegistration === false}
          variant={'ghost'}
          onClick={onClickCompany}
          className={'w-[46%]'}
        >
          company
        </Button>
      </div>
      <div className={'flex items-center sm:justify-center gap-2'} onClick={handleRegistration}>
        <Text>Don't Have an Account?</Text>
        <Text variant={'primary'}>
          <a className='cursor-pointer font-bold' onClick={handleRegistration}>
            Login
          </a>
        </Text>
      </div>
    </AuthLayout>
  )
}
