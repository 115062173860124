import type React from 'react'

export const NotEligible: React.FC = () => {
  return (
    <main className={'grid place-items-center min-h-[30%] h-full flex-1'}>
      <div className={'flex flex-col gap-4 justify-center items-center'}>
        <h1 className='text-destructive text-2xl font-semibold'>Voucher Not Found.</h1>
        <p className={'text-md md:text-lg text-center'}>
          Farmer is not eligible for voucher redemption.
        </p>
      </div>
    </main>
  )
}
