import { ExtensionAreas } from 'features/farmer/land-management/utils/extensionAreas'
import { SubDistricts } from 'features/farmer/land-management/utils/subDistricts'
import type React from 'react'
import { type Lease } from 'types'
import { TabView } from 'components/App/Layout'
import { Input, Text } from 'components/ui'
import { Districts } from '../../../land-management/utils'

interface FarmInformationProps {
  lease: Lease
}

export const FarmInformation: React.FC<FarmInformationProps> = ({ lease }) => {
  const district = Districts.filter((disc) => {
    return lease?.district_id === disc.district_id
  })[0]?.district_name

  const subDistrict = SubDistricts.filter((sub) => {
    return lease?.district_id === sub.district_id && lease.sub_district_id === sub.sub_district_id
  })[0]?.sub_district_name

  const extensionArea = ExtensionAreas.filter((ext) => {
    return (
      lease?.district_id === ext.district_id &&
      lease?.sub_district_id === ext.sub_district_id &&
      lease?.ext_area_id === ext.extension_area_id
    )
  })[0]?.extension_area_name

  return (
    <TabView>
      <Text size={'medium'}>Farm Information</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input withLabel label={'district'} value={district} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={'Sub District'}
          value={subDistrict}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={'Extension Area'}
          value={extensionArea}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={'village'} value={lease.village} classLabel={'bg-card'} disabled />

        <Input withLabel label={'Plot No'} value={'1'} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={'Farm Name'}
          value={lease.farm_name}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}
