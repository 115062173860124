import { Loader } from 'components/Loader'
import type React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { type ROLES } from 'types'
import { useRequiresAuth } from '../hooks/useRequiresAuth'

interface RequireAuthProps {
  allowedRoles: ROLES[]
  children: React.ReactNode
}

export const RequiresAuth: React.FC<RequireAuthProps> = ({ children, allowedRoles }) => {
  const location = useLocation()
  const { loading, isLoading, role, isUser, isFirstLogin } = useRequiresAuth()

  if (loading || isLoading) {
    return <Loader />
  }

  if (isFirstLogin === true && role === 1001 && location.pathname !== '/officer/change-password') {
    return <Navigate replace to={'/officer/change-password'} />
  }

  if (
    isFirstLogin === true &&
    role === 1010 &&
    location.pathname !== '/inventory/change-password'
  ) {
    return <Navigate replace to={'/inventory/change-password'} />
  }

  if (isFirstLogin === true && role === 2000 && location.pathname !== '/soil-lab/change-password') {
    return <Navigate replace to={'/soil-lab/change-password'} />
  }

  if (
    isFirstLogin === true &&
    role === 2001 &&
    location.pathname !== '/service-provider/change-password'
  ) {
    return <Navigate replace to={'/service-provider/change-password'} />
  }

  if (
    isFirstLogin === true &&
    role === 2002 &&
    location.pathname !== '/seed-dealer/change-password'
  ) {
    return <Navigate replace to={'/seed-dealer/change-password'} />
  }

  return isUser && allowedRoles.includes(role as ROLES) ? (
    <>{children}</>
  ) : (
    <Navigate to='/unauthorized' replace />
  )
}
