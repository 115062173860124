import type React from 'react'
import { MobileStepper, Step, StepLabel, Stepper } from '@mui/material'
import { BasicButton } from '../../../../../components/Elements/Button'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Text } from 'components/Elements/Text/Text'
interface LoanStepperProps {
  steps: string[]
  activeStep: number
  next: () => void
  back: () => void
  children: React.ReactNode
  stepperKey: string
  isLoading?: boolean
  isDisabled?: boolean
  finalLabel?: string
}

export const LoanStepper: React.FC<LoanStepperProps> = ({
  steps,
  activeStep,
  next,
  back,
  children,
  stepperKey,
  isDisabled = false,
  isLoading = false,
  finalLabel
}) => {
  return (
    <div className={'w-full h-full p-8 flex flex-col gap-6 flex-grow'}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        key={stepperKey + '-stepper-key'}
        className={'w-full flex-grow'}
        sx={{
          display: { sm: 'none', xs: 'none', lg: 'flex' }
        }}
      >
        {steps.map((label) => (
          <Step
            key={stepperKey + '-stepper-' + label}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#4CAE4FFF'
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                color: '#1F1F1FFF'
              }
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {children}
      <MobileStepper
        variant='dots'
        steps={steps.length}
        position='static'
        activeStep={activeStep}
        sx={{
          flexGrow: 1,
          display: { xs: 'flex', sm: 'none', lg: 'none' },
          gap: { xs: '1rem', sm: '1rem', md: '1.5rem' }
        }}
        key={stepperKey + 'xs-mobile-stepper'}
        nextButton={
          <BasicButton
            label={finalLabel !== undefined ? finalLabel : 'Next'}
            onClick={next}
            size={'xs'}
            disabled={isDisabled}
            isLoading={isLoading}
            icon={
              <KeyboardArrowRight
                sx={{
                  color: 'var(--color-white)'
                }}
              />
            }
            textColor={'var(--color-white)'}
            style={{ marginLeft: '8px' }} // Adjust the margin as needed
          />
        }
        backButton={
          <BasicButton
            label={'Back'}
            variant={'secondary'}
            onClick={back}
            size={'xs'}
            disabled={isLoading}
            startIcon={<KeyboardArrowLeft />}
            style={{ marginRight: '8px' }}
          />
        }
      />
      <MobileStepper
        variant='progress'
        steps={steps.length}
        position='static'
        activeStep={activeStep}
        sx={{
          flexGrow: 1,
          display: { xs: 'none', sm: 'flex', lg: 'none' },
          gap: { xs: '1rem', sm: '1rem', md: '1.5rem' }
        }}
        key={stepperKey + '-mobile-stepper'}
        nextButton={
          <BasicButton
            label={finalLabel !== undefined ? finalLabel : 'Next'}
            onClick={next}
            size={'xs'}
            disabled={isDisabled}
            isLoading={isLoading}
            icon={
              <KeyboardArrowRight
                sx={{
                  color: 'var(--color-white)'
                }}
              />
            }
            textColor={'var(--color-white)'}
            style={{ marginLeft: '8px' }}
          />
        }
        backButton={
          <BasicButton
            label={'Back'}
            variant={'secondary'}
            onClick={back}
            disabled={isLoading}
            size={'xs'}
            startIcon={<KeyboardArrowLeft />}
            style={{ marginRight: '8px' }}
          />
        }
      />
    </div>
  )
}
