import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type ApiLocation } from '../types'

export const getVillageArea = async (village: string): Promise<ApiResponse<ApiLocation>> => {
  return axios.get(`/farm/location?VILLAGE_NAME=${village}`)
}

export const useGetVillageArea = (
  village: string,
  enabled: boolean
): UseQueryResult<ApiResponse<ApiLocation>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farmlocations', village],
    queryFn: async () => getVillageArea(village),
    enabled
  })
}
