import { type Merchant } from 'types'
import { create } from 'zustand'

interface MerchantStore {
  merchant: Merchant | null
  setMerchant: (merchant: Merchant | null) => void
}

export const useMerchantStore = create<MerchantStore>()((set) => ({
  merchant: null,
  setMerchant: (merchant: Merchant | null) => {
    set((state) => ({ ...state, merchant }))
  }
}))
