import { useAcceptOrRejectLease } from 'features/officer/lease-management/hooks/useAcceptOrRejectLease'
import type React from 'react'
import { useForm } from 'react-hook-form'
import { useOfficerStore } from 'stores/useOfficerStore'
import { type Lease } from 'types'
import type z from 'zod'
import { object, string, type TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from 'components/ui'

const rejectSchema = object({
  reason: string().min(1, 'Reason for rejection is required')
})

type RejectInput = TypeOf<typeof rejectSchema>

interface RejectReasonFormProps {
  className?: string
  lease: Lease
  onReject: () => void
  onCancel: () => void
}

export const RejectReasonForm: React.FC<RejectReasonFormProps> = ({
  className,
  lease,
  onReject,
  onCancel
}) => {
  const form = useForm<z.infer<typeof rejectSchema>>({
    resolver: zodResolver(rejectSchema),
    defaultValues: {
      reason: ''
    }
  })
  const { officer } = useOfficerStore()
  const { rejectLease, isLoading } = useAcceptOrRejectLease(lease, officer?.user_id)

  const onSubmit = (data: RejectInput): void => {
    rejectLease(data.reason, onReject)
  }

  return (
    <Form {...form}>
      <form className={className} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name='reason'
          render={({ field }) => (
            <FormItem className='flex flex-col items-start space-x-3 space-y-0 p-4 gap-4'>
              <FormLabel>Rejection Reason</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'flex flex-row justify-between gap-4'}>
          <Button variant={'destructive'} onClick={onCancel} size={'xs'} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'xs'}
            disabled={isLoading}
            loading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </Form>
  )
}
