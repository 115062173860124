import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdatePackagingDTO {
  cp_id: number | null | undefined
  method: string
  user_id: number | null | undefined
  farmer_id: number | null | undefined
}

export const updatePackaging = async (params: UpdatePackagingDTO): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/packaging`, params)
}
