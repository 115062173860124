import {
  CompanyAttachments,
  CompanyCommercialBank,
  CompanyNDBLoans,
  FinancialParticularsCompany,
  SummarizedBusinessInformationCompany
} from 'features/loan-management/components/stepper/company'
import type React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompanyInformation } from './CompanyInformation'
import { AccountantsBody } from './AccountantsBody'
import { AuditorsBody } from './AuditorsBody'
import { ProductionScheduleCompanyBody } from './ProductionScheduleCompanyBody'
import { CompanySecurityBody } from './CompanySecurityBody'
import { CompanyBalanceSheetBody } from './CompanyBalanceSheetBody'
import { CompanyOtherLoansBody } from './CompanyOtherLoansBody'
import { CompanyApplicantEmploymentInfo } from './CompanyApplicantEmployment'
import { SelectCompanyPlans } from './SelectCompanyPlans'

const steps = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14']

export const LoanApplicationFormCompany: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState: number) => prevState + 1)
  }

  const navigate = useNavigate()

  const handleBack = (): void => {
    setActiveStep((prevState: number) => prevState - 1)
  }
  const handleCancel = (): void => {
    navigate('/farmer/loans')
  }

  if (activeStep === 1) {
    return (
      <CompanyAttachments
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }

  // if (activeStep === 2) {
  //   return (
  //     <Successor activeStep={activeStep} steps={steps} onBack={handleBack} onNext={handleNext} />
  //   )
  // }
  if (activeStep === 2) {
    return (
      <CompanyInformation
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 3) {
    return (
      <CompanyApplicantEmploymentInfo
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 4) {
    return (
      <AccountantsBody
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }

  if (activeStep === 5) {
    return (
      <AuditorsBody activeStep={activeStep} steps={steps} onBack={handleBack} onNext={handleNext} />
    )
  }
  if (activeStep === 6) {
    return (
      <CompanyCommercialBank
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 7) {
    return (
      <CompanyNDBLoans
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 8) {
    return (
      <CompanyOtherLoansBody
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 9) {
    return (
      <FinancialParticularsCompany
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 10) {
    return (
      <ProductionScheduleCompanyBody
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 11) {
    return (
      <CompanySecurityBody
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 12) {
    return (
      <SummarizedBusinessInformationCompany
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }
  if (activeStep === 13) {
    return (
      <CompanyBalanceSheetBody
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNext}
      />
    )
  }

  return (
    <SelectCompanyPlans
      activeStep={activeStep}
      steps={steps}
      onBack={handleCancel}
      onNext={handleNext}
    />
  )
}
