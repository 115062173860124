import {
  type CroppingPlan,
  type Harvesting,
  type LandPreparation,
  type Packaging,
  type PestControl,
  type Seeding,
  type ShellingAndThreshing,
  type Weeding
} from 'types'
import { create } from 'zustand'

interface UseCroppingPlanStore {
  ssrId: number | null
  header: CroppingPlan | null
  seeding: Seeding | null
  weeding: Weeding | null
  pest: PestControl | null
  landPreparation: LandPreparation | null
  harvesting: Harvesting | null
  shellingAndThreshing: ShellingAndThreshing | null
  packaging: Packaging | null
  setSsrId: (ssrId: number | null) => void
  setHeader: (header: CroppingPlan | null) => void
  setSeeding: (seeding: Seeding | null) => void
  setWeeding: (weeding: Weeding | null) => void
  setPest: (pest: PestControl | null) => void
  setLandPreparation: (landPreparation: LandPreparation | null) => void
  setHarvesting: (harvesting: Harvesting | null) => void
  setShellingAndThreshing: (shellingAndThreshing: ShellingAndThreshing | null) => void
  setPackaging: (packaging: Packaging | null) => void
}

export const useCroppingPlanStore = create<UseCroppingPlanStore>()((set) => ({
  ssrId: null,
  header: null,
  seeding: null,
  weeding: null,
  pest: null,
  landPreparation: null,
  harvesting: null,
  shellingAndThreshing: null,
  packaging: null,
  setSsrId: (ssrId: number | null) => {
    set((state) => ({ ...state, ssrId }))
  },
  setHeader: (header: CroppingPlan | null) => {
    set((state) => ({ ...state, header }))
  },
  setSeeding: (seeding: Seeding | null) => {
    set((state) => ({ ...state, seeding }))
  },
  setWeeding: (weeding: Weeding | null) => {
    set((state) => ({ ...state, weeding }))
  },
  setPest: (pest: PestControl | null) => {
    set((state) => ({ ...state, pest }))
  },
  setLandPreparation: (landPreparation: LandPreparation | null) => {
    set((state) => ({ ...state, landPreparation }))
  },
  setHarvesting: (harvesting: Harvesting | null) => {
    set((state) => ({ ...state, harvesting }))
  },
  setShellingAndThreshing: (shellingAndThreshing: ShellingAndThreshing | null) => {
    set((state) => ({ ...state, shellingAndThreshing }))
  },
  setPackaging: (packaging: Packaging | null) => {
    set((state) => ({ ...state, packaging }))
  }
}))
