import { type CroppingPlan, type Farm, type Lease } from 'types'
import { type FC, useState } from 'react'
import { CroppingPlans } from './CroppingPlans'
import { NewGrantOtpForm } from './NewGrantOtpForm'
import { type SelectedCroppingPlans } from '../../types'

interface Base {
  cancel: () => void
  croppingPlans: CroppingPlan[]
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type GrantFormProps = WithLease | WithFarm

export const GrantForm: FC<GrantFormProps> = ({ cancel, lease, farm, croppingPlans }) => {
  const [showOTP, setShowOTP] = useState(false)
  const [selectedCroppingPlans, setSelectedCroppingPlans] = useState<SelectedCroppingPlans[]>([])

  const handleNext = (): void => {
    setShowOTP(true)
  }

  if (showOTP) {
    if (lease != null) {
      return (
        <NewGrantOtpForm
          selectedCroppingPlans={selectedCroppingPlans}
          cancel={cancel}
          lease={lease}
        />
      )
    }
    return (
      <NewGrantOtpForm selectedCroppingPlans={selectedCroppingPlans} cancel={cancel} farm={farm} />
    )
  }

  if (lease != null) {
    return (
      <CroppingPlans
        croppingPlans={croppingPlans}
        lease={lease}
        next={handleNext}
        cancel={cancel}
        setCroppingPlans={setSelectedCroppingPlans}
      />
    )
  }

  return (
    <CroppingPlans
      croppingPlans={croppingPlans}
      farm={farm}
      next={handleNext}
      cancel={cancel}
      setCroppingPlans={setSelectedCroppingPlans}
    />
  )
}
