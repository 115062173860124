import { useGetFarms } from 'features/farmer/land-management/api/getFarms'
import { useEffect, useState } from 'react'
import { type Farm } from 'types'
import { type AxiosError } from 'axios'

interface UseMyFarms {
  farms: Farm[] | undefined
  verified: Farm[] | null
  rejected: Farm[] | null
  pending: Farm[] | null
  isLoading: boolean
  error: AxiosError | null
}

export const useMyFarms = (farmerId: number | null | undefined): UseMyFarms => {
  const { data: farms, isLoading, isInitialLoading, error } = useGetFarms(farmerId)
  const [verified, setVerified] = useState<Farm[] | null>(null)
  const [rejected, setRejected] = useState<Farm[] | null>(null)
  const [pending, setPending] = useState<Farm[] | null>(null)

  useEffect(() => {
    if (farms?.status === 200) {
      setVerified(
        farms.data.filter((res) => {
          return res.status === 'verified'
        })
      )
      setRejected(
        farms.data.filter((res) => {
          return res.status === 'rejected'
        })
      )
      setPending(
        farms.data.filter((res) => {
          return res.status === 'pending'
        })
      )
    }
  }, [farms])

  return {
    farms: farms?.data,
    verified,
    rejected,
    pending,
    error,
    isLoading: isLoading || isInitialLoading
  }
}
