import logo from 'assets/logos/moa.svg'
import type React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Text } from 'components/ui'

interface LayoutProps {
  className?: string
  children: React.ReactNode
}

export const AuthLayout: React.FC<LayoutProps> = ({ children, className }: LayoutProps) => {
  const navigate = useNavigate()
  const handleGoHome = (): void => {
    navigate('/')
  }

  return (
    <div
      className={
        'w-full min-h-[100svh] bg-background flex flex-col gap-10 md:gap-16 justify-center items-center p-4 pt-4 sm:pt-[5vh] '
      }
    >
      <div className='w-full flex gap-2 justify-center items-center'>
        <Link to={'/'}>
          <img
            role={'presentation'}
            onClick={handleGoHome}
            className='h-[4rem] md:h-[7rem] xl:h-[7.5rem] sm:h-[clamp(3rem,10vw,4rem)] hover:transform hover:scale-[1.005] cursor-pointer hover:duration-500 ease-in-out transition-transform'
            src={logo}
            alt={logo}
          />
        </Link>
        <Text className={'sm:hidden'}>Ministry Of Agriculture</Text>
      </div>
      {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
      <main className={`sm:bg-card rounded-lg sm:shadow-lg ${className}`}>{children}</main>
    </div>
  )
}
