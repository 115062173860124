import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { verifyLease, type VerifyLeaseDTO } from 'features/officer/lease-management/api/verifyLease'
import { type ApiResponse, type Lease } from 'types'
import { useToast } from '../../../../components/ui'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'

interface UseAcceptOrRejectLease {
  isLoading: boolean
  acceptLease: (next: () => void) => void
  rejectLease: (reason: string, next: () => void) => void
}

export const useAcceptOrRejectLease = (
  lease: Lease,
  officerId: number | null | undefined
): UseAcceptOrRejectLease => {
  const { toast } = useToast()

  const acceptLeaseMutation = useMutation({
    mutationFn: async (verifyDTO: VerifyLeaseDTO) => {
      return verifyLease(verifyDTO)
    }
  })
  const rejectLeaseMutation = useMutation({
    mutationFn: async (verifyDTO: VerifyLeaseDTO) => {
      return verifyLease(verifyDTO)
    }
  })

  const acceptLease = (next: () => void): void => {
    acceptLeaseMutation.mutate(
      {
        LEASE_ID: lease.lease_id,
        LAST_UPDATED_BY: officerId,
        STATUS_ID: 4,
        LEASE_MESSAGE: 'lease successfully verified'
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  const rejectLease = (reason: string, next: () => void): void => {
    acceptLeaseMutation.mutate(
      {
        LEASE_ID: lease.lease_id,
        LAST_UPDATED_BY: officerId,
        STATUS_ID: 2,
        LEASE_MESSAGE: reason
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  return {
    isLoading: rejectLeaseMutation.isLoading || acceptLeaseMutation.isLoading,
    rejectLease,
    acceptLease
  }
}
