import type React from 'react'
import { useState } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { useAtom } from 'jotai'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { companyLoanStore } from '../stores'
import { Auditors, type AuditorsInput, schemaAud } from './Auditors'
import { usePhoneValidationArray } from 'features/loan-management/hooks/usePhoneValidationArray'
import LoanButtonGroup from '../../generic/form/LoanButtonGroup'

interface AuditorsBodyProps {
  className?: string
  onNext: () => void
  onBack: () => void
  steps: string[]
  activeStep: number
}

export const AuditorsBody: React.FC<AuditorsBodyProps> = ({
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const [, setLoanData] = useAtom(companyLoanStore)
  const [telephones, setTelephones] = useState<string[]>([''])
  const [faxes, setFaxes] = useState<string[]>([''])
  const [cells, setCells] = useState<string[]>([''])
  const isTelephoneValid = usePhoneValidationArray(telephones)
  const isFaxValid = usePhoneValidationArray(faxes)
  const isCellValid = usePhoneValidationArray(cells)

  const isSubmitDisabled = !(isTelephoneValid && isFaxValid && isCellValid)

  const onSubmitAud: SubmitHandler<AuditorsInput> = (data: AuditorsInput) => {
    setLoanData((prev) => ({
      ...prev!,
      loan_auditors: data.auditors
    }))
    onNext()
  }

  return (
    <FormWithStepper<AuditorsInput, typeof schemaAud>
      className={`flex flex-col p-4 flex-grow-[3] justify-between w-full`}
      isDisabled={false}
      onSubmit={onSubmitAud}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schemaAud}
    >
      {(methods) => (
        <>
          <Auditors
            methods={methods}
            telephones={telephones}
            faxes={faxes}
            cells={cells}
            setTelephones={setTelephones}
            setFaxes={setFaxes}
            setCells={setCells}
          />
          <div className='w-full p-8 hidden lg:flex flex-row justify-between gap-12'>
            <LoanButtonGroup
              onBack={onBack}
              onSubmit={methods.handleSubmit(onSubmitAud)}
              disabled={isSubmitDisabled}
            />
          </div>
        </>
      )}
    </FormWithStepper>
  )
}
