import { useState } from 'react'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { useLeaseStore } from '../stores/leaseStore'
import { AxiosError, type AxiosResponse } from 'axios'
import type { ApiResponse, Lessee } from '../../../../types'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'
import { getFarmer } from '../api/getFarmer'
import { useToast } from 'components/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createLease, type CreateLeaseDTO } from '../api/createLease'

interface UseLeaseFarm {
  isLoading: boolean
  lessee: Lessee | null
  searchLessee: (farmerId: string, next: () => void) => Promise<void>
  createNewLease: (lease: CreateLeaseDTO, next: () => void) => void
}

export const useLeaseFarm = (): UseLeaseFarm => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const { setLessorDetails, farmerID } = useLeaseStore()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const [lessee, setLessee] = useState<Lessee | null>(null)

  const createLeaseMutation = useMutation({
    mutationFn: async (lease: CreateLeaseDTO) => {
      return createLease(lease)
    }
  })

  const searchLessee = async (farmerId: string, next: () => void): Promise<void> => {
    setLessee(null)
    setIsLoading(true)

    try {
      const response = await getFarmer(farmerId)

      if (response.status === 200) {
        setLessee(response.data)
        setLessorDetails(response.data)
        next()
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: response.message
        })
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        })
      } else {
        const errorMsg = err as Error
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(errorMsg.message)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const createNewLease = (lease: CreateLeaseDTO, next: () => void): void => {
    createLeaseMutation.mutate(
      {
        ORIGIN_FARMER_ID: farmer?.farmer_id,
        LEASED_AREA: lease.LEASED_AREA,
        COMMENCEMENT_DATE: lease.COMMENCEMENT_DATE,
        TERMINATION_DATE: lease.TERMINATION_DATE,
        DEST_FARMER_ID: parseInt(farmerID ?? '0'),
        FARM_ID: lease.FARM_ID,
        LAST_UPDATED_BY: farmer?.user_id
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Your lease request has been created successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: [`leases-${lease.FARM_ID ?? ''}`] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    isLoading: isLoading || createLeaseMutation.isLoading,
    lessee,
    searchLessee,
    createNewLease
  }
}
