import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Card,
  Layout,
  Text
} from 'components/ui'
import type React from 'react'
import { Manuals } from './manualsContent'
import { Link } from 'react-router-dom'
import { ManualBanner } from './Banner'

export const ManualNavigation: React.FC = () => {
  return (
    <Layout variant={'bodyMax'}>
      <ManualBanner />
      <Layout className={'w-full flex flex-col px-6 sm:px-8 py-8'}>
        <Breadcrumb className={'border-b border-border p-4'}>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href='/'>Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>User Manuals</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className='w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'>
          {Manuals.map((items) => (
            <Card className='md:max-w-[500px] relative flex flex-col justify-between gap-8 sm:gap-4 md:px-6 lg:px-8'>
              <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-1'}>
                  <Text size={'large'} variant={'primaryLight'}>
                    {items.title}
                  </Text>
                  <Text variant={'muted'}>{items.subTitle}</Text>
                </div>

                <Text>
                  <div dangerouslySetInnerHTML={{ __html: items.description }} />
                </Text>
              </div>
              <Link to={items.link}>
                <Button size={'lg'} variant={'secondary'}>
                  Learn More
                </Button>
              </Link>
            </Card>
          ))}
        </div>
      </Layout>
    </Layout>
  )
}
