import dayjs from 'dayjs'
import { useLeaseStore } from 'features/farmer/land-management/stores/leaseStore'
import type React from 'react'
import { useForm } from 'react-hook-form'
import { type Farm } from 'types'
import type z from 'zod'
import { coerce, object } from 'zod'
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger
} from 'components/ui'
import { useLeaseFarm } from '../../hooks/useLeaseFarm'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '../../../../../lib/utils'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import {
  maxLeaseYear,
  maxLeaseYearDate,
  minLeaseYearDate,
  minLeaseYearTaxPayerDate
} from '../../../../../utils/utilFunctions'

interface LeaseDetailsProps {
  farm: Farm
  back: () => void
  next: () => void
}

export const LeaseDetails: React.FC<LeaseDetailsProps> = ({ next, back, farm }) => {
  const { createNewLease, isLoading } = useLeaseFarm()
  const { lesseeDetails } = useLeaseStore()

  const minLeaseYears = lesseeDetails?.class_id !== 'TAXPAYER' ? 1 : 5
  const minDate =
    lesseeDetails?.class_id !== 'TAXPAYER' ? minLeaseYearDate() : minLeaseYearTaxPayerDate()
  const maxDate = maxLeaseYearDate()
  const maxYear = maxLeaseYear()

  const leaseSchema = object({
    leaseSize: coerce
      .number({
        required_error: 'lease size is required',
        invalid_type_error: 'lease size should be a number'
      })
      .min(1, 'lease size should be greater than 1')
      .max(
        farm.size - farm.leased_size,
        `lease size should be less than ${farm.size - farm.leased_size}`
      ),
    startDate: coerce
      .date()
      .refine((data) => data > new Date(), 'Start date must be in the future'),
    endDate: coerce.date()
  })
    .refine((data) => data.endDate >= minDate, {
      message: `End date should be at least ${minLeaseYears} year after today`,
      path: ['endDate']
    })
    .refine((data) => data.endDate <= maxDate, {
      message: `End date should be at most 10 years after today`,
      path: ['endDate']
    })

  const form = useForm<z.infer<typeof leaseSchema>>({
    resolver: zodResolver(leaseSchema),
    defaultValues: {
      leaseSize: 1,
      startDate: undefined,
      endDate: undefined
    }
  })

  const onSubmit = (data: z.infer<typeof leaseSchema>): void => {
    createNewLease(
      {
        ORIGIN_FARMER_ID: undefined,
        LEASED_AREA: data.leaseSize,
        COMMENCEMENT_DATE: dayjs(data.startDate).format('YYYY-MM-DD'),
        TERMINATION_DATE: dayjs(data.endDate).format('YYYY-MM-DD'),
        DEST_FARMER_ID: undefined,
        FARM_ID: farm.farm_id,
        LAST_UPDATED_BY: undefined
      },
      next
    )
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={'leaseSize'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>Lease Size</FormLabel>
              <FormDescription>Max Size: {farm.size - farm.leased_size}</FormDescription>
              <FormControl>
                <Input
                  placeholder='Lease Size'
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        {/* date picker */}
        <FormField
          control={form.control}
          name='startDate'
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>Start Date</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={'outlined'}
                      className={cn(
                        'text-left font-normal',
                        field.value != null && 'text-muted-foreground'
                      )}
                    >
                      {field.value != null ? format(field.value, 'PPP') : <span>Pick a date</span>}
                      <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0' align='start'>
                  <Calendar
                    mode='single'
                    captionLayout={'dropdown-buttons'}
                    toYear={dayjs(new Date(Date.now())).toDate().getFullYear() + 1}
                    fromDate={dayjs(new Date(Date.now())).toDate()}
                    selected={field.value}
                    onSelect={field.onChange}
                    disabled={(date) => date <= new Date(Date.now())}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='endDate'
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>End Date</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={'outlined'}
                      disabled={form.formState.dirtyFields.startDate == null}
                      className={cn(
                        'text-left font-normal',
                        field.value != null && 'text-muted-foreground'
                      )}
                    >
                      {field.value != null ? format(field.value, 'PPP') : <span>Pick a date</span>}
                      <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0' align='start'>
                  <Calendar
                    mode='single'
                    captionLayout='dropdown-buttons'
                    toYear={maxYear}
                    selected={field.value}
                    onSelect={field.onChange}
                    disabled={(date) =>
                      date <
                      new Date(
                        new Date(form.watch('startDate'))?.setFullYear(
                          new Date(form.watch('startDate'))?.getFullYear() + minLeaseYears
                        )
                      )
                    }
                    fromDate={
                      new Date(
                        new Date(form.watch('startDate'))?.setFullYear(
                          new Date(form.watch('startDate'))?.getFullYear() + minLeaseYears
                        )
                      )
                    }
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button disabled={isLoading} variant={'secondary'} className={'w-1/2'} onClick={back}>
            Back
          </Button>
          <Button disabled={isLoading} loading={isLoading} className={'w-1/2'} type={'submit'}>
            Next
          </Button>
        </div>
      </form>
    </Form>
  )
}
