/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */

import type React from 'react'
import { useAtom } from 'jotai/index'
import { companyLoanStore } from '../stores'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { CompanyOtherLoans, type CompanyOtherLoansInput, schemaCOL } from './CompanyOtherLoans'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import LoanButtonGroup from '../../generic/form/LoanButtonGroup'
import { type LoanStepperProps } from '../../../types'

export const CompanyOtherLoansBody: React.FC<LoanStepperProps> = ({
  className,
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const { farmer } = useFarmerStore()
  const onSubmit: SubmitHandler<CompanyOtherLoansInput> = (data: CompanyOtherLoansInput) => {
    setLoanData((prev) => ({
      ...prev!,
      loans_with_other_institutions_ndb: data.loans_with_other_institution.map((loan) => ({
        company_registration_id: farmer?.farmer_id!,
        last_update_by: Number(farmer?.user_id),
        farmer_id: Number(farmer?.user_id),
        ...loan
      }))
      // other properties...
    }))
    onNext()
  }

  return (
    <FormWithStepper<CompanyOtherLoansInput, typeof schemaCOL>
      className={'flex flex-col p-4 flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schemaCOL}
    >
      {(methods) => (
        <>
          <CompanyOtherLoans methods={methods} />
          <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
            <LoanButtonGroup onBack={onBack} onSubmit={methods.handleSubmit(onSubmit)} />
          </div>
        </>
      )}
    </FormWithStepper>
  )
}
