import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createPestControl,
  type CreatePestControlDTO
} from 'features/farmer/cropping-plan/api/pest-control/createPestControl'
import {
  updatePestControl,
  type UpdatePestControlDTO
} from 'features/farmer/cropping-plan/api/pest-control/updatePestControl'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { getManualPestStage } from 'features/farmer/cropping-plan/utils/functions'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, type PestControl } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { type z } from 'zod'
import { type pestSchema } from '../components/steps/pest/Pest'
import { useToast } from '../../../../components/ui'

interface UseCroppingPlanPest {
  header: CroppingPlan | null
  pest: PestControl | null
  setPest: (pest: PestControl | null) => void
  isLoading: boolean
  createManualPest: (data: z.infer<typeof pestSchema>) => void
  updateManualPest: (data: z.infer<typeof pestSchema>) => void
}

export const useCroppingPlanPest = (next: () => void): UseCroppingPlanPest => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { header, pest, setPest } = useCroppingPlanStore()

  const createPestMutation = useMutation({
    mutationFn: async (cpDto: CreatePestControlDTO) => {
      return createPestControl(cpDto)
    }
  })

  const updatePestMutation = useMutation({
    mutationFn: async (cpDto: UpdatePestControlDTO) => {
      return updatePestControl(cpDto)
    }
  })

  const createManualPest = (data: z.infer<typeof pestSchema>): void => {
    createPestMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: data.type,
        user_id: farmer?.user_id,
        manual_method: data.type === 'Manual' ? data.applicationMethod : undefined,
        manual_schedule:
          data.type === 'Manual' ? getManualPestStage(data.manual_schedule) : undefined
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['pest-control'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }
  const updateManualPest = (data: z.infer<typeof pestSchema>): void => {
    updatePestMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: data.type,
        user_id: farmer?.user_id,
        manual_method: data.type === 'Manual' ? data.applicationMethod : undefined,
        manual_schedule:
          data.type === 'Manual' ? getManualPestStage(data.manual_schedule) : undefined
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['pest-control'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }
  // const createChemicalPest = (data: PestChemicalMethodsInput): void => {
  //   createPestMutation.mutate(
  //     {
  //       farmer_id: farmer?.farmer_id,
  //       cp_id: header?.cropping_plan_id,
  //       type: pest?.type,
  //       user_id: farmer?.user_id,
  //       chemical_method: getChemicalPestMethod(data.applicationMethod),
  //       chemical_schedule: pest?.chemical_schedule,
  //       chemicals: pest?.chemical_list
  //     },
  //     {
  //       onSuccess: (result) => {
  //         if (result.status === 201) {
  //           next()
  //           toast({
  //             variant: 'success',
  //             title: 'Created Successfully.'
  //           })
  //           void queryClient.invalidateQueries({ queryKey: ['pest-control'] })
  //         }
  //       },
  //       onError: (err) => {
  //         if (err instanceof AxiosError) {
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
  //           })
  //         } else {
  //           const err1 = err as Error
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err1.message)
  //           })
  //         }
  //       }
  //     }
  //   )
  // }
  // const updateChemicalPest = (data: PestChemicalMethodsInput): void => {
  //   updatePestMutation.mutate(
  //     {
  //       farmer_id: farmer?.farmer_id,
  //       cp_id: header?.cropping_plan_id,
  //       type: pest?.type,
  //       user_id: farmer?.user_id,
  //       chemical_method: getChemicalPestMethod(data.applicationMethod),
  //       chemical_schedule: pest?.chemical_schedule,
  //       chemicals: pest?.chemical_list
  //     },
  //     {
  //       onSuccess: (result) => {
  //         if (result.status === 201) {
  //           next()
  //           toast({
  //             variant: 'success',
  //             title: 'Updated Successfully.'
  //           })
  //           void queryClient.invalidateQueries({ queryKey: ['pest-control'] })
  //         }
  //       },
  //       onError: (err) => {
  //         if (err instanceof AxiosError) {
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
  //           })
  //         } else {
  //           const err1 = err as Error
  //           toast({
  //             variant: 'destructive',
  //             title: 'Uh oh! Something went wrong.',
  //             description: mapCmsErrorToMessage(err1.message)
  //           })
  //         }
  //       }
  //     }
  //   )
  // }

  return {
    header,
    pest,
    setPest,
    isLoading: createPestMutation.isLoading || updatePestMutation.isLoading,
    createManualPest,
    updateManualPest
  }
}
