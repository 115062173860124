import type React from 'react'
import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from 'components/ui'
import { MoreHorizontal } from 'lucide-react'
import type { Lease } from 'types'
import { TerminateLease } from '../terminate-lease/TerminateLease'
import { AcceptTermination } from '../terminate-lease/AcceptTermination'
import { LeaseRenewal } from '../lease-renewal/LeaseRenewal'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

interface DataTableActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  lease: Lease
}

export const LeaseActions: React.FC<DataTableActionsProps> = ({ lease }) => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const [openTermination, setOpenTermination] = useState<boolean>(false)
  const [openRenewal, setOpenRenewal] = useState<boolean>(false)
  const [openRenewalReject, setOpenRenewalReject] = useState<boolean>(false)
  const [openTerminationAcceptance, setOpenTerminationAcceptance] = useState<boolean>(false)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])
  const handleCloseTermination = (): void => {
    setOpenTermination(false)
  }

  const handleOpenTermination = (): void => {
    setOpenTermination(true)
  }

  const handleCloseAcceptTermination = (): void => {
    setOpenTerminationAcceptance(false)
  }

  const handleOpenAcceptTermination = (): void => {
    setOpenTerminationAcceptance(true)
  }

  const handleCloseRenewal = (): void => {
    setOpenRenewal(false)
  }

  const handleOpenRenewal = (): void => {
    setOpenRenewal(true)
  }

  const handleCloseRenewalReject = (): void => {
    setOpenRenewalReject(false)
  }

  const handleOpenRenewalReject = (): void => {
    setOpenRenewalReject(true)
  }

  // actions to reject or accept lease renewal request
  if (lease.status_desc === 'RENEWAL INITIATED BY LESSEE') {
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size={'icon'} variant='ghost' className='h-8 w-8 p-0'>
              <span className='sr-only'>Open menu</span>
              <MoreHorizontal className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuItem onClick={handleOpenRenewal}>Accept Renewal</DropdownMenuItem>
            <DropdownMenuItem onClick={handleOpenRenewalReject}>Reject Renewal</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <Dialog
          key={'accept-renewal'}
          open={openRenewal}
          defaultOpen={false}
          modal
          onOpenChange={handleCloseRenewal}
        >
          <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
            <LeaseRenewal state={'accept'} lease={lease} next={handleCloseRenewal} />
          </DialogContent>
        </Dialog>

        <Dialog
          key={'reject-renewal'}
          open={openRenewalReject}
          defaultOpen={false}
          modal
          onOpenChange={handleCloseRenewalReject}
        >
          <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
            <LeaseRenewal state={'reject'} lease={lease} next={handleCloseRenewalReject} />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  // actions to reject or accept lease termination request
  if (lease.status_desc === 'TERMINATION INITIATED BY LESSEE') {
    if (flags.leaseTermination === false) {
      return null
    }

    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size={'icon'} variant='icon' className='h-8 w-8 p-0'>
              <span className='sr-only'>Open menu</span>
              <MoreHorizontal className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            <DropdownMenuItem onClick={handleOpenAcceptTermination}>
              Accept Termination
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <Dialog
          key={'accept-terminate'}
          open={openTerminationAcceptance}
          defaultOpen={false}
          modal
          onOpenChange={handleCloseTermination}
        >
          <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
            <AcceptTermination isLessor={true} lease={lease} next={handleCloseAcceptTermination} />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  // no actions available for these statuses
  // expired
  // terminated
  if (
    lease.status_desc === 'EXPIRED' ||
    lease.status_desc === 'REJECTED BY LESSEE' ||
    lease.status_desc === 'TERMINATION ACCEPTED BY LESSEE' ||
    lease.status_desc === 'TERMINATION ACCEPTED BY LESSOR' ||
    lease.status_desc === 'INITIATED BY LESSOR' ||
    lease.status_desc === 'TERMINATION INITIATED BY LESSOR'
  ) {
    return null
  }

  if (flags.leaseTermination === false) {
    return null
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size={'icon'} variant='icon' className='h-8 w-8 p-0'>
            <span className='sr-only'>Open menu</span>
            <MoreHorizontal className='h-4 w-4' />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuItem onClick={handleOpenTermination}>Terminate</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        key={'terminate'}
        open={openTermination}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseTermination}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <TerminateLease lease={lease} next={handleCloseTermination} />
        </DialogContent>
      </Dialog>
    </>
  )
}
