import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreateFarmDTO {
  FARMER_ID: number | null | undefined
  FARM_NAME: string
  LAST_UPDATED_BY: number | null | undefined // userid
  PLOT_NO: number
  DISTRICT_ID: number
  SUB_DISTRICT_ID: number
  EXT_AREA_ID: number
  VILLAGE_ID: number
  SIZE: number
  NICKNAME: string
  UNIT_OF_MEASURE: number // 1 is the default
}

export const createFarm = async (params: CreateFarmDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/farm', params)
}
