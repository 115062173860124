import { AxiosError, type AxiosResponse } from 'axios'
import { app, auth } from 'config/firebase'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import { FirebaseError } from 'firebase/app'
import {
  browserSessionPersistence,
  type ConfirmationResult,
  setPersistence,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import { getFunctions, httpsCallable, type HttpsCallableResult } from 'firebase/functions'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from 'stores/useAuthStore'
import { type ApiResponse } from 'types'
import { mapAuthErrorToMessage } from 'utils'
import { verifyNumber } from '../api/verifyNumber'
import { type NumberExists } from '../types'
import { signInWithNumber } from '../utils/signInWithNumber'
import { useToast } from '../../../components/ui/use-toast'

interface PhoneLoginResponse {
  status: boolean
  confirm: ConfirmationResult | undefined
}

interface UseAuth {
  isLoading: boolean
  error: string | null
  role: number | null
  isFirstLogin: boolean | null
  userId: number | null
  phoneLoginResponse: PhoneLoginResponse
  otpVerificationStatus: boolean
  logOut: () => void
  signOutUser: () => void
  phoneSignIn: (number: string) => Promise<void>
  phoneSignUp: (number: string) => Promise<void>
  emailSignIn: (email: string, password: string) => Promise<void>
  resendOtp: (number: string) => Promise<void>
  verifyOtp: (verify: ConfirmationResult | undefined, otp: string) => Promise<void>
}

export const useAuth = (): UseAuth => {
  const { toast } = useToast()
  const {
    role,
    setRole,
    error,
    setError,
    isFirstLogin,
    setIsFirstLogin,
    userId,
    setUserID,
    user,
    setUser,
    isLoading,
    setIsLoading
  } = useAuthStore()
  const { contactDetails, setContactDetails } = useIndividualRegistrationStore()
  const navigate = useNavigate()
  const functions = getFunctions(app, 'us-central1')
  const [phoneLoginResponse, setPhoneLoginResponse] = useState<PhoneLoginResponse>({
    status: false,
    confirm: undefined
  })
  const [otpVerificationStatus, setOtpVerificationStatus] = useState(false)

  async function checkIfNumberExists(phoneNumber: string): Promise<HttpsCallableResult> {
    const checkNumber = httpsCallable(functions, 'checkNumberExists')

    return checkNumber({ phoneNumber })
  }

  const emailSignIn = async (email: string, password: string): Promise<void> => {
    setIsLoading(true)
    setError(null)
    try {
      await setPersistence(auth, browserSessionPersistence)
      const signInResponse = await signInWithEmailAndPassword(auth, email, password)
      await signInResponse.user.getIdTokenResult(true).then((token) => {
        const claims = token.claims
        setRole((claims?.role as number) ?? null)
        setIsFirstLogin(claims?.isFirstLogin as boolean)
        setUser(signInResponse.user)
        setUserID((claims?.uid as number) ?? null)
      })
    } catch (err: unknown) {
      const errorMsg = err as FirebaseError
      setError(mapAuthErrorToMessage(errorMsg.code))
    } finally {
      setIsLoading(false)
    }
  }

  const phoneSignIn = async (number: string): Promise<void> => {
    setIsLoading(true)
    setError(null)
    setOtpVerificationStatus(false)
    setPhoneLoginResponse({
      status: false,
      confirm: undefined
    })
    try {
      const checkNumber = await checkIfNumberExists(number.replace(/\s/g, ''))
      if (checkNumber.data !== null) {
        const response = await verifyNumber({ CONTACT: number.replace(/\s/g, '') })
        if (response.status === 200 && response.data[0].exists) {
          await setPersistence(auth, browserSessionPersistence)
          const signInResponse = await signInWithNumber(number.replace(/\s/g, ''))
          setPhoneLoginResponse({ status: true, confirm: signInResponse })
          toast({
            variant: 'success',
            title: 'Otp successfully sent'
          })
        } else {
          setError('user does not exist.')
        }
      }
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        setError(mapAuthErrorToMessage(err.code))
      } else if (err instanceof Error) {
        setError(
          err.message === 'user does not exist.'
            ? 'User with number does not exist!'
            : 'An error occurred, Try Again'
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const phoneSignUp = async (number: string): Promise<void> => {
    setIsLoading(true)
    setError(null)
    setOtpVerificationStatus(false)
    setPhoneLoginResponse({
      status: false,
      confirm: undefined
    })
    try {
      const response = await verifyNumber({ CONTACT: number.replace(/\s/g, '') })
      if (response.status === 200 && response.data[0].exists) {
        setError('User with number already exists.')
      } else if (response.status === 200 && !response.data[0].exists) {
        setContactDetails(
          contactDetails === null
            ? {
                number: number.replace(/\s/g, ''),
                email: null,
                physicalAddress: null,
                postalAddress: null
              }
            : {
                ...contactDetails,
                number: number.replace(/\s/g, '')
              }
        )
        const signInResponse = await signInWithNumber(number.replace(/\s/g, ''))
        toast({
          variant: 'success',
          title: 'Otp successfully sent'
        })
        setPhoneLoginResponse({ status: true, confirm: signInResponse })
      }
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        setError(mapAuthErrorToMessage(err.code))
      } else if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<NumberExists>>
        setError(mapAuthErrorToMessage(errorMsg?.data.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const verifyOtp = async (verify: ConfirmationResult | undefined, otp: string): Promise<void> => {
    setIsLoading(true)
    setError(null)
    setOtpVerificationStatus(false)

    try {
      if (verify !== undefined) {
        const signInResponse = await verify.confirm(otp)
        await signInResponse.user.getIdTokenResult(true).then((token) => {
          const claims = token.claims
          setRole((claims?.role as number) ?? null)
          setUser(signInResponse.user)
          setUserID((claims?.uid as number) ?? null)
          setOtpVerificationStatus(true)
        })
      }
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        setError(mapAuthErrorToMessage(err.code))
      } else {
        const errorMsg = err as Error
        setError(mapAuthErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const resendOtp = async (number: string): Promise<void> => {
    setIsLoading(true)
    setError(null)
    setOtpVerificationStatus(false)

    try {
      await setPersistence(auth, browserSessionPersistence)
      const signInResponse = await signInWithNumber(number.replace(/\s/g, ''))
      toast({
        variant: 'success',
        title: 'Otp successfully sent'
      })
      setPhoneLoginResponse({ status: true, confirm: signInResponse })
    } catch (err: unknown) {
      if (err instanceof FirebaseError) {
        setError(mapAuthErrorToMessage(err.code))
      } else {
        const errorMsg = err as Error
        setError(mapAuthErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  const logOut = (): void => {
    setIsLoading(true)
    setPhoneLoginResponse({
      status: false,
      confirm: undefined
    })
    setOtpVerificationStatus(false)
    const navigateTo = role === 1000 ? '/login/phone' : '/login/email'

    signOut(auth)
      .then(() => {
        setUser(null)
        navigate(navigateTo)
      })
      .catch((err) => {
        const result = err as Error
        setError(result.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const signOutUser = (): void => {
    setIsLoading(true)
    setUser(null)
    setPhoneLoginResponse({
      status: false,
      confirm: undefined
    })
    setOtpVerificationStatus(false)

    signOut(auth)
      .then(() => {
        setUser(null)
      })
      .catch((err) => {
        const result = err as Error
        setError(result.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    isLoading,
    error,
    role,
    isFirstLogin,
    userId,
    phoneLoginResponse,
    otpVerificationStatus,
    logOut,
    signOutUser,
    verifyOtp,
    resendOtp,
    phoneSignIn,
    phoneSignUp,
    emailSignIn
  }
}
