import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createHeader,
  type CreateHeaderDTO
} from 'features/farmer/cropping-plan/api/header/createHeader'
import {
  updateHeader,
  type UpdateHeaderDTO
} from 'features/farmer/cropping-plan/api/header/updateHeader'
import {
  updateHeaderStatus,
  type UpdateHeaderStatusDTO
} from 'features/farmer/cropping-plan/api/header/updateHeaderStatus'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { CropsList } from 'features/farmer/cropping-plan/utils/crops'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, CroppingPlanHeaderStatus, type Farm, type Lease } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'
import { type HeaderInput } from '../components/steps/header/CroppingPlanHeader'

interface UseCroppingPlanHeader {
  header: CroppingPlan | null
  setHeader: (croppingPlan: CroppingPlan | null) => void
  isLoading: boolean
  createCroppingPlanHeader: (data: HeaderInput) => void
  updateCroppingPlanHeader: (data: HeaderInput) => void
  updateCroppingPlanHeaderStatus: () => void
}

export const useCroppingPlanHeader = (
  farm: Farm | undefined,
  next: () => void,
  lease?: Lease
): UseCroppingPlanHeader => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { header, ssrId, setHeader } = useCroppingPlanStore()

  const createCroppingPlanHeaderMutation = useMutation({
    mutationFn: async (cpDto: CreateHeaderDTO) => {
      return createHeader(cpDto)
    }
  })

  const updateCroppingPlanHeaderMutation = useMutation({
    mutationFn: async (cpDto: UpdateHeaderDTO) => {
      return updateHeader(cpDto)
    }
  })

  const updateCroppingPlanHeaderStatusMutation = useMutation({
    mutationFn: async (cpDto: UpdateHeaderStatusDTO) => {
      return updateHeaderStatus(cpDto)
    }
  })

  const createCroppingPlanHeader = (data: HeaderInput): void => {
    createCroppingPlanHeaderMutation.mutate(
      {
        hectarage: data.hectarage,
        farm_id: farm !== undefined ? farm.farm_id : lease?.farm_id,
        crop_id: data.crop as unknown as number,
        farmer_id: farm !== undefined ? farm.farmer_id : lease?.dest_farmer_id,
        plough_season: '2022/23',
        ssr_id: ssrId?.toString() ?? '1144',
        user_id: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setHeader({
              cropping_plan_id: result.data[0].cropping_plan_id,
              farmer_id: farm !== undefined ? farm.farmer_id : lease?.dest_farmer_id ?? 0,
              hectarage: data.hectarage,
              farm_id: farm !== undefined ? farm.farm_id : lease?.farm_id ?? 0,
              is_verified: CroppingPlanHeaderStatus.Draft,
              plough_season: '2022/23',
              crop_id: data.crop as unknown as number,
              crop_name: CropsList.filter(
                (crop) => crop.crop_id === (data.crop as unknown as number)
              )[0].crop_name,
              family_name: CropsList.filter(
                (crop) => crop.crop_id === (data.crop as unknown as number)
              )[0].family_name,
              ssr_id: 'SSR_ID FORMAT TBA',
              total_cost: '0',
              comments: null
            })
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['cropping-plans'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }
  const updateCroppingPlanHeader = (data: HeaderInput): void => {
    updateCroppingPlanHeaderMutation.mutate(
      {
        hectarage: data.hectarage,
        crop_id: data.crop,
        farmer_id: farm !== undefined ? farm.farmer_id : lease?.dest_farmer_id,
        ssr_id: ssrId?.toString() ?? '',
        user_id: farmer?.user_id,
        cp_id: header?.cropping_plan_id,
        farm_id: farm?.farm_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setHeader({
              ...header!,
              hectarage: data.hectarage,
              crop_id: data.crop
            })
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['cropping-plans'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateCroppingPlanHeaderStatus = (): void => {
    updateCroppingPlanHeaderStatusMutation.mutate(
      {
        farmer_id: farm !== undefined ? farm.farmer_id : lease?.dest_farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        status: 'Submitted CP Verification'
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({ queryKey: ['cropping-plans'] })
            next()
            toast({
              variant: 'success',
              title: 'Successfully submitted for review.'
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    header,
    setHeader,
    isLoading:
      createCroppingPlanHeaderMutation.isLoading ||
      updateCroppingPlanHeaderMutation.isLoading ||
      updateCroppingPlanHeaderStatusMutation.isLoading,
    createCroppingPlanHeader,
    updateCroppingPlanHeader,
    updateCroppingPlanHeaderStatus
  }
}
