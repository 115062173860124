import { SeedsList } from './seeds'
import { CropsList } from './crops'
import { type SeedsDataRow, type SeedsTable } from '../types'
import { type GridRowId } from '@mui/x-data-grid'

export const getSeeds = (type: string, id: number, seedId: GridRowId[]): SeedsTable[] => {
  if (seedId.length >= 1) {
    const selectedId = SeedsList.find((item) => item.seed_id === seedId[0])

    if (selectedId === undefined) {
      return SeedsList.filter((seed) => {
        return seed.crop_id === id && seed.seed_type === type
      }).map((res) => {
        return {
          id: res.seed_id,
          crop: CropsList.find((crop) => crop.crop_id === res.crop_id)?.crop_name,
          tradeName: res.trade_name,
          variety: res.variety,
          sowingRate: res.sowing_rate,
          yield: res.yield,
          days: res.days_to_maturity,
          price: res.price_per_kg
        }
      }) as SeedsTable[]
    } else {
      const seedsList = new Array({
        id: selectedId.seed_id,
        crop: CropsList.find((crop) => crop.crop_id === selectedId.crop_id)!.crop_name,
        tradeName: selectedId.trade_name,
        variety: selectedId.variety,
        sowingRate: selectedId.sowing_rate,
        yield: selectedId.yield,
        days: selectedId.days_to_maturity,
        price: selectedId.price_per_kg
      } satisfies SeedsTable)
      const seeds = SeedsList.filter((seed) => {
        return seed.crop_id === id && seed.seed_type === type && seed.seed_id !== selectedId.seed_id
      }).map((res) => {
        return {
          id: res.seed_id,
          crop: CropsList.find((crop) => crop.crop_id === res.crop_id)?.crop_name,
          tradeName: res.trade_name,
          variety: res.variety,
          sowingRate: res.sowing_rate,
          yield: res.yield,
          days: res.days_to_maturity,
          price: res.price_per_kg
        }
      }) as SeedsTable[]

      seedsList.push(...seeds)

      return seedsList
    }
  } else {
    return SeedsList.filter((seed) => {
      return seed.crop_id === id && seed.seed_type === type
    }).map((res) => {
      return {
        id: res.seed_id,
        crop: CropsList.find((crop) => crop.crop_id === res.crop_id)?.crop_name,
        tradeName: res.trade_name,
        variety: res.variety,
        sowingRate: res.sowing_rate,
        yield: res.yield,
        days: res.days_to_maturity,
        price: res.price_per_kg
      }
    }) as SeedsTable[]
  }
}

export const getSeedId = (type: string | undefined, variant: string | undefined): number[] => {
  if (type === undefined || variant === undefined) {
    return []
  }

  return SeedsList.filter((seed) => {
    return seed.seed_type === type && seed.variety === variant
  }).map((res) => res.seed_id)
}

export const getSeedIdMoreOptions = (
  type: string | undefined,
  variant: string | undefined,
  yieldR: string | undefined,
  sowingRate: string | undefined
): number[] => {
  if (
    type === undefined ||
    variant === undefined ||
    yieldR === undefined ||
    sowingRate === undefined
  ) {
    return []
  }

  return SeedsList.filter((seed) => {
    return (
      seed.seed_type === type &&
      seed.variety === variant &&
      seed.yield === yieldR &&
      seed.sowing_rate === sowingRate
    )
  }).map((res) => res.seed_id)
}

export const getSeedsTableData = (): SeedsDataRow[] => {
  return SeedsList.map((res) => {
    return {
      id: res.seed_id,
      crop: CropsList.find((crop) => crop.crop_id === res.crop_id)?.crop_name,
      tradeName: res.trade_name,
      type: res.seed_type,
      variety: res.variety,
      sowingRate: res.sowing_rate,
      yield: res.yield,
      days: res.days_to_maturity,
      price: res.price_per_kg
    }
  }) as SeedsDataRow[]
}
