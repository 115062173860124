import { PhoneInputField } from 'components/Form/PhoneInput'
import { PhoneNumberUtil } from 'google-libphonenumber'
import type React from 'react'
import { type Dispatch, type FC, type SetStateAction } from 'react'
import { LoginSeparator } from '../layout/Separator'
import { Text, Button, Phone } from 'components/ui'

interface PhoneLoginFormProps {
  className?: string
  number: string
  onChange: Dispatch<SetStateAction<string>>
  onEmail: () => void
  onRegister: () => void
  onLogin: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  loading: boolean
}

const phoneUtil = PhoneNumberUtil.getInstance()

const usePhoneValidation = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const PhoneLoginForm: FC<PhoneLoginFormProps> = ({
  className,
  onEmail,
  onRegister,
  onChange,
  onLogin,
  loading,
  number
}: PhoneLoginFormProps) => {
  const isPhoneValid = usePhoneValidation(number)

  return (
    <form className={className}>
      <div className={'flex flex-col gap-4'}>
        <Phone
          value={number}
          onChange={(num) => {
            onChange(num)
          }}
        />
        <Button
          loading={loading}
          type={'submit'}
          disabled={!isPhoneValid || loading}
          onClick={onLogin}
        >
          login
        </Button>
      </div>

      <LoginSeparator label={'OR'} />
      <div className={'flex flex-col gap-4'}>
        <Button
          variant={'secondary'}
          disabled={loading}
          onClick={() => {
            onEmail()
          }}
        >
          Sign in with Email
        </Button>
        <div className={'flex items-center sm:justify-center gap-2'} onClick={onRegister}>
          <Text>Don't Have an Account?</Text>
          <Text variant={'primary'}>
            <a className='hover:text-primary-hover cursor-pointer font-bold' onClick={onRegister}>
              Sign Up{' '}
            </a>
          </Text>
        </div>
      </div>
    </form>
  )
}
