import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import { Text } from '../../../../components/Elements/Text/Text'

interface CroppingPlanStatusesProps {
  croppingPlan: CroppingPlan
  className?: string
}

export const CroppingPlanStatuses: React.FC<CroppingPlanStatusesProps> = ({
  croppingPlan,
  className
}) => {
  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_CP_Verification) {
    return (
      <div className={className}>
        <Text size={'medium'} variant={'error'} className={'flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-xmark' /> Rejected
        </Text>
      </div>
    )
  }
  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_Farm_Assessment_Verification) {
    return (
      <div className={className}>
        <Text size={'medium'} variant={'error'} className={'flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-xmark' /> Farm Assessment Rejected
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment) {
    return (
      <div className={className}>
        <Text size={'medium'} variant={'warning'} className={'flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> Awaiting Farm Assessment
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_Farm_Assessment) {
    return (
      <div className={className}>
        <Text size={'medium'} variant={'warning'} className={'flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> Returned Additional Farm
          Information Required
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_CP_Verification) {
    return (
      <div className={className}>
        <Text size={'medium'} variant={'primaryLight'} className={'flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> Returned Additional
          Information Required
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Approved) {
    return (
      <div className={className}>
        <Text size={'medium'} variant={'success'} className={'flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-check' /> Approved
        </Text>
      </div>
    )
  }

  return (
    <div className={className}>
      <Text size={'medium'} variant={'primaryLight'} className={'flex gap-2 items-center'}>
        <p className='material-symbols-outlined'>pending_actions</p> Submitted for Review
      </Text>
    </div>
  )
}
