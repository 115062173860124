import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type Lease } from 'types'

export const getLeasedFarmers = async (
  extAreaId: number | null | undefined
): Promise<ApiResponse<Lease>> => {
  return axios.get(`/lease?EXT_AREA_ID=${extAreaId ?? ''}`)
}

export const useGetLeasedFarmers = (
  extAreaId: number | null | undefined
): UseQueryResult<ApiResponse<Lease>, AxiosError<[]>> => {
  return useQuery({
    queryKey: ['my-leased-farmers', extAreaId],
    queryFn: async () => getLeasedFarmers(extAreaId)
  })
}
