import { RequiresAuth } from 'features/authentication/components/RequiresAuth'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ROLES } from 'types'
import { ErrorPage } from '../Errors/ErrorPage'

const ServiceProviderDashboard = React.lazy(async () => import('../Dashboard/ServiceProvider'))

export const Services: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <RequiresAuth allowedRoles={[ROLES.DRAUGHPOWER]}>
        <ServiceProviderDashboard />
      </RequiresAuth>
    </ErrorBoundary>
  )
}
