import { InputField, PhoneInputField } from 'components/Form'
import { RowContainer } from 'features/loan-management/components/stepper'
import type React from 'react'
import { useState } from 'react'
import { object, string, type TypeOf } from 'zod'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { BasicButton } from 'components/Elements/Button'
import { useAtom } from 'jotai'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { individualLoanItem } from '../stores/individualLoanItem'
import { Text } from 'components/Elements/Text/Text'
import { type LoanStepperProps } from '../../../types'

const schema = object({
  kin_first_name: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  kin_last_name: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  kin_postal_address: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  kin_physical_address: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  kin_email: string().email('Invalid Email')
})

type NextOfKinDetailsInput = TypeOf<typeof schema>

const phoneUtil = PhoneNumberUtil.getInstance()

const usePhoneValidation = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const NextOfKin: React.FC<LoanStepperProps> = ({ onBack, onNext, steps, activeStep }) => {
  const { farmer } = useFarmerStore()
  const [loanData, setLoanData] = useAtom(individualLoanItem)

  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState<string>(
    loanData?.particulars_of_next_of_kin?.kin_phone !== undefined
      ? loanData?.particulars_of_next_of_kin?.kin_phone
      : ''
  )
  const [number, setNumber] = useState<string>(defaultPhoneNumber)
  const isPhoneValid = usePhoneValidation(number)
  const onSubmit: SubmitHandler<NextOfKinDetailsInput> = (data: NextOfKinDetailsInput) => {
    setLoanData((prev) => ({
      ...prev!,
      particulars_of_next_of_kin: {
        kin_first_name: data.kin_first_name,
        kin_mid_name: '',
        kin_last_name: data.kin_last_name,
        kin_physical_address: data.kin_physical_address,
        kin_postal_address: data.kin_postal_address,
        kin_email: data.kin_email,
        kin_phone: number,
        last_update_by: farmer?.user_id as number
      }
    }))
    onNext()
  }

  return (
    <FormWithStepper<NextOfKinDetailsInput, typeof schema>
      className={'flex flex-col flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ register, formState: { errors }, handleSubmit }) => (
        <div className={'w-full flex flex-col gap-4'}>
          <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
            Next Of Kin
          </Text>
          <div className={'w-full '}>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px] capitalize'
                label={'First Names'}
                defaultValue={loanData?.particulars_of_next_of_kin?.kin_first_name}
                error={!(errors.kin_first_name == null)}
                helperText={errors?.kin_first_name?.message ?? ''}
                register={register('kin_first_name')}
              />
              <InputField
                className='flex-[1_0_230px]  capitalize'
                label={'Last Name'}
                defaultValue={loanData?.particulars_of_next_of_kin?.kin_last_name}
                error={!(errors.kin_last_name == null)}
                helperText={errors?.kin_last_name?.message ?? ''}
                register={register('kin_last_name')}
              />
            </RowContainer>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px] capitalize'
                label={'postal address'}
                defaultValue={loanData?.particulars_of_next_of_kin?.kin_postal_address}
                error={!(errors.kin_postal_address == null)}
                helperText={errors?.kin_postal_address?.message ?? ''}
                register={register('kin_postal_address')}
              />
              <InputField
                className='flex-[1_0_230px] capitalize'
                label={'physical address'}
                defaultValue={loanData?.particulars_of_next_of_kin?.kin_physical_address}
                error={!(errors.kin_physical_address == null)}
                helperText={errors?.kin_physical_address?.message ?? ''}
                register={register('kin_physical_address')}
              />
            </RowContainer>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px]  capitalize'
                label={'Email'}
                type={'email'}
                defaultValue={loanData?.particulars_of_next_of_kin?.kin_email}
                error={!(errors.kin_email == null)}
                helperText={errors?.kin_email?.message ?? ''}
                register={register('kin_email')}
              />
              <PhoneInputField
                onChange={(num) => {
                  setNumber(num)
                }}
                value={number}
                className={'flex-[1_0_230px] capitalize'}
              />
            </RowContainer>
            <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
              <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
              <BasicButton
                label={'Next'}
                variant={'primary'}
                isDisabled={!isPhoneValid}
                onClick={handleSubmit(onSubmit)}
                size={'xs'}
              />
            </div>
          </div>
        </div>
      )}
    </FormWithStepper>
  )
}
