import type React from 'react'
import { Button, Text, useToast } from 'components/ui'
import { XCircle } from 'lucide-react'

interface RejectedFarmProps {
  reference: string
  onComplete: () => void
}

export const RejectedFarm: React.FC<RejectedFarmProps> = ({ onComplete, reference }) => {
  const { toast } = useToast()

  return (
    <div className={'flex flex-col justify-center items-center gap-8'}>
      <div className={'w-full flex flex-col items-center gap-4'}>
        <Text size={'medium'} variant={'error'} className={'flex gap-2 items-center'}>
          <XCircle />
          Farm Rejected
        </Text>
      </div>
      <div className={'text-center w-4/5 rounded p-2'}>
        <Text>
          Store any physical documents with following reference number:{' '}
          <b className={'text-destructive'}>{reference}</b>
        </Text>
      </div>
      <Button
        variant={'destructive'}
        onClick={() => {
          onComplete()
          toast({
            variant: 'success',
            description: 'Farm rejected!'
          })
        }}
        size={'lg'}
        color={'destructive'}
      >
        Complete
      </Button>
    </div>
  )
}
