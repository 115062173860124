import type React from 'react'
import { BasicButton } from '../Elements/Button'

export const ErrorPage: React.FC = () => {
  return (
    <main className={'flex flex-col gap-4 h-screen justify-center items-center'} role='alert'>
      <h1 className='text-lg font-semibold'>Oops, something went wrong :( </h1>
      <p className='text-md'>An unexpected error has occurred.</p>
      <BasicButton
        onClick={() => {
          window.location.reload()
        }}
        label={'Refresh'}
        variant={'secondary'}
        size={'xs'}
      />
    </main>
  )
}
