import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import {
  createSoilSampleAnalysis,
  type CreateSoilSampleAnalysisDTO
} from 'features/lab/ssr/api/createSoilSampleAnalysis'
import {
  updateSoilSampleAnalysis,
  type UpdateSoilSampleAnalysisDTO
} from 'features/lab/ssr/api/updateSoilSampleAnalysis'
import { useState } from 'react'
import { type ApiResponse } from 'types'
import { useToast } from '../../../../components/ui'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'

interface UseSoilSampleAnalysis {
  isLoading: boolean
  createSSA: (params: CreateSoilSampleAnalysisDTO) => void
  updateSSA: (params: UpdateSoilSampleAnalysisDTO) => void
  isSSACreated: boolean
}

export const useSoilSampleAnalysis = (): UseSoilSampleAnalysis => {
  const { toast } = useToast()
  const [isSSACreated, setIsSSACreated] = useState(false)

  const createSoilSampleAnalysisMutation = useMutation({
    mutationFn: async (params: CreateSoilSampleAnalysisDTO) => {
      return createSoilSampleAnalysis(params)
    }
  })

  const updateSoilSampleAnalysisMutation = useMutation({
    mutationFn: async (params: CreateSoilSampleAnalysisDTO) => {
      return updateSoilSampleAnalysis(params)
    }
  })

  const createSSA = (params: CreateSoilSampleAnalysisDTO): void => {
    setIsSSACreated(false)
    createSoilSampleAnalysisMutation.mutate(
      {
        ssr_id: params.ssr_id,
        ec_comp: params.ec_comp,
        fe_comp: params.fe_comp,
        mn_comp: params.mn_comp,
        cu_comp: params.cu_comp,
        ph_comp: params.ph_comp,
        ca_comp: params.ca_comp,
        zn_comp: params.zn_comp,
        s_comp: params.s_comp,
        mg_comp: params.mg_comp,
        na_comp: params.na_comp,
        k_comp: params.k_comp,
        p_comp: params.p_comp,
        c_comp: params.c_comp,
        cec_comp: params.cec_comp,
        bulk_density: params.bulk_density,
        user_id: params.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setIsSSACreated(true)
            toast({
              variant: 'success',
              title: 'Soil Sample Analysis Successfully Created.'
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const updateSSA = (params: UpdateSoilSampleAnalysisDTO): void => {
    setIsSSACreated(false)
    updateSoilSampleAnalysisMutation.mutate(
      {
        ssr_id: params.ssr_id,
        ec_comp: params.ec_comp,
        fe_comp: params.fe_comp,
        mn_comp: params.mn_comp,
        cu_comp: params.cu_comp,
        ph_comp: params.ph_comp,
        ca_comp: params.ca_comp,
        zn_comp: params.zn_comp,
        s_comp: params.s_comp,
        mg_comp: params.mg_comp,
        na_comp: params.na_comp,
        k_comp: params.k_comp,
        p_comp: params.p_comp,
        c_comp: params.c_comp,
        cec_comp: params.cec_comp,
        bulk_density: params.bulk_density,
        user_id: params.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setIsSSACreated(true)
            toast({
              variant: 'success',
              title: 'Soil Sample Analysis Successfully Updated.'
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    isLoading:
      createSoilSampleAnalysisMutation.isLoading || updateSoilSampleAnalysisMutation.isLoading,
    isSSACreated,
    createSSA,
    updateSSA
  }
}
