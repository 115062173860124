import type React from 'react'
import { type Lease } from 'types'
import { Button, Text } from 'components/ui'
import { useTerminateLease } from 'features/farmer/hooks/useTerminateLease'

interface DeleteLeaseConfirmationProps {
  lease: Lease
  isLessor?: boolean
  next: () => void
}

export const AcceptTermination: React.FC<DeleteLeaseConfirmationProps> = ({
  lease,
  next,
  isLessor = false
}) => {
  const { lesseeAcceptTermination, lessorAcceptTermination, isLoading } = useTerminateLease(next)

  const onAcceptTermination = (): void => {
    if (isLessor) {
      lessorAcceptTermination(lease)
    } else {
      lesseeAcceptTermination(lease)
    }
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        Do You Accept Lease Termination?
      </Text>

      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        Are you sure you want to end your lease with{' '}
        <strong>{isLessor ? lease.dest_farmer_name : lease.origin_farmer_name}</strong>? This means
        that the lease for this farm will be terminated immediately.
      </Text>
      <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
        NOTE: This is an irreversible action.
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} disabled={isLoading} className={'w-1/2'} onClick={next}>
          Cancel
        </Button>
        <Button
          className={'w-1/2'}
          variant={'destructive'}
          disabled={isLoading}
          loading={isLoading}
          onClick={onAcceptTermination}
        >
          Terminate
        </Button>
      </div>
    </div>
  )
}
