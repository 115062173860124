import { axios } from 'lib/axios'
import { type ApiResponseWithObject } from 'types'
import { type NewGrantResponse, type SelectedCroppingPlans } from '../types'

export interface CreateGrantDTO {
  farmer_id: number | null | undefined
  cropping_plan: SelectedCroppingPlans[]
  farm_id: number | null | undefined
  lease_id: number | null | undefined
  user_id: number | null | undefined
  last_update_by: number | null | undefined
}

export const createNewGrant = async (
  payload: CreateGrantDTO
): Promise<ApiResponseWithObject<NewGrantResponse>> => {
  return axios.post('/grant', payload)
}
