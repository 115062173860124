import { axios } from 'lib/axios'
import { type ApiResponseWithObject } from 'types'
import { type SuccessfulVoucherRedemption } from 'features/merchant/types'
import { type SelectedServices } from '../types'

export interface ServicesVoucherDTO {
  merchant_id: number | null | undefined
  omang: string | null | undefined
  services: SelectedServices[]
}

export const redeemServicesVoucher = async (
  payload: ServicesVoucherDTO
): Promise<ApiResponseWithObject<SuccessfulVoucherRedemption>> => {
  return axios.post('/voucher/redemption/services', payload)
}
