import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export interface GetSeederChecklist {
  non_powered_implement_id: number
  inventory_id: number
  inventory_type: number
  farrow_opener: number
  seed_metering: number
  tine: number
  covering_wheels: number
  fertilizer_tubes: number
  seed_hopers: number
  fertilizer_hopers: number
  seed_hoses: number
  greasing: number
  hydraulics: number
  gears: number
  pressure_gauges: number
  hitch_pins: number
  bearings: number
  result: boolean
  comments: string
  create_date: string
  last_update_by: string
  last_update_date: string
}

export const getSeederChecklist = async (
  inventoryId: number
): Promise<ApiResponse<GetSeederChecklist>> => {
  return axios.get(`/assessment/seeder?inventory_id=${inventoryId}`)
}

export const useGetSeederChecklist = (
  inventoryId: number
): UseQueryResult<ApiResponse<GetSeederChecklist>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['seederChecklist'],
    queryFn: async () => getSeederChecklist(inventoryId)
  })
}
