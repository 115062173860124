import { CustomStepper } from 'components/Elements/Stepper/Stepper'
import type React from 'react'
import { useState } from 'react'
import { LandReview } from '../LandReview'
import { FarmDetails } from './FarmDetails'
import { FarmLocation } from './FarmLocation'

interface RegWithoutDeedProps {
  onClose: () => void
}

const steps = ['Farm Details', 'Farm Location', 'Review']

export const RegWithoutDeed: React.FC<RegWithoutDeedProps> = ({ onClose }) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }

  if (activeStep === 1) {
    return (
      <div className={'flex flex-col items-center gap-8 p-4'}>
        <CustomStepper steps={steps} activeStep={activeStep} className={'w-full'} />
        <FarmLocation onNext={handleNext} onBack={handleBack} />
      </div>
    )
  }

  if (activeStep === steps.length - 1) {
    return (
      <div className={'flex flex-col items-center gap-8 p-4'}>
        <CustomStepper className={'w-full'} steps={steps} activeStep={activeStep} />
        <LandReview onBack={handleBack} onNext={onClose} />
      </div>
    )
  }

  return (
    <div className={'flex flex-col items-center gap-8 p-4'}>
      <CustomStepper className={'w-full'} steps={steps} activeStep={activeStep} />
      <FarmDetails next={handleNext} cancel={onClose} />
    </div>
  )
}
