import type React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type Farm } from 'types'
import { VerifiedStatus } from './VerifiedStatus'
import { DeleteFarm } from '../delete-farm'
import { EditFarm } from '../edit-farm'
import {
  Text,
  Card,
  Button,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenu,
  Badge,
  Dialog,
  DialogContent
} from 'components/ui'
import { ArrowUpRightFromSquare, EditIcon, MoreVertical, Trash2 } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip'
interface FarmCardProps {
  farm: Farm
}

export const FarmCard: React.FC<FarmCardProps> = ({ farm }) => {
  const navigate = useNavigate()
  const [isDeleteFarm, setIsDeleteFarm] = useState(false)
  const [isEditFarm, setIsEditFarm] = useState(false)

  const handleCloseDeleteFarm = (): void => {
    setIsDeleteFarm(false)
  }
  const handleCloseEditFarm = (): void => {
    setIsEditFarm(false)
  }

  const handleOpenDeleteFarm = (): void => {
    setIsDeleteFarm(true)
  }
  const handleOpenEditFarm = (): void => {
    setIsEditFarm(true)
  }

  const handleClick = (): void => {
    if (farm.status === 'verified') {
      navigate(`/farmer/farms/${farm.farm_id}`)
    }
  }

  const hoverStyles =
    farm.status === 'verified'
      ? 'transition-shadow duration-200 ease-in-out transform hover:shadow-md dark:hover:shadow-sm dark:hover:bg-web-card-light hover:shadow-input hover:border-second'
      : ''
  const cursorStyle =
    farm.status === 'verified' ? 'cursor-pointer' : 'shadow-none border-muted cursor-default'

  if (farm.status === 'rejected') {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Card
              onClick={handleClick}
              className={`md:max-w-[500px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 transition-shadow duration-200 ease-in-out transform hover:shadow-md hover:shadow-input hover:border-second dark:hover:border-second-foreground ${cursorStyle}`}
            >
              <div className={'flex items-center justify-between'}>
                <div className={'flex gap-4 items-center justify-between'}>
                  <div className={'flex gap-1 items-baseline'}>
                    <Text size={'large'}>{farm.size.toFixed(4)}</Text>
                    <Text>ha</Text>
                  </div>
                </div>
              </div>

              <div className={'items-start w-full flex flex-col'}>
                <Text size={'medium'} className={'capitalize'}>
                  {farm.farm_name.toLowerCase()}
                </Text>
                <Text
                  className={'capitalize'}
                  variant={'bodyTextLight'}
                >{`${farm.village_name.toLowerCase()}, ${farm.extension_area_name.toLowerCase()}`}</Text>
              </div>
              <VerifiedStatus status={farm.status} />

              <Dialog
                key={'edit'}
                open={isEditFarm}
                defaultOpen={false}
                modal
                onOpenChange={handleCloseEditFarm}
              >
                <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
                  <EditFarm farm={farm} next={handleCloseEditFarm} />
                </DialogContent>
              </Dialog>

              <Dialog
                key={'delete'}
                open={isDeleteFarm}
                defaultOpen={false}
                modal
                onOpenChange={handleCloseDeleteFarm}
              >
                <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
                  <DeleteFarm farm={farm} next={handleCloseDeleteFarm} />
                </DialogContent>
              </Dialog>
            </Card>
          </TooltipTrigger>
          <TooltipContent>
            <p>rejected reason</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  return (
    <Card
      onClick={handleClick}
      className={`bg-web-card md:max-w-[600px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 hover:${hoverStyles} ${cursorStyle}`}
    >
      <div className={'flex items-center justify-between'}>
        <div className={'flex gap-4 items-center justify-between'}>
          <div className={'flex gap-1 items-baseline'}>
            <Text size={'large'}>{farm.size.toFixed(4)}</Text>
            <Text>ha</Text>
          </div>
          <Badge
            className={farm.status === 'verified' ? 'pointer' : 'default'}
          >{`${farm.leased_size} Ha leased`}</Badge>
        </div>
        {farm.status === 'pending' && (
          <div className={'flex relative'}>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size={'icon'} variant='icon' className='h-8 w-8 p-0'>
                  <span className='sr-only'>Open menu</span>
                  <MoreVertical className='h-6 w-6' />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className={'bg-popover-dark w-[10rem] p-2'}>
                <DropdownMenuItem onClick={handleOpenEditFarm} className={'px-2'}>
                  <Text>
                    <EditIcon className={'h-[1rem]'} />
                  </Text>
                  <Text>Edit Farm</Text>
                </DropdownMenuItem>
                <DropdownMenuItem className={'px-2'} onClick={handleOpenDeleteFarm}>
                  <Text>
                    <Trash2 className={'h-[1rem] text-destructive'} />
                  </Text>
                  <Text>Delete Farm</Text>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
        {farm.status === 'verified' && <ArrowUpRightFromSquare />}
      </div>

      <div className={'w-full flex flex-col'}>
        <Text size={'medium'} className={'capitalize'}>
          {farm.farm_name.toLowerCase()}
        </Text>
        <Text
          className={'capitalize'}
          variant={'bodyTextLight'}
        >{`${farm.village_name.toLowerCase()}, ${farm.extension_area_name.toLowerCase()}`}</Text>
      </div>
      <VerifiedStatus status={farm.status} />

      <Dialog
        key={'edit'}
        open={isEditFarm}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseEditFarm}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <EditFarm farm={farm} next={handleCloseEditFarm} />
        </DialogContent>
      </Dialog>

      <Dialog
        key={'delete'}
        open={isDeleteFarm}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDeleteFarm}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <DeleteFarm farm={farm} next={handleCloseDeleteFarm} />
        </DialogContent>
      </Dialog>
    </Card>
  )
}
