import type React from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type Farm, type Lease } from 'types'
import { useGetHeader } from '../api/header/getHeader'
import { Button, Loader, Text } from 'components/ui'
import { CircleOff } from 'lucide-react'
import { CroppingPlansTable } from './tables/CroppingPlansTable'
import { croppingPlansColumns } from './tables/cropping-plans-columns'
import { AxiosError } from 'axios'
import { Error } from 'components/Errors/Error'

interface Base {
  openCroppingPlan: () => void
  openApprovedCroppingPlan: () => void
  newCroppingPlan: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CroppingPlansProps = WithFarm | WithLease

export const CroppingPlans: React.FC<CroppingPlansProps> = ({
  farm,
  lease,
  openCroppingPlan,
  openApprovedCroppingPlan,
  newCroppingPlan
}) => {
  const { farmer } = useFarmerStore()
  const {
    data: croppingPlans,
    isLoading,
    error
  } = useGetHeader(farmer?.farmer_id, farm?.farm_id !== undefined ? farm.farm_id : lease?.farm_id)

  if (isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error instanceof AxiosError) {
      if (error?.response?.status !== 404) {
        return <Error />
      }
    }
  }

  if (croppingPlans != null) {
    return (
      <>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>Cropping Plans</Text>
          <Button variant={'success'} size={'xs'} onClick={newCroppingPlan}>
            New Cropping Plan
            <p className='fa-solid fa-right-left' />
          </Button>
        </div>
        <CroppingPlansTable
          data={croppingPlans.data}
          columns={croppingPlansColumns}
          onApproved={openApprovedCroppingPlan}
          onDraft={openCroppingPlan}
        />
      </>
    )
  }

  // no cropping plans available
  return (
    <>
      <div className={'flex justify-between items-center'}>
        <Text size={'medium'}>Cropping Plans</Text>
        <Button variant={'success'} size={'xs'} onClick={newCroppingPlan}>
          New Cropping Plan
          <p className='fa-solid fa-right-left' />
        </Button>
      </div>
      <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
        <Text size={'xLarge'} className={'opacity-40'}>
          <CircleOff />
        </Text>
        <Text size={'medium'} variant={'bodyTextLight'}>
          No Cropping Plans Available
        </Text>
      </div>
    </>
  )
}
