import { type Farmer } from 'types'
import { create } from 'zustand'

interface FarmerStore {
  farmer: Farmer | null
  setFarmer: (farmer: Farmer | null) => void
}

export const useFarmerStore = create<FarmerStore>()((set) => ({
  farmer: null,
  setFarmer: (farmer: Farmer | null) => {
    set((state) => ({ ...state, farmer }))
  }
}))
