import { useGetFarmer } from 'features/authentication/api/getFarmer'
import { useAuth } from 'features/authentication/hooks/useAuth'
import { useEffect, useState } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useToast } from 'components/ui/use-toast'

interface UsePhoneLogin {
  isUser: boolean
  error: string | null
  loading: boolean
}

export const usePhoneLogin = (role: number | null, userId: number | null): UsePhoneLogin => {
  const { toast } = useToast()
  const { setFarmer } = useFarmerStore()
  const { signOutUser } = useAuth()
  const [error, setError] = useState<string | null>(null)
  const [isUser, setIsUser] = useState(false)

  const {
    data: farmer,
    isInitialLoading: loadingFarmer,
    error: farmerError
  } = useGetFarmer(userId, role)

  useEffect(() => {
    if (loadingFarmer) {
      return
    }

    if (role === 1000 && farmer?.status === 200) {
      setFarmer(farmer?.data[0])
      setIsUser(true)
    } else if ((role === 1000 && farmer?.status !== 200) || farmerError !== null) {
      signOutUser()
      setIsUser(false)
      setError('user does not exist.')
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'user does not exist.'
      })
    }
  }, [role, userId, farmer, loadingFarmer])

  return { error, loading: loadingFarmer, isUser }
}
