import type React from 'react'
import { FormProvider, useFieldArray, useFormContext, type UseFormReturn } from 'react-hook-form'
import { useAtom } from 'jotai/index'
import { companyLoanStore } from '../stores'
import { InputField, PhoneInputField } from 'components/Form'
import {
  CategoryLabel,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper'
import { BasicButton } from '../../../../../components/Elements/Button'
import { array, object, string, type TypeOf } from 'zod'
import { Text } from '../../../../../components/Elements/Text'

interface AccountantsProps {
  methods: UseFormReturn<AccountantsInput>
  telephones: string[]
  faxes: string[]
  cells: string[]
  setTelephones: React.Dispatch<React.SetStateAction<string[]>>
  setFaxes: React.Dispatch<React.SetStateAction<string[]>>
  setCells: React.Dispatch<React.SetStateAction<string[]>>
}

export const auditData = object({
  bica_registration: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  postal_address: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  physical_address: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  cell: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  telephone: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  fax: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  })
})

export const schemaAcc = object({
  accountants: array(auditData).nonempty('At least one accountant is required')
})

export type AccountantsInput = TypeOf<typeof schemaAcc>

export const Accountants: React.FC<AccountantsProps> = ({
  methods,
  setTelephones,
  setFaxes,
  setCells
}) => {
  const [loanData] = useAtom(companyLoanStore)
  const { control } = useFormContext<AccountantsInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'accountants'
  })

  const addAccountant = (): void => {
    append({
      bica_registration: '',
      physical_address: '',
      postal_address: '',
      cell: '',
      telephone: '',
      fax: ''
    })
  }

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <div className='w-full flex flex-col gap-8'>
          <div className={'flex items-center justify-between'}>
            <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
              Accountants
            </Text>

            <BasicButton
              label={'Add new'}
              variant={'primary'}
              size={'xs'}
              onClick={addAccountant}
            />
          </div>

          {fields.map((field, index) => (
            <div key={index} id={`accountant_${index + 1}`} className='w-full mb-8'>
              <CategoryLabel label={`Accountant ${index + 1}`} />
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px]  capitalize'
                  label='BICA Registration NO'
                  type='number'
                  defaultValue={loanData?.loan_accountants?.[index]?.bica_registration}
                  register={methods.register(`accountants.${index}.bica_registration`)}
                  error={
                    !(methods.formState?.errors?.accountants?.[index]?.bica_registration == null)
                  }
                  helperText={
                    methods.formState.errors?.accountants?.[index]?.bica_registration?.message ?? ''
                  }
                />
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label='physical address'
                  defaultValue={loanData?.loan_accountants?.[index]?.physical_address}
                  register={methods.register(`accountants.${index}.physical_address`)}
                  error={
                    !(methods.formState?.errors?.accountants?.[index]?.physical_address == null)
                  }
                  helperText={
                    methods.formState.errors?.accountants?.[index]?.physical_address?.message ?? ''
                  }
                />
              </RowContainer>

              <RowContainer>
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label='postal address'
                  defaultValue={loanData?.loan_accountants?.[index]?.postal_address}
                  register={methods.register(`accountants.${index}.postal_address`)}
                  error={!(methods.formState?.errors?.accountants?.[index]?.postal_address == null)}
                  helperText={
                    methods.formState.errors?.accountants?.[index]?.postal_address?.message ?? ''
                  }
                />
                <PhoneInputField
                  label='Cellphone'
                  onChange={(num) => {
                    methods.setValue(`accountants.${index}.cell`, num)
                    setCells((prevCells) => {
                      const newCells = [...prevCells]
                      newCells[index] = num
                      return newCells
                    })
                  }}
                  value={field.cell}
                  className='flex-[1_0_230px] capitalize'
                />
              </RowContainer>
              <RowContainer>
                <PhoneInputField
                  label='Telephone'
                  onChange={(num) => {
                    methods.setValue(`accountants.${index}.telephone`, num)
                    setTelephones((prevPhones) => {
                      const newPhones = [...prevPhones]
                      newPhones[index] = num
                      return newPhones
                    })
                  }}
                  value={field.telephone}
                  className='flex-[1_0_230px] capitalize'
                />
                <PhoneInputField
                  label='FAX'
                  onChange={(num) => {
                    methods.setValue(`accountants.${index}.fax`, num)
                    setFaxes((prevFaxes) => {
                      const newFaxes = [...prevFaxes]
                      newFaxes[index] = num
                      return newFaxes
                    })
                  }}
                  value={field.fax}
                  className='flex-[1_0_230px] capitalize'
                />
              </RowContainer>
              <div className='w-full flex justify-end '>
                <BasicButton
                  label='Remove'
                  color='error'
                  variant='secondary'
                  size='xs'
                  onClick={() => {
                    remove(index)
                    setTelephones((prevPhones) => {
                      const newPhones = [...prevPhones]
                      newPhones.splice(index, 1)
                      return newPhones
                    })
                    setFaxes((prevFaxes) => {
                      const newFaxes = [...prevFaxes]
                      newFaxes.splice(index, 1)
                      return newFaxes
                    })
                    setCells((prevCells) => {
                      const newCells = [...prevCells]
                      newCells.splice(index, 1)
                      return newCells
                    })
                  }}
                  disabled={index === 0}
                />
              </div>
            </div>
          ))}
        </div>
      </MainContainer>
    </FormProvider>
  )
}
