import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface VerifyOtpDTO {
  number: string
  otp: string
}

export const verifyOtp = async (params: VerifyOtpDTO): Promise<ApiResponse<[]>> => {
  const request = {
    TO: params.number,
    CODE: params.otp
  }

  return axios.post('/messaging/verify/otp', request)
}
