import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { app } from 'config/firebase'
import dayjs from 'dayjs'
import { getCompanyDetails } from 'features/authentication/api/company/getCompanyData'
import {
  type DirectorDetailsDTO,
  validateCompanyDirectorDetails
} from 'features/authentication/api/company/validateCompanyDirector'
import {
  type ShareHoldersDetailsDTO,
  validateCompanyShareHoldersDetails
} from 'features/authentication/api/company/validateCompanyShareHolders'
import { type LoginDetailsInput } from 'features/authentication/components/company/LoginDetails'
import { type CompanyShareHoldersInput } from 'features/authentication/components/company/ShareHolderDetails'
import { type CompanyDirectorInput } from 'features/authentication/components/company/ValidateCompanyDirector'
import { useCompanyRegistrationStore } from 'features/authentication/stores/useCompanyRegistrationStore'
import {
  type CompanyRegistrationDTO,
  type CompanyRegistrationResponse
} from 'features/authentication/types'
import { FirebaseError } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { type ApiResponse, type Farmer, type ShareHoldersDetails } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'

interface UseCompanyRegistration {
  isLoading: boolean
  error: string | null
  checkCompanyUIN: (uin: string) => Promise<boolean | null>
  validateDirector: (data: CompanyDirectorInput, onNext: () => void) => void
  validateShareHolder: (
    data: CompanyShareHoldersInput,
    currentUser: number,
    onNext: () => void
  ) => void
  registerCompany: (data: LoginDetailsInput, onNext: () => void) => void
}

export const useCompanyRegistration = (): UseCompanyRegistration => {
  const functions = getFunctions(app, 'us-central1')
  const createCompanyFarmer = httpsCallable(functions, 'addCompanyFarmer')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const {
    companyUIN,
    companyDetails,
    shareHoldersDetails,
    setShareHolderDetails,
    editShareHolderDetails,
    setDirectorIDDetails,
    setCompanyDetails,
    setDirectorDetails
  } = useCompanyRegistrationStore()

  const validateDirectorMutation = useMutation({
    mutationFn: async (data: DirectorDetailsDTO) => {
      return validateCompanyDirectorDetails(data)
    }
  })
  const validateShareHoldersMutation = useMutation({
    mutationFn: async (data: ShareHoldersDetailsDTO) => {
      return validateCompanyShareHoldersDetails(data)
    }
  })
  const createCompanyFarmerMutation = useMutation({
    mutationFn: async (data: CompanyRegistrationDTO) => {
      return createCompanyFarmer({ ...data })
    }
  })

  const checkCompanyUIN = async (uin: string): Promise<boolean | null> => {
    setIsLoading(true)
    setError(null)

    try {
      const checkCompanyResponse = await getCompanyDetails(uin)

      if (checkCompanyResponse.status === 200) {
        if (checkCompanyResponse.data.active_status !== 'registered') {
          toast.error('Company currently not active!')
          return false
        }

        const company = checkCompanyResponse.data
        setCompanyDetails(company)
        return true
      } else {
        toast.error(mapCmsErrorToMessage(checkCompanyResponse.status.toString()))
        return false
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const er = err?.response as AxiosResponse<ApiResponse<[]>>
        if (er.data.status === 204 || er.data.status === 404) {
          toast.error('The Company Entered does not exist')
        } else {
          toast.error(mapCmsErrorToMessage(er.data.status.toString()))
        }
      } else {
        const er = err as Error
        toast.error(mapCmsErrorToMessage(er.message))
      }
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const validateDirector = (data: CompanyDirectorInput, onNext: () => void): void => {
    validateDirectorMutation.mutate(
      {
        uin: companyUIN,
        id: data.id
      },
      {
        onSuccess: (result) => {
          if (result.status === 200) {
            setDirectorDetails(result.data)
            setDirectorIDDetails(data.id)
            onNext()
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const er = err?.response as AxiosResponse<ApiResponse<Farmer>>
            if (er.status === 404) {
              setError('Director not found.')
            } else {
              setError(mapCmsErrorToMessage(er.data.status.toString()))
            }
          } else {
            const er = err as Error
            setError(mapCmsErrorToMessage(er.message))
          }
        }
      }
    )
  }

  const registerCompany = (data: LoginDetailsInput, onNext: () => void): void => {
    createCompanyFarmerMutation.mutate(
      {
        authDetails: {
          email: data.email,
          password: data.password
        },
        companyDetails: {
          uin: companyDetails?.co_uin,
          name: companyDetails?.co_name,
          email: data.email,
          contact: data.contact,
          postal_address: companyDetails?.co_postal_address,
          physical_address: companyDetails?.co_physical_address,
          registration_date: dayjs(companyDetails?.co_reg_date).format('YYYY-MM-DD'),
          shareholders: shareHoldersDetails
        }
      },
      {
        onSuccess: (result) => {
          const response = result.data as CompanyRegistrationResponse
          if (response.success) {
            onNext()
          }
        },
        onError: (err) => {
          if (err instanceof FirebaseError) {
            setError(mapCmsErrorToMessage(err.message))
          } else {
            const er = err as Error
            setError(mapCmsErrorToMessage(er.message))
          }
        }
      }
    )
  }

  const validateShareHolder = (
    data: CompanyShareHoldersInput,
    currentUser: number,
    onNext: () => void
  ): void => {
    const shareHolder: ShareHoldersDetails = {
      surname: data.surname,
      firstnames: data.forenames,
      citizen: data.citizenship === 'Citizen' ? 'Y' : 'N',
      contact: data.contact,
      gender: data.gender,
      place_of_birth: data.placeOfBirth,
      passport: data.passport ?? '',
      id: data.id ?? '',
      id_expiry_date: dayjs(data.id_expiry_date).format('YYYY-MM-DD'),
      passport_expiry_date: dayjs(data.passport_expiry_date).format('YYYY-MM-DD'),
      date_of_birth: dayjs(data.dateOfBirth).format('YYYY-MM-DD')
    }

    if (currentUser > shareHoldersDetails.length) {
      throw new Error('Invalid shareholder')
    }

    const isEdit = isEqual(shareHoldersDetails[currentUser], shareHolder)

    if (shareHoldersDetails[currentUser] != null) {
      if (isEdit) {
        onNext()
      } else {
        validateShareHoldersMutation.mutate(
          {
            uin: companyDetails?.co_uin,
            shareholders: shareHolder
          },
          {
            onSuccess: (result) => {
              if (result.status === 200 && result.data.isShareholder) {
                editShareHolderDetails(shareHolder, currentUser)
                onNext()
              }
            },
            onError: (err) => {
              if (err instanceof AxiosError) {
                const er = err?.response as AxiosResponse<ApiResponse<Farmer>>
                if (er.status === 404) {
                  const isCitizen = data.citizenship === 'Citizen'
                  setError(
                    isCitizen
                      ? 'Shareholder with Omang not found.'
                      : 'Shareholder with' + ' Passport' + ' not found.'
                  )
                } else {
                  setError(mapCmsErrorToMessage(er.data.status.toString()))
                }
              } else {
                const er = err as Error
                setError(mapCmsErrorToMessage(er.message))
              }
            }
          }
        )
      }
    } else {
      validateShareHoldersMutation.mutate(
        {
          uin: companyDetails?.co_uin,
          shareholders: shareHolder
        },
        {
          onSuccess: (result) => {
            if (result.status === 200 && result.data.isShareholder) {
              setShareHolderDetails(shareHolder)
              onNext()
            }
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              const er = err?.response as AxiosResponse<ApiResponse<Farmer>>
              if (er.status === 404) {
                const isCitizen = data.citizenship === 'Citizen'
                setError(
                  isCitizen
                    ? 'Shareholder with Omang not found.'
                    : 'Shareholder with' + ' Passport' + ' not found.'
                )
              } else {
                setError(mapCmsErrorToMessage(er.data.status.toString()))
              }
            } else {
              const er = err as Error
              setError(mapCmsErrorToMessage(er.message))
            }
          }
        }
      )
    }
  }

  return {
    error,
    isLoading:
      isLoading ||
      validateDirectorMutation.isLoading ||
      validateShareHoldersMutation.isLoading ||
      createCompanyFarmerMutation.isLoading,
    checkCompanyUIN,
    validateDirector,
    validateShareHolder,
    registerCompany
  }
}
