import { type ApiResponse, type Lease } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getLeased = async (farmerId: string | undefined): Promise<ApiResponse<Lease>> => {
  return axios.get(`/lease?ORIGIN_FARMER_ID=${farmerId ?? ''}`)
}
export const getLease = async (
  farmerId: string | undefined,
  extAreaId: number | null | undefined,
  leaseId: string | undefined
): Promise<ApiResponse<Lease>> => {
  return axios.get(
    `/lease?DEST_FARMER_ID=${farmerId ?? ''}&EXT_AREA_ID=${extAreaId ?? ''}&LEASE_ID=${
      leaseId ?? ''
    }`
  )
}

export const useGetLeased = (
  farmerId: string | undefined
): UseQueryResult<ApiResponse<Lease>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['leased', farmerId],
    queryFn: async () => getLeased(farmerId),
    enabled: farmerId != null
  })
}

export const useGetLease = (
  farmerId: string | undefined,
  extAreaId: number | null | undefined,
  leaseId: string | undefined
): UseQueryResult<ApiResponse<Lease>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['lease', farmerId, extAreaId, leaseId],
    queryFn: async () => getLease(farmerId, extAreaId, leaseId),
    enabled: farmerId != null && extAreaId != null
  })
}
