import { BasicButton } from 'components/Elements/Button'
import type React from 'react'

interface RejectOrReturnProps {
  reject: () => void
  returnCp: () => void
}

export const RejectOrReturn: React.FC<RejectOrReturnProps> = ({ reject, returnCp }) => {
  return (
    <>
      <div className={'flex items-center pt-5 pb-5 text-xl font-bold'}>
        Reject Or Return Cropping Plan
      </div>
      <div className={'flex justify-around w-full gap-12 lg:w-[80%]'}>
        <BasicButton
          textColor={'#000'}
          color={'warning'}
          variant={'secondary'}
          label={'Return'}
          onClick={returnCp}
          size={'xs'}
        />
        <BasicButton
          textColor={'#fff'}
          color={'error'}
          label={'Reject'}
          type={'submit'}
          onClick={reject}
          size={'xs'}
        />
      </div>
    </>
  )
}
