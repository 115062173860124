import { InputAdornment, TextField } from '@mui/material'
import type React from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'

interface SelectFieldProps {
  label: string
  error: boolean | undefined
  register?: Partial<UseFormRegisterReturn>
  icon?: React.JSX.Element
  fullWidth?: boolean
  helperText?: string | undefined
  minWidth?: string
  children: React.JSX.Element[]
  defaultValue?: string | number | undefined
  onChange?: (event: React.ChangeEvent<{ value: string }>) => void
  readOnly?: boolean
  disabled?: boolean
  className?: string
}

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  icon,
  children,
  register,
  error,
  helperText,
  fullWidth = false,
  defaultValue = '',
  minWidth,
  readOnly = false,
  onChange,
  disabled,
  className
}: SelectFieldProps) => {
  return (
    <TextField
      select
      fullWidth={fullWidth}
      size={'small'}
      label={label}
      defaultValue={defaultValue}
      variant={'outlined'}
      sx={{
        minWidth
      }}
      InputProps={{
        readOnly,
        startAdornment: <InputAdornment position='start'>{icon}</InputAdornment>
      }}
      error={error}
      helperText={helperText}
      onChange={onChange}
      disabled={disabled}
      className={className}
      {...register}
    >
      {children}
    </TextField>
  )
}