import { object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import {
  useStepper,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { StepperFooter } from '../Footer'
import { useCroppingPlanPackaging } from '../../../hooks/useCroppingPlanPackaging'
import { useGetPackaging } from '../../../api/packaging/getPackaging'

export const packagingSchema = object({
  method: z.enum(
    [
      'Machinery - 50 KG Bags',
      'Machinery - 1 Tonne Bags',
      'Labour - 50 KG Bags',
      'Labour - 1 Tonne Bags'
    ],
    {
      required_error: 'You need to select a Packaging method'
    }
  )
})

export const Packaging: React.FC = () => {
  const { nextStep } = useStepper()
  const { header, setPackaging, updatePackagingPlan, createPackagingPlan, isLoading } =
    useCroppingPlanPackaging(nextStep)

  const {
    data: apiPackaging,
    isInitialLoading: apiIsLoading,
    error: packagingError
  } = useGetPackaging(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof packagingSchema>>({
    resolver: zodResolver(packagingSchema),
    defaultValues: {
      method:
        apiPackaging?.data?.packaging_method != null
          ? apiPackaging?.data?.packaging_method
          : 'Machinery - 50 KG Bags'
    }
  })

  const onSubmit = (data: z.infer<typeof packagingSchema>): void => {
    if (apiPackaging?.data == null) {
      createPackagingPlan(data)
    } else if (apiPackaging?.data?.packaging_method !== data.method) {
      updatePackagingPlan(data)
    } else {
      nextStep()
    }
  }

  useEffect(() => {
    if (apiPackaging?.data != null) {
      setPackaging(apiPackaging.data)
    }
  }, [apiPackaging])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (packagingError != null) {
    if (packagingError?.response?.status !== 404) {
      return <Error />
    }
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='method'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>Select a Shelling/Threshing technique...</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Machinery - 50 KG Bags' />
                      </FormControl>
                      <FormLabel className='font-normal'>Machinery - 50 KG Bags</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Machinery - 1 Tonne Bags' />
                      </FormControl>
                      <FormLabel className='font-normal'>Machinery - 1 Tonne Bags</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Labour - 50 KG Bags' />
                      </FormControl>
                      <FormLabel className='font-normal'>Labour - 50 KG Bags</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Labour - 1 Tonne Bags' />
                      </FormControl>
                      <FormLabel className='font-normal'>Labour - 1 Tonne Bags</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter isLoading={isLoading} />
      </form>
    </Form>
  )
}
