import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateRequest {
  user_id: number | null | undefined
  farm_id: number | null | undefined
  farmer_id: number | null | undefined
  merchant_id: number | null | undefined
  ssr_id: number | null | undefined
}

export const updateRequest = async (params: UpdateRequest): Promise<ApiResponse<[]>> => {
  return axios.put('/ssr', params)
}
