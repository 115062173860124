import type React from 'react'
import { toast } from 'react-hot-toast'
import { Text, Button } from 'components/ui'
import { TabView } from 'components/App/Layout'
export const Ownership: React.FC = () => {
  const handleClick = (): void => {
    toast.success('Not available')
  }

  return (
    <TabView>
      <Text size={'medium'}>Ownership</Text>
      <Button
        variant={'destructive'}
        onClick={handleClick}
        className={'w-full sm:max-w-[200px]'}
      >
        Transfer Ownership
      </Button>
    </TabView>
  )
}
