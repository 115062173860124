import type React from 'react'
import { Button, Text } from 'components/ui'

interface TitleDeedProps {
  onClickNo: () => void
  onClickYes: () => void
}

export const TitleDeed: React.FC<TitleDeedProps> = ({ onClickNo, onClickYes }) => {
  return (
    <div className={'w-full flex flex-col gap-8 py-8 px-4 justify-center items-center'}>
      <Text className={'text-center'} size={'medium'}>
        Do you have a secure title deed?
      </Text>
      <div className={'w-full flex justify-between gap-4'}>
        <Button size={'lg'} variant={'secondary'} onClick={onClickNo} className={'w-full'}>
          No
        </Button>
        <Button size={'lg'} onClick={onClickYes} className={'w-full'}>
          Yes
        </Button>
      </div>
    </div>
  )
}
