import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'
import type React from 'react'
import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import { useCroppingPlanStore } from '../../stores/useCroppingPlanStore'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
  onApproved: () => void
  onDraft: () => void
}

export function CroppingPlansTable<TData, TValue>({
  columns,
  data,
  onDraft,
  onApproved
}: DataTableProps<TData, TValue>): React.ReactNode {
  const { setHeader } = useCroppingPlanStore()
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  })

  return (
    <div className={'flex flex-col gap-4'}>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className='text-center font-bold'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} className={'hover:cursor-pointer hover:bg-muted-hover'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      onClick={() => {
                        const croppingPlan = row.original as CroppingPlan
                        if (
                          (croppingPlan.is_verified === CroppingPlanHeaderStatus.Draft ||
                            croppingPlan.is_verified ===
                              CroppingPlanHeaderStatus.RAI_CP_Verification) &&
                          cell.column.id !== 'actions'
                        ) {
                          setHeader(croppingPlan)
                          onDraft()
                        } else if (
                          croppingPlan.is_verified === CroppingPlanHeaderStatus.Approved &&
                          cell.column.id !== 'actions'
                        ) {
                          setHeader(croppingPlan)
                          onApproved()
                        }
                      }}
                      key={cell.id}
                      className='text-center font-medium'
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
