import { Loader } from 'components/Loader'
import { auth } from 'config/firebase'
import { onAuthStateChanged, type User } from 'firebase/auth'
import { createContext, type FC, type ReactNode, useContext, useEffect, useState } from 'react'
import { useAuthStore } from 'stores/useAuthStore'

interface AuthProviderProps {
  children: ReactNode
}

export interface AuthContextState {
  user: User | null
  role: number | null
  userId: number | null
  isFirstLogin: boolean | null
  isLoading: boolean
}

export const AuthContext = createContext<AuthContextState>({
  isLoading: false,
  user: null,
  role: null,
  userId: null,
  isFirstLogin: false
})

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [initialLoader, setInitialLoader] = useState<boolean>(true)
  const {
    user,
    role,
    setRole,
    isFirstLogin,
    setIsFirstLogin,
    userId,
    setUserID,
    setUser,
    isLoading,
    setIsLoading
  } = useAuthStore()

  const value = {
    user,
    isLoading,
    role,
    userId,
    isFirstLogin
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onAuthStateChanged(auth, async (usr) => {
      if (usr !== null) {
        await usr.getIdTokenResult(true).then((token) => {
          const claims = token.claims
          setRole((claims?.role as number) ?? null)
          setIsFirstLogin(claims?.isFirstLogin as boolean)
          setUser(usr)
          setUserID((claims?.uid as number) ?? null)
        })
      } else {
        setIsLoading(true)
        setUser(null)
        setUserID(null)
        setRole(null)
        setIsFirstLogin(false)
      }
      setInitialLoader(false)
      setIsLoading(false)
    })
  }, [])

  return (
    <AuthContext.Provider value={value}>
      {initialLoader ? <Loader /> : children}
    </AuthContext.Provider>
  )
}

export const useAuthState = (): AuthContextState => {
  return useContext(AuthContext)
}
