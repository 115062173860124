import { BasicButton } from 'components/Elements/Button'
import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { Spinner } from 'components/Elements/Spinner'
import { Text } from 'components/Elements/Text/Text'
import { NotFound } from 'components/Errors/NotFound'
import { AcceptCroppingPlan } from 'features/officer/cropping-plan/components/approve/AcceptCroppingPlan'
import { CroppingPlanInfo } from 'features/officer/cropping-plan/components/CroppingPlanInfo'
import { CroppingPlanStatuses } from 'features/officer/cropping-plan/components/CroppingPlanStatuses'
import { RejectCroppingPlanModal } from 'features/officer/cropping-plan/components/reject/RejectCroppingPlanModal'
import { RejectOrReturn } from 'features/officer/cropping-plan/components/RejectOrReturn'
import { ReturnCroppingPlanModal } from 'features/officer/cropping-plan/components/return/ReturnCroppingPlanModal'
import { useCroppingPlan } from 'features/officer/cropping-plan/hooks/useCroppingPlan'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout } from '../components/Layout'

export const CroppingPlanView: React.FC = () => {
  const { cpId } = useParams<{ cpId: string }>()
  const { farmerId } = useParams<{ farmerId: string }>()
  const [isRejectOrReturn, setIsRejectOrReturn] = useState(false)
  const [isReject, setIsReject] = useState(false)
  const [isReturn, setIsReturn] = useState(false)
  const [accept, setAccept] = useState(false)
  const navigate = useNavigate()
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const handleCloseAccept = (): void => {
    setAccept(false)
  }

  const handleBack = (): void => {
    navigate('/officer/cropping-plan', { replace: true })
  }

  const {
    rejectCroppingPlan,
    returnCroppingPlan,
    approveCroppingPlan,
    acceptCroppingPlan,
    rejectFarm,
    returnFarm,
    isLoading,
    isLoadingApi,
    header,
    details
  } = useCroppingPlan(parseInt(cpId ?? ''), parseInt(farmerId ?? ''), handleCloseAccept, handleBack)

  const handleReject = (): void => {
    setIsRejectOrReturn(false)
    setIsReject(true)
  }
  const handleReturn = (): void => {
    setIsRejectOrReturn(false)
    setIsReturn(true)
  }
  const handleOpenAccept = (): void => {
    setIsRejectOrReturn(false)
    setIsReturn(false)
    setIsReject(false)
    setAccept(true)
  }

  const handleOpenReject = (): void => {
    setAccept(true)
    setIsRejectOrReturn(true)
  }

  if (flags.croppingPlan === false) {
    return <NotFound />
  }

  if (isLoadingApi) {
    return (
      <div className={'w-full grid place-items-center h-full flex-1'}>
        <Spinner size={'sm'} />
      </div>
    )
  }

  if (header == null) {
    return <NotFound />
  }

  if (details == null) {
    return <NotFound />
  }

  return (
    <div className={'max-w-[1500px] w-full flex-col gap-4 flex justify-center mx-auto px-0 py-4'}>
      <div className={'flex justify-between items-center mx-8'}>
        <button onClick={handleBack} className={'flex items-center gap-4 cursor-pointer group'}>
          <Text variant={'bodyTextLight'} size={'large'} className={'group-hover:text-primary'}>
            <p className='fa-solid fa-arrow-left' />
          </Text>
          <div className={'flex gap-1 items-baseline'}>
            <Text
              size={'large'}
              variant={'bodyTextLight'}
              className={'group-hover:text-secondary capitalize'}
            >
              Cropping Plan Review
            </Text>
          </div>
        </button>

        <CroppingPlanStatuses
          croppingPlan={header}
          className={'flex justify-center items-center gap-2'}
        />
      </div>
      <div
        className={
          'w-full flex bg-white items-center justify-between gap-8 rounded-md lg:rounded-xl p-4 sm:px-16 md:px-16 '
        }
      >
        <div className={'flex flex-col'}>
          <Text>Cropping Plan ID</Text>
          <Text variant={'primary'} size={'large'}>
            {header?.cropping_plan_id}
          </Text>
        </div>

        <div className={'flex flex-col'}>
          <Text>Farmer ID</Text>
          <Text size={'large'} variant={'primary'}>
            {farmerId}
          </Text>
        </div>
      </div>
      <div
        className={
          'w-full flex flex-col bg-white gap-8 rounded-md lg:rounded-xl p-8 py-8 sm:px-16 md:px-16'
        }
      >
        {/* <Text size={'small'}>Farmer ID</Text> */}
        {/* <Text size={'medium'}>{farmerId}</Text> */}

        <CroppingPlanInfo croppingPlan={details} header={header} />

        <div className={'w-full flex items-center justify-between'}>
          <BasicButton
            label={'Reject'}
            color={'error'}
            variant={'secondary'}
            onClick={handleOpenReject}
            customSx={{
              padding: '0.3rem 2rem',
              width: '8rem'
            }}
            isDisabled={
              header?.is_verified !== 'Awaiting Farm Assessment' &&
              header?.is_verified !== 'Submitted CP Verification'
            }
          />
          <BasicButton
            label={'Approve'}
            color={'success'}
            textColor={'#fff'}
            variant={'primary'}
            customSx={{
              padding: '0.3rem, 2rem',
              width: '8rem'
            }}
            isDisabled={
              header?.is_verified !== 'Awaiting Farm Assessment' &&
              header?.is_verified !== 'Submitted CP Verification'
            }
            onClick={handleOpenAccept}
          />
        </div>
        <Layout onClose={handleCloseAccept} open={accept}>
          {isRejectOrReturn ? (
            <RejectOrReturn reject={handleReject} returnCp={handleReturn} />
          ) : isReject ? (
            <RejectCroppingPlanModal
              header={header}
              onClose={handleCloseAccept}
              rejectCroppingPlan={rejectCroppingPlan}
              rejectFarm={rejectFarm}
              isLoading={isLoading}
              className={'flex flex-col gap-4 '}
            />
          ) : isReturn ? (
            <ReturnCroppingPlanModal
              header={header}
              onClose={handleCloseAccept}
              returnCroppingPlan={returnCroppingPlan}
              returnFarm={returnFarm}
              isLoading={isLoading}
              className={'flex flex-col gap-4'}
            />
          ) : (
            <AcceptCroppingPlan
              header={header}
              onClose={handleCloseAccept}
              acceptCroppingPlan={acceptCroppingPlan}
              approveCroppingPlan={approveCroppingPlan}
              isLoading={isLoading}
              className={'flex flex-col gap-4'}
            />
          )}
        </Layout>
      </div>
    </div>
  )
}
