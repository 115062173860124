import type React from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { Security } from './Security'
import { useAtom } from 'jotai/index'
import { companyLoanStore, farmerType } from '../stores'
import { SecurityOptional } from '../individual/SecurityOptional'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { schemaS, type SecurityDetailsInput } from '../individual'
import { BasicButton } from '../../../../../components/Elements/Button'
import { scaleType } from '../individual/LoanApplicationForm'

interface IndividualSecurityProps {
  className?: string
  onNext: () => void
  onBack: () => void
  steps: string[]
  activeStep: number
}

export const IndividualSecurityBody: React.FC<IndividualSecurityProps> = ({
  className,
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const onSubmit: SubmitHandler<SecurityDetailsInput> = (data: SecurityDetailsInput) => {
    setLoanData((prev) => ({
      ...prev!,
      loan_security: {
        immovable_property: data.immovableProperty,
        loan_machinery: {
          age: data.machineryAge,
          original_cost: data.machineryOriginalCost,
          market_value: data.machineryMarketValue,
          estimated_forces_sale_value: data.machineryVehicleEstimatedForcedSaleVal,
          encumbrances_amount: data.machineryVehicleAmountEncumb,
          machinery_id: data.machineryId
        },
        loan_motor_vehicle: {
          age: data.motorVehicleAge,
          original_cost: data.motorVehicleOriginalCost,
          market_value: data.motorVehicleMarketValue,
          estimated_forces_sale_value: data.motorVehicleEstimatedForcedSaleVal,
          encumbrances_amount: data.motorVehicleAmountEncumb,
          vehicle_id: data.motorvehicleId
        },
        loan_bank_guarantee: { guarantee_value: data.valueOfGurantee },
        loan_other_guarantee: { guarantee_value: data.valueOfOtherGurantee },
        loan_insurance_policy: { sum_assured: data.sumAssured },
        loan_other_security: { fixed_deposit_balance: data.fixedDeposit },
        basis_of_estimation: {
          basis_of_estimation: data.estimations
        }
      }
    }))
    onNext()
  }
  const [famerType] = useAtom(farmerType)
  return (
    <>
      <div className={'flex flex-col gap-8'}>
        {famerType?.farmerType === scaleType ? (
          <SecurityOptional activeStep={activeStep} steps={steps} onBack={onBack} onNext={onNext} />
        ) : (
          <FormWithStepper<SecurityDetailsInput, typeof schemaS>
            className={'flex flex-col p-4 flex-grow-[3] justify-between w-full'}
            isLoading={false}
            isDisabled={false}
            onSubmit={onSubmit}
            steps={steps}
            activeStep={activeStep}
            onBack={onBack}
            schema={schemaS}
          >
            {(methods) => (
              <>
                <Security
                  activeStep={activeStep}
                  steps={steps}
                  onBack={onBack}
                  onNext={onNext}
                  methods={methods}
                />
                <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
                  <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
                  <BasicButton
                    label={'Next'}
                    variant={'primary'}
                    onClick={methods.handleSubmit(onSubmit)}
                    size={'xs'}
                  />
                </div>
              </>
            )}
          </FormWithStepper>
        )}
      </div>
    </>
  )
}
