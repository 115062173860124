import { create } from 'zustand'
import { type Chemicals, type Fertilizers, type Seeds, type Services } from '../types/static'

interface UseStaticDataStore {
  seeds: Seeds[] | null
  setSeeds: (seeds: Seeds[] | null) => void
  services: Services[] | null
  setServices: (services: Services[] | null) => void
  chemicals: Chemicals[] | null
  setChemicals: (chemicals: Chemicals[] | null) => void
  fertilizers: Fertilizers[] | null
  setFertilizers: (fertilizers: Fertilizers[] | null) => void
}

export const useStaticDataStore = create<UseStaticDataStore>()((set) => ({
  seeds: null,
  services: null,
  chemicals: null,
  fertilizers: null,
  setSeeds: (seeds: Seeds[] | null) => {
    set((state) => ({ ...state, seeds }))
  },
  setServices: (services: Services[] | null) => {
    set((state) => ({ ...state, services }))
  },
  setChemicals: (chemicals: Chemicals[] | null) => {
    set((state) => ({ ...state, chemicals }))
  },
  setFertilizers: (fertilizers: Fertilizers[] | null) => {
    set((state) => ({ ...state, fertilizers }))
  }
}))
