import { BasicButton } from 'components/Elements/Button'
import { OtpField } from 'components/Form/OtpField'
import { ResendOtp } from 'components/Generic/ResendOtp'
import { getTimerTime } from 'features/authentication/utils/otp'
import type React from 'react'
import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import { useOtpStore } from 'stores/useOtpStore'

interface OtpFormProps {
  className?: string
  onSubmit: () => void
  number: string
  otp: string
  onChange: (otp: string) => void
  isLoading: boolean
  resendOtp: () => void
}

export const OtpForm: React.FC<OtpFormProps> = ({
  number,
  resendOtp,
  isLoading,
  otp,
  onChange,
  className,
  onSubmit
}) => {
  const {
    setExpiryTimeOfResend,
    resendCounter,
    expiryTimeOfResend,
    setTimeOfFirstResend,
    setResendCounter
  } = useOtpStore()
  const { hours, minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp:
      expiryTimeOfResend !== null ? new Date(expiryTimeOfResend) : new Date(Date.now())
  })

  const hiddenNumber = String(number).slice(-3)

  useEffect(() => {
    if (
      resendCounter > 1 &&
      expiryTimeOfResend !== null &&
      new Date(Date.now()) < expiryTimeOfResend
    ) {
      restart(expiryTimeOfResend)
    }
  }, [resendCounter, expiryTimeOfResend])

  const handleResendOtp = (): void => {
    if (resendCounter === 0) {
      setTimeOfFirstResend(new Date(Date.now()))
    }
    const counter = resendCounter + 1
    setResendCounter(resendCounter + 1)
    resendOtp()
    setExpiryTimeOfResend(getTimerTime(new Date(Date.now()), counter))
    restart(getTimerTime(new Date(Date.now()), counter))
  }

  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <p className={'text-center self-center max-w-[18rem]'}>
        Enter OTP sent to a phone number that ends with <span>*****{hiddenNumber}</span>
      </p>
      <OtpField value={otp} onChange={onChange} />
      <p className={'text-center'}>Didn't Get Any Code? Request A New Code.</p>
      <ResendOtp
        seconds={seconds}
        minutes={minutes}
        hours={hours}
        isDisabled={(isRunning && seconds > 0) || isLoading}
        onResend={handleResendOtp}
      />
      <div className='flex justify-center'>
        <BasicButton
          label={'continue'}
          color={'success'}
          type={'submit'}
          isLoading={isLoading}
          isDisabled={otp.length !== 6 || isLoading}
        />
      </div>
    </form>
  )
}
