import { AxiosError, type AxiosResponse } from 'axios'
import { useState } from 'react'
import { type ApiResponseWithObject } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { getVoucherEligibility } from '../api/getVoucherEligibility'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'components/ui/use-toast'

interface UseCheckVoucherEligibility {
  isLoading: boolean
  error: string | null
  checkVoucherEligibility: (query: string) => Promise<void>
  isEligible: boolean | null
}

export const useCheckVoucherEligibility = (
  route: string,
  shouldNavigate = true
): UseCheckVoucherEligibility => {
  const navigate = useNavigate()
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isEligible, setIsEligible] = useState<boolean | null>(null)

  const checkVoucherEligibility = async (query: string): Promise<void> => {
    setIsEligible(null)
    setError(null)
    setIsLoading(true)
    try {
      const response = await getVoucherEligibility(query)
      if (response.status === 200) {
        setIsEligible(response.data.exists)
        if (response.data.exists && shouldNavigate) {
          navigate(route)
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponseWithObject<[]>>
        setError(
          mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        )
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        })
      } else {
        const errorMsg = err as Error
        setError(mapCmsErrorToMessage(errorMsg.message))
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(errorMsg.message)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { error, isLoading, checkVoucherEligibility, isEligible }
}
