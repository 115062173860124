import { Grow } from '@mui/material'
import { NotFound } from 'components/Errors/NotFound'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { InventoryFilterMenu } from '../components/generic/InventoryFilterMenu'

import { type GetInventoryRes } from '../types'
import { Box } from 'components/App/Layout'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text
} from 'components/ui'
import { FolderX } from 'lucide-react'
import { useMerchantStore } from '../../../../../../stores/useMerchantStore'
import { useGetServiceProviderTransferRequest } from '../api/getInventoryTransfer'
import { useGetServiceProviderInventory } from '../api/getServiceProviderInventory'
import { TransferRequestCard } from '../components/AcceptRejectTransfer/TransferRequestCard'
import { InventoryCard } from '../components/InventoryCard'

const NewInventory: React.FC = () => {
  const navigate = useNavigate()
  const [selectedInventory, setSelectedInventory] = useState('')

  const handleSubmit = (): void => {
    if (selectedInventory != null) {
      navigate(`/service-provider/inventory/register-new/${selectedInventory}`)
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size={'xs'} variant={'success'}>
          Add Inventory
          <p className={'fa-solid fa-add'} />
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-md gap-8 w-[90vw]'>
        <DialogHeader>
          <DialogTitle>
            <Text size={'medium'} className={'text-center text-semibold text-[1.2rem]'}>
              Select the inventory type
            </Text>
          </DialogTitle>
        </DialogHeader>

        <RadioGroup
          value={selectedInventory}
          onValueChange={setSelectedInventory}
          defaultValue={''}
          className={'w-full ml-4 gap-[0.7rem]'}
        >
          <div className='flex items-center space-x-2'>
            <RadioGroupItem id='tractor' value='tractor' />
            <Label htmlFor={'tractor'}>
              <Text className={'font-'}>Tractor</Text>
            </Label>
          </div>
          <div className='flex items-center space-x-2'>
            <RadioGroupItem id='pwrd-implements' value='pwrd-implements' />
            <Label htmlFor='pwrd-implements'>
              <Text>Powered Implements</Text>
            </Label>
          </div>
          <div className='flex items-center space-x-2'>
            <RadioGroupItem id='trd-implements' value='trd-implements' />
            <Label htmlFor='trd-implements'>
              <Text>Non-Powered Implements</Text>
            </Label>
          </div>
        </RadioGroup>

        <DialogFooter className='justify-end'>
          <Button
            size='lg'
            variant='primary'
            onClick={handleSubmit}
            disabled={selectedInventory === ''}
          >
            Proceed
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export const ServiceProviderInventories: React.FC = () => {
  const { merchant } = useMerchantStore()
  const [enableQuery, setEnableQuery] = useState(false)
  const [verified, setVerified] = useState<GetInventoryRes[] | null>(null)
  const [pending, setPending] = useState<GetInventoryRes[] | null>(null)
  const [filter, setFilter] = useState<'all' | 'VERIFIED' | 'PENDING_VERIFICATION'>('all')
  const flags = useFlags()
  const ldClient = useLDClient()
  const { data: transferRequest, isLoading: transferLoading } =
    useGetServiceProviderTransferRequest(Number(merchant?.merchant_id))
  const { data, isLoading } = useGetServiceProviderInventory(
    merchant?.merchant_id as number,
    enableQuery
  )

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
    if (merchant?.merchant_id !== undefined && data === undefined) {
      setEnableQuery(true)
    }

    if (data?.success === true) {
      setVerified(data?.data.filter((res) => res.item_verification_status === 'VERIFIED'))
      setPending(
        data?.data.filter((res) => res.item_verification_status === 'PENDING_VERIFICATION')
      )
    }
  }, [merchant, data, ldClient])

  if (flags.merchantInventory === false) {
    return <NotFound />
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (data?.data === undefined) {
    return (
      <Box>
        <div className={'flex justify-between items-center'}>
          <Text size={'large'}>My Inventories</Text>
          <NewInventory />
        </div>
        {transferRequest != null && transferRequest?.data?.length > 0 && (
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
            }
          >
            {transferRequest?.data
              .filter((item) => item.transaction_status === 1)
              .map((offer) => (
                <TransferRequestCard key={offer.resource_item} transferRequest={offer} />
              ))}
          </div>
        )}
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <FolderX />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            You currently have no farms inventories
          </Text>
        </div>
      </Box>
    )
  }

  return (
    <Box>
      <div className={'flex justify-between items-center'}>
        <Text size={'large'}>My Inventories</Text>
        <NewInventory />
      </div>
      {transferRequest != null && transferRequest?.data?.length > 0 && (
        <div
          className={
            'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
          }
        >
          {transferRequest?.data
            .filter((item) => item.transaction_status === 1)
            .map((offer) => (
              <TransferRequestCard key={offer.resource_item} transferRequest={offer} />
            ))}
        </div>
      )}
      <div className={'w-full flex flex-col gap-4'}>
        <InventoryFilterMenu
          all={data.data.length}
          verified={verified?.length ?? 0}
          pending={pending?.length ?? 0}
          filterBy={filter}
          setFilterBy={setFilter}
        />

        <Grow in={data !== undefined}>
          <div>
            {data.data
              .filter((i) => filter === 'all' || i.item_verification_status === filter)
              .map((item) => (
                <div
                  key={item.inventory_id}
                  className={
                    'flex flex-col sm:flex-row items-end sm:items-start justify-between gap-2 sm:gap-8 mb-3'
                  }
                >
                  <InventoryCard key={item.inventory_id} inventoryData={item} />
                </div>
              ))}
          </div>
        </Grow>
      </div>
    </Box>
  )
}
