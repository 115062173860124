import { BasicButton } from 'components/Elements/Button'
import { Form, InputField } from 'components/Form'
import { useCompanyRegistration } from 'features/authentication/hooks/useCompanyRegistration'
import type React from 'react'
import { useEffect, useState } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { object, string, type TypeOf } from 'zod'

const loginDetailsSchema = object({
  email: string().min(1, 'Email is required').email('Email is invalid'),
  contact: string().min(8, 'Contact is required'),
  password: string()
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters'),
  confirmPassword: string().min(8, 'Field is required')
}).refine((fields) => fields.password === fields.confirmPassword, {
  path: ['confirmPassword'],
  message: "Passwords don't match"
})

export type LoginDetailsInput = TypeOf<typeof loginDetailsSchema>

interface LoginDetailsFormProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

export const LoginDetails: React.FC<LoginDetailsFormProps> = ({ onBack, onNext, className }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { error, isLoading, registerCompany } = useCompanyRegistration()

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }
  }, [error])

  const onSubmit: SubmitHandler<LoginDetailsInput> = (data: LoginDetailsInput): void => {
    registerCompany(data, onNext)
  }

  return (
    <Form<LoginDetailsInput, typeof loginDetailsSchema>
      className={className}
      onSubmit={onSubmit}
      schema={loginDetailsSchema}
    >
      {({ reset, register, formState: { errors, isValid }, handleSubmit }) => (
        <>
          <div className={'flex flex-col gap-4'}>
            <InputField
              label={'Email'}
              error={!(errors.email == null)}
              helperText={errors?.email?.message ?? ''}
              register={register('email')}
            />
            <InputField
              label={'Contact'}
              error={!(errors.contact == null)}
              helperText={errors?.contact?.message ?? ''}
              register={register('contact')}
            />
            <InputField
              label={'Password'}
              type={showPassword ? 'text' : 'password'}
              error={!(errors.password == null)}
              isPassword
              showPassword={showPassword}
              onShowPassword={() => {
                setShowPassword(!showPassword)
              }}
              helperText={errors?.password?.message ?? ''}
              register={register('password')}
              defaultValue={''}
            />
            <InputField
              label={'Confirm Password'}
              type={showConfirmPassword ? 'text' : 'password'}
              error={!(errors.confirmPassword == null)}
              isPassword
              showPassword={showConfirmPassword}
              onShowPassword={() => {
                setShowConfirmPassword(!showConfirmPassword)
              }}
              helperText={errors?.confirmPassword?.message ?? ''}
              register={register('confirmPassword')}
              defaultValue={''}
            />
            <div className='flex justify-between'>
              <BasicButton
                label={'Back'}
                variant={'secondary'}
                size='sm'
                onClick={onBack}
                isDisabled={isLoading}
                customSx={{ width: '40%' }}
              />
              <BasicButton
                isDisabled={isLoading}
                isLoading={isLoading}
                label={'Complete'}
                size='sm'
                onClick={handleSubmit(onSubmit)}
                type={'submit'}
                customSx={{ width: '40%' }}
              />
            </div>
          </div>
        </>
      )}
    </Form>
  )
}
