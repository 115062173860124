import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export interface GetprocessingChecklist {
  non_powered_implement_id: number
  inventory_id: number
  inventory_type: number
  cutting_unit: number
  picker_unit: number
  auger: number
  drive_chain_belts: number
  conveying_mechanism: number
  bearings: number
  threshing_drum: number
  beaters: number
  sieve: number
  fan: number
  grain_tank: number
  discharge_unit: number
  safety_covers: number
  gears: number
  pto_shaft: number
  result: boolean
  comments: string
  create_date: string
  last_update_by: string
  last_update_date: string
}

export const getProcessingChecklist = async (
  inventoryId: number
): Promise<ApiResponse<GetprocessingChecklist>> => {
  return axios.get(`/assessment/harvestingprocessing?inventory_id=${inventoryId}`)
}

export const useGetProcessingChecklist = (
  inventoryId: number
): UseQueryResult<ApiResponse<GetprocessingChecklist>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['processingChecklist'],
    queryFn: async () => getProcessingChecklist(inventoryId)
  })
}
