import type React from 'react'
import { Modal } from '@mui/material'

interface BasicModalProps {
  children: React.JSX.Element
  open: boolean
  onClose: () => void
}

export const BasicModal: React.FC<BasicModalProps> = ({ open, onClose, children }) => {
  return (
    <Modal open={open} onClose={onClose} className={'grid place-items-center h-full w-full'}>
      {children}
    </Modal>
  )
}
