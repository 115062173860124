import type React from 'react'
import { Text } from 'components/ui'

interface InfoProps {
  label: string
  name: string
}

export const Info: React.FC<InfoProps> = ({ label, name }) => {
  return (
    <div className={'flex flex-col'}>
      <Text size={'small'} className={'ml-1 capitalize'} variant={'primary'}>
        {label}
      </Text>
      <Text size={'medium'} className={label !== 'email address' ? 'capitalize' : ''}>
        {name}
      </Text>
    </div>
  )
}
