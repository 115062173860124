import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import type React from 'react'
import { useState } from 'react'
import { DataTableViewOptions } from 'components/Data-Table/datatable-viewoptions'
import { DataTableColumnFilter } from 'components/Data-Table/datatable-filter'
import { Dialog, DialogContent } from 'components/ui'
import { SeedsTable } from './SeedsTable'
import { type SeedTransaction, type SeedTransactionDetails } from '../../types'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
}

export function ReportsTable<TData, TValue>({
  columns,
  data
}: DataTableProps<TData, TValue>): React.ReactNode {
  const [open, setOpen] = useState(false)
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [clickedData, setClickedData] = useState<SeedTransactionDetails[]>([])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters
    }
  })

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col sm:flex-row gap-4 justify-start'}>
        <DataTableColumnFilter
          columnId={'date'}
          table={table}
          placeHolder={'filter reports by date'}
        />
        <DataTableViewOptions table={table} />
      </div>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={'hover:cursor-pointer hover:bg-muted-hover'}
                  onClick={() => {
                    const seedData = row.original as SeedTransaction
                    setClickedData(seedData.seed_list)
                    openDialog()
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />

      <Dialog open={open} defaultOpen={false} modal onOpenChange={closeDialog}>
        <DialogContent
          className={
            'max-w-[80%] md:max-w-[60%] lg:max-w-[50%] overflow-scroll max-h-[90svh] md:max-h-[75svh] lg:max-h-[65svh]'
          }
        >
          <SeedsTable seeds={clickedData} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
