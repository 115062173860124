import type React from 'react'
import { BasicButton } from 'components/Elements/Button'
import {
  CategoryLabel,
  MainContainer,
  RowContainer
} from 'features/loan-management/components/stepper'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import { Text } from '../../../../../components/Elements/Text'
import { array, coerce, object, string, type TypeOf } from 'zod'
import { FormProvider, useFieldArray, useFormContext, type UseFormReturn } from 'react-hook-form'
import { MenuItem } from '@mui/material'
import { CustomDatePicker, InputField, SelectField } from 'components/Form'
import { CropsList } from 'features/farmer/cropping-plan/utils/crops'
import dayjs from 'dayjs'

const productionSchedule = object({
  crop_name: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  tonnage: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  production_year: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  })
})

// const yesOrNo: Array<RadioOptions<string>> = [
//   { value: 'yes', label: 'Yes' },
//   { value: 'no', label: 'No' }
// ]

export const schemaPSI = object({
  productionSchedule: array(productionSchedule).nonempty('Field is required')
})

// const schema = object({
//   fp_checklist1: z.enum(['yes', 'no'])
// })

export type ProductionScheduleInput = TypeOf<typeof schemaPSI>

interface StepProps {
  methods: UseFormReturn<ProductionScheduleInput>
}

export const ProductionSchedule: React.FC<StepProps> = ({ methods }) => {
  const [loanData] = useAtom(individualLoanItem)

  const { control } = useFormContext<ProductionScheduleInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productionSchedule'
  })

  const addNewProductionForm = (): void => {
    append({
      crop_name: '',
      production_year: new Date(),
      tonnage: ''
    })
  }

  return (
    <FormProvider {...methods}>
      <MainContainer>
        <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
          Production History
        </Text>
        <div className='w-full flex justify-end '>
          <BasicButton
            label={'Add new'}
            variant={'primary'}
            size={'xs'}
            onClick={addNewProductionForm}
          />
        </div>

        {fields.map((field, index) => (
          <div key={index} id={`history_${index + 1}`} className={'w-full '}>
            <CategoryLabel label={`History ${index + 1}`} />
            <RowContainer>
              <div className='flex-[1_0_100px]  capitalize'>
                <SelectField
                  fullWidth
                  label={'Crop'}
                  defaultValue={loanData?.production_history?.[index]?.crop_name}
                  register={methods.register(`productionSchedule.${index}.crop_name`)}
                  error={!(methods.formState.errors.productionSchedule?.[index]?.crop_name == null)}
                  helperText={
                    methods.formState.errors.productionSchedule?.[index]?.crop_name?.message ?? ''
                  }
                >
                  {CropsList.map((item) => (
                    <MenuItem key={item.crop_id} value={item.crop_id.toString()}>
                      <Text>{item.crop_name.toUpperCase()}</Text>
                    </MenuItem>
                  ))}
                </SelectField>
              </div>
              <InputField
                className='flex-[1_0_100px] capitalize'
                label={'Tonnage(tonnes)'}
                type='number'
                defaultValue={loanData?.production_history?.[index]?.tonnage}
                register={methods.register(`productionSchedule.${index}.tonnage`)}
                error={!(methods.formState.errors.productionSchedule?.[index]?.tonnage == null)}
                helperText={
                  methods.formState.errors.productionSchedule?.[index]?.tonnage?.message ?? ''
                }
              />
              <CustomDatePicker
                format={'YYYY'}
                className={'flex-[1_0_100px]  capitalize'}
                label={'Duration(Year)'}
                defaultValue={
                  loanData?.production_history?.[index]?.production_year === undefined
                    ? undefined
                    : dayjs(loanData?.production_history?.[index]?.production_year)
                }
                error={
                  !(methods.formState.errors.productionSchedule?.[index]?.production_year == null)
                }
                helperText={
                  methods.formState.errors.productionSchedule?.[index]?.production_year?.message ??
                  ''
                }
                name={`production_year`}
                control={control}
              />
            </RowContainer>
            <div className='w-full flex justify-end '>
              <BasicButton
                label={'Remove'}
                color='error'
                variant={'secondary'}
                size={'xs'}
                onClick={() => {
                  remove(index)
                }}
                disabled={index === 0}
              />
            </div>
          </div>
        ))}
      </MainContainer>
    </FormProvider>
  )
}
