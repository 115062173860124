/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { LandingButton, LandingLayout, Text } from 'features/landing/components/index'
import { Link, useNavigate } from 'react-router-dom'
import CoatofarmsofBotswana from 'assets/landing/logos/Coat_of_arms_of_Botswana.svg'
import brandbw from 'assets/landing/logos/brandbw.svg'
import { Layout, Button} from 'components/ui'
export const Footer: React.FC = () => {
  const navigate = useNavigate() // Initialize the useNavigate hook

  // Function to navigate to a specific route
  const navigateTo = (route: string) => {
    navigate(route)
  }
  return (
    <Layout variant='body' className='relative bg-[--color-text] px-12'>
      <Layout className='flex flex-col gap-2 px-4 md:px-6 py-4 sm:py-10 lg:py-12'>
        <div className='flex flex-wrap items-start gap-6 justify-center md:justify-between'>
          <div className='flex flex-[1_0_300px] h-full justify-center items-start gap-4'>
            <div className='w-[clamp(3rem,10vw,7rem)]'>
              <Link to='/'>
                <img src={CoatofarmsofBotswana} alt={CoatofarmsofBotswana} className='w-full' />
              </Link>
            </div>
            <div className='flex flex-col gap-2'>
              <Text variant='bodyTextlight'>Ministry Of Agricutlture</Text>
              <Text variant='bodyTextlight'>P / Bag 003 Gaborone, Botswana</Text>
              <Text variant='bodyTextlight'>
                <Link
                  to='tel: 368 9000'
                  className='flex items-center gap-2 text-[white] hover:text-[var(--color-primary)]'
                >
                  <p className='fa-solid fa-phone' />
                  (+267) 368 9000
                </Link>
              </Text>
            </div>
          </div>
          <div className='flex flex-[1_0_300px] items-center flex-col justify-center items-center gap-2'>
            <Text variant='bodyTextlight' className='w-[16rem] items-start'>
              <Link
                to='tel: 368 9000'
                className='flex items-center gap-2 text-[white] hover:text-[var(--color-primary)]'
              >
                <p className='fa-solid fa-home'></p>
                Home
              </Link>
            </Text>
            <Text variant='bodyTextlight' className='w-[16rem] items-start'>
              <Link
                to='tel: 368 9000'
                className='flex items-center gap-2 text-[white] hover:text-[var(--color-primary)]'
              >
                <p className='fa-solid fa-server'></p>
                Crop Management System
              </Link>
            </Text>
            <LandingButton
              size='small'
              title='get started'
              icon2={<p className='material-symbols-outlined'>north_east</p>}
              onClick={() => {
                navigateTo('/registration')
              }}
              hint={'register as new user'}
              className='w-full justify-center max-w-[17rem] py-2 mt-2'
            />
            <div className='w-full max-w-[15rem] flex mt-4 gap-2 items-center justify-around'>
              <Link
                to='https://web.facebook.com/AgrinewsMagazineMoa'
                className='text-base bg-[#47545b] text-[#e6e6e6] text-base inline-block leading-[44px] w-11 h-11 text-center transition-all duration-[0.2s] ease-linear mr-2 rounded-[100%] hover:text-white hover:bg-[var(--color-primary)]'
              >
                <p className='fa-brands fa-facebook-f' />
              </Link>
              <Link
                to=''
                className='text-base bg-[#47545b] text-[#e6e6e6] text-base inline-block leading-[44px] w-11 h-11 text-center transition-all duration-[0.2s] ease-linear mr-2 rounded-[100%] hover:text-white hover:bg-[var(--color-primary)]'
              >
                <p className='fa-brands fa-twitter' />
              </Link>
              <Link
                to='/'
                className='text-base bg-[#47545b] text-[#e6e6e6] text-base inline-block leading-[44px] w-11 h-11 text-center transition-all duration-[0.2s] ease-linear mr-2 rounded-[100%] hover:text-white hover:bg-[var(--color-primary)]'
              >
                <p className='fa-brands fa-linkedin-in' />
              </Link>
            </div>
          </div>

          <div className='flex h-full items-start justify-center flex-[1_0_300px]'>
            <img src={brandbw} alt={brandbw} />
          </div>
        </div>
      </Layout>
      <div className='absolute inset-x-0 flex w-full justify-center items-center py-4 bg-[white]'>
        <Text size='small'>Copyright © 2023 MOA. All rights reserved</Text>
      </div>
    </Layout>
  )
}
