import { NotFound } from 'components/Errors/NotFound'
import { useGetSoilAnalysis } from 'features/lab/ssr/api/getSoilAnalysisResult'
import { useGetSSR } from 'features/lab/ssr/api/getSSR'
import { useSoilSampleAnalysis } from 'features/lab/ssr/hooks/useSoilSampleAnalysis'
import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useMerchantStore } from 'stores/useMerchantStore'
import type z from 'zod'
import { object, string } from 'zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Loader,
  Text
} from 'components/ui'
import { TabView } from 'components/App/Layout'
import { zodResolver } from '@hookform/resolvers/zod'
import { Error } from '../../../../components/Errors/Error'

const soilSchema = object({
  ec: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  c: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  fe: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  Mn: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  Mg: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  Cu: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  pH: string()
    .refine((value) => value.trim() !== '', {
      message: 'Field is required'
    })
    .refine(
      (value) => {
        const numericValue = parseFloat(value)
        return numericValue >= 1 && numericValue <= 14
      },
      {
        message: 'pH must be a number between 1 and 14'
      }
    ),
  Ca: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  Zn: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  S: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  Na: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  K: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  P: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  bk: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  CEC: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  })
})

export const SoilSampleAnalysis: React.FC = () => {
  const { merchant } = useMerchantStore()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { ssrId } = useParams<{ ssrId: string }>()
  const { farmId } = useParams<{ farmId: string }>()
  const navigate = useNavigate()
  const {
    data: SSR,
    isLoading: ssrIsLoading,
    error: ssrError
  } = useGetSSR(farmerId, ssrId, farmId, merchant?.merchant_id)
  const {
    data: SSA,
    isLoading: ssaIsLoading,
    error: ssaError
  } = useGetSoilAnalysis(farmId, farmerId, ssrId, merchant?.merchant_id)
  const { createSSA, isSSACreated, isLoading } = useSoilSampleAnalysis()

  const onSave = (): void => {
    navigate('/soil-lab/analyses', { replace: true })
  }

  const form = useForm<z.infer<typeof soilSchema>>({
    resolver: zodResolver(soilSchema),
    defaultValues: {
      CEC: SSA?.data[0].cec_comp ?? '',
      bk: SSA?.data[0].bulk_density ?? '',
      Mn: SSA?.data[0].mn_comp ?? '',
      c: SSA?.data[0].c_comp ?? '',
      Ca: SSA?.data[0].ca_comp ?? '',
      Cu: SSA?.data[0].cu_comp ?? '',
      ec: SSA?.data[0].ec_comp ?? '',
      K: SSA?.data[0].k_comp ?? '',
      S: SSA?.data[0].s_comp ?? '',
      Zn: SSA?.data[0].zn_comp ?? '',
      pH: SSA?.data[0].ph_comp ?? '',
      P: SSA?.data[0].p_comp ?? '',
      Na: SSA?.data[0].na_comp ?? '',
      Mg: SSA?.data[0].mg_comp ?? '',
      fe: SSA?.data[0].fe_comp ?? ''
    }
  })
  const onSubmit = (data: z.infer<typeof soilSchema>): void => {
    createSSA({
      ssr_id: parseInt(ssrId ?? ''),
      ec_comp: data.ec,
      fe_comp: data.fe,
      mn_comp: data.Mn,
      cu_comp: data.Cu,
      ph_comp: data.pH,
      ca_comp: data.Ca,
      zn_comp: data.Zn,
      s_comp: data.S,
      mg_comp: data.Mg,
      na_comp: data.Na,
      k_comp: data.K,
      p_comp: data.P,
      c_comp: data.c,
      cec_comp: data.CEC,
      bulk_density: data.bk,
      user_id: merchant?.user_id
    })
  }

  useEffect(() => {
    if (isSSACreated) {
      onSave()
    }

    if (SSA != null) {
      form.reset()
    }
  }, [isSSACreated, ssaIsLoading])

  if (ssaIsLoading || ssrIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (ssaError !== null && ssaError?.response?.status !== 404) {
    return <Error />
  }

  if (ssrError != null || SSR?.data[0].merchant_id !== merchant?.merchant_id) {
    return <NotFound />
  }

  return (
    <TabView>
      {SSA?.data != null ? (
        <Text size={'medium'}>
          Update Soil Sample Results for {SSA?.data[0].farm_name} of SSR ID {SSA?.data[0].ssr_id}
        </Text>
      ) : (
        <Text size={'medium'}>
          Add Soil Sample Results for {SSR?.data[0].farm_name} of SR ID {ssrId}
        </Text>
      )}
      <Form {...form}>
        <form
          className={'w-full flex flex-col gap-4 md:gap-8'}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none md:gap-8'
            }
          >
            <FormField
              control={form.control}
              name={'bk'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Bulk Density, (g/cm3)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'CEC'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>
                    Cation Exchange Capacity, CEC (mol/kg)
                  </FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'ec'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>
                    Electrical Conductivity, ec (mSm-1)
                  </FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'c'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Organic Carbon %</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'fe'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Iron, Fe (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Mn'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Manganese, Mn (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'Cu'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Copper, Cu (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'pH'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Soil pH</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Ca'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Calcium, Ca (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Zn'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Zinc , Zn (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'S'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Sulphur, S (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Na'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Sodium, Na (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'K'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Potassium, K (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'P'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Phosphorus, P (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Mg'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Magnesium, Mg (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'text'}
                      placeholder=''
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className={'flex justify-between'}>
            <Button
              variant={'secondary'}
              onClick={onSave}
              disabled={isLoading}
              className={'min-w-24'}
            >
              Back
            </Button>
            {SSA?.data != null ? (
              <Button
                onClick={form.handleSubmit(onSubmit)}
                loading={isLoading}
                disabled={isLoading}
                className={'min-w-24'}
              >
                Update
              </Button>
            ) : (
              <Button
                type={'submit'}
                loading={isLoading}
                disabled={isLoading}
                className={'min-w-24'}
              >
                Save
              </Button>
            )}
          </div>
        </form>
      </Form>
    </TabView>
  )
}
