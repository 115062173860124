import { Grow } from '@mui/material'
import { AxiosError } from 'axios'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { NotFound } from 'components/Errors/NotFound'
import { FarmerSoilResults } from 'features/farmer/ssr/routes/FarmerSoilResults'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useEffect } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useFarmerStore } from 'stores/useFarmerStore'
import { CroppingPlan } from '../../cropping-plan/routes'
import { useGetFarm } from '../api/getFarm'
import { Box } from 'components/App/Layout'
import { Information } from '../components/tabs/Information'
import { FarmDevelopments } from '../components/tabs/FarmDevelopments'
import { Leases } from '../components/tabs/Leases'
import {
  Loader,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from 'components/ui'
import { Grants } from '../../grant/routes/Grants'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'

const getTabValue = (tab: string | null, enabled = false): string => {
  if (tab === 'Leases') {
    return 'leases'
  } else if (tab === 'Farm Developments') {
    return 'developments'
  } else if (tab === 'Soil Samples') {
    return 'ssr'
  } else if (tab === 'Cropping Plans') {
    return 'cp'
  } else if (tab === 'Grants' && enabled) {
    return 'grants'
  }
  return 'information'
}

const getTabString = (tab: string | null, enabled = false): string => {
  if (tab === 'leases') {
    return 'leases'
  } else if (tab === 'developments') {
    return 'developments'
  } else if (tab === 'ssr') {
    return 'ssr'
  } else if (tab === 'cp') {
    return 'cp'
  } else if (tab === 'grants' && enabled) {
    return 'grants'
  }
  return 'information'
}
export const FarmView: React.FC = () => {
  const { farmer } = useFarmerStore()
  const { farmId } = useParams<{ farmId: string }>()
  const { data, isLoading, error } = useGetFarm(
    farmId as unknown as number,
    farmer?.farmer_id as number
  )
  const flags = useFlags()
  const ldClient = useLDClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('tab')
  const [value, setValue] = React.useState(
    getTabString(
      query,
      farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER' && flags.grant === true
    )
  )

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const currentTab = getTabValue(
      event.currentTarget.textContent,
      farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER' && flags.grant === true
    )
    setValue(currentTab)
    setSearchParams({ tab: currentTab })
  }

  if (isLoading) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error instanceof AxiosError) {
      if (error?.response?.status === 404) {
        return <NotFound />
      } else {
        return <ErrorPage />
      }
    }
    return <ErrorPage />
  }

  return (
    <Box>
      <Breadcrumb className={'border-b border-border p-4'}>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link className={'text-lg'} to={'/farmer/farms'}>
                Farms
              </Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage className={'text-lg'}>
              {data?.data[0].farm_name} - Farm ID: {data?.data[0].farm_id}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Grow in={data !== undefined}>
        <Tabs defaultValue={value} className='flex flex-col gap-2'>
          <TabsList
            className={
              'flex justify-between md:justify-center items-center min-w-full overflow-x-scroll px-4 lg:px-8'
            }
          >
            <TabsTrigger onClick={handleChange} value='information'>
              Information
            </TabsTrigger>
            <TabsTrigger onClick={handleChange} value='leases'>
              Leases
            </TabsTrigger>
            <TabsTrigger onClick={handleChange} value='developments'>
              Farm Developments
            </TabsTrigger>
            <TabsTrigger onClick={handleChange} value='ssr'>
              Soil Samples
            </TabsTrigger>
            <TabsTrigger onClick={handleChange} value='cp'>
              Cropping Plans
            </TabsTrigger>
            {farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER' && flags.grant === true ? (
              <TabsTrigger onClick={handleChange} value='grants'>
                Grants
              </TabsTrigger>
            ) : null}
          </TabsList>

          <TabsContent value='information'>
            <Information farm={data?.data[0]} />
          </TabsContent>
          <TabsContent value='leases'>
            <Leases farm={data?.data[0]} />
          </TabsContent>
          <TabsContent value='developments'>
            <FarmDevelopments />
          </TabsContent>
          <TabsContent value='ssr'>
            <FarmerSoilResults farm={data?.data[0]} />
          </TabsContent>
          <TabsContent value='cp'>
            <CroppingPlan farm={data?.data[0]} />
          </TabsContent>
          {farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER' && flags.grant === true ? (
            <TabsContent value='grants'>
              <Grants farm={data?.data[0]} />
            </TabsContent>
          ) : null}
        </Tabs>
      </Grow>
    </Box>
  )
}
