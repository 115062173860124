import { object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import {
  useStepper,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { StepperFooter } from '../Footer'
import { useCroppingPlanHarvesting } from '../../../hooks/useCroppingPlanHarvesting'
import { useGetHarvesting } from '../../../api/harvesting/getHarvesting'

export const harvestingSchema = object({
  technique: z.enum(['Machine Based Harvesting', 'Human Based Harvesting'], {
    required_error: 'You need to select a Harvesting technique'
  })
})

export const Harvesting: React.FC = () => {
  const { nextStep } = useStepper()
  const { setHarvesting, createHarvestingPlan, updateHarvestingPlan, header, isLoading } =
    useCroppingPlanHarvesting(nextStep)

  const {
    data: apiHarvesting,
    isInitialLoading: apiIsLoading,
    error: harvestingError
  } = useGetHarvesting(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof harvestingSchema>>({
    resolver: zodResolver(harvestingSchema),
    defaultValues: {
      technique:
        apiHarvesting?.data?.harvesting_method != null
          ? apiHarvesting?.data?.harvesting_method
          : 'Machine Based Harvesting'
    }
  })
  const onSubmit = (data: z.infer<typeof harvestingSchema>): void => {
    if (apiHarvesting == null) {
      createHarvestingPlan(data)
    } else if (data.technique !== apiHarvesting?.data?.harvesting_method) {
      updateHarvestingPlan(data)
    } else {
      nextStep()
    }
  }

  useEffect(() => {
    if (apiHarvesting?.data != null) {
      setHarvesting(apiHarvesting.data)
    }
  }, [apiHarvesting])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (harvestingError != null) {
    if (harvestingError?.response?.status !== 404) {
      return <Error />
    }
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='technique'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>Select a Harvesting technique...</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Machine Based Harvesting' />
                      </FormControl>
                      <FormLabel className='font-normal'>Machine Based Harvesting</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value='Human Based Harvesting' />
                      </FormControl>
                      <FormLabel className='font-normal'>Human Based Harvesting</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter isLoading={isLoading} />
      </form>
    </Form>
  )
}
