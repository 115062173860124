import { NotFound } from 'components/Errors/NotFound'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect } from 'react'
import { useOfficerStore } from 'stores/useOfficerStore'
import { useGetGrant } from '../api/getGrants'
import { Card, Text } from '../../../../components/ui'
import { Loader } from '../../../../components/ui/loader'
import { GrantTable } from '../components/grants/grantsTable'
import { columns } from '../components/grants/columns'

export const GrantsView: React.FC = () => {
  const { officer } = useOfficerStore()
  const { data: grantArray, isLoading } = useGetGrant(officer?.ext_area_id)
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.grant === false) {
    return <NotFound />
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full flex-1'}>
        <Loader size={36} />
      </div>
    )
  }

  if (grantArray?.data === undefined) {
    return (
      <div className={'h-full flex-1 flex flex-col justify-center items-center gap-4'}>
        <h1>No Grants in Your Area</h1>
      </div>
    )
  }

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'}>Grants</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <Text size={'medium'} variant={'bodyTextLight'}>
          Below are all the grants in your extension area.
        </Text>
        <GrantTable data={grantArray?.data} columns={columns} />
      </Card>
    </div>
  )
}
