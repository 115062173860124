import { MainContainer } from 'features/loan-management/components/stepper'
import { CustomCheckBoxGroup } from 'features/loan-management/components/generic/form'
import type React from 'react'
import { useState } from 'react'
import { BasicButton } from 'components/Elements/Button'
import { LoanStepper } from '../individual/LoanStepper'
import { Text } from 'components/Elements/Text/Text'
import { type LoanStepperProps } from '../../../types'

export const CompanyAttachments: React.FC<LoanStepperProps> = ({
  steps,
  activeStep,
  onBack,
  onNext
}) => {
  const listOfAttachments = [
    'Confirmation of membership of Grain producer’s association.',
    'One (1) certified copies of National Identity Cards (Omang), for all applicants, Shareholders &amp; Directors.',
    'A copy of residence permits &amp; passports (Non-citizens employees).',
    'If Applicable, a copy of Marriage Certificate, proof of Marriage Regime &amp; Letter of consent.',
    'Provide Tax Clearance Certificate and TIN number, or Acknowledgment of Application (Letter provided pending certificate)',
    'Provide Soil Test results from accredited Laboratory',
    'Provide lease agreement / Title Deed where appropriate / Land Board Certification.',
    'Where applicant has loans with other financial institutions - provide 6 months loan statements.',
    'Proof of Physical Address, i.e. Utility Bills.'
  ]
  const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>(
    new Array(listOfAttachments.length).fill(false)
  )

  const onSubmit = (): void => {
    onNext()
  }

  const handleCheckboxChange = (index: number): void => {
    const updatedCheckboxes = [...checkedCheckboxes]
    updatedCheckboxes[index] = !updatedCheckboxes[index]
    setCheckedCheckboxes(updatedCheckboxes)
  }

  const isContinueButtonDisabled = !checkedCheckboxes.every(
    (isChecked: boolean, index: number) => Boolean(isChecked) || index === 3 || index === 7
  )
  return (
    <LoanStepper
      steps={steps}
      activeStep={activeStep}
      next={onNext}
      back={onBack}
      stepperKey={'attachments'}
    >
      <MainContainer>
        <Text size={'medium'} variant={'bodyTextLight'} className={'ml-8'}>
          Confirm the following list of attachments
        </Text>
        <div className={'w-full'}>
          <CustomCheckBoxGroup
            className={'flex flex-col gap-2'}
            labels={listOfAttachments}
            checkedCheckboxes={checkedCheckboxes}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>

        <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
          <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
          <BasicButton
            label={'Next'}
            variant={'primary'}
            onClick={onSubmit}
            size={'xs'}
            disabled={isContinueButtonDisabled}
          />
        </div>
      </MainContainer>
    </LoanStepper>
  )
}
