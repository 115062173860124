import { useStepper } from 'components/ui/stepper'
import { Button } from 'components/ui'
import type React from 'react'

interface StepperFooterProps {
  isLoading?: boolean
  form?: string
  isFirstStep?: boolean
  notComplete?: boolean
  disabled?: boolean
  cancel?: () => void
  submit?: () => void
}
export const StepperFooter: React.FC<StepperFooterProps> = ({
  isLoading = false,
  form,
  isFirstStep = false,
  notComplete = false,
  disabled = false,
  cancel,
  submit
}) => {
  const { prevStep, nextStep, isLastStep, isDisabledStep, isOptionalStep } = useStepper()

  return (
    <div className='w-full flex justify-end gap-2'>
      {isOptionalStep ? (
        <Button disabled={isLoading} size={'sm'} variant={'outlined'} onClick={nextStep}>
          Skip
        </Button>
      ) : null}
      <Button
        disabled={isLoading || (isDisabledStep && !isFirstStep)}
        onClick={isFirstStep ? cancel : prevStep}
        size={'sm'}
        variant={'secondary'}
      >
        {isFirstStep ? 'Cancel' : 'Prev'}
      </Button>
      <Button
        disabled={isLoading || notComplete || disabled}
        loading={isLoading}
        onClick={isLastStep ? nextStep : submit != null ? submit : undefined}
        size={'sm'}
        variant={'primary'}
        type={'submit'}
        form={form}
      >
        {isLastStep ? 'Finish' : 'Next'}
      </Button>
    </div>
  )
}
