import { Divider } from '@mui/material'
import { Text } from 'components/Elements/Text/Text'
import {
  getChemicalPestMethod,
  getChemicalWeedMethodValue,
  getHerbicide,
  getInsecticide,
  getManualPestStageValue,
  getManualWeedApplicationStageValue,
  getManualWeedMethodValue
} from 'features/farmer/cropping-plan/utils/functions'
import type React from 'react'
import { type CroppingPlan, type CroppingPlanDetails } from 'types'

interface CroppingPlanInfoProps {
  header: CroppingPlan
  croppingPlan: CroppingPlanDetails
}

export const CroppingPlanInfo: React.FC<CroppingPlanInfoProps> = ({ croppingPlan, header }) => {
  return (
    <div className={'w-full flex flex-col gap-8'}>
      <div className={'flex items-center'}>
        <div className={'w-1/2 flex flex-col'}>
          <Text>Crop Name</Text>
          <Text size={'medium'}>{header?.crop_name}</Text>
        </div>
        <div className={'w-1/2 flex flex-col'}>
          <Text>Hectarage</Text>
          <Text size={'medium'}>{croppingPlan?.hectarage}</Text>
        </div>
      </div>

      <div className={'flex flex-col gap-2'}>
        <Divider />
        {/* Seeding */}
        <Text size={'large'} variant={'primary'}>
          Seeding
        </Text>
        <div className={'flex items-center'}>
          <div className={'w-1/2 flex flex-col'}>
            <Text>Seed Variety</Text>
            <Text size={'medium'}>{croppingPlan?.seed_variety}</Text>
          </div>
          <div className={'w-1/2 flex flex-col'}>
            <Text>Anticipated Returns</Text>
            <Text size={'medium'}>BWP {croppingPlan?.anticipated_returns}</Text>
          </div>
        </div>
        <div className={'flex items-center'}>
          <div className={'w-1/2 flex flex-col'}>
            <Text>Total cost for Seeding</Text>
            <Text variant={'success'} size={'medium'}>
              BWP {croppingPlan?.seed_cost}{' '}
            </Text>
          </div>
          <div className={'w-1/2 flex flex-col'}>
            <Text> </Text>
            <Text size={'medium'}> </Text>
          </div>
        </div>
      </div>

      {croppingPlan?.packaging_method === null ? null : (
        <div className={'flex flex-col gap-2'}>
          {/* Weeding */}
          <Divider />
          <Text size={'large'} variant={'primary'}>
            Weeding
          </Text>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Weeding Method</Text>
              <Text size={'medium'}>{croppingPlan?.weed_control_type}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Weeding Application Stage</Text>
              <Text size={'medium'}>
                {getManualWeedApplicationStageValue(
                  croppingPlan?.weed_control_chemical_schedule ??
                    croppingPlan?.weed_control_manual_schedule ??
                    undefined
                )}
              </Text>
            </div>
          </div>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Weeding Application Method</Text>
              <Text size={'medium'}>
                {croppingPlan?.weed_control_manual_method !== null
                  ? getManualWeedMethodValue(croppingPlan?.weed_control_manual_method)
                  : getChemicalWeedMethodValue(croppingPlan.weed_control_chemical_method)}
              </Text>
            </div>

            {croppingPlan?.weed_control_chemicals != null &&
            croppingPlan?.weed_control_chemicals.length > 0 ? (
              <div className={'w-1/2 flex flex-col'}>
                <Text>Herbicide(s) Selected</Text>
                {croppingPlan?.weed_control_chemicals?.map((item, index) => (
                  <span key={index}>
                    * <Text size={'medium'}>{getHerbicide(parseInt(item.id))[0].name}</Text>
                  </span>
                ))}
              </div>
            ) : null}
          </div>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Total cost for Weeding</Text>
              <Text variant={'success'} size={'medium'}>
                BWP{' '}
                {croppingPlan?.weed_control_type === 'Chemical'
                  ? (croppingPlan?.weed_control_chemical_cost ?? 0) +
                    (croppingPlan?.weed_control_application_cost ?? 0)
                  : croppingPlan?.weed_control_application_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      {croppingPlan?.packaging_method === null ? null : (
        <div className={'flex flex-col gap-2'}>
          {/* Pest Control */}
          <Divider />
          <Text size={'large'} variant={'primary'}>
            Pest Control
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text> Pest Control Method</Text>
              <Text size={'medium'}>{croppingPlan?.pest_control_type} </Text>
            </div>
            {croppingPlan?.pest_control_manual_schedule != null ? (
              <div className={'w-1/2 flex flex-col'}>
                <Text>Pest Control Schedule</Text>
                <Text size={'medium'}>
                  {croppingPlan?.pest_control_chemical_schedule != null
                    ? croppingPlan?.pest_control_chemical_schedule
                    : getManualPestStageValue(croppingPlan?.pest_control_manual_schedule)}
                </Text>
              </div>
            ) : null}
          </div>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text> Pest Control Application Method</Text>
              <Text size={'medium'}>
                {croppingPlan?.pest_control_manual_method !== null
                  ? croppingPlan?.pest_control_manual_method
                  : croppingPlan?.pest_control_chemical_method != null
                  ? getChemicalPestMethod(croppingPlan.pest_control_chemical_method)
                  : null}
              </Text>
            </div>

            {croppingPlan?.pest_control_chemicals != null &&
            croppingPlan?.pest_control_chemicals.length > 0 ? (
              <div className={'w-1/2 flex flex-col'}>
                <Text> Insecticide(s) Selected</Text>

                {croppingPlan?.pest_control_chemicals?.map((item, index) => (
                  <span key={index}>
                    *<Text size={'medium'}> {getInsecticide(parseInt(item.id))[0].name}</Text>
                  </span>
                ))}
              </div>
            ) : null}
          </div>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text> Total cost for Pest Control</Text>
              <Text variant={'success'} size={'medium'}>
                BWP{' '}
                {croppingPlan?.pest_control_type === 'Chemical'
                  ? (croppingPlan?.pest_control_application_cost ?? 0) +
                    (croppingPlan?.pest_control_chemical_cost ?? 0)
                  : croppingPlan?.pest_control_application_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      <div className={'flex flex-col gap-2'}>
        {/* Land Preparation */}
        <Divider />
        <Text size={'large'} variant={'primary'}>
          Land Preparation
        </Text>
        <div className={'flex items-center'}>
          <div className={'w-1/2 flex flex-col'}>
            <Text>Land Preparation Method</Text>
            <Text size={'medium'}>{croppingPlan?.tillage_method}</Text>
          </div>
          <div className={'w-1/2 flex flex-col'}>
            <Text>Harrowing</Text>
            <Text size={'medium'}>{croppingPlan?.isHarrowing === true ? 'Yes' : 'No'}</Text>
          </div>
        </div>
        <div className={'flex items-center'}>
          <div className={'w-1/2 flex flex-col'}>
            <Text>Total cost for Land Preparation</Text>
            <Text variant={'success'} size={'medium'}>
              BWP {croppingPlan?.land_preparation_cost}
            </Text>
          </div>
        </div>
      </div>

      {croppingPlan?.harvesting_method === null ? null : (
        <div className={'flex flex-col gap-2'}>
          {/* Harvesting */}
          <Divider />
          <Text size={'large'} variant={'primary'}>
            Harvesting
          </Text>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Harvesting Method</Text>
              <Text size={'medium'}>{croppingPlan?.harvesting_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text> Total cost for harvesting</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.harvesting_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      {croppingPlan?.shelling_threshing_method === null ? null : (
        <div className={'flex flex-col gap-2'}>
          {/* Threshing */}
          <Divider />
          <Text size={'large'} variant={'primary'}>
            Shelling & Threshing
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text> Shelling & Threshing Method</Text>
              <Text size={'medium'}>{croppingPlan?.shelling_threshing_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Total cost for shelling & threshing</Text>
              <Text variant={'success'} size={'medium'}>
                {' '}
                BWP {croppingPlan?.shelling_threshing_cost}
              </Text>
            </div>
          </div>
        </div>
      )}
      {croppingPlan?.packaging_method === null ? null : (
        <div className={'flex flex-col gap-2'}>
          {/* Packaging */}
          <Divider />
          <Text size={'large'} variant={'primary'}>
            Packaging
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text> Packaging Method</Text>
              <Text size={'medium'}>{croppingPlan?.packaging_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text> Total cost for packaging</Text>
              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.packaging_cost}
              </Text>
            </div>
          </div>
        </div>
      )}
      <div className={'flex flex-col mt-6'}>
        {/* Transportation */}
        <Divider />
        <div className={'flex items-center'}>
          {croppingPlan?.transportation_cost === null ||
          croppingPlan?.transportation_cost === 0 ? null : (
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'large'} variant={'primary'}>
                Transportation
              </Text>

              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.transportation_cost}
              </Text>
            </div>
          )}
          {/* Total Cost */}
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'large'} variant={'primary'}>
              Total Cost
            </Text>
            <Text variant={'success'} size={'medium'}>
              BWP {header.total_cost}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
