import type React from 'react'
import { type GetInventoryRes, type Implement } from '../types'
import { Button, Text } from 'components/ui'
import dayjs from 'dayjs'
import { LabInventoryStatus } from '../../../../../mechanisationOfficer/inventory/components/LabInventoryStatus'

interface InventoryCardProps {
  inventoryData: GetInventoryRes
  onClose?: () => void
  inventoryItem?: Implement
  isMerchant?: boolean
}

export const ViewImplement: React.FC<InventoryCardProps> = ({
  inventoryData,
  inventoryItem,
  onClose,
  isMerchant
}) => {
  return (
    <div className={'w-full flex-col gap-4 md:gap-8'}>
      <div className={'mb-4 flex justify-between items-center'}>
        <Text size={'medium'}>Non-Powered Implement</Text>
        <Text className={'flex gap-2 items-center'}>
          <LabInventoryStatus inventory={inventoryData} className={'flex items-center gap-4'} />
        </Text>
      </div>

      <div className={'w-full flex flex-wrap gap-4'}>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Serial Number</Text>
          <Text>{inventoryData?.reg_number}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Year Of Manufacture</Text>
          <Text>{dayjs(inventoryData?.yom).format('YYYY')}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Draft Power</Text>
          <Text>{inventoryItem?.draft_power}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Make</Text>
          <Text>{inventoryItem?.make}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Model</Text>
          <Text>{inventoryItem?.model}</Text>
        </div>

        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement Class</Text>
          <Text>{inventoryItem?.implement_class ?? 'N/A'}</Text>
        </div>

        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement Type</Text>
          <Text>{inventoryItem?.implement_type ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement Sub Type</Text>
          <Text>{inventoryItem?.implement_sub_type ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>
            {inventoryItem?.implement_class === 'SEEDER' ? 'Number of Rows' : 'Number of Bottoms'}
          </Text>
          <Text>
            {inventoryItem?.implement_class === 'SEEDER'
              ? inventoryItem?.no_of_rows?.toString()
              : inventoryItem?.no_of_bottoms?.toString() ?? 'N/A'}
          </Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Working Width</Text>
          <Text>{inventoryItem?.working_width ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement System</Text>
          <Text>{inventoryItem?.implement_system ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Implement Attachment</Text>
          <Text>{inventoryItem?.attachment ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Inventory Status</Text>
          <Text>{inventoryData.item_status ?? 'N/A'}</Text>
        </div>
      </div>
      {isMerchant === false && (
        <div className={'flex w-full justify-end'}>
          <Button className={'w-full sm:w-[18rem] '} onClick={onClose}>
            Close
          </Button>
        </div>
      )}
    </div>
  )
}
