import React from 'react'
import type {
  GetInventoryRes,
  Implement,
  PoweredImplement,
  Tractor
} from '../../../../farmer/inventory/types'

import { CustomStepper } from '../../../../../components/Elements/Stepper'
import { Checklist } from './Checklist'
import { Button } from '../../../../../components/ui'
import { ModifyInventory } from '../../../../farmer/inventory/components/ModifyInventory'

interface ChecklistContainerProps {
  inventoryItem: Tractor | Implement | PoweredImplement | undefined
  inventoryData: GetInventoryRes
  onclose: () => void
}

const steps = ['Confirm Information', 'Inventory Checklist']

export const ChecklistContainer: React.FC<ChecklistContainerProps> = ({
  inventoryData,
  onclose,
  inventoryItem
}) => {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }

  if (activeStep === 1) {
    return (
      <div className={'flex flex-col gap-8'}>
        <CustomStepper steps={steps} activeStep={activeStep} />
        <Checklist
          className={'flex flex-col gap-8'}
          onBack={handleBack}
          inventoryData={inventoryData}
          onClose={onclose}
          inventoryItem={inventoryItem}
        />
      </div>
    )
  }

  return (
    <div className={'flex flex-col gap-4 w-[100%]'}>
      <CustomStepper steps={steps} activeStep={activeStep} />
      <ModifyInventory
        inventoryData={inventoryData}
        onClose={onclose}
        inventoryItem={inventoryItem}
        isView={true}
        isModify={false}
        isMerchant={true}
      />
      <div className={'flex justify-between pt-4'}>
        <Button variant={'secondary'} onClick={onclose} size={'xs'}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={handleNext} size={'xs'}>
          Continue
        </Button>
      </div>
    </div>
  )
}
