import type React from 'react'
import { type SoilSampleRequest } from 'types'
import { Text } from 'components/Elements/Text'

interface SSRVerificationStatusProps {
  ssr: SoilSampleRequest
  className?: string
}

export const SSRVerificationStatus: React.FC<SSRVerificationStatusProps> = ({ ssr, className }) => {
  if (ssr.status_name === 'COMPLETED') {
    return (
      <Text size={'medium'} variant={'success'} className={className}>
        <p className='fa-solid fa-circle-check' /> SSA Completed
      </Text>
    )
  }
  if (ssr.status_name === 'REJECTED') {
    return (
      <Text size={'medium'} variant={'error'} className={className}>
        <p className='fa-solid fa-circle-check' /> SSR Rejected
      </Text>
    )
  }

  if (ssr.status_name === 'IN PROCESS') {
    return (
      <Text size={'medium'} variant={'warning'} className={className}>
        <p className='material-symbols-outlined'>pending_actions</p>
        <p className={'md:hidden'}>SSR Assessment</p>
        <p className={'hidden md:flex'}>Awaiting SSR Assessment</p>
      </Text>
    )
  }

  return (
    <Text size={'medium'} variant={'primary'} className={className}>
      <p className='material-symbols-outlined'>pending_actions</p>
      <p className={'md:hidden'}>Reviewed</p>
      <p className={'hidden md:flex'}>Submitted for Review</p>
    </Text>
  )
}
