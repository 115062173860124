const applicators = [
  'Nozel Output',
  'Boom',
  'Hoses',
  'Pump O Rings',
  'Tank',
  'Gasket',
  'Regulators Gauges Controls',
  'Belts',
  'Propeller And Agitation',
  'Tires',
  'Filters',
  'Dispenser',
  'Measuring Device',
  'Gears'
]

const harvestingAndProcessing = [
  'Cutting Unit',
  'Picker Unit',
  'Auger',
  'Drive Chain Belts',
  'Conveying Mechanisms',
  'Bearings',
  'Threshing Drums',
  'Beaters',
  'Sieve',
  'Fan',
  'Grain Tank',
  'Discharge Unit',
  'Safety Covers',
  'Gears',
  'Pto Shaft'
]

const tillage = [
  'Disc',
  'Share',
  'Tine',
  'Bearing',
  'Stabilizer Wheel',
  'Landslide',
  'Cross Shaft',
  'Frame',
  'Bolts And Nuts',
  'Greasing',
  'Hydraulics',
  'Hitch Pins',
  'Gears'
]

const seeders = [
  'Farrow Openers',
  'Seed Metering',
  'Tine',
  'Covering Wheels',
  'Fertilizer Hopers',
  'Seed Hopers',
  'Fertilizer Tubes',
  'Seed Hoses',
  'Greasing',
  'Hydraulics',
  'Gears',
  'Pressure Gauges',
  'Hitch Pins',
  'Bearings'
]

const tractors = [
  'Lights',
  'Starting',
  'Gauges',
  'Cooling Leakages',
  'Fuel Leakages',
  'Lubrication Leakages',
  'Hydraulic 0il Leakages',
  'Transmission Leakages',
  'Smoke Color',
  'Oil Check',
  'Greasing',
  'PTO',
  'Gear Shifting',
  'Lifting and Releasing',
  'Hitch Pins',
  'Bearings',
  'Tyres',
  'Mirrors',
  'Functional GPS',
  'Engine Stalling',
  'Positioning and Draft Control',
  'Breaking System',
  'Steering System',
  'Diff Loc'
]

const selfPropelledSprayers = [
  'Nozel Output',
  'Boom',
  'Hoses',
  'Pump O Rings',
  'Tank',
  'Gasket',
  'Regulators Gauges Controls',
  'Belts',
  'Propeller And Agitation',
  'Tyres',
  'Filters',
  'Dispenser',
  'Measuring Device',
  'Gears',
  'Lights',
  'Starting',
  'Gauges',
  'Cooling Leakages',
  'Fuel Leakages',
  'Lubrication Leakages',
  'Hydraulic Oil Leakages',
  'Transmission Leakages',
  'Smoke Color',
  'Oil Check',
  'Greasing',
  'Pto',
  'Gear Shifting',
  'Lifting And Releasing',
  'Hitch Pins',
  'Bearings',
  'Mirrors',
  'Engine Stalling',
  'Positioning And Draft Control',
  'Breaking System',
  'Steering System',
  'Diff Loc'
]

export { applicators, harvestingAndProcessing, tillage, seeders, tractors, selfPropelledSprayers }
