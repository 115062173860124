import { Lock } from '@mui/icons-material'
import { PasswordCheckList } from 'components/Change-Password/PasswordCheckList'
import { BasicButton } from 'components/Elements/Button'
import { Form, InputField } from 'components/Form'
import { useChangePassword } from 'hooks/useChangePassword'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { type ROLES } from 'types'
import { object, string, type TypeOf } from 'zod'

interface ChangePasswordFormProps {
  role: ROLES
}

const schema = object({
  password: string()
    .min(8, 'The password must be at least 8 characters long')
    .max(32, 'The password must be a maximum of 32 characters')
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*-])[A-Za-z\d!@#$%&*-]{8,}$/),
  confirmPassword: string().nonempty('confirm password is required')
}).refine((fields) => fields.password === fields.confirmPassword, {
  path: ['confirmPassword'],
  message: "Passwords don't match"
})

type ChangePasswordInput = TypeOf<typeof schema>
export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ role }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { isLoading, error, changePassword } = useChangePassword(role)

  useEffect(() => {
    if (error != null) {
      toast.error(error, { id: error })
    }
  }, [error])
  const onSubmit: SubmitHandler<ChangePasswordInput> = (data: ChangePasswordInput) => {
    void changePassword(data.password.trim())
  }

  return (
    <Form<ChangePasswordInput, typeof schema>
      className={'grid place-items-center'}
      onSubmit={onSubmit}
      schema={schema}
    >
      {({ register, watch, formState: { errors }, handleSubmit }) => (
        <div
          className={
            'flex flex-col sm:flex-row w-full justify-start sm:justify-around items-center' +
            ' sm:items-start' +
            ' gap-4' +
            ' sm:gap-8'
          }
        >
          <PasswordCheckList password={watch('password') ?? ''} />

          <div className={'flex flex-col gap-6'}>
            <InputField
              label={'New Password'}
              type={showPassword ? 'text' : 'password'}
              error={!(errors.password == null)}
              isPassword
              showPassword={showPassword}
              onShowPassword={() => {
                setShowPassword(!showPassword)
              }}
              helperText={errors?.password?.message ?? ''}
              icon={<Lock color={'primary'} />}
              register={register('password')}
              defaultValue={''}
            />
            <InputField
              label={'Confirm New Password'}
              type={showConfirmPassword ? 'text' : 'password'}
              error={!(errors.confirmPassword == null)}
              isPassword
              showPassword={showConfirmPassword}
              onShowPassword={() => {
                setShowConfirmPassword(!showConfirmPassword)
              }}
              helperText={errors?.confirmPassword?.message ?? ''}
              icon={<Lock color={'primary'} />}
              register={register('confirmPassword')}
              defaultValue={''}
            />
            <BasicButton
              label={'Reset Password'}
              variant={'primary'}
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
              type={'submit'}
            />
          </div>
        </div>
      )}
    </Form>
  )
}
