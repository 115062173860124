import { type ApiResponse, type ApiResponseWithObject } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type LoanResponse } from '../types/individual'

export const getIndividualLoan = async (
  farmerId: number,
  loan_application_id: number
): Promise<ApiResponse<LoanResponse>> => {
  return axios.get(
    `/loan/individual?farmer_id=${farmerId}&loan_application_id=${loan_application_id}`
  )
}

export const useGetIndividualLoan = (
  farmerId: number,
  loan_application_id: number
): UseQueryResult<ApiResponseWithObject<LoanResponse>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['loan_application_id', loan_application_id],
    queryFn: async () => getIndividualLoan(farmerId, loan_application_id)
  })
}
