import type React from 'react'
import { type Control, Controller } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/en-gb'
import { type Dayjs } from 'dayjs'

interface YearPickerProps {
  name: string
  label: string
  disabled?: boolean
  error: boolean
  helperText: string
  control?: Control<any, any>
  defaultValue?: Dayjs | null | undefined
  minDate?: Date | null | undefined
  maxYear?: number | null | undefined
  maxDate?: Date | null | undefined
  className?: string
}

export const YearPicker: React.FC<YearPickerProps> = ({
  name,
  disabled = false,
  error,
  helperText,
  control,
  defaultValue,
  label,
  minDate,
  maxYear,
  className
}: YearPickerProps) => {
  const maxDate = maxYear != null ? new Date(maxYear, 11, 31) : undefined

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? new AdapterDayjs()}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['year']}
            openTo='year'
            label={label}
            disabled={disabled}
            disableFuture
            minDate={minDate}
            maxDate={maxDate}
            className={className}
            sx={{
              '& .MuiInputLabel-root': {
                fontFamily: 'Red Hat Display',
                color: 'var(--foreground)',
                ...(error && {
                  color: 'var(--destructive)'
                })
              },
              '& .MuiInputBase-input': {
                color: 'var(--foreground)',
                fontFamily: 'Red Hat Display',
                ...(error && {
                  color: 'var(--destructive)'
                })
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: error ? 'var(--destructive)' : 'var(--input)',
                  ...(disabled && {
                    opacity: '0.5'
                  })
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary)',
                  ...(error && {
                    borderColor: 'var(--destructive)'
                  })
                }
              }
            }}
            slotProps={{ textField: { size: 'small', error, helperText } }}
            {...field}
          />
        </LocalizationProvider>
      )}
    />
  )
}
