import { type InventoryTransfer } from '../../types'
import { useState } from 'react'
import { useTransferInventory } from '../../hooks/useTransferInventory'
import { DropdownMenuContent, DropdownMenuItem, Text } from '../../../../../components/ui'
import { Check, Trash2 } from 'lucide-react'
import { LoadingDialog } from '../../../../../components/ui/loading-dialog'

interface AcceptRejectTransferProps {
  transferRequest?: InventoryTransfer
}

export const AcceptRejectTransfer: React.FC<AcceptRejectTransferProps> = ({ transferRequest }) => {
  const [open, setOpen] = useState(false)
  const { acceptTransferRequest, rejectTransferRequest, isLoading } =
    useTransferInventory(transferRequest)
  const handleCloseOpen = (): void => {
    setOpen(false)
  }

  const acceptRequest = (): void => {
    acceptTransferRequest(handleCloseOpen)
  }

  const rejectRequest = (): void => {
    rejectTransferRequest(handleCloseOpen)
  }

  return (
    <>
      <DropdownMenuContent align='end'>
        <DropdownMenuItem onClick={acceptRequest} disabled={isLoading}>
          <Text>
            <Check className={'h-[1rem] text-success'} />
          </Text>
          <Text>Accept Transfer</Text>
        </DropdownMenuItem>

        <DropdownMenuItem onClick={rejectRequest} disabled={isLoading}>
          <Text>
            <Trash2 className={'h-[1rem] text-destructive'} />
          </Text>
          <Text>Reject Transfer</Text>
        </DropdownMenuItem>
      </DropdownMenuContent>
      <LoadingDialog key={'lease'} modal defaultOpen={false} open={isLoading} />
    </>
  )
}
