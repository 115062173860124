import { Seeds } from 'components/Auth/seedDealer'
import { ChangeSeedPassword } from 'features/vendor/seed-dealer/profile/change-password/routes/ChangeMerchantPassword'
import { Navigate } from 'react-router-dom'
import { ServiceProviderProfile } from '../features/profile/routes/ServiceProvider'
import { SeedsVoucherRedemption } from '../features/merchant/seed-dealer/routes/Redemption'
import { SearchEligibility } from '../features/merchant/components/SearchEligibility'
import { SeedsReports } from '../features/merchant/seed-dealer/routes/Reports'

export const seeddealerRoutes = {
  path: '/seed-dealer',
  element: <Seeds />,
  children: [
    { index: true, element: <SearchEligibility route={'/seed-dealer/redeem'} /> },
    { path: '/seed-dealer/profile', element: <ServiceProviderProfile /> },
    { path: '/seed-dealer/change-password', element: <ChangeSeedPassword /> },
    { path: '/seed-dealer/redeem', element: <SeedsVoucherRedemption /> },
    { path: '/seed-dealer/reports', element: <SeedsReports /> },
    { path: '/seed-dealer/*', element: <Navigate to={'/seed-dealer'} /> },
    { path: '*', element: <Navigate to={'.'} /> }
  ]
}
