import React from 'react'
import { Button, Dialog, DialogContent, Text } from 'components/ui'
import { type Lease } from 'types'
import { Separator } from 'components/ui/separator'
import { TerminationRequest } from '../renewal-and-termination/TerminationRequest'

interface TerminateLeaseProps {
  lease: Lease
}
export const TerminateLease: React.FC<TerminateLeaseProps> = ({ lease }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)

  const handleOpenDialog = (): void => {
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  const isTerminationRequest =
    lease.status_desc === 'TERMINATION INITIATED BY LESSOR' ||
    lease.status_desc === 'TERMINATION INITIATED BY LESSEE'
  const cannotTerminate =
    lease.status_desc !== 'ACCEPTED BY LESSEE' &&
    lease.status_desc !== 'VERIFIED BY EXTENSION OFFICER' &&
    lease.status_desc !== 'RENEWAL ACCEPTED BY LESSOR'

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'flex flex-col items-start gap-4 md:gap-6 lg:gap-8'}>
        <Text size={'large'}>Terminate Farm Lease</Text>
        <Separator />
        <Text variant={'bodyTextLight'} size={'body'}>
          By clicking "Terminate" below, I acknowledge that I am relinquishing my rights and access
          to the farm <span className={'text-primary'}>{lease.farm_name}</span>.
        </Text>
        {isTerminationRequest ? (
          <Text variant={'bodyTextLight'} size={'body'}>
            {' '}
            Lease termination request awaiting approval from:{' '}
            <span className={'text-primary'}>{lease.origin_farmer_name}</span>
          </Text>
        ) : null}
        <div className={'flex flex-col items-start'}>
          <Button
            disabled={isTerminationRequest || cannotTerminate}
            variant={'destructive'}
            size={'sm'}
            onClick={handleOpenDialog}
          >
            Terminate
          </Button>
        </div>
      </div>

      <Dialog
        key={'terminate-lease'}
        open={openDialog}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <TerminationRequest lease={lease} next={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
