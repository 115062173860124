import { type ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from 'config/firebase'

export const signInWithNumber = async (number: string): Promise<ConfirmationResult> => {
  if (window.recaptchaVerifier === null || typeof window.recaptchaVerifier === 'undefined') {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      { size: 'invisible' },
      auth
    )
  } else {
    await window.recaptchaVerifier.render().then((widgetId) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      window.grecaptcha.reset(widgetId)
    })
  }

  const { recaptchaVerifier } = window

  return signInWithPhoneNumber(auth, number, recaptchaVerifier)
}
