import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import React from 'react'
import { Text } from '../../../../components/Elements/Text'

interface AccordionSection {
  label: string
  children: React.ReactNode
  fields?: string[]
  fieldErrors?: Record<string, any>
}

interface CustomAccordionProps {
  sections: AccordionSection[]
}

export const CustomAccordion: React.FC<CustomAccordionProps> = ({ sections }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const hasErrorInSection = (
    sectionFields: string[] | undefined,
    errors: Record<string, any>
  ): boolean => {
    return sectionFields?.some((field) => Boolean(errors[field])) as boolean
  }

  return (
    <>
      {sections.map((section, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Text
              size={'medium'}
              variant={
                hasErrorInSection(section?.fields, section.fieldErrors ?? {})
                  ? 'error'
                  : 'bodyTextLight'
              }
              className={`capitalize`}
            >
              {section.label}
            </Text>
          </AccordionSummary>
          <AccordionDetails>
            <div className='w-full'>{section.children}</div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  )
}
