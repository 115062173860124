import type React from 'react'
import { Button } from 'components/ui'

export const Error: React.FC = () => {
  return (
    <main className={'flex flex-col gap-4 justify-center items-center'} role='alert'>
      <h1 className='text-lg font-semibold'>Oops, something went wrong :( </h1>
      <p className='text-md'>An unexpected error has occurred.</p>
      <Button
        onClick={() => {
          window.location.reload()
        }}
        variant={'primary'}
        size={'xs'}
      >
        Refresh
      </Button>
    </main>
  )
}
