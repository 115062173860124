import type React from 'react'
import { type GetInventoryRes, type Tractor } from '../types'
import { Button, Text } from 'components/ui'
import { LabInventoryStatus } from '../../../mechanisationOfficer/inventory/components/LabInventoryStatus'
import dayjs from 'dayjs'

interface InventoryCardProps {
  inventoryData: GetInventoryRes
  inventoryItem: Tractor
  onClose?: () => void
  isMerchant?: boolean
  isTransfer?: boolean
}

export const ViewTractor: React.FC<InventoryCardProps> = ({
  inventoryData,
  inventoryItem,
  onClose,
  isMerchant,
  isTransfer
}) => {
  return (
    <div className={'w-full flex-col gap-4 md:gap-8'}>
      <div className={'mb-4 flex justify-between items-center'}>
        <Text size={'medium'}>Tractor</Text>
        <Text className={'flex gap-2 items-center'}>
          <LabInventoryStatus inventory={inventoryData} className={'flex items-center gap-4'} />
        </Text>
      </div>

      <div className={'w-full flex flex-wrap gap-4'}>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Registration Number</Text>
          <Text>{inventoryData?.reg_number}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Year Of Manufacture</Text>
          <Text>{dayjs(inventoryData?.yom).format('YYYY')}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Make</Text>
          <Text>{inventoryItem?.make}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Model</Text>
          <Text>{inventoryItem?.model}</Text>
        </div>

        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Tractor Power</Text>
          <Text>{inventoryItem?.tractor_power_hp ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Drive</Text>
          <Text>{inventoryItem?.drive ?? 'N/A'}</Text>
        </div>
        <div className={'flex flex-col flex-[1_0_150px]'}>
          <Text variant={'muted'}>Status</Text>
          <Text>{inventoryData.item_status}</Text>
        </div>
      </div>
      {isMerchant === false && (
        <div className={'flex w-full justify-end'}>
          <Button className={'w-full sm:w-[18rem] '} onClick={onClose}>
            Close
          </Button>
        </div>
      )}
    </div>
  )
}
