import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createInventoryItem } from '../api/createInventoryItem'
import { updateInventoryItem } from '../api/updateInventoryItem'
import { type ApiResponse } from 'types'
import { AxiosError, type AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { type CreateInventoryItemDTO, type UpdateInventoryItemDTO } from '../types'
import { useNavigate } from 'react-router-dom'
import { type UseMutationResult } from 'react-query'
import { useFarmerStore } from '../../../../../../stores/useFarmerStore'

interface UseInventoryItemParams {
  isEdit: boolean
}

export const createUpateInventoryItem = ({
  isEdit
}: UseInventoryItemParams): UseMutationResult<
  ApiResponse<[]>,
  unknown,
  CreateInventoryItemDTO | UpdateInventoryItemDTO
> => {
  const { farmer } = useFarmerStore()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: CreateInventoryItemDTO | UpdateInventoryItemDTO) => {
      if (isEdit) {
        return updateInventoryItem(params as UpdateInventoryItemDTO)
      } else {
        return createInventoryItem(params as CreateInventoryItemDTO)
      }
    },
    onSuccess: (result: ApiResponse<[]>) => {
      if (result.status === 201 && result.success) {
        toast.success('Inventory item submitted successfully!')
        void queryClient.invalidateQueries({ queryKey: [`inventory-${Number(farmer?.farmer_id)}`] })
        navigate('/service-provider/inventory')
      }
    },
    onError: (err: unknown) => {
      if (err instanceof AxiosError) {
        const error = err.response as AxiosResponse<ApiResponse<[]>>
        toast.error(
          mapCmsErrorToMessage(error?.status?.toString() ?? err.code ?? error.data.message ?? '')
        )
      } else {
        const error = err as Error
        toast.error(mapCmsErrorToMessage(error.message))
      }
    }
  })
}
