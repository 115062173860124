import type React from 'react'
import { useEffect, useState } from 'react'
import { BasicButton } from 'components/Elements/Button'
import { useNavigate } from 'react-router-dom'
import { LoanElligibility } from './LoanElligibility'
import { Disclaimer } from './Disclaimer'
import { useGetFarms } from '../../../../farmer/land-management/api/getFarms'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useGetCroppingPlans } from '../../../api/getCroppingPlans'
import { Spinner } from '../../../../../components/Elements/Spinner'

interface ModalStepperProps {
  onClose: () => void
}

export const ModalStepper: React.FC<ModalStepperProps> = ({ onClose }) => {
  const { farmer } = useFarmerStore()
  const [activeStep, setActiveStep] = useState<number>(1)
  const [isEligible, setIsEligible] = useState<boolean>(true)
  const [notEligibleReasons, setNotEligibleReasons] = useState<string[]>()
  const navigate = useNavigate()

  const { data: farms, isLoading: farmsLoading } = useGetFarms(farmer?.farmer_id)
  const { data: croppingPlans, isLoading: croppingPlansLoading } = useGetCroppingPlans(
    farmer?.farmer_id as number
  )

  // Consider both types of data to be loading
  const isLoading = farmsLoading || croppingPlansLoading

  useEffect(() => {
    if (!farmsLoading && !croppingPlansLoading) {
      // Initialize eligibility status
      let eligibility = true
      const reasons: string[] = []

      // Check for farms existence and verification
      if (farms == null || farms.data.length === 0) {
        eligibility = false
        reasons.push('You do not have any farms.')
      } else if (farms.data.length === 1 && farms.data[0].status !== 'verified') {
        eligibility = false
        reasons.push('Your farm is not verified.')
      }

      // Check for cropping plans existence and verification
      if (croppingPlans == null || croppingPlans.data.length === 0) {
        eligibility = false
        reasons.push('You do not have any cropping plans.')
      } else if (
        croppingPlans.data.length === 1 &&
        croppingPlans.data[0].is_verified !== 'Approved'
      ) {
        eligibility = false
        reasons.push('Your cropping plan is not approved.')
      }

      setIsEligible(eligibility)
      setNotEligibleReasons(reasons)
    }
  }, [farms, croppingPlans, farmsLoading, croppingPlansLoading])

  const handleNext = (): void => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handlePrev = (): void => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const onSubmit = (): void => {
    navigate('/farmer/loans/newloan')
  }

  return (
    <div className='w-96 flex flex-col items-center p-6'>
      {isLoading ? (
        <div className='flex justify-center items-center h-full'>
          <Spinner size={'lg'} /> {/* Adjust as necessary for your Spinner component */}
        </div>
      ) : (
        <>
          {activeStep === 1 && (
            <div className='w-full flex flex-col gap-8'>
              <LoanElligibility isEligible={isEligible} notEligibleReasons={notEligibleReasons} />
              <div className='w-full flex justify-between'>
                <BasicButton label={'Cancel'} variant={'secondary'} size={'xs'} onClick={onClose} />
                <BasicButton
                  label={'Continue'}
                  variant={'primary'}
                  size={'xs'}
                  onClick={handleNext}
                  disabled={!isEligible}
                />
              </div>
            </div>
          )}
          {activeStep === 2 && <Disclaimer handleNext={onSubmit} handlePrev={handlePrev} />}
        </>
      )}
    </div>
  )
}
