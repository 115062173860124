import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type SoilSampleRequest } from 'types'

export const getRequests = async (
  merchantId: number | null | undefined
): Promise<ApiResponse<SoilSampleRequest>> => {
  return axios.get(`ssr?merchant_id=${merchantId ?? ''}`)
}

export const useGetRequests = (
  merchantId: number | null | undefined
): UseQueryResult<ApiResponse<SoilSampleRequest>, AxiosError<[]>> => {
  return useQuery({
    queryKey: ['soil-sample-requests', merchantId],
    queryFn: async () => getRequests(merchantId),
    enabled: merchantId != null
  })
}
