import { type Table } from '@tanstack/react-table'

import type React from 'react'
import { Input } from '../ui'

interface DataTableColumnHeaderProps<TData> {
  table: Table<TData>
  placeHolder: string
  columnId: string
}

export function DataTableColumnFilter<TData>({
  table,
  placeHolder,
  columnId
}: DataTableColumnHeaderProps<TData>): React.ReactNode {
  return (
    <Input
      placeholder={`${placeHolder}...`}
      value={(table.getColumn(columnId)?.getFilterValue() as string) ?? ''}
      onChange={(event) => table.getColumn(columnId)?.setFilterValue(event.target.value)}
      className='max-w-sm'
    />
  )
}
