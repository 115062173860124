import {
  RejectCroppingPlan,
  type RejectReasonInput
} from 'features/officer/cropping-plan/components/reject/RejectCroppingPlan'
import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'

interface RejectCroppingPlanModalProps {
  header: CroppingPlan
  onClose: () => void
  rejectCroppingPlan: (data: RejectReasonInput) => void
  rejectFarm: (data: RejectReasonInput) => void
  isLoading: boolean
  className: string
}

export const RejectCroppingPlanModal: React.FC<RejectCroppingPlanModalProps> = ({
  header,
  onClose,
  rejectFarm,
  rejectCroppingPlan,
  isLoading,
  className
}) => {
  if (header.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment) {
    return (
      <div className={className}>
        <RejectCroppingPlan
          label={'Farm Assessment'}
          onReject={rejectFarm}
          onCancel={onClose}
          isLoading={isLoading}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <RejectCroppingPlan
        label={'Cropping Plan'}
        onReject={rejectCroppingPlan}
        onCancel={onClose}
        isLoading={isLoading}
      />
    </div>
  )
}
