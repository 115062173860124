import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { type InitiateTransferDTO, type InventoryTransfer } from '../types'
import { acceptTransfer, initateTransfer } from '../api/initiateTransfer'
import { AxiosError, type AxiosResponse } from 'axios'
import { toast } from '../../../../../../components/ui'
import { useLeaseStore } from '../../../../../farmer/land-management/stores/leaseStore'
import { useFarmerStore } from '../../../../../../stores/useFarmerStore'
import { type ApiResponse } from '../../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../../utils/apiErrors'

interface UseTranserInventory {
  isLoading: boolean
  initiateNewTransfer: (transfer: InitiateTransferDTO, next: () => void) => void
  acceptTransferRequest: (next: () => void) => void
  rejectTransferRequest: (next: () => void) => void
}

export const useTransferInventory = (transferRequest?: InventoryTransfer): UseTranserInventory => {
  const queryClient = useQueryClient()
  const { setLessorDetails, farmerID } = useLeaseStore()
  const [isLoading, setIsLoading] = useState(false)
  const { farmer } = useFarmerStore()
  const createInventoryTransferMutation = useMutation({
    mutationFn: async (transfer: InitiateTransferDTO) => {
      return initateTransfer(transfer)
    }
  })

  const acceptInventoryTransferMutation = useMutation({
    mutationFn: async (transfer: InitiateTransferDTO) => {
      return acceptTransfer(transfer)
    }
  })

  const initiateNewTransfer = (transfer: InitiateTransferDTO, next: () => void): void => {
    createInventoryTransferMutation.mutate(
      {
        inventory_id: transfer.inventory_id,
        message: 'Transfer Initiated',
        dest_merchant_id: parseInt(farmerID ?? '0'),
        origin_merchant_id: Number(farmer?.farmer_id),
        transaction_status: 1,
        last_update_by: Number(farmer?.user_id)
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Your transfer request has been created successfully.'
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const acceptTransferRequest = (next: () => void): void => {
    acceptInventoryTransferMutation.mutate(
      {
        inventory_id: Number(transferRequest?.inventory_id),
        message: 'Transfer accepted',
        dest_merchant_id: Number(transferRequest?.dest_merchant_id),
        origin_merchant_id: Number(transferRequest?.origin_merchant_id),
        transaction_status: 2,
        last_update_by: Number(farmer?.user_id)
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Transfer Request Accepted'
            })
            void queryClient.invalidateQueries({
              queryKey: [`inventories`, farmer?.farmer_id]
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const rejectTransferRequest = (next: () => void): void => {
    acceptInventoryTransferMutation.mutate(
      {
        inventory_id: Number(transferRequest?.inventory_id),
        message: 'Transfer Rejected',
        dest_merchant_id: Number(transferRequest?.dest_merchant_id),
        origin_merchant_id: Number(transferRequest?.origin_merchant_id),
        transaction_status: 3,
        last_update_by: Number(farmer?.user_id)
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Transfer Request Rejected'
            })
            void queryClient.invalidateQueries({
              queryKey: [`inventories`, farmer?.farmer_id]
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    isLoading:
      isLoading ||
      createInventoryTransferMutation.isLoading ||
      acceptInventoryTransferMutation.isLoading,
    initiateNewTransfer,
    acceptTransferRequest,
    rejectTransferRequest
  }
}
