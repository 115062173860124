import { AppProvider } from 'providers/app'
import type React from 'react'
import { useEffect } from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { UnderMaintenance } from '../components/Errors/UnderConstruction'

export const App: React.FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.maintenance === true) {
    return <UnderMaintenance />
  }

  return <AppProvider />
}
