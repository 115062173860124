import { type CroppingPlan, type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getCroppingPlans = async (farmerId: number): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(`/production/header?farmer_id=${farmerId}`)
}

export const useGetCroppingPlans = (
  farmerId: number
): UseQueryResult<ApiResponse<CroppingPlan>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farmerId', farmerId],
    queryFn: async () => getCroppingPlans(farmerId)
  })
}
