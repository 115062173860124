import { type ColumnDef } from '@tanstack/react-table'
import moment from 'moment'

import { type GetInventoryRes } from 'features/farmer/inventory/types'
import { LabInventoryStatus } from '../LabInventoryStatus'
import { Text } from '../../../../../components/ui'

export const inventoryColumns: Array<ColumnDef<GetInventoryRes>> = [
  {
    id: 'inventory_id',
    accessorFn: (row) => row.inventory_id,
    enableHiding: false,
    cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('inventory_id')}</Text>,
    header: 'Inventory ID'
  },
  {
    id: 'farmer_id',
    accessorFn: (row) => row.farmer_id,
    cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('farmer_id')}</Text>,
    header: 'Farmer ID'
  },
  {
    id: 'reg_number',
    accessorFn: (row) => row.reg_number,
    cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('reg_number')}</Text>,
    header: 'Registration Number'
  },
  {
    id: 'yom',
    accessorFn: (row) => row.yom,
    cell: ({ row }) => (
      <Text variant={'colorText'}>{moment(row.getValue('yom')).format('DD-MM-YYYY hh:mm a')}</Text>
    ),
    header: 'Create Date'
  },
  {
    id: 'status',
    accessorFn: (row) => row.item_verification_status,
    cell: ({ row }) => (
      <LabInventoryStatus inventory={row.original} className={'flex items-center gap-4'} />
    ),
    header: 'Status'
  }
]
