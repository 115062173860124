import { type ApiResponse, type Farm } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getFarm = async (farmId: number, farmerId: number): Promise<ApiResponse<Farm>> => {
  return axios.get(`/farm?FARM_ID=${farmId}&FARMER_ID=${farmerId}`)
}

export const useGetFarm = (
  farmId: number,
  farmerId: number
): UseQueryResult<ApiResponse<Farm>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['farm', farmId, farmerId],
    queryFn: async () => getFarm(farmId, farmerId)
  })
}
