import { Step, StepLabel, Stepper } from '@mui/material'
import type React from 'react'
import { Text } from 'components/ui'
interface StepperProps {
  steps: string[]
  activeStep: number
  className?: string
}

export const CustomStepper: React.FC<StepperProps> = ({ activeStep, steps, className }) => {
  return (
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    <Stepper className={`w-full ${className}`} activeStep={activeStep} alternativeLabel>
      {steps.map((label) => {
        return (
          <Step
            key={label}
            sx={{
              width: '100%',
              '& .MuiStepLabel-root .Mui-completed': {
                color: '#4CAE4FFF'
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                color: '#1F1F1FFF'
              }
            }}
          >
            <StepLabel>
              <Text size={'small'}>{label}</Text>
            </StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}
