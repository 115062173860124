export interface Bank {
  bank_id: number
  bank_name: string
  bank_branch_id: number
  bank_branch_name: string
}

export const Banks: Bank[] = [
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 101,
    bank_branch_name: 'Retail Head Office'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 102,
    bank_branch_name: 'The Square Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 103,
    bank_branch_name: 'Fair Ground Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 104,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 105,
    bank_branch_name: 'Game City Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 106,
    bank_branch_name: 'Airport Junction Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 107,
    bank_branch_name: 'Maun Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 108,
    bank_branch_name: 'Selebi Phikwe'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 109,
    bank_branch_name: 'Palapye Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 110,
    bank_branch_name: 'Private Banking'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 111,
    bank_branch_name: 'Kanye Service Centre'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 112,
    bank_branch_name: 'Molepolole Service Centre'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 113,
    bank_branch_name: 'Letlhakane Service Centre'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 114,
    bank_branch_name: 'Ghanzi Service Centre'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 115,
    bank_branch_name: 'Head Office'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 116,
    bank_branch_name: 'Item Processing'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 117,
    bank_branch_name: 'Insurance Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 118,
    bank_branch_name: 'Shared Services Branch'
  },
  {
    bank_id: 3,
    bank_name: 'Bank Of Baroda Botswana',
    bank_branch_id: 119,
    bank_branch_name: 'Vehicle & Asset Finance'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 120,
    bank_branch_name: 'Airport Junction Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 121,
    bank_branch_name: 'Central Processing Centre'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 122,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 123,
    bank_branch_name: 'Gaborone Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 124,
    bank_branch_name: 'Game City Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 125,
    bank_branch_name: 'Ghanzi Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 126,
    bank_branch_name: 'Head Office'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 127,
    bank_branch_name: 'Home Loans'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 128,
    bank_branch_name: 'Main Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 129,
    bank_branch_name: 'Treasury'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 130,
    bank_branch_name: 'CBD Commercial'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 131,
    bank_branch_name: 'Kasane Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 132,
    bank_branch_name: 'Legal'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 133,
    bank_branch_name: 'Mahalapye Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 134,
    bank_branch_name: 'Maun Branch'
  },
  {
    bank_id: 4,
    bank_name: 'Bank Of Botswana (Central Reserve Bank)',
    bank_branch_id: 135,
    bank_branch_name: 'Palapye Branch'
  },
  {
    bank_id: 5,
    bank_name: 'ABSA Bank Botswana (prev. Barclays)',
    bank_branch_id: 136,
    bank_branch_name: 'All Branches'
  },
  {
    bank_id: 5,
    bank_name: 'ABSA Bank Botswana (prev. Barclays)',
    bank_branch_id: 137,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 5,
    bank_name: 'ABSA Bank Botswana (prev. Barclays)',
    bank_branch_id: 138,
    bank_branch_name: 'Gaborone West Branch'
  },
  {
    bank_id: 5,
    bank_name: 'ABSA Bank Botswana (prev. Barclays)',
    bank_branch_id: 139,
    bank_branch_name: 'Palapye Branch'
  },
  {
    bank_id: 6,
    bank_name: 'First Capital Bank Botswana',
    bank_branch_id: 140,
    bank_branch_name: 'Gaborone Branch'
  },
  {
    bank_id: 6,
    bank_name: 'First Capital Bank Botswana',
    bank_branch_id: 141,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 142,
    bank_branch_name: 'Gaborone Mall Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 143,
    bank_branch_name: 'Barclays House Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 144,
    bank_branch_name: 'Gaborone Industrial Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 145,
    bank_branch_name: 'Broadhurst Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 146,
    bank_branch_name: 'Merafhe/Gemvas Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 147,
    bank_branch_name: 'BOFEX Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 148,
    bank_branch_name: 'Carbo Centre Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 149,
    bank_branch_name: 'Lobatse Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 150,
    bank_branch_name: 'Orapa Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 151,
    bank_branch_name: 'Jwaneng Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 152,
    bank_branch_name: 'Ghanzi Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 153,
    bank_branch_name: 'Kanye Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 154,
    bank_branch_name: 'Mahalapye Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 155,
    bank_branch_name: 'Serowe Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 156,
    bank_branch_name: 'Palapye Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 157,
    bank_branch_name: 'Selibe Phikwe Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 158,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 159,
    bank_branch_name: 'Tutune Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 160,
    bank_branch_name: 'Maun Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 161,
    bank_branch_name: 'Kasane Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 162,
    bank_branch_name: 'Tsabong Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 163,
    bank_branch_name: 'Masunga Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 164,
    bank_branch_name: 'Bobonong Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 165,
    bank_branch_name: 'Mochudi Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 166,
    bank_branch_name: 'Molepolole Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 167,
    bank_branch_name: 'Tlokweng Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 168,
    bank_branch_name: 'Ramotswa Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 169,
    bank_branch_name: 'Moshupa Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 170,
    bank_branch_name: 'Mogoditshane Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 171,
    bank_branch_name: 'Sowa Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 172,
    bank_branch_name: 'Gumare Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 173,
    bank_branch_name: 'Shakawe Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 174,
    bank_branch_name: 'Airport Junction Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 175,
    bank_branch_name: 'Game City Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 176,
    bank_branch_name: 'Phakalane Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 177,
    bank_branch_name: 'Galo Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 178,
    bank_branch_name: 'Gaborone Station Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 179,
    bank_branch_name: 'Letlhakane Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 180,
    bank_branch_name: 'Galagwe Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 181,
    bank_branch_name: 'Tati Nickel Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 182,
    bank_branch_name: 'Personal Prestige Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 183,
    bank_branch_name: 'Selibe Phikwe Prestige Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 184,
    bank_branch_name: 'Blue Jacket Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 185,
    bank_branch_name: 'Gaborone Savings Branch'
  },
  {
    bank_id: 7,
    bank_name: 'First National Bank Botswana',
    bank_branch_id: 186,
    bank_branch_name: 'Head Office'
  },
  {
    bank_id: 8,
    bank_name: 'Stanbic Bank Botswana',
    bank_branch_id: 187,
    bank_branch_name: 'Head Office'
  },
  {
    bank_id: 8,
    bank_name: 'Stanbic Bank Botswana',
    bank_branch_id: 188,
    bank_branch_name: 'Main Branch'
  },
  {
    bank_id: 8,
    bank_name: 'Stanbic Bank Botswana',
    bank_branch_id: 189,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 8,
    bank_name: 'Stanbic Bank Botswana',
    bank_branch_id: 190,
    bank_branch_name: 'Broadhurst Branch'
  },
  {
    bank_id: 8,
    bank_name: 'Stanbic Bank Botswana',
    bank_branch_id: 191,
    bank_branch_name: 'Mogoditshane Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 192,
    bank_branch_name: 'Broadhurst Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 193,
    bank_branch_name: 'Gaborone Main Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 194,
    bank_branch_name: 'West Bank Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 195,
    bank_branch_name: 'Gaborone Industrial Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 196,
    bank_branch_name: 'Lobatse Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 197,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 198,
    bank_branch_name: 'Kanye Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 199,
    bank_branch_name: 'Head Office'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 200,
    bank_branch_name: 'Treasury'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 201,
    bank_branch_name: 'Corporate'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 202,
    bank_branch_name: 'Maun Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 203,
    bank_branch_name: 'Mahalapye Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 204,
    bank_branch_name: 'Gaborone Mall Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 205,
    bank_branch_name: 'FNB Airport Junction Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 206,
    bank_branch_name: 'Jwaneng Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 207,
    bank_branch_name: 'Palapye Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 208,
    bank_branch_name: 'West Bank Corporate'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 209,
    bank_branch_name: 'WesBank Motor Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 210,
    bank_branch_name: 'WesBank Francistown Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 211,
    bank_branch_name: 'Internet Tansfers'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 212,
    bank_branch_name: 'Code Line Clearing'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 213,
    bank_branch_name: 'Kgale View Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 214,
    bank_branch_name: 'First Funding Motor Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 215,
    bank_branch_name: 'Selebi Phikwe Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 216,
    bank_branch_name: 'Kasane Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 217,
    bank_branch_name: 'Riverwalk Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 218,
    bank_branch_name: 'Serowe Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 219,
    bank_branch_name: 'Private Clients Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 220,
    bank_branch_name: 'Letlhakane Branch'
  },
  {
    bank_id: 9,
    bank_name: 'Standard Chartered Botswana',
    bank_branch_id: 221,
    bank_branch_name: 'Molepolole Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 222,
    bank_branch_name: 'Acacia Mall Phakalane'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 223,
    bank_branch_name: 'Airport Junction Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 224,
    bank_branch_name: 'Blue Jacket Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 225,
    bank_branch_name: 'CBD Square Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 226,
    bank_branch_name: 'Diamond Park Service Centre'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 227,
    bank_branch_name: 'Fair grounds Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 228,
    bank_branch_name: 'Francistown Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 229,
    bank_branch_name: 'Gaborone Industrial Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 230,
    bank_branch_name: 'Gaborone West Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 231,
    bank_branch_name: 'Head Office'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 232,
    bank_branch_name: 'Kazungula Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 233,
    bank_branch_name: 'Letlhakane Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 234,
    bank_branch_name: 'Maun Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 235,
    bank_branch_name: 'Mogoditshane Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 236,
    bank_branch_name: 'Molepolole Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 237,
    bank_branch_name: 'Palapye Branch'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 238,
    bank_branch_name: 'Selebi Phikwe'
  },
  {
    bank_id: 10,
    bank_name: 'State Bank Of India Botswana',
    bank_branch_id: 239,
    bank_branch_name: 'Stannic Branch'
  }
]
