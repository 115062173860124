import { BasicButton } from 'components/Elements/Button'
import { Form, InputField } from 'components/Form'
import type React from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { object, string, type TypeOf } from 'zod'

interface RejectCroppingPlanProps {
  label: string
  onReject: (data: RejectReasonInput) => void
  onCancel: () => void
  isLoading: boolean
}

const schema = object({
  reason: string().nonempty('Surname is required')
})

export type RejectReasonInput = TypeOf<typeof schema>

export const RejectCroppingPlan: React.FC<RejectCroppingPlanProps> = ({
  label,
  onReject,
  isLoading,
  onCancel
}) => {
  const onSubmit: SubmitHandler<RejectReasonInput> = (data: RejectReasonInput) => {
    onReject(data)
  }

  return (
    <Form<RejectReasonInput, typeof schema>
      className={'flex flex-col gap-6 justify-center items-center'}
      onSubmit={onSubmit}
      schema={schema}
    >
      {({ control, register, formState: { errors }, handleSubmit }) => (
        <>
          <div className={'flex text-center pt-5 pb-5 text-xl font-bold'}>Reject {label}</div>
          <div className={'flex items-center pb-10 w-4/5 text-center'}>
            <p>Enter the reason why the {label} is being rejected to proceed.</p>
          </div>
          <InputField
            label={'Reason'}
            inputMode={'text'}
            type={'textarea'}
            error={!(errors.reason == null)}
            helperText={errors?.reason?.message ?? ''}
            register={register('reason')}
          />
          <div className={'flex justify-around w-full gap-12 lg:w-[80%]'}>
            <BasicButton
              variant={'secondary'}
              label={'Cancel'}
              onClick={onCancel}
              isDisabled={isLoading}
              size={'xs'}
            />
            <BasicButton
              textColor={'#fff'}
              color={'error'}
              label={'Confirm'}
              type={'submit'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              isDisabled={isLoading}
              size={'xs'}
            />
          </div>
        </>
      )}
    </Form>
  )
}
