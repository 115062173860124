import { axios } from 'lib/axios'
import { type ApiResponse, type CroppingPlan } from 'types'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getCroppingPlanList = async (
  farmId: string | number | null | undefined,
  farmerId: string | null | undefined,
  extArea: number | null | undefined
): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(
    `/production/header?farmer_id=${farmerId ?? ''}&extension_area_id=${extArea ?? ''}&farm_id=${
      farmId ?? ''
    }`
  )
}

export const useGetCroppingPlanList = (
  farmId: string | number | null | undefined,
  farmerId: string | null | undefined,
  extArea: number | null | undefined
): UseQueryResult<ApiResponse<CroppingPlan>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['cropping-plan-list', farmId, farmerId, extArea],
    queryFn: async () => getCroppingPlanList(farmId, farmerId, extArea),
    enabled: farmId != null && farmerId != null && extArea != null
  })
}
