import { type ColumnDef } from '@tanstack/react-table'
import { type Grant } from '../../../../../types'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'

export const columns: Array<ColumnDef<Grant>> = [
  {
    accessorKey: 'farmer_id',
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Farmer ID' />,
    cell: ({ row }) => <div className='lowercase'>{row.getValue('farmer_id')}</div>
  },
  {
    accessorKey: 'contact',
    header: 'Contact'
  },
  {
    accessorKey: 'available_size',
    enableHiding: false,
    header: 'Hectarage'
  },
  {
    accessorKey: 'total_costs',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Total Cost' />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('total_costs'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]
