import moment from 'moment'
import type React from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { Box, TabView } from 'components/App/Layout'
import { AvatarFallback, Avatar, Text, AvatarImage, Button } from 'components/ui'
import { ArrowLeft } from 'lucide-react'
import farmerIcon from 'assets/logos/farmer.png'
import { useNavigate } from 'react-router-dom'
interface InfoProps {
  label: string
  name: string
}
const Info: React.FC<InfoProps> = ({ label, name }) => {
  return (
    <div className={'flex flex-col'}>
      <Text size={'small'} className={'ml-1 capitalize'} variant={'primary'}>
        {label}
      </Text>
      <Text size={'medium'} className={label !== 'email address' ? 'capitalize' : ''}>
        {name}
      </Text>
    </div>
  )
}
export const Profile: React.FC = () => {
  const navigate = useNavigate()
  const { farmer } = useFarmerStore()

  const handleBack = (): void => {
    navigate(-1)
  }
  return (
    <Box>
      <div className={'flex items-center gap-2 mt-2 sm:mt-4'}>
        <Button
          onClick={handleBack}
          variant={'ghost'}
          className={
            'bg-transparent gap-2 py-1 text-[clamp(1.3rem,4vw,1.5rem)] leading-[70%] font-semibold capitalize'
          }
        >
          <ArrowLeft />
        </Button>
        <Text size={'large'}>My profile</Text>
      </div>

      <TabView>
        <div className={'flex gap-4 items-center'}>
          <Avatar className={'h-[clamp(3rem,4vw,4rem)] w-[clamp(3rem,4vw,4rem)]'}>
            <AvatarImage src={farmerIcon} />
            <AvatarFallback>
              <Text size={'medium'} className='fa-solid fa-user text-success'></Text>
            </AvatarFallback>
          </Avatar>
          <div className={'flex flex-col'}>
            <Text size={'small'} className={'capitalize'}>
              farmer ID
            </Text>
            <Text size={'medium'} variant={'primary'}>
              {farmer?.farmer_id}
            </Text>
          </div>
        </div>
        <div className={'flex flex-col border-y border-input py-4'}>
          <Text size={'medium'} variant={'muted'}>
            Personal Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'Surname'} name={farmer?.surname ?? ''} />
            <Info label={'Forename/S'} name={farmer?.firstname ?? ''} />
            <Info
              label={'Date Of Birth'}
              name={moment(farmer?.individual_dob).format('DD-MM-YYYY')}
            />
            <Info label={'place of birth'} name={farmer?.individual_pob ?? ''} />
            <Info label={'omang/ID number'} name={farmer?.omang ?? ''} />
            <Info
              label={'Expiry date'}
              name={moment(farmer?.individual_expiry_date).format('DD-MM-YYYY')}
            />
            <Info label={'gender'} name={farmer?.individual_gender ?? ''} />
          </div>
        </div>
        <div className={'flex flex-col'}>
          <Text size={'medium'} variant={'muted'}>
            Contact Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'email address'} name={farmer?.email.toLowerCase() ?? ''} />
            <Info label={'phone'} name={farmer?.contact ?? ''} />
            <Info label={'Physical Address'} name={farmer?.physical_addr ?? 'N/A'} />
            <Info label={'Postal Address'} name={farmer?.postal_addr ?? 'N/A'} />
          </div>
        </div>
      </TabView>
    </Box>
  )
}
