import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import type React from 'react'
import { type Control, Controller } from 'react-hook-form'
import { Text } from 'components/Elements/Text/Text'
export interface RadioOptions<T> {
  label: string
  value: T
}

interface CustomRadioProps {
  label: string
  name: string
  options: Array<RadioOptions<string | number | boolean>> | string[]
  defaultValue: string | number | boolean
  control?: Control<any, any>
  error?: boolean
  errorMessage?: string
}

export const CustomRadio: React.FC<CustomRadioProps> = ({
  label,
  control,
  options,
  defaultValue,
  error,
  name,
  errorMessage
}) => {
  return (
    <FormControl error={error}>
      <FormLabel>
        <Text>{label}</Text>
      </FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { ref, onChange, value, ...field } }) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <RadioGroup value={value} onChange={onChange} {...field}>
            {options.map((item) => {
              return (
                <FormControlLabel
                  key={typeof item === 'string' ? item : item.label}
                  control={<Radio />}
                  sx={{ display: 'flex' }}
                  label={<Text>{typeof item === 'string' ? item : item.label}</Text>}
                  value={typeof item === 'string' ? item : item.value}
                />
              )
            })}
          </RadioGroup>
        )}
      />
      <FormHelperText>
        <Text variant={'error'}>{errorMessage ?? ''}</Text>
      </FormHelperText>
    </FormControl>
  )
}
