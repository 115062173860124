import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'

interface CroppingPlanTableProps {
  croppingPlans: CroppingPlan[]
  onClick: (item: CroppingPlan) => void
}

export const CroppingPlanTable: React.FC<CroppingPlanTableProps> = ({ croppingPlans, onClick }) => {
  return (
    <Table>
      <TableCaption>List of cropping plans pending verification.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>Farmer ID</TableHead>
          <TableHead className='font-bold text-color-text'>Plough Season</TableHead>
          <TableHead className='font-bold text-color-text'>Crop Name</TableHead>
          <TableHead className='font-bold text-color-text'>Family Name</TableHead>
          <TableHead className='font-bold text-color-text'>Hectarage</TableHead>
          <TableHead className='font-bold text-color-text'>Cost</TableHead>
          <TableHead className='font-bold text-color-text'>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {croppingPlans.map((cp) => (
          <TableRow
            onClick={() => {
              onClick(cp)
            }}
            key={cp.cropping_plan_id}
            role={'listitem'}
            className={'hover:cursor-pointer hover:bg-muted-hover'}
          >
            <TableCell className='font-medium'>{cp.farmer_id}</TableCell>
            <TableCell className='font-medium'>{cp.plough_season}</TableCell>
            <TableCell className='font-medium'>{cp.crop_name}</TableCell>
            <TableCell className='font-medium'>{cp.family_name}</TableCell>
            <TableCell className='font-medium'>{cp.hectarage}</TableCell>
            <TableCell className='font-medium'>{cp.total_cost}</TableCell>
            <TableCell className='font-medium'>
              {cp.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment
                ? 'Farm Assessment'
                : 'Submitted for Review'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
