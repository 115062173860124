import type z from 'zod'
import { coerce, object } from 'zod'
import type React from 'react'
import { useState } from 'react'
import {
  Districts,
  ExtensionAreas,
  locations,
  SubDistricts
} from 'features/farmer/land-management/utils'

import { useForm } from 'react-hook-form'
import { useNewFarmStore } from 'features/farmer/land-management/stores/newFarm'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components/ui'
import { zodResolver } from '@hookform/resolvers/zod'

const schema = object({
  district: coerce
    .number({
      required_error: 'District is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'District is required'),
  subDistrict: coerce
    .number({
      required_error: 'Sub District is required',
      invalid_type_error: 'Sub District is required'
    })
    .min(1, 'Sub District is required'),
  extension: coerce
    .number({
      required_error: 'Extension is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'Extension is required'),
  village: coerce
    .number({
      required_error: 'Village is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'Village is required')
})

interface FarmLocationProps {
  onNext: () => void
  onBack: () => void
}

export const FarmLocation: React.FC<FarmLocationProps> = ({ onNext, onBack }) => {
  const { setLocationDetails, locationDetails } = useNewFarmStore()
  const [district, setDistrict] = useState<number | string>(locationDetails?.districtId ?? '')
  const [subDistrict, setSubDistrict] = useState<number | string>(
    locationDetails?.subDistrictId ?? ''
  )
  const [extensionArea, setExtensionArea] = useState<number | string>(
    locationDetails?.extensionAreaId ?? ''
  )
  const [village, setVillage] = useState<number | string>(locationDetails?.villageId ?? '')

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      district: locationDetails?.districtId ?? undefined,
      subDistrict: locationDetails?.subDistrictId ?? undefined,
      extension: locationDetails?.extensionAreaId ?? undefined,
      village: locationDetails?.villageId ?? undefined
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    setLocationDetails({
      districtId: data.district,
      extensionAreaId: data.extension,
      villageId: data.village,
      subDistrictId: data.subDistrict
    })
    onNext()
  }

  const districts = Districts.slice().sort((a, b) => {
    return a.district_name.localeCompare(b.district_name)
  })
  const subDistricts = SubDistricts.filter(
    (sub) => sub.district_id.toString() === district.toString()
  ).sort((a, b) => a.district_name.localeCompare(b.district_name))
  const extensions = ExtensionAreas.filter(
    (ext) => ext.sub_district_id.toString() === subDistrict.toString()
  ).sort((a, b) => a.extension_area_name.localeCompare(b.extension_area_name))

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className={'flex flex-col gap-4'}>
          <FormField
            control={form.control}
            name='district'
            render={({ field }) => (
              <FormItem>
                <FormLabel className={'ml-2 font-redHat'}>District</FormLabel>
                <Select
                  onValueChange={(val) => {
                    setDistrict(val)
                    setSubDistrict('')
                    setExtensionArea('')
                    setVillage('')
                    field.onChange(val)
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='district' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {districts.map((disc) => (
                      <SelectItem key={disc.district_id} value={disc.district_id.toString()}>
                        {disc.district_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='subDistrict'
            render={({ field }) => (
              <FormItem>
                <FormLabel className={'ml-2 font-redHat'}>Sub District</FormLabel>
                <Select
                  disabled={district === ''}
                  onValueChange={(val) => {
                    setSubDistrict(val)
                    setExtensionArea('')
                    setVillage('')
                    field.onChange(val)
                  }}
                  defaultValue={subDistrict.toString()}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='sub district' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {subDistricts.map((sub) => (
                      <SelectItem key={sub.sub_district_id} value={sub.sub_district_id.toString()}>
                        {sub.sub_district_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='extension'
            disabled={subDistrict === ''}
            render={({ field }) => (
              <FormItem>
                <FormLabel className={'ml-2 font-redHat'}>Extension Area</FormLabel>
                <Select
                  disabled={subDistrict === ''}
                  onValueChange={(val) => {
                    setExtensionArea(val)
                    setVillage('')
                    field.onChange(val)
                  }}
                  defaultValue={extensionArea.toString()}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='extension area' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {extensions.map((ext) => (
                      <SelectItem
                        key={ext.extension_area_id}
                        value={ext.extension_area_id.toString()}
                      >
                        {ext.extension_area_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='village'
            disabled={extensionArea === ''}
            render={({ field }) => (
              <FormItem>
                <FormLabel className={'ml-2 font-redHat'}>Village Name</FormLabel>
                <Select
                  disabled={extensionArea === ''}
                  onValueChange={(val) => {
                    setVillage(val)
                    field.onChange(val)
                  }}
                  defaultValue={village.toString()}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='village' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {locations
                      .filter((area) => {
                        return (
                          area.extension_area_id.toString() === extensionArea.toString() &&
                          area.district_id.toString() === district.toString() &&
                          area.sub_district_id.toString() === subDistrict.toString()
                        )
                      })
                      .map((vil) => (
                        <SelectItem key={vil.village_id} value={vil.village_id.toString()}>
                          {vil.village_name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className={'w-full flex justify-between gap-4'}>
          <Button variant={'secondary'} className={'w-1/2'} onClick={onBack}>
            Back
          </Button>
          <Button type={'submit'} className={'w-1/2'} onClick={form.handleSubmit(onSubmit)}>
            Next
          </Button>
        </div>
      </form>
    </Form>
  )
}
