import { CustomStepper } from 'components/Elements/Stepper'
import type React from 'react'
import { useState } from 'react'
import {
  type GetInventoryRes,
  type Implement,
  type PoweredImplement,
  type Tractor
} from '../../types'
import { TransferConfirmation } from './TransferConfirmation'
import { OriginTransferrer } from './OriginTransferrer'

const steps = ['Destination Farmer Details', 'Confirm Transfer']

interface TransferInventoryProps {
  inventoryData: GetInventoryRes
  inventoryItem: Tractor | Implement | PoweredImplement
  onClose: () => void
}

export const TransferInventory: React.FC<TransferInventoryProps> = ({
  inventoryItem,
  inventoryData,
  onClose
}) => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }
  if (activeStep === steps.length - 1) {
    return (
      <div className={'flex flex-col items-center gap-8 p-4'}>
        <CustomStepper steps={steps} activeStep={activeStep} />
        <TransferConfirmation next={onClose} back={handleBack} inventoryData={inventoryData} />
      </div>
    )
  }

  return (
    <div className={'flex flex-col items-center gap-8 p-4'}>
      <CustomStepper steps={steps} activeStep={activeStep} />
      <OriginTransferrer cancel={onClose} next={handleNext} />
    </div>
  )
}
