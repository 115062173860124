import React from 'react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { type ServiceTransactionDetails } from '../../types'
import { Services } from '../../../utils/services'

interface ServicesTableProps {
  services: ServiceTransactionDetails[]
}

const getService = (serviceId: number): string => {
  const findService = Services.find((service) => service.service_header_id === serviceId)
  return findService != null ? findService.service_name : ''
}

export const ServicesTable: React.FC<ServicesTableProps> = ({ services }) => {
  return (
    <Table>
      <TableCaption>Transaction Details.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>Service</TableHead>
          <TableHead className='font-bold text-color-text'>Quantity</TableHead>
          <TableHead className='font-bold text-color-text'>Price per unit</TableHead>
          <TableHead className='font-bold text-color-text'>Cost</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {services.map((service) => (
          <TableRow key={React.useId()} role={'listitem'}>
            <TableCell className='font-medium'>{getService(service.id)}</TableCell>
            <TableCell className='font-medium'>{service.qty}</TableCell>
            <TableCell className='font-medium'>BWP {service.price.toFixed(2)}</TableCell>
            <TableCell className='font-medium'>BWP {service.total.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
