import { Officer } from 'components/Auth/Officer'
import { SearchCroppingPlan } from 'features/officer/cropping-plan/routes/SearchCroppingPlan'
import { CroppingPlanView } from 'features/officer/cropping-plan/routes/CroppingPlanView'
import { Farmers, FarmersFarmView, FarmersView } from 'features/officer/farmers/routes'
import { LeasedFarmView } from 'features/officer/farmers/routes/LeasedFarmView'
import { GrantsView } from 'features/officer/grants-management/routes/grantsView'
import { SearchFarms } from 'features/officer/land-management/routes/SearchFarms'
// TODO delete or re-enable
// import { SearchLease } from 'features/officer/lease-management/routes/SearchLease'
import { ChangePassword } from 'features/officer/profile/change-password/routes/ChangePassword'
import { Navigate } from 'react-router-dom'
import { OfficerProfile } from '../features/profile/routes/Officer'
import { SearchLease } from '../features/officer/lease-management/routes/SearchLease'

export const officerRoutes = {
  path: '/officer',
  element: <Officer />,
  children: [
    { index: true, element: <SearchFarms /> },
    { path: '/officer/change-password', element: <ChangePassword /> },
    // TODO delete or re-enable
    { path: '/officer/lease', element: <SearchLease /> },
    { path: '/officer/grants', element: <GrantsView /> },
    { path: '/officer/farmers', element: <Farmers /> },
    { path: '/officer/farmers/:farmerId', element: <FarmersView /> },
    { path: '/officer/farmers/:farmerId/:farmId', element: <FarmersFarmView /> },
    { path: '/officer/farmers/lease/:destinationFarmerId/:leaseId', element: <LeasedFarmView /> },
    { path: '/officer/cropping-plan', element: <SearchCroppingPlan /> },
    { path: '/officer/cropping-plan/:farmerId/:cpId', element: <CroppingPlanView /> },
    { path: '/officer/profile', element: <OfficerProfile /> },
    { path: '/officer/*', element: <Navigate to={'/officer/farms'} /> },
    { path: '*', element: <Navigate to={'.'} /> }
  ]
}
