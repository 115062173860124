import { type ApiResponse, type CroppingPlan } from 'types'
import type React from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteHeader, type DeleteHeaderDTO } from '../../api/header/deleteHeader'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { AxiosError, type AxiosResponse } from 'axios'
import { Button, Text, useToast } from 'components/ui'

interface DeleteCroppingPlanProps {
  croppingPlan: CroppingPlan
  next: () => void
}

export const DeleteCroppingPlan: React.FC<DeleteCroppingPlanProps> = ({ croppingPlan, next }) => {
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const deleteCroppingPlanHeaderMutation = useMutation({
    mutationFn: async (cpDto: DeleteHeaderDTO) => {
      return deleteHeader(cpDto)
    }
  })

  const deleteCroppingPlan = (): void => {
    deleteCroppingPlanHeaderMutation.mutate(
      {
        cp_id: croppingPlan.cropping_plan_id,
        farmerId: farmer?.farmer_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 200) {
            next()
            toast({
              variant: 'success',
              title: 'Cropping plan deleted.'
            })
            void queryClient.invalidateQueries({ queryKey: ['cropping-plans'] })
          } else {
            const error = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          } else {
            const error = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error.message)
            })
          }
        }
      }
    )
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        Delete this Cropping Plan?
      </Text>
      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        Are you sure you want to delete this cropping plan.
      </Text>

      <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
        NOTE: This is an irreversible action.
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button
          variant={'secondary'}
          disabled={deleteCroppingPlanHeaderMutation.isLoading}
          className={'w-1/2'}
          onClick={next}
        >
          Cancel
        </Button>
        <Button
          className={'w-1/2'}
          variant={'destructive'}
          disabled={deleteCroppingPlanHeaderMutation.isLoading}
          loading={deleteCroppingPlanHeaderMutation.isLoading}
          onClick={deleteCroppingPlan}
        >
          Delete
        </Button>
      </div>
    </div>
  )
}
