import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'
import type React from 'react'
import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import { useGetStaticInventory } from '../../../../farmer/inventory/api/getInventoryData'
import { ModalLayout } from '../ModalLayout'
import { ChecklistContainer } from '../checklist/ChecklistContainer'
import {
  type GetInventoryRes,
  type Implement,
  type PoweredImplement,
  type StaticInvData,
  type Tractor
} from 'features/farmer/inventory/types'

export function InventoryResults<TData, TValue>({
  columns,
  data
}: {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
}): React.ReactNode {
  const [accept, setAccept] = useState(false)
  const [selectedItem, setSelectedItem] = useState<GetInventoryRes>()
  const [matchingItem, setMatchingItem] = useState<Tractor | PoweredImplement | Implement>()
  const { data: staticInvData } = useGetStaticInventory()

  const findMatch = (
    inventoryData: GetInventoryRes,
    staticData: StaticInvData
  ): PoweredImplement | Tractor | Implement | null => {
    if (inventoryData.inventory_type_id === 1003) {
      return staticData?.tractors?.find(
        (item) => item.model_id === inventoryData.resource_item
      ) as Tractor
    } else if (inventoryData.inventory_type_id === 1002) {
      return staticData?.implements?.find(
        (item) => item.model_id === inventoryData.resource_item
      ) as Implement
    } else if (inventoryData.inventory_type_id === 1001) {
      return staticData?.powered_implements?.find(
        (item) => item.model_id === inventoryData.resource_item
      ) as PoweredImplement
    }

    return null
  }

  const handleOpenAccept = (): void => {
    setAccept(true)
  }

  const handleCloseAccept = (): void => {
    setAccept(false)
  }

  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  })

  return (
    <div className={'flex flex-col gap-4'}>
      <p className={'text-info '}>Search results: {data.length}</p>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className='text-center font-bold'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={'hover:cursor-pointer hover:bg-muted-hover'}
                  onClick={() => {
                    const inventoryItem = row.original as GetInventoryRes
                    handleOpenAccept()
                    setSelectedItem(inventoryItem)
                    setMatchingItem(findMatch(inventoryItem, staticInvData?.data as StaticInvData)!)
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className='text-center font-medium'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
      <ModalLayout open={accept} onClose={handleCloseAccept}>
        <ChecklistContainer
          inventoryData={selectedItem as GetInventoryRes}
          onclose={handleCloseAccept}
          inventoryItem={matchingItem}
        />
      </ModalLayout>
    </div>
  )
}
