import { type Lease } from 'types'
import type React from 'react'
import { Text } from 'components/ui'

interface StatusesProps {
  lease: Lease
}

export const Statuses: React.FC<StatusesProps> = ({ lease }) => {
  if (lease.status_desc === 'RENEWAL INITIATED BY LESSEE') {
    return <Text variant={'warning'}>Pending Renewal</Text>
  }

  if (
    lease.status_desc === 'TERMINATION INITIATED BY LESSOR' ||
    lease.status_desc === 'TERMINATION INITIATED BY LESSEE'
  ) {
    return <Text variant={'warning'}>Pending Termination</Text>
  }

  if (lease.status_desc === 'EXPIRED') {
    return <Text variant={'error'}>Expired</Text>
  }

  if (lease.status_desc === 'REJECTED BY LESSEE') {
    return <Text variant={'error'}>Rejected</Text>
  }

  if (
    lease.status_desc === 'TERMINATION ACCEPTED BY LESSEE' ||
    lease.status_desc === 'TERMINATION ACCEPTED BY LESSOR'
  ) {
    return <Text variant={'error'}>Terminated</Text>
  }

  if (
    lease.status_desc === 'ACCEPTED BY LESSEE' ||
    lease.status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
    lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR'
  ) {
    return <Text variant={'success'}>Verified</Text>
  }

  return <Text variant={'warning'}>Pending Verification</Text>
}
