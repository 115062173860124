import type React from 'react'
import { useState } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { useAtom } from 'jotai'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { Accountants, type AccountantsInput, schemaAcc } from './Accountants'
import { companyLoanStore } from '../stores'
import { type LoanStepperProps } from '../../../types'
import { usePhoneValidationArray } from 'features/loan-management/hooks/usePhoneValidationArray'
import LoanButtonGroup from '../../generic/form/LoanButtonGroup'

export const AccountantsBody: React.FC<LoanStepperProps> = ({
  onNext,
  onBack,
  steps,
  activeStep
}) => {
  const [, setLoanData] = useAtom(companyLoanStore)
  const [telephones, setTelephones] = useState<string[]>([''])
  const [faxes, setFaxes] = useState<string[]>([''])
  const [cells, setCells] = useState<string[]>([''])
  const isTelephoneValid = usePhoneValidationArray(telephones)
  const isFaxValid = usePhoneValidationArray(faxes)
  const isCellValid = usePhoneValidationArray(cells)

  const isSubmitDisabled = !(isTelephoneValid && isFaxValid && isCellValid)

  const onSubmitAcc: SubmitHandler<AccountantsInput> = (data: AccountantsInput) => {
    setLoanData((prev) => ({
      ...prev!,
      loan_accountants: data.accountants
    }))
    onNext()
  }

  return (
    <FormWithStepper<AccountantsInput, typeof schemaAcc>
      className={`flex flex-col p-4 flex-grow-[3] justify-between w-full`}
      isDisabled={false}
      onSubmit={onSubmitAcc}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schemaAcc}
    >
      {(methods) => (
        <>
          {/* Pass phone arrays and setters to the Accountants component */}
          <Accountants
            methods={methods}
            telephones={telephones}
            faxes={faxes}
            cells={cells}
            setTelephones={setTelephones}
            setFaxes={setFaxes}
            setCells={setCells}
          />
          <div className='w-full p-8 hidden lg:flex flex-row justify-between gap-12'>
            <LoanButtonGroup
              onBack={onBack}
              onSubmit={methods.handleSubmit(onSubmitAcc)}
              disabled={isSubmitDisabled}
            />
          </div>
        </>
      )}
    </FormWithStepper>
  )
}
