import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createSeeding,
  type CreateSeedingDTO
} from 'features/farmer/cropping-plan/api/seeding/createSeeding'
import {
  updateSeeding,
  type UpdateSeedingDTO
} from 'features/farmer/cropping-plan/api/seeding/updateSeeding'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, type Seeding } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'
import type z from 'zod'
import { type seedingSchema } from '../components/steps/seeding/SeedSelection'

interface UseCroppingPlanSeeding {
  header: CroppingPlan | null
  seeding: Seeding | null
  setSeeding: (seeding: Seeding | null) => void
  isLoading: boolean
  createSeedingPlan: (
    data: z.infer<typeof seedingSchema>,
    variety: string | null | undefined
  ) => void
  updateSeedingPlan: (
    data: z.infer<typeof seedingSchema>,
    variety: string | null | undefined
  ) => void
}

export const useCroppingPlanSeeding = (next: () => void): UseCroppingPlanSeeding => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { header, seeding, setSeeding } = useCroppingPlanStore()

  const createSeedingMutation = useMutation({
    mutationFn: async (cpDto: CreateSeedingDTO) => {
      return createSeeding(cpDto)
    }
  })

  const updateSeedingMutation = useMutation({
    mutationFn: async (cpDto: UpdateSeedingDTO) => {
      return updateSeeding(cpDto)
    }
  })

  const createSeedingPlan = (
    data: z.infer<typeof seedingSchema>,
    variety: string | null | undefined
  ): void => {
    createSeedingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        seed_type: data.seedType,
        target_yield: data?.targetYield,
        cp_id: header?.cropping_plan_id,
        last_update_by: farmer?.user_id,
        seed_variety: variety
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['seeding'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateSeedingPlan = (
    data: z.infer<typeof seedingSchema>,
    variety: string | null | undefined
  ): void => {
    updateSeedingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        seed_type: data.seedType,
        target_yield: data?.targetYield,
        cp_id: header?.cropping_plan_id,
        last_update_by: farmer?.user_id,
        seed_variety: variety
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['seeding'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    header,
    seeding,
    setSeeding,
    isLoading: createSeedingMutation.isLoading || updateSeedingMutation.isLoading,
    createSeedingPlan,
    updateSeedingPlan
  }
}
