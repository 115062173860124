import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface DeleteHeaderDTO {
  farmerId: number | null | undefined
  cp_id: number | null | undefined
}

export const deleteHeader = async (params: DeleteHeaderDTO): Promise<ApiResponse<[]>> => {
  return axios.delete(
    `/production/header?farmer_id=${params.farmerId ?? ''}&cp_id=${params.cp_id ?? ''}`
  )
}
