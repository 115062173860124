import { zodResolver } from '@hookform/resolvers/zod'
import {
  type DefaultValues,
  type FieldValues,
  FormProvider,
  type SubmitHandler,
  useForm,
  type UseFormProps,
  type UseFormReturn
} from 'react-hook-form'
import { type ZodType, type ZodTypeDef } from 'zod'
import type React from 'react'
import { LoanStepper } from '../stepper/individual/LoanStepper'

interface FormWithStepperProps<TFormValues extends FieldValues, Schema> {
  className?: string
  onSubmit: SubmitHandler<TFormValues>
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode[] | React.ReactNode
  options?: UseFormProps<TFormValues>
  schema: Schema
  onBack: () => void
  steps: string[]
  activeStep: number
  isLoading?: boolean
  isDisabled?: boolean
  defaultValues?: DefaultValues<TFormValues> | undefined
}

export const FormWithStepper = <
  TFormValues extends FieldValues,
  Schema extends ZodType<unknown, ZodTypeDef, unknown>
>({
  onSubmit,
  children,
  className,
  options,
  schema,
  onBack,
  steps,
  activeStep,
  isDisabled = false,
  isLoading = false,
  defaultValues
}: FormWithStepperProps<TFormValues, Schema>): React.JSX.Element => {
  const methods = useForm<TFormValues>({
    ...options,
    resolver: zodResolver(schema),
    defaultValues
  })

  return (
    <FormProvider {...methods}>
      <form
        className={'w-full h-full flex flex-col flex-grow'}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <LoanStepper
          key={'loan'}
          stepperKey={'loans'}
          steps={steps}
          isDisabled={isDisabled}
          isLoading={isLoading}
          activeStep={activeStep}
          next={methods.handleSubmit(onSubmit)}
          back={onBack}
        >
          <div className={className}>{children(methods)}</div>
        </LoanStepper>
      </form>
    </FormProvider>
  )
}
