import type { SoilSampleRequest } from '../../../../../types'
import type React from 'react'
import type z from 'zod'
import { coerce, object } from 'zod'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSSR } from '../../hooks/useSSR'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '../../../../../components/ui'
import { useGetMerchants } from '../../api/getMerchants'
import { Error } from '../../../../../components/Errors/Error'
import { Check } from 'lucide-react'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from 'components/ui/command'
import { cn } from '../../../../../lib/utils'

interface UpdateSSRProps {
  cancel: () => void
  ssr: SoilSampleRequest
}

const schema = object({
  merchantID: coerce.number({
    required_error: 'Lab is required.',
    invalid_type_error: 'Lab is required.'
  })
})

export const UpdateSSR: React.FC<UpdateSSRProps> = ({ cancel, ssr }) => {
  const { farmer } = useFarmerStore()
  const { updateSSR, deleteSSR, isLoading } = useSSR(farmer, cancel)
  const {
    data: merchants,
    isLoading: isLoadingMerchants,
    error: merchantsError
  } = useGetMerchants()

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      merchantID: ssr?.merchant_id
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    updateSSR(data, ssr)
  }

  const onDelete = (data: z.infer<typeof schema>): void => {
    deleteSSR(ssr)
  }

  if (isLoadingMerchants) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (merchantsError != null) {
    if (merchantsError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (merchants?.data == null) {
    return <Error />
  }

  const soilLabs = merchants.data

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name='merchantID'
          render={({ field }) => (
            <FormItem className='w-full flex flex-col gap-4'>
              <FormLabel>Request Soil Sample Results</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant='outlined'
                      role='combobox'
                      className={cn(
                        'justify-between',
                        field.value != null && 'text-muted-foreground'
                      )}
                    >
                      {field.value != null
                        ? soilLabs.find((lab) => lab.merchant_id === field.value)?.merchant_name
                        : 'Select a Soil Lab'}
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className='p-0'>
                  <Command>
                    <CommandInput placeholder='Search soil labs...' />
                    <CommandList>
                      <CommandEmpty>No soil lab found found.</CommandEmpty>
                      <CommandGroup itemType={'button'}>
                        {soilLabs.map((lab) => (
                          <CommandItem
                            value={lab.merchant_name}
                            key={lab.merchant_id}
                            onSelect={(value) => {
                              form.setValue('merchantID', lab.merchant_id)
                            }}
                          >
                            <Check
                              className={cn(
                                'mr-2 h-4 w-4',
                                lab.merchant_id === field.value ? 'opacity-100' : 'opacity-0'
                              )}
                            />
                            {lab.merchant_name}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormDescription className={'text-center'}>
                Make a request to get your soil sample request from the chosen lab.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button
            variant={'destructive'}
            className={'w-1/2'}
            onClick={form.handleSubmit(onDelete)}
            loading={isLoading}
            disabled={isLoading}
          >
            delete
          </Button>
          <Button
            variant={'primary'}
            className={'w-1/2'}
            type={'submit'}
            loading={isLoading}
            disabled={isLoading || ssr.status_name === 'IN PROCESS'}
          >
            Update
          </Button>
        </div>
      </form>
    </Form>
  )
}
