import { type FC } from 'react'
import { type Grant, type Lease } from 'types'
import { NoGrants } from './NoGrants'
import { FarmGrantsTable } from '../farm-grants/FarmGrants'
import { farmGrantsColumns } from '../farm-grants/columns'

interface GrantsTabProps {
  grants: Grant[] | undefined
  lease?: Lease | undefined
}

export const GrantsTab: FC<GrantsTabProps> = ({ grants, lease }) => {
  if (grants == null) {
    return <NoGrants />
  }

  // filter grants for lease
  const leaseGrants = grants.filter((item) => item.lease_id === lease?.lease_id)

  // if no lease filtered grants
  if (lease != null && leaseGrants.length === 0) {
    return <NoGrants />
  }

  // if lease grants
  if (lease != null) {
    return <FarmGrantsTable columns={farmGrantsColumns} data={leaseGrants} />
  }

  // if not lease and grants
  return <FarmGrantsTable columns={farmGrantsColumns} data={grants} />
}
