import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreateHarvestingDTO {
  cp_id: number | null | undefined
  method: string
  user_id: number | null | undefined
  farmer_id: number | null | undefined
}

export const createHarvesting = async (params: CreateHarvestingDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/production/harvesting', params)
}
