import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type GetInventoryRes } from '../types'

export const getServiceProviderInventory = async (
  merchantId: number
): Promise<ApiResponse<GetInventoryRes>> => {
  return axios.get(`/inventory?merchant_id=${merchantId}`)
}

export const useGetServiceProviderInventory = (
  merchantId: number,
  enabled: boolean
): UseQueryResult<ApiResponse<GetInventoryRes>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: [`inventory-${merchantId}`, merchantId],
    queryFn: async () => getServiceProviderInventory(merchantId),
    enabled
  })
}
