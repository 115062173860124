import { type SeedTransactionDetails } from '../seed-dealer/types'
import { type ServiceTransactionDetails } from '../service-provider/types'

export const isSeedTransactionDetails = (item: unknown): item is SeedTransactionDetails[] => {
  if (!Array.isArray(item)) {
    return false
  }

  if (item.length === 0) return true

  return (item as SeedTransactionDetails[])[0].id !== undefined
}

export const isServiceTransactionDetails = (item: unknown): item is ServiceTransactionDetails[] => {
  if (!Array.isArray(item)) {
    return false
  }

  if (item.length === 0) return true

  return (item as ServiceTransactionDetails[])[0].id !== undefined
}
