import type { ApiResponse, CroppingPlan } from '../../../types'
import { axios } from '../../../lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'

export const getCroppingPlans = async (
  farmerId: number | null | undefined,
  farmId: number | null | undefined
): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(`/production/header?farmer_id=${farmerId ?? ''}&farm_id=${farmId ?? ''}`)
}

export const useGetCroppingPlans = (
  farmerId: number | null | undefined,
  farmId: number | null | undefined
): UseQueryResult<ApiResponse<CroppingPlan>, AxiosError> => {
  return useQuery({
    queryKey: ['cropping-plans', farmerId, farmId],
    queryFn: async () => getCroppingPlans(farmerId, farmId),
    enabled: farmerId != null || farmId != null
  })
}
