import { type Dispatch, type SetStateAction } from 'react'
import { Chip, useMediaQuery } from '@mui/material'

interface FilterMenuProps {
  all: number
  verified: number
  pending: number
  rejected: number
  onTransfer: number
  filterBy: 'all' | 'VERIFIED' | 'PENDING_VERIFICATION' | 'REJECTED' | 'Transfer initiated'
  setFilterBy: Dispatch<
    SetStateAction<'all' | 'VERIFIED' | 'PENDING_VERIFICATION' | 'REJECTED' | 'Transfer initiated'>
  >
}

export const InventoryFilterMenu: React.FC<FilterMenuProps> = ({
  all,
  verified,
  pending,
  rejected,
  setFilterBy,
  filterBy,
  onTransfer
}) => {
  const handleFilter = (
    type: 'all' | 'VERIFIED' | 'PENDING_VERIFICATION' | 'REJECTED' | 'Transfer initiated'
  ): void => {
    setFilterBy(type)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  return (
    <div className={'w-full flex justify-start items-center gap-2'}>
      <Chip
        label={isSmallScreen ? `All(${all})` : `All Inventories (${all})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'all' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'all' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'all' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('all')
        }}
        clickable={true}
      />

      <Chip
        label={isSmallScreen ? `Verified(${verified})` : `Verified Inventories (${verified})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'VERIFIED' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'VERIFIED' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'VERIFIED' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'VERIFIED' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'VERIFIED' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('VERIFIED')
        }}
        clickable={true}
      />
      <Chip
        label={isSmallScreen ? `Pending(${pending})` : `Pending Verification (${pending})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'PENDING_VERIFICATION' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'PENDING_VERIFICATION' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'PENDING_VERIFICATION' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'PENDING_VERIFICATION'
                ? 'var(--primary-foreground)'
                : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'PENDING_VERIFICATION' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('PENDING_VERIFICATION')
        }}
        clickable={true}
      />

      <Chip
        label={isSmallScreen ? `Rejected(${rejected})` : `Rejected (${rejected})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'REJECTED' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'REJECTED' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'REJECTED' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'REJECTED' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'REJECTED' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('REJECTED')
        }}
        clickable={true}
      />

      <Chip
        label={isSmallScreen ? `On Transfer(${onTransfer})` : `On Transfer (${onTransfer})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'Transfer initiated' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'Transfer initiated' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'Transfer initiated' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'Transfer initiated' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'Transfer initiated' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('Transfer initiated')
        }}
        clickable={true}
      />
    </div>
  )
}
