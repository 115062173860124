import { BasicCard } from 'components/Elements/Card/BasicCard'
import { BasicModal } from 'components/Elements/Modal/Modal'
import type React from 'react'

interface LayoutProps {
  open: boolean
  onClose: () => void
  children?: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ open, onClose, children }) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <div className={'grid place-items-center'}>
        <BasicCard
          className={
            'p-4 md:p-6 lg:p-8 xl:p-10 min-w-[20rem] md:min-w-[30rem] min-h-[30%] max-w-[90%] md:max-w-[70%] grid place-items-center'
          }
        >
          {children}
        </BasicCard>
      </div>
    </BasicModal>
  )
}
