import { Box } from '@mui/material'
import { BasicButton } from 'components/Elements/Button'
import { Text } from 'components/Elements/Text/Text'
import type React from 'react'
import { type SoilSampleRequest } from 'types'

interface RejectSSRProps {
  ssr: SoilSampleRequest
  onClose: () => void
}

export const RejectSsr: React.FC<RejectSSRProps> = ({ ssr, onClose }) => {
  return (
    <div className={'flex justify-center items-center w-[110%]'}>
      <Box className={'w-full flex flex-col md:p-8 p-12 gap-4 md:gap-8 lg:gap-12'}>
        <h3 className='w-full flex justify-center p-2'>View SSR Rejection Message</h3>
        <>
          <div className={'flex flex-col'}>
            <Text>Reject Reason</Text>
            <Text size={'medium'}>
              <strong>{ssr?.comment}</strong>
            </Text>
          </div>
          <div className={'flex flex-row justify-between gap-8 mt-8'}>
            <BasicButton
              label={'Okay'}
              variant={'primary'}
              onClick={onClose}
              type={'submit'}
              size={'xs'}
            />
          </div>
        </>
      </Box>
    </div>
  )
}
