import { ErrorPage } from 'components/Errors/ErrorPage'
import { useGetLeasedFarmers } from 'features/officer/farmers/api/getLeasedFarmers'
import { isLease } from 'features/officer/farmers/utils/funcitons'
import { uniqBy } from 'lodash'
import type React from 'react'
import { useOfficerStore } from 'stores/useOfficerStore'
import { useGetFarmers } from '../api/getFarmers'
import { Card, Text, Loader } from 'components/ui'
import { FarmersTable } from '../components/farmers/FarmersTable'
import { farmersColumns } from '../components/farmers/columns'

export const Farmers: React.FC = () => {
  const { officer } = useOfficerStore()
  const { data: farmers, isLoading, error } = useGetFarmers(officer?.ext_area_id)
  const {
    data: leasedFarmers,
    isLoading: leasedIsLoading,
    error: leaseError
  } = useGetLeasedFarmers(officer?.ext_area_id)

  if (isLoading || leasedIsLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (
    error != null &&
    leaseError != null &&
    error?.response?.status !== 404 &&
    leaseError?.response?.status !== 404
  ) {
    return <ErrorPage />
  }

  if (farmers?.data === undefined && leasedFarmers?.data === undefined) {
    return (
      <div className={'max-w-[1700px] w-full flex-col gap-4 flex justify-center mx-auto px-0 py-4'}>
        <Text size={'large'}>My Farmers</Text>
        <div
          className={
            'w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8 '
          }
        >
          <Text size={'medium'} variant={'bodyTextLight'}>
            No farmers found
          </Text>
        </div>
      </div>
    )
  }

  const farmersList = [...(farmers?.data ?? []), ...(leasedFarmers?.data ?? [])]

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'}>My Farmers</Text>

      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <Text size={'medium'} variant={'bodyTextLight'}>
          Below are all the farmers in your extension area.
        </Text>

        <FarmersTable
          columns={farmersColumns}
          data={uniqBy(farmersList, (item) => {
            return isLease(item) ? item.dest_farmer_id : item.farmer_id
          })}
        />
      </Card>
    </div>
  )
}
