import {
  AttachmentRepo,
  Attachments,
  CommercialBank,
  ConfirmDetails,
  FinancialParticulars,
  MaritalInformation,
  NextOfKin,
  SummarizedBusinessInformation
} from 'features/loan-management/components/stepper/individual'
import type React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApplicantEmploymentInfo } from './ApplicantEmploymentInfo'
import { HistoryOfNdbLoans } from './HistoryOfNdbLoans'
import { BalanceSheetDetailsBody } from './BalanceSheetDetailsBody'
import { IndividualSecurityBody } from './IndividualSecurityBody'
import { ProductionScheduleIndividualBody } from './ProductionScheduleIndividualBody'
import { OtherLoansBody } from './OtherLoansBody'
import { PersonalInformation } from './PersonalInformation'
import { SelectPlans } from './SelectPlans'

export const steps = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16'
]
export const scaleType:
  | 'L1-Small Scale'
  | 'L2-Small Scale'
  | 'L1-Medium Scale'
  | 'L2-Medium Scale' = 'L1-Small Scale'
export const LoanApplicationForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const handleNextLAF = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const navigate = useNavigate()
  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }
  const handleCancelLAF = (): void => {
    navigate('/farmer/loans')
  }

  if (activeStep === 1) {
    return (
      <div className={'flex flex-col gap-8'}>
        <Attachments
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 2) {
    return (
      <div className={'flex flex-col gap-8'}>
        <PersonalInformation
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 3) {
    return (
      <div className={'flex flex-col gap-8'}>
        <ApplicantEmploymentInfo
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }

  if (activeStep === 4) {
    return (
      <div className={'flex flex-col gap-8'}>
        <NextOfKin
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }

  if (activeStep === 5) {
    return (
      <div className={'flex flex-col gap-8'}>
        <MaritalInformation
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 6) {
    return (
      <div className={'flex flex-col gap-8'}>
        <CommercialBank
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }

  if (activeStep === 7) {
    return (
      <div className={'flex flex-col gap-8'}>
        <OtherLoansBody
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 8) {
    return (
      <div className={'flex flex-col gap-8'}>
        <HistoryOfNdbLoans
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 9) {
    return (
      <div className={'flex flex-col gap-8'}>
        <FinancialParticulars
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 10) {
    return (
      <div className={'flex flex-col gap-8'}>
        <IndividualSecurityBody
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 11) {
    return (
      <div className={'flex flex-col gap-8'}>
        <ProductionScheduleIndividualBody
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 12) {
    return (
      <div className={'flex flex-col gap-8'}>
        <SummarizedBusinessInformation
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 13) {
    return (
      <BalanceSheetDetailsBody
        activeStep={activeStep}
        steps={steps}
        onBack={handleBack}
        onNext={handleNextLAF}
      />
    )
  }
  if (activeStep === 14) {
    return (
      <div className={'flex flex-col gap-8'}>
        <ConfirmDetails
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleNextLAF}
        />
      </div>
    )
  }
  if (activeStep === 15) {
    return (
      <div className={'flex flex-col gap-8'}>
        <AttachmentRepo
          activeStep={activeStep}
          steps={steps}
          onBack={handleBack}
          onNext={handleCancelLAF}
        />
      </div>
    )
  }

  return (
    <div className={'flex flex-col gap-8'}>
      <SelectPlans
        activeStep={activeStep}
        steps={steps}
        onBack={handleCancelLAF}
        onNext={handleNextLAF}
      />
    </div>
  )
}
