import type z from 'zod'
import type { TypeOf } from 'zod'
import { coerce, object } from 'zod'
import type { Farm, Lease } from 'types'
import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCroppingPlanHeader } from '../../../hooks/useCroppingPlanHeader'
import { useGetIndividualHeader } from '../../../api/header/getHeader'
import { CropsList } from '../../../utils/crops'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Loader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { StepperFooter } from '../Footer'
import { useStepper } from 'components/ui/stepper'

interface Base {
  cancel: () => void
}
interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CroppingPlanHeaderProps = WithFarm | WithLease

const generalSchema = object({
  crop: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  hectarage: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  })
})

export type HeaderInput = TypeOf<typeof generalSchema>

export const CroppingPlanHeader: React.FC<CroppingPlanHeaderProps> = ({ cancel, farm, lease }) => {
  const { nextStep } = useStepper()
  const { header, setHeader, isLoading, createCroppingPlanHeader, updateCroppingPlanHeader } =
    useCroppingPlanHeader(farm, nextStep, lease)
  const {
    data: apiHeader,
    isInitialLoading: apiIsLoading,
    error
  } = useGetIndividualHeader(header?.cropping_plan_id, header?.farmer_id)

  const schema = object({
    crop: coerce.number({
      required_error: 'field is required',
      invalid_type_error: 'field is required'
    }),
    hectarage: coerce
      .number({
        required_error: 'field is required',
        invalid_type_error: 'field is required'
      })
      .min(1)
      .max(farm !== undefined ? farm.size - farm.leased_size : lease?.leased_size)
  })

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      crop:
        header !== null
          ? CropsList.find((crop) => crop.crop_id === header?.crop_id)?.crop_id
          : 10011,
      hectarage: header !== null ? header?.hectarage : apiHeader?.data[0].hectarage ?? 1
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    if (apiHeader == null) {
      createCroppingPlanHeader(data)
    } else if (
      data.crop !== apiHeader.data[0].crop_id ||
      data.hectarage !== apiHeader.data[0].hectarage
    ) {
      updateCroppingPlanHeader(data)
    } else {
      nextStep()
    }
  }

  useEffect(() => {
    if (apiHeader?.data != null) {
      setHeader(apiHeader.data[0])
    }
  }, [apiHeader])

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error?.response?.status !== 404) {
      return <Error />
    }
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='crop'
            render={({ field }) => (
              <FormItem>
                <FormLabel className={'font-redHat'}>Crop</FormLabel>
                <Select
                  onValueChange={(val) => {
                    field.onChange(val)
                  }}
                  defaultValue={
                    header !== null
                      ? CropsList.find(
                          (crop) => crop.crop_id === header?.crop_id
                        )?.crop_id?.toString()
                      : '10011'
                  }
                  disabled={header != null || apiHeader?.data != null}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder='Crop' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {CropsList.map((crop) => (
                      <SelectItem key={crop.crop_id} value={crop.crop_id.toString()}>
                        {crop.crop_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'hectarage'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className={'font-redHat'}>Hectarage (Ha)</FormLabel>
                <FormDescription>
                  Max Size: {farm !== undefined ? farm.size - farm.leased_size : lease?.leased_size}{' '}
                  Ha
                </FormDescription>
                <FormControl>
                  <Input
                    classLabel={'bg-background-dark sm:bg-card'}
                    placeholder={'Farm size'}
                    type={'text'}
                    inputMode={'numeric'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter isFirstStep cancel={cancel} isLoading={isLoading} />
      </form>
    </Form>
  )
}
