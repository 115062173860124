import React from 'react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { type SeedTransactionDetails } from '../../types'
import { SeedsList } from '../../../../farmer/cropping-plan/utils/seeds'
import { CropsList } from '../../../../farmer/cropping-plan/utils/crops'

interface SeedsTableProps {
  seeds: SeedTransactionDetails[]
}

const getCropName = (seedId: number): string => {
  const findSeed = SeedsList.find((seed) => seed.seed_id === seedId)
  const cropId = findSeed != null ? findSeed.crop_id : 0
  const cropName = CropsList.find((crop) => crop.crop_id === cropId)
  return cropName != null ? cropName.crop_name : ''
}

const getSeedVariety = (seedId: number): string => {
  const findSeed = SeedsList.find((seed) => seed.seed_id === seedId)
  return findSeed != null ? findSeed.variety : ''
}

export const SeedsTable: React.FC<SeedsTableProps> = ({ seeds }) => {
  return (
    <Table>
      <TableCaption>Transaction Details.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>Crop</TableHead>
          <TableHead className='font-bold text-color-text'>Variety</TableHead>
          <TableHead className='font-bold text-color-text'>Quantity</TableHead>
          <TableHead className='font-bold text-color-text'>Price</TableHead>
          <TableHead className='font-bold text-color-text'>Cost</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {seeds.map((seed) => (
          <TableRow key={React.useId()} role={'listitem'}>
            <TableCell className='font-medium'>{getCropName(seed.id)}</TableCell>
            <TableCell className='font-medium'>{getSeedVariety(seed.id)}</TableCell>
            <TableCell className='font-medium'>{seed.qty}</TableCell>
            <TableCell className='font-medium'>BWP {seed.price.toFixed(2)}</TableCell>
            <TableCell className='font-medium'>BWP {seed.total.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
