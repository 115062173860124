import React from 'react'
import { Button, Dialog, DialogContent } from 'components/ui'
import { Trash2 } from 'lucide-react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import { DeleteCroppingPlan } from './DeleteCroppingPlan'

interface DataTableActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  croppingPlan: CroppingPlan
}

export const CroppingPlanActions: React.FC<DataTableActionsProps> = ({ croppingPlan }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const handleOpenDialog = (): void => {
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  // actions to delete cropping plan
  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Draft) {
    return (
      <>
        <Button onClick={handleOpenDialog} size={'icon'} variant='icon' className='md:h-8 md:w-8'>
          <Trash2 size={16} className={'text-destructive'} />
        </Button>
        <Dialog
          key={`delete-cropping-plan-${croppingPlan.cropping_plan_id}`}
          open={openDialog}
          defaultOpen={false}
          modal
          onOpenChange={handleCloseDialog}
        >
          <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
            <DeleteCroppingPlan croppingPlan={croppingPlan} next={handleCloseDialog} />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  return null
}
