import type React from 'react'
import { useState } from 'react'
import { type Farm } from 'types'
import { Button, Dialog, DialogContent } from 'components/ui'
import { LeaseFarm } from '../new-lease/LeaseFarm'

interface ManageLeaseProps {
  farm: Farm
}

export const ManageLease: React.FC<ManageLeaseProps> = ({ farm }) => {
  const [open, setOpen] = useState(false)

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant={'success'}
        size={'xs'}
        onClick={openDialog}
        disabled={farm.leased_size === farm.size}
      >
        Lease This Farm
        <p className='fa-solid fa-right-left' />
      </Button>

      <Dialog open={open} defaultOpen={false} modal onOpenChange={closeDialog}>
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <LeaseFarm farm={farm} onClose={closeDialog} />
        </DialogContent>
      </Dialog>
    </>
  )
}
