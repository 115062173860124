import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface RenewLeaseDTO {
  LEASE_ID: number | null | undefined
  FARMER_ID: number | null | undefined
  // STATUS_ID: number
  LAST_UPDATED_BY: number | null | undefined
  LEASED_AREA: number
  TERMINATION_DATE: string
}

export const renewLease = async (params: RenewLeaseDTO): Promise<ApiResponse<[]>> => {
  return axios.put('/lease/renewal', params)
}
