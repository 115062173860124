type ScaleType =
  | 'L1-Small Scale'
  | 'L2-Small Scale'
  | 'L1-Medium Scale'
  | 'L2-Medium Scale'
  | 'L3-Medium Scale'
  | 'L1-Large Scale'
  | 'L2-Large Scale'

export const classifyHectare = (hectare: number): ScaleType => {
  if (hectare <= 8) {
    return 'L1-Small Scale'
  } else if (hectare <= 16) {
    return 'L2-Small Scale'
  } else if (hectare <= 50) {
    return 'L1-Medium Scale'
  } else if (hectare <= 100) {
    return 'L2-Medium Scale'
  } else if (hectare <= 150) {
    return 'L3-Medium Scale'
  } else if (hectare <= 500) {
    return 'L1-Large Scale'
  } else {
    return 'L2-Large Scale'
  }
}
