import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string, type TypeOf } from 'zod'
import { useSearchInventory } from 'features/mechanisationOfficer/inventory/hooks/useSearchInventory'
import { toast } from 'react-hot-toast'
import {
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { Box } from 'components/App/Layout'
import { inventoryColumns } from '../components/tables/inventoryColums'
import { InventoryResults } from '../components'

const schema = object({
  idType: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  idInput: string()
}).refine(
  (data) => {
    if (data.idType === 'merchantId') {
      return data.idInput.length > 0
    }

    if (data.idType === 'farmerId') {
      return data.idInput.length === 7
    }
    return false
  },
  {
    message: 'Invalid input length',
    path: ['idInput']
  }
)

type SsrInput = TypeOf<typeof schema>

export const SearchInventories: React.FC = () => {
  const { searchInventoryItem, error, data: inventory, isLoading } = useSearchInventory()
  const form = useForm<SsrInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      idType: '',
      idInput: ''
    }
  })

  const onSubmit = (data: SsrInput): void => {
    void searchInventoryItem(data.idType, data.idInput)
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }
  }, [error])

  return (
    <Box>
      <div className='flex w-full flex-col gap-2 p-4'>
        <Text size='large'>Farm Inventories</Text>

        <div className='w-full h-full'>
          <Card className='flex-1 flex flex-col justify-center p-8 gap-8'>
            <div className='flex flex-col p-4 w-full gap-2'>
              <div className='pb-4'>
                <Text variant='primaryLight' size='medium'>
                  Select whether to search by Farmer ID or Merchant ID and enter the selected
                  option.
                </Text>
              </div>
              <Form {...form}>
                <form className='w-full' onSubmit={form.handleSubmit(onSubmit)}>
                  <div className='flex flex-row items-center gap-4'>
                    <FormField
                      control={form.control}
                      name='idType'
                      render={({ field }) => (
                        <FormItem className='flex flex-1 flex-col gap-1'>
                          <FormLabel>ID Type</FormLabel>
                          <FormControl>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value)
                              }}
                              value={field.value}
                            >
                              <SelectTrigger className='flex justify-between w-full'>
                                <SelectValue placeholder='Select Farmer/Merchant ID' />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectItem value='merchantId'>Merchant ID</SelectItem>
                                  <SelectItem value='farmerId'>Farmer ID</SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name='idInput'
                      render={({ field }) => (
                        <FormItem className='flex flex-1 flex-col gap-2'>
                          <FormLabel>
                            {form.watch('idType') === 'farmerId'
                              ? 'Farmer ID'
                              : form.watch('idType') === 'merchantId'
                              ? 'Merchant ID'
                              : 'Choose ID Type'}
                          </FormLabel>
                          <FormControl>
                            <Input
                              type='text'
                              inputMode='numeric'
                              pattern='[0-9]*'
                              placeholder={
                                form.watch('idType') === 'farmerId'
                                  ? 'Enter Farmer ID'
                                  : form.watch('idType') === 'merchantId'
                                  ? 'Enter Inventory ID'
                                  : 'Choose ID type'
                              }
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className='pt-8'>
                    <Button
                      variant='primary'
                      type='submit'
                      onClick={form.handleSubmit(onSubmit)}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Search
                    </Button>
                  </div>
                </form>
              </Form>
            </div>
          </Card>
        </div>

        {error === 'Not Found' ? (
          <div className='w-full h-full'>
            <Card className='flex-1 flex flex-col justify-center items-center p-8 gap-8'>
              <h2>No Inventory Items Found</h2>
            </Card>
          </div>
        ) : null}

        {inventory !== null ? (
          <div className='w-full h-full'>
            <Card className='flex-1 flex flex-col justify-center p-8 gap-8'>
              <InventoryResults data={inventory} columns={inventoryColumns} />
            </Card>
          </div>
        ) : null}
      </div>
    </Box>
  )
}
