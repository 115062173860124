import { type ChemicalList } from 'features/farmer/cropping-plan/types'
import { axios } from 'lib/axios'
import { type ApiResponse, type PestChemicalMethods, type PestManualSchedule } from 'types'

export interface CreatePestControlDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  user_id: number | null | undefined
  type: string | null | undefined
  manual_method?: 'Physical Pest Control' | 'Biological Pest Control' | null
  manual_schedule?: PestManualSchedule | null
  chemical_method?: PestChemicalMethods | null
  chemical_schedule?: 'Contact' | 'Preventive' | null
  chemicals?: ChemicalList[] | null
}

export const createPestControl = async (params: CreatePestControlDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/production/pest', params)
}
